const formatGroupForDropdown = (group) => {
    if (group.id && group.group_name) {
        return {
            value: group.id,
            label: group.group_name
        };
    }
}

const formatGroupForDropdownBulk = (groupList) => {
    return groupList.map(group => {
        return {...formatGroupForDropdown(group)};
    });
}

const formatGroupNameForTable = (group) => {
    if (group.id && group.group_name) {
        return group.group_name
    }
}

const formatGroupNameForTableInline = (group) => {
    if (group.label.length > 0) {
        return group.label
    }
}

const GroupUtil = {
    formatGroupForDropdown,
    formatGroupForDropdownBulk,
    formatGroupNameForTable,
    formatGroupNameForTableInline
};

export default GroupUtil;