import React, { useState, useEffect, useRef } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	FormGroup,
	Label,
	Input,
	Form,
} from "reactstrap";
import { useForm } from 'react-hook-form';
import InputGroup from "reactstrap/lib/InputGroup";
import CardBody from "reactstrap/lib/CardBody";
import UncontrolledAlert from "reactstrap/lib/UncontrolledAlert";
import Card from "reactstrap/lib/Card";
import makeAnimated from "react-select/animated";
import CreatableSelect from 'react-select/creatable';
import ENUMS from "constants/appEnums";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import TeamUtil from "util/TeamUtil";
import { useToasts } from "react-toast-notifications";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import GeneralUtils from "util/GeneralUtils";
import Switch from "react-bootstrap-switch";

export default function AddEditStakeholderModal(props) {
    const { handleSubmit, errors } = useForm({
		mode: "onBlur"
    });

    const current_user = useSelector(state => state.authReducer.user);
    
    const { addToast } = useToasts();

    //Editor props
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    // date errors
    const [errorVisible, setErrorVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const onToggleError = () => setErrorVisible(!errorVisible);

    // Form data mambers
    const [email, setEmail] = useState("");
    const [stakeholder, setStakeholder] = useState();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [organizationRole, setOrganizationRole] = useState("");
    const [team, setTeam] = useState();
    
    const [userList, setUserList] = useState([]);
    const [teamsList, setTeamsList] = useState([]);

    // togglers
    const [openEmailMenu, setOpenEmailMenu] = useState(false);
    const [disableEditing, setDisableEditing] = useState(false);
    const [showEditingToggle, setShowEditingToggle] = useState(false);

    // loading and disabled
    const [isLoading, setIsLoading] = useState(true);

    // animated components
    const animatedComponents = makeAnimated();

    /**
     * Populates the form data for edit mode.
     */
    const populateFormData = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.STAKEHOLDER_GET_BY_ID,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                stakeholder_id: props.editStakeholderId
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                const stakeholderData = res.data.projectStakeholder;
                console.log(stakeholderData)
                populateNoteHandler(stakeholderData.note);
                setFirstName(stakeholderData.Stakeholder.first_name);
                setLastName(stakeholderData.Stakeholder.last_name);
                setEmail(stakeholderData.Stakeholder.email);
                setStakeholder(createOption(stakeholderData.Stakeholder.email));
                setOrganizationRole(stakeholderData.Stakeholder.role);
                setTeam(TeamUtil.formatTeamForDropdown(stakeholderData.Team));
                setDisableEditing(true);
                setShowEditingToggle(true);
                setIsLoading(false);
            }
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch stakeholder data for edit. Please try again.", 
                    { 
                        appearance: 'error',
						autoDismiss: true
                    }
                );
            }
        })
    }

    const populateNoteHandler = (note) => {
        const contentBlock = htmlToDraft(note);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        }
    }

    const handleTeamCreation = (inputValue) => {
        // handle team creation
        API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TASK_CREATE_TASK_TEAM,
        {
            ...HttpUtil.adminHttpHeaders(),
            body: 
            {
                projectId: props.projectData.id,
                teamName: inputValue,
                teamLeaderId: current_user.id
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setTeam(TeamUtil.formatTeamForDropdown(res.data.createdTeam));
                setTeamsList([...teamsList, TeamUtil.formatTeamForDropdown(res.data.createdTeam)]);
            }
        })
        .catch(error => {
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch teams and groups for dropdown.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        });
    }

    const getNoteEditorContent = () => {
        const noteHTMLStringContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        if (noteHTMLStringContent === "<p></p>") {
            return '';
        }
        return noteHTMLStringContent;
    }

    /**
     * Fetches the user list for populating the dropdowns
     */
    const fetchUserList = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.USER_ALL_ORGANIZATION,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                project_id: props.projectData.id,
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setUserList(res.data.userList);
            }
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch user list for owner selection", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    // Method to handle form submit
    const handleConfirm = () => {
        const noteContent = getNoteEditorContent();
        const stakeholderData = {
            invite_email: email,
            first_name: firstName,
            last_name: lastName,
            organization_role: organizationRole || 'Unspecified',
            stakeholder_user_id: stakeholder?.value, 
            team_id: team?.value,
            project_id: props.projectData.id,
            note: noteContent,
            // organization_id: current_user.organization_id
        }

        if(!stakeholderData.invite_email || !stakeholderData.team_id) {
            addToast(
                "Please populate all required fields.", 
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
            return;
        }

        // add mode
        if (props.modalMode === ENUMS.MODAL.ADD_MODE) {
            API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.STAKEHOLDER_CREATE,
            {
                ...HttpUtil.adminHttpHeaders(),
                body:
                {
                    ...stakeholderData,
                }
            })
            .then(res => {
                if (res.status === 201 && res.data.success) {
                    props.refreshPageOnSubmit(res.data.message);
                }
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        "Failed to create stakeholder.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
        }
        // edit mode
        else if (props.modalMode === ENUMS.MODAL.EDIT_MODE) {
            API.put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.STAKEHOLDER_UPDATE,
            {
                ...HttpUtil.adminHttpHeaders(),
                body:
                {
                    id: props.editStakeholderId,
                    project_id: props.projectData.id,
                    ...stakeholderData
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success) {
                    props.refreshPageOnSubmit(res.data.message);
                }
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        "Failed to update stakeholder.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
        }
    }

    /**
     * Sets the new value to the stakeholder owner and closes the select dropdown.
     * @param {Object} newValue 
     */
    const handleStakeholderEmailChange = (newValue) => {
        setStakeholder(newValue);
        if (newValue && typeof(newValue.value) == "number") {
            API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.GET_USER_BY_ID,
            {
                ...HttpUtil.adminHttpHeaders(),
                queryStringParameters: {
                    user_id: newValue.value
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success) {
                    setFirstName(res.data.user.first_name);
                    setLastName(res.data.user.last_name);
                    setOrganizationRole(res.data.user.role);
                    setEmail(res.data.user.email);
                    setDisableEditing(true);
                    setShowEditingToggle(true);
                }
            })
            .catch(error => {
                setIsLoading(false);
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        "Failed to fetch user list for owner selection", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
        } else {
            if (newValue == null) {
                setFirstName("");
                setLastName("");
                setOrganizationRole("");
                setDisableEditing(false);
                setShowEditingToggle(false);
            } else {
                setEmail(newValue.value);
            }
        }
        hideMenu();
    }

    /**
     * Closes the dropdown menu on input change for Stakeholder owner select
     * @param {Object} query 
     * @param {Obejct} param1 
     */
    const handleStakeholderEmailInputChange = (query, { action }) => {
        if (action === "input-change") {
            setOpenEmailMenu(true);
        }
    };

    const handleInviteStakeholder = (inputValue) => {
        // TO-DO: Validate against email format
        if (GeneralUtils.validateEmail(inputValue)) {
            const option = createOption(inputValue);
            setUserList([...userList, option]);
            setEmail(option.value);
            setStakeholder(option);
        }
        else {
            addToast(
                "Invalid format for email.", 
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
        }
        hideMenu();
    }

    /**
     * Closes the dropdown menu for owner and member select
     */
    const hideMenu = () => {
        setOpenEmailMenu(false);
    };

    const createOption = (label) => {
        return {
            label,
            value: label.toLowerCase()
        }
    };

    /** 
     * NOTES DATA MEMEBERS 
     */

     /**
      * On change of the new note editor
      * 
      * @param {object} editorState 
      */
     const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    }

    const formatDropDownLabel = (inputValue) => {
        return "Invite - "+ inputValue;
    }

    const formatDropDownLabelTeam = (inputValue) => {
        return "Create Team - "+ inputValue;
    }

    useEffect(() => {
        // fetch user list
        fetchUserList();
    }, []);

    useEffect(() => {
        if (props.projectData) {
            setTeamsList(TeamUtil.formatTeamForDropdownBulk(props.projectData.Teams));
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (props.modalMode === ENUMS.MODAL.EDIT_MODE) {
            populateFormData();
        }
    }, [props.editStakeholderId]);

	return (
		<Modal
			size="md"
			isOpen={props.modalOpen}
			toggle={() => props.closeModal(false)}
            backdrop="static"
		>
			<ModalHeader toggle={() => props.closeModal(false)} tag="h3">
				{props.modalTitle}
			</ModalHeader>
            <Form role="form" onSubmit={handleSubmit(handleConfirm)}>
                <ModalBody className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-4 py-lg-4">
                            <FormGroup>
                                <Label for="stakeholder_email">Stakeholder Email<span className="required-asterix">*</span></Label>
                                <CreatableSelect
                                    id="stakeholderEmail"
                                    name="stakeholderEmail"
                                    className="form-control-alternative"
                                    // as={CreatableSelect}
                                    // control={control}
                                    // rules={{ required: "Stakeholder Owner is required." }}
                                    isClearable
                                    components={animatedComponents}
                                    isDisabled={isLoading}
                                    isLoading={isLoading}
                                    onChange={(newValue)=>handleStakeholderEmailChange(newValue)}
                                    onCreateOption={handleInviteStakeholder}
                                    createOptionPosition="first"
                                    formatCreateLabel={formatDropDownLabel}
                                    options={userList}
                                    value={stakeholder}
                                    onInputChange={handleStakeholderEmailInputChange}
                                    onBlur={hideMenu}
                                    onFocus={e => setOpenEmailMenu(true)}
                                    menuIsOpen={openEmailMenu}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="firstName" className="w-100">
                                    <div className="d-flex justify-content-between w-100">
                                        <div>First Name</div>
                                        {showEditingToggle && <div><span className="mr-3 text-small">Edit user details</span><Switch value={!disableEditing} className="cop-btn-switch mb-0" onChange={() => setDisableEditing(!disableEditing)} name='disableEditing' /></div>}
                                    </div>
                                </Label>
                                <InputGroup className="input-group-alternative mb-3">
                                    <Input 
                                        id="firstName"
                                        name="firstName"
                                        className="form-control-alternative"
                                        disabled={disableEditing}
                                        type="text"
                                        value={firstName}
                                        onChange={(e)=> setFirstName(e.target.value)}
                                        autoComplete="nope"
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label for="lastName">Last Name</Label>
                                <InputGroup className="input-group-alternative mb-3">
                                    <Input 
                                        id="lastName"
                                        name="lastName"
                                        className="form-control-alternative"
                                        disabled={disableEditing}
                                        type="text"
                                        value={lastName}
                                        onChange={(e)=> setLastName(e.target.value)}
                                        autoComplete="nope"
                                    />
                                </InputGroup>
                            </FormGroup>
                                 <FormGroup>
                                <Label for="organizationRole">Organization Role</Label>
                                <InputGroup className="input-group-alternative mb-3">
                                    <Input 
                                        id="organizationRole"
                                        name="organizationRole"
                                        className="form-control-alternative"
                                        disabled={disableEditing}
                                        type="text"
                                        value={organizationRole}
                                        onChange={(e)=> setOrganizationRole(e.target.value)}
                                        autoComplete="off"
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label for="taskTeamAssigned">Assign Task To Team<span className="required-asterix">*</span></Label>
                                <CreatableSelect
                                    id="taskTeamAssigned"
                                    className="form-control-alternative"
                                    components={animatedComponents}
                                    isDisabled={isLoading}
                                    isLoading={isLoading}
                                    onChange={(newValue) => setTeam(newValue)}
                                    onCreateOption={handleTeamCreation}
                                    createOptionPosition="first"
                                    formatCreateLabel={formatDropDownLabelTeam}
                                    options={teamsList}
                                    value={team}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="note">Note</Label>
                                <div className="comment-box-wrapper bg-white note-wrap">
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorStateChange}
                                    />
                                </div>
                            </FormGroup>
                            <UncontrolledAlert
                                color="danger"
                                isOpen={errorVisible} 
                                toggle={onToggleError}
                            >
                                <span className="alert-inner--text">
                                    <strong>Error!</strong> {errorMessage}
                                </span>
                            </UncontrolledAlert>
                        </CardBody>
                    </Card>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={() => props.closeModal(false)}>
						Cancel
					</Button>
					<Button color="primary" type="submit">
						Save
					</Button>{" "}
				</ModalFooter>
			</Form>
		</Modal>
	);
}
