import React from "react";

/**
 * Formats the task for dropdown
 * @param {Object} task 
 */
const formatStatusForDropdown = (statusData) => {
    if (statusData.id && statusData.status) {
        return {
            value: statusData.id,
            label: statusData.status
        };
    }
}

const formatStatusForDropdownBulk = (statusData) => {
    return statusData.length > 0 ? statusData.map(data => {
        return {
            value: data.id,
            label: data.status
        };
    }) : [];
}

const formatTypeForDropdown = (typeData) => {
    if (typeData.id && typeData.type) {
        return {
            value: typeData.id,
            label: typeData.type
        };
    }
}

const formatTypeForDropdownBulk = (typeData) => {
    return typeData.length > 0 ? typeData.map(data => {
        return {
            value: data.id,
            label: data.type
        };
    }) : [];
}

const formatRehearsalType = (typeData) => {
    return typeData ? typeData.type : null;
}

const formatRehearsalStatus = (statusData) => {
    return statusData ? statusData.status : null;
}

const populateDefaultEnvironmentAdd = [
    {
        environmentName: '',
        environmentLink: '',
        environmentOwner: {},
        openEnvironmentOwnerMenu: false,
        added: false
    }
];

const RehearsalUtil = {
    formatStatusForDropdown,
    formatTypeForDropdown,
    formatTypeForDropdownBulk,
    formatStatusForDropdownBulk,
    formatRehearsalType,
    formatRehearsalStatus,
    populateDefaultEnvironmentAdd
};

export default RehearsalUtil;