import React from "react";
import { Link } from "react-router-dom";
import ENUMS from "constants/appEnums";
import DateTimeUtils from "./DateTimeUtils";

const formatProjectName = (projectId, projectName) => {
    return <Link
        to={{
        pathname: '/admin/projects/projects-view',
        search: '?projectId='+projectId,
        state: { passedId: projectId }
        }}
    >
        {projectName}
    </Link>
}

const convertTaskDurationAsPerFormat = (taskDuration, defaultDurationFormat, timeDifference) => {
    if ((defaultDurationFormat === ENUMS.DURATION_FORMAT_VALUES.DAY) && Number(taskDuration)) {
        return (Number(taskDuration)/timeDifference);
    } else {
        return (Number(taskDuration)*timeDifference);
    }
}

const convertTaskDurationForUI = (taskDurationInHours, defaultDurationFormat, timeDifference) => {
    if ((defaultDurationFormat === ENUMS.DURATION_FORMAT_VALUES.DAY) && Number(taskDurationInHours)) {
        return (Number(taskDurationInHours)/timeDifference);
    } else {
        return taskDurationInHours;
    }
}

const convertTaskDurationToHoursForDB = (taskDuration, defaultDurationFormat, timeDifference) => {
    if ((defaultDurationFormat === ENUMS.DURATION_FORMAT_VALUES.DAY) && Number(taskDuration)) {
        return (Number(taskDuration)*timeDifference);
    } else {
        return taskDuration;
    }
}
    
const findTimeDifference = (calendar) => {
    return DateTimeUtils.findTimeDifferenceInHours(calendar.working_start_time, calendar.working_end_time);
}

const twoFixedPlaces = (number) => {
    return Math.round(Number(number) * 100) / 100;
}

const getNumberOfOpenActions = (actionList) => {

    return actionList.length < 1
        ? 0
        : actionList.filter(action => {
            return (action.status != ENUMS.TASK_STATUS.SC)
            && (action.status != ENUMS.TASK_STATUS.FC)
            && (action.status != ENUMS.TASK_STATUS.FT);
        }).length;
}

const getNumberOfOpenIssues = (issuesList) => {
    return issuesList.length < 1
        ? 0
        : issuesList.filter(issues => (
            (issues.status == ENUMS.RAID_STATUS.OPEN)
        )).length;
}

const getNumberOfOpenRisks = (riskList) => {
    return riskList.length < 1
        ? 0
        : riskList.filter(risk => (
            (risk.status == ENUMS.RAID_STATUS.OPEN)
        )).length;
}

const ProjectUtil = {
    formatProjectName,
    convertTaskDurationAsPerFormat,
    convertTaskDurationForUI,
    convertTaskDurationToHoursForDB,
    findTimeDifference,
    getNumberOfOpenActions,
    getNumberOfOpenIssues,
    getNumberOfOpenRisks,
    twoFixedPlaces
}

export default ProjectUtil;