import React, { useState, useEffect } from "react";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader, 
    CardBody,
    Button
} from "reactstrap";
import ENUMS from "constants/appEnums";
import CustomTablePrime from "components/CustomTable/CustomTablePrime";
import TeamAddEditViewModal from "components/Team/TeamAddEditViewModal/TeamAddEditViewModal";
import UserUtil from 'util/UserUtil';
import TableHeadersUtil from "util/TableHeadersUtil";
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import { useToasts } from 'react-toast-notifications';
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import RULES from "rbac/rules";
import APP_CONSTANTS from "constants/appConstants";

export default function ProjectViewTeamsTable (props) {

    const { addToast } = useToasts();

    // Team Data and Header for Table
    const [teamList, setTeamList] = useState([]);
    const [teamListHeader, setTeamListHeader] = useState([]);

    // Table props
    const [loading, setLoading] = useState(true);
    const [tableRef, setTableRef] = useState(null);
    
    /**
     * Delete Modal
     */
    const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(false);
    const [deleteTeamModalText, setDeleteTeamModalText] = useState("");
    const [selectedTeamId, setSelectedTeamId] = useState("");


    /**
     * Add/Edit Modal
     */
    const [addEditViewTeamModalOpen, setAddEditViewTeamModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalButtonName, setModalButtonName] = useState("");
    const [modalMode, setModalMode] = useState("");
    const [viewEditTeamId, setViewEditTeamId] = useState(null);

    const populateTeamTable = () => {
        populateTeamHeaders();
        if (props.projectTeamsList && 
            props.projectTeamsList.length > 0) {
            populateTeamData();
        }
    }

    const populateTeamHeaders = () => {
        /**
         * Columns object to be passed to table
         */
        setTeamListHeader(TableHeadersUtil.projectViewTeamTableHeaders);
    }

    const populateTeamData = () => {
        const updatedTeam = props.projectTeamsList.map((team, index) => {
            team['team_number'] = index + 1;
            team['team_leader_name'] = UserUtil.formatUserForTableColumn(team.TeamLeader);
            return team;
        });
        setTeamList(updatedTeam);
    }

    const openAddTeamModal = () => {
        setModalTitle(ENUMS.MODAL.ADD_TEAM_TITLE);
        setModalButtonName(ENUMS.BUTTON_TEXT.ADD);
        setModalMode(ENUMS.MODAL.ADD_MODE);
        setAddEditViewTeamModalOpen(true);
    }

    const closeAddEditViewModal = (e) => {
        setAddEditViewTeamModalOpen(false);
    };

    const refreshPageOnSubmit = (message) => {
        setAddEditViewTeamModalOpen(false);
        setShowDeleteTeamModal(false);
        props.refreshViewOnAction(ENUMS.TABS.TEAMS_LIST, message);
    }

    const openViewTeamModal = (data) => {
        setModalTitle(ENUMS.MODAL.VIEW_TEAM_TITLE);
        setModalButtonName(ENUMS.BUTTON_TEXT.ADD);
        setModalMode(ENUMS.MODAL.VIEW_MODE);
        setViewEditTeamId(data.id);
        setAddEditViewTeamModalOpen(true);
    }

    const openEditTeamModal = (data) => {
        setModalTitle(ENUMS.MODAL.EDIT_TEAM_TITLE);
        setModalButtonName(ENUMS.BUTTON_TEXT.SAVE);
        setModalMode(ENUMS.MODAL.EDIT_MODE);
        setViewEditTeamId(data.id);
        setAddEditViewTeamModalOpen(true);
    }

    const openCopyTeamModal = () => {
        setModalTitle(ENUMS.MODAL.COPY_TEAM_TITLE);
        setModalButtonName(ENUMS.BUTTON_TEXT.ADD);
        setModalMode(ENUMS.MODAL.COPY_MODE);
        setAddEditViewTeamModalOpen(true);
    }

    const hideDeleteTeamModal = () => {
        setShowDeleteTeamModal(false);
    }

    const deleteTeamConfirmed = (teamId) => {
        API.del(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TEAM_DELETE, 
        {
            ...HttpUtil.adminHttpHeaders(),
            body: {
                team_id: teamId
            }
        })
        .then(res => {
            if(res && res.status === 200) {
                refreshPageOnSubmit("Team has been deleted successfully");
            }
        })
        .catch(error => {
            if (error.response) {
                console.log(error.response);
                addToast(
                    "Failed to delete team.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const capitalizeText = (text) => {
        return "<span className='text-capitalize'>" + text + "</span>"
    }

    const openDeleteTeamModal = (team) => {
        setShowDeleteTeamModal(true);
        let deleteTeamModalText = "All team task and team member associations will be deleted. <br />"
        + ENUMS.MODAL.DELETE_CONFIRMATION_TEXT + capitalizeText(team.team_name) + " team ? <br /><br />";
        if (team.Tasks.length > 0) {
            deleteTeamModalText += capitalizeText(team.team_name) + " team is assigned to following tasks <br />"
            team.Tasks.map(task => {
                const taskDetail = task.task_number + " : " + capitalizeText(task.task_name) + "<br />";
                deleteTeamModalText += taskDetail;
            })
        }
        setDeleteTeamModalText(deleteTeamModalText);
        setSelectedTeamId(team.id);
        setModalMode(ENUMS.MODAL.EDIT_MODE);
    }

    const optionsList = React.useMemo(() => 
    [
        {
            itemName: 'View',
            itemType: 'View',
            openViewModal: openViewTeamModal,
        },
        {
            itemName: 'Edit',
            itemType: 'Edit',
            openEditModal: openEditTeamModal,
            role: [RULES.ROLE_NAMES.TEAM_LEADER, RULES.ROLE_NAMES.PROJECT_OWNER],
            perform: RULES.RULE_NAMES.TEAM_EDIT,
        },
        {
            itemName: 'Delete',
            itemType: ENUMS.RENDER_OPTIONS_TYPE.DELETE,
            delete: openDeleteTeamModal,
            role: [RULES.ROLE_NAMES.TEAM_LEADER, RULES.ROLE_NAMES.PROJECT_OWNER],
            perform: RULES.RULE_NAMES.TEAM_DELETE,
        }
    ], []);

    useEffect (() => {
        populateTeamTable();
        setLoading(false);
    }, [props.projectTeamsList]);

    return (
        <>
            <Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="bg-secondary box-shadow-down card-border-radius-0">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center">
                                <Col xs="6">
                                    <h3 className="mb-0">Team List</h3>
                                </Col>
                                <Col className="text-right">
                                    <Button
                                        color="secondary"
                                        onClick={openCopyTeamModal}
                                        size="md"
                                    >
                                        Copy Team
                                    </Button>
                                    <Button
                                        color="primary"
                                        onClick={openAddTeamModal}
                                        size="md"
                                    >
                                        Add Team
                                    </Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <CustomTablePrime
                                columnDefs={teamListHeader} 
                                rowData={teamList}
                                loading={loading}
                                optionsList={optionsList}
                                tableRef={tableRef}
                                setTableRef={setTableRef}
                                stateStorageName={ENUMS.DT_STATE_STORAGE_NAMES.PROJECT_VIEW_TEAM_TABLE +
                                    "-" + props.projectDetails.id}
                                projectOwnerId={props.projectDetails.project_owner_id}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            { 
                addEditViewTeamModalOpen && 
                <TeamAddEditViewModal 
                    modalTitle={modalTitle}
                    modalButtonName={modalButtonName}
                    modalMode={modalMode}
                    modalOpen={addEditViewTeamModalOpen} 
                    projectId={props.projectDetails.id}
                    viewEditTeamId={viewEditTeamId}
                    closeModal={closeAddEditViewModal}
                    refreshPageOnSubmit={refreshPageOnSubmit}
                /> 
            }
            {
                showDeleteTeamModal && 
                <ConfirmationModal
                    actionText={APP_CONSTANTS.DELETE_MODAL_ACTION_TEXTS}
                    onCancel={hideDeleteTeamModal}
                    onConfirm={deleteTeamConfirmed}
                    modalBody={deleteTeamModalText}
                    showModal={showDeleteTeamModal}
                    modalTitle={ENUMS.MODAL.DELETE_TEAM_TITLE}
                    data={selectedTeamId}
                />
            }
        </>
    )
}