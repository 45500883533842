import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, UncontrolledTooltip } from "reactstrap";
import "./ViewFileModal.css";
// import FileViewer from "react-file-viewer";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

export default function ViewFileModal(props) {
    
    const [fullScreenView, setFullScreenView] = useState(false);

    const onError = e => {
        console.log(e, "error in file-viewer");
    };

    useEffect(() => {
        console.log(props.docsToView)
    }, [])
    return (
        <Modal
            size="lg"
            isOpen={props.modalOpen}
            toggle={() => props.closeModal()}
            className={fullScreenView ? "fullscreen-modal" : ''}
            contentClassName={fullScreenView ? "fullscreen-modal-content" : ''}
            backdrop="static"
        >
            <ModalHeader toggle={() => props.closeModal()} tag="h2">
                {props.modalTitle}
                <a href={props.viewFileUrl} download={props.viewFileUrl} className="ml-3">
                    <i className="fas fa-cloud-download-alt" id="download-file"></i>
                    <UncontrolledTooltip
                        delay={0}
                        placement="right"
                        target="download-file"
                    >
                        Download file
                    </UncontrolledTooltip>
                </a>
                <span id="fullscreen" className="cursor-pointer ml-3">
                    {
                        fullScreenView 
                        ? <i className="fas fa-compress" onClick={() => setFullScreenView(false)}></i>
                        : <i className="fas fa-expand" onClick={() => setFullScreenView(true)}></i>
                    }
                </span>
                <UncontrolledTooltip
                    delay={0}
                    placement="right"
                    target="fullscreen"
                >
                    { fullScreenView ? 'Exit full screen' : 'View full screen' }
                </UncontrolledTooltip>
            </ModalHeader>
                <ModalBody>
                    <div className={fullScreenView ? "fullscreen-content-wrap" : "content-wrap"}>
                        <DocViewer className={fullScreenView ? "fullscreen-content-wrap" : "content-wrap"} documents={props.docsToView} pluginRenderers={DocViewerRenderers} config={{header: {disableFileName: true}}}/>
                    </div>
                </ModalBody>
        </Modal>
    )
}
