import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { 
    Row, 
    Col,
    Container, 
    Card, 
    CardHeader, 
    CardBody,
    Button,
    UncontrolledTooltip,
    Media,
    Spinner
} from "reactstrap";
import CheckPermissions from "components/CheckPermissions/CheckPermissions";
import RULES from "rbac/rules";
import ENUMS from "constants/appEnums";
import HttpUtil from "util/HttpUtil";
import { useSelector } from "react-redux";
import Loader from "components/Loader/Loader";
import { API } from "aws-amplify";
import UserUtil from "util/UserUtil";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import parse from 'html-react-parser';
import { format } from "date-fns";
import DateTimeUtils from "util/DateTimeUtils";
import ExecutionModeViewHeader from "./ExecutionModeViewHeader";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

export default function ExecutionModeView (props) {
    const location = useLocation();
    const { addToast } = useToasts();

    const [rehearsalDetails, setRehearsalDetails] = useState();
    const [isRehearsalDetailLoading, setIsRehearsalDetailLoading] = useState(true);

    const [projectId, setProjectId] = useState("");

    const [editModalOpen, setEditModalOpen] = useState(true);

    // fetch user project lists
    const projectList = useSelector(state => state.authReducer.userProjectList);
    //User props
    const current_user = useSelector(state => state.authReducer.user);

    //Editor props
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [editingEditorState, setEditingEditorState] = useState(EditorState.createEmpty())
    
    const [showEditor, setShowEditor] = useState(false);
    const [isNotesLoading, setIsNotesLoading] = useState(false);
    const [notesLoading, setNotesLoading] = useState(true);
    const  [showAllNotes, setShowAllNotes] = useState(false);

    //Note props
    const [showDeleteNoteModal, setShowDeleteNoteModal] = useState(false);
    const [rehearsalNotes, setRehearsalNotes] = useState([]);
    const [deleteNoteModalText, setDeleteNoteModalText] = useState('');
    const [selectedNoteId, setSelectedNoteId] = useState(null);
    const [userImageSrc, setUserImageSrc] = useState();

    /**
     * Get rehearsal details by Id
     * @param {Number} rehearsalIdSearch 
     */
    const fetchExecutionModeById = (rehearsalIdSearch) => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.REHEARSAL_GET_BY_ID,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                rehearsal_id: rehearsalIdSearch
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                const rehearsalDetailsData = {
                    ...res.data.rehearsal
                }
                setRehearsalDetails(rehearsalDetailsData);

                const allRehearsalNotes = rehearsalDetailsData.Rehearsal_Notes;
                const fetchUserImages = async () => {
                    return Promise.all(rehearsalDetailsData.Rehearsal_Notes.map(note => getUserImageAsync(note.User)))
                }
    
                fetchUserImages().then(allResponses => {
                    allResponses.map((res, index) => {
                        allRehearsalNotes[index].userImageURL = res.data.signedRequest;
                    })
                    
                    setRehearsalNotes(allRehearsalNotes);
                    setNotesLoading(false);
                });

                setIsRehearsalDetailLoading(false);
            }
        })
        .catch(error => {
            setIsRehearsalDetailLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch Rehearsal data. Please try again.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        });
    }
    
    const getRehearsalDetails = () => {
        if (location.state && location.state.passedId) {
            fetchExecutionModeById(location.state.passedId);
        }
        else if (location.search) {
            const params = new URLSearchParams(location.search);
            const rehearsalIdSearch = params.get('rehearsalId');
            fetchExecutionModeById(rehearsalIdSearch);
        }
    }

    const onClickEditButtonHandler = () => {
        setEditModalOpen(true);
    }
    
    /** 
     * NOTES DATA MEMEBERS 
     */

     /**
      * On change of the new note editor
      * 
      * @param {object} editorState 
      */
      const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    }

    /**
     * on change of editing on the existing note editor
     * 
     * @param {object} editingEditorState 
     */
    const onEditingEditorStateChange = (editingEditorState) => {
        setEditingEditorState(editingEditorState);
    }

    /**
     * handler for save note button
     * 
     * @param {object} note 
     */
    const saveNoteHandler = (note = null) => {
        const noteHTMLStringContent = draftToHtml(convertToRaw(
            note ? editingEditorState.getCurrentContent() : editorState.getCurrentContent()
        ));
        if (noteHTMLStringContent === "<p></p>") {
            return;
        }
        if(note) {
            updateNote(note, noteHTMLStringContent)
        } else {
            createNote(noteHTMLStringContent);
        }
    }
    
    /**
     * API call to create a new note on rehearsal
     * 
     * @param {string} noteContent 
     */
    const createNote = (noteContent) => {
        setIsNotesLoading(true);
        API
        .post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.REHEARSAL_NOTES_ADD, 
            {
                ...HttpUtil.adminHttpHeaders(),
                body: 
                {
                    rehearsal_id: rehearsalDetails.id,
                    user_id: current_user.id,
                    note: noteContent
                },
            })
            .then(res => {
                if (res.status === 201 && res.data.success) {
                    setIsNotesLoading(false)
                    getRehearsalDetails();
                    setShowEditor(false);
                    setEditorState(EditorState.createEmpty())
                }
            })
            .catch(error => {
                setIsNotesLoading(false);
                if (error.response) {
                    console.log(error.response);
                    addToast(
                        "Failed to add note.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                    getRehearsalDetails();
                }
            })
    }

    /**
     * API call to update existing note on rehearsal
     * 
     * @param {object} note 
     * @param {string} noteContent 
     */
    const updateNote = (note, noteContent) => {
        setIsNotesLoading(true);
        API
        .put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.REHEARSAL_NOTES_UPDATE, 
            {
                ...HttpUtil.adminHttpHeaders(),
                body: 
                {
                    id: note.id,
                    rehearsal_id: rehearsalDetails.id,
                    user_id: current_user.id,
                    note: noteContent
                },
            })
            .then(res => {
                if (res.status === 200 && res.data.success) {
                    setIsNotesLoading(false)
                    getRehearsalDetails();
                    setEditingEditorState(EditorState.createEmpty())
                }
            })
            .catch(error => {
                setIsNotesLoading(false);
                if (error.response) {
                    console.log(error.response);
                    addToast(
                        "Failed to update note.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                    getRehearsalDetails();
                }
            })
    }

    /**
     * handler for cancel button of the editor
     * 
     * @param {object} note 
     */
    const cancelEditing = (note = null) => {
        if (note) {
            setRehearsalNotes([...rehearsalNotes].map(noteItem => {
                if(noteItem.id === note.id) {
                    return {
                        ...noteItem,
                        editing: false,
                    }
                }
                else return noteItem;
            }))
        } else {
            setShowEditor(false);
        }
    }

    /**
     * handler for edit button of editor
     * to open up the editor and load the note contents
     * 
     * @param {object} noteObj 
     * @param {integer} key 
     */
    const editNoteHandler = (noteObj, key) => {
        const contentBlock = htmlToDraft(noteObj.note);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditingEditorState(editorState);
        }
        setRehearsalNotes([...rehearsalNotes].map(noteItem => {
            if(noteItem.id === noteObj.id) {
              return {
                ...noteItem,
                editing: true,
              }
            }
            else return noteItem;
          }))
    }

    /**
     * show delete note modal
     */
    const deleteNoteModalHandler = (note) => {
        setShowDeleteNoteModal(true);
        setDeleteNoteModalText(ENUMS.MODAL.DELETE_CONFIRMATION_TEXT + " this note ?");
        setSelectedNoteId(note.id);
    }

    /**
     * hide delete note modal - on cancel
     */
    const hideDeleteNoteModal = () => {
        setShowDeleteNoteModal(false);
    }

    /**
     * API call to delete note
     * @param {object} note 
     */
    const deleteNoteConfirmed = (noteId) => {
        API
        .del(
            ENUMS.AWS.API_NAME,
            ENUMS.API_ROUTES.REHEARSAL_NOTES_DELETE,
            {
                ...HttpUtil.adminHttpHeaders(),
                body: {
                    note_id: noteId
                }
        
            }
        )
        .then(res => {
            if(res && res.status === 200) {
                addToast('Rehearsal note has been deleted successfully!', { 
                    appearance: 'success',
                    autoDismiss: true
                });
                getRehearsalDetails();
                setShowDeleteNoteModal(false);
            }
        })
        .catch(error => {
            if (error.response) {
                console.log(error.response);
                addToast(
                    "Failed to delete rehearsal note", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const getUserImageAsync = async (user) => {
        return await API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.S3_DOWNLOAD_SIGNED_URL, 
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                fileId: user.id,
                getFrom: 'profile-pictures'
            },
        });
    }

    const handleError = (err) => {
        if (err.response) {
            const content = (
                <div>
                    <strong>Failed to generate presigned url.</strong>
                    <div>
                        {err.response.data && err.response.data.message}
                    </div>
                </div>
            )
            addToast(
                content,
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
        }
    }

    // useEffect(() => {
    //     // fetch profile pic for all uses present in notes
    //     if(rehearsalDetails) {
    //         const allRehearsalNotes = rehearsalDetails.Rehearsal_Notes;
    //         const fetchUserImages = async () => {
    //             return Promise.all(rehearsalDetails.Rehearsal_Notes.map(note => getUserImageAsync(note.User)))
    //         }

    //         fetchUserImages().then(allResponses => {
    //             allResponses.map((res, index) => {
    //                 allRehearsalNotes[index].userImageURL = res.data.signedRequest;
    //             });
    //             setRehearsalNotes(allRehearsalNotes);
    //         });
    //     }
    // }, [rehearsalDetails]);

    // useEffect(() => {
    //     if(current_user) {
    //         async function fetchUserImage() {
    //             try {
    //                 const res = await getUserImageAsync(current_user);
    //                 if (res.status === 200 && res.data && res.data.success) {
    //                     setUserImageSrc(res.data.signedRequest);
    //                 }
    //             } catch (err) {
    //                 handleError(err);
    //             }
    //         }
    //         fetchUserImage();
    //     }
    // }, [current_user]);

    useEffect (() => {
        getRehearsalDetails();
    }, []);
    
    return (
        <>
            {
                isRehearsalDetailLoading
                ? <Loader></Loader>
                :  <>
                { rehearsalDetails && 
                    <React.Fragment>
                        <ExecutionModeViewHeader
                            rehearsalDetails={rehearsalDetails}
                            currentUser={current_user}
                        />
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                            
                                        </CardHeader>
                                        <CardBody>
                                            <Card>
                                                <CardHeader className="cop-card-header">
                                                    Notes
                                                </CardHeader>
                                                {
                                                    notesLoading 
                                                        ? <div className="p-3"><Spinner size="sm" color="primary" /><span className="ml-3">Loading rehearsal notes..</span></div>
                                                        :  <CardBody>
                                                        <Row>
                                                            <Col>
                                                                {
                                                                    rehearsalNotes && rehearsalNotes.map((noteItem, key) => {
                                                                        return (
                                                                            <div key={key}>
                                                                                {
                                                                                    (showAllNotes || (key < 2))
                                                                                    ? <div className="note-item editnote note-wrap">
                                                                                        <div className="note-d-flex">
                                                                                            <div>
                                                                                                <div className="note-user">
                                                                                                    <Media className="align-items-center">
                                                                                                        {
                                                                                                            !noteItem.userImageURL
                                                                                                            ? <div className="avatar-circle">
                                                                                                                <span className="avatar-initials">
                                                                                                                {UserUtil.formatUserForInitials(noteItem.User)}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            : <img
                                                                                                                alt="..."
                                                                                                                src={noteItem.userImageURL}
                                                                                                                className="small-profile-pic"
                                                                                                            />
                                                                                                        }
                                                                                                    </Media>
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                noteItem.editing &&
                                                                                                <div>
                                                                                                    <div className="comment-box-wrapper edit">
                                                                                                        <Editor
                                                                                                            editorState={editingEditorState}
                                                                                                            toolbarClassName="toolbarClassName"
                                                                                                            wrapperClassName="wrapperClassName"
                                                                                                            editorClassName="editorClassName"
                                                                                                            onEditorStateChange={onEditingEditorStateChange}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        {
                                                                                                            isNotesLoading ?
                                                                                                            <Spinner size="sm" color="primary" /> :
                                                                                                            <Button
                                                                                                                size="sm"
                                                                                                                color="primary"
                                                                                                                onClick={e => saveNoteHandler(noteItem)}
                                                                                                            >
                                                                                                                Save
                                                                                                            </Button>
                                                                                                        }
                                                                                                        <Button
                                                                                                            size="sm"
                                                                                                            color="secondary"
                                                                                                            onClick={e => cancelEditing(noteItem)}
                                                                                                        >
                                                                                                            Cancel
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                !noteItem.editing &&
                                                                                                <div>
                                                                                                    <div>
                                                                                                        <span className="note-user-name">{noteItem.User.first_name} {noteItem.User.last_name}</span>
                                                                                                        <span className="note-created-date ml-2 mr-2">
                                                                                                            {
                                                                                                                format(
                                                                                                                    DateTimeUtils.convertTimezone(
                                                                                                                        new Date(noteItem.createdAt), current_user), 
                                                                                                                    DateTimeUtils.getUserDateTimeFormat(
                                                                                                                        current_user.date_format, current_user.twenty_four_hour_clock))
                                                                                                            }
                                                                                                        </span>
                                                                                                        <span className="note-updated-date">
                                                                                                            {
                                                                                                                !DateTimeUtils.compareBetweenDates(noteItem.createdAt, noteItem.updatedAt) 
                                                                                                                ? (
                                                                                                                    <span>
                                                                                                                        <span className="note-edited-tag" id="edited_tooltip">Edited</span>
                                                                                                                        <UncontrolledTooltip
                                                                                                                            delay={0}
                                                                                                                            placement="top"
                                                                                                                            target="edited_tooltip"
                                                                                                                        >
                                                                                                                            {format(
                                                                                                                                DateTimeUtils.convertTimezone(
                                                                                                                                    new Date(noteItem.updatedAt), current_user), 
                                                                                                                                DateTimeUtils.getUserDateTimeFormat(
                                                                                                                                    current_user.date_format, current_user.twenty_four_hour_clock))}
                                                                                                                        </UncontrolledTooltip>
                                                                                                                    </span>
                                                                                                                )
                                                                                                                : ''
                                                                                                            }
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div className="note-content">{parse(noteItem.note)}</div>
                                                                                                    {
                                                                                                        (noteItem.User.id == current_user.id) && 
                                                                                                        <div className="note-actions">
                                                                                                            <span className="note-action-button" onClick={e => editNoteHandler(noteItem, key)}>Edit</span>
                                                                                                            <span className="note-action-button" onClick={e => deleteNoteModalHandler(noteItem)}>Delete</span>
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    : <span></span>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    (!showAllNotes && (rehearsalNotes.length > 2)) &&
                                                                    <Button block color="secondary" onClick={() => setShowAllNotes(true)} style={{ marginBottom: '1rem', width: '40vw' }}>
                                                                        View older notes ({rehearsalNotes.length - 2} remaining)
                                                                    </Button>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>   
                                                                <div className="comment-section">
                                                                    <div>
                                                                        <div className="note-user">
                                                                            <Media className="align-items-center">
                                                                                {
                                                                                    !userImageSrc
                                                                                    ? <div className="avatar-circle">
                                                                                        <span className="avatar-initials">
                                                                                        {UserUtil.formatUserForInitials(current_user)}
                                                                                        </span>
                                                                                    </div>
                                                                                    : <img
                                                                                        alt="..."
                                                                                        src={userImageSrc}
                                                                                        className="small-profile-pic"
                                                                                    />
                                                                                }
                                                                            </Media>
                                                                            <div className="note-user-name">{UserUtil.formatUserForTableColumn(current_user)}</div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        !showEditor &&
                                                                        <div className="add-notes-box" onClick={(e) => setShowEditor(true)}>Add a note...</div>
                                                                    }
                                                                    {
                                                                        showEditor &&
                                                                        <div>
                                                                            <div className="comment-box-wrapper note-wrap">
                                                                                <Editor
                                                                                    editorState={editorState}
                                                                                    toolbarClassName="toolbarClassName"
                                                                                    wrapperClassName="wrapperClassName"
                                                                                    editorClassName="editorClassName"
                                                                                    onEditorStateChange={onEditorStateChange}
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                {
                                                                                    isNotesLoading ?
                                                                                    <Spinner size="sm" color="primary" /> :
                                                                                    <Button
                                                                                        size="sm"
                                                                                        color="primary"
                                                                                        onClick={e => saveNoteHandler()}
                                                                                    >
                                                                                        Save
                                                                                    </Button>
                                                                                }
                                                                                <Button
                                                                                    size="sm"
                                                                                    color="secondary"
                                                                                    onClick={e => cancelEditing()}
                                                                                >
                                                                                    Cancel
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                }
                                            </Card>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </React.Fragment>
                    // <CheckPermissions
                    //     role={[RULES.ROLE_NAMES.PROJECT_MEMBER]}
                    //     perform={RULES.RULE_NAMES.PROJECT_VIEW}
                    //     data={[{projectId: projectId, projectList: projectList}]}
                    //     yes={() => {
                    //         return 
                    //     }}
                    //     no={() => {
                    //         return <Redirect to="/"/>
                    //     }}
                    // />
                }
                {
                    showDeleteNoteModal && 
                    <ConfirmationModal
                        actionText={{
                            'confirm': ENUMS.BUTTON_TEXT.DELETE_ACTION_TEXT_CONFIRM,
                            'cancel': ENUMS.BUTTON_TEXT.CANCEL
                        }}
                        onCancel={hideDeleteNoteModal}
                        onConfirm={deleteNoteConfirmed}
                        modalBody={deleteNoteModalText}
                        showModal={showDeleteNoteModal}
                        modalTitle={ENUMS.MODAL.DELETE_NOTE_TITLE}
                        data={selectedNoteId}
                    />
                }
                </>       
            }
        </>
    )
}