import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import ENUMS from 'constants/appEnums';
import { useToasts } from 'react-toast-notifications';
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import { useForm } from 'react-hook-form';
import CustomErrorMessage from 'components/CustomErrorMessage/CustomErrorMessage';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Spinner
} from "reactstrap";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import AuthNavbar from "components/Navbars/AuthNavbar";

/**
 * Function to render the view for resetting the password for the user from the login page.
 */
export default function ResetPasswordRequest () {

    let history = useHistory();
    const { addToast } = useToasts();
    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur"
    });
    const userNameFocusRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    /**
     * Method to handle resetting the password.
     * @param {FormData} data 
     * @param {Event} e 
     */
    const handleResetPassword = (data, e) => {
        e.preventDefault();
        setIsLoading(true);
        API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.AUTH_RESET_PASSWORD,
        {
            ...HttpUtil.authHttpHeaders,
            body: {
                email : username.toLowerCase(), 
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                addToast(res.data.message, { 
                    appearance: 'success',
                    autoDismiss: true
                });
                setEmailSent(true);
            }
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                addToast(
                    error.response.data.message ? error.response.data.message : "Invalid email address.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    };

    /**
	 * Redirect to Login
	 */
	const redirectToLogin = () => {
		history.push("/auth/login");
	};

    useEffect(() => {
        if (userNameFocusRef.current) {
            userNameFocusRef.current.focus();
        } 
    }, [userNameFocusRef.current]);

    return (
        <>
            <AuthNavbar />
            <Container>
                <div className="header-body text-center mb-7">
                    <Row className="justify-content-center">
                    </Row>
                </div>
            </Container>
            <Container className="mt-5 pb-5">
                <Row className="justify-content-center">
                    <Col lg="5" md="7">
                        <Card className="bg-secondary shadow border-0">
                            {
                                !emailSent &&
                                <CardHeader className="bg-transparent pb-2">
                                    <div className="text-muted text-center mt-1">
                                        <h2>Reset Password</h2>
                                    </div>
                                    <div className="text-muted text-center mt-2 mb-4">
                                        Already have an account?
                                        <span className="ml-2">
                                            <a
                                                className="text-primary"
                                                href="#pablo"
                                                onClick={() => redirectToLogin()}
                                            >
                                                Login
                                            </a>
                                        </span>
                                    </div>
                                </CardHeader>
                            }
                            
                            <CardBody className="px-lg-5 py-lg-5">
                            {
                                emailSent
                                ? <> 
                                    <div className="text-muted text-center mt-1">
                                        Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.
                                    </div>
                                    <div className="text-center">
                                    <Button className="my-4" color="primary" onClick={() => redirectToLogin()}>
                                        Return to sign in
                                    </Button>
                                    </div>
                                </>
                                : <>
                                    <div className="text-muted text-center mt-1">
                                        <p>Enter your registered email address and a link will be sent to your email for resetting your password.</p>
                                    </div>
                                    
                                    <Form role="form" onSubmit={handleSubmit(handleResetPassword)}>
                                        <FormGroup className="mb-3">
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-email-83" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input 
                                                    name="username" 
                                                    placeholder="Email" 
                                                    type="email"
                                                    onChange={(e)=>setUsername(e.target.value)} 
                                                    autoComplete="off"
                                                    innerRef={(e) => {
                                                        userNameFocusRef.current = e;
                                                        register(e,
                                                        {
                                                            required: "Email is required.",
                                                            pattern: {
                                                                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                                message: "Invalid pattern for email. Ex: example@example.com"
                                                            }
                                                        });
                                                    }}
                                                />
                                            </InputGroup>
                                            <CustomErrorMessage errors={errors} name="username" />
                                        </FormGroup>
                                        
                                        <div className="text-center">
                                            {isLoading ?
                                                <Spinner size="sm" color="primary" /> :
                                                <Button className="my-4" color="primary" type="submit">
                                                    Send password reset email
                                                </Button>
                                            }
                                        </div>
                                    </Form>
                                </>
                            }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

