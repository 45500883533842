import { SET_USER_STATE, USER_LOGGED_OUT, SET_USER_PROJECT_LIST } from 'redux/actionsTypes';

const setUserState = (user) => ({
    type: SET_USER_STATE,
    payload: {
        user
    }
})

const userLoggedOut = () => ({
    type: USER_LOGGED_OUT
})

const setUserProjectList = (projectList) => ({
    type: SET_USER_PROJECT_LIST,
    payload: {
        projectList
    }
})

export default {
    setUserState,
    userLoggedOut,
    setUserProjectList
}