import React, { useEffect } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
    Col,
    Row
} from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import Card from "reactstrap/lib/Card";
import parse from 'html-react-parser';

export default function ViewStakeholderModal(props) {
    
	return (
		<Modal
			size="md"
			isOpen={props.modalOpen}
			toggle={() => props.closeModal(false)}
            backdrop="static"
		>
			<ModalHeader toggle={() => props.closeModal(false)} tag="h3">
				{props.modalTitle}
			</ModalHeader>
            <ModalBody className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                    <CardBody>
                        <Col>
                            <Row className="mb-3">
                                <Col xs="6">
                                    <div><strong>Name</strong></div>
                                    <div>{props.stakeholderData.Stakeholder.first_name} {props.stakeholderData.Stakeholder.last_name}</div>
                                </Col>
                                <Col xs="6">
                                    <div><strong>Email</strong></div>
                                    <div>{props.stakeholderData.Stakeholder.email}</div>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="mb-3">
                                <Col xs="6">
                                    <div><strong>Role</strong></div>
                                    <div>{props.stakeholderData.Stakeholder.role}</div>
                                </Col>
                                <Col xs="6">
                                    <div><strong>Team</strong></div>
                                    <div>{props.stakeholderData.team_name}</div>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="mb-3">
                                <Col xs="12">
                                    <div><strong>Note</strong></div>
                                    <div>{parse(props.stakeholderData.note)}</div>
                                </Col>
                            </Row>
                            <hr />
                        </Col>
                    </CardBody>
                </Card>
            </ModalBody>
		</Modal>
	);
}
