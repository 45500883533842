import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ENUMS from 'constants/appEnums';
import { useToasts } from 'react-toast-notifications';
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import ResetPassword from "components/User/ResetPassword/ResetPassword";

/**
 * Function rendered when user clicks on the reset password link from email
 * @param {Props} props 
 */
export default function ResetPasswordConfirm (props) {
	let history = useHistory();
    const { addToast } = useToasts();

    // state variables
	const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [userToken, setUserToken] = useState("");
	
	useEffect(() => {
        // extract the reset token from link
        const params = new URLSearchParams(props.location.search);
        setUserToken(params.get('reset-token'));
	}, []);

	/**
	 * Redirect to Login
	 */
	const redirectToLogin = () => {
		history.push("/auth/login");
	};

	/**
	 * Call to reset password for user
	 *
	 * @param {object} e
	 */
	const handleResetPassword = (data, e) => {
		e.preventDefault();
		API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.AUTH_CONFIRM_RESET_PASSWORD,
        {
            ...HttpUtil.authHttpHeaders,
            body: 
            {
                userToken: userToken,
                newPassword: newPassword
            }
        })
        .then((res) => {
            if (res.status === 200 && res.data.success) {
                addToast(res.data.message, { 
                    appearance: 'success',
                    autoDismiss: true
                })
                redirectToLogin();
            } else {
                addToast("There was some issue while resetting the password. Please try again!", { 
                    appearance: 'error',
                    autoDismiss: true
                });
            }
        })
        .catch((error) => {
            console.log(error)
            addToast("The link to reset password has expired", { 
                appearance: 'error',
                autoDismiss: true
            });
        });
	};

	return (
		<>
            <ResetPassword 
                setNewPassword={setNewPassword}
                setConfirmNewPassword={setConfirmNewPassword}
                handleReset={handleResetPassword}
                changePasswordForm={true}
                buttonText="Reset Password"
                organizationDefault={{}} //hot-fix
            />
		</>
	);
}