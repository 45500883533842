import React, { useState, useEffect } from "react";
import TaskViewHeader from "components/Headers/TaskViewHeader";
import { Redirect, useLocation } from "react-router-dom";
import { 
    Row, 
    Col, 
    Collapse,
    Container, 
    Card, 
    CardHeader, 
    CardBody,
    Button,
    Spinner,
    UncontrolledTooltip,
    Media
} from "reactstrap";
import { API } from "aws-amplify";
import ENUMS from "constants/appEnums";
import { useToasts } from 'react-toast-notifications';
import HttpUtil from "util/HttpUtil";
import "./TaskView.css";
import UserUtil from "util/UserUtil";
import DateTimeUtils from "util/DateTimeUtils";
import TableHeadersUtil from "util/TableHeadersUtil";
import CustomTablePrime from "components/CustomTable/CustomTablePrime";
import TeamAddEditViewModal from "components/Team/TeamAddEditViewModal/TeamAddEditViewModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import TaskExceptionAddModal from "../TaskExceptionAddModal/TaskExceptionAddModal";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { useSelector } from "react-redux";
import htmlToDraft from 'html-to-draftjs';
import parse from 'html-react-parser';
import { format } from "date-fns";
import CheckPermissions from "components/CheckPermissions/CheckPermissions";
import RULES from "rbac/rules";
import TaskAddEditModal from "../TaskAddEditModal/TaskAddEditModal";
import UploadModal from "components/UploadModal/UploadModal";
import FileListTable from "components/FileListTable/FileListTable";
import TaskUtil from "util/TaskUtil";
import AddEditActionModal from "components/Action/AddEditActionModal/AddEditActionModal";
import ViewActionModal from "components/Action/ViewActionModal";
import Loader from "components/Loader/Loader";

export default function TaskView (props) {

    const location = useLocation();
    const { addToast } = useToasts();
    //User props
    const current_user = useSelector(state => state.authReducer.user);

    const [userImageSrc, setUserImageSrc] = useState();

    //Editor props
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [editingEditorState, setEditingEditorState] = useState(EditorState.createEmpty())
    
    const [showEditor, setShowEditor] = useState(false);
    const [isNotesLoading, setIsNotesLoading] = useState(false);
    const [notesLoading, setNotesLoading] = useState(true);

    //Note props
    const [showDeleteNoteModal, setShowDeleteNoteModal] = useState(false);
    const [taskNotes, setTaskNotes] = useState([]);
    const [deleteNoteModalText, setDeleteNoteModalText] = useState('');
    const [selectedNoteId, setSelectedNoteId] = useState(null);
            
    //Task props
    const [taskDetails, setTaskDetails] = useState();
    const [taskHolidays, setTaskHolidays] = useState([]);

    //Task action
    const [actionList, setActionList] = useState([]);

    // Table props
    const [loading, setLoading] = useState(true);
    const [tableRef, setTableRef] = useState(null);
    const [userTableRef, setUserTableRef] = useState(null);
    const [exceptionTableRef, setExceptionTableRef] = useState(null);
    const [actionTableRef, setActionTableRef] = useState(null);
    
    
    //Toggle props
    const [isOpenTeams, setIsOpenTeams] = useState(false);
    const [isOpenUsers, setIsOpenUsers] = useState(false);
    const [isOpenHolidays, setIsOpenHolidays] = useState(false);
    const [isOpenFileList, setIsOpenFileList] = useState(false);
    const [isOpenActionList, setIsOpenActionList] = useState(false);
    
    const  [showAllNotes, setShowAllNotes] = useState(false);

    // fetch user project lists
    const projectList = useSelector(state => state.authReducer.userProjectList);

    //Task holiday props
    const [showAddTaskException, setShowAddTaskException] = useState(false);
    const [showDeleteTaskExceptionModal, setShowDeleteTaskExceptionModal] = useState(false);
    const [selectedTaskHoliday, setSelectedTaskHoliday] = useState(null);
    const [deleteTaskExceptionModalText, setDeleteTaskExceptionModalText] = useState("");
    const deleteModalActionTexts = {
        'confirm': ENUMS.BUTTON_TEXT.DELETE_ACTION_TEXT_CONFIRM,
        'cancel': ENUMS.BUTTON_TEXT.CANCEL
    };

    //Add Team Modal props
    const [showTeamAddEditModal, setShowTeamAddEditModal] = useState(false);
    const [viewEditTeamId, setViewEditTeamId] = useState(null);

    //Delete Team Modal props
    const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(false);
    const [deleteTeamModalText, setDeleteTeamModalText] = useState("");
    const [selectedTeamId, setSelectedTeamId] = useState(null);
    const deleteTeamModalActionTexts = {
        'confirm': ENUMS.BUTTON_TEXT.UASSIGN_ACTION_TEXT_CONFIRM,
        'cancel': ENUMS.BUTTON_TEXT.CANCEL
    };

    /**
     * Delete Action Modal
     */
    const [showDeleteActionModal, setShowDeleteActionModal] = useState(false);
    const [deleteActionModalText, setDeleteActionModalText] = useState("");
    const [selectedActionId, setSelectedActionId] = useState("");

    //Task Modal
    const [addEditTaskModalOpen, setAddEditTaskModalOpen] = useState(false);

    //Upload modal
    const [uploadModalOpen, setUploadModalOpen] = useState(false);

    /** ADD/EDIT Action modal */
    const [showAddEditActionModal, setShowAddEditActionModal] = useState(false);
    const [actionAddEditModalMode, setActionAddEditModalMode] = useState("");
    const [editActionId, setEditActionId] = useState(null);

    /**
     * View Modal
     */
    const [viewActionModalOpen, setViewActionModalOpen] = useState(false);
    const [viewActionModalTitle, setViewActionModalTitle] = useState("View Stakeholder");
    const [actionToView, setActionToView] = useState('');

    const [isTaskDetailLoading, setIsTaskDetailLoading] = useState(true);

    /** 
     * 
     * MEMBER FUNCTIONS STARTS
     * 
     */

    /**
     * Get task details by Id
     * @param {Number} taskIdSearch 
     */
    const fetchTaskById = (taskIdSearch) => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TASK_GET_TASK_BY_ID,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                taskId: taskIdSearch
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                const taskDetailsData = {
                    ...res.data.task,
                    Teams: res.data.task.Teams.map(team => {
                        return {
                            ...team,
                            team_leader_name: UserUtil.formatUserForTableColumn(team.TeamLeader)
                        }
                    })
                }
                setTaskDetails(taskDetailsData);
                const taskHolidaysData = taskDetailsData.Task_Holidays.map(holiday => {
                    return {
                        ...holiday,
                        start_date: format(DateTimeUtils.convertTimezone(
                            new Date(holiday.start_date), current_user), 
                            DateTimeUtils.getUserDateTimeFormat(current_user.date_format, current_user.twenty_four_hour_clock)),
                        end_date: format(DateTimeUtils.convertTimezone(
                            new Date(holiday.end_date), current_user), 
                            DateTimeUtils.getUserDateTimeFormat(current_user.date_format, current_user.twenty_four_hour_clock)),
                    }
                })
                setTaskHolidays(taskHolidaysData);
                
                const allTaskNotes = taskDetailsData.Task_Notes;
                const fetchUserImages = async () => {
                    return Promise.all(taskDetailsData.Task_Notes.map(note => getUserImageAsync(note.User)))
                }
    
                fetchUserImages().then(allResponses => {
                    allResponses.map((res, index) => {
                        allTaskNotes[index].userImageURL = res.data.signedRequest;
                    })
                    
                    setTaskNotes(allTaskNotes);
                    setNotesLoading(false);
                });
                const updatedActionList = taskDetailsData.Actions.map((action, index) => {
                    return {
                        ...action,
                        action_number: index + 1,
                        action_owner_name: UserUtil.formatUserForTableColumn(action.ActionOwner),
                        task: TaskUtil.formatTaskNumber(action.Task.id, action.Task.task_number),
                        action_status_view: TaskUtil.formatTaskStatusForTable(action.status),
                        due_date: format(DateTimeUtils.convertTimezone(new Date(action['due_date']), current_user), 
                            DateTimeUtils.getUserDateTimeFormat(current_user.date_format, current_user.twenty_four_hour_clock)),
                        created_at: format(DateTimeUtils.convertTimezone(new Date(action['createdAt']), current_user), 
                            DateTimeUtils.getUserDateTimeFormat(current_user.date_format, current_user.twenty_four_hour_clock))    
                    };
                });
                setActionList(updatedActionList);
                
                setLoading(false);
                setIsTaskDetailLoading(false);
            }
        })
        .catch(error => {
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch task data. Please try again.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        });
    }

    const getTaskDetails = () => {
        if (location.state && location.state.passedId) {
            fetchTaskById(location.state.passedId);
        }
        else if (location.search) {
            const params = new URLSearchParams(location.search);
            const taskIdSearch = params.get('taskId');
            fetchTaskById(taskIdSearch);
        }
    }

    /**
     * Method to handle opening of the task edit modal
     */
    const onClickEditButtonHandler = () => {
        setAddEditTaskModalOpen(true);
    }

    /**
     * Method to handle closing of the task modal
     */
    const closeAddEditModal = () => {
        setAddEditTaskModalOpen(false);
    };

    const refreshPageOnSubmit = (message) => {
        setShowAddEditActionModal(false);
        setAddEditTaskModalOpen(false);
        setShowTeamAddEditModal(false);
        setShowDeleteActionModal(false);
        setViewEditTeamId(null);
        getTaskDetails();
        addToast(message, { 
            appearance: 'success',
            autoDismiss: true
        });
    }

    /**
     * Method to open add edit action modal
     */
    const openAddActionModal = () => {
        setActionAddEditModalMode(ENUMS.MODAL.ADD_MODE);
        setShowAddEditActionModal(true);
    }

    /**
     * Method to close add edit action modal
     */
    const closeAddEditActionModal = () => {
        setShowAddEditActionModal(false);
    }

    /**
     * Method to handle opening the action modal in edit mode
     */
     const openEditActionModal = (action) => {
        setActionAddEditModalMode(ENUMS.MODAL.EDIT_MODE);
        setEditActionId(action.id);
        setShowAddEditActionModal(true);
    }

    const openViewActionModal = (data) => {
        setViewActionModalOpen(true);
        setActionToView(data);
    }

    const closeActionViewModal = () => {
        setViewActionModalOpen(false);
    }

    const openDeleteActionModal = (action) => {
        setShowDeleteActionModal(true);
        setDeleteActionModalText(ENUMS.MODAL.DELETE_CONFIRMATION_TEXT + action.description + " action ?");
        setSelectedActionId(action.id);
    }

    const hideDeleteActionModal = () => {
        setShowDeleteActionModal(false);
    }

    const openDeleteTaskExceptionConfirmationModal = (data) => {
        setShowDeleteTaskExceptionModal(true);
        setDeleteTaskExceptionModalText(ENUMS.MODAL.DELETE_CONFIRMATION_TEXT + data.holiday_name + "?");
        setSelectedTaskHoliday(data);
    }

    const hideDeleteTaskExceptionModal = () => {
        setShowDeleteTaskExceptionModal(false);
    }

    const deleteTaskExceptionConfirmed = (taskHoliday) => {
        API
        .del(
            ENUMS.AWS.API_NAME,
            ENUMS.API_ROUTES.TASK_HOLIDAY_DELETE,
            {
                ...HttpUtil.adminHttpHeaders(),
                body: {
                    task_holiday_id: taskHoliday.id,
                    projectId: taskDetails.project_id
                }
        
            }
        )
        .then(res => {
            if(res && res.status === 200) {
                addToast('Task calendar exception has been deleted successfully!', { 
                    appearance: 'success',
                    autoDismiss: true
                });
                getTaskDetails();
                hideDeleteTaskExceptionModal();
            }
        })
        .catch(error => {
            if (error.response) {
                console.log(error.response);
                addToast(
                    "Failed to delete task calendar exception", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const taskExceptionActionList =  React.useMemo(() => [
        {
            clickHandler: openDeleteTaskExceptionConfirmationModal,
            iconClass: "far fa-trash-alt text-danger"
        }
    ], []);

    const openEditModal = (data) => {
        setViewEditTeamId(data.id);
        setShowTeamAddEditModal(true);
    };

    const deleteActionConfirmed = (actionId) => {
        API.del(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.ACTION_DELETE, 
            {
                ...HttpUtil.adminHttpHeaders(),
                body:  {
                    actionId: actionId
                }
            })
            .then(res => {
                if(res && res.status === 200) {
                    refreshPageOnSubmit("Action has been deleted successfully");
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response);
                    addToast(
                        "Failed to delete action.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
    }

    const deleteTeamConfirmed = (teamId) => {
        API.del(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TASK_TEAM_USER_DELETE, 
            {
                ...HttpUtil.adminHttpHeaders(),
                body: {
                    team_id: teamId,
                    task_id: taskDetails.id
                }
            })
            .then(res => {
                if(res && res.status === 200) {
                    refreshPageOnSubmit("Team unassiged from task.");
                    setShowDeleteTeamModal(false);
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response);
                    addToast(
                        "Failed to delete team from task.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
    }

    const hideDeleteTeamModal = () => {
        setShowDeleteTeamModal(false);
    }

    /** 
     * NOTES DATA MEMEBERS 
     */

     /**
      * On change of the new note editor
      * 
      * @param {object} editorState 
      */
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    }

    /**
     * on change of editing on the existing note editor
     * 
     * @param {object} editingEditorState 
     */
    const onEditingEditorStateChange = (editingEditorState) => {
        setEditingEditorState(editingEditorState);
    }

    /**
     * handler for save note button
     * 
     * @param {object} note 
     */
    const saveNoteHandler = (note = null) => {
        const noteHTMLStringContent = draftToHtml(convertToRaw(
            note ? editingEditorState.getCurrentContent() : editorState.getCurrentContent()
        ));
        if (noteHTMLStringContent === "<p></p>") {
            return;
        }
        if(note) {
            updateNote(note, noteHTMLStringContent)
        } else {
            createNote(noteHTMLStringContent);
        }
    }
    
    /**
     * API call to create a new note on task
     * 
     * @param {string} noteContent 
     */
    const createNote = (noteContent) => {
        setIsNotesLoading(true);
        API
        .post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TASK_NOTES_ADD, 
            {
                ...HttpUtil.adminHttpHeaders(),
                body: 
                {
                    task_id: taskDetails.id,
                    user_id: current_user.id,
                    note: noteContent
                },
            })
            .then(res => {
                if (res.status === 201 && res.data.success) {
                    setIsNotesLoading(false)
                    getTaskDetails();
                    setShowEditor(false);
                    setEditorState(EditorState.createEmpty())
                }
            })
            .catch(error => {
                setIsNotesLoading(false);
                if (error.response) {
                    console.log(error.response);
                    addToast(
                        "Failed to add note.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                    getTaskDetails();
                }
            })
    }

    /**
     * API call to update existing note on task
     * 
     * @param {object} note 
     * @param {string} noteContent 
     */
    const updateNote = (note, noteContent) => {
        setIsNotesLoading(true);
        API
        .put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TASK_NOTES_UPDATE, 
            {
                ...HttpUtil.adminHttpHeaders(),
                body: 
                {
                    id: note.id,
                    task_id: taskDetails.id,
                    user_id: current_user.id,
                    note: noteContent
                },
            })
            .then(res => {
                if (res.status === 200 && res.data.success) {
                    setIsNotesLoading(false)
                    getTaskDetails();
                    setEditingEditorState(EditorState.createEmpty())
                }
            })
            .catch(error => {
                setIsNotesLoading(false);
                if (error.response) {
                    console.log(error.response);
                    addToast(
                        "Failed to update note.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                    getTaskDetails();
                }
            })
    }

    /**
     * handler for cancel button of the editor
     * 
     * @param {object} note 
     */
    const cancelEditing = (note = null) => {
        if (note) {
            setTaskNotes([...taskNotes].map(noteItem => {
                if(noteItem.id === note.id) {
                    return {
                        ...noteItem,
                        editing: false,
                    }
                }
                else return noteItem;
            }))
        } else {
            setShowEditor(false);
        }
    }

    /**
     * handler for edit button of editor
     * to open up the editor and load the note contents
     * 
     * @param {object} noteObj 
     * @param {integer} key 
     */
    const editNoteHandler = (noteObj, key) => {
        const contentBlock = htmlToDraft(noteObj.note);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditingEditorState(editorState);
        }
        setTaskNotes([...taskNotes].map(noteItem => {
            if(noteItem.id === noteObj.id) {
              return {
                ...noteItem,
                editing: true,
              }
            }
            else return noteItem;
          }))
    }

    /**
     * show delete note modal
     */
    const deleteNoteModalHandler = (note) => {
        setShowDeleteNoteModal(true);
        setDeleteNoteModalText(ENUMS.MODAL.DELETE_CONFIRMATION_TEXT + " this note ?");
        setSelectedNoteId(note.id);
    }

    /**
     * hide delete note modal - on cancel
     */
    const hideDeleteNoteModal = () => {
        setShowDeleteNoteModal(false);
    }

    const openUploadFileModal = () => {
        setUploadModalOpen(true);
    }

    const closeUploadModal = () => {
        setUploadModalOpen(false);
    }

    /**
     * API call to delete note
     * @param {object} note 
     */
    const deleteNoteConfirmed = (noteId) => {
        API
        .del(
            ENUMS.AWS.API_NAME,
            ENUMS.API_ROUTES.TASK_NOTES_DELETE,
            {
                ...HttpUtil.adminHttpHeaders(),
                body: {
                    note_id: noteId
                }
        
            }
        )
        .then(res => {
            if(res && res.status === 200) {
                addToast('Task note has been deleted successfully!', { 
                    appearance: 'success',
                    autoDismiss: true
                });
                getTaskDetails();
                setShowDeleteNoteModal(false);
            }
        })
        .catch(error => {
            if (error.response) {
                console.log(error.response);
                addToast(
                    "Failed to delete task note", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const deleteTeam = (team) => {
        setShowDeleteTeamModal(true);
        setDeleteTeamModalText(ENUMS.MODAL.UNASSIGN_CONFIRMATION_TEXT + team.team_name + " team from " + taskDetails.task_name + " task ?");
        setSelectedTeamId(team.id);
    };

    const teamsOptionsList = [
        {
            itemName: 'Edit',
            itemType: 'Edit',
            openEditModal: openEditModal,
            role: [RULES.ROLE_NAMES.TEAM_LEADER, RULES.ROLE_NAMES.PROJECT_OWNER],
            perform: RULES.RULE_NAMES.TEAM_EDIT,
        },
        {
            itemName: 'Unassign Team',
            itemType: ENUMS.RENDER_OPTIONS_TYPE.DELETE,
            delete: deleteTeam,
            role: [RULES.ROLE_NAMES.TEAM_LEADER, RULES.ROLE_NAMES.PROJECT_OWNER],
            perform: RULES.RULE_NAMES.TEAM_DELETE,
        }
    ];

    const closeAddEditTeamModal = (e) => {
        setShowTeamAddEditModal(false);
    };

    const getUserImageAsync = async (user) => {
        return await API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.S3_DOWNLOAD_SIGNED_URL, 
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                fileId: user.id,
                getFrom: 'profile-pictures'
            },
        });
    }

    const actionOptionsList = React.useMemo(() => 
    [
        {
            itemName: 'View',
            itemType: 'View',
            openViewModal: openViewActionModal,
        },
        {
            itemName: 'Edit',
            itemType: 'Edit',
            openEditModal: openEditActionModal,
        },
        {
            itemName: 'Delete',
            itemType: ENUMS.RENDER_OPTIONS_TYPE.DELETE,
            delete: openDeleteActionModal,
            // role: [RULES.ROLE_NAMES.TEAM_LEADER, RULES.ROLE_NAMES.PROJECT_OWNER],
            // perform: RULES.RULE_NAMES.FILE_DELETE,
        }
    ], []);

    useEffect (() => {
        getTaskDetails();
    }, []);

    const handleError = (err) => {
        if (err.response) {
            const content = (
                <div>
                    <strong>Failed to generate presigned url.</strong>
                    <div>
                        {err.response.data && err.response.data.message}
                    </div>
                </div>
            )
            addToast(
                content,
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
        }
    }

    useEffect(() => {
        // fetch profile pic for all uses present in notes
        if(taskDetails) {
            const allTaskNotes = taskDetails.Task_Notes;
            const fetchUserImages = async () => {
                return Promise.all(taskDetails.Task_Notes.map(note => getUserImageAsync(note.User)))
            }

            fetchUserImages().then(allResponses => {
                allResponses.map((res, index) => {
                    allTaskNotes[index].userImageURL = res.data.signedRequest;
                });
                setTaskNotes(allTaskNotes);
            });
        }
    }, [taskDetails]);

    useEffect(() => {
        if(current_user) {
            async function fetchUserImage() {
                try {
                    const res = await getUserImageAsync(current_user);
                    if (res.status === 200 && res.data && res.data.success) {
                        setUserImageSrc(res.data.signedRequest);
                    }
                } catch (err) {
                    handleError(err);
                }
            }
            fetchUserImage();
        }
    }, [current_user]);

    return (
        <>
            {
                isTaskDetailLoading
                ? <Loader></Loader>
                :  <>
                { taskDetails && 
                    <CheckPermissions
                        role={[RULES.ROLE_NAMES.PROJECT_MEMBER]}
                        perform={RULES.RULE_NAMES.PROJECT_VIEW}
                        data={[{projectId: taskDetails.project_id, projectList: projectList}]}
                        yes={() => {
                        return <React.Fragment>
                            <TaskViewHeader
                                taskDetails={taskDetails}
                                currentUser={current_user}
                            />
                            <Container className="mt--7" fluid>
                                <Row>
                                    <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <div className="col">
                                                {/* Project Calendars List */}
                                            </div>
                                            <div className="col text-right">
                                                <Button 
                                                    size="md"
                                                    color="secondary"
                                                    onClick={(e) => openUploadFileModal()}
                                                >
                                                    Upload File
                                                </Button>
                                                <CheckPermissions
                                                    role={[RULES.ROLE_NAMES.PROJECT_OWNER, RULES.ROLE_NAMES.TASK_OWNER]}
                                                    perform={RULES.RULE_NAMES.TASK_EDIT}
                                                    data={[
                                                        {
                                                            userId: current_user.id, 
                                                            roleId: taskDetails.Project.project_owner_id
                                                        },
                                                        {
                                                            userId: current_user.id,
                                                            roleId: taskDetails.task_owner
                                                        }]}
                                                    yes={() => {
                                                        return <Button 
                                                            className="float-right" 
                                                            color="primary" 
                                                            onClick={(e) => onClickEditButtonHandler()}
                                                        >
                                                            Edit Task
                                                        </Button>
                                                    }}
                                                    no={() => {
                                                        return <Button 
                                                            className="float-right" 
                                                            color="primary" 
                                                            onClick={(e) => onClickEditButtonHandler()}
                                                            disabled={true}
                                                        >
                                                            Edit Task
                                                        </Button>
                                                    }}
                                                />
                                            </div>
                                        </Row>
                                        </CardHeader>
                                        <CardBody>
                                        <Card className={isOpenTeams ? 'toggle-card-open' : ''}>
                                            <CardHeader className="toggle-card-header" onClick={() => setIsOpenTeams(!isOpenTeams)}>
                                                <div>
                                                    Teams
                                                </div>
                                            </CardHeader>
                                            <Collapse isOpen={isOpenTeams}>
                                                <CardBody>
                                                    <Row>
                                                        <Col className="mb-3">
                                                            <Button className="float-right" color="primary" onClick={() => setShowTeamAddEditModal(true)}>Add Team</Button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            {
                                                                (taskDetails) && 
                                                                <CustomTablePrime
                                                                    columnDefs={TableHeadersUtil.projectViewTeamTableHeaders.filter(header => header.field !== ENUMS.FIELDS.TEAM_DESCRIPTION)} 
                                                                    rowData={taskDetails.Teams}
                                                                    loading={loading}
                                                                    tableRef={tableRef}
                                                                    setTableRef={setTableRef}
                                                                    optionsList={teamsOptionsList}
                                                                    user={current_user}
                                                                    projectOwnerId={taskDetails.Project.project_owner_id}
                                                                />
                                                            }
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                        <Card className={isOpenUsers ? 'toggle-card-open' : ''}>
                                            <CardHeader className="toggle-card-header" onClick={() => setIsOpenUsers(!isOpenUsers)}>
                                                <div>
                                                    Users
                                                </div>
                                            </CardHeader>
                                            <Collapse isOpen={isOpenUsers}>
                                                <CardBody>
                                                    {
                                                        (taskDetails) && 
                                                        <CustomTablePrime
                                                            columnDefs={TableHeadersUtil.userTableHeaders} 
                                                            rowData={taskDetails.Users}
                                                            loading={loading}
                                                            noHeight={true}
                                                            tableRef={userTableRef}
                                                            setTableRef={setUserTableRef}
                                                        />
                                                    }
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                        <Card className={isOpenHolidays ? 'toggle-card-open' : ''}>
                                            <CardHeader className="toggle-card-header" onClick={() => setIsOpenHolidays(!isOpenHolidays)}>
                                                <div>
                                                    Task Calendar Exceptions
                                                </div>
                                            </CardHeader>
                                            <Collapse isOpen={isOpenHolidays}>
                                                <CardBody>
                                                    <Row>
                                                        <Col className="mb-3">
                                                            <Button className="float-right" color="primary" onClick={() => setShowAddTaskException(true)}>Add Task Calendar Exception</Button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            {
                                                                (taskDetails) &&
                                                                <CustomTablePrime
                                                                    columnDefs={TableHeadersUtil.taskExceptionHeaders}
                                                                    rowData={taskHolidays}
                                                                    loading={loading}
                                                                    noHeight={true}
                                                                    tableRef={exceptionTableRef}
                                                                    setTableRef={setExceptionTableRef}
                                                                    buttonList={taskExceptionActionList}
                                                                />
                                                            }
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                        <Card className={isOpenFileList ? 'toggle-card-open' : ''}>
                                            <CardHeader className="toggle-card-header" onClick={() => setIsOpenFileList(!isOpenFileList)}>
                                                <div>
                                                    Task Files
                                                </div>
                                            </CardHeader>
                                            <Collapse isOpen={isOpenFileList}>
                                                <CardBody>
                                                    <Row>
                                                        <Col>
                                                            {
                                                                (taskDetails) &&
                                                                <FileListTable
                                                                    allFileList={taskDetails.Task_Files}
                                                                    projectOwnerId={taskDetails.Project.project_owner_id}
                                                                    refreshViewOnAction={refreshPageOnSubmit}
                                                                    loadFrom='task'
                                                                    viewMode="task"
                                                                />
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            {
                                                                (taskDetails) &&
                                                                <FileListTable
                                                                    allFileList={taskDetails.Project_Files}
                                                                    projectOwnerId={taskDetails.Project.project_owner_id}
                                                                    refreshViewOnAction={refreshPageOnSubmit}
                                                                    loadFrom='project'
                                                                    viewMode="task"
                                                                />
                                                            }
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                        <Card className={isOpenActionList ? 'toggle-card-open' : ''}>
                                            <CardHeader className="toggle-card-header" onClick={() => setIsOpenActionList(!isOpenActionList)}>
                                                <div>
                                                    Task Actions
                                                </div>
                                            </CardHeader>
                                            <Collapse isOpen={isOpenActionList}>
                                                <CardBody>
                                                    <Row>
                                                        <Col className="mb-3">
                                                            <Button 
                                                                className="float-right"
                                                                size="md"
                                                                color="primary"
                                                                onClick={(e) => openAddActionModal()}
                                                            >
                                                                Add Action
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            {
                                                                (taskDetails) &&
                                                                <CustomTablePrime
                                                                    columnDefs={TableHeadersUtil.projectViewActionTableHeaders}
                                                                    rowData={actionList}
                                                                    loading={loading}
                                                                    noHeight={true}
                                                                    optionsList={actionOptionsList}
                                                                    tableRef={actionTableRef}
                                                                    setTableRef={setActionTableRef}
                                                                />
                                                            }
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                        <Card>
                                            <CardHeader className="cop-card-header">
                                                Notes
                                            </CardHeader>
                                            {
                                                notesLoading 
                                                    ? <div className="p-3"><Spinner size="sm" color="primary" /><span className="ml-3">Loading task notes..</span></div>
                                                    :  <CardBody>
                                                    <Row>
                                                        <Col>
                                                            {
                                                                taskNotes && taskNotes.map((noteItem, key) => {
                                                                    return (
                                                                        <div>
                                                                            {
                                                                                (showAllNotes || (key < 2))
                                                                                ? <div className="note-item editnote note-wrap" key={key}>
                                                                                    <div className="note-d-flex">
                                                                                        <div>
                                                                                            <div className="note-user">
                                                                                                <Media className="align-items-center">
                                                                                                    {
                                                                                                        !noteItem.userImageURL
                                                                                                        ? <div className="avatar-circle">
                                                                                                            <span className="avatar-initials">
                                                                                                            {UserUtil.formatUserForInitials(noteItem.User)}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        : <img
                                                                                                            alt="..."
                                                                                                            src={noteItem.userImageURL}
                                                                                                            className="small-profile-pic"
                                                                                                        />
                                                                                                    }
                                                                                                </Media>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            noteItem.editing &&
                                                                                            <div>
                                                                                                <div className="comment-box-wrapper edit">
                                                                                                    <Editor
                                                                                                        editorState={editingEditorState}
                                                                                                        toolbarClassName="toolbarClassName"
                                                                                                        wrapperClassName="wrapperClassName"
                                                                                                        editorClassName="editorClassName"
                                                                                                        onEditorStateChange={onEditingEditorStateChange}
                                                                                                    />
                                                                                                </div>
                                                                                                <div>
                                                                                                    {
                                                                                                        isNotesLoading ?
                                                                                                        <Spinner size="sm" color="primary" /> :
                                                                                                        <Button
                                                                                                            size="sm"
                                                                                                            color="primary"
                                                                                                            onClick={e => saveNoteHandler(noteItem)}
                                                                                                        >
                                                                                                            Save
                                                                                                        </Button>
                                                                                                    }
                                                                                                    <Button
                                                                                                        size="sm"
                                                                                                        color="secondary"
                                                                                                        onClick={e => cancelEditing(noteItem)}
                                                                                                    >
                                                                                                        Cancel
                                                                                                    </Button>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            !noteItem.editing &&
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span className="note-user-name">{noteItem.User.first_name} {noteItem.User.last_name}</span>
                                                                                                    <span className="note-created-date ml-2 mr-2">
                                                                                                        {
                                                                                                            format(
                                                                                                                DateTimeUtils.convertTimezone(
                                                                                                                    new Date(noteItem.createdAt), current_user), 
                                                                                                                DateTimeUtils.getUserDateTimeFormat(
                                                                                                                    current_user.date_format, current_user.twenty_four_hour_clock))
                                                                                                        }
                                                                                                    </span>
                                                                                                    <span className="note-updated-date">
                                                                                                        {
                                                                                                            !DateTimeUtils.compareBetweenDates(noteItem.createdAt, noteItem.updatedAt) 
                                                                                                            ? (
                                                                                                                <span>
                                                                                                                    <span className="note-edited-tag" id="edited_tooltip">Edited</span>
                                                                                                                    <UncontrolledTooltip
                                                                                                                        delay={0}
                                                                                                                        placement="top"
                                                                                                                        target="edited_tooltip"
                                                                                                                    >
                                                                                                                        {format(
                                                                                                                            DateTimeUtils.convertTimezone(
                                                                                                                                new Date(noteItem.updatedAt), current_user), 
                                                                                                                            DateTimeUtils.getUserDateTimeFormat(
                                                                                                                                current_user.date_format, current_user.twenty_four_hour_clock))}
                                                                                                                    </UncontrolledTooltip>
                                                                                                                </span>
                                                                                                            )
                                                                                                            : ''
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className="note-content">{parse(noteItem.note)}</div>
                                                                                                {
                                                                                                    (noteItem.User.id == current_user.id) && 
                                                                                                    <div className="note-actions">
                                                                                                        <span className="note-action-button" onClick={e => editNoteHandler(noteItem, key)}>Edit</span>
                                                                                                        <span className="note-action-button" onClick={e => deleteNoteModalHandler(noteItem)}>Delete</span>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                : <span></span>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                (!showAllNotes && (taskNotes.length > 2)) &&
                                                                <Button block color="secondary" onClick={() => setShowAllNotes(true)} style={{ marginBottom: '1rem', width: '40vw' }}>
                                                                    View older notes ({taskNotes.length - 2} remaining)
                                                                </Button>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>   
                                                            <div className="comment-section">
                                                                <div>
                                                                    <div className="note-user">
                                                                        <Media className="align-items-center">
                                                                            {
                                                                                !userImageSrc
                                                                                ? <div className="avatar-circle">
                                                                                    <span className="avatar-initials">
                                                                                    {UserUtil.formatUserForInitials(current_user)}
                                                                                    </span>
                                                                                </div>
                                                                                : <img
                                                                                    alt="..."
                                                                                    src={userImageSrc}
                                                                                    className="small-profile-pic"
                                                                                />
                                                                            }
                                                                        </Media>
                                                                        <div className="note-user-name">{UserUtil.formatUserForTableColumn(current_user)}</div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    !showEditor &&
                                                                    <div className="add-notes-box" onClick={(e) => setShowEditor(true)}>Add a note...</div>
                                                                }
                                                                {
                                                                    showEditor &&
                                                                    <div>
                                                                        <div className="comment-box-wrapper note-wrap">
                                                                            <Editor
                                                                                editorState={editorState}
                                                                                toolbarClassName="toolbarClassName"
                                                                                wrapperClassName="wrapperClassName"
                                                                                editorClassName="editorClassName"
                                                                                onEditorStateChange={onEditorStateChange}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                isNotesLoading ?
                                                                                <Spinner size="sm" color="primary" /> :
                                                                                <Button
                                                                                    size="sm"
                                                                                    color="primary"
                                                                                    onClick={e => saveNoteHandler()}
                                                                                >
                                                                                    Save
                                                                                </Button>
                                                                            }
                                                                            <Button
                                                                                size="sm"
                                                                                color="secondary"
                                                                                onClick={e => cancelEditing()}
                                                                            >
                                                                                Cancel
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            }
                                        </Card>
                                </CardBody>
                            </Card>
                            </Col>
                        </Row>
                    </Container>
                    {
                        showAddTaskException && 
                        <TaskExceptionAddModal
                            onCancel={() => setShowAddTaskException(false)}
                            showModal={showAddTaskException}
                            task={taskDetails}
                            refreshList={fetchTaskById}
                        />
                    }
                    {
                        showDeleteTaskExceptionModal && 
                        <ConfirmationModal
                            actionText={deleteModalActionTexts}
                            onCancel={hideDeleteTaskExceptionModal}
                            onConfirm={deleteTaskExceptionConfirmed}
                            modalBody={deleteTaskExceptionModalText}
                            showModal={showDeleteTaskExceptionModal}
                            modalTitle={ENUMS.MODAL.TASK_EXCEPTION_DELETE_MODAL_TITLE}
                            data={selectedTaskHoliday}
                        />
                    }
                    {
                        showTeamAddEditModal && 
                        <TeamAddEditViewModal
                            modalTitle={viewEditTeamId ? ENUMS.MODAL.EDIT_TEAM_TITLE : ENUMS.MODAL.ADD_TEAM_TITLE}
                            modalButtonName={viewEditTeamId ? ENUMS.BUTTON_TEXT.SAVE : ENUMS.BUTTON_TEXT.ADD}
                            modalMode={viewEditTeamId ? ENUMS.MODAL.EDIT_MODE : ENUMS.MODAL.ADD_MODE}
                            modalOpen={showTeamAddEditModal} 
                            projectId={taskDetails.project_id}
                            assignTeamTotaskId={taskDetails.id}
                            viewEditTeamId={viewEditTeamId}
                            closeModal={closeAddEditTeamModal}
                            refreshPageOnSubmit={refreshPageOnSubmit}
                        /> 
                    }
                    {
                        showDeleteTeamModal && 
                        <ConfirmationModal
                            actionText={deleteTeamModalActionTexts}
                            onCancel={hideDeleteTeamModal}
                            onConfirm={deleteTeamConfirmed}
                            modalBody={deleteTeamModalText}
                            showModal={showDeleteTeamModal}
                            modalTitle={ENUMS.MODAL.UNASSIGN_TEAM_TITLE}
                            data={selectedTeamId}
                        />
                    }
                    {
                        showDeleteActionModal && 
                        <ConfirmationModal
                            actionText={deleteModalActionTexts}
                            onCancel={hideDeleteActionModal}
                            onConfirm={deleteActionConfirmed}
                            modalBody={deleteActionModalText}
                            showModal={showDeleteActionModal}
                            modalTitle={ENUMS.MODAL.DELETE_ACTION_TITLE}
                            data={selectedActionId}
                        />
                    }
                    {
                        showDeleteNoteModal && 
                        <ConfirmationModal
                            actionText={deleteModalActionTexts}
                            onCancel={hideDeleteNoteModal}
                            onConfirm={deleteNoteConfirmed}
                            modalBody={deleteNoteModalText}
                            showModal={showDeleteNoteModal}
                            modalTitle={ENUMS.MODAL.DELETE_NOTE_TITLE}
                            data={selectedNoteId}
                        />
                    }
                    { 
                        addEditTaskModalOpen && 
                        <TaskAddEditModal 
                            modalTitle={ENUMS.MODAL.EDIT_TASK_TITLE}
                            modalButtonName={ENUMS.BUTTON_TEXT.SAVE}
                            modalMode={ENUMS.MODAL.EDIT_MODE}
                            modalOpen={addEditTaskModalOpen} 
                            projectId={taskDetails.project_id}
                            editTaskId={taskDetails.id}
                            closeModal={closeAddEditModal}
                            refreshPageOnSubmit={refreshPageOnSubmit}
                        /> 
                    }
                    { 
                        uploadModalOpen && 
                        <UploadModal
                            modalTitle={ENUMS.MODAL.UPLOAD_FILE_MODAL_TITLE}
                            modalButtonName={ENUMS.BUTTON_TEXT.UPLOAD}
                            showModal={uploadModalOpen}
                            closeUploadModal={closeUploadModal}
                            uploadTo="task"
                            projectId={taskDetails.project_id}
                            taskId={taskDetails.id}
                            refreshViewOnAction={refreshPageOnSubmit}
                        /> 
                    }
                    {
                        showAddEditActionModal && 
                        <AddEditActionModal
                            modalTitle={actionAddEditModalMode === ENUMS.MODAL.ADD_MODE ? ENUMS.MODAL.ADD_ACTION_TITLE : ENUMS.MODAL.EDIT_ACTION_TITLE}
                            modalButtonName={ ENUMS.MODAL.ADD_MODE ? ENUMS.BUTTON_TEXT.ADD : ENUMS.BUTTON_TEXT.SAVE }
                            modalOpen={showAddEditActionModal}
                            closeModal={closeAddEditActionModal}
                            projectData={taskDetails.Project}
                            modalMode={actionAddEditModalMode}
                            refreshPageOnSubmit={refreshPageOnSubmit}
                            editActionId={editActionId}
                            assignToTask={taskDetails.id}
                        /> 
                    }
                    { 
                        viewActionModalOpen && 
                        <ViewActionModal
                            modalTitle={viewActionModalTitle}
                            modalOpen={viewActionModalOpen}
                            actionData={actionToView}
                            closeModal={closeActionViewModal}
                        /> 
                    }
                </React.Fragment>
                        }}
                        no={() => {
                            return <Redirect to="/"/>
                        }}
                    />
                }
                </>       
            }
        </>
    );
}