import RULES from "rbac/rules";
import React from "react";
import Badge from "reactstrap/lib/Badge";

const ROUTE_SYSTEM = {
    REPORTS_GANTT: '/admin/projects/gantt-report',
    REPORTS_CALENDAR: '/admin/projects/calendar-report',
    REPORTS_MILESTONE: '/admin/projects/milestone'
}

const ROUTES = {
    AUTH: '/auth',
    ADMIN: '/admin',
}

const API_ROUTES = {
    ACTION_ADD: '/api/action/add',
    ACTION_EDIT: '/api/action/update',
    ACTION_GET_BY_ID: '/api/action/get-action-by-id',
    ACTION_DELETE: '/api/action/delete',
    AUTH_STATUS: '/api/auth/auth-status',
    AUTH_LOGIN: '/api/auth/auth-login',
    AUTH_GOOGLE: process.env.REACT_APP_STAGE === 'production' ? 
        'https://0qn0mj7t5d.execute-api.ap-southeast-2.amazonaws.com/prod/api/auth/google':
        '/api/auth/google',
    AUTH_GOOGLE_SIGN_IN: '/api/auth/google/sign-in',
    AUTH_LOGOUT: '/api/auth/auth-logout',
    AUTH_CONFIRM_INVITE_USER: '/api/auth/invite-confirm',
    AUTH_RESET_PASSWORD: '/api/auth/reset-password',
    AUTH_CONFIRM_RESET_PASSWORD: '/api/auth/reset-password/confirm',
    LICENCES_GET_ALL: '/api/licence/all',
    PROJECT_GET_SINGLE: '/api/project/get-project',
    PROJECT_GET_ALL: '/api/project/all',
    PROJECT_ADD: '/api/project/add',
    PROJECT_UPDATE: '/api/project/update',
    PROJECT_GET_SETTINGS: '/api/project/settings',
    PROJECT_CONFIGURE_SETTINGS: '/api/project/configure',
    PROJECT_GET_OWNER_ID: '/api/project/get-owner-id',
    CALENDAR_GET_ALL: '/api/calendar/all',
    CALENDAR_CREATE: '/api/calendar/create',
    CALENDAR_UPDATE: '/api/calendar/update',
    CALENDAR_COPY: '/api/calendar/copy',
    CALENDAR_DELETE: '/api/calendar/delete',
    CALENDAR_EXCEPTION_ADD: '/api/calendar/add-exception',
    CALENDAR_EXCEPTION_EDIT: '/api/calendar/edit-exception',
    CALENDAR_EXCEPTION_DELETE: '/api/calendar/delete-exception',
    CALENDAR_EXCEPTION_GET_ALL: '/api/calendar/all-exceptions',
    DELETE_PROJECT: '/api/project/delete',
    FEEDBACK_ADD: '/api/feedback/add',
    FUTURE_FEATURES_GET: '/api/future-features/all',
    FUTURE_FEATURES_VOTE: '/api/future-features/vote',
    TIMEZONE_GET_ALL: '/api/timezone/all',
    USER_FETCH_GOOGLE: '/api/user/get-user-google',
    USER_CREATE: '/api/user/create',
    USER_UPDATE: '/api/user/update',
    USER_ALL_ORGANIZATION: '/api/user/all/project',
    USER_UPDATE_PASSWORD: '/api/user/update-password',
    TEAM_ADD: '/api/team/create',
    TEAM_GET_SINGLE: '/api/team/get-team',
    TEAM_UPDATE: '/api/team/update',
    TEAM_GET_ALL_USERS_TEAM: '/api/team/get-user-teams',
    TEAM_DELETE: '/api/team/delete',
    TEAM_GET_COPY_TEAMS: '/api/team/get-copy-teams',
    TASK_GET_ALL_PROJECT_DATA: '/api/task/get-all-project-data-task',
    TASK_CREATE_TASK_GROUP: '/api/task/create-task-group',
    TASK_CREATE_TASK_TEAM: '/api/task/create-task-team',
    TASK_ADD: '/api/task/add',
    TASK_GET_TASK_BY_ID: '/api/task/get-task-by-id',
    TASK_UPDATE: '/api/task/update',
    TASK_CHECK_DELETE: '/api/task/check-delete',
    TASK_DELETE: '/api/task/delete',
    TASK_UPDATE_TABLE_ROWS: '/api/task/update-table-rows',
    TASK_HOLIDAY_ADD: '/api/task/add-holiday',
    TASK_HOLIDAY_DELETE: '/api/task/delete-holiday',
    TASK_NOTES_ADD: '/api/note/create',
    TASK_NOTES_UPDATE: '/api/note/edit-note',
    TASK_NOTES_DELETE: '/api/note/delete',
    TASK_TEAM_USER_DELETE: '/api/task/delete-team-user-assigned',
    ORGANIZATION_GET_ALL: '/api/organization/all',
    ORGANIZATION_CREATE: '/api/organization/create',
    ORGANIZATION_UPDATE: '/api/organization/update',
    S3_UPLOAD_SIGNED_URL: '/api/file/generatePutObjectPresignedUrl',
    S3_DOWNLOAD_SIGNED_URL: '/api/file/generateGetObjectPresignedUrl',
    STRIPE_CHARGE: '/api/stripe/charge',
    STRIPE_SUB: '/api/stripe/sub',
    FILE_TYPES_GET: '/api/file/file-types',
    FILE_TYPE_CREATE: '/api/file/file-type-create',
    PROJECT_FILES_GET: '/api/file/project-files',
    PROJECT_FILE_CREATE: '/api/file/project-file-create',
    PROJECT_FILE_DELETE: '/api/file/project-file-delete',
    TASK_FILES_GET: '/api/file/task-files',
    TASK_FILE_CREATE: '/api/file/task-file-create',
    TASK_FILE_DELETE: '/api/file/task-file-delete',
    NOTIFICATION_GET_EMAIL_SETTINGS: '/api/email-notification/setting',
    NOTIFICATION_UPDATE_EMAIL_SETTINGS: '/api/email-notification/update-setting',
    RUN_DATA_FIX_SCRIPT: '/api/admin/data-fix',
    VERIFY_ENABLE_2FA_TOKEN: '/api/2-factor-auth/verify-enable',
    SEND_TOKEN_TWO_FACTOR_AUTHENTICATION: '/api/2-factor-auth/send-token',
    DISABLE_TWO_FACTOR_AUTHENTICATION: '/api/2-factor-auth/disable',
    AUTH_VALIDATE_TOKEN: '/api/auth/auth-login/validate-2fa-token',
    GET_INVITED_USER: '/api/auth/invite-confirm/get-invited-user',
    CONTACT_US_CREATE: '/api/contact-us/create',
    UPDATE_USER_DEFAULT_CALENDAR: '/api/user/update-default-calendar',
    STAKEHOLDER_GET_BY_ID: '/api/stakeholder/get',
    STAKEHOLDER_GET_ALL: '/api/stakeholder/get-all',
    STAKEHOLDER_CREATE: '/api/stakeholder/create',
    STAKEHOLDER_DELETE: '/api/stakeholder/delete',
    STAKEHOLDER_UPDATE: '/api/stakeholder/update',
    GET_USER_BY_ID: '/api/user/get',
    PROJECT_RISK_GET_ALL: '/api/raid/risk/get-all',
    PROJECT_RISK_GET_BY_ID: '/api/raid/risk/get',
    PROJECT_ASSUMPTION_GET_ALL: '/api/raid/assumption/get-all',
    PROJECT_ASSUMPTION_GET_BY_ID: '/api/raid/assumption/get',
    PROJECT_ISSUE_GET_ALL: '/api/raid/issue/get-all',
    PROJECT_ISSUE_GET_BY_ID: '/api/raid/issue/get',
    PROJECT_DEPENDENCY_GET_ALL: '/api/raid/dependency/get-all',
    PROJECT_DEPENDENCY_GET_BY_ID: '/api/raid/dependency/get',
    RISK_RATINGS_GET:  '/api/raid/risk/get-all-rating',
    PROJECT_RISK_ADD: '/api/raid/risk/create',
    PROJECT_RISK_UPDATE: '/api/raid/risk/update',
    PROJECT_RISK_DELETE: '/api/raid/risk/delete',
    PROJECT_RISK_UPDATE_TABLE_ROWS: '/api/raid/risk/update-table-rows',
    PROJECT_ASSUMPTION_ADD: '/api/raid/assumption/create',
    PROJECT_ASSUMPTION_UPDATE: '/api/raid/assumption/update',
    PROJECT_ASSUMPTION_DELETE: '/api/raid/assumption/delete',
    PROJECT_ASSUMPTION_UPDATE_TABLE_ROWS: '/api/raid/assumption/update-table-rows',
    PROJECT_ISSUE_ADD: '/api/raid/issue/create',
    PROJECT_ISSUE_UPDATE: '/api/raid/issue/update',
    PROJECT_ISSUE_DELETE: '/api/raid/issue/delete',
    PROJECT_ISSUE_UPDATE_TABLE_ROWS: '/api/raid/issue/update-table-rows',
    PROJECT_DEPENDENCY_ADD: '/api/raid/dependency/create',
    PROJECT_DEPENDENCY_UPDATE: '/api/raid/dependency/update',
    PROJECT_DEPENDENCY_DELETE: '/api/raid/dependency/delete',
    PROJECT_DEPENDENCY_UPDATE_TABLE_ROWS: '/api/raid/dependency/update-table-rows',
    RISK_RATING_UPDATE_TABLE_ROWS: '/api/raid/risk/update-rating',
    ACTION_NOTES_ADD: '/api/action-note/create',
    ACTION_NOTES_UPDATE: '/api/action-note/edit-note',
    ACTION_NOTES_DELETE: '/api/action-note/delete',
    RISK_NOTES_ADD: '/api/risk-note/create',
    RISK_NOTES_UPDATE: '/api/risk-note/edit-note',
    RISK_NOTES_DELETE: '/api/risk-note/delete',
    ASSUMPTION_NOTES_ADD: '/api/assumption-note/create',
    ASSUMPTION_NOTES_UPDATE: '/api/assumption-note/edit-note',
    ASSUMPTION_NOTES_DELETE: '/api/assumption-note/delete',
    ISSUE_NOTES_ADD: '/api/issue-note/create',
    ISSUE_NOTES_UPDATE: '/api/issue-note/edit-note',
    ISSUE_NOTES_DELETE: '/api/issue-note/delete',
    DEPENDENCY_NOTES_ADD: '/api/dependency-note/create',
    DEPENDENCY_NOTES_UPDATE: '/api/dependency-note/edit-note',
    DEPENDENCY_NOTES_DELETE: '/api/dependency-note/delete',
    VERIFY_USER: '/api/auth/verify-user',
    REHEARSAL_TYPE_CREATE: '/api/rehearsal/type/create',
    REHEARSAL_STATUS_CREATE: '/api/rehearsal/status/create',
    REHEARSAL_TYPE_GET: '/api/rehearsal/type/get-all',
    REHEARSAL_STATUS_GET: '/api/rehearsal/status/get-all',
    REHEARSAL_CREATE: '/api/rehearsal/create',
    REHEARSAL_UPDATE: '/api/rehearsal/update',
    REHEARSAL_GET_ALL: '/api/rehearsal/get-all-rehearsals',
    REHEARSAL_START: '/api/rehearsal/start',
    REHEARSAL_END: '/api/rehearsal/end',
    REHEARSAL_GET_BY_ID: '/api/rehearsal/get',
    REHEARSAL_NOTES_ADD: '/api/rehearsal/create-note',
    REHEARSAL_NOTES_UPDATE: '/api/rehearsal/edit-note',
    REHEARSAL_NOTES_DELETE: '/api/rehearsal/delete-note',
}

const BUTTON_TEXT = {
    ADD_PROJECT: 'Add Project',
    ADD: 'Add',
    EDIT: 'Edit',
    SAVE: 'Save',
    CANCEL: 'Cancel',
    DELETE_ACTION_TEXT_CONFIRM: 'Yes, Delete',
    UASSIGN_ACTION_TEXT_CONFIRM: 'Yes, Unassign',
    UPDATE_ACTION_TEXT_CONFIRM: 'Yes, Update',
    COPY_AND_CREATE_NEW: 'Copy and Create New',
    ACTION_TEXT_CONFIRM: 'Yes, Confirm',
    EDIT_TABLE: 'Edit Table',
    UPLOAD: 'Upload'
}

const MODAL = {
    ADD_MODE: 'Add',
    EDIT_MODE: 'Edit',
    VIEW_MODE: 'View',
    COPY_MODE: 'Copy',
    DELETE_MODE: 'Delete',
    SET_MODE: 'Set',
    ADD_PROJECT_TITLE: 'Add Project',
    DELETE_ACTION_TITLE: 'Delete Action',
    DELETE_PROJECT_TITLE: 'Delete ',
    DELETE_CONFIRMATION_TEXT: 'Are you sure you want to delete ',
    DELETE_CALENDAR_CONFIRMATION_TEXT: 'Deleting calendar would also delete all calendar exceptions associated with the calendar. Are you sure you want to delete ',
    DELETE_FILE_TITLE: 'Delete File',
    EDIT_PROJECT_TITLE: 'Edit Project',
    EDIT_CALENDAR_TITLE: 'Edit Calendar',
    COPY_CALENDAR_TITLE: 'Copy Calendar',
    DELETE_CALENDAR_TITLE: 'Delete Calendar',
    DELETE_CALENDAR_HOLIDAY_TITLE: 'Delete Calendar Exception',
    CALENDAR_EXCEPTIONS_LIST_TITLE: 'Calendar Exceptions',
    CALENDAR_EXCEPTION_ADD_MODAL_TITLE: 'Add Calendar Exception',
    CALENDAR_EXCEPTION_EDIT_MODAL_TITLE: 'Edit Calendar Exception',
    ADD_TEAM_TITLE: 'Add Team',
    EDIT_TEAM_TITLE: 'Edit Team',
    DELETE_TEAM_TITLE: 'Delete Team',
    VIEW_TEAM_TITLE: 'View Team',
    COPY_TEAM_TITLE: 'Copy Team',
    ADD_TASK_TITLE: 'Add Task',
    EDIT_TASK_TITLE: 'Edit Task',
    DELETE_TASK_TITLE: 'Delete Task',
    DELETE_TASK_TEXT: 'Are you sure you want to delete task number ',
    TASK_EXCEPTION_ADD_MODAL_TITLE: 'Add Task Exception',
    TASK_EXCEPTION_DELETE_MODAL_TITLE: 'Delete Task Exception',
    DELETE_NOTE_TITLE: 'Delete Note',
    UPDATE_ORGANIZATION_TITLE: "Update Organisation",
    UNASSIGN_CONFIRMATION_TEXT: 'Are you sure you want to unassign ',
    UNASSIGN_TEAM_TITLE: 'Unassign Team',
    UPLOAD_FILE_MODAL_TITLE: 'Upload File',
    ADD_ACTION_TITLE: 'Add Action',
    EDIT_ACTION_TITLE: 'Edit Action',
    DISABLE_TWO_FACT_AUTH: 'Disable two-factor authentication',
    ADD_STAKEHOLDER_TITLE: 'Add Stakeholder',
    EDIT_STAKEHOLDER_TITLE: 'Edit Stakeholder',
    ADD_RISK_TITLE: 'Add Risk',
    EDIT_RISK_TITLE: 'Edit Risk',
    DELETE_RISK_TITLE: 'Delete Risk',
    ADD_ASSUMPTION_TITLE: 'Add Assumption',
    EDIT_ASSUMPTION_TITLE: 'Edit Assumption',
    DELETE_ASSUMPTION_TITLE: 'Delete Assumption',
    ADD_ISSUE_TITLE: 'Add Issue',
    EDIT_ISSUE_TITLE: 'Edit Issue',
    DELETE_ISSUE_TITLE: 'Delete Issue',
    ADD_DEPENDENCY_TITLE: 'Add Dependency',
    EDIT_DEPENDENCY_TITLE: 'Edit Dependency',
    DELETE_DEPENDENCY_TITLE: 'Delete Dependency',
}

const RESPONSE_MESSAGE = {
    USER_AUTHENTICATED: 'USER_AUTHENTICATED',
    USER_LOGGED_OUT: 'USER_LOGGED_OUT'
}

const TABS = {
    GENERAL_STETTINGS: "GENERAL_STETTINGS",
    NOTIFICATION_SETTINGS: "NOTIFICATION_SETTINGS",
    PROJECT_CALENDARS: "PROJECT_CALENDARS",
    TASK_LIST: "TASK_LIST",
    TEAMS_LIST: "TEAMS_LIST",
    FILES_LIST: "FILES_LIST",
    ACTIONS_LIST: "ACTIONS_LIST",
    STAKEHOLDERS_LIST: "STAKEHOLDERS_LIST",
    RAID_VIEW: "RAID_VIEW",
    RISK_LIST: "RISK_LIST",
    ASSUMPTION_LIST: "ASSUMPTION_LIST",
    ISSUES_LIST: "ISSUES_LIST",
    DEPENDENCIES_LIST: "DEPENDENCIES_LIST",
}

const RENDER_OPTIONS_TYPE = {
    DELETE_CALENDAR: 'delete',
    CALENDAR_EXCEPTIONS_LIST: 'all-calendar-holidays',
    DELETE: 'DELETE',
    START_TASK: 'Start Task',
    STOP_TASK: 'Stop Task',
}

const AWS = {
    API_NAME: 'cutoverplan-api'
}

const EXCEPTION_DEFAULT_VALUES = {
    HOLIDAY: 'holiday',
    WORKING_DAY: 'working_day'
}

const DURATION_FORMAT_VALUES = {
    HOUR: 'hour',
    DAY: 'day'
}

const PREDECESSOR_TASK_LINK_TYPES = {
    FS: 'FS',
    SS: 'SS',
    FF: 'FF',
    FS_LABEL: 'FS (Finish Start)',
    SS_LABEL: 'SS (Start Start)',
    FF_LABEL: 'FF (Finish Finish)'
}

const DT_STATE_STORAGE_NAMES = {
    PREFIX: 'dt-state-',
    PROJECT_VIEW_TASK_TABLE: 'project-view-task',
    PROJECT_VIEW_RISK_TABLE: 'project-view-risk',
    PROJECT_VIEW_TEAM_TABLE: 'project-view-team',
    PROJECT_VIEW_FILE_TABLE: 'project-view-files',
    PROJECT_HOME_TABLE: 'project-home',
    PROJECT_SETTING_CALENDAR: 'project-setting-calendar',
    CALENDAR_HOLIDAY_LIST: 'calendar-holiday-list',
    TEAMS_HOME: 'teams-home',
    SELECTED_COL: 'project-view-task-selected-col-',
    SELECTED_COL_OPTIONS: 'project-view-task-selected-col-options-',
    SELECTED_COL_DROPDOWN: 'project-view-task-selected-col-dropdown-',
    PAGINATOR_FIRST: 'project-view-task-paginator-first-',
    PAGINATOR_ROWS: 'project-view-task-paginator-rows-',
    GROUP_TASK: 'project-view-task-group-rows-',
    SORT_FIELD: 'project-view-task-sort-field-',
    SORT_ORDER: 'project-view-task-sort-order-',
    COMPARE_REHEARSAL: 'project-view-task-compare-rehearsal-'
}

const TABLE_CONSTANTS = {
    LOCAL: 'local',
    COL_RESIZE_MODE: 'expand',
    SORT_MODE: 'single',
    EMPTY_MESSAGE: 'No data found',
    PAGINATOR_TEMPLATE: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
    CURRENT_PAGE_TEMPLATE: 'Showing {first} to {last} of {totalRecords}',
    EDIT_MODE_CELL: 'cell',
    MIN_ROWS: 10
}

const TASK_STATUS = {
    P: 'P',
    P_LABEL: 'PENDING',
    S: 'S',
    S_LABEL: 'STARTED',
    SC: 'SC',
    SC_LABEL: 'SUCCESS/CLOSED',
    PA: 'PA',
    PA_LABEL: 'PARKED',
    FC: 'FC',
    FC_LABEL: 'FINISHED - CUTOVER CONTINUES',
    FT: 'FT',
    FT_LABEL: 'FINISHED - CUTOVER TERMINATED',
    BL: 'BL',
    BL_LABEL: 'BLOCKED',
}

const TASK_STATUS_VIEW = {
    P: <Badge color="primary">{TASK_STATUS.P_LABEL}</Badge>,
    S: <Badge color="info">{TASK_STATUS.S_LABEL}</Badge>,
    SC: <Badge color="success">{TASK_STATUS.SC_LABEL}</Badge>,
    PA: <Badge color="default">{TASK_STATUS.PA_LABEL}</Badge>,
    FC: <Badge color="danger">{TASK_STATUS.BL_LABEL}</Badge>,
    FT: <Badge color="danger">{TASK_STATUS.FC_LABEL}</Badge>,
    BL: <Badge color="danger">{TASK_STATUS.FT_LABEL}</Badge>,
}

const PERCENTAGE_COMPLETE_VIEW = {
    0: '0%',
    10: '10%',
    20: '20%',
    30: '30%',
    40: '40%',
    50: '50%',
    60: '60%',
    70: '70%',
    80: '80%',
    90: '90%',
    100: '100%',
}

const FIELDS = {
    TEAM_DESCRIPTION: 'team_description'
}

const PERMISSIONS_MAP = {
    [RULES.ROLE_NAMES.TASK_OWNER]: 'task_owner',
    [RULES.ROLE_NAMES.TEAM_LEADER]: 'team_leader',
}

const REPORT_TYPE = {
    GANTT: 'REPORTS_GANTT',
    CALENDAR: 'REPORTS_CALENDAR',
    MILESTONE: 'REPORTS_MILESTONE'
}

const FEEDBACK_TYPES = {
    BUG: 'bug',
    QUESTION: 'question',
    COMMENT: 'comment',
    SUGGESTION: 'suggestion',
}

const ISSUE_SEVERITY = {
    LOW: 'low',
    MEDIUM: 'medium',
    HIGH: 'high'
}

const LIKELIHOOD = {
    almost_certain: 'Almost Certain',
    likely: 'Likely',
    possible: 'Possible',
    unlikely: 'Unlikely',
    rare: 'Rare',
}

const IMPACT = {
    low: <Badge color="success">LOW</Badge>,
    medium: <Badge className="badge-light-warning">MEDIUM</Badge>,
    high: <Badge color="warning">HIGH</Badge>,
    extreme: <Badge color="danger">EXTREME</Badge>,
}

const RAID_STATUS = {
    open: <Badge color="success">OPEN</Badge>,
    closed: <Badge color="danger">CLOSED</Badge>,
    OPEN: 'open',
    CLOSED: 'closed',
    OPEN_LABEL: 'OPEN',
    CLOSED_LABEL: 'CLOSED',
}

const VIEW_PATH = {
    ACTION: '/admin/actions/action-view',
    RISK: '/admin/risks/risk-view',
    ASSUMPTION: '/admin/assumptions/assumption-view',
    ISSUE: '/admin/issues/issue-view',
    DEPENDENCY: '/admin/dependencies/dependency-view'
}

const REHEARSAL_STATE = {
    completed: <Badge color="success">COMPLETED</Badge>,
    active: <Badge className="badge-light-warning">IN PROGRESS</Badge>,
    inactive: <Badge color="secondary">INACTIVE</Badge>,
}

const LOCAL_STORAGE_KEYS = {
  PROJECT_VIEW_ACTIVE_TAB: "project-view-active-tab",
  PROJECT_VIEW_ACTIVE_RAID_TAB: "project-view-active-raid-tab"
};

const ENUMS = {
    ROUTES,
    API_ROUTES,
    BUTTON_TEXT,
    MODAL,
    RESPONSE_MESSAGE,
    TABS,
    RENDER_OPTIONS_TYPE,
    AWS,
    EXCEPTION_DEFAULT_VALUES,
    PREDECESSOR_TASK_LINK_TYPES,
    DT_STATE_STORAGE_NAMES,
    DURATION_FORMAT_VALUES,
    TABLE_CONSTANTS,
    TASK_STATUS,
    TASK_STATUS_VIEW,
    FIELDS,
    PERMISSIONS_MAP,
    REPORT_TYPE,
    ROUTE_SYSTEM,
    FEEDBACK_TYPES,
    ISSUE_SEVERITY,
    LIKELIHOOD,
    IMPACT,
    RAID_STATUS,
    VIEW_PATH,
    REHEARSAL_STATE,
    PERCENTAGE_COMPLETE_VIEW,
    LOCAL_STORAGE_KEYS
}

export default ENUMS;