import './UploadPictureModal.css';
import React, { useCallback, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Card, CardBody, Spinner } from "reactstrap";
import Cropper from 'react-easy-crop';
import getCroppedImg from 'util/ImageUtil';
import { useToasts } from 'react-toast-notifications';
import { API } from 'aws-amplify';
import ENUMS from 'constants/appEnums';
import HttpUtil from 'util/HttpUtil';
import  axios  from "../../axiosConfig";
import APP_CONSTANTS from 'constants/appConstants';

export default function UploadPictureModal(props) {
    const { addToast } = useToasts();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [uploadedFile, setUploadedFile] = useState();
    const [uploadedFileBlob, setUploadedFileBlob] = useState();

    const closeModal = (uploaded = false) => {
        props.onCancel(uploaded);
    }

    const isImage = (filename) => {
        if(APP_CONSTANTS.ACCEPTED_IMAGE_FORMATS.indexOf(filename.split(".").pop()) > -1) {
            return true;
        } else {
            const temp = <div>
                <div><strong>{filename.split(".").pop()} format is not supported.</strong></div>
                <div>Please upload a image file of 'png', 'jpg' or 'jpeg' format.</div>
            </div>
            addToast(
                temp, 
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
        }
    }

    // On file select (from the pop up) 
    const onFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0 && isImage(event.target.files[0].name)) {
            setUploadedFile(event.target.files[0]);
            const reader = new FileReader()
            reader.addEventListener(
              'load', () => setUploadedFileBlob(reader.result), false
            )
            reader.readAsDataURL(event.target.files[0])
        }
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImageBlob = await getCroppedImg(
                uploadedFileBlob,
                croppedAreaPixels,
                0
            );
            const croppedImageFile = new File([croppedImageBlob], uploadedFile.name, {
                type: uploadedFile.type
            });
            API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.S3_UPLOAD_SIGNED_URL, 
                {
                    ...HttpUtil.adminHttpHeaders(),
                    queryStringParameters: 
                    {
                        fileName: croppedImageFile.name,
                        fileType: croppedImageFile.type,
                        path: 'profile-pictures'
                    },
                })
                .then(res => {
                    if (res.status === 200 && res.data.success) {
                        uploadFileToS3(res.data.signedRequest, croppedImageFile);
                    }
                })
                .catch(error => {
                    setIsSubmitting(false);
                    if (error.response) {
                        const content = (
                            <div>
                                <strong>Failed to generate presigned url.</strong>
                                <div>
                                    {error.response.data && error.response.data.message}
                                </div>
                            </div>
                        )
                        addToast(
                            content,
                            { 
                                appearance: 'error',
                                autoDismiss: true
                            }
                        );
                    }
                })
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, 0])

    const uploadFileToS3 = (signedRequest, file) => {
        axios.put(signedRequest.presignedURL, file)
        .then(result => {
            saveUserInfo(signedRequest.fileUploadPath);
        })
        .catch(err => {
            setIsSubmitting(false);
            console.log(err);
        })
    }

    const saveUserInfo = (fileUploadPath) => {
        API.put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.USER_UPDATE, 
        {
            ...HttpUtil.adminHttpHeaders(),
            body: 
            {
                id: props.user.id,
                s3filePath: fileUploadPath
            },
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                addToast(
                    "Your Profile picture has been updated successfully", 
                    { 
                        appearance: 'success',
                        autoDismiss: true
                    }
                );
                closeModal(true);
            }
        })
        .catch(error => {
            if (error.response) {
                const content = (
                    <div>
                        <strong>Failed to add feedback.</strong>
                        <div>
                           {error.response.data.message }
                        </div>
                    </div>
                )
                addToast(
                    content,
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const changeZoom = (zoomIn) => {
        if (zoomIn) {
            setZoom(zoom + 1);
        } else if (zoom > 1) {
            setZoom(zoom - 1);
        }
    }

    return (
        <Modal
            className="modal-dialog-centered upload-picture-modal"
            size="md"
            isOpen={props.showModal}
            toggle={() => closeModal()}
            backdrop="static"
        >
            <ModalHeader className="modal-header" toggle={() => closeModal()} tag="h2">{props.modalTitle}</ModalHeader>
            <ModalBody className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-4 py-lg-4 up-card-body">
                        {
                            uploadedFileBlob
                            ? <div>
                                <div className="crop-container">
                                    <Cropper
                                        image={uploadedFileBlob}
                                        crop={crop}
                                        zoom={zoom}
                                        cropShape="round"
                                        aspect={1 / 1}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                        className="cropper"
                                    />
                                </div>
                                <div className="controls">
                                    <strong className="mr-2">Zoom: </strong>
                                    <Button className="btn-icon-only" onClick={() => changeZoom(true)}>+</Button>
                                    <Button className="btn-icon-only" onClick={() => changeZoom(false)}>-</Button>
                                </div>
                            </div>
                            : <div className="dialog-content-wrapper">
                                <div className="dialog-content">
                                    <div className="text-center">
                                        <i className="far fa-file-image fs-120 mb-3"></i>
                                        <div>Drop image file here (or)</div>
                                        <div><u>Choose Image</u></div>
                                    </div>
                                </div>
                                <Input
                                    type="file"
                                    name="file"
                                    id="file"
                                    className="file-selector"
                                    onChange={(e) => onFileChange(e)}
                                />
                            </div>
                        }
                    </CardBody>
                </Card>
            </ModalBody>
            <ModalFooter>
                <Button 
                    color="secondary" 
                    onClick={() => closeModal()}
                >
                    Cancel
                </Button>
                {' '}
                {isSubmitting ?
                    <Spinner size="sm" color="primary" /> :
                    <Button 
                        color="primary" 
                        type="submit"
                        onClick={showCroppedImage}
                        disabled={!uploadedFileBlob}
                    >
                        {props.modalButtonName}
                    </Button>
                }
                {" "}
            </ModalFooter>
        </Modal>
    )
}
