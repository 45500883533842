import React, { useState, useEffect, useRef } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	FormGroup,
	Label,
	Input,
	Form,
} from "reactstrap";
import { useForm } from 'react-hook-form';
import InputGroup from "reactstrap/lib/InputGroup";
import CardBody from "reactstrap/lib/CardBody";
import UncontrolledAlert from "reactstrap/lib/UncontrolledAlert";
import Card from "reactstrap/lib/Card";
import makeAnimated from "react-select/animated";
import CreatableSelect from 'react-select/creatable';
import ENUMS from "constants/appEnums";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import TeamUtil from "util/TeamUtil";
import { useToasts } from "react-toast-notifications";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import GeneralUtils from "util/GeneralUtils";
import Switch from "react-bootstrap-switch";
import Select from 'react-select';
import DataOptions from "constants/appDataOptions";
import CustomErrorMessage from "components/CustomErrorMessage/CustomErrorMessage";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker";
import UserUtil from "util/UserUtil";

export default function AddEditRiskModal(props) {
    const { handleSubmit, errors } = useForm({
		mode: "onBlur"
    });

    const current_user = useSelector(state => state.authReducer.user);
    
    const { addToast } = useToasts();

    //Editor props
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    // date errors
    const [errorVisible, setErrorVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const onToggleError = () => setErrorVisible(!errorVisible);

    // Form data
    const [description, setDescription] = useState("");
    const [team, setTeam] = useState();
    const [status, setStatus] = useState("");
    const [impact, setImpact] = useState("");
    const [likelihood, setLikelihood] = useState("");
    const [overall, setOverall] = useState("");
    const [mitigation, setMitigation] = useState("");
    const [dueDate, setDueDate] = useState();
    const [escalationRequired, setEscalationRequired] = useState(false);
    
    const [overallView, setOverallView] = useState("");

    // Risk Owner Dropdown
    const [riskOwner, setRiskOwner] = useState("");
    const [inviteRiskOwner, setInviteRiskOwner] = useState(null);

    // Risk Mitigation Owner Dropdown
    const [riskMitigationOwner, setRiskMitigationOwner] = useState("");
    const [inviteRiskMitigationOwner, setInviteRiskMitigationOwner] = useState(null);
    
    // Lists to populate dropdown 
    const [userList, setUserList] = useState([]);
    const [teamsList, setTeamsList] = useState([]);
    const [riskRatingsList, setRiskRatingsList] = useState([]);

    // togglers
    const [openRiskOwnerMenu, setOpenRiskOwnerMenu] = useState(false);
    const [openRiskMitigationOwnerMenu, setOpenRiskMitigationOwnerMenu] = useState(false);
    
    // loading and disabled
    const [isLoading, setIsLoading] = useState(true);

    // animated components
    const animatedComponents = makeAnimated();

    /**
     * Populates the form data for edit mode.
     */
    const populateFormData = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_RISK_GET_BY_ID,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                risk_id: props.editRiskId
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                const responseRiskData = res.data.projectRisk;
                setDescription(responseRiskData.description);
                setTeam(TeamUtil.formatTeamForDropdown(responseRiskData.Team));
                setStatus(responseRiskData.status);
                setRiskOwner(UserUtil.formatUserForDropdown(responseRiskData.RiskOwner));
                setLikelihood(responseRiskData.likelihood);
                setImpact(responseRiskData.impact);
                setOverallView(props.riskRatingsList.find(rating => rating.id == responseRiskData.overall)[responseRiskData.impact]);
                setOverall(responseRiskData.overall);
                setMitigation(responseRiskData.mitigation);
                setDueDate(responseRiskData.due_date);
                setRiskMitigationOwner(UserUtil.formatUserForDropdown(responseRiskData.RiskMitigationOwner));
                setEscalationRequired(responseRiskData.escalation_required);
                populateNoteHandler(responseRiskData.notes);
                setIsLoading(false);
            }
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch assumption data for edit. Please try again.", 
                    { 
                        appearance: 'error',
						autoDismiss: true
                    }
                );
            }
        })
    }

    const populateNoteHandler = (note) => {
        const contentBlock = htmlToDraft(note);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        }
    }

    const handleTeamCreation = (inputValue) => {
        // handle team creation
        API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TASK_CREATE_TASK_TEAM,
        {
            ...HttpUtil.adminHttpHeaders(),
            body: 
            {
                projectId: props.projectData.id,
                teamName: inputValue,
                teamLeaderId: current_user.id
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setTeam(TeamUtil.formatTeamForDropdown(res.data.createdTeam));
                setTeamsList([...teamsList, TeamUtil.formatTeamForDropdown(res.data.createdTeam)]);
            }
        })
        .catch(error => {
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch teams and groups for dropdown.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        });
    }

    const getNoteEditorContent = () => {
        const noteHTMLStringContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        if (noteHTMLStringContent === "<p></p>") {
            return '';
        }
        return noteHTMLStringContent;
    }

    /**
     * Fetches the user list for populating the dropdowns
     */
    const fetchUserList = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.USER_ALL_ORGANIZATION,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                project_id: props.projectData.id,
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setUserList(res.data.userList);
            }
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch user list for owner selection", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    // Method to handle form submit
    const handleConfirm = () => {
        console.log('on risk form submit');
        const noteContent = getNoteEditorContent();

        console.log('noteContent: ', noteContent);
        const riskData = {
            project_id: props.projectData.id,
            description: description,
            team_id: team?.value,
            status: status,
            owner_id: riskOwner.value,
            inviteRiskOwner: inviteRiskOwner,
            likelihood: likelihood,
            impact: impact,
            overall: overall,
            mitigation: mitigation,
            due_date: dueDate,
            mitigation_owner_id: riskMitigationOwner.value,
            inviteRiskMitigationOwner: inviteRiskMitigationOwner,
            escalation_required: escalationRequired,
            notes: noteContent
        }

        console.log('riskData: ', riskData);

        // add mode
        if (props.modalMode === ENUMS.MODAL.ADD_MODE) {
            API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_RISK_ADD,
            {
                ...HttpUtil.adminHttpHeaders(),
                body:
                {
                    ...riskData,
                }
            })
            .then(res => {
                if (res.status === 201 && res.data.success) {
                    props.refreshPageOnSubmit(res.data.message);
                }
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        "Failed to create risk.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
        }
        // edit mode
        else if (props.modalMode === ENUMS.MODAL.EDIT_MODE) {
            API.put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_RISK_UPDATE,
            {
                ...HttpUtil.adminHttpHeaders(),
                body:
                {
                    id: props.editRiskId,
                    ...riskData
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success) {
                    props.refreshPageOnSubmit(res.data.message);
                }
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        "Failed to update risk.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
        }
    }

    const handleInviteRiskOwner = (inputValue) => {
        // TO-DO: Validate against email format
        if (GeneralUtils.validateEmail(inputValue)) {
            setInviteRiskOwner(inputValue);
            const option = createOption(inputValue);
            setUserList([...userList, option]);
            setRiskOwner(option);
        }
        else {
            addToast(
                "Invalid format for email.", 
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
        }
        hideRiskOwnerMenu();
    }

    /**
     * Sets the new value to the risk owner and closes the select dropdown.
     * @param {Object} newValue 
     */
     const handleRiskOwnerChange = (newValue) => {
        if(newValue == null) {
            setInviteRiskOwner(null);
        }
        setRiskOwner(newValue);
        hideRiskOwnerMenu();
    }

    /**
     * Closes the dropdown menu on input change for risk owner select
     * @param {Object} query 
     * @param {Obejct} param1 
     */
     const handleRiskOwnerInputChange = (query, { action }) => {
        if (action === "input-change") {
            setOpenRiskOwnerMenu(true);
        }
    };

    /**
     * Closes the dropdown menu for owner and member select
     */
    const hideRiskOwnerMenu = () => {
        setOpenRiskOwnerMenu(false);
    };

    const handleInviteRiskMitigationOwner = (inputValue) => {
        // TO-DO: Validate against email format
        if (GeneralUtils.validateEmail(inputValue)) {
            setInviteRiskMitigationOwner(inputValue);
            const option = createOption(inputValue);
            setUserList([...userList, option]);
            setRiskMitigationOwner(option);
        }
        else {
            addToast(
                "Invalid format for email.", 
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
        }
        hideRiskMitigationOwnerMenu();
    }

    /**
     * Sets the new value to the risk mitigation owner and closes the select dropdown.
     * @param {Object} newValue 
     */
     const handleRiskMitigationOwnerChange = (newValue) => {
        if(newValue == null) {
            setInviteRiskMitigationOwner(null);
        }
        setRiskMitigationOwner(newValue);
        hideRiskMitigationOwnerMenu();
    }

    /**
     * Closes the dropdown menu on input change for risk mitigation owner select
     * @param {Object} query 
     * @param {Obejct} param1 
     */
     const handleRiskMitigationOwnerInputChange = (query, { action }) => {
        if (action === "input-change") {
            setOpenRiskMitigationOwnerMenu(true);
        }
    };

    /**
     * Closes the dropdown menu for owner and member select
     */
    const hideRiskMitigationOwnerMenu = () => {
        setOpenRiskMitigationOwnerMenu(false);
    };

    const createOption = (label) => {
        return {
            label,
            value: label.toLowerCase()
        }
    };

    const calculateOverAll = (type, data) => {
        type == 'likelihood' ? setLikelihood(data) : setImpact(data)
        let riskRatingData = null;
        if ((type == 'likelihood' && impact)) {
            riskRatingData = riskRatingsList.find(rating => rating.likelihood == data);
            setOverallView(riskRatingData[impact]);
            setOverall(riskRatingData.id);
        }
        if((type == 'impact' && likelihood)) {
            riskRatingData = riskRatingsList.find(rating => rating.likelihood == likelihood);
            setOverallView(riskRatingData[data]);
            setOverall(riskRatingData.id);
        }
    }

    /** 
     * NOTES DATA MEMEBERS 
     */
     /**
      * On change of the new note editor
      * 
      * @param {object} editorState 
      */
     const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    }

    const formatDropDownLabel = (inputValue) => {
        return "Invite - "+ inputValue;
    }

    const formatDropDownLabelTeam = (inputValue) => {
        return "Create Team - "+ inputValue;
    }

    useEffect(() => {
        // fetch user list
        fetchUserList();
        setRiskRatingsList(props.riskRatingsList);
    }, []);

    useEffect(() => {
        if (props.projectData) {
            setTeamsList(TeamUtil.formatTeamForDropdownBulk(props.projectData.Teams));
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (props.modalMode === ENUMS.MODAL.EDIT_MODE) {
            populateFormData();
        }
    }, [props.editRiskId]);

	return (
		<Modal
			size="md"
			isOpen={props.modalOpen}
			toggle={() => props.closeModal(false)}
            backdrop="static"
		>
			<ModalHeader toggle={() => props.closeModal(false)} tag="h3">
				{props.modalTitle}
			</ModalHeader>
            <Form role="form" onSubmit={handleSubmit(handleConfirm)}>
                <ModalBody className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-4 py-lg-4">
                            <FormGroup>
                                <Label for="riskTeamAssigned">Assign Risk To Team</Label>
                                <CreatableSelect
                                    id="riskTeamAssigned"
                                    className="form-control-alternative"
                                    components={animatedComponents}
                                    isDisabled={isLoading}
                                    isLoading={isLoading}
                                    onChange={(newValue) => setTeam(newValue)}
                                    onCreateOption={handleTeamCreation}
                                    createOptionPosition="first"
                                    formatCreateLabel={formatDropDownLabelTeam}
                                    options={teamsList}
                                    value={team}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="description">Description</Label>
                                <InputGroup className="input-group-alternative mb-3">
                                    <Input 
                                        id="description"
                                        name="description"
                                        className="form-control-alternative"
                                        type="text"
                                        value={description}
                                        onChange={(e)=> setDescription(e.target.value)}
                                        autoComplete="off"
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <Label for="status">Risk Status<span className="required-asterix">*</span></Label>
                                <Select
                                    id="status"
                                    name="status"
                                    className="form-control-alternative"
                                    options={DataOptions.RAID_STATUS_OPTIONS}
                                    value={DataOptions.RAID_STATUS_OPTIONS.filter(option => option.value === status)}
                                    onChange={(e) => setStatus(e.value)}
                                />
                                <CustomErrorMessage errors={errors} name="status" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="riskOwner">Risk Owner<span className="required-asterix">*</span></Label>
                                <CreatableSelect
                                    id="riskOwner"
                                    name="riskOwner"
                                    className="form-control-alternative"
                                    // as={CreatableSelect}
                                    // control={control}
                                    // rules={{ required: "Risk Owner is required." }}
                                    isClearable
                                    components={animatedComponents}
                                    isDisabled={isLoading}
                                    isLoading={isLoading}
                                    onChange={(newValue)=>handleRiskOwnerChange(newValue)}
                                    onCreateOption={handleInviteRiskOwner}
                                    createOptionPosition="first"
                                    formatCreateLabel={formatDropDownLabel}
                                    options={userList}
                                    value={riskOwner}
                                    onInputChange={handleRiskOwnerInputChange}
                                    onBlur={hideRiskOwnerMenu}
                                    onFocus={e => setOpenRiskOwnerMenu(true)}
                                    menuIsOpen={openRiskOwnerMenu}
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <Label for="likelihood">Likelihood<span className="required-asterix">*</span></Label>
                                <Select
                                    id="likelihood"
                                    name="likelihood"
                                    className="form-control-alternative"
                                    options={DataOptions.RISK_LIKELIHOOD_OPTIONS}
                                    value={DataOptions.RISK_LIKELIHOOD_OPTIONS.filter(option => option.value === likelihood)}
                                    onChange={(e) => calculateOverAll('likelihood', e.value)}
                                />
                                <CustomErrorMessage errors={errors} name="status" />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <Label for="impact">Risk impact<span className="required-asterix">*</span></Label>
                                <Select
                                    id="impact"
                                    name="impact"
                                    className="form-control-alternative"
                                    options={DataOptions.RISK_IMPACT_OPTIONS}
                                    value={DataOptions.RISK_IMPACT_OPTIONS.filter(option => option.value === impact)}
                                    onChange={(e) => calculateOverAll('impact', e.value)}
                                />
                                <CustomErrorMessage errors={errors} name="status" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="overall">Overall</Label>
                                    <Select
                                        id="overall"
                                        name="overall"
                                        className="form-control-alternative"
                                        options={DataOptions.RISK_RATING_OPTIONS}
                                        value={DataOptions.RISK_RATING_OPTIONS.filter(option => option.value === overallView)}
                                        isDisabled={true}
                                        onChange={(e) => setOverallView(e.value)}
                                    />
                            </FormGroup>
                            <FormGroup>
                                <Label for="due_date">Due Date</Label>
                                <div className="react-datepicker-manager" id="due-date-picker">
                                    <CustomDatePicker
                                        id="due_date"
                                        selected={dueDate}
                                        onChange={date => setDueDate(date)}
                                        showTimeSelect={false}
                                        renderToEl="due-date-picker"
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label for="mitigation">Mitigation</Label>
                                <InputGroup className="input-group-alternative mb-3">
                                    <Input 
                                        id="mitigation"
                                        name="mitigation"
                                        className="form-control-alternative"
                                        type="text"
                                        value={mitigation}
                                        onChange={(e)=> setMitigation(e.target.value)}
                                        autoComplete="off"
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label for="stakeholder_email">Risk Mitigation Owner<span className="required-asterix">*</span></Label>
                                <CreatableSelect
                                    id="riskMitigationOwner"
                                    name="riskMitigationOwner"
                                    className="form-control-alternative"
                                    // as={CreatableSelect}
                                    // control={control}
                                    // rules={{ required: "RiskMitigation Owner is required." }}
                                    isClearable
                                    components={animatedComponents}
                                    isDisabled={isLoading}
                                    isLoading={isLoading}
                                    onChange={(newValue)=>handleRiskMitigationOwnerChange(newValue)}
                                    onCreateOption={handleInviteRiskMitigationOwner}
                                    createOptionPosition="first"
                                    formatCreateLabel={formatDropDownLabel}
                                    options={userList}
                                    value={riskMitigationOwner}
                                    onInputChange={handleRiskMitigationOwnerInputChange}
                                    onBlur={hideRiskMitigationOwnerMenu}
                                    onFocus={e => setOpenRiskMitigationOwnerMenu(true)}
                                    menuIsOpen={openRiskMitigationOwnerMenu}
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <Label for="escalation_required">Escalation Required<span className="required-asterix">*</span></Label>
                                <Select
                                    id="escalation_required"
                                    name="escalation_required"
                                    className="form-control-alternative"
                                    options={DataOptions.YES_NO_OPTIONS}
                                    value={DataOptions.YES_NO_OPTIONS.filter(option => option.value === escalationRequired)}
                                    onChange={(e) => setEscalationRequired(e.value)}
                                />
                                <CustomErrorMessage errors={errors} name="status" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="note">Note</Label>
                                <div className="comment-box-wrapper bg-white note-wrap">
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorStateChange}
                                    />
                                </div>
                            </FormGroup>
                            <UncontrolledAlert
                                color="danger"
                                isOpen={errorVisible} 
                                toggle={onToggleError}
                            >
                                <span className="alert-inner--text">
                                    <strong>Error!</strong> {errorMessage}
                                </span>
                            </UncontrolledAlert>
                        </CardBody>
                    </Card>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={() => props.closeModal(false)}>
						Cancel
					</Button>
					<Button color="primary" type="submit">
						Save
					</Button>{" "}
				</ModalFooter>
			</Form>
		</Modal>
	);
}
