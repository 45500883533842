/**
 * Checks the user's id against the role id
 * @param {Object} data 
 */
const idCheck = (data) => {
    if (!data.userId || !data.roleId)
        return false;
    return data.userId === data.roleId;
}

/**
 * Checks if the user is a member of the project.
 * Checks if the project id is in the user project's id list.
 * @param {Object} data 
 */
const projectMemberCheck = (data) => {
    if (!data.projectId || !data.projectList)
        return false
    return data.projectList.includes(data.projectId);
    
}

const projectEditabilityCheck = (data) => {
  if (data.userId && data.adminIds)
    return data.adminIds.find((x) => x.id === data.userId);
  else if (data.userId && data.roleId) return data.userId === data.roleId;
  return false;
};

const DYNAMIC_FUNCTIONS = {
    idCheck,
    projectMemberCheck,
    projectEditabilityCheck
}

export default DYNAMIC_FUNCTIONS;