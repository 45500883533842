import React, { useState } from "react";
import {DataTable} from 'primereact/datatable';
import { Column } from 'primereact/column';
import OptionsRendererPrime from "components/OptionsRenderer/OptionsRendererPrime";
import TableActionButtons from "components/OptionsRenderer/TableActionButtons";
import ENUMS from 'constants/appEnums';
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker";
import { Input } from "reactstrap";
import { parse } from "date-fns";
import DateTimeUtils from "util/DateTimeUtils";
import Select from 'react-select';
import { useSelector } from "react-redux";
import _ from 'lodash';
import TaskUtil from 'util/TaskUtil';
import 'primereact/resources/themes/mdc-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './CustomTablePrime.css';
import DataOptions from "constants/appDataOptions";

export default function CustomTablePrime (props) {
    
    const [editingCellRows, setEditingCellRows] = useState([]);

    const onEditorInit = ({columnProps}) => {
        const { rowIndex, field, rowData } = columnProps;
        if (!editingCellRows[rowIndex]) {
            editingCellRows[rowIndex] = {...rowData};
        }
        const newEditingCellRows = [...editingCellRows];
        newEditingCellRows[rowIndex][field] = props.rowData[rowIndex][field];
        setEditingCellRows(newEditingCellRows);
    }

    const onEditorSubmit = ({columnProps}) => {
        const { rowIndex, field } = columnProps;
        const newEditingCellRows = [...editingCellRows];
        delete newEditingCellRows[rowIndex][field];
        setEditingCellRows(newEditingCellRows);
    }

    // value field, incase the view is different than the value
    const inputTextEditor = (tableProps, field, valueField) => {
        return <Input 
        className="form-control-alternative"
        type="text"
        value={valueField ? tableProps.rowData[valueField] : tableProps.rowData[field]} 
        onChange={(e)=>props.onEditorValueChange(tableProps, e.target.value)}
    />;
    }

    const inputAreaTextEditor = (tableProps, field) => {
        return <Input 
        className="form-control-alternative"
        type="textarea"
        rows="4"
        value={tableProps.rowData[field]}
        onChange={(e)=>props.onEditorValueChange(tableProps, e.target.value)}
    />;
    }

    // value field, incase the view is different than the value
    const numberTextEditor = (tableProps, field, valueField) => {
        return <Input 
        className="form-control-alternative"
        type="number"
        defaultValue={valueField ? tableProps.rowData[valueField] : tableProps.rowData[field]} 
        onChange={(e) => props.onEditorValueChange(tableProps, e.target.value)} 
        />;
    }

    const dateTimeEditor = (tableProps, field, disableCheck) => {
        return <CustomDatePicker
            selected={parse(
                tableProps.rowData[field],
                DateTimeUtils.getUserDateTimeFormat(props.user.date_format, 
                    props.user.twenty_four_hour_clock),
                new Date()
            )}
            onChange={date => props.onEditorValueChange(tableProps, date)}
            disabled={disableCheck ? tableProps.rowData.PredecessorTask.length > 0 : false}
            showTimeSelect={true}
            renderToEl={props.renderToEL}
        />
        // return <DatePicker
        //     selected={new Date(tableProps.rowData[field])}
        //     onChange={date => props.onEditorValueChange(tableProps, date)}
        //     className="form-control form-control-alternative react-datepicker-manager"
        //     showTimeSelect
        //     timeIntervals={15}
        //     dateFormat={DateTimeUtils.getUserDateTimeFormat(props.user.date_format, 
        //         props.user.twenty_four_hour_clock)}
        //     placeholderText={(tableProps.rowData.PredecessorTask.length > 0 || 
        //         tableProps.rowData.task_status === 'F') ? 
        //         "Disabled because task has predecessors" : 
        //         "Enter Task Start Time"}
        //     disabled={tableProps.rowData.PredecessorTask.length > 0}
        // />
    }

    const dropdownTaskOwnerEditor = (tableProps, field) => {
        return <Select
                className="form-control-alternative"
                isClearable
                onChange={(newValue) => props.onEditorValueChange(tableProps, newValue)}
                options={props.taskOwnerList}
                value={props.taskOwnerList.filter(owner => 
                    owner.value === tableProps.rowData['task_owner'])[0]
                }
                menuPortalTarget={document.getElementById(props.renderToEL)}
                menuPosition={"fixed"}
                styles={
                    {
                        menu: (css) => ({
                            ...css,
                            width: 200,
                          }),
                    }
                }
            />
    }

    // const handleTaskGroupCreation = (inputValue) => {
    //     // handle task group creation
    //     API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TASK_CREATE_TASK_GROUP,
    //     {
    //         ...HttpUtil.adminHttpHeaders(),
    //         body: 
    //         {
    //             projectId: props.projectId,
    //             groupName: inputValue
    //         }
    //     })
    //     .then(res => {
    //         if (res.status === 200 && res.data.success) {
    //             setTaskGroup(GroupUtil.formatGroupForDropdown(res.data.createdGroup));
    //             setGroupList([...groupList, GroupUtil.formatGroupForDropdown(res.data.createdGroup)]);
    //         }
    //     })
    //     .catch(error => {
    //         if (error.response) {
    //             console.error(error.response);
    //             addToast(
    //                 "Failed to create group.", 
    //                 { 
    //                     appearance: 'error'
    //                 }
    //             );
    //         }
    //     });
    // }

    // const formatDropDownLabelGroup = (inputValue) => {
    //     return "Create Group - "+ inputValue;
    // }

    const dropdownGroupEditor = (tableProps, field) => {
        return <Select
            className="form-control-alternative"
            isClearable
            onChange={(newValue) => props.onEditorValueChange(tableProps, newValue)}
            // onCreateOption={handleTaskGroupCreation}
            // createOptionPosition="first"
            // formatCreateLabel={formatDropDownLabelGroup}
            options={props.projectGroupList}
            value={props.projectGroupList.filter(group => 
                group.label === tableProps.rowData['group_name'])[0]
            }
            menuPortalTarget={document.getElementById(props.renderToEL)}
            menuPosition={"fixed"}
        />
    }

    const dropdownStatusEditor = (tableProps, field) => {
        return <Select
            className="form-control-alternative"
            isClearable
            onChange={(newValue) => props.onEditorValueChange(tableProps, newValue)} 
            options={TaskUtil.taskStatusTypesInlineEdit}
            value={TaskUtil.taskStatusTypesInlineEdit.filter(statusType => statusType.value === tableProps.rowData['task_status'])}
            menuPortalTarget={document.getElementById(props.renderToEL)}
            menuPosition={"fixed"}
            styles={
                {
                    menu: (css) => ({
                        ...css,
                        width: '200px',
                      }),
                }
            }
        />
    }

    const dropdownPercentageCompleteEditor = (tableProps, field) => {
        return <Select
            className="form-control-alternative"
            isClearable
            onChange={(newValue) => props.onEditorValueChange(tableProps, newValue)} 
            options={DataOptions.TASK_PERCENTAGE_COMPLETE_OPTIONS}
            value={DataOptions.TASK_PERCENTAGE_COMPLETE_OPTIONS.filter(pc => pc.value === tableProps.rowData['percentage_complete'])}
            menuPortalTarget={document.getElementById(props.renderToEL)}
            menuPosition={"fixed"}
            styles={
                {
                    menu: (css) => ({
                        ...css,
                        width: '200px',
                      }),
                }
            }
        />
    }

    const dropdownRiskRatingEditor = (tableProps, field) => {
        console.log(DataOptions.RISK_RATING_OPTIONS.filter(option => option.value === tableProps.rowData[field]))
        return <Select
            className="form-control-alternative"
            onChange={(newValue) => props.onEditorValueChange(tableProps, newValue.value)} 
            options={DataOptions.RISK_RATING_OPTIONS}
            value={DataOptions.RISK_RATING_OPTIONS.filter(option => option.value === tableProps.rowData['og_' + field + '_value'])}
            menuPortalTarget={document.getElementById(props.renderToEL)}
            menuPosition={"fixed"}
            styles={
                {
                    menu: (css) => ({
                        ...css,
                        width: '200px',
                      }),
                }
            }
        />
    }

    const dropdownEditor = (tableProps, field, optionList) => {
        return <Select
            className="form-control-alternative"
            onChange={(newValue) => props.onEditorValueChange(tableProps, newValue.value)} 
            options={optionList}
            value={optionList.filter(option => option.value === tableProps.rowData[field + '_value_field'])}
            menuPortalTarget={document.getElementById(props.renderToEL)}
            menuPosition={"fixed"}
            styles={
                {
                    menu: (css) => ({
                        ...css,
                        width: '200px',
                    }),
                }
            }
        />
    }

    const emptyValueValidator = ({columnProps}) => {
        const { rowData, field } = columnProps;
        return rowData[field] && rowData[field].toString().trim().length > 0;
    }

    const positiveNumberValidator = ({columnProps}) => {
        const { rowData, field } = columnProps;
        return parseInt(rowData[field]) >= 0;
    }

    const dynamicColumns = props.columnDefs.map((col,i) => {
        let editorValue = null;
        let validator = null;
        if (props.editMode === 'cell' && col.editor) {
            if (col.editor === 'text') {
                editorValue = (tableProps) => inputTextEditor(tableProps, col.field, col.valueField);
            }
            else if (col.editor === 'textarea') {
                editorValue = (tableProps) => inputAreaTextEditor(tableProps, col.field);
            }
            else if (col.editor === 'number') {
                editorValue = (tableProps) => numberTextEditor(tableProps, col.field, col.valueField);
            }
            else if (col.editor === 'datetime') {
                editorValue = (tableProps) => dateTimeEditor(tableProps, col.field, true);
            }
            else if (col.editor === 'datetimeWoDisableCheck') {
                editorValue = (tableProps) => dateTimeEditor(tableProps, col.field, false);
            }
            else if (col.editor === 'dropdownTaskOwner') {
                editorValue = (tableProps) => dropdownTaskOwnerEditor(tableProps, col.field);
            }
            else if (col.editor === 'dropdownGroup') {
                editorValue = (tableProps) => dropdownGroupEditor(tableProps, col.field);
            }
            else if (col.editor === 'dropdownStatus') {
                editorValue = (tableProps) => dropdownStatusEditor(tableProps, col.field);
            }
            else if (col.editor === 'dropdownPercentageComplete') {
                editorValue = (tableProps) => dropdownPercentageCompleteEditor(tableProps, col.field);
            }
            else if (col.editor === 'dropdownRiskRating') {
                editorValue = (tableProps) => dropdownRiskRatingEditor(tableProps, col.field);
            }
            else if (col.editor === 'dropdownTeam') {
                editorValue = (tableProps) => dropdownEditor(tableProps, col.field, props.teamsList);
            }
            else if (col.editor === 'dropdownRaidStatus') {
                editorValue = (tableProps) => dropdownEditor(tableProps, col.field, DataOptions.RAID_STATUS_OPTIONS);
            }
            else if (col.editor === 'dropdownOwner') {
                editorValue = (tableProps) => dropdownEditor(tableProps, col.field, props.userList);
            }
            else if (col.editor === 'dropdownLikelihood') {
                editorValue = (tableProps) => dropdownEditor(tableProps, col.field, DataOptions.RISK_LIKELIHOOD_OPTIONS);
            }
            else if (col.editor === 'dropdownImpact') {
                editorValue = (tableProps) => dropdownEditor(tableProps, col.field, DataOptions.RISK_IMPACT_OPTIONS);
            }
            else if (col.editor === 'dropdownMitigationOwner') {
                editorValue = (tableProps) => dropdownEditor(tableProps, col.field, props.userList);
            }
            else if (col.editor === 'dropdownYesNo') {
                editorValue = (tableProps) => dropdownEditor(tableProps, col.field, DataOptions.YES_NO_OPTIONS);
            }
            else if (col.editor === 'dropdownSeverity') {
                editorValue = (tableProps) => dropdownEditor(tableProps, col.field, DataOptions.ISSUE_SEVERITY_OPTIONS);
            }
        }
        if (props.editMode === 'cell' && col.validator) {
            if (col.validator === 'empty') {
                validator = (tableProps) => emptyValueValidator(tableProps);
            }
            else if (col.validator === 'positiveNumber') {
                validator = (tableProps) => positiveNumberValidator(tableProps);
            }
        }
        return <Column 
            className={col.className}
            columnKey={col.field} 
            editor={editorValue}
            editorValidator={validator}
            field={col.field} 
            filter
            filterElement={col.filterElement}
            filterFunction={col.filterFunction}
            filterMatchMode={col.filterFunction ? "custom" : "contains"}
            filterPlaceholder={col.filterPlaceholder ? col.filterPlaceholder : "Search..."}
            frozen={col.frozen ? true : false}
            header={col.headerName}
            headerStyle={{ width: '150px', whiteSpace: 'nowrap' }}
            key={col.field} 
            onEditorCancel={col.editor !== null ? (tableProps) => props.onEditorCancel(tableProps, editingCellRows) : null} 
            onEditorInit={col.editor !== null ? onEditorInit : null} 
            onEditorSubmit={col.editor !== null ? onEditorSubmit : null}
            sortable={col.sortable}
            sortFunction={col.sortFunction}
        />;
    });

    const actionBodyTemplate = (rowData) => {
        return (
            <OptionsRendererPrime 
                optionsList={props.optionsList} 
                data={rowData} 
                projectOwnerId={props.projectOwnerId}
            />
        );
    }

    const actionButtonsTemplate = (rowData) => {
        return (
            <TableActionButtons
                buttonsList={props.buttonList}
                data={rowData}
            />
        )
    }

    const groupHeaderTemplate = (data) => {
        return (
            <React.Fragment>
                <span className="image-text">{data.group_name}</span>
            </React.Fragment>
        );
    }

    const footerTemplate = (data) => {
        return (
            <React.Fragment>
                <td />
            </React.Fragment>
        );
    }

    return (
        <>
            <DataTable 
                // classname
                className={
                    "datatable-max-height " +
                    (props.className ? props.className : '') + 
                    (props.noHeight ? 'no-min-height' : '') 
                }
                emptyMessage={ENUMS.TABLE_CONSTANTS.EMPTY_MESSAGE}

                // properties
                value={props.rowData} 
                
                // sort
                // sortMode={ENUMS.TABLE_CONSTANTS.SORT_MODE}
                removableSort
                sortField={props.sortField}
                sortOrder={props.sortOrder}
                onSort={props.onSort}
                
                // paginator
                paginator={props.showPaginator ? props.rowData.length > ENUMS.TABLE_CONSTANTS.MIN_ROWS ? true : false : false}
                rows={parseInt(props.rows)}
                rowsPerPageOptions={[10,20,30,40,50,60,70,80,90,100]}
                pageLinkSize={5}
                paginatorTemplate={ENUMS.TABLE_CONSTANTS.PAGINATOR_TEMPLATE}
                currentPageReportTemplate={ENUMS.TABLE_CONSTANTS.CURRENT_PAGE_TEMPLATE}
                first={parseInt(props.first)}
                onPage={(e) => props.setOnPage(e)}
                
                // filter
                globalFilter={props.quickFilterText}
                
                // loading
                loading={props.loading}

                // editing
                editMode={props.editMode}

                // column ordering, resize and scroll
                resizableColumns={props.reorderableColumns ? props.reorderableColumns : false}
                columnResizeMode={ENUMS.TABLE_CONSTANTS.COL_RESIZE_MODE}
                reorderableColumns={props.reorderableColumns ? props.reorderableColumns : false}
                scrollable
                scrollHeight="500px"
                responsive
                rowHover

                // frozen column
                frozenWidth={props.frozenWidth ? props.frozenWidth : ""}

                // state storage
                stateStorage={ENUMS.TABLE_CONSTANTS.LOCAL}
                stateKey={ENUMS.DT_STATE_STORAGE_NAMES.PREFIX + props.stateStorageName}

                // grouping
                rowGroupMode={props.rowGroupMode}
                groupField={props.rowGroupField}
                expandableRowGroups={props.expandableRowGroups}
                expandedRows={props.expandedRows} 
                onRowToggle={(e) => {props.onRowGroupToggle(e)}}
                rowGroupHeaderTemplate={groupHeaderTemplate}
                rowGroupFooterTemplate={footerTemplate}

                // reference
                ref={(el) => props.setTableRef(el)}
            >
                {dynamicColumns}
                {
                    props.buttonList &&
                    <Column 
                        headerStyle={{ width: '3rem', textAlign: 'center' }} 
                        bodyStyle={{textAlign: 'center', overflow: 'visible'}}
                        body={actionButtonsTemplate}
                    />
                }
                {
                    props.optionsList &&
                    <Column 
                        headerStyle={{ width: '3rem', textAlign: 'center' }} 
                        bodyStyle={{textAlign: 'center', overflow: 'visible'}}
                        body={actionBodyTemplate}
                        appendTo="body"
                    />
                }
            </DataTable>
        </>
    )
}
