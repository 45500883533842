import React from "react";
import DatePicker from "react-datepicker";
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useSelector } from "react-redux";
import Timezones from "util/TimezonesUtil";
import DateTimeUtils from "util/DateTimeUtils";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatePicker.css";
import { Portal } from 'react-overlays';

export default function CustomDatePicker(props) {

    // grab current state
    const user = useSelector(state => state.authReducer.user);

    const setLocalZone = (date) => {
        const utcTime = zonedTimeToUtc(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
        const timezoneValue = Timezones.find(
            (t) => t.value.indexOf(user.timezone) > -1
        ).utc[0];
        return utcToZonedTime(utcTime, timezoneValue);
    }

    /**
     * Calendar needs to contain a parent element
     * to render it on the specific element
     * 
     * props.renderToEl will contain the id of the CustomDatePicker container
     */
    const getCalendarContainer = ({children}) => {
        const el = document.getElementById(props.renderToEl);
        
        return (
            <Portal container={el}>
                {children}
            </Portal>
        )
    }

    return (
        <DatePicker
            id={props.id}
            name={props.name}
            className="form-control form-control-alternative react-datepicker-manager"
            selected={props.selected ? setLocalZone(props.selected) : null}
            onChange={date => props.onChange(setLocalZone(date))}
            showTimeSelect={props.showTimeSelect === false ? 
                false : 
                true}
            timeIntervals={15}
            dateFormat={props.showTimeSelect === false ? 
                DateTimeUtils.getUserDateTimeFormat(
                    user.date_format) : 
                DateTimeUtils.getUserDateTimeFormat(
                    user.date_format, 
                    user.twenty_four_hour_clock)}
            placeholderText={props.placeholderText}
            disabled={props.disabled}
            popperContainer={getCalendarContainer}
            autoComplete="off"
        />
    )
}