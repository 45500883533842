const formatUserForDropdown = (user) => {
    if (user.first_name && user.last_name) {
        return {
            value: user.id,
            label: user.first_name + 
                        " " + 
                        user.last_name + 
                        " (" + 
                        user.email +
                        ")"
        };
    }
    else {
        return {
            value: user.id,
            label: user.email
        };
    }
}

const formatUserForDropdownBulk = (userList) => {
    return userList.map(user => {
        return {...formatUserForDropdown(user)};
    });
}

const formatUserForTableColumn = (user) => {
    if (user.first_name && user.last_name) {
        return user.first_name + " " + user.last_name;
    }
    else {
        return user.email;
    }
}

const formatUserForInitials = (user) => {
    let names = (user.first_name + " " + user.last_name).split(' '),
        initials = names[0].substring(0, 1).toUpperCase();
    
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
}

const formatUserForTableColumnInline = (user) => {
    if (user.label.length > 0) {
        return user.label;
    }
}

const formatLicenceForDropdownBulk = (licenceList) => {
    return licenceList.map(licence => {
        return {
            value: licence.id,
            label: licence.licence_name,
            type: licence.licence_type
        };
    });
}

const UserUtil = {
    formatUserForDropdown,
    formatUserForDropdownBulk,
    formatUserForTableColumn,
    formatUserForInitials,
    formatUserForTableColumnInline,
    formatLicenceForDropdownBulk
};

export default UserUtil;