import React, { useState, useEffect } from "react";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader, 
    CardBody,
    Button,
    Input
} from "reactstrap";
import ENUMS from "constants/appEnums";
import CustomTablePrime from "components/CustomTable/CustomTablePrime";
import TableHeadersUtil from "util/TableHeadersUtil";
import { format } from 'date-fns';
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import { useToasts } from 'react-toast-notifications';
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import UserUtil from "util/UserUtil";
import TaskUtil from "util/TaskUtil";
import AddEditActionModal from "components/Action/AddEditActionModal/AddEditActionModal";
import DateTimeUtils from "util/DateTimeUtils";
import htmlToDraft from 'html-to-draftjs';
import { useSelector } from "react-redux";
import _ from "lodash";
import GeneralUtils from "util/GeneralUtils";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import InputGroupText from "reactstrap/lib/InputGroupText";
import AddEditStakeholderModal from "components/Stakeholder/AddEditStakeholderModal";
import parse from 'html-react-parser';
import ViewStakeholderModal from "components/Stakeholder/ViewStakeholderModal";
import AddEditAssumptionModal from "./Assumption/AddEditAssumptionModal";
import TeamUtil from "util/TeamUtil";
import ViewRaidModal from "./ViewRaidModal";
import RULES from "rbac/rules";

export default function ProjectViewAssumptionTable (props) {

    const { addToast } = useToasts();

    // Team Data and Header for Table
    const [assumptionList, setAssumptionList] = useState([]);
    const [assumptionListHeader, setAssumptionListHeader] = useState([]);

    // Table props
    const [loading, setLoading] = useState(true);
    const [tableRef, setTableRef] = useState(null);
    
    const [quickFilterText, setQuickFilterText] = useState("");

    /**
     * Delete Modal
     */
    const [showDeleteAssumptionModal, setShowDeleteAssumptionModal] = useState(false);
    const [deleteAssumptionModalText, setDeleteAssumptionModalText] = useState("");
    const [selectedAssumptionId, setSelectedAssumptionId] = useState("");
    const [deleteModalAssumptionTexts, setDeleteModalAssumptionTexts] = useState({
        'confirm': ENUMS.BUTTON_TEXT.DELETE_ACTION_TEXT_CONFIRM,
        'cancel': ENUMS.BUTTON_TEXT.CANCEL
    });

    // Current User
    const user = useSelector(state => state.authReducer.user);

    /** ADD/EDIT Assumption modal */
    const [showAddEditAssumptionModal, setShowAddEditAssumptionModal] = useState(false);
    const [modalMode, setModalMode] = useState("");
    const [editAssumptionId, setEditAssumptionId] = useState(null);

    // editable table
    const [editTableText, setEditTableText] = useState(ENUMS.BUTTON_TEXT.EDIT_TABLE);
    const [tableEditMode, setTableEditMode] = useState(null);
    const [editedRows, setEditedRows] = useState([]);
    const [preEditTableData, setPreEditTableData] = useState([]);

    const [teamsList, setTeamsList] = useState([]);
    const [userList, setUserList] = useState([]);
    
    /**
     * View Modal
     */
    const [viewAssumptionModalOpen, setViewAssumptionModalOpen] = useState(false);
    const [assumptionToView, setAssumptionToView] = useState(null);

    // TABLE filtering and sorting
    const [ogAssumptionList, setOgAssumptionList] = useState([]);
    const [colKeyList, setColKeyList] = useState(
        ['assumption_id','created_at','team_name','description','status_value_field','owner','notes','note_view']
    );

    const populateAssumptionTable = () => {
        populateAssumptionHeaders();
        if (props.projectAssumptionList && 
            props.projectAssumptionList.length > 0) {
            populateAssumptionData();
        } else {
            setAssumptionList([]);
        }
    }

    const populateAssumptionHeaders = () => {
        /**
         * Columns object to be passed to table
         */
        setAssumptionListHeader(TableHeadersUtil.projectViewAssumptionTableHeaders);
    }

    const populateAssumptionData = () => {
        const updatedAssumptionList = props.projectAssumptionList.map((assumption, index) => {
            assumption['assumption_number_link'] = GeneralUtils.viewPageLink(
                assumption.id,
                index +1,
                ENUMS.VIEW_PATH.ASSUMPTION,
                '?assumptionId=' + assumption.id,
                {passedId: assumption.id}
            );
            assumption['created_at'] = format(DateTimeUtils.convertTimezone(
                new Date(assumption.createdAt), user), 
                DateTimeUtils.getUserDateTimeFormat(user.date_format, user.twenty_four_hour_clock));
            assumption['team_name_value_field'] = assumption.Team.id;
            assumption['team_name'] = assumption.Team.team_name;
            assumption['description'] = assumption.description;
            assumption['status_value_field'] = assumption.status;
            assumption['status'] = ENUMS.RAID_STATUS[assumption.status];
            assumption['owner_value_field'] = assumption.AssumptionOwner.id;
            assumption['owner'] = UserUtil.formatUserForTableColumn(assumption.AssumptionOwner);
            assumption['notes'] = assumption.notes;
            assumption['note_view'] = parse('<div class="table-note-display">' + assumption.notes + '</div>');
            return assumption;
        });
        setAssumptionList(updatedAssumptionList);
        setOgAssumptionList(updatedAssumptionList);
    }

    const openViewAssumptionModal = (data) => {
        setViewAssumptionModalOpen(true);
        setAssumptionToView(data);
    }


    const openDeleteAssumptionModal = (assumption) => {
        setShowDeleteAssumptionModal(true);
        setDeleteAssumptionModalText(ENUMS.MODAL.DELETE_CONFIRMATION_TEXT + assumption.description + " assumption ?");
        setSelectedAssumptionId(assumption.id);
    }

    const hideDeleteAssumptionModal = () => {
        setShowDeleteAssumptionModal(false);
    }

    const deleteAssumptionConfirmed = (assumptionId) => {
        API.del(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_ASSUMPTION_DELETE, 
        {
            ...HttpUtil.adminHttpHeaders(),
            body:  {
                assumption_id: assumptionId
            }
        })
        .then(res => {
            if(res && res.status === 200) {
                refreshPageOnSubmit("Assumption has been deleted successfully");
            }
        })
        .catch(error => {
            if (error.response) {
                console.log(error.response);
                addToast(
                    "Failed to delete assumption.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    /**
     * Fetches the user list for populating the dropdowns
     */
     const fetchUserList = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.USER_ALL_ORGANIZATION,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                project_id: props.projectDetails.id
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setUserList(res.data.userList);
            }
        })
        .catch(error => {
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch user list for owner selection", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const closeViewModal = () => {
        setViewAssumptionModalOpen(false);
    }

    /**
     * Method to handle opening the task modal in edit mode
     */
    const openEditAssumptionModal = (assumption) => {
        setModalMode(ENUMS.MODAL.EDIT_MODE);
        setEditAssumptionId(assumption.id);
        setShowAddEditAssumptionModal(true);
    }

    const optionsList = React.useMemo(() => 
    [
        {
            itemName: 'View',
            itemType: 'View',
            openViewModal: openViewAssumptionModal,
        },
        {
            itemName: 'Edit',
            itemType: 'Edit',
            openEditModal: openEditAssumptionModal,
            role: [RULES.ROLE_NAMES.PROJECT_OWNER, RULES.ROLE_NAMES.TEAM_LEADER, RULES.ROLE_NAMES.ASSUMPTION_OWNER],
            perform: RULES.RULE_NAMES.ASSUMPTION_EDIT,
        },
        {
            itemName: 'Delete',
            itemType: ENUMS.RENDER_OPTIONS_TYPE.DELETE,
            delete: openDeleteAssumptionModal,
            role: [RULES.ROLE_NAMES.PROJECT_OWNER, RULES.ROLE_NAMES.TEAM_LEADER, RULES.ROLE_NAMES.ASSUMPTION_OWNER],
            perform: RULES.RULE_NAMES.ASSUMPTION_DELETE,
        }
    ], []);

    /**
     * Function to handle custom global filtering
     * @param {*} value 
     */
    const handleGlobalFiltering = (value) => {
        setQuickFilterText(value);
        let filteredAssumption = _.cloneDeep(ogAssumptionList);

        if (value !== '') {
            const lowerVal = value.toLowerCase();
            filteredAssumption = filteredAssumption.filter(assumption => {
                return colKeyList.some(col => {
                    if (assumption[col] !== null) {
                        return assumption[col] && assumption[col].toString().toLowerCase().includes(lowerVal);
                    }
                })
            });
        }
        setAssumptionList(filteredAssumption);
    }

    /**
     * Method to handle the exporting to csv.
     * @param {Object} e 
     */
    const exportAssumptionList = (e) => {
        e.preventDefault();
        console.log(assumptionList);
        let csvContent = "data:text/csv;charset=utf-8,";
        let headers = "S.No.,Project ID,Project Name,Date Created,Team,Description,Status,Owner\r\n";
        csvContent += headers;
        assumptionList.forEach((assumption, index) => {
            let rowArray = [
                index + 1,
                props.projectDetails.id,
                GeneralUtils.handleStringForCSV(props.projectDetails.project_name),
                assumption.created_at,
                assumption.Team.team_name,
                GeneralUtils.handleStringForCSV(assumption.description),
                assumption.status_value_field,
                UserUtil.formatUserForTableColumn(assumption.AssumptionOwner),
            ];
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", props.projectDetails.project_name + "_assumptions.csv");
        document.body.appendChild(link); // Required for FF
        link.click(); // This will download the data file named "my_data.csv".
    }

    /**
     * EDIT TABLE FUNCTIONS STARTS HERE
     */

     const updateTableData = () => {
        let rowsToEdit = _.cloneDeep(editedRows).filter(row => row !== null && row !== undefined);
        const tableEdits = rowsToEdit.map(row => {
            return {
                id: row.id,
                project_id: row.project_id,
                team_id: row.team_name_value_field,
                description: row.description,
                status: row.status_value_field,
                owner_id: row.owner_value_field,
                notes: row.notes,
            }
        });
        API.put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_ASSUMPTION_UPDATE_TABLE_ROWS, 
        {
            ...HttpUtil.adminHttpHeaders(),
            body: 
            {
                editedRows: tableEdits,
                projectId: props.projectDetails.id
            }
        })
        .then(res => {
            if (res.status === 201 && res.data.success) {
                refreshPageOnSubmit("Assumptions have been updated successfully!");
                setEditedRows([]);
            }
        })
        .catch(error => {
            if (error.response) {
                addToast(
                    "Failed to update assumptions!", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const setEditableTable = () => {
        if (editTableText === ENUMS.BUTTON_TEXT.SAVE) {
            setEditTableText(ENUMS.BUTTON_TEXT.EDIT_TABLE);
            setTableEditMode(null);
            if (editedRows.length > 0) {
                updateTableData();
            }
            
        }
        else {
            setPreEditTableData(_.cloneDeep(assumptionList));
            setEditTableText(ENUMS.BUTTON_TEXT.SAVE);
            setTableEditMode(ENUMS.TABLE_CONSTANTS.EDIT_MODE_CELL);
        }
    }

    const setCancelEditableTable = () => {
        setAssumptionList(_.cloneDeep(preEditTableData));
        setEditTableText(ENUMS.BUTTON_TEXT.EDIT_TABLE);
        setTableEditMode(null);
    }

    const onEditorValueChange = (tableProps, value) => {
        let updatedProducts = _.cloneDeep(tableProps.value);
        if (tableProps.field === 'team_name' && value) {
            updatedProducts[tableProps.rowIndex]['team_name_value_field'] = value;
            updatedProducts[tableProps.rowIndex]['team_name'] = teamsList.find(t => t.value == value).label;
        }
        else if (tableProps.field === 'status' && value) {
            updatedProducts[tableProps.rowIndex]['status_value_field'] = value;
            updatedProducts[tableProps.rowIndex]['status'] = ENUMS.RAID_STATUS[value];
        }
        else if (tableProps.field === 'owner' && value) {
            updatedProducts[tableProps.rowIndex]['owner_value_field'] = value;
            updatedProducts[tableProps.rowIndex]['owner'] = userList.find(u => u.value == value).label;
        } else {
            updatedProducts[tableProps.rowIndex][tableProps.field] = value;
        }
        let updatedEditedRows = _.cloneDeep(editedRows);
        updatedEditedRows[tableProps.rowIndex] = updatedProducts[tableProps.rowIndex];
        setEditedRows(updatedEditedRows);
        setAssumptionList(updatedProducts);
    }

    const onEditorCancel = ({columnProps}, editingCellRows) => {
        const { rowIndex, field } = columnProps;
        let products = _.cloneDeep(assumptionList);
        products[rowIndex][field] = editingCellRows[rowIndex][field];
        delete editingCellRows[rowIndex][field];
        setAssumptionList(products);
    }

    /**
     * Method to reset the filters and sorting on the entire table. 
     */
    const resetTable = () => {
        tableRef.reset();
    }

    /**
     * Refreshes the page on submit of add/edit/delete form
     * @param {String} message 
     */
    const refreshPageOnSubmit = (message) => {
        setShowAddEditAssumptionModal(false);
        setShowDeleteAssumptionModal(false);
        props.refreshViewOnAction(ENUMS.TABS.ASSUMPTION_LIST, message);
    }

    const openAddAssumptionModal = () => {
        setModalMode(ENUMS.MODAL.ADD_MODE);
        setShowAddEditAssumptionModal(true);
    }

    const closeAddEditAssumptionModal = () => {
        setShowAddEditAssumptionModal(false);
    }

    useEffect (() => {
        populateAssumptionTable();
        setLoading(false);
    }, [props.projectAssumptionList]);

    useEffect(() => {
        fetchUserList();
        setTeamsList(TeamUtil.formatTeamForDropdownBulk(props.projectDetails.Teams));
    }, []);

    return (
        <>
            <Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="bg-secondary box-shadow-down card-border-radius-0">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center mb-4">
                                <Col xs="2">
                                    <h3 className="mb-0">Assumption List</h3>
                                </Col>
                                <Col className="text-right">
                                    {editTableText === ENUMS.BUTTON_TEXT.SAVE &&
                                        <Button
                                            color="secondary"
                                            onClick={setCancelEditableTable}
                                            size="md"
                                            disabled={assumptionList.length === 0}
                                        >
                                            Cancel Edits
                                        </Button>
                                    }
                                    <Button
                                        color="secondary"
                                        onClick={setEditableTable}
                                        size="md"
                                        disabled={assumptionList.length === 0}
                                    >
                                        {editTableText}
                                    </Button>
                                    <Button
                                        color="secondary"
                                        onClick={resetTable}
                                        size="md"
                                    >
                                        Reset Filters
                                    </Button>
                                    <Button
                                        color="secondary"
                                        onClick={e => exportAssumptionList(e)}
                                        size="md"
                                        disabled={assumptionList.length === 0}
                                    >
                                        Export
                                    </Button>
                                    <Button 
                                        size="md"
                                        color="primary"
                                        onClick={(e) => openAddAssumptionModal()}
                                    >
                                        Add asumption
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-2">
                                <Col xs="6" className="">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-search"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            size="md"
                                            type="text"
                                            id="quickFilterText"
                                            value={quickFilterText}
                                            onChange={e => handleGlobalFiltering(e.target.value)}
                                            placeholder="Search Table...">
                                        </Input>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <CustomTablePrime
                                columnDefs={assumptionListHeader} 
                                rowData={assumptionList}
                                loading={loading}
                                optionsList={optionsList}
                                tableRef={tableRef}
                                setTableRef={setTableRef}
                                onEditorValueChange={onEditorValueChange}
                                onEditorCancel={onEditorCancel}
                                editMode={tableEditMode}
                                userList={userList}
                                teamsList={teamsList}
                                user={user}
                                projectOwnerId={props.projectDetails.project_owner_id}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                showDeleteAssumptionModal && 
                <ConfirmationModal
                    actionText={deleteModalAssumptionTexts}
                    onCancel={hideDeleteAssumptionModal}
                    onConfirm={deleteAssumptionConfirmed}
                    modalBody={deleteAssumptionModalText}
                    showModal={showDeleteAssumptionModal}
                    modalTitle={ENUMS.MODAL.DELETE_ASSUMPTION_TITLE}
                    data={selectedAssumptionId}
                />
            }
            {
                showAddEditAssumptionModal && 
                <AddEditAssumptionModal
                    modalTitle={modalMode === ENUMS.MODAL.ADD_MODE ? ENUMS.MODAL.ADD_ASSUMPTION_TITLE : ENUMS.MODAL.EDIT_Assumption_TITLE}
                    modalButtonName={ ENUMS.MODAL.ADD_MODE ? ENUMS.BUTTON_TEXT.ADD : ENUMS.BUTTON_TEXT.SAVE }
                    modalOpen={showAddEditAssumptionModal}
                    closeModal={closeAddEditAssumptionModal}
                    projectData={props.projectDetails}
                    modalMode={modalMode}
                    refreshPageOnSubmit={refreshPageOnSubmit}
                    editAssumptionId={editAssumptionId}
                /> 
            }
            { 
                viewAssumptionModalOpen && 
                <ViewRaidModal
                    modalTitle="Assumption"
                    modalOpen={viewAssumptionModalOpen}
                    rowData={assumptionToView}
                    closeModal={closeViewModal}
                    list={TableHeadersUtil.projectViewAssumptionTableHeaders}
                /> 
            }
        </>
    )
}
