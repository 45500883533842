import React, { useEffect, useState } from "react";

// reactstrap components
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import "./ProjectViewHeader.css";
import { useSelector } from "react-redux";
import ViewMoreModal from "components/ViewMoreModal/ViewMoreModal";
import ENUMS from "constants/appEnums";

export default function ProjectViewHeader (props) {    
    let userFullName = "";
    
    if (props.projectDetails.User) {
        userFullName = props.projectDetails.User.first_name + " " + props.projectDetails.User.last_name;
    }
    
    /**
     * View Modal
     */
    const [viewMoreModalOpen, setViewMoreModalOpen] = useState(false);
    const [viewMoreModalTitle, setViewMoreModalTitle] = useState("Project Description");
    const [dataToView, setDataToView] = useState('');

    const openViewMoreModal = (data) => {
        setDataToView(data);
        setViewMoreModalOpen(true);
    }

    const closeViewMoreModal = () => {
        setViewMoreModalOpen(false);
    }

    return (
        <>
            <div className="header bg-info pb-7 pt-5 pt-md-8">
                <Container fluid>
                    <div className="header-body">
                        <Row>
                            <Col>
                                <Card className="black-back card-stats mb-4 mb-xl-0">
                                    <CardBody className="black-back">
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Open Tasks
                                                </CardTitle>
                                                <div className="h2 font-weight-bold mb-0 dt-text-white card-desc">
                                                    {props.openTasks}
                                                </div>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="black-back card-stats mb-4 mb-xl-0">
                                    <CardBody className="black-back">
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Target end date
                                                </CardTitle>
                                                <div className="h2 font-weight-bold mb-0 dt-text-white card-desc">
                                                    {props.targetDate ?  props.targetDate : '-'}
                                                </div>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="black-back card-stats mb-4 mb-xl-0">
                                    <CardBody className="black-back">
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Open Actions
                                                </CardTitle>
                                                <div className="h2 font-weight-bold mb-0 dt-text-white card-desc">
                                                    {props.openAction}
                                                </div>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="black-back card-stats mb-4 mb-xl-0">
                                    <CardBody className="black-back">
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Open Issues
                                                </CardTitle>
                                                <div className="h2 font-weight-bold mb-0 dt-text-white card-desc">
                                                    {props.openIssues}
                                                </div>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="black-back card-stats mb-4 mb-xl-0">
                                    <CardBody className="black-back">
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Open Risks
                                                </CardTitle>
                                                <div className="h2 font-weight-bold mb-0 dt-text-white card-desc">
                                                    {props.openRisks}
                                                </div>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="padding-row-2">
                            <Col>
                                <h2 className="text-white">
                                    Project Name:
                                </h2>
                                <p className="text-white">
                                    {props.projectDetails.project_name}
                                </p>
                            </Col>
                            <Col>
                                <h2 className="text-white">
                                    Project Type:
                                </h2>
                                <p className="text-white">
                                    {props.projectDetails.project_type}
                                </p>
                            </Col>
                            <Col>
                                <h2 className="text-white">
                                    Project Owner: 
                                </h2>
                                <p className="text-white">
                                    {userFullName}
                                </p>
                            </Col>
                            {
                                props.activeRehearsal ? 
                                <Col>
                                    <h2 className="text-white">
                                        Execution Mode: 
                                    </h2>
                                    <p className="text-white">
                                        {props.activeRehearsal.name} {ENUMS.REHEARSAL_STATE.active}
                                    </p>
                                </Col>
                                : '' 
                            }
                        </Row>
                        <Row>
                            <Col lg="7" md="10">
                                <h3 className="text-white">
                                    Project Description: 
                                </h3>
                                <p className="text-white">
                                    {
                                        props.projectDetails.project_description.slice(0, 220)
                                    }
                                    {
                                        props.projectDetails.project_description.length > 200 
                                        ? <span>
                                            ... <span className="cursor-pointer" onClick={(e) => openViewMoreModal(props.projectDetails.project_description)}><strong>View more</strong></span>
                                        </span> 
                                        : ""
                                    }
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            { 
                viewMoreModalOpen && 
                <ViewMoreModal
                    modalTitle={viewMoreModalTitle}
                    modalOpen={viewMoreModalOpen}
                    dataToView={dataToView}
                    closeModal={closeViewMoreModal}
                /> 
            }
        </>
    );
}