/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Fade from 'react-reveal/Fade'; // Importing Zoom effect
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import './LandingPage.css';

class FeaturesSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputFocus: false
    };
  }
  render() {
    return (
      <>
        <div className="section section-basic each-section" id="basic-elements">
          <img alt="..." className="path" src={require("assets/img/path1.png")} />
          <Container>
            <Row>
              <Col lg="12">
                <Row className="row-grid justify-content-center">
                  <Col lg="4">
                    <div className="info text-center landing-info">
                      <div className="icon icon-primary m-auto">
                        <i className="tim-icons icon-money-coins" />
                      </div>
                      <Fade left>
                        <h1 className="info-title">Collaboration</h1>
                      </Fade>
                      {/* <hr className="line-primary" /> */}
                      <Fade bottom>
                        <h4 className="text-gray">
                          Collaborate with all deployment team participants in the preparation, maintenance and the execution of your cutover plan.
                        </h4>
                      </Fade>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="info text-center mt-neg-50 landing-info">
                      <div className="icon icon-warning m-auto">
                        <i className="tim-icons icon-chart-pie-36" />
                      </div>
                      <Fade top>
                        <h1 className="info-title">Visibility</h1>
                      </Fade>
                      {/* <hr className="line-warning" /> */}
                      <Fade bottom>
                        <h4 className="text-gray">
                        CutOverPlan is highly accessible and visible, so that at any time, any permitted user may view and/or edit the plan on demand.
                        </h4>
                      </Fade>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="info text-center landing-info">
                      <div className="icon icon-success m-auto">
                        <i className="tim-icons icon-single-02" />
                      </div>
                      <Fade right duration={1300} delay={400}>
                        <h1 className="info-title">Contextual Information</h1>
                      </Fade>
                      {/* <hr className="line-success" /> */}
                      <Fade bottom duration={1300} delay={400}>
                        <h4 className="text-gray">
                          All information needed to support each task (eg. scripts, work instructions, test plans, configuration files, external links, etc.) may be contextually stored within CutOverPlan so that it is readily accessible to all permitted users.
                        </h4>
                      </Fade>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default FeaturesSection;
