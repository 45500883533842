import React, { useEffect } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
    Col,
    Row
} from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import Card from "reactstrap/lib/Card";
import parse from 'html-react-parser';
import UserUtil from "util/UserUtil";
import TaskUtil from "util/TaskUtil";
import { useSelector } from "react-redux";
import { format } from 'date-fns';
import DateTimeUtils from "util/DateTimeUtils";

export default function ViewActionModal(props) {
    // Current User
    const user = useSelector(state => state.authReducer.user);

	return (
		<Modal
			size="md"
			isOpen={props.modalOpen}
			toggle={() => props.closeModal(false)}
            backdrop="static"
		>
			<ModalHeader toggle={() => props.closeModal(false)} tag="h3">
				{props.modalTitle}
			</ModalHeader>
            <ModalBody className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                    <CardBody>
                        <Col>
                            <Row className="mb-3">
                                <Col xs="4">
                                    <div><strong>Action Name</strong></div>
                                    <div>{props.actionData.description}</div>
                                </Col>
                                <Col xs="4">
                                    <div><strong>Action Status</strong></div>
                                    <div>{TaskUtil.formatTaskStatusForTable(props.actionData.status)}</div>
                                </Col>
                                <Col xs="4">
                                    <div><strong>Task</strong></div>
                                    <div>{TaskUtil.formatPredTaskNameForTable(props.actionData.Task)}</div>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="mb-3">
                                <Col xs="6">
                                    <div><strong>Action Owner</strong></div>
                                    <div>{UserUtil.formatUserForTableColumn(props.actionData.ActionOwner)} {props.actionData.ActionOwner.email}</div>
                                </Col>
                                <Col xs="6">
                                    <div><strong>Due date</strong></div>
                                    <div>{
                                        format(DateTimeUtils.convertTimezone(
                                            new Date(props.actionData['due_date']), user), 
                                            DateTimeUtils.getUserDateTimeFormat(user.date_format, user.twenty_four_hour_clock))
                                    }</div>
                                </Col>
                                
                            </Row>
                            <hr />
                            <Row className="mb-3">
                                <Col xs="12">
                                    <div><strong>Note</strong></div>
                                    <div>{parse(props.actionData.note)}</div>
                                </Col>
                            </Row>
                            <hr />
                        </Col>
                    </CardBody>
                </Card>
            </ModalBody>
		</Modal>
	);
}
