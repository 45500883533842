import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import {
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Card,
	Row,
	Col,
	CardHeader,
	CardBody,
	Container,
    Button,
} from "reactstrap";
import classnames from "classnames";
import GeneralSettings from "./GeneralSettings/GeneralSettings";
import ProjectSettingsHeader from "components/Headers/ProjectSettingsHeader";
import AddEditCalendarModal from "./AddEditCalendarModal/AddEditCalendarModal";
import AddCalendarExceptionModal from "./AddCalendarExceptionModal/AddCalendarExceptionModal";
import CustomTablePrime from "components/CustomTable/CustomTablePrime";
import './ProjectSettings.css';
import ENUMS from 'constants/appEnums';
import { Redirect, useLocation } from "react-router-dom";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { useToasts } from "react-toast-notifications";
import { useSelector } from "react-redux";
import ListCalendarExceptionsModal from "./ListCalendarExceptionsModal/ListCalendarExceptionsModal";
import HttpUtil from "util/HttpUtil";
import TableHeadersUtil from "util/TableHeadersUtil";
import CheckPermissions from "components/CheckPermissions/CheckPermissions";
import RULES from "rbac/rules";
import APP_CONSTANTS from "constants/appConstants";
import NotificationSettings from "components/NotificationSettings/NotificationSettings";

export default function ProjectSettings(props) {

    /**
     * Location hook
     */
    const location = useLocation();

    /**
     * TOAST hook
     */
    const { addToast } = useToasts();

    const [calendars, setCalendars] = useState([]);
    const [activeTab, setActiveTab] = useState(ENUMS.TABS.GENERAL_STETTINGS);
    const [calendarId, setCalendarId] = useState("");
    const [projectId, setProjectId] = useState(null);
    const [projectSettings, setProjectSettings] = useState({});
    const [projectOwnerId, setProjectOwnerId] = useState(null);
    /**
     * ADD EDIT COPY CALENDAR MODAL
     */
    const [showAddEditCalendarModal, setShowAddEditCalendarModal] = useState(false);
    const [addEditCalendarModalTitle, setAddEditCalendarModalTitle] = useState("");
    const [calendarData, setCalendarData] = useState(null);
    const [addEditCalendarModalActionText, setAddEditCalendarModalActionText] = useState("");
    const [modalType, setModalType] = useState(ENUMS.MODAL.ADD_MODE);

    /**
     * PUBLIC HOLIDAY MODAL
     */
    const [showAddEditPublicHolidayModal, setShowAddEditPublicHolidayModal] = useState(false);
    const [showCalendarHolidaysListsModal, setShowCalendarHolidaysListsModal] = useState(false);
    
    /**
     * DELETE CALENDAR MODAL
     */
    const [showDeleteCalendarModal, setShowDeleteCalendarModal] = useState(false);
    const [deleteModalText, setDeleteModalText] = useState("");

    // table properties
    const [calendarColumns, setCalendarColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tableRef, setTableRef] = useState(null);

    // fetch redux state
    const user = useSelector(state => state.authReducer.user);
    const projectList = useSelector(state => state.authReducer.userProjectList);

    const openAddEditCalendarModal = (type, calendar = null) => {
        let title = "Create New Calendar";
        let actionText = "Add Calendar";
        switch (type) {
            case ENUMS.MODAL.EDIT_MODE:
                title = ENUMS.MODAL.EDIT_CALENDAR_TITLE;
                actionText = ENUMS.BUTTON_TEXT.SAVE; 
                setModalType(ENUMS.MODAL.EDIT_MODE);
                break;
            case ENUMS.MODAL.COPY_MODE:
                title = ENUMS.MODAL.COPY_CALENDAR_TITLE;
                actionText = ENUMS.BUTTON_TEXT.COPY_AND_CREATE_NEW; 
                setModalType(ENUMS.MODAL.COPY_MODE);
                break;
            case ENUMS.MODAL.ADD_MODE:
            default: 
                setModalType(ENUMS.MODAL.ADD_MODE);
                break;
        }
        
        setAddEditCalendarModalTitle(title);
        setAddEditCalendarModalActionText(actionText);
        setCalendarData(calendar);
        setShowAddEditCalendarModal(true);
    }

    const openAddEditPublicHolidayModal = (calendar) => {
        setCalendarData(calendar);
        setShowAddEditPublicHolidayModal(true);
    }

    const hidePublicHolidayModal = () => {
        setShowAddEditPublicHolidayModal(false);
    }

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

    const copyCalender = (data) => {
        openAddEditCalendarModal(ENUMS.MODAL.COPY_MODE, data);
    }

    const openEditModal = (data) => {
        openAddEditCalendarModal(ENUMS.MODAL.EDIT_MODE, data);
    }

    const openConfirmationModal = (calendar) => {
        setCalendarId(calendar.id);
        setDeleteModalText(ENUMS.MODAL.DELETE_CONFIRMATION_TEXT + calendar.calendar_name + " calendar ?");
        setShowDeleteCalendarModal(true);
    }

    const openPublicHolidaysModal = (calendar) => {
        setCalendarData(calendar);
        setShowCalendarHolidaysListsModal(true);
    }

    const checkUserProjectOwner = () => {
        return user.id === projectSettings.project_owner_id;
    };

    const optionsList = [
        {
            itemName: 'Copy Calendar',
            itemType: 'Copy Calendar',
            copyCalendar: copyCalender,
        },
        {
            itemName: 'Edit Calendar',
            itemType: 'Edit',
            openEditModal: openEditModal,
            checkPermission: checkUserProjectOwner,
            role: [RULES.ROLE_NAMES.PROJECT_OWNER],
            perform: RULES.RULE_NAMES.CALENDAR_EDIT,
        },
        {
            itemName: 'Add Calendar Exceptions',
            itemType: 'Add Public Holiday',
            addPublicHolidays: openAddEditPublicHolidayModal,
        },
        {
            itemName: 'View Calendar Exceptions',
            itemType: ENUMS.RENDER_OPTIONS_TYPE.CALENDAR_EXCEPTIONS_LIST,
            openPublicHolidays: openPublicHolidaysModal,
        },
        {
            itemName: 'Delete Calendar',
            itemType: ENUMS.RENDER_OPTIONS_TYPE.DELETE_CALENDAR,
            deleteCalendar: openConfirmationModal,
            checkPermission: checkUserProjectOwner,
            role: [RULES.ROLE_NAMES.PROJECT_OWNER],
            perform: RULES.RULE_NAMES.CALENDAR_DELETE,
        }
    ];

    /**
	 * get all projects assigned to the user
	 *
	 */
    const getAllCalendarOfProject = () => {
        if (projectSettings.hasOwnProperty('id')) {
            API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.CALENDAR_GET_ALL, 
            {
                ...HttpUtil.adminHttpHeaders(),
                queryStringParameters: 
                {
                    project_settings_id: projectSettings.id
                }
            })
            .then(res => {
                if(res && res.status === 200) {
                    setCalendars(res.data.map((cal, index) => {
                        return {
                            ...cal,
                            calendar_number: index +1
                        }
                    }));
                    setLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    const hideDeleteCalendarModal = () => {
        setShowDeleteCalendarModal(false);
    }

    const deleteCalendarConfirmed = (calendarIdToDelete) => {
        API
            .del(
                ENUMS.AWS.API_NAME,
                ENUMS.API_ROUTES.CALENDAR_DELETE,
                {
                    ...HttpUtil.adminHttpHeaders(),
                    body: {
                        calendar_id: calendarIdToDelete
                    }
            
                }
            )
            .then(res => {
                if(res && res.status === 200) {
                    addToast('Calendar has been deleted successfully!', { 
                        appearance: 'success',
                        autoDismiss: true
                    });
                    getAllCalendarOfProject();
                    setShowDeleteCalendarModal(false);
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response);
                    addToast(
                        "Failed to delete calendar", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
    }

    const getProjectSettings = () => {
        API.get(
            ENUMS.AWS.API_NAME,
            ENUMS.API_ROUTES.PROJECT_GET_SETTINGS, 
            {
                ...HttpUtil.adminHttpHeaders(),
                queryStringParameters: 
                {
                    project_id: projectId
                }
            })
            .then(res => {
                if(res && res.status === 200) {
                    setProjectSettings(res.data);
                }
            })
            .catch(error => {
                console.log(error);
            }
        );
    }

    /**
     * Fetches the project owner id for permissions
     */
    const getProjectOwnerId = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_GET_OWNER_ID, 
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                project_id: projectId
            }
        })
        .then(res => {
            if(res && res.status === 200) {
                setProjectOwnerId(res.data.projectOwner.project_owner_id);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    useEffect (() => {
        if (location.state && location.state.passedId) {
            setProjectId(location.state.passedId);
        } else if (location.search) {
            const params = new URLSearchParams(location.search);
            const projectIdSearch = params.get('projectId');
            setProjectId(projectIdSearch);
        }
    }, []);

    useEffect (() => {
        if (projectId) {
            getProjectSettings();
            getProjectOwnerId();
        }
    }, [projectId]);

    useEffect(() => {
        if(projectSettings) {
            setCalendarColumns(TableHeadersUtil.projectSettingCalendarTableHeaders);
            getAllCalendarOfProject();
        }
    }, [projectSettings, projectOwnerId]);

	return (
        <>
        { projectId && 
            <CheckPermissions
                role={[RULES.ROLE_NAMES.PROJECT_MEMBER]}
                perform={RULES.RULE_NAMES.PROJECT_VIEW}
                data={[{projectId: projectId, projectList: projectList}]}
                yes={() => {
                return <div>
                    <ProjectSettingsHeader />
                    {/* Page content */}
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="card-transparent">
                                    <CardHeader className="card-header-transparent border-0 pb-0">
                                        <Row className="align-items-center">
                                            <Col xs="12">
                                                <Nav tabs>
                                                    <NavItem className="settings-tabs">
                                                        <NavLink
                                                            className={classnames({ active: activeTab === ENUMS.TABS.GENERAL_STETTINGS })}
                                                            onClick={() => {toggle(ENUMS.TABS.GENERAL_STETTINGS);}}
                                                        >
                                                            General Settings
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="settings-tabs">
                                                        <NavLink
                                                            className={classnames({ active: activeTab === ENUMS.TABS.NOTIFICATION_STETTINGS })}
                                                            onClick={() => {toggle(ENUMS.TABS.NOTIFICATION_STETTINGS);}}
                                                        >
                                                            Notification Settings
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="settings-tabs">
                                                        <NavLink
                                                            className={classnames({ active: activeTab === ENUMS.TABS.PROJECT_CALENDARS })}
                                                            onClick={() => { toggle(ENUMS.TABS.PROJECT_CALENDARS); }}
                                                        >
                                                            Calendar
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody className="card-body-pt-0">
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId={ENUMS.TABS.GENERAL_STETTINGS}>
                                                {
                                                    projectId && 
                                                    <GeneralSettings 
                                                        calendarList={calendars}
                                                        projectSettings={projectSettings}
                                                        projectOwnerId={projectOwnerId}
                                                    />
                                                }
                                            </TabPane>
                                            <TabPane tabId={ENUMS.TABS.NOTIFICATION_STETTINGS}>
                                                {
                                                    projectId && 
                                                    <NotificationSettings 
                                                        projectId={projectId}
                                                    />
                                                }
                                            </TabPane>
                                            <TabPane tabId={ENUMS.TABS.PROJECT_CALENDARS}>
                                                <Row>
                                                    <Col className="order-xl-1" xl="12">
                                                        <Card className="bg-secondary box-shadow-down card-border-radius-0">
                                                            <CardHeader className="bg-white border-0">
                                                                <Row className="align-items-center">
                                                                    <Col xs="6">
                                                                        <h3 className="mb-0">Calendar List</h3>
                                                                    </Col>
                                                                    <Col className="text-right">
                                                                        <Button color="primary" onClick={(e) => openAddEditCalendarModal(ENUMS.MODAL.ADD_MODE)}>Add New Calendar</Button>
                                                                    </Col>
                                                                </Row>
                                                            </CardHeader>
                                                            <CardBody id="attachDropdownsAt">    
                                                                <CustomTablePrime
                                                                    columnDefs={calendarColumns} 
                                                                    rowData={calendars}
                                                                    loading={loading}
                                                                    optionsList={optionsList}
                                                                    tableRef={tableRef}
                                                                    setTableRef={setTableRef}
                                                                    stateStorageName={
                                                                        ENUMS.DT_STATE_STORAGE_NAMES.PROJECT_SETTING_CALENDAR + 
                                                                    '-' + projectId}
                                                                    projectOwnerId={projectOwnerId}
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                    {
                        showAddEditCalendarModal && 
                        <AddEditCalendarModal
                            closeModal={setShowAddEditCalendarModal}
                            refreshGrid={getAllCalendarOfProject}
                            showModal={showAddEditCalendarModal}
                            modalTitle={addEditCalendarModalTitle}
                            calendarData={calendarData}
                            modalType={modalType}
                            confirmActionText={addEditCalendarModalActionText}
                            projectSettingsId={projectSettings.id}
                        />
                    }

                    {
                        showAddEditPublicHolidayModal && 
                        <AddCalendarExceptionModal
                            onCancel={hidePublicHolidayModal}
                            showModal={showAddEditPublicHolidayModal}
                            calendar={calendarData}
                        />
                    }
                    {
                        showDeleteCalendarModal && 
                        <ConfirmationModal
                            actionText={APP_CONSTANTS.DELETE_MODAL_ACTION_TEXTS}
                            onCancel={hideDeleteCalendarModal}
                            onConfirm={deleteCalendarConfirmed}
                            modalBody={deleteModalText}
                            showModal={showDeleteCalendarModal}
                            modalTitle={ENUMS.MODAL.DELETE_CALENDAR_TITLE}
                            data={calendarId}
                        />
                    }
                    {
                        showCalendarHolidaysListsModal &&
                        <ListCalendarExceptionsModal
                            closeModal={setShowCalendarHolidaysListsModal}
                            showModal={showCalendarHolidaysListsModal}
                            modalTitle={ENUMS.MODAL.CALENDAR_EXCEPTIONS_LIST_TITLE}
                            calendar={calendarData}
                            confirmActionText={addEditCalendarModalActionText}
                            projectOwnerId={projectOwnerId}
                        />
                    }
                </div>
                }}
                no={() => {
                    return <Redirect to="/"/>
                }}
            />
        }
        </>
	);
}
