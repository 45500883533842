import React, { useState, useEffect, useRef } from "react";
import { 
    Modal, 
    ModalHeader,
    ModalBody,
    Card, 
    CardBody, 
    ModalFooter,
    Form, 
    FormGroup,
    Input, 
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button, 
    Label,
    Spinner,
    UncontrolledAlert,
    Collapse
} from "reactstrap";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import ENUMS from 'constants/appEnums';
import { useToasts } from 'react-toast-notifications';
import HttpUtil from "util/HttpUtil";
import { useForm } from 'react-hook-form';
import CustomErrorMessage from 'components/CustomErrorMessage/CustomErrorMessage';
import TeamUtil from 'util/TeamUtil';
import GroupUtil from 'util/GroupUtil';
import TaskUtil from "util/TaskUtil";
import DateTimeUtils from "util/DateTimeUtils";
import UserUtil from "util/UserUtil";
import { isBefore, isAfter, set } from "date-fns";
import ProjectUtil from "util/ProjectUtil";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker";
import GeneralUtils from "util/GeneralUtils";
import DataOptions from "constants/appDataOptions";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";

export default function TaskAddEditModal (props) {

    const { addToast } = useToasts();
    const { register, handleSubmit, errors, control } = useForm({
        mode: "onBlur"
    });
    const current_user = useSelector(state => state.authReducer.user);
    const taskNameFocusRef = useRef(null);

    // form elements
    const [taskNumber, setTaskNumber] = useState("");
    const [taskName, setTaskName] = useState("");
    const [taskDescription, setTaskDescription] = useState("");
    const [taskOwner, setTaskOwner] = useState();
    const [taskTeamAssigned, setTaskTeamAssigned] = useState([]);
    const [taskUserAssigned, setTaskUserAssigned] = useState([]);
    const [taskGroup, setTaskGroup] = useState();
    const [predecessorTaskDetails, setPredecessorTaskDetails] = useState(JSON.parse(JSON.stringify(
        TaskUtil.populateDefaultPredTaskAdd)));
    const [taskStartTime, setTaskStartTime] = useState(new Date());
    const [taskDuration, setTaskDuration] = useState(0);
    const [taskEndTime, setTaskEndTime] = useState(new Date());
    const [taskHolidayList, setTaskHolidayList] = useState([]);
    const [taskStatus, setTaskStatus] = useState(ENUMS.TASK_STATUS.P);
    const [percentageComplete, setPercentageComplete] = useState(0);

    // dropdown lists
    const [taskOwnerList, setTaskOwnerList] = useState([]);
    const [teamsList, setTeamsList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [predecessorTaskList, setPredecessorTaskList] = useState([]);

    // invitation lists
    const [inviteTaskOwner, setInviteTaskOwner] = useState(null);
    const [inviteTaskUsers, setInviteTaskUsers] = useState([]);

    // loading and disabled
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isTaskStartTimeDisabled, setIsTaskStartTimeDisabled] = useState(false);
    const [isTaskEndTimeDisabled, setIsTaskEndTimeDisabled] = useState(false);
    const [isTimeChangedDueToFFPred, setIsTimeChangedDueToFFPred] = useState(false);

    // project data
    const [projectSettings, setProjectSettings] = useState({});
    const [projectTasks, setProjectTasks] = useState([]);

    // date errors
    const [errorVisible, setErrorVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const onToggleError = () => setErrorVisible(!errorVisible);

    //execution mode
    const [activeRehearsal, setActiveRehearsal] = useState();

    // toggelers
    const [isOpenTaskInfo, setIsOpenTaskInfo] = useState(true);
    const [isOpenPredTaskInfo, setIsOpenPredTaskInfo] = useState(false);
    const [isOpenTaskTimeInfo, setIsOpenTaskTimeInfo] = useState(false);
    const toggleTaskInfo = () => setIsOpenTaskInfo(!isOpenTaskInfo);
    const togglePredTaskInfo = () => setIsOpenPredTaskInfo(!isOpenPredTaskInfo);
    const toggleTaskTimeInfo = () => setIsOpenTaskTimeInfo(!isOpenTaskTimeInfo);
    const [openTaskOwnerMenu, setOpenTaskOwnerMenu] = useState(false);
    const [openTaskMemberMenu, setOpenTaskMemberMenu] = useState(false);

    // temp state
    const [prevStartTime, setPrevStartTime] = useState(new Date(null));
    const [prevEndTime, setPrevEndTime] = useState(new Date(null));

    const animatedComponents = makeAnimated();

    // Submit/Update button click handler for adding to editing task
    const onButtonClickHandler = async (data, e) => {
        e.preventDefault();
        let predTaskDetails = [];
        // pre submit validations
        // checks if end time is greater than start time
        if (taskStartTime && taskEndTime && isBefore(taskEndTime, taskStartTime)) {
            setErrorMessage("Task End Time cannot be less than the Task Start Time.");
            setErrorVisible(true);
            return;
        }
        // validates the task start time 
        const message = DateTimeUtils.validateTaskDates(taskStartTime, projectSettings, taskHolidayList);
        if (message !== null) {
            setErrorMessage(message);
            setErrorVisible(true);
            return;
        }
        // validates the task end time
        const mes = DateTimeUtils.validateTaskDates(taskStartTime, projectSettings, taskHolidayList);
        if (mes !== null) {
            setErrorMessage(mes);
            setErrorVisible(true);
            return;
        }
        // validates the predecessor task list
        if (TaskUtil.validatePredTaskDetails(predecessorTaskDetails)) {
            predTaskDetails = TaskUtil.formatPredTaskDetails(predecessorTaskDetails, projectSettings);
        }
        else {
            setErrorMessage("Please save all the entries in the predecessor task section before adding a task.");
            setErrorVisible(true);
            return;
        }
        // setting pre submit variables
        setIsSubmitting(true);
        const teamAssigned = taskTeamAssigned ? 
            taskTeamAssigned.map(team => team.value) : null;
        const userAssignedNonInvite = taskUserAssigned ? taskUserAssigned.filter(
            user => !inviteTaskUsers.includes(user.label)).map(
                user => user.value
        ) : null;

        // convert days to hours if default duration is set in days
		// DB only saves duration in hours
		const timeDifference = ProjectUtil.findTimeDifference(projectSettings.AssignedCalendar);
        const taskDurationInHours = ProjectUtil.convertTaskDurationToHoursForDB(taskDuration, projectSettings.default_duration_format, timeDifference);
        
        // creating new task object
        const newTask = {
            task_number: taskNumber,
            task_name: taskName,
            task_description: taskDescription,
            task_owner: taskOwner.value,
            task_team_assigned: teamAssigned,
            task_user_assigned: userAssignedNonInvite,
            task_group: taskGroup ? taskGroup.value : null,
            task_start_time: taskStartTime,
            task_duration: taskDurationInHours,
            task_end_time: taskEndTime,
            task_status: taskStatus,
            task_percentage_complete: percentageComplete
        }
        // add mode
        if (props.modalMode === ENUMS.MODAL.ADD_MODE) {
            API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TASK_ADD,
            {
                ...HttpUtil.adminHttpHeaders(),
                body: 
                {
                    projectId: props.projectId,
                    newTask: newTask,
                    inviteTaskOwner: inviteTaskOwner,
                    inviteTaskUsers: inviteTaskUsers,
                    predecessorTasks: predTaskDetails,
                }
            })
            .then(res => {
                if (res.status === 201 && res.data.success) {
                    props.refreshPageOnSubmit(res.data.message);
                }
            })
            .catch(error => {
                setIsSubmitting(false);
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        "Failed to create task.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
        }
        // edit mode
        else if (props.modalMode === ENUMS.MODAL.EDIT_MODE) {
            API.put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TASK_UPDATE,
            {
                ...HttpUtil.adminHttpHeaders(),
                body: 
                {
                    projectId: props.projectId,
                    taskId: props.editTaskId,
                    updateTask: newTask,
                    inviteTaskOwner: inviteTaskOwner,
                    inviteTaskUsers: inviteTaskUsers,
                    predecessorTasks: predTaskDetails,
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success) {
                    props.refreshPageOnSubmit(res.data.message);
                }
            })
            .catch(error => {
                setIsSubmitting(false);
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        "Failed to update task.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
        }
    }

    /**
     * Populates the default task owner based on the passed list of users
     * @param {Array} listOfUsers 
     */
    const populateDefaultTaskOwner = (listOfUsers) => {
        // Assigning current user as project owner if mode is ADD
        if (props.modalMode === ENUMS.MODAL.ADD_MODE && listOfUsers.length > 0) {
            const defaultKey = listOfUsers.findIndex(user => 
                user.value === current_user.id
            );
            setTaskOwner(listOfUsers[defaultKey]);
        }   
    }

    /**
     * Fetches the user list for populating the dropdowns
     */
    const fetchUserList = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.USER_ALL_ORGANIZATION,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                project_id: props.projectId,
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                console.log(res.data.userList, 'userslist')
                setTaskOwnerList(res.data.userList);
                setUserList(res.data.userList);
                populateDefaultTaskOwner(res.data.userList);
            }
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch user list for owner selection", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    /**
     * Populates the dropdown from the project data
     * @param {Object} projectData 
     */
    const populateDropdowns = (projectData) => {
        setTeamsList(TeamUtil.formatTeamForDropdownBulk(projectData.Teams));
        setGroupList(GroupUtil.formatGroupForDropdownBulk(projectData.Task_Groups));
        if (props.modalMode === ENUMS.MODAL.ADD_MODE)
            setPredecessorTaskList(TaskUtil.formatTaskForDropdownBulkWithTime(projectData.Tasks, null, current_user));
        else
            setPredecessorTaskList(TaskUtil.formatTaskForDropdownBulkWithTime(projectData.Tasks, props.editTaskId, current_user));
    }

    /**
     * Populates the default task number for add mode
     * @param {Object} projectData 
     */
    function populateDefaultTaskNumber(projectData) {
        // If there are no tasks in the project and this is a first task
        if (projectData.Tasks && projectData.Tasks.length === 0) {
            setTaskNumber(projectData.Project_Setting.start_task_id);
        }
        // Add default task increment to last task number
        else {
            const lastTaskNumber = projectData.Tasks[projectData.Tasks.length - 1].task_number;
            const taskInterval = projectData.Project_Setting.default_task_no_intervals;
            setTaskNumber(TaskUtil.calculateNextTaskNumber(lastTaskNumber, taskInterval));
        }
    }

    /**
     * Populates the form defaults. Task Number, Task Duration and End time.
     * @param {Object} projectData 
     */
    const populateFormDefaults = (projectData) => {
        populateDefaultTaskNumber(projectData);
        setTaskDurationAsPerFormat(projectData.Project_Setting.default_task_duration, projectData.Project_Setting);
        // calculates default task end date
        const message = DateTimeUtils.validateTaskDates(taskStartTime, projectData.Project_Setting, []);
        let newStartDate = null;
        const workStartTime = projectData.Project_Setting.AssignedCalendar.working_start_time.split(":");
        if (message !== null) {
            newStartDate = set(taskStartTime, {
                date: taskStartTime.getDate() + 1,
                hours: workStartTime[0],
                minutes: workStartTime[1],
                seconds: workStartTime[2]
            })
        }
        else {
            newStartDate = set(taskStartTime, {
                date: taskStartTime.getDate(),
                hours: workStartTime[0],
                minutes: workStartTime[1],
                seconds: workStartTime[2]
            })
        }
        newStartDate = DateTimeUtils.recalculateStartTime(newStartDate,
            projectData.Project_Setting,
            []
        );
        setTaskStartTime(newStartDate);
        setTaskEndTime(DateTimeUtils.calculateTaskTimeForward(
            projectData.Project_Setting, 
            newStartDate, 
            projectData.Project_Setting.default_task_duration, 
            taskHolidayList));
    }

    /**
     * Fetches all the task related data and populates the form.
     */
    const populateDropdownsAndProjectData = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TASK_GET_ALL_PROJECT_DATA,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                projectId: props.projectId
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                populateDropdowns(res.data.projectData);
                if (props.modalMode === ENUMS.MODAL.ADD_MODE) {
                    populateFormDefaults(res.data.projectData);
                }
                setProjectSettings(res.data.projectData.Project_Setting);
                setProjectTasks(res.data.projectData.Tasks);
                if (res.data.projectData.Rehearsals.length > 0) {
                    setActiveRehearsal(res.data.projectData.Rehearsals.find(rehearsal => rehearsal.active));
                }
                if (props.modalMode === ENUMS.MODAL.ADD_MODE)
                    setIsLoading(false);
            }
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch teams and groups for dropdown.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        });
    }

    /**
     * Fetch dropdown data and user data for all modes.
     */
    const fetchAllDropdownAndProjectData = () => {
        fetchUserList();
        populateDropdownsAndProjectData();
    }

    /**
     * Populates the form data for edit mode.
     */
    const populateFormData = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TASK_GET_TASK_BY_ID,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                taskId: props.editTaskId
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                const currentTask = res.data.task;
                setTaskNumber(currentTask.task_number);
                setTaskName(currentTask.task_name);
                setTaskDescription(currentTask.task_description);
                setTaskOwner(UserUtil.formatUserForDropdown(currentTask.TaskOwner));
                if (currentTask.task_group_id) {
                    setTaskGroup(GroupUtil.formatGroupForDropdown(currentTask.Task_Group));
                }
                if (currentTask.Teams && currentTask.Teams.length > 0) {
                    setTaskTeamAssigned(TeamUtil.formatTeamForDropdownBulk(currentTask.Teams));
                }
                if (currentTask.Users && currentTask.Users.length > 0) {
                    setTaskUserAssigned(UserUtil.formatUserForDropdownBulk(currentTask.Users));
                }
                if (currentTask.PredecessorTask.length > 0) {
                    const predTaskDetails = TaskUtil.formatPredTaskForEdit(currentTask.PredecessorTask, projectSettings);
                    predTaskDetails.push(JSON.parse(JSON.stringify(...TaskUtil.populateDefaultPredTaskAdd)));
                    setPredecessorTaskDetails(predTaskDetails);
                    setIsTaskEndTimeDisabled(true);
                    setIsTaskStartTimeDisabled(true);
                }
                else {
                    setPredecessorTaskDetails(JSON.parse(JSON.stringify(
                        TaskUtil.populateDefaultPredTaskAdd)));
                }
                
                setTaskStartTime(new Date(currentTask.task_start_time));
                setTaskDurationAsPerFormat(currentTask.task_duration, projectSettings);
                setTaskEndTime(new Date(currentTask.task_end_time));
                setTaskHolidayList(currentTask.Task_Holidays);
                setPercentageComplete(currentTask.percentage_complete ? currentTask.percentage_complete : 0);
                setTaskStatus(TaskUtil.getTaskStatusWithActiveRehearsal(currentTask));
                setIsLoading(false);
            }
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch task data for edit. Please try again.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const formatDropDownLabel = (inputValue) => {
        return "Invite - "+ inputValue;
    }

    const formatDropDownLabelGroup = (inputValue) => {
        return "Create Group - "+ inputValue;
    }

    const formatDropDownLabelTeam = (inputValue) => {
        return "Create Team - "+ inputValue;
    }

    const createOption = (label) => {
        return {
            label,
            value: label.toLowerCase()
        }
    };
    
    /**
     * 
     * @param {number} duration 
     * 
     * Convert and set task duration as per format
     */
    const setTaskDurationAsPerFormat = (duration, projectSettingsData) => {
        const durationAsPerFormat = ProjectUtil.convertTaskDurationForUI(
            duration,
            projectSettingsData.default_duration_format,
            ProjectUtil.findTimeDifference(projectSettingsData.AssignedCalendar)
        );
        setTaskDuration(ProjectUtil.twoFixedPlaces(durationAsPerFormat));
    }

    const handleInviteTaskOwner = (inputValue) => {
        // TO-DO: Validate against email format
        if (GeneralUtils.validateEmail(inputValue)) {
            setInviteTaskOwner(inputValue);
            const option = createOption(inputValue);
            setTaskOwnerList([...taskOwnerList, option]);
            setTaskOwner(option);
        }
        else {
            addToast(
                "Invalid format for email.", 
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
        }
        hideMenu();
    }

    const handleInviteTaskUser = (inputValue) => {
        // TO-DO: Validate against email format
        if (GeneralUtils.validateEmail(inputValue)) {
            setInviteTaskUsers([...inviteTaskUsers, inputValue]);
            const option = createOption(inputValue);
            setUserList([...userList, option]);
            setTaskUserAssigned([...taskUserAssigned, option]);
        }
        else {
            addToast(
                "Invalid format for email.", 
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
        }
        hideMenu();
    }

    const handleTaskGroupCreation = (inputValue) => {
        // handle task group creation
        API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TASK_CREATE_TASK_GROUP,
        {
            ...HttpUtil.adminHttpHeaders(),
            body: 
            {
                projectId: props.projectId,
                groupName: inputValue
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setTaskGroup(GroupUtil.formatGroupForDropdown(res.data.createdGroup));
                setGroupList([...groupList, GroupUtil.formatGroupForDropdown(res.data.createdGroup)]);
            }
        })
        .catch(error => {
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to create group.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        });
    }

    const taskTeamChanged = (newValue) => {
        if(newValue) {
            setTaskTeamAssigned(newValue);
        } else {
            setTaskTeamAssigned([]);
        }
    }

    const handleTeamCreation = (inputValue) => {
        // handle team creation
        API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TASK_CREATE_TASK_TEAM,
        {
            ...HttpUtil.adminHttpHeaders(),
            body: 
            {
                projectId: props.projectId,
                teamName: inputValue,
                teamLeaderId: current_user.id
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setTaskTeamAssigned([...taskTeamAssigned, TeamUtil.formatTeamForDropdown(res.data.createdTeam)]);
                setTeamsList([...teamsList, TeamUtil.formatTeamForDropdown(res.data.createdTeam)]);
            }
        })
        .catch(error => {
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch teams and groups for dropdown.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        });
    }

    /**
     * Method to handle predecessor task select change.
     * Assigns predecessor task. Populates default task link type. 
     * Calculates the task start and end time based on FS type.
     * @param {Object} newValue 
     * @param {int} key 
     */
    const handlePredecessorTaskChange = (newValue, key) => {
        const predTaskDetails = JSON.parse(JSON.stringify(predecessorTaskDetails));
        if (newValue !== null) {
            if (!TaskUtil.checkIfPredTaskSelected(newValue, predTaskDetails)) {
                predTaskDetails[key].predecessorTask = newValue;
                predTaskDetails[key].predecessorTaskLinkType = TaskUtil.predecessorTaskLinkTypes[0];
                setPredecessorTaskDetails(predTaskDetails);
                handleTaskTimeCalculations(
                    TaskUtil.predecessorTaskLinkTypes[0], key, newValue, predTaskDetails);
                setIsTaskEndTimeDisabled(true);
                setIsTaskStartTimeDisabled(true);
            }
            else {
                setErrorMessage("Predecessor task already selected.");
                setErrorVisible(true);
            }
        }
        else {
            predTaskDetails[key].predecessorTask = {};
            predTaskDetails[key].predecessorTaskLinkType = {};
            predTaskDetails[key].taskDelayStart = 0;
            setPredecessorTaskDetails(predTaskDetails);
            setIsTaskEndTimeDisabled(false);
            setIsTaskStartTimeDisabled(false);
        }
    }

    /**
     * Method to calculate the task time based on predecessor link type chosen
     * @param {Object} newValue 
     * @param {int} key 
     * @param {Object} defaultPredTask 
     * @param {Array} defaultPredTaskDetails 
     */
    const handleTaskTimeCalculations = (newValue, key, defaultPredTask, defaultPredTaskDetails) => {
        const predTaskDetails = defaultPredTaskDetails ? 
                defaultPredTaskDetails : 
                JSON.parse(JSON.stringify(predecessorTaskDetails));
        if (newValue !== null) {
            predTaskDetails[key].predecessorTaskLinkType = newValue;
            const defaultKey = projectTasks.findIndex(task => 
                task.id === (defaultPredTask ? 
                    defaultPredTask.value : 
                    predTaskDetails[key].predecessorTask.value)
            );
            const predTask = projectTasks[defaultKey];
            setIsTaskEndTimeDisabled(true);
            setIsTaskStartTimeDisabled(true);

            const taskDurationInHours = ProjectUtil.convertTaskDurationToHoursForDB(
                taskDuration,
                projectSettings.default_duration_format,
                ProjectUtil.findTimeDifference(projectSettings.AssignedCalendar)
            );

            if (newValue.value === ENUMS.PREDECESSOR_TASK_LINK_TYPES.FF) {
                // inside FF
                // pred task end time -> current task end time
                // current task finishes when the pred task finishes
                predTaskDetails[key].taskDelayStart = 0;
                let predTaskEndTime = new Date(predTask.task_end_time);
                predTaskEndTime = DateTimeUtils.recalculateEndTime(
                    predTaskEndTime,
                    projectSettings, 
                    taskHolidayList
                );
                if (isAfter(predTaskEndTime, prevEndTime)) {
                    setTaskEndTime(predTaskEndTime);
                    setTaskStartTime(DateTimeUtils.calculateTaskTimeBackwards(
                        projectSettings, predTaskEndTime, taskDurationInHours, taskHolidayList));
                    setIsTimeChangedDueToFFPred(true);
                }
            }
            else if (newValue.value === ENUMS.PREDECESSOR_TASK_LINK_TYPES.SS) {
                // inside SS
                // pred task start time -> current task start time
                // current task start along with the pred task
                let predTaskStartTime = new Date(predTask.task_start_time);
                predTaskStartTime = DateTimeUtils.recalculateStartTime(
                    predTaskStartTime,
                    projectSettings, 
                    taskHolidayList
                );
                if (isAfter(predTaskStartTime, prevStartTime)) {
                    setTaskStartTime(predTaskStartTime);
                    setTaskEndTime(DateTimeUtils.calculateTaskTimeForward(
                        projectSettings, predTaskStartTime, taskDurationInHours, taskHolidayList));
                    predTaskDetails[key].taskDelayStart = 0;
                    setIsTimeChangedDueToFFPred(false);
                }
            }
            else {
                // inside FS
                // pred task END time -> current task START time
                // current task starts when the pred task finishes
                let predTaskEndTime = new Date(predTask.task_end_time);
                predTaskEndTime = DateTimeUtils.recalculateStartTime(
                    predTaskEndTime,
                    projectSettings, 
                    taskHolidayList
                );
                if (isAfter(predTaskEndTime, prevStartTime)) {
                    setTaskStartTime(predTaskEndTime);
                    setTaskEndTime(DateTimeUtils.calculateTaskTimeForward(
                        projectSettings, predTaskEndTime, taskDurationInHours, taskHolidayList));
                    predTaskDetails[key].taskDelayStart = 0;
                    setIsTimeChangedDueToFFPred(false);
                }
            }
        }
        else {
            predTaskDetails[key].predecessorTaskLinkType = {};
            predTaskDetails[key].taskDelayStart = 0;
        }
        setPredecessorTaskDetails(predTaskDetails);
    }

    /**
     * Method to handle the calculation of task times based on task delay start
     * @param {Object} newValue 
     * @param {int} key 
     */
    const handleTaskStartDelay = (newValue, key) => {
        const predTaskDetails = JSON.parse(JSON.stringify(predecessorTaskDetails));
        const taskDelayStartInHours = ProjectUtil.convertTaskDurationToHoursForDB(
            newValue,
            projectSettings.default_duration_format,
            ProjectUtil.findTimeDifference(projectSettings.AssignedCalendar)
        );

        const taskDurationInHours = ProjectUtil.convertTaskDurationToHoursForDB(
            taskDuration,
            projectSettings.default_duration_format,
            ProjectUtil.findTimeDifference(projectSettings.AssignedCalendar)
        );

        predTaskDetails[key].taskDelayStart = newValue;
        const defaultKey = projectTasks.findIndex(task => 
            task.id === predTaskDetails[key].predecessorTask.value
        );
        const predTask = projectTasks[defaultKey];
        if (predTaskDetails[key].predecessorTaskLinkType.value === ENUMS.PREDECESSOR_TASK_LINK_TYPES.SS) {
            // inside SS
            // pred task start time -> current task start time
            // current task start along with the pred task
            let predTaskStartTime = new Date(predTask.task_start_time);
            predTaskStartTime = DateTimeUtils.recalculateStartTime(
                predTaskStartTime,
                projectSettings, 
                taskHolidayList
            );
            setTaskStartTime(DateTimeUtils.calculateTaskTimeForward(
                projectSettings, predTaskStartTime, taskDelayStartInHours, taskHolidayList));
            setTaskEndTime(DateTimeUtils.calculateTaskTimeForward(
                projectSettings, predTaskStartTime, parseFloat(taskDurationInHours) + parseFloat(taskDelayStartInHours), taskHolidayList));
        }
        else {
            // inside FS
            // pred task END time -> current task START time
            // current task starts when the pred task finishes
            let predTaskEndTime = new Date(predTask.task_end_time);
            predTaskEndTime = DateTimeUtils.recalculateStartTime(
                predTaskEndTime,
                projectSettings, 
                taskHolidayList
            );
            setTaskStartTime(DateTimeUtils.calculateTaskTimeForward(
                projectSettings, predTaskEndTime, taskDelayStartInHours, taskHolidayList));
            setTaskEndTime(DateTimeUtils.calculateTaskTimeForward(
                projectSettings, predTaskEndTime, parseFloat(taskDurationInHours) + parseFloat(taskDelayStartInHours), taskHolidayList));
        }
        setPredecessorTaskDetails(predTaskDetails);
    }

    const handleTaskStatusChange = (e) => {
        setTaskStatus(e.value);
        
        if (activeRehearsal && e.value == "S") {
            handleStartTimeChange(new Date())
        }
        if (activeRehearsal && ["SC", "FC", "FT"].indexOf(e.value) > -1) {
            const newDate = new Date();
            const message = DateTimeUtils.validateTaskDates(newDate, projectSettings, taskHolidayList);
            if (message === null) {
                setErrorMessage("");
                setErrorVisible(false);
                setTaskEndTime(newDate);
                const newTaskDuration = DateTimeUtils.findDateDifferenceInHours(taskStartTime, newDate);
                setTaskDurationAsPerFormat(newTaskDuration, projectSettings);
            }
            else {
                setErrorMessage(message);
                setErrorVisible(true);
            }
        }
    }

    const handleTaskPercentageCompleteChange = (e) => {
        setPercentageComplete(e.value);
    }

    /**
     * Handle task start time changes
     * @param {Object} newDate 
     */
    const handleStartTimeChange = (newDate) => {
        // check if selected start time falls on a holiday
        const message = DateTimeUtils.validateTaskDates(newDate, projectSettings, taskHolidayList);
        if (message === null) {
            setErrorMessage("");
            setErrorVisible(false);
            setTaskStartTime(newDate);
            if (taskDuration) {
                const taskDurationInHours = ProjectUtil.convertTaskDurationToHoursForDB(
                    taskDuration,
                    projectSettings.default_duration_format,
                    ProjectUtil.findTimeDifference(projectSettings.AssignedCalendar)
                );
                setTaskEndTime(DateTimeUtils.calculateTaskTimeForward(
                    projectSettings, newDate, taskDurationInHours, taskHolidayList));
            }
        }
        else {
            setErrorMessage(message);
            setErrorVisible(true);
        }
    }

    /**
     * Handle task duration changes
     * @param {Number} value 
     */
    const handleTaskDurationChange = (value) => {
        // if pred task link type == FF then change start time instead of end time
        const taskDurationInHours = ProjectUtil.convertTaskDurationToHoursForDB(
            value,
            projectSettings.default_duration_format,
            ProjectUtil.findTimeDifference(projectSettings.AssignedCalendar)
        );
        // Check to see if the task times has been changed due to a FF type predecessor link type,
        // then calculate the task start time (backward calculation) - because it's running in FF mode
        if (isTimeChangedDueToFFPred && 
            taskEndTime) {
            // handle backward change
            setTaskStartTime(DateTimeUtils.calculateTaskTimeBackwards(
                projectSettings, taskEndTime, taskDurationInHours, taskHolidayList));
        }
        else if (taskStartTime) {
            setTaskEndTime(DateTimeUtils.calculateTaskTimeForward(
                projectSettings, taskStartTime, taskDurationInHours, taskHolidayList));
        }
        setTaskDuration(value);
    }

    /**
     * Handle task ent time change
     * @param {Object} newDate 
     */
    const handleEndTimeChange = (newDate) => {
        // check if end time is before start time
        if (taskStartTime && isBefore(newDate, taskStartTime)) {
            setErrorMessage("Task End Time cannot be less than the Task Start Time.");
            setErrorVisible(true);
        }
        else {
            // check if selected end time falls on a holiday
            // check if selected end time is within working hours
            const message = DateTimeUtils.validateTaskDates(newDate, projectSettings, taskHolidayList);
            if (message === null) {
                setErrorMessage("");
                setErrorVisible(false);
                setTaskEndTime(newDate);
                if (taskDuration) {
                    const taskDurationInHours = ProjectUtil.convertTaskDurationToHoursForDB(
                        taskDuration,
                        projectSettings.default_duration_format,
                        ProjectUtil.findTimeDifference(projectSettings.AssignedCalendar)
                    );
                    setTaskStartTime(DateTimeUtils.calculateTaskTimeBackwards(
                        projectSettings, newDate, taskDurationInHours, taskHolidayList));
                }
            }
            else {
                setErrorMessage(message);
                setErrorVisible(true);
            }
        }
    }

    /**
     * Validates the predecessor task form to check for blank entries
     * @param {int} key 
     * @param {Object} predTask 
     */
    const validatePredTaskFields = (key, predTask) => {
        if (predTask.predecessorTask && 
            predTask.predecessorTask.value) {
            
            if (predTask.predecessorTaskLinkType &&
                predTask.predecessorTaskLinkType.value) {

                if ((TaskUtil.linkTypesForDelay.includes(predTask.predecessorTaskLinkType.value) &&
                    predTask.taskDelayStart >= 0) || 
                    predTask.predecessorTaskLinkType.value === ENUMS.PREDECESSOR_TASK_LINK_TYPES.FF) {
                    return true;
                }
                else {
                    setErrorMessage("Predecessor Task Delay Start cannot be empty.");
                    setErrorVisible(true);
                    return false;
                }
            }
            else {
                setErrorMessage("Predecessor Task Link Type cannot be empty.");
                setErrorVisible(true);
                return false;
            }
        }
        else {
            setErrorMessage("Predecessor Task cannot be empty.");
            setErrorVisible(true);
            return false;
        }
    }
    
    /**
     * Handles the save button functionality for predecessor task form group
     * @param {int} key 
     * @param {Object} predTask 
     */
    const handleSaveButtonPredTask = (key, predTask) => {
        // validate the predecessor task record
        if (validatePredTaskFields(key, predTask)) {
            // update the temporary times
            // Sets a temporary time for cases in which there might be multiple predecessor tasks
            /**
             * Eg- if predecessor tasks are more than one, only one of them can be responsible for setting the
             * correct start/end time. This checks helps in checking the correct time and only calculates the
             * start and end time if the new value (calculated from a newly added predecessor task) is greater than
             * this value.
             */
            setPrevStartTime(taskStartTime);
            setPrevEndTime(taskEndTime);
            // set added element to true so that it shows "Update"
            const predTaskDetails = JSON.parse(JSON.stringify(predecessorTaskDetails));
            predTaskDetails[key].added = true;
            if (predTaskDetails[predTaskDetails.length - 1].added) {
                predTaskDetails.push(JSON.parse(JSON.stringify(...TaskUtil.populateDefaultPredTaskAdd)));
            }
            setPredecessorTaskDetails(predTaskDetails);

            setErrorMessage("");
            setErrorVisible(false);
        }
    }

    /**
     * Handles delete predecessor task button
     * @param {int} key 
     * @param {Object} predTask 
     */
    const handleDeleteButtonPredTask = (key) => {
        let predTaskDetails = JSON.parse(JSON.stringify(predecessorTaskDetails));
        setIsTaskEndTimeDisabled(false);
        setIsTaskStartTimeDisabled(false);
        if (predTaskDetails.length === 1) {
            setPredecessorTaskDetails(JSON.parse(JSON.stringify(
                TaskUtil.populateDefaultPredTaskAdd)));
        }
        else {
            // TODO: Check to see if the last remove is empty or not and not delete it.
            predTaskDetails.splice(key, 1);
            setPredecessorTaskDetails(JSON.parse(JSON.stringify(predTaskDetails)));
        }
    }

    /**
     * Returns the predecessor task form groups
     * @param {int} key 
     * @param {Object} predTask 
     */
    const predTaskFormGroup = (key, predTask) => {
        return <React.Fragment key={key}>
            <FormGroup>
                <Label for="predecessorTask">Assign Predecessor Task</Label>
                <Select
                    id="predecessorTask"
                    className="form-control-alternative"
                    isClearable
                    components={animatedComponents}
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    onChange={(newValue)=>handlePredecessorTaskChange(newValue, key)}
                    options={predecessorTaskList}
                    value={predTask.predecessorTask}
                />
            </FormGroup>
            { predTask.predecessorTask && predTask.predecessorTask.value &&
                <FormGroup>
                    <Label for="predecessorTaskLinkType">
                        Predecessor Task Link Type
                        <i className="fa fa-info-circle ml-2" id="pred-info"></i>
                        <UncontrolledTooltip
                            target="pred-info"
                            delay={0}
                            placement="top"
                            className="full-width-tooltip text-left cop-tooltip"
                        >
                            <div><strong>FS</strong> will schedule to start this task immediately after the predecessor task is finished</div>
                            <div><strong>SS</strong> will schedule to start this task at the same time as the predecessor task</div>
                            <div><strong>FF</strong> will schedule to start this task at a time that will result in it finishing at the same time as the predecessor task</div>
                        </UncontrolledTooltip>
                    </Label>
                    <Select
                        id="predecessorTaskLinkType"
                        className="form-control-alternative"
                        isClearable
                        components={animatedComponents}
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        onChange={(newValue)=>handleTaskTimeCalculations(newValue, key)}
                        options={TaskUtil.predecessorTaskLinkTypes}
                        value={predTask.predecessorTaskLinkType}
                    />
                </FormGroup>
            }
            { predTask.predecessorTask.value && 
            predTask.predecessorTaskLinkType &&
            TaskUtil.linkTypesForDelay.includes(predTask.predecessorTaskLinkType.value) &&
                <FormGroup>
                    <Label for="taskDelayStart">Task Delay Start?</Label>
                    <InputGroup className="input-group-alternative mb-3">
                        <Input 
                            id="taskDelayStart"
                            name="taskDelayStart"
                            className="form-control-alternative"
                            type="number"
                            value={predTask.taskDelayStart}
                            onChange={(e)=>handleTaskStartDelay(e.target.value, key)}
                            autoComplete="off"
                        />
                        <InputGroupAddon addonType="append">
                            <InputGroupText>
                                {projectSettings.default_duration_format === ENUMS.DURATION_FORMAT_VALUES.HOUR ? 'Hrs' : 'Days'}
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </FormGroup>
            }
            <Button
                size="sm"
                color="primary"
                onClick={e => handleSaveButtonPredTask(key, predTask)}
            >
                {predTask.added ? "Update" : "Save"}
            </Button>
            <Button
                size="sm"
                color="secondary"
                onClick={e => handleDeleteButtonPredTask(key)}
            >
                Remove
            </Button>
            {predTask.added ? <hr className="my-4" /> : null}
        </React.Fragment>
    }

    /**
     * Sets the new value to the task owner and closes the select dropdown.
     * @param {Object} newValue 
     */
    const handleTaskOwnerChange = (newValue) => {
        setTaskOwner(newValue);
        hideMenu();
    }

    /**
     * Sets the new value to the task member and closes the select dropdown.
     * @param {Object} newValue 
     */
    const handleTaskMemberChange = (newValue) => {
        setTaskUserAssigned(newValue);
        hideMenu();
    }

    /**
     * Closes the dropdown menu on input change for task owner select
     * @param {Object} query 
     * @param {Obejct} param1 
     */
    const handleTaskOwnerInputChange = (query, { action }) => {
        if (action === "input-change") {
            setOpenTaskOwnerMenu(true);
        }
    };

    /**
     * Closes the dropdown menu on input change for task member select
     * @param {Object} query 
     * @param {Obejct} param1 
     */
    const handleTaskMemberInputChange = (query, { action }) => {
        if (action === "input-change") {
            setOpenTaskMemberMenu(true);
        }
    };

    /**
     * Closes the dropdown menu for owner and member select
     */
    const hideMenu = () => {
        setOpenTaskOwnerMenu(false);
        setOpenTaskMemberMenu(false);
    };

    useEffect(() => {
        // fetch actions
        fetchAllDropdownAndProjectData();
    }, []);

    useEffect(() => {
        if (props.modalMode === ENUMS.MODAL.EDIT_MODE) {
            populateFormData();
        }
    }, [projectSettings]);

    useEffect(() => {
        if (taskNameFocusRef.current !== null) {
            taskNameFocusRef.current.focus();
        } 
    }, [taskNameFocusRef.current]);

    return (
        <Modal
            className="modal-dialog-centered"
            size="lg"
            isOpen={props.modalOpen}
            toggle={() => props.closeModal()}
            backdrop="static"
        >
            <ModalHeader 
                toggle={() => props.closeModal()}
                className="modal-header"
                tag="div"
            >
                <h3 className="modal-title">
                    {props.modalTitle}
                </h3>
			</ModalHeader>
            {isLoading ?
                <Spinner 
                    type="grow" 
                    color="primary" 
                    style={{display: "block", marginLeft: "auto", marginRight: "auto"}} 
                /> : 
                <Form role="form" onSubmit={handleSubmit(onButtonClickHandler)}>
                    <ModalBody className="modal-body p-0">
                        <Card className="bg-secondary shadow border-0">
                            <CardBody className="px-lg-4 py-lg-4">
                                <Button block color="default" onClick={toggleTaskInfo} style={{ marginBottom: '1rem' }}>
                                    Task Information
                                </Button>
                                <Collapse isOpen={isOpenTaskInfo}>
                                    <FormGroup className="mb-3">
                                        <Label for="taskNumber">Task Number<span className="required-asterix">*</span></Label>
                                        <Input 
                                            name="taskNumber"
                                            className="form-control-alternative"
                                            type="text"
                                            value={taskNumber}
                                            onChange={(e)=>setTaskNumber(e.target.value)}
                                            innerRef={register(
                                            {
                                                required: "Task Number is required."
                                            })}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <CustomErrorMessage errors={errors} name="taskNumber" />
                                    <FormGroup className="mb-3">
                                        <Label for="taskName">Task Name<span className="required-asterix">*</span></Label>
                                        <Input 
                                            name="taskName"
                                            className="form-control-alternative"
                                            type="text"
                                            value={taskName}
                                            onChange={(e)=>setTaskName(e.target.value)}
                                            innerRef={(e) => {
                                                register(e,
                                                {
                                                    required: "Task Name is required."
                                                });
                                                taskNameFocusRef.current = e;
                                            }}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <CustomErrorMessage errors={errors} name="taskName" />
                                    <FormGroup>
                                        <Label for="taskDescription">Task Description</Label>
                                        <Input 
                                            id="taskDescription"
                                            className="form-control-alternative"
                                            type="textarea"
                                            rows="4"
                                            value={taskDescription}
                                            onChange={(e)=>setTaskDescription(e.target.value)}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="taskOwner">Task Owner<span className="required-asterix">*</span></Label>
                                        <CreatableSelect
                                            id="taskOwner"
                                            name="taskOwner"
                                            className="form-control-alternative"
                                            // as={CreatableSelect}
                                            // control={control}
                                            // rules={{ required: "Task Owner is required." }}
                                            isClearable
                                            components={animatedComponents}
                                            isDisabled={isLoading}
                                            isLoading={isLoading}
                                            onChange={(newValue)=>handleTaskOwnerChange(newValue)}
                                            onCreateOption={handleInviteTaskOwner}
                                            createOptionPosition="first"
                                            formatCreateLabel={formatDropDownLabel}
                                            options={taskOwnerList}
                                            value={taskOwner}
                                            onInputChange={handleTaskOwnerInputChange}
                                            onBlur={hideMenu}
                                            onFocus={(e) => setOpenTaskOwnerMenu(true)}
                                            menuIsOpen={openTaskOwnerMenu}
                                        />
                                    </FormGroup>
                                    <CustomErrorMessage errors={errors} name="taskOwner" />
                                    <FormGroup>
                                        <Label for="taskGroup">Task Group</Label>
                                        <CreatableSelect
                                            id="taskGroup"
                                            className="form-control-alternative"
                                            isClearable
                                            components={animatedComponents}
                                            isDisabled={isLoading}
                                            isLoading={isLoading}
                                            onChange={(newValue)=>setTaskGroup(newValue)}
                                            onCreateOption={handleTaskGroupCreation}
                                            createOptionPosition="first"
                                            formatCreateLabel={formatDropDownLabelGroup}
                                            options={groupList}
                                            value={taskGroup}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="taskTeamAssigned">Assign Task To Team</Label>
                                        <CreatableSelect
                                            id="taskTeamAssigned"
                                            className="form-control-alternative"
                                            isMulti
                                            components={animatedComponents}
                                            isDisabled={isLoading}
                                            isLoading={isLoading}
                                            onChange={(newValue) => taskTeamChanged(newValue)}
                                            onCreateOption={handleTeamCreation}
                                            createOptionPosition="first"
                                            formatCreateLabel={formatDropDownLabelTeam}
                                            options={teamsList}
                                            value={taskTeamAssigned}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="taskUserAssigned">Assign Task To Users</Label>
                                        <CreatableSelect
                                            id="taskUserAssigned"
                                            className="form-control-alternative"
                                            isMulti
                                            components={animatedComponents}
                                            isDisabled={isLoading}
                                            isLoading={isLoading}
                                            onChange={(newValue) => handleTaskMemberChange(newValue)}
                                            onCreateOption={handleInviteTaskUser}
                                            createOptionPosition="first"
                                            formatCreateLabel={formatDropDownLabel}
                                            options={userList}
                                            value={taskUserAssigned}
                                            onInputChange={handleTaskMemberInputChange}
                                            onBlur={hideMenu}
                                            onFocus={e => setOpenTaskMemberMenu(true)}
                                            menuIsOpen={openTaskMemberMenu}
                                        /> 
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label for="status">Task Status<span className="required-asterix">*</span></Label>
                                        <Select
                                            id="status"
                                            name="status"
                                            className="form-control-alternative"
                                            options={DataOptions.TASK_ACTION_STATUS_OPTIONS}
                                            value={DataOptions.TASK_ACTION_STATUS_OPTIONS.filter(option => option.value === taskStatus)}
                                            onChange={(e) => handleTaskStatusChange(e)}
                                        />
                                        <CustomErrorMessage errors={errors} name="status" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="taskDuration">Task Duration</Label>
                                        <InputGroup className="input-group-alternative mb-3">
                                            <Input 
                                                id="taskDuration"
                                                name="taskDuration"
                                                className="form-control-alternative"
                                                type="number"
                                                value={taskDuration}
                                                disabled={activeRehearsal}
                                                onChange={(e)=>handleTaskDurationChange(e.target.value)}
                                                innerRef={register(
                                                    {
                                                        required: "Task Duration is required."
                                                    })
                                                }
                                                autoComplete="off"
                                            />
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText>
                                                    {projectSettings.default_duration_format === ENUMS.DURATION_FORMAT_VALUES.HOUR ? 'Hrs' : 'Days'}
                                                </InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label for="percentage_complete">Percentage complete</Label>
                                        <Select
                                            id="percentage_complete"
                                            name="percentage_complete"
                                            className="form-control-alternative"
                                            options={DataOptions.TASK_PERCENTAGE_COMPLETE_OPTIONS}
                                            value={DataOptions.TASK_PERCENTAGE_COMPLETE_OPTIONS.filter(option => option.value === percentageComplete)}
                                            onChange={(e) => handleTaskPercentageCompleteChange(e)}
                                        />
                                        <CustomErrorMessage errors={errors} name="percentage_complete" />
                                    </FormGroup>
                                </Collapse>
                                <hr className="my-4" />
                                <Button block color="default" onClick={togglePredTaskInfo} style={{ marginBottom: '1rem' }}>
                                    Predecessor Task Information
                                </Button>
                                <Collapse isOpen={isOpenPredTaskInfo}>
                                    {
                                        projectTasks && projectTasks.length > 0 ?
                                        predecessorTaskDetails.map((predTask,key) => {
                                            return predTaskFormGroup(key, predTask);
                                        }) :
                                        <p> No Predecessor Tasks Available. </p>
                                    }
                                </Collapse>
                                <hr className="my-4" />
                                <Button block color="default" onClick={toggleTaskTimeInfo} style={{ marginBottom: '1rem' }}>
                                    Task Time Information
                                </Button>
                                <Collapse isOpen={isOpenTaskTimeInfo}>
                                    <FormGroup>
                                        <Label for="taskStartTime">Task Start Time</Label>
                                        <div className="react-datepicker-manager" id="task-start-picker">
                                            <CustomDatePicker
                                                id="taskStartTime"
                                                selected={taskStartTime}
                                                onChange={date => handleStartTimeChange(date)}
                                                placeholderText="Enter Task Start Time"
                                                disabled={isTaskStartTimeDisabled || activeRehearsal}
                                                showTimeSelect={true}
                                                renderToEl="task-start-picker"
                                            />
                                            {/* <DatePicker
                                                selected={taskStartTime}
                                                onChange={date => handleStartTimeChange(date)}
                                                className="form-control form-control-alternative react-datepicker-manager"
                                                id="taskStartTime"
                                                showTimeSelect
                                                timeIntervals={15}
                                                dateFormat={DateTimeUtils.getUserDateTimeFormat(current_user.date_format, 
                                                    current_user.twenty_four_hour_clock)}
                                                placeholderText="Enter Task Start Time"
                                                disabled={isTaskStartTimeDisabled || activeRehearsal}
                                            /> */}
                                        </div>
                                    </FormGroup>
                                    <CustomErrorMessage errors={errors} name="taskStartTime" />
                                    <FormGroup>
                                        <Label for="taskEndTime">Task End Time</Label>
                                        <div className="react-datepicker-manager" id="task-end-picker">
                                            <CustomDatePicker
                                                id="taskEndTime"
                                                selected={taskEndTime}
                                                onChange={date => handleEndTimeChange(date)}
                                                placeholderText="Enter Task End Time"
                                                disabled={isTaskEndTimeDisabled || activeRehearsal}
                                                showTimeSelect={true}
                                                renderToEl="task-end-picker"
                                            />
                                            {/* <DatePicker
                                                selected={taskEndTime}
                                                onChange={date => handleEndTimeChange(date)}
                                                className="form-control form-control-alternative react-datepicker-manager"
                                                id="taskEndTime"
                                                showTimeSelect
                                                timeIntervals={15}
                                                dateFormat={DateTimeUtils.getUserDateTimeFormat(current_user.date_format, 
                                                    current_user.twenty_four_hour_clock)}
                                                placeholderText="Enter Task End Time"
                                                disabled={isTaskEndTimeDisabled}
                                            /> */}
                                        </div>
                                    </FormGroup>
                                </Collapse>
                                <hr className="my-4" />
                                <UncontrolledAlert
                                    color="danger" 
                                    isOpen={errorVisible} 
                                    toggle={onToggleError}
                                >
                                    <span className="alert-inner--text">
                                        <strong>Error!</strong> {errorMessage}
                                    </span>
                                </UncontrolledAlert>
                            </CardBody>
                        </Card>
                    </ModalBody>
                    <ModalFooter>
                        <Button 
                            color="secondary" 
                            onClick={() => props.closeModal()}
                        >
                            Cancel
                        </Button>
                        {isSubmitting ?
                            <Spinner size="sm" color="primary" /> :
                            <Button 
                                color="primary" 
                                type="submit"
                                disabled={props.modalMode === ENUMS.MODAL.VIEW_MODE}
                            >
                                {props.modalButtonName}
                            </Button>
                        }
                        {" "}
                    </ModalFooter>
                </Form>
            }
        </Modal>
    )
}