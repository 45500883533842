import RULES from "rbac/rules";
import DYNAMIC_FUNCTIONS from "rbac/dynamicFunctions";
/**
 * Contains the permitted rules for all the roles
 */
const roles = {
    projectMember: {
        static: 
        [
            RULES.RULE_NAMES.TASK_VIEW, 
            RULES.RULE_NAMES.TEAM_VIEW,
        ],
        dynamic: 
        [
            {
                [RULES.RULE_NAMES.PROJECT_VIEW]: DYNAMIC_FUNCTIONS.projectMemberCheck
            }
        ]
    },
    taskOwner: {
        dynamic: 
        [
            { [RULES.RULE_NAMES.TASK_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.TASK_DELETE]: DYNAMIC_FUNCTIONS.idCheck }
        ]
    },
    teamLeader: {
        dynamic: 
        [
            { [RULES.RULE_NAMES.TEAM_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.TEAM_DELETE]: DYNAMIC_FUNCTIONS.idCheck }
        ]
    },
    projectOwner: {
        dynamic: 
        [
            { [RULES.RULE_NAMES.PROJECT_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.PROJECT_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.TEAM_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.TEAM_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.TASK_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.TASK_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.PROJECT_SETTING_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.CALENDAR_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.CALENDAR_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.CALENDAR_EXCEPTIONS_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.CALENDAR_EXCEPTIONS_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.ACTION_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.ACTION_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.RISK_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.RISK_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.ASSUMPTION_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.ASSUMPTION_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.ISSUE_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.ISSUE_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.DEPENDENCY_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.DEPENDENCY_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
        ]
    },
    projectAdmin: {
        dynamic: 
        [
            { [RULES.RULE_NAMES.PROJECT_EDIT]: DYNAMIC_FUNCTIONS.projectEditabilityCheck },
            { [RULES.RULE_NAMES.PROJECT_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.TEAM_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.TEAM_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.TASK_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.TASK_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.PROJECT_SETTING_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.CALENDAR_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.CALENDAR_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.CALENDAR_EXCEPTIONS_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.CALENDAR_EXCEPTIONS_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.ACTION_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.ACTION_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.RISK_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.RISK_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.ASSUMPTION_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.ASSUMPTION_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.ISSUE_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.ISSUE_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.DEPENDENCY_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.DEPENDENCY_DELETE]: DYNAMIC_FUNCTIONS.idCheck },
        ]
    },
    admin: {
        static: 
        [
            RULES.RULE_NAMES.TASK_VIEW, 
            RULES.RULE_NAMES.TEAM_VIEW,
        ],
        dynamic: []
    },
    actionOwner: {
        static: [],
        dynamic: [
            { [RULES.RULE_NAMES.ACTION_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.ACTION_DELETE]: DYNAMIC_FUNCTIONS.idCheck }
        ]
    },
    riskOwner: {
        static: [],
        dynamic: [
            { [RULES.RULE_NAMES.RISK_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.RISK_DELETE]: DYNAMIC_FUNCTIONS.idCheck }
        ]
    },
    riskMitigationOwner: {
        static: [],
        dynamic: [
            { [RULES.RULE_NAMES.RISK_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
        ]
    },
    assumptionOwner: {
        static: [],
        dynamic: [
            { [RULES.RULE_NAMES.ASSUMPTION_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.ASSUMPTION_DELETE]: DYNAMIC_FUNCTIONS.idCheck }
        ]
    },
    issueOwner: {
        static: [],
        dynamic: [
            { [RULES.RULE_NAMES.ISSUE_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.ISSUE_DELETE]: DYNAMIC_FUNCTIONS.idCheck }
        ]
    },
    issueMitigationOwner: {
        static: [],
        dynamic: [
            { [RULES.RULE_NAMES.ISSUE_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
        ]
    },
    dependencyOwner: {
        static: [],
        dynamic: [
            { [RULES.RULE_NAMES.DEPENDENCY_EDIT]: DYNAMIC_FUNCTIONS.idCheck },
            { [RULES.RULE_NAMES.DEPENDENCY_DELETE]: DYNAMIC_FUNCTIONS.idCheck }
        ]
    },
};

export default roles;
