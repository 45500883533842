import TaskUtil from "./TaskUtil";
import UserUtil from "./UserUtil";

const getBackGroundColorForTask = (task) => {
    if (task.is_critical_path) {
        return '#fb6340'
    }

    return '#11cdef';
}

const formatTaskForFullCalendar = (task) => {
    return {
        title: task.task_number,
        backgroundColor: getBackGroundColorForTask(task),
        start: task.task_start_time,
        end: task.task_end_time,
        extendedProps: {
            description: task.task_name,
            taskHolidays: task.Task_Holidays.length > 0 ? true : false,
            owner: UserUtil.formatUserForTableColumn(task.TaskOwner),
            critical_path: TaskUtil.formatIsCriticalPath(task.is_critical_path),
            duration: task.duration_view,
            task_start: task.start_time_view,
            task_end: task.end_time_view,
        },
    };
}

const formatTaskListForFullCalendar = (taskList) => {
    return taskList.map(task => {
        return {...formatTaskForFullCalendar(task)};
    });
}

const CalendarUtil = {
    formatTaskListForFullCalendar,
    formatTaskForFullCalendar,
};

export default CalendarUtil;