/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import './LandingPage.css';
import Fade from 'react-reveal/Fade'; // Importing Zoom effect
class NextFeature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputFocus: false
    };
  }

  render() {
    return (
      <>
        
        <section className="section section-lg section-safe each-section">
            {/* <img
              alt="..."
              className="path"
              src={require("assets/img/path5.png")}
            /> */}
            <Container>
              <Row className="row-grid justify-content-between">
                <Col md="6">
                    <Fade left duration={1300} delay={400}>
                        <div className="px-md-5">
                            <hr className="line-success" />
                            <h3 className="text-white-0dot8">Task List</h3>
                            <p className="text-gray">
                              The task list view shows all tasks in a project. Users can define the visible fields, filter on any data to find specific tasks, group tasks and/or edit tasks. Task dependencies are clearly presented and can be easily altered to automatically recalibrate the project end date.
                            </p>
                            <ul className="list-unstyled mt-5">
                            <li className="py-2">
                                <div className="d-flex align-items-center">
                                <div className="icon icon-success mb-2">
                                    <i className="tim-icons icon-vector" />
                                </div>
                                <div className="ml-3">
                                    <h4 className="text-white-0dot8">Visualise all tasks in the project</h4>
                                </div>
                                </div>
                            </li>
                            <li className="py-2">
                                <div className="d-flex align-items-center">
                                <div className="icon icon-success mb-2">
                                    <i className="tim-icons icon-tap-02" />
                                </div>
                                <div className="ml-3">
                                    <h4 className="text-white-0dot8">Obvious task dependencies</h4>
                                </div>
                                </div>
                            </li>
                            <li className="py-2">
                                <div className="d-flex align-items-center">
                                <div className="icon icon-success mb-2">
                                    <i className="tim-icons icon-single-02" />
                                </div>
                                <div className="ml-3">
                                    <h4 className="text-white-0dot8">Filter tasks</h4>
                                </div>
                                </div>
                            </li>
                            </ul>
                        </div>
                    </Fade>
                </Col>
                <Col md="6">
                    <Fade right duration={1300} delay={400}>
                        <img
                            alt="..."
                            className="img-fluid floating"
                            src={require("assets/img/task_list_screen.png")}
                            />
                    </Fade>
                </Col>
              </Row>
            </Container>
          </section>
      </>
    );
  }
}

export default NextFeature;
