import React from "react";

// reactstrap components
import { Container, Row, Col, Button, UncontrolledTooltip} from "reactstrap";

export default function ReportHeader (props) {
    return (
        <>
            <div className="header bg-info pt-6 pb-8">
                <Container fluid>
                    <div className="header-body">
                        <Row className="pt-5 align-items-center">
                            <Col>
                                <h2 className="text-white">
                                    Project Name:
                                </h2>
                                <p className="text-white">
                                    {props.projectDetails.project_name}
                                </p>
                            </Col>
                            <Col className="text-right">
                                <i className={"fas fa-cloud-download-alt cursor-pointer fs-30 default-color " + (props.reportType != 'gantt' ? 'd-none' : '')} id="save-icon" onClick={props.printFunction} />
                                <i className={"fas fa-print cursor-pointer fs-30 default-color " + (props.reportType === 'gantt' ? 'd-none' : '')} id="print-icon" onClick={props.printFunction} />
                                <UncontrolledTooltip delay={0} placement="top" target="save-icon">Save Report</UncontrolledTooltip>
                                <UncontrolledTooltip delay={0} placement="top" target="print-icon">Print</UncontrolledTooltip>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}