import roles from "rbac/roles";

const check = (role, action, data) => {
    let isPermitted = false;
    
    for (const index in role) {
        const permissions = roles[role[index]];
        if (!permissions) {
            // role is not present in the list of roles
            continue;
        }
    
        const staticPermissions = permissions.static;
    
        if (staticPermissions && staticPermissions.includes(action)) {
            // static rule provided for action
            isPermitted = true;
            break;
        }
    
        const dynamicPermissions = permissions.dynamic;
    
        if (dynamicPermissions) {
            const permissionCondition = dynamicPermissions.filter(
                permission => permission[action]);
            if (!permissionCondition || permissionCondition.length === 0) {
                // dynamic rule not provided for action
                continue;
            }
            
            isPermitted = permissionCondition[0][action](data[index]);
            if (isPermitted)
                break;
        }
    }
    return isPermitted;
};

const CheckPermissions = (props) => {
    return check(props.role, props.perform, props.data)
    ? props.yes()
    : props.no();
}

CheckPermissions.defaultProps = {
    yes: () => null,
    no: () => null
}

export default CheckPermissions;

