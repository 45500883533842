import React, { useState, useEffect, useRef } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	FormGroup,
	Label,
	Input,
	Form,
} from "reactstrap";
import { useForm } from 'react-hook-form';
import InputGroup from "reactstrap/lib/InputGroup";
import CardBody from "reactstrap/lib/CardBody";
import UncontrolledAlert from "reactstrap/lib/UncontrolledAlert";
import Card from "reactstrap/lib/Card";
import makeAnimated from "react-select/animated";
import CreatableSelect from 'react-select/creatable';
import ENUMS from "constants/appEnums";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import TeamUtil from "util/TeamUtil";
import { useToasts } from "react-toast-notifications";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import GeneralUtils from "util/GeneralUtils";
import Switch from "react-bootstrap-switch";
import Select from 'react-select';
import DataOptions from "constants/appDataOptions";
import CustomErrorMessage from "components/CustomErrorMessage/CustomErrorMessage";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker";
import UserUtil from "util/UserUtil";

export default function AddEditIssueModal(props) {
    const { handleSubmit, errors } = useForm({
		mode: "onBlur"
    });

    const current_user = useSelector(state => state.authReducer.user);
    
    const { addToast } = useToasts();

    //Editor props
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    // date errors
    const [errorVisible, setErrorVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const onToggleError = () => setErrorVisible(!errorVisible);

    // Form data
    const [description, setDescription] = useState("");
    const [team, setTeam] = useState();
    const [status, setStatus] = useState("");
    const [severity, setSeverity] = useState("");
    const [mitigation, setMitigation] = useState("");
    const [dueDate, setDueDate] = useState();
    const [escalationRequired, setEscalationRequired] = useState(false);
    
    const [overallView, setOverallView] = useState("");

    // Issue Owner Dropdown
    const [issueOwner, setIssueOwner] = useState("");
    const [inviteIssueOwner, setInviteIssueOwner] = useState(null);

    // Issue Mitigation Owner Dropdown
    const [issueMitigationOwner, setIssueMitigationOwner] = useState("");
    const [inviteIssueMitigationOwner, setInviteIssueMitigationOwner] = useState(null);
    
    // Lists to populate dropdown 
    const [userList, setUserList] = useState([]);
    const [mitigationUserList, setMitigationUserList] = useState([]);
    const [teamsList, setTeamsList] = useState([]);

    // togglers
    const [openIssueOwnerMenu, setOpenIssueOwnerMenu] = useState(false);
    const [openIssueMitigationOwnerMenu, setOpenIssueMitigationOwnerMenu] = useState(false);
    
    // loading and disabled
    const [isLoading, setIsLoading] = useState(true);

    // animated components
    const animatedComponents = makeAnimated();

    /**
     * Populates the form data for edit mode.
     */
    const populateFormData = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_ISSUE_GET_BY_ID,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                issue_id: props.editIssueId
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                const responseIssueData = res.data.projectIssue;
                setDescription(responseIssueData.description);
                setTeam(TeamUtil.formatTeamForDropdown(responseIssueData.Team));
                setStatus(responseIssueData.status);
                setIssueOwner(UserUtil.formatUserForDropdown(responseIssueData.IssueOwner));
                setSeverity(responseIssueData.severity);
                setMitigation(responseIssueData.mitigation);
                setDueDate(responseIssueData.due_date);
                setIssueMitigationOwner(UserUtil.formatUserForDropdown(responseIssueData.IssueMitigationOwner));
                setEscalationRequired(responseIssueData.escalation_required);
                populateNoteHandler(responseIssueData.notes);
                setIsLoading(false);
            }
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch assumption data for edit. Please try again.", 
                    { 
                        appearance: 'error',
						autoDismiss: true
                    }
                );
            }
        })
    }

    const populateNoteHandler = (note) => {
        const contentBlock = htmlToDraft(note);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        }
    }

    const handleTeamCreation = (inputValue) => {
        // handle team creation
        API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TASK_CREATE_TASK_TEAM,
        {
            ...HttpUtil.adminHttpHeaders(),
            body: 
            {
                projectId: props.projectData.id,
                teamName: inputValue,
                teamLeaderId: current_user.id
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setTeam(TeamUtil.formatTeamForDropdown(res.data.createdTeam));
                setTeamsList([...teamsList, TeamUtil.formatTeamForDropdown(res.data.createdTeam)]);
            }
        })
        .catch(error => {
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch teams and groups for dropdown.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        });
    }

    const getNoteEditorContent = () => {
        const noteHTMLStringContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        if (noteHTMLStringContent === "<p></p>") {
            return '';
        }
        return noteHTMLStringContent;
    }

    /**
     * Fetches the user list for populating the dropdowns
     */
    const fetchUserList = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.USER_ALL_ORGANIZATION,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                project_id: props.projectData.id,
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setUserList(res.data.userList);
                setMitigationUserList(res.data.userList);
            }
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch user list for owner selection", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    // Method to handle form submit
    const handleConfirm = () => {
        const noteContent = getNoteEditorContent();
        const issueData = {
            project_id: props.projectData.id,
            description: description,
            team_id: team.value,
            status: status,
            owner_id: issueOwner.value,
            inviteIssueOwner: inviteIssueOwner,
            severity: severity,
            mitigation: mitigation,
            due_date: dueDate,
            mitigation_owner_id: issueMitigationOwner.value,
            inviteIssueMitigationOwner: inviteIssueMitigationOwner,
            escalation_required: escalationRequired,
            notes: noteContent
        }
        // add mode
        if (props.modalMode === ENUMS.MODAL.ADD_MODE) {
            API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_ISSUE_ADD,
            {
                ...HttpUtil.adminHttpHeaders(),
                body:
                {
                    ...issueData,
                }
            })
            .then(res => {
                if (res.status === 201 && res.data.success) {
                    props.refreshPageOnSubmit(res.data.message);
                }
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        "Failed to create issue.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
        }
        // edit mode
        else if (props.modalMode === ENUMS.MODAL.EDIT_MODE) {
            API.put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_ISSUE_UPDATE,
            {
                ...HttpUtil.adminHttpHeaders(),
                body:
                {
                    id: props.editIssueId,
                    ...issueData
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success) {
                    props.refreshPageOnSubmit(res.data.message);
                }
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        "Failed to update issue.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
        }
    }

    const handleInviteIssueOwner = (inputValue) => {
        // TO-DO: Validate against email format
        if (GeneralUtils.validateEmail(inputValue)) {
            setInviteIssueOwner(inputValue);
            const option = createOption(inputValue);
            setUserList([...userList, option]);
            setIssueOwner(option);
        }
        else {
            addToast(
                "Invalid format for email.", 
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
        }
        hideIssueOwnerMenu();
    }

    /**
     * Sets the new value to the issue owner and closes the select dropdown.
     * @param {Object} newValue 
     */
     const handleIssueOwnerChange = (newValue) => {
        if(newValue == null) {
            setInviteIssueOwner(null);
        }
        setIssueOwner(newValue);
        hideIssueOwnerMenu();
    }

    /**
     * Closes the dropdown menu on input change for issue owner select
     * @param {Object} query 
     * @param {Obejct} param1 
     */
     const handleIssueOwnerInputChange = (query, { action }) => {
        if (action === "input-change") {
            setOpenIssueOwnerMenu(true);
        }
    };

    /**
     * Closes the dropdown menu for owner and member select
     */
    const hideIssueOwnerMenu = () => {
        setOpenIssueOwnerMenu(false);
    };

    const handleInviteIssueMitigationOwner = (inputValue) => {
        // TO-DO: Validate against email format
        if (GeneralUtils.validateEmail(inputValue)) {
            setInviteIssueMitigationOwner(inputValue);
            const option = createOption(inputValue);
            setMitigationUserList([...mitigationUserList, option]);
            setIssueMitigationOwner(option);
        }
        else {
            addToast(
                "Invalid format for email.", 
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
        }
        hideIssueMitigationOwnerMenu();
    }

    /**
     * Sets the new value to the issue mitigation owner and closes the select dropdown.
     * @param {Object} newValue 
     */
     const handleIssueMitigationOwnerChange = (newValue) => {
        if(newValue == null) {
            setInviteIssueMitigationOwner(null);
        }
        setIssueMitigationOwner(newValue);
        hideIssueMitigationOwnerMenu();
    }

    /**
     * Closes the dropdown menu on input change for issue mitigation owner select
     * @param {Object} query 
     * @param {Obejct} param1 
     */
     const handleIssueMitigationOwnerInputChange = (query, { action }) => {
        if (action === "input-change") {
            setOpenIssueMitigationOwnerMenu(true);
        }
    };

    const handleSeverityChange = (value) => {
        console.log(value)
        setSeverity(value);
    }

    /**
     * Closes the dropdown menu for owner and member select
     */
    const hideIssueMitigationOwnerMenu = () => {
        setOpenIssueMitigationOwnerMenu(false);
    };

    const createOption = (label) => {
        return {
            label,
            value: label.toLowerCase()
        }
    };

    /** 
     * NOTES DATA MEMEBERS 
     */
     /**
      * On change of the new note editor
      * 
      * @param {object} editorState 
      */
     const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    }

    const formatDropDownLabel = (inputValue) => {
        return "Invite - "+ inputValue;
    }

    const formatDropDownLabelTeam = (inputValue) => {
        return "Create Team - "+ inputValue;
    }

    useEffect(() => {
        // fetch user list
        fetchUserList();
    }, []);

    useEffect(() => {
        if (props.projectData) {
            setTeamsList(TeamUtil.formatTeamForDropdownBulk(props.projectData.Teams));
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (props.modalMode === ENUMS.MODAL.EDIT_MODE) {
            populateFormData();
        }
    }, [props.editIssueId]);

	return (
		<Modal
			size="md"
			isOpen={props.modalOpen}
			toggle={() => props.closeModal(false)}
            backdrop="static"
		>
			<ModalHeader toggle={() => props.closeModal(false)} tag="h3">
				{props.modalTitle}
			</ModalHeader>
            <Form role="form" onSubmit={handleSubmit(handleConfirm)}>
                <ModalBody className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-4 py-lg-4">
                            <FormGroup>
                                <Label for="issueTeamAssigned">Assign Issue To Team</Label>
                                <CreatableSelect
                                    id="issueTeamAssigned"
                                    className="form-control-alternative"
                                    components={animatedComponents}
                                    isDisabled={isLoading}
                                    isLoading={isLoading}
                                    onChange={(newValue) => setTeam(newValue)}
                                    onCreateOption={handleTeamCreation}
                                    createOptionPosition="first"
                                    formatCreateLabel={formatDropDownLabelTeam}
                                    options={teamsList}
                                    value={team}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="description">Description</Label>
                                <InputGroup className="input-group-alternative mb-3">
                                    <Input 
                                        id="description"
                                        name="description"
                                        className="form-control-alternative"
                                        type="text"
                                        value={description}
                                        onChange={(e)=> setDescription(e.target.value)}
                                        autoComplete="off"
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <Label for="status">Issue Status<span className="required-asterix">*</span></Label>
                                <Select
                                    id="status"
                                    name="status"
                                    className="form-control-alternative"
                                    options={DataOptions.RAID_STATUS_OPTIONS}
                                    value={DataOptions.RAID_STATUS_OPTIONS.filter(option => option.value === status)}
                                    onChange={(e) => setStatus(e.value)}
                                />
                                <CustomErrorMessage errors={errors} name="status" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="issueOwner">Issue Owner<span className="required-asterix">*</span></Label>
                                <CreatableSelect
                                    id="issueOwner"
                                    name="issueOwner"
                                    className="form-control-alternative"
                                    // as={CreatableSelect}
                                    // control={control}
                                    // rules={{ required: "Issue Owner is required." }}
                                    isClearable
                                    components={animatedComponents}
                                    isDisabled={isLoading}
                                    isLoading={isLoading}
                                    onChange={(newValue)=>handleIssueOwnerChange(newValue)}
                                    onCreateOption={handleInviteIssueOwner}
                                    createOptionPosition="first"
                                    formatCreateLabel={formatDropDownLabel}
                                    options={userList}
                                    value={issueOwner}
                                    onInputChange={handleIssueOwnerInputChange}
                                    onBlur={hideIssueOwnerMenu}
                                    onFocus={e => setOpenIssueOwnerMenu(true)}
                                    menuIsOpen={openIssueOwnerMenu}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="severity">Severity</Label>
                                    <Select
                                        id="severity"
                                        name="severity"
                                        className="form-control-alternative"
                                        options={DataOptions.ISSUE_SEVERITY_OPTIONS}
                                        value={DataOptions.ISSUE_SEVERITY_OPTIONS.filter(option => option.value === severity)}
                                        onChange={(e) => handleSeverityChange(e.value)}
                                    />
                            </FormGroup>
                            <FormGroup>
                                <Label for="due_date">Due Date</Label>
                                <div className="react-datepicker-manager" id="due-date-picker">
                                    <CustomDatePicker
                                        id="due_date"
                                        selected={dueDate}
                                        onChange={date => setDueDate(date)}
                                        showTimeSelect={false}
                                        renderToEl="due-date-picker"
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label for="mitigation">Mitigation</Label>
                                <InputGroup className="input-group-alternative mb-3">
                                    <Input 
                                        id="mitigation"
                                        name="mitigation"
                                        className="form-control-alternative"
                                        type="text"
                                        value={mitigation}
                                        onChange={(e)=> setMitigation(e.target.value)}
                                        autoComplete="off"
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label for="stakeholder_email">Issue Mitigation Owner<span className="required-asterix">*</span></Label>
                                <CreatableSelect
                                    id="issueMitigationOwner"
                                    name="issueMitigationOwner"
                                    className="form-control-alternative"
                                    // as={CreatableSelect}
                                    // control={control}
                                    // rules={{ required: "IssueMitigation Owner is required." }}
                                    isClearable
                                    components={animatedComponents}
                                    isDisabled={isLoading}
                                    isLoading={isLoading}
                                    onChange={(newValue)=>handleIssueMitigationOwnerChange(newValue)}
                                    onCreateOption={handleInviteIssueMitigationOwner}
                                    createOptionPosition="first"
                                    formatCreateLabel={formatDropDownLabel}
                                    options={mitigationUserList}
                                    value={issueMitigationOwner}
                                    onInputChange={handleIssueMitigationOwnerInputChange}
                                    onFocus={e => setOpenIssueMitigationOwnerMenu(true)}
                                    onBlur={hideIssueMitigationOwnerMenu}
                                    menuIsOpen={openIssueMitigationOwnerMenu}
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <Label for="escalation_required">Escalation Required<span className="required-asterix">*</span></Label>
                                <Select
                                    id="escalation_required"
                                    name="escalation_required"
                                    className="form-control-alternative"
                                    options={DataOptions.YES_NO_OPTIONS}
                                    value={DataOptions.YES_NO_OPTIONS.filter(option => option.value === escalationRequired)}
                                    onChange={(e) => setEscalationRequired(e.value)}
                                />
                                <CustomErrorMessage errors={errors} name="status" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="note">Note</Label>
                                <div className="comment-box-wrapper bg-white note-wrap">
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorStateChange}
                                    />
                                </div>
                            </FormGroup>
                            <UncontrolledAlert
                                color="danger"
                                isOpen={errorVisible} 
                                toggle={onToggleError}
                            >
                                <span className="alert-inner--text">
                                    <strong>Error!</strong> {errorMessage}
                                </span>
                            </UncontrolledAlert>
                        </CardBody>
                    </Card>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={() => props.closeModal(false)}>
						Cancel
					</Button>
					<Button color="primary" type="submit">
						Save
					</Button>{" "}
				</ModalFooter>
			</Form>
		</Modal>
	);
}
