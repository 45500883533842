import React, { useState } from "react";

// reactstrap components
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";

export default function ProjectMilestoneHeader (props) {    
    
    return (
        <>
            <div className="header bg-info pb-7 pt-5 pt-md-8">
                <Container fluid>
                    <div className="header-body">
                        
                    </div>
                </Container>
            </div>
        </>
    );
}