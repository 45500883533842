import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useToasts } from 'react-toast-notifications';
import { useAppContext } from "util/ContextUtil";
import { useSelector } from "react-redux";
import StripeContainer from "components/Stripe/StripeContainer";
import './Subscribe.css';

// reactstrap components
import {
  Card,
  CardBody,
  Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    Button
} from "reactstrap";
import AuthNavbar from "components/Navbars/AuthNavbar";

/**
 * Function to implement the Login View.
 */
export default function Subscribe () {    
    //User props
    const user = useSelector(state => state.authReducer.user);

    const getImgSrc = () => {
        return require("assets/img/home/logocop.svg")
      }

    return (
        <div className="overlay">
            <div className="header py-5 py-lg-6">
            <Container className="">
                <Row className="justify-content-center">
                    <Col lg="12" md="10">
                        <Card className="bg-secondary shadow border-0">
                            <CardBody className="px-lg-5 py-lg-5">
                                <Row className="">
                                    <Col lg="7" md="7">
                                        <StripeContainer />
                                    </Col>
                                    <Col lg="5" md="5">
                                    <div>
                                        <div className="price-card price-standard">
                                            <div>
                                                <div className="price-card-title">Standard</div>
                                                <div className="price-subtitle">Up to 3 projects</div>
                                                <div className="price-feature-list">
                                                    <div className="price-feature">
                                                        <img src={require('assets/img/home/check_circle.svg')} className="mr-2"/>
                                                        Create/edit Projects
                                                    </div>
                                                    <div className="price-feature">
                                                        <img src={require('assets/img/home/check_circle.svg')} className="mr-2"/>
                                                        Create/edit Tasks
                                                    </div>
                                                    <div className="price-feature">
                                                        <img src={require('assets/img/home/check_circle.svg')} className="mr-2"/>
                                                        Create/edit Actions, RAID
                                                    </div>
                                                    <div className="price-feature">
                                                        <img src={require('assets/img/home/check_circle.svg')} className="mr-2"/>
                                                        30mb File Storage
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="price-divider"></div>
                                            <div className="price-card-price">$25</div>
                                            <div className="price-per">per month</div>
                                            {/* <div>
                                                <button type="button" className="btn-price-choose index-button-primary" onClick={e => selectPlan('standard')}>Choose plan</button>
                                            </div> */}
                                        </div>
                                    </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </div>
        </div>
    );
}

