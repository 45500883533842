import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { 
    Row, 
    Col,
    Container, 
    Card, 
    CardHeader, 
    CardBody,
    Button,
    UncontrolledTooltip,
    Media,
    Spinner
} from "reactstrap";
import CheckPermissions from "components/CheckPermissions/CheckPermissions";
import RULES from "rbac/rules";
import ENUMS from "constants/appEnums";
import HttpUtil from "util/HttpUtil";
import { useSelector } from "react-redux";
import Loader from "components/Loader/Loader";
import { API } from "aws-amplify";
import AssumptionViewHeader from "./AssumptionViewHeader";
import UserUtil from "util/UserUtil";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import parse from 'html-react-parser';
import { format } from "date-fns";
import DateTimeUtils from "util/DateTimeUtils";

export default function AssumptionView (props) {
    const location = useLocation();
    const { addToast } = useToasts();

    const [assumptionDetails, setAssumptionDetails] = useState();
    const [isAssumptionDetailLoading, setIsAssumptionDetailLoading] = useState(true);

    // const [editModalOpen, setEditModalOpen] = useState(true);

    // fetch user project lists
    const projectList = useSelector(state => state.authReducer.userProjectList);
    //User props
    const current_user = useSelector(state => state.authReducer.user);
    
    //Editor props
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [editingEditorState, setEditingEditorState] = useState(EditorState.createEmpty())
    
    const [showEditor, setShowEditor] = useState(false);
    const [isNotesLoading, setIsNotesLoading] = useState(false);
    const [notesLoading, setNotesLoading] = useState(true);
    const  [showAllNotes, setShowAllNotes] = useState(false);

    //Note props
    const [showDeleteNoteModal, setShowDeleteNoteModal] = useState(false);
    const [assumptionNotes, setAssumptionNotes] = useState([]);
    const [deleteNoteModalText, setDeleteNoteModalText] = useState('');
    const [selectedNoteId, setSelectedNoteId] = useState(null);
    const [userImageSrc, setUserImageSrc] = useState();

    /**
     * Get Assumption details by Id
     * @param {Number} assumptionIdSearch 
     */
    const fetchAssumptionById = (assumptionIdSearch) => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_ASSUMPTION_GET_BY_ID,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                assumption_id: assumptionIdSearch
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                const assumptionDetailsData = {
                    ...res.data.projectAssumption
                }
                setAssumptionDetails(assumptionDetailsData);
                
                const allAssumptionNotes = assumptionDetailsData.Assumption_Notes;
                const fetchUserImages = async () => {
                    return Promise.all(assumptionDetailsData.Assumption_Notes.map(note => getUserImageAsync(note.User)))
                }
    
                fetchUserImages().then(allResponses => {
                    allResponses.map((res, index) => {
                        allAssumptionNotes[index].userImageURL = res.data.signedRequest;
                    })
                    
                    setAssumptionNotes(allAssumptionNotes);
                    setNotesLoading(false);
                });

                setIsAssumptionDetailLoading(false);
            }
        })
        .catch(error => {
            setIsAssumptionDetailLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch assumption data. Please try again.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        });
    }
    
    const getAssumptionDetails = () => {
        if (location.state && location.state.passedId) {
            fetchAssumptionById(location.state.passedId);
        }
        else if (location.search) {
            const params = new URLSearchParams(location.search);
            const assumptionIdSearch = params.get('assumptionId');
            fetchAssumptionById(assumptionIdSearch);
        }
    }

    const onClickEditButtonHandler = () => {
        // setEditModalOpen(true);
    }
    
    /** 
     * NOTES DATA MEMEBERS 
     */

     /**
      * On change of the new note editor
      * 
      * @param {object} editorState 
      */
      const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    }

    /**
     * on change of editing on the existing note editor
     * 
     * @param {object} editingEditorState 
     */
    const onEditingEditorStateChange = (editingEditorState) => {
        setEditingEditorState(editingEditorState);
    }

    /**
     * handler for save note button
     * 
     * @param {object} note 
     */
    const saveNoteHandler = (note = null) => {
        const noteHTMLStringContent = draftToHtml(convertToRaw(
            note ? editingEditorState.getCurrentContent() : editorState.getCurrentContent()
        ));
        if (noteHTMLStringContent === "<p></p>") {
            return;
        }
        if(note) {
            updateNote(note, noteHTMLStringContent)
        } else {
            createNote(noteHTMLStringContent);
        }
    }
    
    /**
     * API call to create a new note on assumption
     * 
     * @param {string} noteContent 
     */
    const createNote = (noteContent) => {
        setIsNotesLoading(true);
        API
        .post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.ASSUMPTION_NOTES_ADD, 
            {
                ...HttpUtil.adminHttpHeaders(),
                body: 
                {
                    assumption_id: assumptionDetails.id,
                    user_id: current_user.id,
                    note: noteContent
                },
            })
            .then(res => {
                if (res.status === 201 && res.data.success) {
                    setIsNotesLoading(false)
                    getAssumptionDetails();
                    setShowEditor(false);
                    setEditorState(EditorState.createEmpty())
                }
            })
            .catch(error => {
                setIsNotesLoading(false);
                if (error.response) {
                    console.log(error.response);
                    addToast(
                        "Failed to add note.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                    getAssumptionDetails();
                }
            })
    }

    /**
     * API call to update existing note on assumption
     * 
     * @param {object} note 
     * @param {string} noteContent 
     */
    const updateNote = (note, noteContent) => {
        setIsNotesLoading(true);
        API
        .put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.ASSUMPTION_NOTES_UPDATE, 
            {
                ...HttpUtil.adminHttpHeaders(),
                body: 
                {
                    id: note.id,
                    assumption_id: assumptionDetails.id,
                    user_id: current_user.id,
                    note: noteContent
                },
            })
            .then(res => {
                if (res.status === 200 && res.data.success) {
                    setIsNotesLoading(false)
                    getAssumptionDetails();
                    setEditingEditorState(EditorState.createEmpty())
                }
            })
            .catch(error => {
                setIsNotesLoading(false);
                if (error.response) {
                    console.log(error.response);
                    addToast(
                        "Failed to update note.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                    getAssumptionDetails();
                }
            })
    }

    /**
     * handler for cancel button of the editor
     * 
     * @param {object} note 
     */
    const cancelEditing = (note = null) => {
        if (note) {
            setAssumptionNotes([...assumptionNotes].map(noteItem => {
                if(noteItem.id === note.id) {
                    return {
                        ...noteItem,
                        editing: false,
                    }
                }
                else return noteItem;
            }))
        } else {
            setShowEditor(false);
        }
    }

    /**
     * handler for edit button of editor
     * to open up the editor and load the note contents
     * 
     * @param {object} noteObj 
     * @param {integer} key 
     */
    const editNoteHandler = (noteObj, key) => {
        const contentBlock = htmlToDraft(noteObj.note);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditingEditorState(editorState);
        }
        setAssumptionNotes([...assumptionNotes].map(noteItem => {
            if(noteItem.id === noteObj.id) {
              return {
                ...noteItem,
                editing: true,
              }
            }
            else return noteItem;
          }))
    }

    /**
     * show delete note modal
     */
    const deleteNoteModalHandler = (note) => {
        setShowDeleteNoteModal(true);
        setDeleteNoteModalText(ENUMS.MODAL.DELETE_CONFIRMATION_TEXT + " this note ?");
        setSelectedNoteId(note.id);
    }

    /**
     * hide delete note modal - on cancel
     */
    const hideDeleteNoteModal = () => {
        setShowDeleteNoteModal(false);
    }

    /**
     * API call to delete note
     * @param {object} note 
     */
    const deleteNoteConfirmed = (noteId) => {
        API
        .del(
            ENUMS.AWS.API_NAME,
            ENUMS.API_ROUTES.ASSUMPTION_NOTES_DELETE,
            {
                ...HttpUtil.adminHttpHeaders(),
                body: {
                    note_id: noteId
                }
        
            }
        )
        .then(res => {
            if(res && res.status === 200) {
                addToast('Assumption note has been deleted successfully!', { 
                    appearance: 'success',
                    autoDismiss: true
                });
                getAssumptionDetails();
                setShowDeleteNoteModal(false);
            }
        })
        .catch(error => {
            if (error.response) {
                console.log(error.response);
                addToast(
                    "Failed to delete assumption note", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const getUserImageAsync = async (user) => {
        return await API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.S3_DOWNLOAD_SIGNED_URL, 
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                fileId: user.id,
                getFrom: 'profile-pictures'
            },
        });
    }

    const handleError = (err) => {
        if (err.response) {
            const content = (
                <div>
                    <strong>Failed to generate presigned url.</strong>
                    <div>
                        {err.response.data && err.response.data.message}
                    </div>
                </div>
            )
            addToast(
                content,
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
        }
    }

    useEffect(() => {
        // fetch profile pic for all uses present in notes
        if(assumptionDetails) {
            const allAssumptionNotes = assumptionDetails.Assumption_Notes;
            const fetchUserImages = async () => {
                return Promise.all(assumptionDetails.Assumption_Notes.map(note => getUserImageAsync(note.User)))
            }

            fetchUserImages().then(allResponses => {
                allResponses.map((res, index) => {
                    allAssumptionNotes[index].userImageURL = res.data.signedRequest;
                });
                setAssumptionNotes(allAssumptionNotes);
            });
        }
    }, [assumptionDetails]);

    useEffect(() => {
        if(current_user) {
            async function fetchUserImage() {
                try {
                    const res = await getUserImageAsync(current_user);
                    if (res.status === 200 && res.data && res.data.success) {
                        setUserImageSrc(res.data.signedRequest);
                    }
                } catch (err) {
                    handleError(err);
                }
            }
            fetchUserImage();
        }
    }, [current_user]);

    useEffect (() => {
        console.log('here');
        getAssumptionDetails();
    }, []);
    
    return (
        <>
            {
                isAssumptionDetailLoading
                ? <Loader></Loader>
                :  <>
                { assumptionDetails && 
                    <CheckPermissions
                        role={[RULES.ROLE_NAMES.PROJECT_MEMBER]}
                        perform={RULES.RULE_NAMES.PROJECT_VIEW}
                        data={[{projectId: assumptionDetails.project_id, projectList: projectList}]}
                        yes={() => {
                            return <React.Fragment>
                                <AssumptionViewHeader
                                    assumptionDetails={assumptionDetails}
                                    currentUser={current_user}
                                />
                                <Container className="mt--7" fluid>
                                    <Row>
                                        <Col className="order-xl-1" xl="12">
                                            <Card className="bg-secondary shadow">
                                                <CardHeader className="bg-white border-0">
                                                    <Row className="align-items-center">
                                                        <CheckPermissions
                                                            role={[RULES.ROLE_NAMES.PROJECT_OWNER, RULES.ROLE_NAMES.TASK_OWNER]}
                                                            perform={RULES.RULE_NAMES.TASK_EDIT}
                                                            data={[
                                                                {
                                                                    userId: current_user.id, 
                                                                    roleId: assumptionDetails.Project.project_owner_id
                                                                },
                                                                {
                                                                    userId: current_user.id,
                                                                    roleId: assumptionDetails.assumption_owner
                                                                }]}
                                                            yes={() => {
                                                                return <Button 
                                                                    className="float-right" 
                                                                    color="primary" 
                                                                    onClick={(e) => onClickEditButtonHandler()}
                                                                >
                                                                    Edit Assumption
                                                                </Button>
                                                            }}
                                                            no={() => {
                                                                return <Button 
                                                                    className="float-right" 
                                                                    color="primary" 
                                                                    onClick={(e) => onClickEditButtonHandler()}
                                                                    disabled={true}
                                                                >
                                                                    Edit Assumption
                                                                </Button>
                                                            }}
                                                        />
                                                    </Row>
                                                </CardHeader>
                                                <CardBody>
                                                    <Card>
                                                        <CardHeader className="cop-card-header">
                                                            Notes
                                                        </CardHeader>
                                                        {
                                                            notesLoading 
                                                                ? <div className="p-3"><Spinner size="sm" color="primary" /><span className="ml-3">Loading assumption notes..</span></div>
                                                                :  <CardBody>
                                                                <Row>
                                                                    <Col>
                                                                        {
                                                                            assumptionNotes && assumptionNotes.map((noteItem, key) => {
                                                                                return (
                                                                                    <div>
                                                                                        {
                                                                                            (showAllNotes || (key < 2))
                                                                                            ? <div className="note-item editnote note-wrap" key={key}>
                                                                                                <div className="note-d-flex">
                                                                                                    <div>
                                                                                                        <div className="note-user">
                                                                                                            <Media className="align-items-center">
                                                                                                                {
                                                                                                                    !noteItem.userImageURL
                                                                                                                    ? <div className="avatar-circle">
                                                                                                                        <span className="avatar-initials">
                                                                                                                        {UserUtil.formatUserForInitials(noteItem.User)}
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                    : <img
                                                                                                                        alt="..."
                                                                                                                        src={noteItem.userImageURL}
                                                                                                                        className="small-profile-pic"
                                                                                                                    />
                                                                                                                }
                                                                                                            </Media>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {
                                                                                                        noteItem.editing &&
                                                                                                        <div>
                                                                                                            <div className="comment-box-wrapper edit">
                                                                                                                <Editor
                                                                                                                    editorState={editingEditorState}
                                                                                                                    toolbarClassName="toolbarClassName"
                                                                                                                    wrapperClassName="wrapperClassName"
                                                                                                                    editorClassName="editorClassName"
                                                                                                                    onEditorStateChange={onEditingEditorStateChange}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                {
                                                                                                                    isNotesLoading ?
                                                                                                                    <Spinner size="sm" color="primary" /> :
                                                                                                                    <Button
                                                                                                                        size="sm"
                                                                                                                        color="primary"
                                                                                                                        onClick={e => saveNoteHandler(noteItem)}
                                                                                                                    >
                                                                                                                        Save
                                                                                                                    </Button>
                                                                                                                }
                                                                                                                <Button
                                                                                                                    size="sm"
                                                                                                                    color="secondary"
                                                                                                                    onClick={e => cancelEditing(noteItem)}
                                                                                                                >
                                                                                                                    Cancel
                                                                                                                </Button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }
                                                                                                    {
                                                                                                        !noteItem.editing &&
                                                                                                        <div>
                                                                                                            <div>
                                                                                                                <span className="note-user-name">{noteItem.User.first_name} {noteItem.User.last_name}</span>
                                                                                                                <span className="note-created-date ml-2 mr-2">
                                                                                                                    {
                                                                                                                        format(
                                                                                                                            DateTimeUtils.convertTimezone(
                                                                                                                                new Date(noteItem.createdAt), current_user), 
                                                                                                                            DateTimeUtils.getUserDateTimeFormat(
                                                                                                                                current_user.date_format, current_user.twenty_four_hour_clock))
                                                                                                                    }
                                                                                                                </span>
                                                                                                                <span className="note-updated-date">
                                                                                                                    {
                                                                                                                        !DateTimeUtils.compareBetweenDates(noteItem.createdAt, noteItem.updatedAt) 
                                                                                                                        ? (
                                                                                                                            <span>
                                                                                                                                <span className="note-edited-tag" id="edited_tooltip">Edited</span>
                                                                                                                                <UncontrolledTooltip
                                                                                                                                    delay={0}
                                                                                                                                    placement="top"
                                                                                                                                    target="edited_tooltip"
                                                                                                                                >
                                                                                                                                    {format(
                                                                                                                                        DateTimeUtils.convertTimezone(
                                                                                                                                            new Date(noteItem.updatedAt), current_user), 
                                                                                                                                        DateTimeUtils.getUserDateTimeFormat(
                                                                                                                                            current_user.date_format, current_user.twenty_four_hour_clock))}
                                                                                                                                </UncontrolledTooltip>
                                                                                                                            </span>
                                                                                                                        )
                                                                                                                        : ''
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <div className="note-content">{parse(noteItem.note)}</div>
                                                                                                            {
                                                                                                                (noteItem.User.id == current_user.id) && 
                                                                                                                <div className="note-actions">
                                                                                                                    <span className="note-action-button" onClick={e => editNoteHandler(noteItem, key)}>Edit</span>
                                                                                                                    <span className="note-action-button" onClick={e => deleteNoteModalHandler(noteItem)}>Delete</span>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            : <span></span>
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            (!showAllNotes && (assumptionNotes.length > 2)) &&
                                                                            <Button block color="secondary" onClick={() => setShowAllNotes(true)} style={{ marginBottom: '1rem', width: '40vw' }}>
                                                                                View older notes ({assumptionNotes.length - 2} remaining)
                                                                            </Button>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>   
                                                                        <div className="comment-section">
                                                                            <div>
                                                                                <div className="note-user">
                                                                                    <Media className="align-items-center">
                                                                                        {
                                                                                            !userImageSrc
                                                                                            ? <div className="avatar-circle">
                                                                                                <span className="avatar-initials">
                                                                                                {UserUtil.formatUserForInitials(current_user)}
                                                                                                </span>
                                                                                            </div>
                                                                                            : <img
                                                                                                alt="..."
                                                                                                src={userImageSrc}
                                                                                                className="small-profile-pic"
                                                                                            />
                                                                                        }
                                                                                    </Media>
                                                                                    <div className="note-user-name">{UserUtil.formatUserForTableColumn(current_user)}</div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                !showEditor &&
                                                                                <div className="add-notes-box" onClick={(e) => setShowEditor(true)}>Add a note...</div>
                                                                            }
                                                                            {
                                                                                showEditor &&
                                                                                <div>
                                                                                    <div className="comment-box-wrapper note-wrap">
                                                                                        <Editor
                                                                                            editorState={editorState}
                                                                                            toolbarClassName="toolbarClassName"
                                                                                            wrapperClassName="wrapperClassName"
                                                                                            editorClassName="editorClassName"
                                                                                            onEditorStateChange={onEditorStateChange}
                                                                                        />
                                                                                    </div>
                                                                                    <div>
                                                                                        {
                                                                                            isNotesLoading ?
                                                                                            <Spinner size="sm" color="primary" /> :
                                                                                            <Button
                                                                                                size="sm"
                                                                                                color="primary"
                                                                                                onClick={e => saveNoteHandler()}
                                                                                            >
                                                                                                Save
                                                                                            </Button>
                                                                                        }
                                                                                        <Button
                                                                                            size="sm"
                                                                                            color="secondary"
                                                                                            onClick={e => cancelEditing()}
                                                                                        >
                                                                                            Cancel
                                                                                        </Button>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        }
                                                    </Card>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>
                            </React.Fragment>
                        }}
                        no={() => {
                            return <>You dont have access </>
                            // return <Redirect to="/"/>
                        }}
                    />
                }
                </>       
            }
        </>
    )
}