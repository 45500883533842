import { API } from "aws-amplify";
import CustomErrorMessage from "components/CustomErrorMessage/CustomErrorMessage";
import DataOptions from "constants/appDataOptions";
import ENUMS from "constants/appEnums";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Select from "react-select";
import  axios  from "../../axiosConfig";
import { useToasts } from "react-toast-notifications";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from "reactstrap";
import HttpUtil from "util/HttpUtil";
export default function FeedbackModal(props) {
    const [feedbackType, setFeedbackType] = useState();
    const [comment, setComment] = useState();
    const [contactAllowed, setContactAllowed] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [fileUploadPath, setFileUploadPath] = useState('');
    const [issueSeverity, setIssueSeverity] = useState(ENUMS.ISSUE_SEVERITY.LOW);

    const { addToast } = useToasts();
    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur"
    });
    const user = useSelector(state => state.authReducer.user);

    const handleConfirm = () => {
        API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.FEEDBACK_ADD, 
        {
            ...HttpUtil.adminHttpHeaders(),
            body:
            {
                type: feedbackType,
                comment,
                contactable: contactAllowed,
                user_id: user.id,
                issue_severity: feedbackType === ENUMS.FEEDBACK_TYPES.BUG ? issueSeverity : '',
                fileUploadPath
            },
        })
        .then(res => {
            if (res.status === 201 && res.data.success) {
                addToast(
                    "Thank you for your feedback.", 
                    { 
                        appearance: 'success',
                        autoDismiss: true
                    }
                );
                closeModal();
            }
        })
        .catch(error => {
            if (error.response) {
                const content = (
                    <div>
                        <strong>Failed to add feedback.</strong>
                        <div>
                           {error.response.data.message }
                        </div>
                    </div>
                )
                addToast(
                    content,
                    { 
                        appearance: 'error',
						autoDismiss: true
                    }
                );
            }
        })
    }

    const getLabel = (feedbackTypeSelected) => {
        switch (feedbackTypeSelected) {
            case ENUMS.FEEDBACK_TYPES.BUG:
                return 'Bug Description:';
            case ENUMS.FEEDBACK_TYPES.QUESTION:
                return 'Questions:';
            case ENUMS.FEEDBACK_TYPES.COMMENT:
                return 'Comments:';
            case ENUMS.FEEDBACK_TYPES.SUGGESTION:
                return 'Suggestions:';
            default: 
                return 'Description';
        }
    }

    const onFileChange = (event) => {
        const file = event.target.files[0];
        setIsSubmitting(true);
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.S3_UPLOAD_SIGNED_URL, 
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                fileName: file.name,
                fileType: file.type,
                path: 'feedback'
            },
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                uploadFileToS3(res.data.signedRequest, file);
            }
        })
        .catch(error => {
            setIsSubmitting(false);
            if (error.response) {
                const content = (
                    <div>
                        <strong>Failed to generate presigned url.</strong>
                        <div>
                            {error.response.data && error.response.data.message}
                        </div>
                    </div>
                )
                addToast(
                    content,
                    { 
                        appearance: 'error',
						autoDismiss: true
                    }
                );
            }
        })
    }; 

    const uploadFileToS3 = (signedRequest, file) => {
        axios.put(signedRequest.presignedURL, file)
        .then(result => {
            console.log(result);
            console.log(signedRequest.presignedURL);
            setFileUploadPath(signedRequest.fileUploadPath);
            setIsSubmitting(false);
            addToast(
                "File Uploaded", 
                { 
                    appearance: 'success',
                    autoDismiss: true
                }
            );
        })
        .catch(err => {
            setIsSubmitting(false);
            console.log(err);
        })
    }

    const resetModal = () => {
        setFeedbackType(null);
        setComment('');
        setContactAllowed(true);
    }

    const closeModal = () => {
        resetModal();
        props.closeFeedbackModal();
    }

    return (
        <Modal
            backdrop="static"
            size="md"
            isOpen={props.showModal}
            toggle={() => closeModal()}
        >
            <ModalHeader toggle={() => closeModal()} tag="h2">Give Feedback</ModalHeader>
            <Form role="form" onSubmit={handleSubmit(handleConfirm)}>
                <ModalBody>
                    <p className="font-14">Consider providing suggestions and feedback to improve CutoverPlan.</p>
                    <p className="font-14">
                        All feedback goes to our dev teams and helps us improve our products.
                    </p>
                    <FormGroup className="mb-3">
						<Label for="feedback-type">I am giving feedback to <span className="required-asterix">*</span></Label>
						<Select
							id="feedback-type"
							name="feedback-type"
							className="form-control-alternative"
							options={DataOptions.FEEDBACK_OPTIONS}
							value={DataOptions.FEEDBACK_OPTIONS.filter(option => option.value === feedbackType)}
                            onChange={(e) => setFeedbackType(e.value)}
                            innerRef={register({
                                required: "Please select an option.",
                            })}
						/>
						<CustomErrorMessage errors={errors} name="feedback-type" />
					</FormGroup>
                    {
                        feedbackType &&
                        <>
                            {   feedbackType === ENUMS.FEEDBACK_TYPES.BUG &&
                                <FormGroup className="mb-3">
                                    <Label for="issue-severity">Severity of issue<span className="required-asterix">*</span></Label>
                                    <Select
                                        id="issue-severity"
                                        name="issue-severity"
                                        className="form-control-alternative"
                                        options={DataOptions.ISSUE_SEVERITY_OPTIONS}
                                        value={DataOptions.ISSUE_SEVERITY_OPTIONS.filter(option => option.value === issueSeverity)}
                                        onChange={(e) => setIssueSeverity(e.value)}
                                        innerRef={register({
                                            required: "Please select an severity of the issue..",
                                        })}
                                    />
                                    <CustomErrorMessage errors={errors} name="issue-severity" />
                                </FormGroup>
                            }
                            <FormGroup>
                                <Label for="comments">{getLabel(feedbackType)} <span className="required-asterix">*</span></Label>
                                <Input
                                    type="textarea"
                                    name="comments"
                                    id="comments"
                                    value={comment}
                                    onChange={(e)=>setComment(e.target.value)}
                                    innerRef={register({
                                        required: "Please provide some comments.",
                                    })}
                                />
                                <CustomErrorMessage errors={errors} name="comments" />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="file"
                                    name="file"
                                    id="file"
                                    onChange={(e) => onFileChange(e)}
                                />
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input 
                                        id="contact-allowed"
                                        className="form-control-alternative"
                                        type="checkbox"
                                        defaultChecked={contactAllowed}
                                        onChange={(e) => setContactAllowed(!contactAllowed)}
                                    />{' '}
                                    CutoverPlan may contact me about this feedback.
                                </Label>
                            </FormGroup>
                        </>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button 
                        color="secondary" 
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </Button>
                    <Button 
                        color="primary" 
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Submit Feedback
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
