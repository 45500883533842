/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";

export default function UserHeader () {
    const user = useSelector(state => state.authReducer.user);

    return (
      <>
        <div
            className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        >
            {/* Mask */}
            <span className="mask bg-info opacity-8" />
            {/* Header container */}
            <Container className="d-flex align-items-center" fluid>
                <Row>
                    <Col lg="12" md="12">
                        <h1 className="display-2 text-white text-capitallize">{user.first_name} {user.last_name}</h1>
                    </Col>
                </Row>
            </Container>
        </div>
      </>
    );
}
