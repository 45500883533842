import React, { useState, useEffect } from "react";
import {
    ListGroup,
    ListGroupItem,
    Badge,
} from "reactstrap";
import ENUMS from "constants/appEnums";
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import { useToasts } from 'react-toast-notifications';
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import ViewFileModal from "components/ViewFileModal/ViewFileModal";
import FileUtil from "util/FileUtil";
import OptionsRendererPrime from "components/OptionsRenderer/OptionsRendererPrime";
import { useHistory } from "react-router-dom";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";

export default function FileListTable (props) {

    const { addToast } = useToasts();
    const history = useHistory();

    // Team Data and Header for Table
    const [fileList, setFileList] = useState([]);
    
    /**
     * Delete Modal
     */
    const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);
    const [deleteFileModalText, setDeleteFileModalText] = useState("");
    const [selectedFileId, setSelectedFileId] = useState("");
    const [deleteModalActionTexts, setDeleteModalActionTexts] = useState({
        'confirm': ENUMS.BUTTON_TEXT.DELETE_ACTION_TEXT_CONFIRM,
        'cancel': ENUMS.BUTTON_TEXT.CANCEL
    });

    /**
     * View Modal
     */
    const [viewFileModalOpen, setViewFileModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [viewFileUrl, setViewFileUrl] = useState('');
    const [viewFileType, setViewFileType] = useState('');
    const [docsToView, setDocsToView] = useState("");

    const populateFileData = () => {
        const updatedFile = props.allFileList.map(file => {
            file['type_of_file'] = file.File_Type ? file.File_Type.type_name : '';
            return file;
        });
        setFileList(updatedFile);
    }

    const refreshPageOnSubmit = (message) => {
        setShowDeleteFileModal(false);
        props.viewMode == 'project' ? props.refreshViewOnAction(ENUMS.TABS.FILES_LIST, message) : props.refreshViewOnAction(message);
    }

    const openViewFileModal = (data) => {
        setModalTitle(data.name);
        const fileExtension = FileUtil.extractFileExtension(data.name);
        setViewFileType(fileExtension);
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.S3_DOWNLOAD_SIGNED_URL, 
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                fileId: data.id,
                getFrom: props.loadFrom
            },
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setViewFileUrl(res.data.signedRequest);
                setDocsToView([{uri: res.data.signedRequest, fileType: fileExtension}]);
                setViewFileModalOpen(true);
            }
        })
        .catch(error => {
            if (error.response) {
                const content = (
                    <div>
                        <strong>Failed to generate presigned url.</strong>
                        <div>
                            {error.response.data && error.response.data.message}
                        </div>
                    </div>
                )
                addToast(
                    content,
                    { 
                        appearance: 'error',
						autoDismiss: true
                    }
                );
            }
        })

    }

    const hideDeleteFileModal = () => {
        setShowDeleteFileModal(false);
    }

    const deleteFileConfirmed = (fileId) => {
        let params;
        let apiRoute;

        if (props.loadFrom === 'project') {
            apiRoute = ENUMS.API_ROUTES.PROJECT_FILE_DELETE;
            params = {
                project_file_id: fileId
            };
        } else{
            apiRoute = ENUMS.API_ROUTES.TASK_FILE_DELETE;
            params = {
                task_file_id: fileId
            };
        }
        API.del(ENUMS.AWS.API_NAME, apiRoute, 
        {
            ...HttpUtil.adminHttpHeaders(),
            body: params
        })
        .then(res => {
            if(res && res.status === 200) {
                refreshPageOnSubmit("File has been deleted successfully");
            }
        })
        .catch(error => {
            if (error.response) {
                console.log(error.response);
                addToast(
                    "Failed to delete team.", 
                    { 
                        appearance: 'error',
						autoDismiss: true
                    }
                );
            }
        })
    }

    const closeViewModal = () => {
        setViewFileModalOpen(false);
    }

    const openTaskView = (taskId) => {
        history.push({
            pathname: '/admin/tasks/task-view',
            search: '?taskId='+taskId,
            state: { passedId: taskId }
        });
    }

    const openDeleteFileModal = (file) => {
        setShowDeleteFileModal(true);
        setDeleteFileModalText(ENUMS.MODAL.DELETE_CONFIRMATION_TEXT + file.name + " file ?");
        setSelectedFileId(file.id);
    }

    const optionsList = React.useMemo(() => 
    [
        {
            itemName: 'View',
            itemType: 'View',
            openViewModal: openViewFileModal,
        },
        {
            itemName: 'Delete',
            itemType: ENUMS.RENDER_OPTIONS_TYPE.DELETE,
            delete: openDeleteFileModal,
            // role: [RULES.ROLE_NAMES.TEAM_LEADER, RULES.ROLE_NAMES.PROJECT_OWNER],
            // perform: RULES.RULE_NAMES.FILE_DELETE,
        }
    ], []);

    useEffect (() => {
        populateFileData();
    }, [props.allFileList]);

    return (
        <>
            <ListGroup>
                    {
                        fileList.map((file, key) => {
                            return <ListGroupItem key={key}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <div>
                                            <span className="cop-link" onClick={(e) => openViewFileModal(file)}>{ file.name }</span>
                                            { 
                                                file.type_of_file && 
                                                <span>
                                                    <Badge pill color="info" className="ml-2" id={"type_of_file" + key}>Type: {file.type_of_file}</Badge>
                                                </span>
                                            }
                                            { 
                                                file.Task && file.Task.task_number && 
                                                <span>
                                                    <Badge pill color="primary" className="ml-3 cursor-pointer" id={"task_file" + key} onClick={(e) => openTaskView(file.task_id)}>{file.Task.task_number}</Badge>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        placement="top"
                                                        target={"task_file" + key}
                                                    >
                                                        {file.Task.task_number + ": " + file.Task.task_name}
                                                    </UncontrolledTooltip>
                                                </span>
                                            }
                                            { 
                                                file.Action && file.Action.description && 
                                                <span>
                                                    <Badge pill color="default" className="ml-3 cursor-pointer" id={"action_file" + key}>{file.Action.id}</Badge>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        placement="top"
                                                        target={"action_file" + key}
                                                    >
                                                        {file.Action.id + ": " + file.Action.description}
                                                    </UncontrolledTooltip>
                                                </span>
                                            }
                                        </div>
                                        <div>
                                            { file.description }
                                        </div>
                                    </div>
                                    <div>
                                        <OptionsRendererPrime
                                            optionsList={optionsList} 
                                            data={file} 
                                            projectOwnerId={props.projectOwnerId}
                                        />
                                    </div>
                                </div>
                            </ListGroupItem>
                        })
                    }
            </ListGroup>
            { 
                viewFileModalOpen && 
                <ViewFileModal
                    modalTitle={modalTitle}
                    modalOpen={viewFileModalOpen}
                    viewFileUrl={viewFileUrl}
                    viewFileType={viewFileType}
                    closeModal={closeViewModal}
                    docsToView={docsToView}
                /> 
            }
            {
                showDeleteFileModal && 
                <ConfirmationModal
                    actionText={deleteModalActionTexts}
                    onCancel={hideDeleteFileModal}
                    onConfirm={deleteFileConfirmed}
                    modalBody={deleteFileModalText}
                    showModal={showDeleteFileModal}
                    modalTitle={ENUMS.MODAL.DELETE_FILE_TITLE}
                    data={selectedFileId}
                />
            }
            
        </>
    )
}
