import ENUMS from "./appEnums";

const WORKING_DAYS_OPTIONS = [
    { value: 'sunday', label: 'Sunday' },
    { value: 'monday', label: 'Monday' },
    { value: 'tuesday', label: 'Tuesday' },
    { value: 'wednesday', label: 'Wednesday' },
    { value: 'thursday', label: 'Thursday' },
    { value: 'friday', label: 'Friday' },
    { value: 'saturday', label: 'Saturday' },
];

const CLOCK_OPTIONS = [
    { label: '12 Hour', value: false },
    { label: '24 Hour', value: true }
];

const EXCEPTION_OPTIONS = [
    { label: 'Holiday', value: ENUMS.EXCEPTION_DEFAULT_VALUES.HOLIDAY },
    { label: 'Working Days', value: ENUMS.EXCEPTION_DEFAULT_VALUES.WORKING_DAY },
];

const DURATION_FORMATS = [
    { label: 'Hour', value: ENUMS.DURATION_FORMAT_VALUES.HOUR },
    { label: 'Day', value: ENUMS.DURATION_FORMAT_VALUES.DAY }
];

const DATE_FORMATS = [
    {
        value: "d/M/yy",
        label: "1/1/2020 (d/m/yy)"
    },
    {
        value: "yyyy/MM/dd",
        label: "2020/01/01 (yy/mm/dd)"
    },
    {
        value: "dd-MMM-yyyy",
        label: "1-Jan-2020 (d-M-yy)"
    },
    {
        value: "dd/MM/yyyy",
        label: "01/01/2020 (dd/mm/yy)"
    },
];

const FEEDBACK_OPTIONS = [
    {
        value: ENUMS.FEEDBACK_TYPES.BUG,
        label: 'Report a bug'
    },
    {
        value: ENUMS.FEEDBACK_TYPES.QUESTION,
        label: 'Ask a question?'
    },
    {
        value: ENUMS.FEEDBACK_TYPES.COMMENT,
        label: 'Leave a comment'
    },
    {
        value: ENUMS.FEEDBACK_TYPES.SUGGESTION,
        label: 'Give Suggestions'
    }
]

const ISSUE_SEVERITY_OPTIONS = [
    {
        value: ENUMS.ISSUE_SEVERITY.LOW,
        label: ENUMS.IMPACT.low
    },
    {
        value: ENUMS.ISSUE_SEVERITY.MEDIUM,
        label: ENUMS.IMPACT.medium
    },
    {
        value: ENUMS.ISSUE_SEVERITY.HIGH,
        label: ENUMS.IMPACT.high
    }
]

const TASK_ACTION_STATUS_OPTIONS = [
    {
        value: ENUMS.TASK_STATUS.P,
        label: ENUMS.TASK_STATUS.P_LABEL
    },
    {
        value: ENUMS.TASK_STATUS.S,
        label: ENUMS.TASK_STATUS.S_LABEL
    },
    {
        value: ENUMS.TASK_STATUS.SC,
        label: ENUMS.TASK_STATUS.SC_LABEL
    },
    {
        value: ENUMS.TASK_STATUS.PA,
        label: ENUMS.TASK_STATUS.PA_LABEL
    },
    {
        value: ENUMS.TASK_STATUS.FC,
        label: ENUMS.TASK_STATUS.FC_LABEL
    },
    {
        value: ENUMS.TASK_STATUS.FT,
        label: ENUMS.TASK_STATUS.FT_LABEL
    },
    {
        value: ENUMS.TASK_STATUS.BL,
        label: ENUMS.TASK_STATUS.BL_LABEL
    }
    
]

const RAID_STATUS_OPTIONS = [
    { label: ENUMS.RAID_STATUS.OPEN_LABEL, value: ENUMS.RAID_STATUS.OPEN },
    { label: ENUMS.RAID_STATUS.CLOSED_LABEL, value: ENUMS.RAID_STATUS.CLOSED },
];

const RISK_LIKELIHOOD_OPTIONS = [
    { label: 'Rare', value: 'rare' },
    { label: 'Unlikely', value: 'unlikely' },
    { label: 'Possible', value: 'possible' },
    { label: 'Likely', value: 'likely' },
    { label: 'Almost Certain', value: 'almost_certain' },
];

const RISK_IMPACT_OPTIONS = [
    { label: 'Insignificant', value: 'insignificant' },
    { label: 'Minor', value: 'minor' },
    { label: 'Moderate', value: 'moderate' },
    { label: 'Major', value: 'major' },
    { label: 'Severe', value: 'severe' },
];

const RISK_RATING_OPTIONS = [
    { label: ENUMS.IMPACT.low, value: 'low'},
    { label: ENUMS.IMPACT.medium, value: 'medium'},
    { label: ENUMS.IMPACT.high, value: 'high'},
    { label: ENUMS.IMPACT.extreme, value: 'extreme'},
];

const YES_NO_OPTIONS = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
];

const MILESTONE_START_END_OPTIONS = [
    { label: 'Task start time', value: true },
    { label: 'Task end time', value: false }
];

const TASK_PERCENTAGE_COMPLETE_OPTIONS = [
    { label: '0%', value: 0 },
    { label: '10%', value: 10 },
    { label: '20%', value: 20 },
    { label: '30%', value: 30 },
    { label: '40%', value: 40 },
    { label: '50%', value: 50 },
    { label: '60%', value: 60 },
    { label: '70%', value: 70 },
    { label: '80%', value: 80 },
    { label: '90%', value: 90 },
    { label: '100%', value: 100 },
];

const PREFERRED_MODE_OF_CONTACT_OPTIONS = [
    { label: 'Email', value: 'email' },
    { label: 'Phone', value: 'phone' }
];

const DataOptions = {
    WORKING_DAYS_OPTIONS,
    CLOCK_OPTIONS,
    EXCEPTION_OPTIONS,
    DURATION_FORMATS,
    DATE_FORMATS,
    FEEDBACK_OPTIONS,
    ISSUE_SEVERITY_OPTIONS,
    TASK_ACTION_STATUS_OPTIONS,
    RAID_STATUS_OPTIONS,
    RISK_IMPACT_OPTIONS,
    RISK_LIKELIHOOD_OPTIONS,
    RISK_RATING_OPTIONS,
    YES_NO_OPTIONS,
    MILESTONE_START_END_OPTIONS,
    TASK_PERCENTAGE_COMPLETE_OPTIONS,
    PREFERRED_MODE_OF_CONTACT_OPTIONS
}

export default DataOptions;