/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Button
} from "reactstrap";

class ComponentsNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      displayLogo: false
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.changeColor);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeColor);
  }
  changeColor = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.body.scrollTop > 50
    ) {
      this.setState({
        color: "navbar-scrolled",
        displayLogo: true
      });
    } else if (
      document.documentElement.scrollTop < 51 ||
      document.body.scrollTop < 51
    ) {
      this.setState({
        color: "navbar-transparent",
        displayLogo: false
      });
    }
  };
  toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  onCollapseExiting = () => {
    this.setState({
      collapseOut: "collapsing-out"
    });
  };
  onCollapseExited = () => {
    this.setState({
      collapseOut: ""
    });
  };
  scrollToDownload = () => {
    document
      .getElementById("download-section")
      .scrollIntoView({ behavior: "smooth" });
  };
  getImgSrc = () => {
    return require("assets/img/home/logocop.svg")
  }

  handleSignup = () => {

  }

  handleLogin = () => {

  }

  render() {
    return (
      <Navbar
        className={"bring-front fixed-top " + this.state.color}
        color-on-scroll="100"
        expand="lg"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href="/"
              id="navbar-brand"
            >
              { 
                <img
                  alt="logo"
                  className=""
                  src={this.getImgSrc()}
                />
              }
            </NavbarBrand>
            <button
              aria-expanded={this.state.collapseOpen}
              className="navbar-toggler navbar-toggler nav-hamburger"
              onClick={this.toggleCollapse}
            >
              <img src={require("assets/img/home/hamburger.svg")}></img>
            </button>
            <button
              aria-expanded={this.state.collapseOpen}
              className="navbar-toggler navbar-toggler invisible"
              onClick={this.toggleCollapse}
            >
              <img src={require("assets/img/home/hamburger.svg")}></img>
            </button>
          </div>
          <Collapse
            className={"justify-content-end collapse-modal " + this.state.collapseOut}
            navbar
            isOpen={this.state.collapseOpen}
            onExiting={this.onCollapseExiting}
            onExited={this.onCollapseExited}
          >
            <div className="navbar-collapse-header" data-menuanchor="onClick">
              <Row>
                <Col className="collapse-brand" xs="6">
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button
                    aria-expanded={this.state.collapseOpen}
                    className="navbar-toggler"
                    onClick={this.toggleCollapse}
                  >
                    <img src={require("assets/img/home/times.svg")}></img>
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>
                <NavItem className="d-flex align-items-center justify-content-center">
                    <NavLink
                      className={"indexpage-link text-dark nav-link-icon " + (this.props.currentSlideIndex === 9 ? 'active' : '')}
                      href="/auth/contact-us"
                    >
                    <span className="nav-link-inner--text cop-navbar-text">Contact Us</span>
                    </NavLink>
                </NavItem>
                <LinkContainer to="/auth/register">
                    <NavItem>
                      <Button
                        className="index-button-primary cop-navbar-button cop-navbar-text cursor-pointer nav-signup"
                        type="btn"
                        onClick={(e) => this.handleSignup(e)}
                      >
                        Sign Up
                      </Button>
                        {/* <NavLink
                          className="nav-link-icon"
                          to="/auth/register"
                          tag={Link}
                        >
                          
                        </NavLink> */}
                    </NavItem>
                </LinkContainer>
                <LinkContainer to="/auth/login">
                    <NavItem>
                      <Button
                        className="index-button-secondary cop-navbar-button cop-navbar-text cursor-pointer"
                        type="btn"
                        onClick={(e) => this.handleLogin(e)}
                      >
                        Log in
                      </Button>
                        {/* <NavLink
                        className="nav-link-icon"
                        to="/auth/login"
                        tag={Link}
                        >
                        <i className="ni ni-key-25" />
                        <span className="nav-link-inner--text">Login</span>
                        </NavLink> */}
                    </NavItem>
                </LinkContainer>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default ComponentsNavbar;
