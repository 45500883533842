import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, UncontrolledTooltip, Col, Row, Card, CardBody } from "reactstrap";

export default function ViewRaidModal(props) {
    
    return (
        <Modal
            size="md"
            isOpen={props.modalOpen}
            toggle={() => props.closeModal()}
            backdrop="static"
        >
            <ModalHeader toggle={() => props.closeModal()} tag="h2">
                {props.modalTitle}
            </ModalHeader>
                <ModalBody className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody>
                            <Col xs="12">
                                <Row>
                                {
                                    props.list.map((field, index) => {
                                        return <Col xs="6" key={index}>
                                            <div><strong>{field.headerName}</strong></div>
                                            <div>{props.rowData[field.field]}</div>
                                            {
                                                index % 2 == 0 && <hr></hr>
                                            }
                                        </Col>
                                    })
                                }
                                </Row>
                            </Col>
                        </CardBody>
                    </Card>
                </ModalBody>
        </Modal>
    )
}
