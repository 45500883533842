import React, { useState, useEffect } from "react";
import ProjectViewHeader from "components/Headers/ProjectViewHeader";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { 
    Row, 
    Col, 
    Container, 
    Card, 
    CardHeader, 
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    UncontrolledTooltip,
    Button
} from "reactstrap";
import classnames from "classnames";
import { API } from "aws-amplify";
import ProjectAddEditModal from "components/Project/ProjectAddEditModal/ProjectAddEditModal";
import ENUMS from "constants/appEnums";
import { useToasts } from 'react-toast-notifications';
import { useSelector } from "react-redux";
import HttpUtil from "util/HttpUtil";
import { format } from "date-fns";
import ProjectViewTaskTable from "components/Project/ProjectView/ProjectViewTaskTable/ProjectViewTaskTable";
import ProjectViewTeamsTable from "components/Project/ProjectView/ProjectViewTeamsTable/ProjectViewTeamsTable";
import ProjectViewActionTable from "components/Project/ProjectView/ProjectViewActionTable/ProjectViewActionTable";
import ProjectViewStakeholderTable from "components/Project/ProjectView/ProjectViewStakeholderTable/ProjectViewStakeholderTable";
import RULES from "rbac/rules";
import "./ProjectView.css";
import CheckPermissions from "components/CheckPermissions/CheckPermissions";
import FileListTable from "components/FileListTable/FileListTable";
import UploadModal from "components/UploadModal/UploadModal";
import ProjectRaidView from "components/RAID/ProjectRaidView";
import Loader from "components/Loader/Loader.js";
import TaskUtil from "util/TaskUtil";
import ProjectUtil from "util/ProjectUtil";
import ExecutionModes from "components/ExecutionModes/ExecutionModes";
import _ from "lodash";
import RehearsalUtil from "util/RehearsalUtil";
import DateTimeUtils from "util/DateTimeUtils";
import { useLocalStorage } from "hooks/useLocalStorage";

export default function ProjectView (props) {

    let location = useLocation();
    const { addToast } = useToasts();

    const history = useHistory();

    // grab current state
    const user = useSelector(state => state.authReducer.user);

    // Tab management
    const [activeTab, setActiveTab] = useLocalStorage(ENUMS.LOCAL_STORAGE_KEYS.PROJECT_VIEW_ACTIVE_TAB, ENUMS.TABS.TASK_LIST);

    const [projectDetails, setProjectDetails] = useState("");
    const [projectTeamsList, setProjectTeamsList] = useState([]);
    const [projectTaskList, setProjectTaskList] = useState([]);
    const [projectActionList, setProjectActionList] = useState([]);
    const [projectStakeholderList, setProjectStakeholderList] = useState([]);
    const [projectFileList, setProjectFileList] = useState([]);
    const [taskFileList, setTaskFileList] = useState([]);
    const [rehearsalsList, setRehearsalsList] = useState([]);
    
    const [cardItems, setCardItems] = useState([]);
    /**
     * Edit Modal
     */
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editProjectData, setEditProjectData] = useState({});

    /** Upload modal */
    const [uploadModalOpen, setUploadModalOpen] = useState(false);

    /** Report dropdown */
    const [reportDropdownOpen, setReportDropdownOpen] = useState(false);

    // fetch user project lists
    const projectList = useSelector(state => state.authReducer.userProjectList);

    // Raid
    const [risks, setRisks] = useState([]);
    const [assumptions, setAssumptions] = useState([]);
    const [issues, setIssues] = useState([]);
    const [dependencies, setDependencies] = useState([]);
    const [openRisks, setOpenRisks] = useState();
    const [openIssues, setOpenIssues] = useState();
    const [openTasks, setOpenTasks] = useState();
    const [targetDate, setTargetDate] = useState();
    const [openAction, setOpenAction] = useState();

    // execution mode
    const [activeRehearsal, setActiveRehearsal] = useState();

    // Execution modes modal
    const [openExecutionModes, setOpenExecutionModes] = useState(false);
    
    const [isProjectDetailLoading, setIsProjectDetailLoading] = useState(true);
    
    const fetchProjectDetails = (projectIdPassed) => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_GET_SINGLE,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                projectId: projectIdPassed
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success === true) {
                const taskListData = _.cloneDeep(res.data.projectDetails.Tasks);
                setProjectDetails(res.data.projectDetails);
                setProjectTaskList([...taskListData]);
                setProjectActionList(res.data.projectDetails.Actions);
                setProjectTeamsList(res.data.projectDetails.Teams);
                setProjectFileList(res.data.projectDetails.Project_Files);
                if (res.data.projectDetails.Rehearsals.length > 0) {
                    const activeR = res.data.projectDetails.Rehearsals.find(rehearsal => rehearsal.active)
                    setActiveRehearsal(activeR)
                }

                let allTaskFiles = [];

                taskListData.map(task => {
                    allTaskFiles = [...Array.from(new Set([...allTaskFiles, ...task.Task_Files]))]
                    return task.Task_Files;
                })

                setOpenTasks(TaskUtil.getNumberOfOpenTasks(taskListData));

                setOpenAction(ProjectUtil.getNumberOfOpenActions(res.data.projectDetails.Actions));

                setTaskFileList(allTaskFiles);

                setTargetDate(TaskUtil.getTargetDateFromTaskList(taskListData, user));
                setIsProjectDetailLoading(false);
            }
        })
        .catch(error => {
            if (error.response) {
                setIsProjectDetailLoading(false);
                console.error(error.response);
                addToast(
                    error.response || "Failed to fetch project details",
                    {
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        });
    }

    const getRaidDetails = (projectId) => {
        getRisks(projectId);
        getAssumptions(projectId);
        getIssues(projectId);
        getDependencies(projectId);
    };

    const getRisks = (projectId) => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_RISK_GET_ALL,
            {
                ...HttpUtil.adminHttpHeaders(),
                queryStringParameters: {
                    project_id: projectId
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success === true) {
                    setRisks(_.cloneDeep(res.data.projectRiskData.Project_Risks));
                    setOpenRisks(ProjectUtil.getNumberOfOpenRisks(res.data.projectRiskData.Project_Risks));
                }
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        error.response || "Failed to fetch project stakeholders",
                        {
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            });
    }

    const getAssumptions = (projectId) => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_ASSUMPTION_GET_ALL,
            {
                ...HttpUtil.adminHttpHeaders(),
                queryStringParameters: {
                    project_id: projectId
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success === true) {
                    setAssumptions(res.data.projectAssumptionData.Project_Assumptions);
                }
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        error.response || "Failed to fetch project stakeholders",
                        {
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            });
    }

    const getIssues = (projectId) => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_ISSUE_GET_ALL,
            {
                ...HttpUtil.adminHttpHeaders(),
                queryStringParameters: {
                    project_id: projectId
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success === true) {
                    setIssues(_.cloneDeep(res.data.projectIssueData.Project_Issues));
                    setOpenIssues(ProjectUtil.getNumberOfOpenIssues(res.data.projectIssueData.Project_Issues));
                }
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        error.response || "Failed to fetch project stakeholders",
                        {
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            });
    }

    const getDependencies = (projectId) => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_DEPENDENCY_GET_ALL,
            {
                ...HttpUtil.adminHttpHeaders(),
                queryStringParameters: {
                    project_id: projectId
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success === true) {
                    setDependencies(res.data.projectDependencyData.Project_Dependencies);
                }
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        error.response || "Failed to fetch project stakeholders",
                        {
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            });
    }

    const getRehearsals = (projectId) => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.REHEARSAL_GET_ALL,
            {
                ...HttpUtil.adminHttpHeaders(),
                queryStringParameters: {
                    project_id: projectId
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success === true) {
                    const rehearsalData = res.data.rehearsals.map(rehearsal => {
                        return {
                            ...rehearsal,
                            rehearsal_type: RehearsalUtil.formatRehearsalType(rehearsal.Rehearsal_Type),
                            rehearsal_status: RehearsalUtil.formatRehearsalStatus(rehearsal.Rehearsal_Status),
                            start_time: rehearsal.start_time ?  format(DateTimeUtils.convertTimezone(
                                new Date(rehearsal.start_time), user), 
                                DateTimeUtils.getUserDateTimeFormat(
                                    user.date_format,
                                    user.twenty_four_hour_clock
                                )
                            ) : null,
                            end_time: rehearsal.end_time ?  format(DateTimeUtils.convertTimezone(
                                new Date(rehearsal.end_time), user), 
                                DateTimeUtils.getUserDateTimeFormat(
                                    user.date_format,
                                    user.twenty_four_hour_clock
                                )
                            ) : null,
                            active: rehearsal.active ? ENUMS.REHEARSAL_STATE.active : (rehearsal.end_time ? ENUMS.REHEARSAL_STATE.completed : ENUMS.REHEARSAL_STATE.inactive)
                        }
                    })
                    setRehearsalsList(rehearsalData);
                }
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        error.response || "Failed to fetch project stakeholders",
                        {
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            });
    }

    const getProjectDetails = () => {
        if (location.state && location.state.passedId) {
            fetchProjectDetails(location.state.passedId);
            getRaidDetails(location.state.passedId);
            getRehearsals(location.state.passedId);
        }
        else if (location.search) {
            const params = new URLSearchParams(location.search);
            const projectIdSearch = params.get('projectId');
            fetchProjectDetails(projectIdSearch);
            getRaidDetails(projectIdSearch);
            getRehearsals(projectIdSearch);
        }
    }

    const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
        getData(tab);
	};

    const getData = (tab) => {
        if(tab === ENUMS.TABS.STAKEHOLDERS_LIST) {
            getStakeholders();
        }
    };

    const getStakeholders = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.STAKEHOLDER_GET_ALL,
            {
                ...HttpUtil.adminHttpHeaders(),
                queryStringParameters: {
                    project_id: projectDetails.id
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success === true) {
                    setProjectStakeholderList(res.data.projectStakeholder.Project_Stakeholders);
                }
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        error.response || "Failed to fetch project stakeholders",
                        {
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            });
    }

    const onClickEditButtonHandler = () => {
        setEditProjectData(projectDetails);
        setEditModalOpen(true);
    }

    const closeAddEditModal = (e) => {
        setEditModalOpen(false);
    };

    const refreshPageOnSubmit = () => {
        getProjectDetails();
        setEditModalOpen(false);
    }

    const refreshViewOnAction = (tab, message) => {
        addToast(message, { 
            appearance: 'success',
            autoDismiss: true
        });
        getProjectDetails();
        toggle(tab);
    }

    /**
     * Open Project settings view
     */
    const openProjectSettings = () => {
        history.push({
            pathname: '/admin/projects/project-settings',
            search: '?projectId='+projectDetails.id,
            state: { passedId: projectDetails.id }
        });
    }

    /**
     * Toggle report dropdown
     */
    const toggleReportDropdown = () => setReportDropdownOpen(!reportDropdownOpen);

    /**
     * Open report view
     */
    const openReportView = (type) => {
        const reportPath = ENUMS.ROUTE_SYSTEM[type];
        history.push({
            pathname: reportPath,
            search: '?projectId='+projectDetails.id,
            state: { passedId: projectDetails.id }
        });
    }

    /**
     * Open upload modal
     */
    const openUploadFileModal = () => {
        setUploadModalOpen(true);
    }

    /**
     * Close upload modal
     */
    const closeUploadModal = () => {
        setUploadModalOpen(false);
    }

    const closeExecutionModesModal = () => {
        setOpenExecutionModes(false);
    }
    
    useEffect (() => {
        getProjectDetails();
    }, []);

    return (
        <>
            {
                isProjectDetailLoading
                ? <Loader></Loader>
                :  <>
                { projectDetails && 
                    <CheckPermissions
                        role={[RULES.ROLE_NAMES.PROJECT_MEMBER]}
                        perform={RULES.RULE_NAMES.PROJECT_VIEW}
                        data={[{projectId: projectDetails.id, projectList: projectList}]}
                        yes={() => {
                        return <React.Fragment>
                            <ProjectViewHeader 
                                openTasks={openTasks}
                                targetDate={targetDate}
                                openAction={openAction}
                                projectDetails={projectDetails}
                                openRisks={openRisks}
                                openIssues={openIssues}
                                refreshViewOnAction={refreshViewOnAction}
                                activeRehearsal={activeRehearsal}
                            />
                            <Container className="mt--7" fluid>
                                <Row>
                                    <Col className="order-xl-1" xl="12">
                                    <Card className="card-transparent">
                                        <CardHeader className="card-header-transparent border-0 pb-0">
                                            <Row className="align-items-center">
                                                <Col xs="12" className="d-flex justify-content-between align-items-center">
                                                    <Nav tabs>
                                                        <NavItem className="settings-tabs">
                                                            <NavLink
                                                                className={classnames({ active: activeTab === ENUMS.TABS.TASK_LIST })}
                                                                onClick={() => toggle(ENUMS.TABS.TASK_LIST)}
                                                            >
                                                                Task
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem className="settings-tabs">
                                                            <NavLink
                                                                className={classnames({ active: activeTab === ENUMS.TABS.TEAMS_LIST })}
                                                                onClick={() => toggle(ENUMS.TABS.TEAMS_LIST)}
                                                            >
                                                                Teams
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem className="settings-tabs">
                                                            <NavLink
                                                                className={classnames({ active: activeTab === ENUMS.TABS.FILES_LIST })}
                                                                onClick={() => toggle(ENUMS.TABS.FILES_LIST)}
                                                            >
                                                                Files
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem className="settings-tabs">
                                                            <NavLink
                                                                className={classnames({ active: activeTab === ENUMS.TABS.ACTIONS_LIST })}
                                                                onClick={() => toggle(ENUMS.TABS.ACTIONS_LIST)}
                                                            >
                                                                Actions
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem className="settings-tabs">
                                                            <NavLink
                                                                className={classnames({ active: activeTab === ENUMS.TABS.STAKEHOLDERS_LIST })}
                                                                onClick={() => toggle(ENUMS.TABS.STAKEHOLDERS_LIST)}
                                                            >
                                                                Stakeholders
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem className="settings-tabs">
                                                            <NavLink
                                                                className={classnames({ active: activeTab === ENUMS.TABS.RAID_VIEW })}
                                                                onClick={() => toggle(ENUMS.TABS.RAID_VIEW)}
                                                            >
                                                                RAID
                                                            </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                    <Col className="text-right d-flex align-items-center justify-content-end">
                                                        <i className="fas fa-project-diagram mr-3 fs-19 cursor-pointer" id="execution-icon" onClick={(e) => setOpenExecutionModes(true)}></i>
                                                        <UncontrolledTooltip delay={0} placement="top" target="execution-icon">Execution mode</UncontrolledTooltip>
                                                        <Dropdown isOpen={reportDropdownOpen} toggle={toggleReportDropdown} className="black-btn-dropdown">
                                                            <DropdownToggle tag="span">
                                                                <i className="fas fa-chart-line mr-3 fs-20 cursor-pointer mt-1" id="project-report"></i>
                                                                <UncontrolledTooltip delay={0} placement="top" target="project-report">Project reports</UncontrolledTooltip>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem onClick={(e) => openReportView(ENUMS.REPORT_TYPE.GANTT)}>Gantt</DropdownItem>
                                                                <DropdownItem onClick={(e) => openReportView(ENUMS.REPORT_TYPE.CALENDAR)}>Calendar View</DropdownItem>
                                                                {/* <DropdownItem onClick={(e) => openReportView(ENUMS.REPORT_TYPE.MILESTONE)}>Milestone</DropdownItem> */}
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                        <CheckPermissions
                                                            role={[RULES.ROLE_NAMES.PROJECT_OWNER, RULES.ROLE_NAMES.PROJECT_ADMIN]}
                                                            perform={RULES.RULE_NAMES.PROJECT_EDIT}
                                                            data={[{userId: user.id, 
                                                                roleId: projectDetails.project_owner_id}, {userId: user.id, 
                                                                    adminIds: projectDetails.ProjectAdmins}]}
                                                            yes={() => {    
                                                                return <>
                                                                    <i className="fas fa-edit mr-3 fs-20 cursor-pointer" id="edit-icon" onClick={onClickEditButtonHandler}/>
                                                                    <UncontrolledTooltip delay={0} placement="top" target="edit-icon">Edit Project</UncontrolledTooltip>
                                                                </>
                                                            }}
                                                            no={() => {
                                                                return <>
                                                                    <i className="fas fa-edit mr-3 fs-20 cursor-pointer" id="dis-edit-icon" disabled={true} />
                                                                    <UncontrolledTooltip delay={0} placement="top" target="dis-edit-icon">You don't have permission to edit the project.</UncontrolledTooltip>                                                       
                                                                </>
                                                            }}
                                                        />
                                                        <i className="fas fa-cog cursor-pointer fs-20" id="project-settings-icon" onClick={(e) => openProjectSettings()} />
                                                        <UncontrolledTooltip delay={0} placement="top" target="project-settings-icon">Project Settings</UncontrolledTooltip>                                                       
                                                    </Col>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody className="card-body-pt-0">
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId={ENUMS.TABS.TASK_LIST}>
                                                    {
                                                        projectDetails.id && 
                                                        <ProjectViewTaskTable
                                                            projectDetails={projectDetails}
                                                            projectTaskList={projectTaskList}
                                                            refreshViewOnAction={refreshViewOnAction}
                                                            activeRehearsal={activeRehearsal}
                                                            rehearsalsList={rehearsalsList}
                                                        />
                                                    }
                                                </TabPane>
                                                <TabPane tabId={ENUMS.TABS.TEAMS_LIST}>
                                                    {
                                                        projectDetails.id && 
                                                        <ProjectViewTeamsTable
                                                            projectDetails={projectDetails}
                                                            projectTeamsList={projectTeamsList}
                                                            refreshViewOnAction={refreshViewOnAction}
                                                        />
                                                    }
                                                </TabPane>
                                                <TabPane tabId={ENUMS.TABS.FILES_LIST}>
                                                    {
                                                        projectDetails.id && 
                                                        <Row>	
                                                            <Col className="order-xl-1" xl="12">	
                                                                <Card className="bg-secondary box-shadow-down card-border-radius-0">	
                                                                    <CardHeader className="bg-white border-0">	
                                                                        <Row className="align-items-center">	
                                                                            <Col xs="6">	
                                                                                <h3 className="mb-0">File List</h3>	
                                                                            </Col>	
                                                                            <Col className="text-right">	
                                                                                <Button 	
                                                                                    size="md"	
                                                                                    color="primary"	
                                                                                    onClick={(e) => openUploadFileModal()}	
                                                                                >	
                                                                                    Upload File	
                                                                                </Button>	
                                                                            </Col>	
                                                                        </Row>	
                                                                    </CardHeader>	
                                                                    <CardBody>
                                                                        <FileListTable
                                                                            projectDetails={projectDetails}
                                                                            allFileList={projectFileList}
                                                                            refreshViewOnAction={refreshViewOnAction}
                                                                            loadFrom='project'
                                                                            viewMode="project"
                                                                        />
                                                                        <FileListTable
                                                                            projectDetails={projectDetails}
                                                                            allFileList={taskFileList}
                                                                            refreshViewOnAction={refreshViewOnAction}
                                                                            loadFrom='task'
                                                                            viewMode="project"
                                                                        />
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </TabPane>
                                                <TabPane tabId={ENUMS.TABS.ACTIONS_LIST}>
                                                    {
                                                        projectDetails.id && 
                                                        <ProjectViewActionTable
                                                            projectDetails={projectDetails}
                                                            projectActionList={projectActionList}
                                                            refreshViewOnAction={refreshViewOnAction}
                                                        />
                                                    }
                                                </TabPane>
                                                <TabPane tabId={ENUMS.TABS.STAKEHOLDERS_LIST}>
                                                    {
                                                        projectDetails.id && 
                                                        <ProjectViewStakeholderTable
                                                            projectDetails={projectDetails}
                                                            projectStakeholderList={projectStakeholderList}
                                                            refreshViewOnAction={refreshViewOnAction}
                                                        />
                                                    }
                                                </TabPane>
                                                <TabPane tabId={ENUMS.TABS.RAID_VIEW}>
                                                    {
                                                        projectDetails.id && 
                                                        <ProjectRaidView
                                                            projectDetails={projectDetails}
                                                            risks={risks}
                                                            assumptions={assumptions}
                                                            issues={issues}
                                                            dependencies={dependencies}
                                                            refreshViewOnAction={refreshViewOnAction}
                                                        />
                                                    }
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                    </Col>
                                </Row>
                            </Container>
                            { 
                                editModalOpen && 
                                <ProjectAddEditModal 
                                    modalTitle={ENUMS.MODAL.EDIT_PROJECT_TITLE}
                                    modalButtonName={ENUMS.BUTTON_TEXT.SAVE}
                                    modalMode={ENUMS.MODAL.EDIT_MODE}
                                    modalOpen={editModalOpen} 
                                    editProjectData={editProjectData}
                                    closeAddEditModal={closeAddEditModal}
                                    refreshPageOnSubmit={refreshPageOnSubmit}
                                /> 
                            }
                            { 
                                uploadModalOpen && 
                                <UploadModal
                                    modalTitle={ENUMS.MODAL.UPLOAD_FILE_MODAL_TITLE}
                                    modalButtonName={ENUMS.BUTTON_TEXT.UPLOAD}
                                    showModal={uploadModalOpen}
                                    closeUploadModal={closeUploadModal}
                                    uploadTo="project"
                                    projectId={projectDetails.id}
                                    refreshViewOnAction={refreshViewOnAction}
                                    projectTaskList={projectDetails.Tasks}
                                    projectActionList={projectDetails.Actions}
                                /> 
                            }
                            {
                                openExecutionModes && 
                                <ExecutionModes
                                    modalTitle={ENUMS.MODAL.EXECUTION_MODES_MODAL_TITLE}
                                    showModal={openExecutionModes}
                                    closeExecutionModesModal={closeExecutionModesModal}
                                    projectId={projectDetails.id}
                                    refreshViewOnAction={refreshViewOnAction}
                                    rehearsalsList={rehearsalsList}
                                /> 
                            }
                        </React.Fragment>
                        }}
                        no={() => {
                            return <Redirect to="/"/>
                        }}
                    />
                }
                </>
            }

        </>
    );
}