import React from "react";

import { Container } from "reactstrap";
import "./ProjectViewHeader.css";

export default function ProjectSettingsHeader (props) {
    return (
        <>
            <div className="header bg-info pb-8 pt-5 pt-md-8">
                <Container fluid>
                    <div className="header-body">
                    </div>
                </Container>
            </div>
        </>
    );
}