/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import "./ProjectViewHeader.css";
import { useHistory } from "react-router-dom";
import UserUtil from "util/UserUtil";
import TaskUtil from "util/TaskUtil";
import ProjectUtil from "util/ProjectUtil";
import ENUMS from "constants/appEnums";
import DateTimeUtils from "util/DateTimeUtils";
import { format } from "date-fns";

export default function TaskViewHeader (props) {

    const history = useHistory();
    const [taskTimeDuration, setTaskTimeDuration] = useState(0);

    const formatDateTime = (dateTime)  => {
        return format(
            DateTimeUtils.convertTimezone(
                new Date(dateTime), props.currentUser
            ), 
            DateTimeUtils.getUserDateTimeFormat(
                props.currentUser.date_format,
                props.currentUser.twenty_four_hour_clock
            )
        )
    }

    useEffect(() => {
        if(props.taskDetails) {
            const timeDifference = ProjectUtil.findTimeDifference(props.taskDetails.Project.Project_Setting.AssignedCalendar);
            const timeDuration = ProjectUtil.convertTaskDurationForUI(
                props.taskDetails.task_duration,
                props.taskDetails.Project.Project_Setting.default_duration_format,
                timeDifference
            );
            setTaskTimeDuration(timeDuration);
        }
    }, [props.taskDetails]);

    return (
        <>
            <div className="header bg-info pb-8 pt-5 pt-md-8">
                <Container fluid>
                    <div className="header-body">
                        {/* <Row>
                            {
                                props.cardItems.map((item, key) => {
                                    return (
                                    <Col key={key} lg="6" xl="3">
                                        <Card className="card-stats mb-4 mb-xl-0">
                                            <CardBody>
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle
                                                            tag="h5"
                                                            className="text-uppercase text-muted mb-0"
                                                        >
                                                            {item[0] ? item[0] : ""}
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0">
                                                            {item[1] ? item[1] : ""}
                                                        </span>
                                                    </div>
                                                    <Col className="col-auto">
                                                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                            <i className="fas fa-chart-bar" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    )
                                })
                            }
                        </Row> */}
                        <Row>
                            <Col>
                                <h2 className="text-white">
                                    Task Number:
                                </h2>
                                <p className="text-white">
                                    {props.taskDetails.task_number}
                                </p>
                            </Col>
                            <Col>
                                <h2 className="text-white">
                                    Task Name: 
                                </h2>
                                <p className="text-white">
                                    {props.taskDetails.task_name}
                                </p>
                            </Col>
                            <Col>
                                <h2 className="text-white">
                                    Task Owner: 
                                </h2>
                                <p className="text-white">
                                {props.taskDetails.TaskOwner && (UserUtil.formatUserForTableColumn(props.taskDetails.TaskOwner))}
                                </p>
                            </Col>
                            <Col>
                                <h2 className="text-white">
                                    Task Group:
                                </h2>
                                <p className="text-white">
                                    {props.taskDetails.Task_Group && props.taskDetails.Task_Group.group_name}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="3" md="3">
                                <h3 className="text-white">
                                    Task Duration: 
                                </h3>
                                <p className="text-white"  id="start_end_tooltip">
                                    <span className="mr-2">
                                        {taskTimeDuration} 
                                    </span>
                                    {props.taskDetails.Project.Project_Setting.default_duration_format === ENUMS.DURATION_FORMAT_VALUES.HOUR ? 'Hrs' : 'Days'}
                                    <i className="pl-2 fas fa-info-circle"></i>
                                </p>
                                <UncontrolledTooltip
                                    delay={0}
                                    placement="top"
                                    target="start_end_tooltip"
                                >
                                    <div>Start Time: {formatDateTime(props.taskDetails.task_start_time)}</div>
                                    <div>End Time: {formatDateTime(props.taskDetails.task_end_time)}</div>
                                </UncontrolledTooltip>
                            </Col>
                            <Col lg="3" md="3">
                                <h3 className="text-white">
                                    Task Status: 
                                </h3>
                                <p className="text-white" id="task_status_tooltip">
                                    <span className="mr-2">
                                        {ENUMS.TASK_STATUS_VIEW[props.taskDetails.task_status]}
                                    </span>
                                </p>
                            </Col>
                            <Col lg="3" md="3">
                                <h3 className="text-white">
                                    Percentage Complete: 
                                </h3>
                                <p className="text-white" id="task_pc_tooltip">
                                    <span className="mr-2">
                                        {ENUMS.PERCENTAGE_COMPLETE_VIEW[props.taskDetails.percentage_complete]}
                                    </span>
                                </p>
                            </Col>
                            <Col lg="3" md="3">
                                <h2 className="text-white">
                                    Predecessor Task:
                                </h2>
                                <div className="text-white">
                                    {
                                        props.taskDetails.PredecessorTask && props.taskDetails.Project && 
                                        props.taskDetails.PredecessorTask.length > 0 
                                        ? <Row>
                                            {
                                                TaskUtil.formatPredecessorTaskForDisplay(
                                                    props.taskDetails.PredecessorTask, props.taskDetails.Project.Project_Setting)
                                                .map((predTask, key) => 
                                                    <Col lg="4" md="4" key={key}>
                                                        {predTask}
                                                    </Col>
                                                )
                                            }
                                            
                                        </Row>
                                        : 'N/A'
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" md="12">
                                <h3 className="text-white">
                                    Task Description: 
                                </h3>
                                <p className="text-white">
                                    {props.taskDetails.task_description}
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}