import React from "react";
import { Link } from "react-router-dom";

// general example on how to use Util functions
// import this file and then general methods to be used in components.

const validateEmail = (inputEmail) => {
    var mailformat = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return mailformat.test(inputEmail);
}

const sortByCount = (a, b) => {
    if (a.count < b.count){
      return 1;
    }
    if (a.count > b.count){
      return -1;
    }
    return 0;
};

const extractContentFromHTMLString = (html) => {
  return new DOMParser().parseFromString(html, "text/html").documentElement.textContent;
}

/**
 * Method to handle commas in string for CSV conversion
 * @param {*} value 
 */
const handleStringForCSV = (value) => {
  if(value.includes(',')) {
      return '\"' + value + '\"';
  }
  return value;
}

/**
 * Formats the task number into a link for redirection
 * @param {Integer} taskId 
 * @param {String} taskNumber 
 */
 const viewPageLink = (id, content, path, searchParam, passedIds) => {
  return <Link
      to={{
      pathname: path,
      search: searchParam,
      state: passedIds
      }}
      key={id}
  >
      {content}
  </Link>
}

const GeneralUtils = {
    validateEmail,
    sortByCount,
    extractContentFromHTMLString,
    handleStringForCSV,
    viewPageLink
}


export default GeneralUtils;