import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import ENUMS from "constants/appEnums";
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import { useToasts } from "react-toast-notifications";
import './Stripe.css';
import Button from "reactstrap/lib/Button";
import Row from "reactstrap/lib/Row";
import Input from "reactstrap/lib/Input";
import { useSelector } from "react-redux";


export const CheckoutForm = () => {
    const user = useSelector(state => state.authReducer.user);

    const stripe = useStripe();
    const elements = useElements();
    const [email, setEmail] = useState();
    const [subscribeSuccess, setSubscribeSuccess] = useState(false);
    const [isProcessing, setProcessingTo] = useState(false);
    const [checkoutError, setCheckoutError] = useState();
    const [price, setPrice] = useState(15.00);
    const addToast = useToasts();
    const [paymentCompleted, setPaymentCompleted] = useState(false);
    const handleCardDetailsChange = ev => {
      ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
    };

    const handleSubmit = async (event) => {
        setProcessingTo(true);

        event.preventDefault();
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
        });

        if (!error) {
            console.log("Stripe 23 | token generated!", paymentMethod);
            try {
                const { id } = paymentMethod;
                API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.STRIPE_CHARGE,
                {
                    ...HttpUtil.adminHttpHeaders(),
                    body: 
                    {
                        amount: price * 100,
                        id: id,
                    }
                })
                .then(response => {
                    console.log("Stripe 35 | data", response.data.success);
                    if (response.data.success) {
                        console.log("CheckoutForm.js 25 | payment successful!");
                        setProcessingTo(false);
                        setPaymentCompleted(true);
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.error(error.response);
                        addToast(
                            "Failed to fetch teams and groups for dropdown.", 
                            { 
                                appearance: 'error',
                                autoDismiss: true
                            }
                        );
                    }
                });
            } catch (error) {
                console.log("CheckoutForm.js 28 | ", error);
            }
        } else {
            console.log(error.message);
        }
    };

    const handleSubmitSub = async (event) => {
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            email: user.email,
          },
        });
    
        if (result.error) {
          console.log(result.error.message);
        } else {
            const res = await API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.STRIPE_SUB, {
                ...HttpUtil.adminHttpHeaders(),
                body: 
                {
                    payment_method: result.paymentMethod.id,
                    'email': user.email
                }
            })
          
          // eslint-disable-next-line camelcase
          const {client_secret, status} = res.data;
    
          if (status === 'requires_action') {
            stripe.confirmCardPayment(client_secret).then(function(result) {
              if (result.error) {
                console.log('There was an issue!');
                console.log(result.error);
                // Display error message in your UI.
                // The card was declined (i.e. insufficient funds, card has expired, etc)
              } else {
                  setSubscribeSuccess(true);
                console.log('You got the money!');
                // Show a success message to your customer
              }
            });
          } else {
            setSubscribeSuccess(true);
            console.log('You got the money!');
            // No additional information was needed
            // Show a success message to your customer
          }
        }
      };
  return (
    subscribeSuccess ? 
        <div>
          <div>
            <p className="text-center">Your payment for $25 is successful.</p>  
          </div>
          <p className="text-center">Thank you for subscribing to the CutOverPlan Standard plan!</p>
        </div>
        :
        <div className="pr-4 border-right sub-v-stretch border-very-light">
            <div className="text-center"><h1>Subscription</h1></div>
            <br></br>
            <br></br>
            <p className="text-center">Thank you {user ? user.first_name : ''} for choosing an Standard plan with CutOverPlan.</p>
            
            <p className="text-center">Please complete the payment to start using CutOverPlan.</p>
            <br></br>
            <br></br>
            {
                !paymentCompleted 
                ?  <form>
                <div >
                    <CardElement
                        onChange={handleCardDetailsChange}
                        options={{ hidePostalCode: true }}
                    />
                    </div>
                {checkoutError && <div className="text-danger text-small">{checkoutError}</div>}

                <br></br>
                <p className="text-right"><h3>Total amount: $25</h3></p>
                <Row className="justify-content-center">
                  {
                    isProcessing 
                    ? "Processing..." 
                    : <Button  className="btn btn-info mt-4" onClick={handleSubmitSub}>
                      Subscribe
                    </Button>
                  }
                    {/* TIP always disable your submit button while processing payments */}
                    {/* <Button className="btn btn-info mt-4" disabled={isProcessing || !stripe}>
                    {isProcessing ? "Processing..." : `Pay $${price}`}
                    </Button> */}   
                </Row>
                </form>
                :
                <div>Payment Successful</div>
            }
        </div>
  );
};