/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import Fade from 'react-reveal/Fade'; // Importing Zoom effect
import Zoom from 'react-reveal/Zoom'; // Importing Zoom effect

class PageHeader extends React.Component {
  getImgSrc = () => {
    return require("assets/img/brand/cutover-plan-logo.png")
  }
  render() {
    return (
      <div className="page-header header-filter">
        <img
              alt="..."
              className="path"
              src={require("assets/img/blob.png")}
            />
            <img
              alt="..."
              className="path2"
              src={require("assets/img/path2.png")}
            />
            <img
              alt="..."
              className="shapes triangle"
              src={require("assets/img/triunghiuri.png")}
            />
            <img
              alt="..."
              className="shapes wave"
              src={require("assets/img/waves.png")}
            />
            <img
              alt="..."
              className="shapes squareshape"
              src={require("assets/img/patrat.png")}
            />
            <img
              alt="..."
              className="shapes circle"
              src={require("assets/img/cercuri.png")}
            />
        <Container>
          <div className="content-center brand">
            <Fade top duration={3000} delay={500}>
              <h3 className="brand-banner-sub">
                This is
              </h3>
            </Fade>
            <Zoom duration={3000} delay={500}>
              <h1 className="h1-seo">
                  <img
                    alt="logo"
                    className="landing-brand-img"
                    src={this.getImgSrc()}
                  />  
              </h1>
            </Zoom>
          </div>
        </Container>
        
        <div className="scroll-down"><i className="ni ni-bold-down" /></div>
      </div>
    );
  }
}

export default PageHeader;
