import React from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
    Col,
    Row
} from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import Card from "reactstrap/lib/Card";
import { useSelector } from "react-redux";

export default function ViewMoreModal(props) {
    // Current User
    const user = useSelector(state => state.authReducer.user);

	return (
		<Modal
			size="md"
			isOpen={props.modalOpen}
			toggle={() => props.closeModal(false)}
            backdrop="static"
		>
			<ModalHeader toggle={() => props.closeModal(false)} tag="h3">
				{props.modalTitle}
			</ModalHeader>
            <ModalBody className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                    <CardBody>
                        <Col>
                            <Row className="mb-3">
                                <p className="ws-pre">
                                    {props.dataToView}
                                </p>
                            </Row>
                            <hr />
                        </Col>
                    </CardBody>
                </Card>
            </ModalBody>
		</Modal>
	);
}
