const projectHomeTableHeaders = [
    { headerName: "Project #", field: "project_count", sortable:true },
    { headerName: "Project Name", field: "project_name", sortable:true },
    { headerName: "Project Description", field: "project_description", className: "datatable-cell-truncate"},
    { headerName: "Owned By", field: "project_owner", sortable:true },
    { headerName: "Project Type", field: "project_type" }
];

const projectViewTaskTableHeaders = (
    handleSorting, 
    customTaskNumberFilter,
    percentageCompleteFilterElement,
    statusFilter,
    customPredTaskFilter,
    customTaskTeamFilter,
    compareWithRehearsal,
    customDurationFilter
) => {
    return [
        { headerName: "Task #", field: "task_number_link", sortable:true, sortFunction: handleSorting, filterFunction: customTaskNumberFilter, frozen: false  },
        { headerName: "Name", field: "task_name", editor: "text", validator: "empty", sortable: true, sortFunction: handleSorting, frozen: false  },
        { headerName: "Description", field: "task_description", hidden: true, editor: "textarea", className: "datatable-cell-truncate" },
        { headerName: "Status", field: "task_status_badge",  editor: "dropdownStatus", validator: "empty", sortable: true, sortFunction: handleSorting, filter: true, filterElement: statusFilter },
        { headerName: "Percentage Complete", field: "percentage_complete",  editor: "dropdownPercentageComplete", validator: "empty", sortable: true, sortFunction: handleSorting, filter: true, filterElement: percentageCompleteFilterElement },
        { headerName: "Group", field: "group_name", hidden: true, sortable:true, editor: "dropdownGroup", className: "datatable-cell-truncate"},
        { headerName: "Predecessor Task", field: "predecessor_task_name", filterFunction: customPredTaskFilter  },
        { headerName: "Start Time", field: "task_start_time", editor: "datetime", validator: "datetime", sortable: true, sortFunction: handleSorting },
        { headerName: "Duration", field: "task_duration_view", valueField: 'task_duration', validator: "empty", editor: "number", filterFunction: customDurationFilter, sortable: true, sortFunction: handleSorting },
        { headerName: "End Time", field: "task_end_time", editor: "datetime", validator: "datetime", sortable: true, sortFunction: handleSorting },
        { headerName: "Rehearsal Start Time", field: "rehearsal_start_time", sortable: true, sortFunction: handleSorting, hidden: compareWithRehearsal ? false : true },
        { headerName: "Rehearsal Duration", field: "rehearsal_duration_view", sortable: true, sortFunction: handleSorting, hidden: compareWithRehearsal ? false : true },
        { headerName: "Rehearsal End Time", field: "rehearsal_end_time", sortable: true, sortFunction: handleSorting, hidden: compareWithRehearsal ? false : true },
        { headerName: "Task Owner", field: "task_owner_name", editor: "dropdownTaskOwner", validator: "empty", sortable: true, sortFunction: handleSorting, className: "datatable-cell-truncate" },
        { headerName: "Task Team", field: "task_team_name", filterFunction: customTaskTeamFilter, className: "datatable-cell-truncate" },
        { headerName: "Is Critical Path?", field: "is_critical_path", hidden: true, sortable: true, sortFunction: handleSorting },
    ];
}

const projectViewFileTableHeaders = [
    { headerName: "File Name", field: "name", sortable:true},
    { headerName: "Description", field: "description", sortable:true},
    { headerName: "Type", field: "type_of_file", sortable:true},
]

const projectViewActionTableHeaders = [
    { headerName: "Action #", field: "action_number_link", sortable:true},
    { headerName: "Name", field: "description", sortable:true},
    { headerName: "Owner", field: "action_owner_name", sortable:true},
    { headerName: "Status", field: "action_status_view", sortable:true},
    { headerName: "Due Date", field: "due_date", sortable:true},
    { headerName: "Task", field: "task", sortable:true}
]
;
const projectViewStakeholderTableHeaders = [
    { headerName: "First Name", field: "first_name", sortable:true},
    { headerName: "Last Name", field: "last_name", sortable:true},
    { headerName: "Email", field: "email", sortable:true},
    { headerName: "Organization Role", field: "organization_role", sortable:true},
    { headerName: "Team", field: "team_name", sortable:true},
    { headerName: "Note", field: "note_view", sortable:true},
];

const projectViewRiskTableHeaders = [
    {headerName: 'Risk #', field: 'risk_number_link', sortable: true },
    {headerName: 'Date Created', field: 'created_at', sortable: true },
    {headerName: 'Team', field: 'team_name', editor: "dropdownTeam", sortable: true },
    {headerName: 'Description', field: 'description', editor: "text", validator: "empty", sortable: true },
    {headerName: 'Status', field: 'status', editor: "dropdownRaidStatus", validator: "empty", sortable: true },
    {headerName: 'Owner', field: 'owner', editor: "dropdownOwner", validator: "empty", sortable: true },
    {headerName: 'Likelihood', field: 'likelihood', editor: "dropdownLikelihood", validator: "empty", sortable: true },
    {headerName: 'Impact', field: 'impact', editor: "dropdownImpact", validator: "empty", sortable: true },
    {headerName: 'Overall', field: 'overall', sortable: true },
    {headerName: 'Mitigation', field: 'mitigation', editor: "text", validator: "empty", sortable: true },
    {headerName: 'Due Date', field: 'due_date', editor: "datetimeWoDisableCheck", validator: "empty", sortable: true },
    {headerName: 'Mitigation Owner', field: 'mitigation_owner', editor: "dropdownMitigationOwner", validator: "empty", sortable: true },
    {headerName: 'Escalation Req. ?', field: 'escalation_required', editor: "dropdownYesNo", validator: "empty", sortable: true },
    {headerName: 'Notes', field: 'note_view', sortable: true },
];

const projectViewAssumptionTableHeaders = [
    {headerName: 'Assumption #', field: 'assumption_number_link', sortable: true },
    {headerName: 'Date Created', field: 'created_at', sortable: true },
    {headerName: 'Team', field: 'team_name', editor: "dropdownTeam", sortable: true },
    {headerName: 'Description', field: 'description', editor: "text", validator: "empty", sortable: true },
    {headerName: 'Status', field: 'status', editor: "dropdownRaidStatus", validator: "empty", sortable: true },
    {headerName: 'Owner', field: 'owner', editor: "dropdownOwner", validator: "empty", sortable: true },
    {headerName: 'Notes', field: 'note_view', sortable: true },
];

const projectViewIssueTableHeaders = [
    {headerName: 'Issue #', field: 'issue_number_link', sortable: true },
    {headerName: 'Date Created', field: 'created_at', sortable: true },
    {headerName: 'Team', field: 'team_name', editor: "dropdownTeam", sortable: true },
    {headerName: 'Description', field: 'description', editor: "text", validator: "empty", sortable: true },
    {headerName: 'Status', field: 'status', editor: "dropdownRaidStatus", validator: "empty", sortable: true },
    {headerName: 'Owner', field: 'owner', editor: "dropdownOwner", validator: "empty", sortable: true },
    {headerName: 'Severity', field: 'severity', editor: "dropdownSeverity", validator: "empty", sortable: true },
    {headerName: 'Mitigation', field: 'mitigation', editor: "text", validator: "empty", sortable: true },
    {headerName: 'Due Date', field: 'due_date', editor: "datetimeWoDisableCheck", validator: "empty", sortable: true },
    {headerName: 'Mitigation Owner', field: 'mitigation_owner', editor: "dropdownMitigationOwner", validator: "empty", sortable: true },
    {headerName: 'Escalation Req. ?', field: 'escalation_required', editor: "dropdownYesNo", validator: "empty", sortable: true },
    {headerName: 'Notes', field: 'note_view', sortable: true },
];

const projectViewDependencyTableHeaders = [
    {headerName: 'Dependency #', field: 'dependency_number_link', sortable: true },
    {headerName: 'Date Created', field: 'created_at', sortable: true },
    {headerName: 'Team', field: 'team_name', editor: "dropdownTeam", sortable: true },
    {headerName: 'Description', field: 'description', editor: "text", validator: "empty", sortable: true },
    {headerName: 'Status', field: 'status', editor: "dropdownRaidStatus", validator: "empty", sortable: true },
    {headerName: 'Owner', field: 'owner', editor: "dropdownOwner", validator: "empty", sortable: true },
    {headerName: 'Severity', field: 'severity', editor: "dropdownSeverity", validator: "empty", sortable: true },
];

const projectViewTeamTableHeaders = [
    { headerName: "Team #", field: "team_number", sortable:true },
    { headerName: "Team Name", field: "team_name", sortable:true},
    { headerName: "Description", field: "team_description"},
    { headerName: "Team Leader", field: "team_leader_name", sortable:true},
];

const projectSettingCalendarTableHeaders = [
    { headerName: "Calendar #", field: "calendar_number", sortable:true },
    { headerName: "Calendar Name", field: "calendar_name", sortable:true },
    { headerName: "Work Day Type", field: "work_day_type", sortable:true },
    { headerName: "Working Start Time", field: "working_start_time" },
    { headerName: "Working End Time", field: "working_end_time" }
];

const teamHomeTableHeaders = [
    { headerName: "Team #", field: "team_number", sortable:true },
    { headerName: "Team Name", field: "team_name", sortable:true},
    { headerName: "Project", field: "project_name", sortable:true},
    { headerName: "Team Leader", field: "team_leader_name", sortable:true},
];

const calendarHolidayTableHeaders = [
    { headerName: "Type Of Exception", field: "exception_type", sortable:true },
    { headerName: "Holiday / Working Day Name", field: "holiday_name", sortable:true },
    { headerName: "Start Date", field: "start_date" },
    { headerName: "End Date", field: "end_date" },
    { headerName: "Working Start Time", field: "working_start_time" },
    { headerName: "Working End Time", field: "working_end_time" },
];

const userTableHeaders = [
    { headerName: "Id", field: "id" , sortable:true},
    { headerName: "First Name", field: "first_name", sortable:true },
    { headerName: "Last Name", field: "last_name", sortable:true},
]

const taskExceptionHeaders = [
    { headerName: "Type", field: "exception_type" },
    { headerName: "Holiday", field: "holiday_name" },
    { headerName: "Start Date", field: "start_date" },
    { headerName: "End Date", field: "end_date" },
    { headerName: "Start Time", field: "working_start_time" },
    { headerName: "End Time", field: "working_end_time" },
];

const riskRatingMatrix = [
    {headerName: '', field: 'likelihood'},
    {headerName: 'Insignificant', field: 'insignificant', editor: "dropdownRiskRating", validator: "empty"},
    {headerName: 'Minor', field: 'minor', editor: "dropdownRiskRating", validator: "empty"},
    {headerName: 'Moderate', field: 'moderate', editor: "dropdownRiskRating", validator: "empty"},
    {headerName: 'Major', field: 'major', editor: "dropdownRiskRating", validator: "empty"},
    {headerName: 'Severe', field: 'severe', editor: "dropdownRiskRating", validator: "empty"},
];

const executionModesTableHeaders = [
    { headerName: "Name", field: "name" },
    { headerName: "Type", field: "rehearsal_type" },
    { headerName: "Status", field: "active" },
    { headerName: "Start Time", field: "start_time" },
    { headerName: "End Time", field: "end_time" },
]

const TableHeadersUtil = {
    projectHomeTableHeaders,
    projectViewTaskTableHeaders,
    projectViewTeamTableHeaders,
    projectViewFileTableHeaders,
    projectViewActionTableHeaders,
    projectViewStakeholderTableHeaders,
    projectViewRiskTableHeaders,
    projectViewAssumptionTableHeaders,
    projectViewIssueTableHeaders,
    projectViewDependencyTableHeaders,
    projectSettingCalendarTableHeaders,
    teamHomeTableHeaders,
    calendarHolidayTableHeaders,
    userTableHeaders,
    taskExceptionHeaders,
    riskRatingMatrix,
    executionModesTableHeaders
}

export default TableHeadersUtil;