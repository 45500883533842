import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ENUMS from 'constants/appEnums';
import { useToasts } from 'react-toast-notifications';
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import ResetPassword from "components/User/ResetPassword/ResetPassword";

/**
 * Function to implement view for users coming from invites
 * @param {Props} props 
 */
export default function InviteUserConfirm (props) {
	let history = useHistory();
    const { addToast } = useToasts();

	const [tempPassword, setTempPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [userToken, setUserToken] = useState("");
    const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [contactNo, setContactNo] = useState("");
	const [timezone, setTimezone] = useState("");
    const verifyToken = (user_token) => {
        API.get(ENUMS.AWS.API_NAME,ENUMS.API_ROUTES.GET_INVITED_USER,
            {
                ...HttpUtil.authHttpHeaders,
                queryStringParameters: 
                {
                    user_token
                }
            }
        )
        .then((res) => {
            if (res.status === 200 && res.data.success) {
                setEmail(res.data.user.email);
            } else {
                addToast("There was some issue while getting user. Please try again!", { 
                    appearance: 'error',
                    autoDismiss: true
                });
            }
        })
        .catch((error) => {
            addToast(error.data.message, { 
                appearance: 'error',
                autoDismiss: true
            });
        });
    }

    useEffect(() => {
        // fetching the invite user token
        const search = props.location.search;
        const params = new URLSearchParams(search);
        const user_token = params.get('invite-user-token');
        verifyToken(user_token);
        setUserToken(user_token);
	}, []);

	/**
	 * Redirect to Login
	 */
	const redirectToLogin = () => {
		history.push("/auth/login");
	};

	/**
	 * Call to create new user
	 *
	 * @param {object} e
	 */
	const handleInviteConfirm = (data, e) => {
		e.preventDefault();
		API.post(ENUMS.AWS.API_NAME,ENUMS.API_ROUTES.AUTH_CONFIRM_INVITE_USER,
            {
                ...HttpUtil.authHttpHeaders,
                body: 
                {
                    userToken: userToken,
                    tempPassword: tempPassword,
                    newPassword: newPassword,
                    first_name: firstName,
                    last_name: lastName,
                    username: email,
                    email: email,
                    contact_no: contactNo,
                    timezone: timezone,
                }
            }
        )
        .then((res) => {
            if (res.status === 200 && res.data.success) {
                addToast(res.data.message, { 
                    appearance: 'success',
                    autoDismiss: true
                })
                redirectToLogin();
            } else {
                addToast("There was some issue while updating the password. Please try again!", { 
                    appearance: 'error',
                    autoDismiss: true
                });
            }
        })
        .catch((error) => {
            addToast(error.data.message, { 
                appearance: 'error',
                autoDismiss: true
            });
        });
	};

	return (
		<>
            <ResetPassword 
                setOldPassword={setTempPassword}
                setNewPassword={setNewPassword}
                setConfirmNewPassword={setConfirmNewPassword}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setEmail={setEmail}
                setContactNo={setContactNo}
                setTimezone={setTimezone}
                handleReset={handleInviteConfirm}
                oldPasswordPlaceholder="Temporary Password"
                changePasswordForm={false}
                buttonText="Create Account"
                email={email}
            />
			
		</>
	);
}