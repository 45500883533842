import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ReportHeader from "components/Headers/ReportHeader";
import { API } from "aws-amplify";
import ENUMS from "constants/appEnums";
import HttpUtil from "util/HttpUtil";
import { Container, Row, Col, Card, CardHeader, CardBody, ButtonGroup, Button } from "reactstrap";
// import Gantt from 'frappe-gantt';
import Gantt from 'components/FrappeGantt/index';
import { useSelector } from "react-redux";
import DateTimeUtils from "util/DateTimeUtils";
import { firstBy } from "thenby";
import { format } from 'date-fns';
import './GanttReport.css';
import './frappe-gantt.css';
import UserUtil from 'util/UserUtil';
import ProjectUtil from 'util/ProjectUtil';
import toImg from 'react-svg-to-image';

export default function GanttReport(props) {

    /**
     * Location hook
     */
    const location = useLocation();

    /**
     * Project
     */
    const [projectId, setProjectId] = useState(null);
    const [projectDetails, setProjectDetails] = useState({});
    const [frappeGantt, setFrappeGantt] = useState(null);
    const [viewMode, setViewMode] = useState('Quarter Day');

    // grab current state
    const user = useSelector(state => state.authReducer.user);

    /**
     * TOAST hook
     */
    const { addToast } = useToasts();

    const calculateBarClassOnStatus = (status) => {
        if (status === 'P') {
            return 'task-pending';
        }
        else if (status === 'S') {
            return 'task-started';
        }
        else if (status === 'SC') {
            return 'task-success';
        }
        else if (status === 'PA') {
            return 'task-parked';
        }
        else {
            return 'task-finished';
        }
    }

    const onChangeViewMode = (e) => {
        setViewMode(e.target.value);
        frappeGantt.change_view_mode(e.target.value);
    }

    const populateReportData = (projDetails) => {
        let chartData = [];
        projDetails.Tasks.sort(
            firstBy((a,b) => {
                return new Date(a.task_start_time) - new Date(b.task_start_time);
            })
        );
        projDetails.Tasks.forEach(task => {
            let taskDependencies = task.PredecessorTask.map(
                predTask => predTask.predecessor_task_id).join(',');
            let taskLinkTypes = task.PredecessorTask.map(
                predTask => predTask.predecessor_task_link_type).join(',');
            chartData.push({
                id: task.id.toString(),
                number: task.task_number,
                name: task.task_name,
                start: new Date(task.task_start_time),
                end: new Date(task.task_end_time),
                dependencies: taskDependencies !== '' ?
                    taskDependencies : null,
                taskLinkTypes: taskLinkTypes,
                taskOwner: UserUtil.formatUserForTableColumn(task.TaskOwner),
                duration: ProjectUtil.convertTaskDurationForUI(
                    task.task_duration,
                    projDetails.Project_Setting.default_duration_format,
                    ProjectUtil.findTimeDifference(projDetails.Project_Setting.AssignedCalendar)
                ).toString() + ' ' + projDetails.Project_Setting.default_duration_format,
                criticalPath: task.is_critical_path ? "Yes" : "No",
                custom_class: calculateBarClassOnStatus(task.task_status),

            });
        });
        var gantt = new Gantt("#gantt", chartData, {
            header_height: 70,
            column_width: 50,
            step: 30,
            bar_height: 30,
            padding: 20,
            date_format: DateTimeUtils.getUserDateTimeFormat(
                user.date_format, user.twenty_four_hour_clock),
            view_mode: 'Quarter Day',
            custom_popup_html: function(task) {
                // the task object will contain the updated
                // dates and progress value
                // const end_date = task.end.format('MMM D');
                const start_date = format(
                    DateTimeUtils.convertTimezone(new Date(task.start), user), 
                    DateTimeUtils.getUserDateTimeFormat(
                        user.date_format, user.twenty_four_hour_clock));
                const end_date = format(
                    DateTimeUtils.convertTimezone(new Date(task.end), user), 
                    DateTimeUtils.getUserDateTimeFormat(
                        user.date_format, user.twenty_four_hour_clock));
                return `<div class="popover-secondary task-popup">
                    <h5>${task.number} - ${task.name}</h5>
                    <p><small>Task starts on - ${start_date}</small></p>
                    <p><small>Expected to end by - ${end_date}</small></p>
                    <p><small>Task Owner - ${task.taskOwner}</small></p>
                    <p><small>Duration - ${task.duration}</small></p>
                    <p><small>Is Critical Path? - <b>${task.criticalPath}</b></small></p>
                  </div>`;
            }
        });
        setFrappeGantt(gantt);
    }

    const fetchProjectDetails = (projectIdPassed) => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_GET_SINGLE,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                projectId: projectIdPassed
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success === true) {
                setProjectDetails(res.data.projectDetails);
                populateReportData(res.data.projectDetails);
            }
        })
        .catch(error => {
            if (error.response) {
                console.error(error.response);
                addToast(
                    error.response || "Failed to fetch project details",
                    {
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        });
    }

    const printGantt = () => {
        if (frappeGantt !== null)
            toImg('svg', projectDetails.project_name + '-ganttchart-' + Date.now(), 
            {
                scale: 3,
                quality: .99,
            });
    }

    useEffect (() => {
        if (location.state && location.state.passedId) {
            setProjectId(location.state.passedId);
            fetchProjectDetails(location.state.passedId);
        } else if (location.search) {
            const params = new URLSearchParams(location.search);
            const projectIdSearch = params.get('projectId');
            setProjectId(projectIdSearch);
            fetchProjectDetails(projectIdSearch);
        }
    }, []);

	return (
        <>
            <ReportHeader
                projectDetails={projectDetails}
                printFunction={printGantt}
                reportType="gantt"
            />
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="12">
                                        Gantt Chart Report
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                            <ButtonGroup>
                                    <Button 
                                        aria-pressed={viewMode === 'Quarter Day' ? 
                                        true : false}
                                        className={viewMode === 'Quarter Day' ? 
                                        'active' : null}
                                        color="primary"
                                        onClick={e => onChangeViewMode(e)}
                                        value="Quarter Day"
                                    >
                                        Quarter Day
                                    </Button>
                                    <Button
                                        aria-pressed={viewMode === 'Half Day' ? 
                                        true : false}
                                        className={viewMode === 'Half Day' ? 
                                        'active' : null}
                                        color="primary"
                                        onClick={e => onChangeViewMode(e)}
                                        value="Half Day"
                                    >
                                        Half Day
                                    </Button>
                                    <Button
                                        aria-pressed={viewMode === 'Day' ? 
                                        true : false}
                                        className={viewMode === 'Day' ? 
                                        'active' : null}
                                        color="primary"
                                        onClick={e => onChangeViewMode(e)}
                                        value="Day"
                                    >
                                        Day
                                    </Button>
                                    <Button
                                        aria-pressed={viewMode === 'Week' ? 
                                        true : false}
                                        className={viewMode === 'Week' ? 
                                        'active' : null}
                                        color="primary"
                                        onClick={e => onChangeViewMode(e)}
                                        value="Week"
                                    >
                                        Week
                                    </Button>
                                    <Button
                                        aria-pressed={viewMode === 'Month' ? 
                                        true : false}
                                        className={viewMode === 'Month' ? 
                                        'active' : null}
                                        color="primary"
                                        onClick={e => onChangeViewMode(e)}
                                        value="Month"
                                    >
                                        Month
                                    </Button>
                                </ButtonGroup>
                                <svg id="gantt"></svg>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
	);
}
