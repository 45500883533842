/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  CardFooter
} from "reactstrap";
import { Link } from "react-router-dom";
import './LandingPage.css';
import Fade from 'react-reveal/Fade'; // Importing Zoom effect
class PricingSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputFocus: false
    };
  }

  render() {
    return (
      <>
        <section className="section section-lg each-section">
          {/* <img
            alt="..."
            className="path"
            src={require("assets/img/path3.png")}
          /> */}
          <Container>
            <Row className="row-grid justify-content-center">
              <Col md="8" className="text-center">
                  <Fade left duration={1300} delay={400}>
                      <div className="px-md-5">
                          {/* <hr className="line-success" /> */}
                          <h3 className="text-white-0dot8">Pricing</h3>
                          <p className="text-gray">
                          CutOverPlan is being offered to the public on a Beta basis for an initial period of time.  Whilst in Beta, it will be offered free of charge.  During the Beta period, we welcome any and all feedback and appreciate your patience in the event that issues are discovered. To create an account, simply select to register, enter your details and you will be ready to create new projects, tasks and related content as required.
                          </p>
                      </div>
                  </Fade>
              </Col>
            </Row>
          </Container>
          {/* <Container>
            <Row>
              <Col md="4">
                <Fade left duration={1300} delay={400}>
                  <hr className="line-info" />
                </Fade>
                <Fade right duration={1300} delay={400}>
                  <h1 className="no-margin-b text-white">
                    Choose the plan{" "}
                    <span className="text-info">that fits your needs</span>
                  </h1>
                </Fade>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Fade left duration={1300} delay={400}>
                  <Card className="card-coin card-plain">
                    <CardHeader>
                      
                    </CardHeader>
                    <CardBody className="no-padding-bottom">
                      <Row>
                        <Col className="text-center" md="12">
                          <h4 className="text-uppercase no-margin-b text-white-0dot8">Light Coin</h4>
                          <h3 className="text-white no-margin-b">Personal License</h3>
                          <h2 className="text-white price-for">$89</h2>
                        </Col>
                      </Row>
                      <Row>
                        <ListGroup className="landing-prices-list">
                          <ListGroupItem className="text-white-0dot8">50 messages</ListGroupItem>
                          <ListGroupItem className="text-white-0dot8">100 emails</ListGroupItem>
                          <ListGroupItem className="text-white-0dot8">24/7 Support</ListGroupItem>
                        </ListGroup>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button color="info" to="subscribe" tag={Link}>
                        Get Plan
                      </Button>
                    </CardFooter>
                  </Card>
                </Fade>
              </Col>
              <Col md="6">
                <Fade right duration={1300} delay={400}>
                  <Card className="card-coin card-plain">
                    <CardHeader>
                      
                    </CardHeader>
                    <CardBody className="no-padding-bottom">
                      <Row>
                        <Col className="text-center" md="12">
                          <h4 className="text-uppercase no-margin-b text-white-0dot8">Bright Coin</h4>
                          <h3 className="text-white no-margin-b">Developer License</h3>
                          <h2 className="text-white price-for">$89</h2>
                        </Col>
                      </Row>
                      <Row>
                        <ListGroup className="landing-prices-list landing-prices-list-left">
                          <ListGroupItem className="text-white-0dot8">350 messages</ListGroupItem>
                          <ListGroupItem className="text-white-0dot8">10K emails</ListGroupItem>
                          <ListGroupItem className="text-white-0dot8">24/7 Support</ListGroupItem>
                        </ListGroup>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button color="info" to="register-page" tag={Link}>
                        Get plan
                      </Button>
                    </CardFooter>
                  </Card>
                </Fade>
              </Col>
            </Row>
          </Container> */}
        </section>
      </>
    );
  }
}

export default PricingSection;
