/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes/routes.js";
import ENUMS from "constants/appEnums";

class Admin extends React.Component {
  state = {
    sidebarCollapsed: (localStorage.getItem('sidebarCollapsed') == 'true') ? true : false,
    darkTheme: (localStorage.getItem('cutoverplan_theme') === 'dark') ? true : false,
    showSidebar: false
  };
  componentDidUpdate(e) {
    // document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
    // this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    if (!this.props.isAuthenticated) {
      return <Redirect to={{
          pathname: '/auth/login',
          state: { from: this.props.location }
        }}
      />
    }
    return routes.map((prop, key) => {
      if (prop.layout === ENUMS.ROUTES.ADMIN) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  toggleCollapseSidebar = () => {
    localStorage.setItem('sidebarCollapsed', !this.state.sidebarCollapsed);
    this.setState({
      sidebarCollapsed: !this.state.sidebarCollapsed
    });
  }

  getImgSrc = () => {
    return (!this.state.sidebarCollapsed || this.state.showSidebar)
      ? (this.state.darkTheme ? require("assets/img/home/logo_white.svg") : require("assets/img/home/logocop.svg"))
      : (this.state.darkTheme ? require("assets/img/brand/cutover-plan-small.png") : require("assets/img/home/logo_small.svg"));
  }

  changeTheme = () => {
    if (localStorage.getItem('cutoverplan_theme') === 'dark') {
      localStorage.setItem('cutoverplan_theme', 'light');
      document.body.classList.remove("dark-theme");
      document.body.classList.add("light-theme");
      this.setState({
        darkTheme: false
      });
    } else {
      localStorage.setItem('cutoverplan_theme', 'dark');
      document.body.classList.remove("light-theme");
      document.body.classList.add("dark-theme");
      this.setState({
        darkTheme: true
      });
    }
  }

  setShowSidebar(show) {
    this.setState({
      showSidebar: show
    });
  }

  render() {
    return (
      <>
        <div onMouseEnter={() => this.setShowSidebar(true)}
            onMouseLeave={() => this.setShowSidebar(false)}>
          <Sidebar
            {...this.props}
            routes={routes}
            sidebarCollapsed={this.state.sidebarCollapsed}
            logo={{
              innerLink: "/admin/projects-home",
              imgSrc: this.getImgSrc(),
              imgAlt: "..."
            }}
            showSidebar={this.state.showSidebar}
            toggleCollapseSidebar={this.toggleCollapseSidebar}
            changeTheme={this.changeTheme}
          />
        </div>
        <div className={`main-content ${this.state.sidebarCollapsed ? 'minimized-content-area' : 'expanded-content-area'}`} ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/admin/projects-home" />
          </Switch>
          <Container fluid>
            {/* <AdminFooter /> */}
          </Container>
        </div>
      </>
    );
  }
}

export default Admin;
