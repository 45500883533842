import React, { useState, useRef, useEffect } from "react";
import { 
    Modal, 
    ModalHeader,
    ModalBody,
    Card, 
    CardBody, 
    ModalFooter,
    Form, 
    FormGroup,
    Input, 
    Button, 
    Label,
    Spinner
} from "reactstrap";
import { API } from "aws-amplify";
import ENUMS from 'constants/appEnums';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import CustomErrorMessage from 'components/CustomErrorMessage/CustomErrorMessage';
import HttpUtil from "util/HttpUtil";

/**
 * Function to render the Modal for password change from user account
 * @param {Props} props 
 */
export default function UpdatePasswordModal (props) {

    const { addToast } = useToasts();
    const { register, handleSubmit, errors, getValues } = useForm({
        mode: "onBlur"
    });
    const currentPasswordFocusRef = useRef(null);

    // state variables
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    // loading
    const [isLoading, setIsLoading] = useState(false);

    const onButtonClickHandler = async (data, e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const res = await API.put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.USER_UPDATE_PASSWORD, 
            {
                ...HttpUtil.adminHttpHeaders(),
                body: 
                {
                    userId: props.userId,
                    currentPassword: currentPassword,
                    newPassword: newPassword,
                }
            })
            if (res && res.status === 200 && res.data.success) {
                addToast(
                    res.data.message, 
                    { 
                        appearance: 'success',
                        autoDismiss: true
                    }
                );
                setIsLoading(false);
                props.closeModal();

            }
        }
        catch(error) {
            setIsLoading(false);
            if (error.response) {
                addToast(
                    "Failed to update password.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
                props.closeModal();
            }
        }
    }

    useEffect(() => {
        if (currentPasswordFocusRef.current)
            currentPasswordFocusRef.current.focus();
    }, [currentPasswordFocusRef.current]);

    return (
        <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={props.modalOpen}
            toggle={() => props.closeModal()}
            backdrop="static"
        >
            <ModalHeader 
                toggle={() => props.closeModal()}
                className="modal-header"
                tag="div"
            >
                <h3 className="modal-title">
                    {props.modalTitle}
                </h3>
			</ModalHeader>
            <Form role="form" onSubmit={handleSubmit(onButtonClickHandler)} noValidate>
                <ModalBody className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-4 py-lg-4">
                            <FormGroup className="mb-3">
                                <Label for="currentPassword">Current Password<span className="required-asterix">*</span></Label>
                                <Input 
                                    name="currentPassword"
                                    className="form-control-alternative"
                                    type="password"
                                    value={currentPassword}
                                    onChange={(e)=>setCurrentPassword(e.target.value)}
                                    innerRef={(e) => {
                                        currentPasswordFocusRef.current = e;
                                        register(e,
                                        {
                                            required: "Current Password is required."
                                        });
                                    }}
                                />
                            </FormGroup>
                            <CustomErrorMessage errors={errors} name="currentPassword" />
                            <FormGroup className="mb-3">
                                <Label for="newPassword">New Password<span className="required-asterix">*</span></Label>
                                <Input 
                                    name="newPassword"
                                    className="form-control-alternative"
                                    type="password"
                                    value={newPassword}
                                    onChange={(e)=>setNewPassword(e.target.value)}
                                    innerRef={register(
                                        {
                                            required: "New Password is required.",
                                            pattern: {
                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                                message: "Password should contain at least one capital letter, one lower case letter, a number and a special character"
                                            },
                                            minLength: {
                                                value: 8,
                                                message: "Password should be minimum 8 charachters long."
                                            }
                                        })
                                    }
                                />
                            </FormGroup>
                            <p className="mt-2 text-small">Password should be minimum 8 characters long and should contain at least one capital letter, one lower case letter, a number and a special character.</p>
                            <CustomErrorMessage errors={errors} name="newPassword" />
                            <FormGroup className="mb-3">
                                <Label for="confirmPassword">Confirm New Password<span className="required-asterix">*</span></Label>
                                <Input 
                                    name="confirmPassword"
                                    className="form-control-alternative"
                                    type="password"
                                    value={confirmNewPassword}
                                    onChange={(e)=>setConfirmNewPassword(e.target.value)}
                                    innerRef={register(
                                        {
                                            required: "Current Password is required.",
                                            validate: (value) => {
                                                if (value === getValues()["newPassword"]) {
                                                    return true;
                                                } else {
                                                    return "The passwords do not match";
                                                }
                                            }
                                        })
                                    }
                                />
                            </FormGroup>
                            <CustomErrorMessage errors={errors} name="confirmPassword" />
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <Button 
                        color="secondary" 
                        onClick={() => props.closeModal()}
                    >
                        Cancel
                    </Button>
                    {isLoading ?
                        <Spinner size="sm" color="primary" /> :
                        <Button 
                            color="primary" 
                            type="submit"
                        >
                            {props.modalButtonName}
                        </Button>
                    }
                    {" "}
                </ModalFooter>
            </Form>  
        </Modal>
    )
}