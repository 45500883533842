/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// sections for this page/view
import Signup from "views/IndexSections/Signup.js";
import FeaturesSection from "views/IndexSections/FeaturesSection.js";
import PricingSeciton from "views/IndexSections/PricingSection.js";
import PageHeader from "components/PageHeader/PageHeader.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import { FullPage, Slide } from 'react-full-page';
import AwesomeFeature from "./IndexSections/AwesomeFeature";
import NextFeature from "./IndexSections/NextFeature";
import ReactFullpage from '@fullpage/react-fullpage';
import Home from "components/Home/Home";

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          color: "navbar-transparent",
          displayLogo: false,
          currentSlideIndex: 0
        };
    }
    onLeave(origin, destination, direction) {
        if (destination && destination.index > 0) {
            this.setState({
                color: "bg-info",
                displayLogo: true,
                currentSlideIndex: destination.index
            });
        } else {
            this.setState({
                color: "navbar-transparent",
                displayLogo: false,
                currentSlideIndex: destination.index
            });
        }
        console.log(destination.index);
    }
    afterLoad(origin, destination, direction) {
        // console.log("After load: " + destination.index);
    }

    render() {
        return (
            <>
                <IndexNavbar
                    color={this.state.color}
                    displayLogo={this.state.displayLogo}
                    currentSlideIndex={this.state.currentSlideIndex}
                />
                <Home></Home>
                {/* 
                <ReactFullpage
                    anchors={["","features","2","task-list","4","calendar-management","6","pricing","8","contact-us","10"]}
                    onLeave={this.onLeave.bind(this)}
                    afterLoad={this.afterLoad.bind(this)}
                    render={({ state, fullpageApi }) => {
                        return (
                            <div id="fullpage-wrapper">
                                <div className="section">
                                    <PageHeader />
                                </div>
                                <div className="section">
                                    <FeaturesSection />
                                </div>
                                <div className="section">
                                    <NextFeature />
                                </div>
                                <div className="section">
                                    <AwesomeFeature />
                                </div>
                                <div className="section">
                                    <PricingSeciton />
                                </div>
                                <div className="section">
                                    <Signup />
                                </div>
                            </div>
                        );
                    }}
                /> */}
            </>
        );
    }
}

export default Index;
