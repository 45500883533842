const dev = {
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "http://localhost:3000/prod",
  }
}

const prod = {
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://0qn0mj7t5d.execute-api.ap-southeast-2.amazonaws.com/prod",
  }
}

const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev;

export default {
  ...config
};