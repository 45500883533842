const setTheme = () => {
    if(localStorage.getItem('cutoverplan_theme') === 'dark') {
        document.body.classList.add("dark-theme");
    } else {
        document.body.classList.add("light-theme");
        localStorage.setItem('cutoverplan_theme', 'light');
    }
}

const AuthUtil = {
    setTheme
};

export default AuthUtil;