import React, { useState, useEffect, useRef } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	FormGroup,
	Label,
	Input,
	Form,
} from "reactstrap";
import { useForm } from 'react-hook-form';
import InputGroup from "reactstrap/lib/InputGroup";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker";
import CardBody from "reactstrap/lib/CardBody";
import UncontrolledAlert from "reactstrap/lib/UncontrolledAlert";
import Card from "reactstrap/lib/Card";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import ENUMS from "constants/appEnums";
import TaskUtil from "util/TaskUtil";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import { useToasts } from "react-toast-notifications";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import GeneralUtils from "util/GeneralUtils";
import CustomErrorMessage from "components/CustomErrorMessage/CustomErrorMessage";
import UserUtil from "util/UserUtil";
import DataOptions from "constants/appDataOptions";

export default function AddEditActionModal(props) {
    const { handleSubmit, errors } = useForm({
		mode: "onBlur"
    });

    const current_user = useSelector(state => state.authReducer.user);
    
    const { addToast } = useToasts();

    //Editor props
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    // date errors
    const [errorVisible, setErrorVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const onToggleError = () => setErrorVisible(!errorVisible);

    // Form data mambers
    const [description, setDescription] = useState("");
    const [dueDate, setDueDate] = useState();
    const [task, setTask] = useState();
    const [taskList, setTaskList] = useState();
    const [actionOwner, setActionOwner] = useState();
    const [actionStatus, setActionStatus] = useState(ENUMS.TASK_STATUS.P);
    
    // invitation list
    const [inviteActionOwner, setInviteActionOwner] = useState(null);

    // dropdown list
    const [actionOwnerList, setActionOwnerList] = useState([]);
    const [userList, setUserList] = useState([]);

    // togglers
    const [openActionOwnerMenu, setOpenActionOwnerMenu] = useState(false);

    // loading and disabled
    const [isLoading, setIsLoading] = useState(true);

    // animated components
    const animatedComponents = makeAnimated();

    /**
     * Populates the form data for edit mode.
     */
    const populateFormData = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.ACTION_GET_BY_ID,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                actionId: props.editActionId
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                const actionData = res.data.action;
                populateNoteHandler(actionData.note);
                setDescription(actionData.description);
                setActionOwner(UserUtil.formatUserForDropdown(actionData.ActionOwner));
                setDueDate(actionData.due_date);
                setTask(TaskUtil.formatTaskForDropdown(actionData.Task));
                setIsLoading(false);
            }
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch action data for edit. Please try again.", 
                    { 
                        appearance: 'error',
						autoDismiss: true
                    }
                );
            }
        })
    }

    const populateNoteHandler = (note) => {
        const contentBlock = htmlToDraft(note);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        }
    }

    // Method to handle due date change
    const handleDueDateChange = (newDate) => {
        setDueDate(newDate);
    }
    
    // Method to handle task change
    const handleActionTaskChange = (newValue) => {
        setTask(newValue);
    }

    const getNoteEditorContent = () => {
        const noteHTMLStringContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        if (noteHTMLStringContent === "<p></p>") {
            return '';
        }
        return noteHTMLStringContent;
    }

    /**
     * Populates the default action owner based on the passed list of users
     * @param {Array} listOfUsers 
     */
    const populateDefaultActionOwner = (listOfUsers) => {
        // Assigning current user as action owner if mode is ADD
        if (props.modalMode === ENUMS.MODAL.ADD_MODE && listOfUsers.length > 0) {
            const defaultKey = listOfUsers.findIndex(user => 
                user.value === current_user.id
            );
            setActionOwner(listOfUsers[defaultKey]);
        }   
    }

    const fetchTaskList = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TASK_GET_ALL_PROJECT_DATA,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                projectId: props.projectData.id
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setTaskList(TaskUtil.formatTaskForDropdownBulk(res.data.projectData.Tasks));
                if (props.assignToTask) {
                    setTask(TaskUtil.formatTaskForDropdown(res.data.projectData.Tasks.find(t => t.id === props.assignToTask)));
                }
                setIsLoading(false);
            }
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch tasks for dropdown.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        });
    }

    /**
     * Fetches the user list for populating the dropdowns
     */
    const fetchUserList = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.USER_ALL_ORGANIZATION,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                project_id: props.projectData.id
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setActionOwnerList(res.data.userList);
                setUserList(res.data.userList);
                populateDefaultActionOwner(res.data.userList);
            }
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch user list for owner selection", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    // Method to handle form submit
    const handleConfirm = () => {
        const noteContent = getNoteEditorContent();
        const actionData = {
            description: description,
            project_id: props.projectData.id,
            action_owner: actionOwner?.value,
            status: actionStatus,
            note: noteContent,
            due_date: dueDate,
            task_id: task?.value,
            created_by: current_user.id,
        }

        if(!actionData.task_id) {
            addToast(
                "Task is required to add action.", 
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
            return;
        }
        // add mode
        if (props.modalMode === ENUMS.MODAL.ADD_MODE) {
            // console.log(newAction);
            API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.ACTION_ADD,
            {
                ...HttpUtil.adminHttpHeaders(),
                body:
                {
                    projectId: props.projectData.id,
                    newAction: actionData,
                    inviteActionOwner: inviteActionOwner,
                }
            })
            .then(res => {
                if (res.status === 201 && res.data.success) {
                    props.refreshPageOnSubmit(res.data.message);
                }
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        "Failed to create action.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
        }
        // edit mode
        else if (props.modalMode === ENUMS.MODAL.EDIT_MODE) {
            API.put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.ACTION_EDIT,
            {
                ...HttpUtil.adminHttpHeaders(),
                body:
                {
                    projectId: props.projectData.id,
                    updateAction: actionData,
                    actionId: props.editActionId,
                    inviteActionOwner: inviteActionOwner,
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success) {
                    props.refreshPageOnSubmit(res.data.message);
                }
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        "Failed to create action.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
        }
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    }

    /**
     * Sets the new value to the action owner and closes the select dropdown.
     * @param {Object} newValue 
     */
    const handleActionOwnerChange = (newValue) => {
        setActionOwner(newValue);
        hideMenu();
    }

    /**
     * Closes the dropdown menu on input change for action owner select
     * @param {Object} query 
     * @param {Obejct} param1 
     */
    const handleActionOwnerInputChange = (query, { action }) => {
        if (action === "input-change") {
            setOpenActionOwnerMenu(true);
        }
    };

    const handleInviteActionOwner = (inputValue) => {
        // TO-DO: Validate against email format
        if (GeneralUtils.validateEmail(inputValue)) {
            setInviteActionOwner(inputValue);
            const option = createOption(inputValue);
            setActionOwnerList([...actionOwnerList, option]);
            setActionOwner(option);
        }
        else {
            addToast(
                "Invalid format for email.", 
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
        }
        hideMenu();
    }

    /**
     * Closes the dropdown menu for owner and member select
     */
    const hideMenu = () => {
        setOpenActionOwnerMenu(false);
    };

    const createOption = (label) => {
        return {
            label,
            value: label.toLowerCase()
        }
    };

    /** 
     * NOTES DATA MEMEBERS 
     */

     /**
      * On change of the new note editor
      * 
      * @param {object} editorState 
      */
     const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    }

    const formatDropDownLabel = (inputValue) => {
        return "Invite - "+ inputValue;
    }

    useEffect(() => {
        // fetch user list
        fetchUserList();
        fetchTaskList();
    }, []);

    useEffect(() => {
        if (props.modalMode === ENUMS.MODAL.EDIT_MODE) {
            populateFormData();
        }
    }, [props.projectSettings]);

	return (
		<Modal
            backdrop="static"
			size="md"
			isOpen={props.modalOpen}
			toggle={() => props.closeModal(false)}
		>
			<ModalHeader toggle={() => props.closeModal(false)} tag="h3">
				{props.modalTitle}
			</ModalHeader>
            <Form role="form" onSubmit={handleSubmit(handleConfirm)}>
                <ModalBody className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-4 py-lg-4">
                            <FormGroup>
                                <Label for="description">Name</Label>
                                <InputGroup className="input-group-alternative mb-3">
                                    <Input 
                                        id="description"
                                        name="description"
                                        className="form-control-alternative"
                                        type="text"
                                        value={description}
                                        onChange={(e)=> handleDescriptionChange(e)}
                                        autoComplete="off"
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label for="actionOwner">Action Owner<span className="required-asterix">*</span></Label>
                                <CreatableSelect
                                    id="actionOwner"
                                    name="actionOwner"
                                    className="form-control-alternative"
                                    // as={CreatableSelect}
                                    // control={control}
                                    // rules={{ required: "Action Owner is required." }}
                                    isClearable
                                    components={animatedComponents}
                                    isDisabled={isLoading}
                                    isLoading={isLoading}
                                    onChange={(newValue)=>handleActionOwnerChange(newValue)}
                                    onCreateOption={handleInviteActionOwner}
                                    createOptionPosition="first"
                                    formatCreateLabel={formatDropDownLabel}
                                    options={actionOwnerList}
                                    value={actionOwner}
                                    onInputChange={handleActionOwnerInputChange}
                                    onFocus={e => setOpenActionOwnerMenu(true)}
                                    onBlur={hideMenu}
                                    menuIsOpen={openActionOwnerMenu}
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <Label for="status">Action Status<span className="required-asterix">*</span></Label>
                                <Select
                                    id="status"
                                    name="status"
                                    className="form-control-alternative"
                                    options={DataOptions.TASK_ACTION_STATUS_OPTIONS}
                                    value={DataOptions.TASK_ACTION_STATUS_OPTIONS.filter(option => option.value === actionStatus)}
                                    onChange={(e) => setActionStatus(e.value)}
                                />
                                <CustomErrorMessage errors={errors} name="status" />
                            </FormGroup>
                            <CustomErrorMessage errors={errors} name="actionOwner" />
                            <FormGroup>
                                <Label for="due_date">Due Date</Label>
                                <div className="react-datepicker-manager" id="due-date-picker">
                                    <CustomDatePicker
                                        id="due_date"
                                        selected={dueDate}
                                        onChange={date => handleDueDateChange(date)}
                                        showTimeSelect={false}
                                        renderToEl="due-date-picker"
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label for="task_id">Task<span className="required-asterix">*</span></Label>
                                <Select
                                    id="task_id"
                                    className="form-control-alternative"
                                    isClearable
                                    components={animatedComponents}
                                    isDisabled={isLoading}
                                    isLoading={isLoading}
                                    onChange={(newValue)=>handleActionTaskChange(newValue)}
                                    options={taskList}
                                    value={task}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="note">Note</Label>
                                <div className="comment-box-wrapper bg-white note-wrap">
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorStateChange}
                                    />
                                </div>
                            </FormGroup>
                            <UncontrolledAlert
                                color="danger"
                                isOpen={errorVisible} 
                                toggle={onToggleError}
                            >
                                <span className="alert-inner--text">
                                    <strong>Error!</strong> {errorMessage}
                                </span>
                            </UncontrolledAlert>
                        </CardBody>
                    </Card>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={() => props.closeModal(false)}>
						Cancel
					</Button>
					<Button color="primary" type="submit">
						Save
					</Button>{" "}
				</ModalFooter>
			</Form>
		</Modal>
	);
}
