import { API } from "aws-amplify";
import CustomErrorMessage from "components/CustomErrorMessage/CustomErrorMessage";
import DataOptions from "constants/appDataOptions";
import ENUMS from "constants/appEnums";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Select from "react-select";
import  axios  from "../../axiosConfig";
import { useToasts } from "react-toast-notifications";
import { 
    Modal, 
    ModalHeader,
    ModalBody,
    Card, 
    CardBody,
    Button,
    Row,
    Col,
    CardHeader,
} from "reactstrap";
import CustomTablePrime from "components/CustomTable/CustomTablePrime";
import HttpUtil from "util/HttpUtil";
import TableHeadersUtil from "util/TableHeadersUtil";
import AddEditExecutionModeModal from "./AddEditExecutionModeModal";
import RULES from "rbac/rules";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
export default function ExecutionModes(props) {
    const [tableRef, setTableRef] = useState(null);
    const { addToast } = useToasts();
    const [editExecutionModeId, setEditExecutionModeId] = useState(false);
    const [modalMode, setModalMode] = useState(ENUMS.MODAL.ADD_MODE);
    const [showAddEditExecutionModeModal, setShowAddEditExecutionModeModal] = useState(false);
    const [showRehearsalDeleteModal, setShowRehearsalDeleteModal] = useState(false);
    const openAddEditRehearsalModal = () => {
        setShowAddEditExecutionModeModal(true);
    }

    /**
     * Method to handle opening the execution mode modal in edit mode
     */
     const openEditExecutionModeModal = (executionMode) => {
        setModalMode(ENUMS.MODAL.EDIT_MODE);
        setEditExecutionModeId(executionMode.id);
        setShowAddEditExecutionModeModal(true);
    }

    const hideAddEditExecutionModeModal = () => {
        setShowAddEditExecutionModeModal(false);
    }

    const openConfirmationModal = () => {
        setShowRehearsalDeleteModal(true);
    }

    const hideRehearsalDeleteModal = () => {
        setShowRehearsalDeleteModal(false);
    }

    const rehearsalDeleteModalConfirmed = () => {
        setShowRehearsalDeleteModal(false);
    }


    const openViewStakeholderModal = () => {

    }
    const closeModal = () => {
        props.closeExecutionModesModal();
    }

    const startExecutionMode = (data) => {
        API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.REHEARSAL_START,
            {
                ...HttpUtil.adminHttpHeaders(),
                body: 
                {
                    rehearsal_id: data.id,
                    project_id: props.projectId,
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success) {
                    props.refreshViewOnAction(ENUMS.TABS.TASK_LIST, res.data.message);
                }
            })
            .catch(error => {
                // setIsSubmitting(false);
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        error.response.data.message,
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
    }

    const stopExecutionMode = (data) => {
        API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.REHEARSAL_END,
            {
                ...HttpUtil.adminHttpHeaders(),
                body: 
                {
                    rehearsal_id: data.id,
                    project_id: props.projectId,
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success) {
                    props.refreshViewOnAction(ENUMS.TABS.TASK_LIST, res.data.message);
                }
            })
            .catch(error => {
                // setIsSubmitting(false);
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        error.response.data.message,
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
    }

    const optionsList = [
        {
            itemName: 'View',
            itemType: 'Select',
            selectRedirectionLink: '/admin/execution-mode-view',
            selectSearchPath: '?rehearsalId=',
        },
        {
            itemName: 'Start execution',
            action: startExecutionMode,
            // role: [RULES.ROLE_NAMES.PROJECT_OWNER],
            // perform: RULES.RULE_NAMES.PROJECT_EDIT,
        },
        {
            itemName: 'Mark Completed',
            action: stopExecutionMode,
            // role: [RULES.ROLE_NAMES.PROJECT_OWNER],
            // perform: RULES.RULE_NAMES.PROJECT_EDIT,
        },
        {
            itemName: 'Edit',
            itemType: 'Edit',
            openEditModal: openEditExecutionModeModal,
            // role: [RULES.ROLE_NAMES.PROJECT_OWNER],
            // perform: RULES.RULE_NAMES.PROJECT_EDIT,
        },
        {
            itemName: 'Delete',
            itemType: 'Delete',
            deleteProject: openConfirmationModal,
            // role: [RULES.ROLE_NAMES.PROJECT_OWNER],
            // perform: RULES.RULE_NAMES.PROJECT_DELETE,
        }
    ];

    return (
        <>
            <Modal
                backdrop="static"
                size="lg"
                isOpen={props.showModal}
                toggle={() => closeModal()}
                style={{maxWidth: '1600px', width: '80%'}}
            >
                <ModalHeader toggle={() => closeModal()} tag="h2">Execution modes</ModalHeader>
                    <ModalBody className="p-0 ">
                        <Card className="bg-secondary shadow border-0">
                            <CardBody className="px-lg-4 py-lg-4">
                                <Row>
                                    <Col className="mb-5 mb-xl-0" xl="12">
                                        <Card className="shadow">
                                            <CardHeader className="border-0">
                                                <Row className="align-items-center">
                                                    <div className="col">
                                                        
                                                    </div>
                                                    <div className="col text-right">
                                                        <Button className="float-right" color="primary" onClick={(e) => openAddEditRehearsalModal()}>Add Execution Types</Button>
                                                    </div>
                                                </Row>
                                            </CardHeader>
                                            <CustomTablePrime
                                                columnDefs={TableHeadersUtil.executionModesTableHeaders} 
                                                rowData={props.rehearsalsList}
                                                optionsList={optionsList}
                                                tableRef={tableRef}
                                                setTableRef={setTableRef}
                                            />
                                        </Card>
                                    </Col>
                                </Row>          
                            </CardBody>
                        </Card>
                    </ModalBody>
            </Modal>
            {
                showAddEditExecutionModeModal && 
                <AddEditExecutionModeModal
                    modalTitle={modalMode === ENUMS.MODAL.EDIT_MODE ? "Edit execution mode" : "Add execution mode"}
                    closeModal={hideAddEditExecutionModeModal}
                    showModal={showAddEditExecutionModeModal}
                    refreshList={props.refreshViewOnAction}
                    projectId={props.projectId}
                    editExecutionModeId={editExecutionModeId}
                    modalMode={modalMode}
                />
            }
            {
                    showRehearsalDeleteModal && 
                    <ConfirmationModal
                        actionText={{
                            'confirm': ENUMS.BUTTON_TEXT.DELETE_ACTION_TEXT_CONFIRM,
                            'cancel': ENUMS.BUTTON_TEXT.CANCEL
                        }}
                        onCancel={hideRehearsalDeleteModal}
                        onConfirm={rehearsalDeleteModalConfirmed}
                        modalBody="Are you sure you want to delete rehearsal?"
                        showModal={showRehearsalDeleteModal}
                        modalTitle={ENUMS.MODAL.DELETE_NOTE_TITLE}
                    />
                }
        </>
    )
}
