/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import allActions from 'redux/actions/index';
import { useDispatch, useSelector } from "react-redux";
import ENUMS from "constants/appEnums";
import HttpUtil from "util/HttpUtil";
import { useToasts } from 'react-toast-notifications';
import { useAppContext } from "util/ContextUtil";

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";
import UserUtil from "util/UserUtil";

function AdminNavbar ({ props, brandText }) {
    
    let history = useHistory();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { userHasAuthenticated } = useAppContext();
    const [userImgSrc, setUserImgSrc] = useState("");
    const user = useSelector(state => state.authReducer.user);
    const handleLogout = (e) => {
        e.preventDefault();
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.AUTH_LOGOUT,
        {
            ...HttpUtil.adminHttpHeaders()
        })
        .then(res => {
            if (res.status === 200) {
                userHasAuthenticated(false);
                dispatch(allActions.authActions.userLoggedOut());
                localStorage.removeItem('JWT');
                document.body.classList.remove("dark-theme");
                addToast(res.data.message, { 
                    appearance: 'success',
                    autoDismiss: true
                });
                history.push('/auth/login');
            }
            else {
                console.error("User is not logged out successfully");
            }
        })
        .catch(error => {
            console.error(error);
        })
    };

    const getUserImage = () => {
      API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.S3_DOWNLOAD_SIGNED_URL, 
      {
          ...HttpUtil.adminHttpHeaders(),
          queryStringParameters: 
          {
              fileId: user.id,
              getFrom: 'profile-pictures'
          },
      })
      .then(res => {
          if (res.status === 200 && res.data.success) {
              setUserImgSrc(res.data.signedRequest);
          }
      })
      .catch(error => {
          if (error.response) {
              const content = (
                  <div>
                      <strong>Failed to generate presigned url.</strong>
                      <div>
                          {error.response.data && error.response.data.message}
                      </div>
                  </div>
              )
              addToast(
                  content,
                  { 
                      appearance: 'error',
                      autoDismiss: true
                  }
              );
          }
      })
    }

    useEffect (() => {
      if (user.s3filePath) {
          getUserImage();
      }
    }, [user]);

    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <p
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            >
              {brandText}
            </p>
            {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> */}
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    {
                      !userImgSrc
                        ? <div className="avatar-circle">
                            <span className="avatar-initials">
                              {UserUtil.formatUserForInitials(user)}
                            </span>
                          </div>
                        : <img
                          alt="..."
                          src={userImgSrc}
                          className="small-profile-pic"
                        />
                    }
                  
                    {/* <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require("assets/img/theme/user_img.png")}
                      />
                    </span> */}
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold text-capitalize">
                      {user.first_name} {user.last_name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="#pablo" onClick={e => handleLogout(e)}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
}

export default AdminNavbar;
