const formatTeamForDropdown = (team) => {
    if (team.id && team.team_name) {
        return {
            value: team.id,
            label: team.team_name
        };
    }
}

const formatTeamForDropdownBulk = (teamList) => {
    return teamList.map(team => {
        return {...formatTeamForDropdown(team)};
    });
}

const formatCopyTeamsList = (teamList) => {
    return teamList.map(team => {
        return {
            value: team.id,
            label: team.team_name + 
                    ' (' + 
                    team.Project.project_name +
                    ')'
        }
    })
}

const TeamUtil = {
    formatTeamForDropdown,
    formatTeamForDropdownBulk,
    formatCopyTeamsList
};

export default TeamUtil;