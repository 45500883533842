import { API } from "aws-amplify";

import HttpUtil from "util/HttpUtil";
import ENUMS from "constants/appEnums";
import { useSelector } from "react-redux";

import makeAnimated from "react-select/animated";
import React, { useEffect, useState } from "react";

import { useToasts } from "react-toast-notifications";
import { Modal, ModalHeader, ModalBody, Row, Col, ModalFooter, Button, Form, FormGroup, Label, Input, Card, CardBody, Spinner } from "reactstrap";
import CustomTablePrime from "components/CustomTable/CustomTablePrime";
import _ from "lodash";
import TableHeadersUtil from "util/TableHeadersUtil";
import CardHeader from "reactstrap/lib/CardHeader";
import "./RiskRatingMatrixModal.css";
import CardFooter from "reactstrap/lib/CardFooter";

export default function RiskRatingMatrixModal(props) {
    const { addToast } = useToasts();
   
    const user = useSelector(state => state.authReducer.user);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const animatedComponents = makeAnimated();

    const [columns, setColumns] = useState();
    const [riskRatingList, setRiskRatingList] = useState([]);

    /**
     * Table Edit properties
     */
     const [editTableText, setEditTableText] = useState(ENUMS.BUTTON_TEXT.EDIT_TABLE);
     const [tableEditMode, setTableEditMode] = useState(null);
     const [editedRows, setEditedRows] = useState([]);
     const [preEditTableData, setPreEditTableData] = useState([]);

    /**
     * Table properties
     */
    const [tableRef, setTableRef] = useState(null);
    const [loading, setLoading] = useState(true);

    const closeModal = () => {
        props.closeModal();
    }

    const setEditableTable = () => {
        if (editTableText === ENUMS.BUTTON_TEXT.SAVE) {
            setEditTableText(ENUMS.BUTTON_TEXT.EDIT_TABLE);
            setTableEditMode(null);
            if (editedRows.length > 0) {
                updateTableData();
            }
            
        }
        else {
            setPreEditTableData(_.cloneDeep(riskRatingList));
            setEditTableText(ENUMS.BUTTON_TEXT.SAVE);
            setTableEditMode(ENUMS.TABLE_CONSTANTS.EDIT_MODE_CELL);
        }
    }

    const setCancelEditableTable = () => {
        setRiskRatingList(_.cloneDeep(preEditTableData));
        setEditTableText(ENUMS.BUTTON_TEXT.EDIT_TABLE);
        setTableEditMode(null);
    }

    const onEditorValueChange = (tableProps, value) => {
        console.log(tableProps, value);
        let updatedProducts = _.cloneDeep(tableProps.value);
        updatedProducts[tableProps.rowIndex]['og_' + tableProps.field + '_value'] = value;
        updatedProducts[tableProps.rowIndex][tableProps.field] = ENUMS.IMPACT[value];
        let updatedEditedRows = _.cloneDeep(editedRows);
        updatedEditedRows[tableProps.rowIndex] = updatedProducts[tableProps.rowIndex];
        
        setEditedRows(updatedEditedRows);
        setRiskRatingList(updatedProducts);
    }

    const onEditorCancel = ({columnProps}, editingCellRows) => {
        const { rowIndex, field } = columnProps;
        let products = _.cloneDeep(riskRatingList);
        products[rowIndex][field] = editingCellRows[rowIndex][field];
        delete editingCellRows[rowIndex][field];
        setRiskRatingList(products);
    }

    const updateTableData = () => {
        let rowsToEdit = _.cloneDeep(editedRows).filter(row => row !== null && row !== undefined);
        console.log(rowsToEdit);
        const tableEdits = rowsToEdit.map(row => {
            return {
                id: row.id,
                likelihood: row.og_likelihood,
                insignificant: row.og_insignificant_value,
                major: row.og_major_value,
                minor: row.og_minor_value,
                moderate: row.og_moderate_value,
                severe: row.og_severe_value,
                project_id: props.projectId,
            };
        })
        API.put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.RISK_RATING_UPDATE_TABLE_ROWS, 
            {
                ...HttpUtil.adminHttpHeaders(),
                body: 
                {
                    editedRows: tableEdits,
                    projectId: props.projectId
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success) {
                    props.refreshPageOnSubmit("Tasks have been updated successfully!");
                    setEditedRows([]);
                }
            })
            .catch(error => {
                if (error.response) {
                    addToast(
                        "Failed to update tasks!", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
    }

    useEffect(() => {
        setColumns(TableHeadersUtil.riskRatingMatrix);
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.RISK_RATINGS_GET, 
            {
                ...HttpUtil.adminHttpHeaders(),
                queryStringParameters: 
                {
                    project_id: props.projectId,
                },
            })
            .then(res => {
                if (res.status === 200 && res.data.success) {
                    const ratingList = res.data.projectRiskRatingData.Risk_Ratings.map(riskr => {
                        return {
                            ...riskr,
                            og_likelihood: riskr.likelihood,
                            og_insignificant_value: riskr.insignificant,
                            og_major_value: riskr.major,
                            og_minor_value: riskr.minor,
                            og_moderate_value: riskr.moderate,
                            og_severe_value: riskr.severe,
                            likelihood: ENUMS.LIKELIHOOD[riskr.likelihood],
                            insignificant: ENUMS.IMPACT[riskr.insignificant],
                            major: ENUMS.IMPACT[riskr.major],
                            minor: ENUMS.IMPACT[riskr.minor],
                            severe: ENUMS.IMPACT[riskr.severe],
                            moderate: ENUMS.IMPACT[riskr.moderate]
                        }
                    });
                    console.log(ratingList);
                    setRiskRatingList(ratingList);
                    setLoading(false);
                }
            })
            .catch(error => {
                if (error.response) {
                    const content = (
                        <div>
                            <strong>Failed to get risk ratings.</strong>
                            <div>
                                {error.response.data && error.response.data.message}
                            </div>
                        </div>
                    )
                    addToast(
                        content,
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
    }, [])

    return (
        <Modal
            className="modal-dialog-centered custom-modal-style"
            isOpen={props.showModal}
            toggle={() => closeModal()}
            backdrop="static"
        >
            <ModalHeader className="modal-header" toggle={() => closeModal()} tag="h2">{props.modalTitle}</ModalHeader>
                <ModalBody className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-4 py-lg-4" id="attachRiskRatingDropdownsAt">
                            <CustomTablePrime
                                columnDefs={columns} 
                                rowData={riskRatingList}
                                loading={loading}
                                tableRef={tableRef}
                                setTableRef={setTableRef}
                                onEditorValueChange={onEditorValueChange}
                                onEditorCancel={onEditorCancel}
                                editMode={tableEditMode}
                                renderToEl="attachRiskRatingDropdownsAt"
                            />
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    {editTableText === ENUMS.BUTTON_TEXT.SAVE &&
                        <Button
                            color="secondary"
                            onClick={setCancelEditableTable}
                            size="md"
                            disabled={riskRatingList.length === 0}
                        >
                            Cancel Edits
                        </Button>
                    }
                    <Button
                        color="secondary"
                        onClick={setEditableTable}
                        size="md"
                        disabled={riskRatingList.length === 0}
                    >
                        {editTableText}
                    </Button>
                </ModalFooter>
        </Modal>
    )
}
