import { add, set, isAfter, isBefore, sub, isEqual, differenceInHours, parseISO } from "date-fns";
import ENUMS from 'constants/appEnums';
import DataOptions from "constants/appDataOptions";
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import Timezones from "util/TimezonesUtil";
import { format } from "date-fns";

const getDateAtZeroHours = (date) => {
    return new Date(new Date(date).setHours(0, 0, 0, 0));
}

const daysOfWeek = ['sunday','monday','tuesday','wednesday','thursday','friday','saturday'];

const getDayOfWeek = (day) => {
    return daysOfWeek[day];
}

/**
 * Fetches project setting data (work start/end time, working days and holiday list)
 * @param {Project_Setting} projectSettings 
 */
const getProjectSettingData = (projectSettings, taskHolidayList) => {
    const workStartTime = projectSettings.AssignedCalendar.working_start_time.split(":");
    const workEndTime = projectSettings.AssignedCalendar.working_end_time.split(":");
    const workingDays = projectSettings.AssignedCalendar.work_day_type.split(",");
    const holidayList = projectSettings.AssignedCalendar.Calendar_Holidays;
    taskHolidayList.forEach(taskHoliday => {
        holidayList.push(taskHoliday);
    });
    return { holidayList, workingDays, workStartTime, workEndTime };
}

/**
 * Checks if the date(temp date) falls on a calendar specified holiday or not.
 * @param {List} holidayList 
 * @param {Date} tempDate 
 */
const checkIfHolidayOnDate = (holidayList, tempDate) => {
    return holidayList.filter(holiday => {
        if (holiday.exception_type === ENUMS.EXCEPTION_DEFAULT_VALUES.HOLIDAY) {
            const holStartDate = set(new Date(holiday.start_date), {
                hours: 0,
                minutes: 0,
                seconds: 0,
                milliseconds: 0
            });
            const newTempDate = set(tempDate, {
                hours: 0,
                minutes: 0,
                seconds: 0,
                milliseconds: 0
            });
            const holEndDate = set(new Date(holiday.end_date), {
                hours: 0,
                minutes: 0,
                seconds: 0,
                milliseconds: 0
            });
            if (isEqual(holStartDate, newTempDate) ||
                isEqual(holEndDate, newTempDate) || 
                (isBefore(newTempDate, holEndDate) && isAfter(newTempDate, holStartDate)))
                return true;
            return false;
        }
    });
}

/**
 * Calculates the new time left (duration) post subtraction.
 * @param {Date} subDateTillEndTime 
 * @param {Integer} tempDuration 
 */
const calculateSubtractedTime = (subDateTillEndTime, tempDuration) => {
    const timeRemoved = subDateTillEndTime.getHours() * 3600 +
        subDateTillEndTime.getMinutes() * 60 +
        subDateTillEndTime.getSeconds();

    tempDuration -= timeRemoved;
    return tempDuration;
}

/**
 * Checks to see if there is a working day on the previous/next day of the date
 * @param {Array} exceptionList 
 * @param {Date} tempDate 
 */
const checkIfExceptionOnPreviousNextDay = (exceptionList, tempDate, type) => {
    let workTime = null;
    exceptionList.forEach(exception => {
        // Get the working day exception
        if (exception.exception_type === ENUMS.EXCEPTION_DEFAULT_VALUES.WORKING_DAY) {
            // Get exception start day
            const exceptionStartDate = set(new Date(exception.start_date), {
                hours: 0,
                minutes: 0,
                seconds: 0,
                milliseconds: 0
            });
            // Get a temporary date for comparison which would be the previous/next day
            const newTempDate = set(tempDate, {
                date: type === 'F' ? 
                    tempDate.getDate() + 1 : 
                    tempDate.getDate() - 1,
                hours: 0,
                minutes: 0,
                seconds: 0,
                milliseconds: 0
            });
            // Get exception end day
            const exceptionEndDate = set(new Date(exception.end_date), {
                hours: 0,
                minutes: 0,
                seconds: 0,
                milliseconds: 0
            });
            // check to see if next day falls between the exception start and end days
            if (isEqual(exceptionStartDate, newTempDate) ||
                isEqual(exceptionEndDate, newTempDate) || 
                ( isBefore(newTempDate, exceptionEndDate) && 
                isAfter(newTempDate, exceptionStartDate) )) {
                // return the start day work time
                workTime = type === 'F' ? 
                    exception.working_start_time.split(":") : 
                    exception.working_end_time.split(":");
            }
        }
    })
    return workTime;
}

/**
 * Checks to see if there is a working day present on the current day of the iteration
 * @param {Array} exceptionList 
 * @param {Date} tempDate 
 */
const checkIfWorkingDayException = (exceptionList, tempDate, type) => {
    let date = null;
    let newTempDateExp = tempDate;
    exceptionList.forEach(exception => {
        // Get the working day exception
        if (exception.exception_type === ENUMS.EXCEPTION_DEFAULT_VALUES.WORKING_DAY) {
            // gets the exception start datetime
            let exceptionStartDate = set(new Date(exception.start_date), {
                hours: exception.working_start_time.split(':')[0],
                minutes: exception.working_start_time.split(':')[1],
                seconds: exception.working_start_time.split(':')[2],
                milliseconds: 0
            });
            // get exception end datetime
            let exceptionEndDate = set(new Date(exception.end_date), {
                hours: exception.working_end_time.split(':')[0],
                minutes: exception.working_end_time.split(':')[1],
                seconds: exception.working_end_time.split(':')[2],
                milliseconds: 0
            });
            let newTempDate = null;
            // checks to see if temporary datetime has the same date as the exception start date
            // and is before it
            if (type === 'F' && 
            tempDate.getDate() === exceptionStartDate.getDate() && 
            isBefore(tempDate, exceptionStartDate)) {
                // make the temp datetime as the same as exception start datetime
                newTempDate = set(tempDate, {
                    hours: exception.working_start_time.split(':')[0],
                    minutes: exception.working_start_time.split(':')[1],
                    seconds: exception.working_start_time.split(':')[2],
                    milliseconds: 0
                }); 
            }
            // checks to see if temporary datetime has the same date as the exception end date
            // and is after it
            else if (type === 'B' && 
            tempDate.getDate() === exceptionEndDate.getDate() && 
            isAfter(tempDate, exceptionEndDate)) {
                // make the temp datetime as the same as exception end datetime
                newTempDate = set(tempDate, {
                    hours: exception.working_end_time.split(':')[0],
                    minutes: exception.working_end_time.split(':')[1],
                    seconds: exception.working_end_time.split(':')[2],
                    milliseconds: 0
                }); 
            }
            // otherwise keep it as it is
            else {
                newTempDate = set(tempDate, {
                    hours: tempDate.getHours(),
                    minutes: tempDate.getMinutes(),
                    seconds: tempDate.getSeconds(),
                    milliseconds: 0
                });
            }
            // check to see if temp date falls between the exception start and end datetimes
            if (isEqual(exceptionStartDate, newTempDate) ||
                isEqual(exceptionEndDate, newTempDate) || 
                ( isBefore(newTempDate, exceptionEndDate) && 
                isAfter(newTempDate, exceptionStartDate) )) {
                // if multiple day exception, then set end date as the same as temporary date for day calculation
                if (type === 'F' && exceptionEndDate.getDate() !== newTempDate.getDate()) {
                    exceptionEndDate = set(exceptionEndDate, {
                        date: newTempDate.getDate()
                    });
                    if (isEqual(exceptionEndDate, newTempDate) ||
                    isAfter(newTempDate, exceptionEndDate)){
                        newTempDate = set(newTempDate, {
                            date: newTempDate.getDate() + 1,
                            hours: exception.working_start_time.split(':')[0],
                            minutes: exception.working_start_time.split(':')[1],
                            seconds: exception.working_start_time.split(':')[2],
                            milliseconds: 0
                        });
                        exceptionEndDate = set(exceptionEndDate, {
                            date: newTempDate.getDate()
                        });
                    }
                }
                // if multiple day exception, then set start date as the same as temporary date for day calculation
                else if (type === 'B' && exceptionStartDate.getDate() !== newTempDate.getDate()) {
                    exceptionStartDate = set(exceptionStartDate, {
                        date: newTempDate.getDate()
                    });
                    if (isEqual(exceptionStartDate, newTempDate) ||
                    isBefore(newTempDate, exceptionStartDate)){
                        newTempDate = set(newTempDate, {
                            date: newTempDate.getDate() - 1,
                            hours: exception.working_end_time.split(':')[0],
                            minutes: exception.working_end_time.split(':')[1],
                            seconds: exception.working_end_time.split(':')[2],
                            milliseconds: 0
                        });
                        exceptionStartDate = set(exceptionStartDate, {
                            date: newTempDate.getDate()
                        });
                    }
                }
                if (type === 'F') 
                    date = exceptionEndDate;
                else
                    date = exceptionStartDate;
                newTempDateExp = newTempDate;
            }
        }
    })
    if (date === null)
        return null;
    else    
        return [date, newTempDateExp];
}

/**
 * Calculates a future time based on the duration and given date.
 * Checks for project holidays and working days.
 * @param {Project_Setting} projectSettings 
 * @param {Date} newDate 
 * @param {Integer} taskDuration 
 */
const calculateTaskTimeForward = (projectSettings, newDate, taskDuration, taskHolidayList) => {
    const { holidayList, workingDays, workStartTime, workEndTime } = getProjectSettingData(projectSettings, taskHolidayList);
    let tempDate = newDate;
    let calcTaskEndTime = new Date();
    let tempDuration = taskDuration * 3600;
    while(tempDuration > 0) {
        let workEndTimeTemp = null;
        const result = checkIfWorkingDayException(holidayList, tempDate, 'F');
        if (result === null) {
            const holidayCheck = checkIfHolidayOnDate(holidayList, tempDate);
            if (!workingDays.includes(
                DateTimeUtils.getDayOfWeek(tempDate.getDay())) ||
                holidayCheck.length > 0) {
                const exceptionStartTime = checkIfExceptionOnPreviousNextDay(holidayList, tempDate, 'F');
                if (exceptionStartTime !== null) {
                    tempDate = set(tempDate, {
                        date: tempDate.getDate() + 1,
                        hours: exceptionStartTime[0],
                        minutes: exceptionStartTime[1],
                        seconds: exceptionStartTime[2]
                    });
                }
                else {
                    tempDate = set(tempDate, {
                        date: tempDate.getDate() + 1,
                        hours: workStartTime[0],
                        minutes: workStartTime[1],
                        seconds: workStartTime[2]
                    });
                }
                continue;
            }
            workEndTimeTemp = set(tempDate, {
                hours: workEndTime[0],
                minutes: workEndTime[1],
                seconds: workEndTime[2]
            });
        }
        else {
            workEndTimeTemp = result[0];
            tempDate = result[1];
        }
        
        if (isBefore(tempDate, workEndTimeTemp)) {
            let newTaskEndTime = add(tempDate, {
                seconds: tempDuration
            });
            if (isAfter(newTaskEndTime, workEndTimeTemp)) {
                const subDateTillEndTime = sub(workEndTimeTemp, {
                    hours: tempDate.getHours(),
                    minutes: tempDate.getMinutes(),
                    seconds: tempDate.getSeconds(),
                });
                tempDuration = calculateSubtractedTime(subDateTillEndTime, tempDuration);
                const exceptionStartTime = checkIfExceptionOnPreviousNextDay(holidayList, tempDate, 'F');
                if (exceptionStartTime !== null) {
                    tempDate = set(tempDate, {
                        date: tempDate.getDate() + 1,
                        hours: exceptionStartTime[0],
                        minutes: exceptionStartTime[1],
                        seconds: exceptionStartTime[2]
                    });
                }
                else {
                    tempDate = set(tempDate, {
                        date: tempDate.getDate() + 1,
                        hours: workStartTime[0],
                        minutes: workStartTime[1],
                        seconds: workStartTime[2]
                    });
                }
                continue;
            }
            else {
                calcTaskEndTime = newTaskEndTime;
                break;
            }
        }
        else {
            tempDate = set(tempDate, {
                date: tempDate.getDate() + 1,
                hours: workStartTime[0],
                minutes: workStartTime[1],
                seconds: workStartTime[2]
            });
            continue;
        }
    }
    return calcTaskEndTime;
}

/**
 * Calculates a past time based on the duration and given date.
 * Checks for project holidays and working days.
 * @param {Project_Setting} projectSettings 
 * @param {Date} newDate 
 * @param {Integer} taskDuration 
 */
const calculateTaskTimeBackwards = (projectSettings, newDate, taskDuration, taskHolidayList) => {
    const { holidayList, workingDays, workStartTime, workEndTime } = getProjectSettingData(projectSettings, taskHolidayList);
    let tempDate = newDate;
    let calcTaskStartTime = new Date();
    let tempDuration = taskDuration * 3600;
    while(tempDuration > 0) {
        let workStartTimeTemp = null;
        const result = checkIfWorkingDayException(holidayList, tempDate, 'B');
        if (result === null) {
            const holidayCheck = checkIfHolidayOnDate(holidayList, tempDate);
            if (!workingDays.includes(
                DateTimeUtils.getDayOfWeek(tempDate.getDay())) ||
                holidayCheck.length > 0) {
                const exceptionEndTime = checkIfExceptionOnPreviousNextDay(holidayList, tempDate, 'B');
                if (exceptionEndTime !== null) {
                    tempDate = set(tempDate, {
                        date: tempDate.getDate() - 1,
                        hours: exceptionEndTime[0],
                        minutes: exceptionEndTime[1],
                        seconds: exceptionEndTime[2]
                    });
                }
                else {
                    tempDate = set(tempDate, {
                        date: tempDate.getDate() - 1,
                        hours: workEndTime[0],
                        minutes: workEndTime[1],
                        seconds: workEndTime[2]
                    });
                }
                continue;
            }
            workStartTimeTemp = set(tempDate, {
                hours: workStartTime[0],
                minutes: workStartTime[1],
                seconds: workStartTime[2]
            });
        }
        else {
            workStartTimeTemp = result[0];
            tempDate = result[1];
        }
        
        if (isAfter(tempDate, workStartTimeTemp)) {
            let newTaskStartTime = sub(tempDate, {
                seconds: tempDuration
            });
            if (isBefore(newTaskStartTime, workStartTimeTemp)) {
                const subDateTillEndTime = sub(tempDate, {
                    hours: workStartTimeTemp.getHours(),
                    minutes: workStartTimeTemp.getMinutes(),
                    seconds: workStartTimeTemp.getSeconds(),
                });
                tempDuration = calculateSubtractedTime(subDateTillEndTime, tempDuration);
                const exceptionEndTime = checkIfExceptionOnPreviousNextDay(holidayList, tempDate, 'B');
                if (exceptionEndTime !== null) {
                    tempDate = set(tempDate, {
                        date: tempDate.getDate() - 1,
                        hours: exceptionEndTime[0],
                        minutes: exceptionEndTime[1],
                        seconds: exceptionEndTime[2]
                    });
                }
                else {
                    tempDate = set(tempDate, {
                        date: tempDate.getDate() - 1,
                        hours: workEndTime[0],
                        minutes: workEndTime[1],
                        seconds: workEndTime[2]
                    });
                }
                continue;
            }
            else {
                calcTaskStartTime = newTaskStartTime;
                break;
            }
        }
        else {
            tempDate = set(tempDate, {
                date: tempDate.getDate() - 1,
                hours: workEndTime[0],
                minutes: workEndTime[1],
                seconds: workEndTime[2]
            });
            continue;
        }
    }
    return calcTaskStartTime;
}

const recalculateStartTime = (startTime, projectSettings, taskHolidayList) => {
    const { holidayList, workingDays, workStartTime, workEndTime } = getProjectSettingData(projectSettings, taskHolidayList);
    let tempDate = set(startTime, {
        milliseconds: 0
    });
    while(true) {
        let workStartTimeTemp = set(tempDate, {
            hours: workStartTime[0],
            minutes: workStartTime[1],
            seconds: workStartTime[2]
        });
        let workEndTimeTemp = set(tempDate, {
            hours: workEndTime[0],
            minutes: workEndTime[1],
            seconds: workEndTime[2]
        });
        const result = checkIfWorkingDayException(holidayList, tempDate, 'F');
        if (result === null) {
            let holidayCheck = checkIfHolidayOnDate(holidayList, tempDate);
            if (!workingDays.includes(
                DateTimeUtils.getDayOfWeek(tempDate.getDay())) ||
                holidayCheck.length > 0) {
                tempDate = set(tempDate, {
                    date: tempDate.getDate() + 1,
                    hours: workStartTime[0],
                    minutes: workStartTime[1],
                    seconds: workStartTime[2]
                });
                continue;
            }
            if (isAfter(tempDate, workEndTimeTemp) || 
            isEqual(tempDate, workEndTimeTemp)) {
                tempDate = set(tempDate, {
                    date: tempDate.getDate() + 1,
                    hours: workStartTime[0],
                    minutes: workStartTime[1],
                    seconds: workStartTime[2]
                });
            }
            if (isBefore(tempDate, workStartTimeTemp)) {
                tempDate = set(workStartTimeTemp, {});
            }
            holidayCheck = checkIfHolidayOnDate(holidayList, tempDate);
            if (!workingDays.includes(
                DateTimeUtils.getDayOfWeek(tempDate.getDay())) ||
                holidayCheck.length > 0) {
                tempDate = set(tempDate, {
                    date: tempDate.getDate() + 1,
                    hours: workStartTime[0],
                    minutes: workStartTime[1],
                    seconds: workStartTime[2]
                });
                continue;
            }
        }
        else {
            tempDate = set(result[1], {});
        }
        break;
    }
    return tempDate;
}

const recalculateEndTime = (endTime, projectSettings, taskHolidayList) => {
    const { holidayList, workingDays, workStartTime, workEndTime } = getProjectSettingData(projectSettings, taskHolidayList);
    let tempDate = set(endTime, {
        milliseconds: 0
    });
    while(true) {
        let workStartTimeTemp = set(tempDate, {
            hours: workStartTime[0],
            minutes: workStartTime[1],
            seconds: workStartTime[2]
        });
        let workEndTimeTemp = set(tempDate, {
            hours: workEndTime[0],
            minutes: workEndTime[1],
            seconds: workEndTime[2]
        });
        const result = checkIfWorkingDayException(holidayList, tempDate, 'B');
        if (result === null) {
            let holidayCheck = checkIfHolidayOnDate(holidayList, tempDate);
            if (!workingDays.includes(
                DateTimeUtils.getDayOfWeek(tempDate.getDay())) ||
                holidayCheck.length > 0) {
                tempDate = set(tempDate, {
                    date: tempDate.getDate() + 1,
                    hours: workStartTime[0],
                    minutes: workStartTime[1],
                    seconds: workStartTime[2]
                });
                continue;
            }
            if (isAfter(tempDate, workEndTimeTemp)) {
                tempDate = set(tempDate, {
                    date: tempDate.getDate() + 1,
                    hours: workStartTime[0],
                    minutes: workStartTime[1],
                    seconds: workStartTime[2]
                });
            }
            if (isBefore(tempDate, workStartTimeTemp)) {
                tempDate = set(workStartTimeTemp, {});
            }
            holidayCheck = checkIfHolidayOnDate(holidayList, tempDate);
            if (!workingDays.includes(
                DateTimeUtils.getDayOfWeek(tempDate.getDay())) ||
                holidayCheck.length > 0) {
                tempDate = set(tempDate, {
                    date: tempDate.getDate() + 1,
                    hours: workStartTime[0],
                    minutes: workStartTime[1],
                    seconds: workStartTime[2]
                });
                continue;
            }
        }
        else {
            tempDate = set(result[1], {});
        }
        break;
    }
    return tempDate;
}

/**
 * Validates the start and end dates to check if they are between working hours.
 * Validates the start and end dates to check if they don't fall on a calendar holiday.
 * Validates the start and end dates to check if they don't fall on a non-working day.
 * @param {Date} date 
 * @param {Project_Setting} projectSettings 
 */
const validateTaskDates = (date, projectSettings, taskHolidayList) => {
    if (date === null) {
        return "Date cannot be empty";
    }
    const { holidayList, workingDays, workStartTime, workEndTime } = getProjectSettingData(projectSettings, taskHolidayList);
    let workDayErrorMessage = "";
    const workDayCheck = holidayList.filter(exception => {
        // Get the working day exception
        if (exception.exception_type === ENUMS.EXCEPTION_DEFAULT_VALUES.WORKING_DAY) {
            // Get exception start day
            const exceptionStartDate = set(new Date(exception.start_date), {
                hours: exception.working_start_time.split(':')[0],
                minutes: exception.working_start_time.split(':')[1],
                seconds: exception.working_start_time.split(':')[2],
                milliseconds: 0
            });
            // Get a temporary date for comparison which would be the next day
            const newTempDate = set(date, {
                date: date.getDate(),
                hours: date.getHours(),
                minutes: date.getMinutes(),
                seconds: date.getSeconds(),
                milliseconds: 0
            });
            // Get exception end day
            const exceptionEndDate = set(new Date(exception.end_date), {
                hours: exception.working_end_time.split(':')[0],
                minutes: exception.working_end_time.split(':')[1],
                seconds: exception.working_end_time.split(':')[2],
                milliseconds: 0
            });
            // check to see if next day falls between the exception start and end days
            if (isEqual(exceptionStartDate, newTempDate) ||
                isEqual(exceptionEndDate, newTempDate) || 
                ( isBefore(newTempDate, exceptionEndDate) && 
                isAfter(newTempDate, exceptionStartDate) )) {
                return true;
            }
            else if (newTempDate.getDate() === exceptionStartDate.getDate() || 
            newTempDate.getDate() === exceptionEndDate.getDate()) {
                workDayErrorMessage = "Date should be between working day hours.";
            }
        }
    });
    if (workDayCheck.length === 0) {
        let workStartTimeTemp = set(date, {
            hours: workStartTime[0],
            minutes: workStartTime[1],
            seconds: workStartTime[2]
        });
        let workEndTimeTemp = set(date, {
            hours: workEndTime[0],
            minutes: workEndTime[1],
            seconds: workEndTime[2]
        });
        if (isAfter(date, workEndTimeTemp) || isBefore(date, workStartTimeTemp)) {
            return "Date should be between working hours.";
        }
        const holidayCheck = checkIfHolidayOnDate(holidayList, date);
        if (holidayCheck.length > 0) {
            return "Date should not fall on a calendar holiday.";
        }
        if (!workingDays.includes(
            getDayOfWeek(date.getDay())) ||
            holidayCheck.length > 0) {
            return "Date should not fall on a non-working day.";
        }
    }
    else if (workDayErrorMessage.length > 0) {
        return workDayErrorMessage;
    }
    return null;
}

const checkIfHolidayLiesInDate = (holidayStartDate, holidayEndDate, taskStartDate, taskEndDate) => {
    if(
        (isAfter(parseISO(taskStartDate), parseISO(holidayStartDate)) && isBefore(parseISO(taskStartDate), parseISO(holidayEndDate)))
        || (isAfter(parseISO(taskEndDate), parseISO(holidayStartDate)) && isBefore(parseISO(taskEndDate), parseISO(holidayEndDate)))
        || (isAfter(parseISO(holidayStartDate), parseISO(taskStartDate)) && isBefore(parseISO(holidayStartDate), parseISO(taskEndDate)))
        || (isAfter(parseISO(holidayEndDate), parseISO(taskStartDate)) && isBefore(parseISO(holidayEndDate), parseISO(taskEndDate)))
    ) {
        return true
    }
    return false;
}

const getUserDateTimeFormat = (dateFormat, isTwentyFourHourClock=null) => {
    let format = DataOptions.DATE_FORMATS.filter(dateForm => dateForm.value === dateFormat)[0].value;
    if (isTwentyFourHourClock !== null) {
        if (isTwentyFourHourClock) {
            format = format + " HH:mm";
        }
        else {
            format = format + " hh:mm aa";
        }
    }
    return format;
}

/**
 * 
 * @param {string, date} first
 * @param {string, date} second
 * 
 * @return {boolean}
 */
const compareBetweenDates = (first, second) => {
    return new Date(first).getTime() == new Date(second).getTime();
}

const findDateDifferenceInHours = (startTime, endTime) => {
    const timeDifference = new Date(endTime).getTime() - new Date(startTime).getTime();
    return timeDifference / (1000 * 3600);
}

const findTimeDifferenceInHours = (time1, time2) => {
    const time1Date = set(new Date(), {
        hours: time1.split(':')[0],
        minutes: time1.split(':')[1],
        seconds: time1.split(':')[2]
    });
    const time2Date = set(new Date(), {
        hours: time2.split(':')[0],
        minutes: time2.split(':')[1],
        seconds: time2.split(':')[2]
    });
    return differenceInHours(
        time2Date,
        time1Date
    );
}

const convertTimezone = (date, user) => {
    const utcTime = zonedTimeToUtc(date, Intl.DateTimeFormat().resolvedOptions()
        .timeZone);
    const timezoneValue = Timezones.find(
        (t) => t.value.indexOf(user.timezone) > -1
    ).utc[0];
    return utcToZonedTime(utcTime, timezoneValue);
}

const formatDateTime = (dateTime, user)  => {
    return format(
        convertTimezone(
            new Date(dateTime), user
        ), 
        getUserDateTimeFormat(
            user.date_format,
            user.twenty_four_hour_clock
        )
    )
}

const DateTimeUtils = {
    getDateAtZeroHours,
    daysOfWeek,
    getDayOfWeek,
    calculateTaskTimeForward,
    calculateTaskTimeBackwards,
    validateTaskDates,
    checkIfHolidayLiesInDate,
    getUserDateTimeFormat,
    compareBetweenDates,
    findDateDifferenceInHours,
    findTimeDifferenceInHours,
    convertTimezone,
    recalculateStartTime,
    recalculateEndTime,
    formatDateTime
}

export default DateTimeUtils;