import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import { Container, Row, Col, CardHeader, CardBody, Card, Button } from "reactstrap";
import { useSelector } from "react-redux";
import ENUMS from "constants/appEnums";
import HttpUtil from "util/HttpUtil";
import { API } from "aws-amplify";
import TableHeadersUtil from "util/TableHeadersUtil";
import CustomTablePrime from "components/CustomTable/CustomTablePrime";
import UserUtil from "util/UserUtil";
import TeamAddEditViewModal from "../TeamAddEditViewModal/TeamAddEditViewModal";

export default function TeamsView() {

    // grab current state
    const user = useSelector(state => state.authReducer.user);

    // Teams Data and Header for Table
    const [teams, setTeams] = useState([]);
    const [teamListHeader, setTeamListHeader] = useState([]);

    // view team
    const [viewEditTeamId, setViewEditTeamId] = useState();
    const [addEditViewTeamModalOpen, setAddEditViewTeamModalOpen] = useState();

    // Table props
    const [loading, setLoading] = useState(true);
    const [tableRef, setTableRef] = useState(null);

    /**
     * Method to reset the filters and sorting on the entire table. 
     */
     const resetTable = () => {
        tableRef.reset();
    }

    /**
	 * get all teams the user is assigned to
	 *
	 * @param {number} userId
	 */
    const getAllUserTeams = () => {
        setLoading(true);
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TEAM_GET_ALL_USERS_TEAM,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                userId: user.id
            }
        })
        .then(res => {
            if(res && res.status === 200) {
                if (res.data && res.data.userTeams && res.data.userTeams.Teams) {
                    const overallTeams = [
                        ...res.data.userTeams.TeamLeader.filter(team => !res.data.userTeams.Teams.map(t => t.id).includes(team.id)),  // not to repeat the same team that exists on teamsWithMember
                        ...res.data.userTeams.Teams,
                    ];


                    setTeams(overallTeams.map((team, index) => {
                        return {
                            ...team,
                            team_number: index + 1,
                            team_leader_name: UserUtil.formatUserForTableColumn(team.TeamLeader),
                            project_name: team.Project.project_name,
                        }
                    }));
                }
                setLoading(false);
            }
        })
        .catch(error => {
            console.log(error);
        })
    }

    const closeAddEditViewModal = (e) => {
        setAddEditViewTeamModalOpen(false);
    };

    const openViewTeamModal = (data) => {
        setViewEditTeamId(data.id);
        setAddEditViewTeamModalOpen(true);
    }

    const optionsList = React.useMemo(() => 
    [
        {
            itemName: 'View',
            itemType: 'View',
            openViewModal: openViewTeamModal,
        }
    ], []);

    useEffect(() => {
        getAllUserTeams();
        setTeamListHeader(TableHeadersUtil.teamHomeTableHeaders);
    }, []);

    return (
        <React.Fragment>
            <Header/>
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="6">
                                        <h3 className="mb-0">Teams</h3>

                                    </Col>
                                    <Col className="text-right">
                                        <Button
                                            color="secondary"
                                            onClick={resetTable}
                                            size="md"
                                        >
                                            Reset Filters
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <CustomTablePrime
                                    columnDefs={teamListHeader} 
                                    rowData={teams}
                                    loading={loading}
                                    optionsList={optionsList}
                                    tableRef={tableRef}
                                    setTableRef={setTableRef}
                                    stateStorageName={
                                        ENUMS.DT_STATE_STORAGE_NAMES.TEAMS_HOME + 
                                    "-" + user.id}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            { 
                addEditViewTeamModalOpen && 
                <TeamAddEditViewModal 
                    modalTitle={ENUMS.MODAL.VIEW_TEAM_TITLE}
                    modalButtonName={ENUMS.BUTTON_TEXT.ADD}
                    modalMode={ENUMS.MODAL.VIEW_MODE}
                    modalOpen={addEditViewTeamModalOpen}
                    viewEditTeamId={viewEditTeamId}
                    closeModal={closeAddEditViewModal}
                /> 
            }
        </React.Fragment>
    );
}