import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Form, FormGroup, Input, Spinner, Button, Label } from 'reactstrap';
import "./ContactUs.css";
import { API } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import HttpUtil from "util/HttpUtil";
import ENUMS from 'constants/appEnums';
import DataOptions from 'constants/appDataOptions';
import { useToasts } from 'react-toast-notifications';
import Select from "react-select";

export function ContactUs() {
    
    const { addToast } = useToasts();
    let location = useLocation();
    let history = useHistory();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [subject, setSubject] = useState();
    const [message, setMessage] = useState();
    const [companyName, setCompanyName]= useState();
    const [role, setRole]= useState();
    const [preferredContact, setPreferredContact]= useState();

    const [isLoading, setIsLoading] = useState();

    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur"
    });

    const handleLogin = () => {
        setIsLoading(true);
        API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.CONTACT_US_CREATE,
        {
            ...HttpUtil.authHttpHeaders,
            body: {
                fullName: name,
                email: email,
                subject: subject,
                message: message,
                company_name: companyName,
                company_role: role,
                phone: phone || '',
                mobile: '' || phone,
                preferred_contact: preferredContact || '',
                comments: message,
            }
        })
        .then(res => {
            if (res.status === 200) {
                addToast("Contact details saved", { 
                    appearance: 'success',
                    autoDismiss: true
                });
            }
            else {
                addToast(res.data.message, { 
                    appearance: 'error',
                    autoDismiss: true
                });
            }
            setIsLoading(false);
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    error.response.data.message ? error.response.data.message : "Invalid Credentials", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const goHomePage = () => {
        history.push("/auth/home");
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="bg-white">
            <Container fluid>
                <Row>
                    <Col sm="12" md="6" className="outroute-left-pannel bg-white">
                        <div className="mt-4">
                            <img className="cursor-pointer" onClick={() => goHomePage()} src={require("assets/img/home/logocop.svg")}></img>
                        </div>
                        <div className="outroute-wrapper">
                            <div>
                                <img className="cursor-pointer" onClick={() => goHomePage()} src={require("assets/img/home/arrow_back.png")} alt="Go back"></img>
                                <span className="back-text">BACK</span>
                            </div>
                            <div className="outroute-title">Contact Us</div>
                            <div className="outroute-content">We will get back to you within one to two days via email. Also, please don't forget to monitor your spam email in case our response is redirected.</div>
                            <div className="mt-4">
                                <Form role="form" onSubmit={handleSubmit(handleLogin)}>
                                    <FormGroup className="mb-3">
                                        <Label className="outroute-form-label">Name*</Label>
                                        <Input 
                                            name="name" 
                                            placeholder="" 
                                            type="text"
                                            onChange={(e)=>setName(e.target.value)}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label className="outroute-form-label">Email*</Label>
                                        <Input 
                                            name="email" 
                                            placeholder="" 
                                            type="email"
                                            onChange={(e)=>setEmail(e.target.value)}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label className="outroute-form-label">Phone</Label>
                                        <Input 
                                            name="phone" 
                                            placeholder="" 
                                            type="text"
                                            onChange={(e)=>setPhone(e.target.value)}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label className="outroute-form-label">Company</Label>
                                        <Input 
                                            name="company" 
                                            placeholder="" 
                                            type="text"
                                            onChange={(e)=>setCompanyName(e.target.value)}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label className="outroute-form-label">Role / Job Title</Label>
                                        <Input 
                                            name="role" 
                                            placeholder="" 
                                            type="text"
                                            onChange={(e)=>setRole(e.target.value)}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="timezone">Preferred Mode of Contact</Label>
                                        <Select
                                            id="preferredContact"
                                            className="form-control-alternative"
                                            options={DataOptions.PREFERRED_MODE_OF_CONTACT_OPTIONS}
                                            defaultValue={DataOptions.PREFERRED_MODE_OF_CONTACT_OPTIONS[0]}
                                            onChange={(e)=> setPreferredContact(e.value)}
                                        />
                                    </FormGroup>
                                    
                                    <FormGroup className="mb-3">
                                        <Label className="outroute-form-label">Subject*</Label>
                                        <Input 
                                            name="subject" 
                                            placeholder="" 
                                            type="text"
                                            onChange={(e)=>setSubject(e.target.value)}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label className="outroute-form-label">Message*</Label>
                                        <Input 
                                            name="message" 
                                            placeholder="Type your message"
                                            type="textarea"
                                            onChange={(e)=>setMessage(e.target.value)}
                                            style={{ height: '173px' }}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <div className="text-center mb-5">
                                    {isLoading ?
                                        <Spinner size="sm" color="primary" /> :
                                        <Button className="index-button-primary w-100 my-4 cursor-pointer" color="primary" type="submit">
                                            Send Request
                                        </Button>
                                    }
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className="outroute-footer">
                            <Row className="d-flex justify-content-between">
                                <Col sm="12" md="5" className="d-none d-md-block">
                                    <img className="cursor-pointer"  onClick={() => goHomePage()} src={require("assets/img/home/logocop_gray.png")}></img>
                                </Col>
                                <Col className="sm-text-center text-right outroute-footer-links" sm="12" md="7">
                                    <span className="ml-md-5">Terms & Conditions</span>
                                    <span className="ml-md-5">Privacy Policy</span>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col className="d-none d-md-flex outroute-right-col justify-content-center align-items-center" md="6">
                        <div className="outroute-right-col-gradient"></div>
                        <div className="d-flex justify-content-center align-items-center w-100 h-100">
                            <img src={require("assets/img/home/contactus-lady.png")} className="right-img"></img>
                            <div className="blur-imge"></div>
                            <div className="blur-imge"></div>
                            <div className="blur-imge"></div>
                            <img src={require("assets/img/home/mushroom_yellow.png")} className="right-floating-img right-img-mushroom"></img>
                            <img src={require("assets/img/home/ring_pink.png")} className="right-floating-img right-img-ring-pink"></img>
                            <img src={require("assets/img/home/sphere_purple.png")} className="right-floating-img right-img-sphere-purple"></img>
                            <img src={require("assets/img/home/spring_purple.png")} className="right-floating-img right-img-spring-purple"></img>
                            <img src={require("assets/img/home/ring_blue.png")} className="right-floating-img right-img-ring-blue"></img>
                            <img src={require('assets/img/home/toggle.svg')} className="right-floating-img icon-shadow right-img-toggle"></img>
                            <img src={require('assets/img/home/cardboard.svg')} className="right-floating-img icon-shadow right-img-cardboard"></img>
                            <img src={require('assets/img/home/calendar.svg')} className="right-floating-img icon-shadow right-img-calendar"></img>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}