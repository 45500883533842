import React from 'react';
import './Loader.css';

function Loader() {
  return (
    <div className="loading">
        <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
        </div>
    </div>
  );
}

export default Loader;