import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { API } from "aws-amplify";
import ENUMS from "constants/appEnums";
import HttpUtil from "util/HttpUtil";
import ReportHeader from "components/Headers/ReportHeader";
import { Container, Form, FormGroup, Label, Input, Row, Col, Card, CardHeader, CardBody, Button } from "reactstrap";
import FullPageCalendar from "components/FullPageCalendar/FullPageCalendar";
import CalendarUtil from "util/CalendarUtil";
import ProjectUtil from "util/ProjectUtil";
import { useSelector } from "react-redux";
import DateTimeUtils from "util/DateTimeUtils";
import { format } from "date-fns";
import ProjectMilestoneHeader from "./ProjectMilestoneHeader";
import "./ProjectMilestone.css";
import HorizontalTimeline from "components/HorizontalTimeline/HorizontalTimeline";
import { CompactPicker } from 'react-color';
import DataOptions from "constants/appDataOptions";
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { useForm } from "react-hook-form";
import _ from "lodash";
import ReactToPrint from 'react-to-print';

export default function ProjectMilestone(props) {
    const { register, handleSubmit, errors, control } = useForm();

    /**
     * Location hook
     */
    const location = useLocation();
    //User props
    const current_user = useSelector(state => state.authReducer.user);

    const animatedComponents = makeAnimated();

    const dates = [
        {
          date: '2013-06-16',
          name: 'Referral date'
        },
        {
          date: '2015-02-16',
          name: 'Some rando date'
        },
        {
          date: '2015-02-16',
          name: 'Another thing'
        },
        {
          date: '2015-10-01',
          name: 'Current shipping date'
        }
    ]

    const [timelineData, setTimelineData] = useState([]);
    const [timeline, setTimeline] = useState([]);

    /**
     * Project
     */
    const [projectId, setProjectId] = useState(null);
    const [projectDetails, setProjectDetails] = useState({});

    const [loading, setLoading] = useState(true);
    
    const [tasks, setTasks] = useState();

    /**
     * TOAST hook
     */
    const { addToast } = useToasts();

    const fetchProjectDetails = (projectIdPassed) => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_GET_SINGLE,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                projectId: projectIdPassed
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success === true) {
                const projectDetailsData = res.data.projectDetails
                projectDetailsData.Tasks.map(task => {
                    const timeDifference = ProjectUtil.findTimeDifference(projectDetailsData.Project_Setting.AssignedCalendar);
                    const timeDuration = ProjectUtil.convertTaskDurationForUI(
                        task.task_duration,
                        projectDetailsData.Project_Setting.default_duration_format,
                        timeDifference
                    );
                    task['duration_view'] = timeDuration + 
                        (projectDetailsData.Project_Setting.default_duration_format === ENUMS.DURATION_FORMAT_VALUES.HOUR ? 'Hrs' : 'Days');
                    task['start_time'] = format(DateTimeUtils.convertTimezone(
                        new Date(task.task_start_time), current_user), 'yyyy-MM-dd');
                    task['end_time'] = format(DateTimeUtils.convertTimezone(
                        new Date(task.task_end_time), current_user), 'yyyy-MM-dd');
                    
                    return task;
                });
                setProjectDetails(projectDetailsData);
                populateDefaultTimelineConfiguration(projectDetailsData.Tasks);
                setLoading(false)
            }
        })
        .catch(error => {
            if (error.response) {
                console.error(error.response);
                addToast(
                    error.response || "Failed to fetch project details",
                    {
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        });
    }

    const populateDefaultTimelineConfiguration = (taskList) => {
        const timelineList = []
        taskList.map(task => {
            timelineList.push({
                date: task['start_time'],
                start_time: task.start_time,
                end_time: task.end_time,
                name: task.task_name,
                color: '#fff',
                description: task.task_name,
                selected: true,
                showPicker: false,
                start_time_view: true
            });
        });
        setTimelineData(timelineList);
        setTimeline(_.cloneDeep(timelineList));
    }

    const populateTimelineView = () => {
        const timelineList = [];
        timelineData.map(task => {
            console.log(task)
            if(task.selected) {
                timelineList.push({
                    date: task.start_time_view ? task['start_time'] : task['end_time'],
                    name: task.name,
                    color: task.color,
                    description: task.description,
                });
            }
        });
        console.log(timelineList);
        setTimeline(_.cloneDeep(timelineList));
    }

    const getProjectDetails = () => {
        if (location.state && location.state.passedId) {
            setProjectId(location.state.passedId);
            fetchProjectDetails(location.state.passedId);
        }
        else if (location.search) {
            const params = new URLSearchParams(location.search);
            const projectIdSearch = params.get('projectId');
            setProjectId(projectIdSearch);
            fetchProjectDetails(projectIdSearch);
        }
    }

    const taskCheckChanged = (e, task, key) => {
        const updatedTimelineData = [...timelineData];
        updatedTimelineData[key].selected = !updatedTimelineData[key].selected;
        e.target.checked = false;
        setTimelineData(updatedTimelineData);
    }

    const handleChangeComplete = (color, task, key) => {
        const updatedTimelineData = [...timelineData];
        updatedTimelineData[key].color = color.hex;
        updatedTimelineData[key].showPicker = false;
        setTimelineData(updatedTimelineData);
    }

    const handleDescriptionChange = (e, key) => {
        const updatedTimelineData = [...timelineData];
        updatedTimelineData[key].description = e.target.value;
        setTimelineData(updatedTimelineData);
    }
    
    const showPicker = (task, key) => {
        const updatedTimelineData = [...timelineData];
        updatedTimelineData[key].showPicker = true;
        setTimelineData(updatedTimelineData);
    }

    const handleTimeView = (optionSelected, key) => {
        const updatedTimelineData = [...timelineData];
        updatedTimelineData[key].start_time_view = optionSelected.value;
        setTimelineData(updatedTimelineData);
    }

    const printCalendar = () => {
        window.print()
    }
    
    const onButtonClickHandler = (data, e) => {
        e.preventDefault();
        populateTimelineView();
    }
    const componentRef = useRef();
    
    useEffect (() => {
        getProjectDetails();
    }, []);

	return (
        <>
            <ProjectMilestoneHeader></ProjectMilestoneHeader>
            <Container className="timeline-container  mt-5" fluid>
                <Row>
                    <Col>
                        {timeline.length && <div>
                            <ReactToPrint
                                trigger={() => <Button>Print this out!</Button>}
                                content={() => componentRef.current}
                            />
                            
                            <HorizontalTimeline data={ timeline } ref={componentRef} />
                        </div>}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form role="form" onSubmit={handleSubmit(onButtonClickHandler)}>
                            <Card>
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="6">
                                            <h3 className="mb-0">Configure Milestone</h3>

                                        </Col>
                                        <Col className="text-right">
                                            <Button
                                                color="primary" 
                                                type="submit"
                                                size="md"
                                            >
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                        {
                                            timelineData.length && timelineData.map((task, key) => {
                                                return (
                                                    <div className="milestone-form" key={key}>
                                                        <Row className="align-items-center">
                                                            <Col sm={2}>
                                                                <FormGroup check className="mb-1dot5rem">
                                                                    <Label check>
                                                                    <Input type="checkbox" checked={timelineData[key].selected} onChange={(e)=> taskCheckChanged(e, timelineData[key], key)}/>{' '}
                                                                        {timelineData[key].name}
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <FormGroup row>
                                                                    <Label sm={4}>
                                                                        Description
                                                                    </Label>
                                                                    <Col sm={8}>
                                                                        <Input type="text" value={timelineData[key].description} onChange={(e) => handleDescriptionChange(e, key)} autoComplete="off"/>
                                                                    </Col>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm={1}>
                                                                <FormGroup row>
                                                                    <Label sm={4}>
                                                                        Description
                                                                    </Label>
                                                                    <Col sm={8}>
                                                                        <Input type="text" value={timelineData[key].description} onChange={(e) => handleDescriptionChange(e, key)} autoComplete="off"/>
                                                                    </Col>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm={2}>
                                                                <FormGroup row className="align-items-center">
                                                                    <Label sm={5}>
                                                                        Color
                                                                    </Label>
                                                                    <Col sm={3}>
                                                                        <div
                                                                            className="color-picker"
                                                                            style={{background: timelineData[key].color, borderColor: timelineData[key].color}}
                                                                            onClick={e => showPicker(timelineData[key], key)}
                                                                        >
                                                                        </div>
                                                                        {
                                                                            task.showPicker
                                                                            ? <div className="color-pick-wrapper">
                                                                                <CompactPicker
                                                                                    color={timelineData[key].color}
                                                                                    onChangeComplete={(e) => handleChangeComplete(e, timelineData[key], key)}
                                                                                />
                                                                            </div>
                                                                            : ''
                                                                        }
                                                                        
                                                                    </Col>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <FormGroup row>
                                                                    <Label sm={4}>
                                                                        Date/Time
                                                                    </Label>
                                                                    <Col sm={8}>
                                                                        <Select
                                                                            id="start_time_view"
                                                                            className="form-control-alternative"
                                                                            components={animatedComponents}
                                                                            onChange={(newValue)=> handleTimeView(newValue, key)}
                                                                            options={DataOptions.MILESTONE_START_END_OPTIONS}
                                                                            value={DataOptions.MILESTONE_START_END_OPTIONS.filter(option => option.value == timelineData[key].start_time_view)}
                                                                        >   
                                                                        </Select>
                                                                    </Col>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                        }
                                </CardBody>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
	);
}
