const authHttpHeaders = {
    headers: {
        'Access-Control-Allow-Origin': process.env.REACT_APP_STAGE === 'dev' ?  'http:/localhost:3000' : 'https://uat.cutoverplan.com.au'
    },
    response: true,
    withCredentials: true
}

const adminHttpHeaders = () => {
    const accessString = localStorage.getItem('JWT') ? localStorage.getItem('JWT') : '';
    return {
        headers: {
            'Access-Control-Allow-Origin': process.env.REACT_APP_STAGE === 'dev' ?  'http:/localhost:3000' : 'https://uat.cutoverplan.com.au',
            'Authorization': `JWT ${accessString}`
        },
        response: true,
        withCredentials: true
    }
}

const HttpUtil = {
    authHttpHeaders,
    adminHttpHeaders
}

export default HttpUtil;