import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import CustomErrorMessage from 'components/CustomErrorMessage/CustomErrorMessage';
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Col,
    Label,
    Container,
    Row
} from "reactstrap";
import { API } from "aws-amplify";
import ENUMS from "constants/appEnums";
import HttpUtil from "util/HttpUtil";
import makeAnimated from "react-select/animated";
import CreatableSelect from 'react-select/creatable';
import { useToasts } from "react-toast-notifications";
import Select from "react-select";
import AuthNavbar from "components/Navbars/AuthNavbar";
import { S } from "@fullpage/react-fullpage";

/**
 * Common function for rendering the reset password form.
 * @param {Props} props 
 */
export default function ResetPassword (props) {
    const { register, handleSubmit, errors, getValues } = useForm({
        mode: "onBlur"
    });

    const { addToast } = useToasts();

    const [timezoneOptions, setTimezoneOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [timezone, setTimezone] = useState("");

    const animatedComponents = makeAnimated();
    
    const settingTimezone = (value) => {
        setTimezone(value);
        props.setTimezone(value);
    }
    
    useEffect(() => {
        API
            .get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TIMEZONE_GET_ALL,
            {
                ...HttpUtil.authHttpHeaders
            })
			.then((res) => {
				setTimezoneOptions(res.data);
				const userBrowserTimeZone = Intl.DateTimeFormat().resolvedOptions()
					.timeZone;
				const timezoneValue = res.data.find(
					(t) => t.utc.indexOf(userBrowserTimeZone) > -1
				).value;
				setTimezone(timezoneValue);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);
    return (
    <>
        <AuthNavbar />
        <Container>
            <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                </Row>
            </div>
        </Container>
        <Container className="mt-5 pb-5">
            <Row className="justify-content-center">
                <Col lg="6" md="8">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent pb-1">
                            <div className="text-center text-muted mb-1">
                                {
                                    !props.changePasswordForm
                                    ? <h2>Enter your details</h2>
                                    : <h2>Reset Password</h2>
                                }
                            </div>
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            
                            <Form role="form" onSubmit={handleSubmit(props.handleReset)} noValidate>
                                {
                                    !props.changePasswordForm && <>
                                        <FormGroup>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-single-02" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="First name *"
                                                    type="text"
                                                    id="firstName"
                                                    name="firstName"
                                                    onChange={(e) => props.setFirstName(e.target.value)}
                                                    innerRef={register(
                                                        {
                                                            required: "First name is required.",
                                                        })}
                                                />
                                            </InputGroup>
                                            <CustomErrorMessage errors={errors} name="firstName" />
                                        </FormGroup>
                                        <FormGroup>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-single-02" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Last name *"
                                                    type="text"
                                                    id="lastName"
                                                    name="lastName"
                                                    onChange={(e) => props.setLastName(e.target.value)}
                                                    innerRef={register(
                                                        {
                                                            required: "Last name is required.",
                                                        })}
                                                />
                                            </InputGroup>
                                            <CustomErrorMessage errors={errors} name="lastName" />
                                        </FormGroup>
                                        <FormGroup>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-email-83" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Email *"
                                                    type="email"
                                                    autoComplete="new-email"
                                                    name="email"
                                                    onChange={(e) => props.setEmail(e.target.value)}
                                                    value={props.email}
                                                    readOnly
                                                    innerRef={register(
                                                        {
                                                            required: "Email is required.",
                                                            pattern: {
                                                                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                                message: "Invalid pattern for email. Ex: example@example.com"
                                                            },
                                                        })}
                                                />
                                            </InputGroup>
                                            <CustomErrorMessage errors={errors} name="email" />
                                        </FormGroup>
                                        <FormGroup>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-mobile-button" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Contact number *"
                                                    type="text"
                                                    id="contactNumber"
                                                    name="contactNumber"
                                                    onChange={(e) => props.setContactNo(e.target.value)}
                                                    innerRef={register(
                                                        {
                                                            required: "Contact number is required.",
                                                            pattern: {
                                                                value: /\d{8}/,
                                                                message: "Invalid contact number"
                                                            }	
                                                        })}
                                                />
                                            </InputGroup>
                                            <CustomErrorMessage errors={errors} name="contactNumber" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="timezone">Timezone</Label>
                                            <Select
                                                id="timezone"
                                                className="form-control-alternative"
                                                getOptionLabel={option =>`${option.text}`}
                                                options={timezoneOptions}
                                                components={animatedComponents}
                                                value={timezoneOptions.filter(option => option.value === timezone)}
                                                onChange={(selectedTimezone)=> settingTimezone(selectedTimezone.value)}
                                            />
                                        </FormGroup>
                                    </>
                                }
                                { !props.changePasswordForm && <FormGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder={props.oldPasswordPlaceholder+" *"}
                                            type="password"
                                            autoComplete="old-password"
                                            name="oldPassword"
                                            onChange={(e) => props.setOldPassword(e.target.value)}
                                            innerRef={register(
                                                {
                                                    required: "Old Password is required.",
                                                })}
                                        />
                                    </InputGroup>
                                    <CustomErrorMessage errors={errors} name="oldPassword" />
                                </FormGroup>
                                }
                                <FormGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="New Password *"
                                            type="password"
                                            autoComplete="new-password"
                                            name="password"
                                            onChange={(e) => props.setNewPassword(e.target.value)}
                                            innerRef={register(
                                                {
                                                    required: "Password is required.",
                                                    pattern: 
                                                    {
                                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                                        message: "Password should contain at least one capital letter, one lower case letter, a number and a special character"
                                                    },
                                                    minLength: 
                                                    {
                                                        value: 8,
                                                        message: "Password should be minimum 8 charachters long."
                                                    }
                                                })}
                                        />
                                    </InputGroup>
                                    <p className="mt-2 text-small">Password should be minimum 8 characters long and should contain at least one capital letter, one lower case letter, a number and a special character.</p>
                                    <CustomErrorMessage errors={errors} name="password" />
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Confirm Password *"
                                            type="password"
                                            autoComplete="confirm-password"
                                            name="confirm-password"
                                            onChange={(e) => props.setConfirmNewPassword(e.target.value)}
                                            innerRef={register(
                                                {
                                                    required: "Confirm Password is required.",
                                                    validate: (value) => {
                                                        if (value === getValues()["password"]) {
                                                            return true;
                                                        } else {
                                                            return "The passwords do not match";
                                                        }
                                                    }
                                                })}
                                        />
                                    </InputGroup>
                                    <CustomErrorMessage errors={errors} name="confirm-password" />
                                </FormGroup>
                                <div className="text-center">
                                    <Button
                                        className="mt-4"
                                        color="primary"
                                        type="submit"
                                    >
                                        {props.buttonText}
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
    )
}