import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ENUMS from 'constants/appEnums';
import { useToasts } from 'react-toast-notifications';
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import { useForm } from 'react-hook-form';
import CustomErrorMessage from 'components/CustomErrorMessage/CustomErrorMessage';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Spinner
} from "reactstrap";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import AuthNavbar from "components/Navbars/AuthNavbar";
import { useAppContext } from "util/ContextUtil";
import { useDispatch } from "react-redux";
import allActions from 'redux/actions/index';
import AuthUtil from "util/AuthUtil";

export default function TwoFactorAuthentication(props) {
    
    let history = useHistory();
    const location = useLocation();

    const { userHasAuthenticated } = useAppContext();
    const dispatch = useDispatch();

    const { addToast } = useToasts();
    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur"
    });

    const [token, setToken] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [userValue, setUserValue] = useState({});

    /**
     * Method to handle verify token.
     * @param {FormData} data 
     * @param {Event} e 
     */
    const handleVerifyToken = (data, e) => {
        e.preventDefault();
        setIsLoading(true);
        API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.AUTH_VALIDATE_TOKEN,
        {
            ...HttpUtil.authHttpHeaders,
            body: {
                email : userValue.email,
                token: token 
            }
        })
        .then(res => {
            console.log(res);
            if (res.status === 200 && res.data.success) {
                localStorage.setItem('JWT', res.data.token);
                userHasAuthenticated(true);
                dispatch(allActions.authActions.setUserState(res.data.user));
                dispatch(allActions.authActions.setUserProjectList(res.data.projectList));
                history.push('/admin/projects-home');
                AuthUtil.setTheme();
            }
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    error.response.data.message ? error.response.data.message : "Invalid token.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    };

    useEffect(() => {
        if (location && location.state) {
            setUserValue(location.state);
        } else {
            history.push('/auth/login');
        }
    }, []);

    return (
        <>
            <AuthNavbar />
            <Container>
                <div className="header-body text-center mb-7">
                    <Row className="justify-content-center">
                    </Row>
                </div>
            </Container>
            <Container className="mt-5 pb-5">
                <Row className="justify-content-center">
                    <Col lg="5" md="7">
                        <Card className="bg-secondary shadow border-0">
                            <CardHeader className="bg-transparent pb-2">
                                <div className="text-muted text-center mt-1">
                                    <h2>Two-Factor Authentication</h2>
                                </div>
                            </CardHeader>
                            
                            <CardBody className="px-lg-5 py-lg-5">
                                <>
                                    <div className="text-muted text-center mt-1">
                                        <p>
                                            We just sent you an email with your authentication code. 
                                            Enter the code in the form below to verify your identity. 
                                            <span className="ml-1 text-primary cursor-pointer">
                                                Resend the code
                                            </span>
                                        </p>
                                    </div>
                                    
                                    <Form role="form" onSubmit={handleSubmit(handleVerifyToken)}>
                                        <FormGroup className="mb-3">
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-email-83" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input 
                                                    name="token" 
                                                    placeholder="6-digit code" 
                                                    type="text"
                                                    onChange={(e)=>setToken(e.target.value)} 
                                                    autoComplete="off"
                                                    innerRef={register({
                                                            required: "Token is required."
                                                    })}
                                                />
                                            </InputGroup>
                                            <CustomErrorMessage errors={errors} name="token" />
                                        </FormGroup>
                                        
                                        <div className="text-center">
                                            {isLoading ?
                                                <Spinner size="sm" color="primary" /> :
                                                <Button className="my-4" color="primary" type="submit">
                                                    Verify
                                                </Button>
                                            }
                                        </div>
                                    </Form>
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
