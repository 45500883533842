import ENUMS from 'constants/appEnums';
import React from "react";
import {
    UncontrolledTooltip,
    Badge
} from "reactstrap";
import { isAfter, isBefore, isEqual, format, parse } from 'date-fns';
import { Link } from "react-router-dom";
import ProjectUtil from './ProjectUtil';
import DateTimeUtils from './DateTimeUtils';
import { firstBy } from "thenby";

/**
 * Formats the task for dropdown
 * @param {Object} task 
 */
const formatTaskForDropdown = (task) => {
    if (task.id && task.task_number && task.task_name) {
        return {
            value: task.id,
            label: task.task_number + 
                    " (" + task.task_name + 
                    ")"
        };
    }
}

/**
 * Formats the task for dropdown with start and end time
 * @param {Object} task 
 */
const formatTaskForDropdownWithTime = (task, user) => {
    if (task.id && task.task_number && task.task_name) {
        return {
            value: task.id,
            label: task.task_number + 
                    " (" + task.task_name + 
                    ") | " +
                    DateTimeUtils.formatDateTime(task.task_start_time, user) +
                    " - " +
                    DateTimeUtils.formatDateTime(task.task_end_time, user)
        };
    }
}

/**
 * Formats the task list for dropdown
 * @param {Array} taskList 
 */
const formatTaskForDropdownBulkWithTime = (taskList, currentTaskId, user) => {
    return taskList.map(task => {
        return {...formatTaskForDropdownWithTime(task, user)};
    }).filter(task => task.value !== currentTaskId);
}

/**
 * Formats the task list for dropdown
 * @param {Array} taskList 
 */
const formatTaskForDropdownBulk = (taskList, currentTaskId) => {
    return taskList.map(task => {
        return {...formatTaskForDropdown(task)};
    }).filter(task => task.value !== currentTaskId);
}

// Predecessor Task Link type array
const predecessorTaskLinkTypes = [
    {
        value: ENUMS.PREDECESSOR_TASK_LINK_TYPES.FS,
        label: ENUMS.PREDECESSOR_TASK_LINK_TYPES.FS_LABEL
    },
    {
        value: ENUMS.PREDECESSOR_TASK_LINK_TYPES.SS,
        label: ENUMS.PREDECESSOR_TASK_LINK_TYPES.SS_LABEL
    },
    {
        value: ENUMS.PREDECESSOR_TASK_LINK_TYPES.FF,
        label: ENUMS.PREDECESSOR_TASK_LINK_TYPES.FF_LABEL
    },
];

/**
 * Calculates the next task number for new task based on task interval value
 * @param {string} taskNumber 
 * @param {string} taskInterval 
 */
const calculateNextTaskNumber = (taskNumber, taskInterval) => {
    const taskNumberSplit = taskNumber.split("-");
    return taskNumberSplit[0] + "-" + (parseInt(taskNumberSplit[1]) + parseInt(taskInterval));
}

// Valid task predecessor link types for generation of task delay start field
const linkTypesForDelay = [ENUMS.PREDECESSOR_TASK_LINK_TYPES.FS, ENUMS.PREDECESSOR_TASK_LINK_TYPES.SS];

/**
 * Formats the predecessor task for table
 * @param {Object} predTask 
 */
const formatPredTaskNameForTable = (predTask) => {
    if (predTask.id && predTask.task_number) {
        return predTask.task_number;
    }
}

/**
 * Formats the task link type based on the general format
 * @param {string} linkType 
 */
const formatTaskLinkTypeForDropdown = (linkType) => {
    if (linkType) {
        return predecessorTaskLinkTypes.filter(link => link.value === linkType)[0];
    }
}

/**
 * Formats and returns Yes/No based on the critical path value.
 * @param {boolean} isCriticalPath 
 */
const formatIsCriticalPath = (isCriticalPath) => {
    return isCriticalPath ? "Yes" : "No";
}

// Default predecessor task format
const populateDefaultPredTaskAdd = [
    {
        predecessorTask: {},
        predecessorTaskLinkType: {},
        taskDelayStart: 0,
        added: false
    }
];

/**
 * Checks if newly selected predtask value already exists or not.
 * @param {Object} newValue 
 * @param {Object} predTaskDetails 
 */
const checkIfPredTaskSelected = (newValue, predTaskDetails) => {
    const isExists = predTaskDetails.filter(task => {
        return task.predecessorTask.value === newValue.value
    });
    return isExists.length > 0;
}

/**
 * Validates the predecessor task details array to check if all of the entries are valid or not
 * @param {Object} predTaskDetails 
 */
const validatePredTaskDetails = (predTaskDetails) => {
    let isValidated = true;
    for (const predTask of predTaskDetails) {
        // validates the saved predecessor tasks
        if (predTask.added) {
            if (!predTask.predecessorTask || 
                !predTask.predecessorTask.value ||
                !predTask.predecessorTaskLinkType ||
                !predTask.predecessorTaskLinkType.value) {
                isValidated = false;
                break;
            }  
        }
        // validates the empty or not saved predecessor tasks
        else {
            if (!predTask.predecessorTask && 
                !predTask.predecessorTask.value)
                continue;
            else if ((predTask.predecessorTask && 
                predTask.predecessorTask.value) ||
                (predTask.predecessorTaskLinkType &&
                predTask.predecessorTaskLinkType.value)) {
                isValidated = false;
                break;
            }
        }
    }
    return isValidated;
}

/**
 * Formats the predecessor task array by removing empty entries and making it database standardized.
 * @param {Object} predTaskDetails 
 */
const formatPredTaskDetails = (predTaskDetails, projectSettings) => {
    return predTaskDetails.filter(task => task.added).map(predTask => {
        return {
            predecessor_task_id: predTask.predecessorTask.value,
            predecessor_task_link_type: predTask.predecessorTaskLinkType.value,
            task_delay_start: ProjectUtil.convertTaskDurationToHoursForDB(
                predTask.taskDelayStart,
                projectSettings.default_duration_format,
                ProjectUtil.findTimeDifference(projectSettings.AssignedCalendar)
            )
        }
    });
}

/**
 * Formats the predecessor task list on load of edit mode.
 * @param {Array} predTaskDetails 
 */
const formatPredTaskForEdit = (predTaskDetails, projectSettings) => {
    return predTaskDetails.map(predTask => {
        return {
            predecessorTask: formatTaskForDropdown(predTask.SuccessorTask),
            predecessorTaskLinkType: formatTaskLinkTypeForDropdown(predTask.predecessor_task_link_type),
            taskDelayStart: ProjectUtil.convertTaskDurationForUI(
                predTask.task_delay_start,
                projectSettings.default_duration_format,
                ProjectUtil.findTimeDifference(projectSettings.AssignedCalendar)
            ),
            added: true
        }
    });
}

/**
 * Formats the predecessor tasks for display
 * @param {Array} predecessorTasks 
 */
const formatPredecessorTaskForDisplay = (predecessorTasks, projectSettings) => {
    return  predecessorTasks.map(task => {
        return <div key={task.id} className="text-nowrap">
            {formatTaskNumber(task.SuccessorTask.id, task.SuccessorTask.task_number)}
            {" "}
            <Badge pill color="warning">{task.predecessor_task_link_type}</Badge> 
            {" "}
            <i className="pl-2 fas fa-info-circle" id={"start_end_tooltip-" + task.id}></i>
            <UncontrolledTooltip
                delay={0}
                placement="top"
                target={"start_end_tooltip-" + task.id}
            >
                <div>Name: {task.SuccessorTask.task_name}</div>
                <div>Task Delay Start: 
                    {
                        ProjectUtil.convertTaskDurationForUI(
                            task.task_delay_start,
                            projectSettings.default_duration_format,
                            ProjectUtil.findTimeDifference(projectSettings.AssignedCalendar)
                        )
                    }
                </div>
            </UncontrolledTooltip>
        </div>
    });
}

const formatTaskTeamForDisplay = (taskTeams) => {
    return taskTeams.map((team,key) => {
        return <div key={key} className="text-capitalize">{team.team_name}</div>
    })
}

const formatTaskDataForExceptionDisplay = (taskId, data, taskExceptions, workExceptions, current_user, durationFormat) => {
    return <div key={taskId}>
        <span key={taskId}>{data} {durationFormat === ENUMS.DURATION_FORMAT_VALUES.HOUR ? ' Hrs' : ' Days'}</span>
        {
            taskExceptions.length > 0 &&
            <span>
                <i className="pl-2 fas fa-exclamation-triangle fs-11 warning-color" id={"duration-task-exception" + taskId}></i>
                <UncontrolledTooltip
                    style={{ backgroundColor: "#4A7EFA", "max-width": "500px" }}
                    delay={0}
                    placement="top"
                    target={"duration-task-exception" + taskId}
                >
                    {
                        taskExceptions.map((exception, key) => {
                            return <div key={key}>
                                <span className="mr-1">
                                    {exception.holiday_name}:
                                </span>
                                <span className="mr-1">
                                    {
                                        format(
                                            DateTimeUtils.convertTimezone(
                                                new Date(exception.start_date),
                                                current_user
                                            ), 
                                            DateTimeUtils.getUserDateTimeFormat(current_user.date_format)
                                        )
                                    }
                                </span>
                                <span>-</span>  
                                <span className="ml-1">
                                {
                                    format(
                                        DateTimeUtils.convertTimezone(
                                            new Date(exception.end_date),
                                            current_user
                                        ), 
                                        DateTimeUtils.getUserDateTimeFormat(current_user.date_format)
                                    )
                                }
                                </span>
                            </div>
                        })
                    }
                </UncontrolledTooltip>
            </span>
        }
        {
            workExceptions.length > 0 &&
            <span>
                <i className="pl-2 fas fa-business-time fs-11 warning-color" id={"duration-task-exception" + taskId}></i>
                <UncontrolledTooltip
                    style={{ backgroundColor: "#4A7EFA", "max-width": "500px" }}
                    delay={0}
                    placement="top"
                    target={"duration-task-exception" + taskId}
                >
                    {
                        workExceptions.map((exception, key) => {
                            return <div key={key}>
                                <span className="mr-1">
                                    {exception.holiday_name}:
                                </span>
                                <span className="mr-1">
                                    {
                                        format(
                                            DateTimeUtils.convertTimezone(
                                                new Date(exception.start_date),
                                                current_user
                                            ), 
                                            DateTimeUtils.getUserDateTimeFormat(current_user.date_format)
                                        )
                                    }
                                </span>
                                <span>-</span>  
                                <span className="ml-1">
                                {
                                    format(
                                        DateTimeUtils.convertTimezone(
                                            new Date(exception.end_date),
                                            current_user
                                        ), 
                                        DateTimeUtils.getUserDateTimeFormat(current_user.date_format)
                                    )
                                }
                                </span>
                            </div>
                        })
                    }
                </UncontrolledTooltip>
            </span>
        }
    </div>
}

/**
 * Task Status Types for badge
 */
const taskStatusTypes = [
    {
        value: ENUMS.TASK_STATUS.P,
        label: ENUMS.TASK_STATUS.P_LABEL,
        color: "primary"
    },
    {
        value: ENUMS.TASK_STATUS.S,
        label: ENUMS.TASK_STATUS.S_LABEL,
        color: "info"
    },
    {
        value: ENUMS.TASK_STATUS.SC,
        label: ENUMS.TASK_STATUS.SC_LABEL,
        color: "success"
    },
    {
        value: ENUMS.TASK_STATUS.PA,
        label: ENUMS.TASK_STATUS.PA_LABEL,
        color: "default"
    },
    {
        value: ENUMS.TASK_STATUS.BL,
        label: ENUMS.TASK_STATUS.BL_LABEL,
        color: "danger"
    },
    {
        value: ENUMS.TASK_STATUS.FC,
        label: ENUMS.TASK_STATUS.FC_LABEL,
        color: "danger"
    },
    {
        value: ENUMS.TASK_STATUS.FT,
        label: ENUMS.TASK_STATUS.FT_LABEL,
        color: "danger"
    },
];

const taskStatusTypesInlineEdit = [
    {
        value: ENUMS.TASK_STATUS.P,
        label: <Badge color="primary">{ENUMS.TASK_STATUS.P_LABEL}</Badge>,
        color: "primary"
    },
    {
        value: ENUMS.TASK_STATUS.S,
        label: <Badge color="info">{ENUMS.TASK_STATUS.S_LABEL}</Badge>,
        color: "info"
    },
    {
        value: ENUMS.TASK_STATUS.SC,
        label: <Badge color="success">{ENUMS.TASK_STATUS.SC_LABEL}</Badge>,
        color: "success"
    },
    {
        value: ENUMS.TASK_STATUS.PA,
        label: <Badge color="default">{ENUMS.TASK_STATUS.PA_LABEL}</Badge>,
        color: "default"
    },
    {
        value: ENUMS.TASK_STATUS.BL,
        label: <Badge color="danger">{ENUMS.TASK_STATUS.BL_LABEL}</Badge>,
        color: "danger"
    },
    {
        value: ENUMS.TASK_STATUS.FC,
        label: <Badge color="danger">{ENUMS.TASK_STATUS.FC_LABEL}</Badge>,
        color: "danger"
    },
    {
        value: ENUMS.TASK_STATUS.FT,
        label: <Badge color="danger">{ENUMS.TASK_STATUS.FT_LABEL}</Badge>,
        color: "danger"
    },
];

const getTaskStatusWithRehearsal = (activeRehearsal, task) => {
    if (activeRehearsal) {
        return task.Rehearsal_Tasks.find(r => r.rehearsal_id == activeRehearsal.id).status;
    } else {
        return task.task_status;
    }
}

const getTaskStatusWithActiveRehearsal = (task) => {
    if (task.Rehearsal_Tasks.length > 0) {
        const activeReh = task.Rehearsal_Tasks.find(rt => rt.Rehearsal.active);
        return activeReh ? activeReh.status : task.task_status;
    } else {
        return task.task_status;
    }
}

const getTaskStartTimeWithRehearsal = (activeRehearsal, task) => {
    if (activeRehearsal) {
        return task.Rehearsal_Tasks.find(r => r.rehearsal_id == activeRehearsal.id).start_time;
    } else {
        return task.task_start_time;
    }
}

const getTaskEndTimeWithRehearsal = (activeRehearsal, task) => {
    if (activeRehearsal) {
        return task.Rehearsal_Tasks.find(r => r.rehearsal_id == activeRehearsal.id).end_time;
    } else {
        return task.task_end_time;
    }
}

/**
 * Formats the task status for table
 * @param {String} taskStatus 
 */
const formatTaskStatusForTable = (taskStatus) => {
    const taskStatusType = taskStatusTypes.filter(type => type.value === taskStatus)[0];
    return <Badge color={taskStatusType.color}>{taskStatusType.label}</Badge>
}

/**
 * Autogenerates the task status calculation from current time
 * @param {Object} task 
 */
const getTaskStatus = (task) => {
    if (isEqual(new Date(task.task_start_time), new Date()) || 
    isEqual(task.task_end_time, new Date())) {
        return formatTaskStatusForTable(ENUMS.TASK_STATUS.S);
    }
    else if (isBefore(new Date(task.task_start_time), new Date()) && 
    isAfter(new Date(task.task_end_time), new Date())) {
        return formatTaskStatusForTable(ENUMS.TASK_STATUS.S);
    }
    else if (isAfter(new Date(task.task_start_time), new Date())) {
        return formatTaskStatusForTable(ENUMS.TASK_STATUS.NS);
    }
    else {
        return formatTaskStatusForTable(ENUMS.TASK_STATUS.F);
    }
}

/**
 * Formats the task number into a link for redirection
 * @param {Integer} taskId 
 * @param {String} taskNumber 
 */
const formatTaskNumber = (taskId, taskNumber) => {
    return <Link
        to={{
        pathname: '/admin/tasks/task-view',
        search: '?taskId=' + taskId,
        state: { passedId: taskId }
        }}
        key={taskId}
    >
        {taskNumber}
    </Link>
}

/**
 * Formats the task number into a link for redirection
 * @param {Integer} taskId 
 * @param {String} taskNumber 
 */
const formatTaskNumberWithName = (taskId, taskNumber, taskName) => {
    return <Link
        to={{
        pathname: '/admin/tasks/task-view',
        search: '?taskId=' + taskId,
        state: { passedId: taskId }
        }}
        key={taskId}
    >
        {taskNumber + ': ' + taskName}
    </Link>
}

const getNumberOfOpenTasks = (taskList) => {
    return taskList.length < 1
        ? 0
        : taskList.filter(task => (
            (task.task_status != ENUMS.TASK_STATUS.SC)
            && (task.task_status != ENUMS.TASK_STATUS.FC)
            && (task.task_status != ENUMS.TASK_STATUS.FT)
        )).length;
}

/**
 * method to return end time of the last task of project (Ordered by task end time
 * @param {*} taskList 
 * @param {*} user 
 * @returns DateTime String
 */
const getTargetDateFromTaskList = (taskList, user) => {
    if (taskList.length < 1) {
        return null;
    } else {
        const val = taskList.sort(
            firstBy((a,b) => {
                return -1 * (parse(
                    DateTimeUtils.formatDateTime(a.task_end_time, user),
                    DateTimeUtils.getUserDateTimeFormat(user.date_format, 
                        user.twenty_four_hour_clock),
                    new Date()
                ) - parse(
                    DateTimeUtils.formatDateTime(b.task_end_time, user),
                    DateTimeUtils.getUserDateTimeFormat(user.date_format, 
                        user.twenty_four_hour_clock),
                    new Date()
                ));
            })
        );
        return DateTimeUtils.formatDateTime(val[0].task_end_time, user);
    }
};

const TaskUtil = {
    formatTaskForDropdown,
    formatTaskForDropdownBulk,
    formatTaskForDropdownWithTime,
    formatTaskForDropdownBulkWithTime,
    predecessorTaskLinkTypes,
    calculateNextTaskNumber,
    linkTypesForDelay,
    formatPredTaskNameForTable,
    formatTaskLinkTypeForDropdown,
    formatIsCriticalPath,
    populateDefaultPredTaskAdd,
    checkIfPredTaskSelected,
    validatePredTaskDetails,
    formatPredTaskDetails,
    formatPredTaskForEdit,
    formatPredecessorTaskForDisplay,
    formatTaskTeamForDisplay,
    taskStatusTypes,
    taskStatusTypesInlineEdit,
    formatTaskStatusForTable,
    formatTaskNumber,
    formatTaskNumberWithName,
    formatTaskDataForExceptionDisplay,
    getTaskStatus,
    getNumberOfOpenTasks,
    getTargetDateFromTaskList,
    getTaskStatusWithRehearsal,
    getTaskStatusWithActiveRehearsal,
    getTaskStartTimeWithRehearsal,
    getTaskEndTimeWithRehearsal
};

export default TaskUtil;