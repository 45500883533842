/**
 * Contains the names of the rules
 * Follows the pattern "{module}/{action}"
 */
const RULE_NAMES = {
    PROJECT_VIEW: "project:view",
    PROJECT_EDIT: "project:edit",
    PROJECT_ADMIN_EDIT: "project: admin_edit",
    PROJECT_DELETE: "project:delete",
    PROJECT_SETTING_EDIT: "project_setting:edit",
    TEAM_VIEW: "team:view",
    TEAM_ADD: "team:add",
    TEAM_EDIT: "team:edit",
    TEAM_DELETE: "team:delete",
    TASK_VIEW: "task:view",
    TASK_ADD: "task:add",
    TASK_EDIT: "task:edit",
    TASK_DELETE: "task:delete",
    TASK_START: "task:start",
    TASK_STOP: "task:stop",
    CALENDAR_VIEW: "calendar:view",
    CALENDAR_ADD: "calendar:add",
    CALENDAR_EDIT: "calendar:edit",
    CALENDAR_DELETE: "calendar:delete",
    CALENDAR_EXCEPTIONS_VIEW: "calendar_exceptions:view",
    CALENDAR_EXCEPTIONS_ADD: "calendar_exceptions:add",
    CALENDAR_EXCEPTIONS_EDIT: "calendar_exceptions:edit",
    CALENDAR_EXCEPTIONS_DELETE: "calendar_exceptions:delete",
    FILE_TASK_DELETE: "file:delete",
    ACTION_EDIT: "action:edit",
    ACTION_DELETE: "action:delete",
    RISK_EDIT: "risk:edit",
    RISK_DELETE: "risk:delete",
    ISSUE_EDIT: "issue:edit",
    ISSUE_DELETE: "issue:delete",
    ASSUMPTION_EDIT: "assumption:edit",
    ASSUMPTION_DELETE: "assumption:delete",
    DEPENDENCY_EDIT: "dependency:edit",
    DEPENDENCY_DELETE: "dependency:delete",
}

/**
 * Contains the names of the roles
 */
const ROLE_NAMES = {
    PROJECT_MEMBER: "projectMember",
    TASK_OWNER: "taskOwner",
    TEAM_LEADER: "teamLeader",
    PROJECT_OWNER: "projectOwner",
    ADMIN: "admin",
    ACTION_OWNER: "actionOwner",
    RISK_OWNER: "riskOwner",
    RISK_MITIGATION_OWNER: "riskMitigationOwner",
    ASSUMPTION_OWNER: "assumptionOwner",
    ISSUE_OWNER: "issueOwner",
    ISSUE_MITIGATION_OWNER: "issueMitigationOwner",
    DEPENDENCY_OWNER: "dependencyOwner",
    PROJECT_ADMIN: "projectAdmin"
}

const RULES = {
    RULE_NAMES,
    ROLE_NAMES
}

export default RULES;