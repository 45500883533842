import React, { useState, useEffect, useRef } from "react";
import { 
    Modal, 
    ModalHeader,
    ModalBody,
    Card, 
    CardBody, 
    ModalFooter,
    Form, 
    FormGroup,
    Input, 
    Button, 
    Label,
    Spinner
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import allActions from 'redux/actions/index';
import { API } from "aws-amplify";
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import ENUMS from 'constants/appEnums';
import APP_CONSTANTS from 'constants/appConstants';
import { useToasts } from 'react-toast-notifications';
import HttpUtil from "util/HttpUtil";
import { useForm } from 'react-hook-form';
import CustomErrorMessage from 'components/CustomErrorMessage/CustomErrorMessage';
import DataOptions from "constants/appDataOptions";
import Select from "react-select";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import GeneralUtils from "util/GeneralUtils";
import AddEditCalendarModal from "../ProjectSettings/AddEditCalendarModal/AddEditCalendarModal";
import DateTimeUtils from "util/DateTimeUtils";
import UserUtil from "util/UserUtil";

export default function ProjectAddEditModal (props) {

    const { addToast } = useToasts();
    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur"
    });
    const dispatch = useDispatch();
    const current_user = useSelector(state => state.authReducer.user);
    const projectList = useSelector(state => state.authReducer.userProjectList);
    const projectNameFocusRef = useRef(null);

    const [projectName, setProjectName] = useState("");
    const [projectType, setProjectType] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [projectOwner, setProjectOwner] = useState({});
    const [projectAdmin, setProjectAdmin] = useState([]);
    const [userList, setUserList] = useState([]);
    const [projectAdminUserList, setProjectAdminUserList] = useState([]);
    const [startTaskID, setStartTaskID] = useState("");
    const [defaultDurationFormat, setDefaultDurationFormat] = useState(ENUMS.DURATION_FORMAT_VALUES.HOUR);
    const [defaultTaskNumberInterval, setDefaultTaskNumberInterval] = useState(100);
    const [inviteUserName, setInviteUserName] = useState(null);
    const [inviteProjectAdmin, setInviteProjectAdmin] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingUserList, setIsLoadingUserList] = useState(false);

    const [openMenu, setOpenMenu] = useState(false);
    const [openProjectAdminMenu, setOpenProjectAdminMenu] = useState(false);
    
    // hide show owner assignment confirmation modal
    const [showOwnerConfirmationModal, setShowOwnerConfirmationModal] = useState(false);

    const animatedComponents = makeAnimated();

    /**
     * ADD EDIT COPY CALENDAR MODAL
     */
    const [showAddEditCalendarModal, setShowAddEditCalendarModal] = useState(false);
    const [addEditCalendarModalTitle, setAddEditCalendarModalTitle] = useState("");
    const [calendarData, setCalendarData] = useState();
    const [addEditCalendarModalActionText, setAddEditCalendarModalActionText] = useState("");
    const [modalType, setModalType] = useState(ENUMS.MODAL.SET_MODE);

    const populateDefaultProjectOwner = (listOfUsers) => {
        console.log(listOfUsers)
        console.log(props.editProjectData)
        // Setting project's project owner to dropdown field
        if (props.modalMode === ENUMS.MODAL.EDIT_MODE && listOfUsers.length > 0) {
            const defaultKey = listOfUsers.findIndex(user => 
                user.value === props.editProjectData.project_owner_id
            );
            setProjectOwner(listOfUsers[defaultKey]);
        }
        // Assigning current user as project owner if mode is ADD
        else if (props.modalMode === ENUMS.MODAL.ADD_MODE && listOfUsers.length > 0) {
            
            const defaultKey = listOfUsers.findIndex(user => 
                user.value === current_user.id
            );
            setProjectOwner(listOfUsers[defaultKey]);
        }   
    }

    const populateForm = () => {
        if (props.editProjectData.project_name.props) 
            setProjectName(props.editProjectData.project_name.props.children);
        else
            setProjectName(props.editProjectData.project_name);
        setProjectType(props.editProjectData.project_type);
        setProjectDescription(props.editProjectData.project_description);
    }

    const fetchUserList = () => {
        setIsLoadingUserList(true);
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.USER_ALL_ORGANIZATION,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                project_id: props.editProjectData.id
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                console.log(res)
                setUserList(res.data.userList);
                setProjectAdminUserList(res.data.userList);
                if (props.modalMode === ENUMS.MODAL.EDIT_MODE && res.data.userList.length > 0) {
                    setProjectAdmin(UserUtil.formatUserForDropdownBulk(props.editProjectData.ProjectAdmins));
                }
                console.log("2")
                populateDefaultProjectOwner(res.data.userList);
                setIsLoadingUserList(false);
            }
        })
        .catch(error => {
            setIsLoadingUserList(false);
            if (error.response) {
                console.log(error.response);
                addToast(
                    "Failed to fetch user list for owner selection", 
                    { 
                        appearance: 'error',
						autoDismiss: true
                    }
                );
            }
        })
    }

    const hideOwnerConfirmModal = () => {
        setShowOwnerConfirmationModal(false);
    }

    const ownerConfirmed = (data) => {
        setShowOwnerConfirmationModal(false);
        addEditProject();
    }

    const askForOwnerConfirmation = () => {
        setShowOwnerConfirmationModal(true);
    }

    const onButtonClickHandler = (data, e) => {
      e.preventDefault();

      if (
        projectOwner.value === current_user.id ||
        projectAdmin.find((x) => x.value === current_user.id)
      ) {
        addEditProject();
      } else {
        askForOwnerConfirmation();
      }
    };

    const addEditProject = async () => {
        setIsLoading(true);
        if (props.modalMode === ENUMS.MODAL.ADD_MODE) {
            let newProject = {
                project_owner_id: projectOwner.value,
                project_name: projectName,
                project_type: projectType,
                project_description: projectDescription,
                project_settings: {
                    start_task_id: startTaskID,
                    default_task_duration: DateTimeUtils.findTimeDifferenceInHours(calendarData.working_start_time, calendarData.working_end_time),
                    default_task_no_intervals: defaultTaskNumberInterval,
                    default_duration_format: defaultDurationFormat
                },
                calendar: calendarData
            };
            const projectAdminNonInvite = projectAdmin && projectAdmin.length > 0 ? 
            projectAdmin.filter(
                member => !inviteProjectAdmin.includes(member.label)).map(
                    member => member.value
            ) : null;
            
            newProject.project_admin = projectAdminNonInvite;
            newProject.invite_project_admin = inviteProjectAdmin;

            // add project api call
            await API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_ADD, 
            {
                ...HttpUtil.adminHttpHeaders(),
                body: 
                {
                    newProject: newProject,
                    inviteUserName: inviteUserName
                },
            })
            .then(res => {
                if (res.status === 201 && res.data.success) {
                    if (!projectList.includes(res.data.projectId)) {
                        projectList.push(res.data.projectId);
                        dispatch(allActions.authActions.setUserProjectList(projectList));
                    }
                    addToast('Project has been added successfully!', { 
                        appearance: 'success',
                        autoDismiss: true
                    });
                    props.refreshPageOnSubmit();
                }
            })
            .catch(error => {
                setIsLoading(false);
                if (error.response) {
                    console.log(error.response);
                    addToast(
                        "Failed to add new project.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
        }
        else if (props.modalMode === ENUMS.MODAL.EDIT_MODE) {
            let editedProject = JSON.parse(JSON.stringify(props.editProjectData));
            let isProjectOwnerChanged = false;
            if (props.editProjectData.project_name !== projectName) {
                editedProject.project_name = projectName;
            }
            if (props.editProjectData.project_type !== projectType) {
                editedProject.project_type = projectType;
            }
            if (props.editProjectData.project_description !== projectDescription) {
                editedProject.project_description = projectDescription;
            }
            if (props.editProjectData.project_owner_id !== projectOwner.value) {
                editedProject.project_owner_id = projectOwner.value;
                isProjectOwnerChanged = true;
            }
            const projectAdminNonInvite = projectAdmin && projectAdmin.length > 0 ? 
            projectAdmin.filter(
                member => !inviteProjectAdmin.includes(member.label)).map(
                    member => member.value
            ) : null;
            
            editedProject.project_admin = projectAdminNonInvite;
            editedProject.invite_project_admin = inviteProjectAdmin;
            
            API.put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_UPDATE, 
            {
                ...HttpUtil.adminHttpHeaders(),
                body: 
                {
                    editedProject: editedProject,
                    isProjectOwnerChanged: isProjectOwnerChanged,
                    inviteUserName: inviteUserName
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success) {
                    addToast('Project has been updated successfully!', { 
                        appearance: 'success',
                        autoDismiss: true
                    });
                    props.refreshPageOnSubmit();

                }
                else {
                    addToast(
                        res.data.message ? 
                            res.data.message : 
                            "Failed to updated project.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
            .catch(error => {
                setIsLoading(false);
                if (error.response) {
                    console.log(error.response);
                    addToast(
                        "Failed to updated project.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                    props.refreshPageOnSubmit();
                }
            })
        }
    }
    const populateStartTaskID = (projName) => {
        setProjectName(projName);
        if (props.modalMode === ENUMS.MODAL.ADD_MODE) {
            const startID = projName
                                .split(' ')
                                .map(elem => { return elem.charAt(0); })
                                .join('')
                                .toUpperCase();
            setStartTaskID(startID+"-10000");
        }
    }

    const createOption = (label) => {
        return {
            label,
            value: label.toLowerCase() + "-invite user"
        }
    };

    const handleInviteUserCreate = (inputValue) => {
        if (GeneralUtils.validateEmail(inputValue)) {
            setInviteUserName(inputValue);
            const option = createOption(inputValue);
            setUserList([...userList, option]);
            setProjectOwner(option);
        }
        else {
            addToast(
                "Invalid format for email.", 
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
        }
        hideMenu();
    }

    const handleInviteProjectAdmin = (inputValue) => {
        // TO-DO: Validate against email format
        if (GeneralUtils.validateEmail(inputValue)) {
            setInviteProjectAdmin([...inviteProjectAdmin, inputValue]);
            const option = createOption(inputValue);
            setProjectAdminUserList([...projectAdminUserList, option]);
            setProjectAdmin([...projectAdmin, option]);
        }
        else {
            addToast(
                "Invalid format for email.", 
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
        }
        hideProjectAdminMenu();
    }

    const formatDropDownLabel = (inputValue) => {
        return "Invite "+ inputValue;
    }

    /**
     * Sets the new value to the project owner and closes the select dropdown.
     * @param {Object} newValue 
     */
    const handleProjectOwnerChange = (newValue) => {
        setProjectOwner(newValue);
        hideMenu();
    }

    /**
     * Sets the new value to the team members and closes the select dropdown.
     * @param {Object} newValue 
     */
     const handleProjectAdminChange = (newValue) => {
        setProjectAdmin(newValue);
        hideProjectAdminMenu();
    }

    /**
     * Closes the dropdown menu on input change
     * @param {Object} query 
     * @param {Obejct} param1 
     */
    const handleInputChange = (query, { action }) => {
        if (action === "input-change") {
            setOpenMenu(true);
        }
    };

    /**
     * Closes the dropdown menu on input change for project admin select
     * @param {Object} query 
     * @param {Obejct} param1 
     */
     const handleProjectAdminInputChange = (query, { action }) => {
        if (action === "input-change") {
            setOpenProjectAdminMenu(true);
        }
    };

    /**
     * Closes the dropdown menu
     */
    const hideMenu = () => {
        setOpenMenu(false);
    };

    /**
     * Closes the dropdown menu
     */
     const hideProjectAdminMenu = () => {
        setOpenProjectAdminMenu(false);
    };

    const openCalendarSettingsModal = () => {
        let title = "Set Default Calendar";
        let actionText = "Set Calendar";
        setModalType(ENUMS.MODAL.SET_MODE);
        
        setAddEditCalendarModalTitle(title);
        setAddEditCalendarModalActionText(actionText);
        setShowAddEditCalendarModal(true);
    }

    const onDefaultCalendarSet = (data) => {
        setCalendarData(data);
        setShowAddEditCalendarModal(false);
    }

    useEffect( () => {
        setCalendarData(current_user.User_Default_Calendar);
        if (props.modalMode === ENUMS.MODAL.EDIT_MODE) {
            fetchUserList();
            populateForm();
        } else {
            setUserList([UserUtil.formatUserForDropdown(current_user)]);
        }
    }, []);

    useEffect(() => {
        if (projectNameFocusRef.current) {
            projectNameFocusRef.current.focus();
        } 
    }, [projectNameFocusRef.current]);

    return (
        <Modal
            className="modal-dialog-centered"
            size="lg"
            isOpen={props.modalOpen}
            toggle={() => props.closeAddEditModal()}
            backdrop="static"
        >
            <ModalHeader 
                toggle={() => props.closeAddEditModal()}
                className="modal-header"
                tag="div"
            >
                <h3 className="modal-title">
                    {props.modalTitle}
                </h3>
			</ModalHeader>
            <Form role="form" onSubmit={handleSubmit(onButtonClickHandler)}>
                <ModalBody className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-4 py-lg-4">
                            <FormGroup className="mb-3">
                                <Label for="projectName">Project Name<span className="required-asterix">*</span></Label>
                                <Input 
                                    name="projectName"
                                    className="form-control-alternative"
                                    type="text"
                                    autoComplete="off"
                                    value={projectName}
                                    onChange={(e)=>populateStartTaskID(e.target.value)}
                                    innerRef={(e) => {
                                        projectNameFocusRef.current = e;
                                        register(e,
                                        {
                                            required: "Project Name is required."
                                        });
                                    }}
                                />
                            </FormGroup>
                            <CustomErrorMessage errors={errors} name="projectName" />
                            <FormGroup>
                                <Label for="projectType">Project Type</Label>
                                <Input 
                                    id="projectType"
                                    className="form-control-alternative"
                                    type="text"
                                    value={projectType}
                                    onChange={(e)=>setProjectType(e.target.value)}
                                    autoComplete="off"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="projectDescription">Project Description</Label>
                                <Input 
                                    id="projectDescription"
                                    className="form-control-alternative"
                                    type="textarea"
                                    rows="4"
                                    maxLength="5000"
                                    value={projectDescription}
                                    onChange={(e)=>setProjectDescription(e.target.value)}
                                    autoComplete="off"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="projectOwner">Project Owner<span className="required-asterix">*</span></Label>
                                <CreatableSelect
                                    id="projectOwner"
                                    name="projectOwner"
                                    // as={CreatableSelect}
                                    // control={control}
                                    // rules={{ required: "Project Owner is required." }}
                                    className="form-control-alternative"
                                    isClearable
                                    components={animatedComponents}
                                    isDisabled={isLoadingUserList}
                                    isLoading={isLoadingUserList}
                                    onChange={(newValue)=>handleProjectOwnerChange(newValue)}
                                    onCreateOption={handleInviteUserCreate}
                                    createOptionPosition="first"
                                    formatCreateLabel={formatDropDownLabel}
                                    options={userList}
                                    value={projectOwner}
                                    onInputChange={handleInputChange}
                                    onFocus={(e) => setOpenMenu(true)}
                                    onBlur={hideMenu}
                                    menuIsOpen={openMenu}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="projectAdmins">Project Admin</Label>
                                <CreatableSelect
                                    id="projectAdmins"
                                    className="form-control-alternative"
                                    isMulti
                                    components={animatedComponents}
                                    isDisabled={isLoadingUserList}
                                    isLoading={isLoadingUserList}
                                    onChange={(newValue) => handleProjectAdminChange(newValue)}
                                    onCreateOption={handleInviteProjectAdmin}
                                    createOptionPosition="first"
                                    formatCreateLabel={formatDropDownLabel}
                                    options={userList}
                                    value={projectAdmin}
                                    onInputChange={handleProjectAdminInputChange}
                                    onBlur={hideProjectAdminMenu}
                                    onFocus={e => setOpenProjectAdminMenu(true)}
                                    menuIsOpen={openProjectAdminMenu}
                                />
                            </FormGroup>
                            {/* <CustomErrorMessage errors={errors} name="projectOwner" /> */}
                            { props.modalMode === ENUMS.MODAL.ADD_MODE &&
                                <FormGroup>
                                    <Label for="startTaskID">Start Task ID<span className="required-asterix">*</span></Label>
                                    <Input 
                                        id="startTaskID"
                                        name="startTaskID"
                                        className="form-control-alternative"
                                        type="text"
                                        value={startTaskID}
                                        onChange={(e)=>setStartTaskID(e.target.value)}
                                        innerRef={register(
                                        {
                                            required: "Start Task ID is required."
                                        })}
                                        autoComplete="off"
                                    />
                                </FormGroup>
                            }
                            { props.modalMode === ENUMS.MODAL.ADD_MODE &&
                                <FormGroup>
                                    <Label for="defaultTaskNumberInterval">Default Task Number Interval<span className="required-asterix">*</span></Label>
                                    <Input 
                                        id="defaultTaskNumberInterval"
                                        name="defaultTaskNumberInterval"
                                        className="form-control-alternative"
                                        type="number"
                                        value={defaultTaskNumberInterval}
                                        onChange={(e)=>setDefaultTaskNumberInterval(e.target.value)}
                                        innerRef={register(
                                        {
                                            required: "Default Task Number Interval is required."
                                        })}
                                        autoComplete="off"
                                    />
                                </FormGroup>
                            }
                            {props.modalMode === ENUMS.MODAL.ADD_MODE && 
                                <CustomErrorMessage errors={errors} name="startTaskID" />
                            }
                            { props.modalMode === ENUMS.MODAL.ADD_MODE &&
                                <FormGroup>
                                    <Label for="defaultDurationFormat">Default Task Duration Format<span className="required-asterix">*</span></Label>
                                    <Select
                                        className="form-control-alternative"
                                        id="defaultDurationFormat"
                                        name="defaultDurationFormat"
                                        options={DataOptions.DURATION_FORMATS}
                                        components={animatedComponents}
                                        value={DataOptions.DURATION_FORMATS.filter(option => option.value === defaultDurationFormat)}
                                        onChange={(e) => setDefaultDurationFormat(e.value)}
                                    />
                                </FormGroup>
                            }
                            {
                                // Deault task duration removed TODO: remove comment 
                            }
                            {props.modalMode === ENUMS.MODAL.ADD_MODE && 
                                <CustomErrorMessage errors={errors} name="defaultTaskNumberInterval" />
                            }
                            <div className="cursor-pointer" onClick={(e) => openCalendarSettingsModal()}>Change default calendar settings...</div>
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <Button 
                        color="secondary" 
                        onClick={() => props.closeAddEditModal()}
                    >
                        Cancel
                    </Button>
                    {isLoading ?
                        <Spinner size="sm" color="primary" /> :
                        <Button 
                            color="primary" 
                            type="submit"
                        >
                            {props.modalButtonName}
                        </Button>
                    }
                    {" "}
                </ModalFooter>
            </Form>
            {
                showOwnerConfirmationModal && 
                <ConfirmationModal
                    actionText={APP_CONSTANTS.CONFIRMATION_MODAL_ACTION_TEXTS}
                    onCancel={hideOwnerConfirmModal}
                    onConfirm={ownerConfirmed}
                    modalBody="<div>By setting the owner to someone other than yourself, this project will <strong>NOT</strong> appear in your list of available projects.</div>"
                    showModal={showOwnerConfirmationModal}
                    modalTitle="Confirm Owner Assignment"
                    primaryButtonColor="primary"
                    data="confirm"
                />
            }
            {
                showAddEditCalendarModal && 
                <AddEditCalendarModal
                    closeModal={setShowAddEditCalendarModal}
                    showModal={showAddEditCalendarModal}
                    modalTitle={addEditCalendarModalTitle}
                    calendarData={calendarData}
                    modalType={modalType}
                    confirmActionText={addEditCalendarModalActionText}
                    onSet={onDefaultCalendarSet}
                />
            }
        </Modal>
    )
}