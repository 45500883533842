import { API } from "aws-amplify";
import ENUMS from "constants/appEnums";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import GeneralUtils from "util/GeneralUtils";
import HttpUtil from "util/HttpUtil";
import './FeatureFeatureModal.css';

export default function FutureFeatureModal(props) {    
    // feature list array
    const [featureList, setFeatureList] = useState([]);
    const { addToast } = useToasts();

    // user details
    const user = useSelector(state => state.authReducer.user);

    /**
     * Get all future feature list
     */
    const fetchFeatureList = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.FUTURE_FEATURES_GET,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                userId: user.id
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                const list  = res.data.futureFeaturesList.map(feature => {
                    const userVoted = feature.Users.find(userData => userData.id === user.id);

                    return {
                        ...feature,
                        voted: userVoted ? true : false,
                        count: feature.Users.length
                    }
                });

                list.sort(GeneralUtils.sortByCount);
                setFeatureList(list);
            }
        })
        .catch(error => {
            if (error.response) {
                console.log(error.response);
                addToast(
                    "Failed to fetch feature list", 
                    { 
                        appearance: 'error',
						autoDismiss: true
                    }
                );
            }
        })        
    }

    const onConfirm = () => {
        const addVoteFeatureIds = featureList.filter(feature => feature.voted).map(f => f.id);
        const removeVoteFeatureIds = featureList.filter(feature => !feature.voted).map(f => f.id);

        API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.FUTURE_FEATURES_VOTE, 
        {
            ...HttpUtil.adminHttpHeaders(),
            body: 
            {
                addVoteFeatureIds,
                removeVoteFeatureIds,
                user_id: user.id
            },
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                addToast(
                    "Thank you for your vote.", 
                    { 
                        appearance: 'success',
						autoDismiss: true
                    }
                );
                props.onCancel()
            }
        })
        .catch(error => {
            if (error.response) {
                addToast(
                    "Failed to add vote.", 
                    { 
                        appearance: 'error',
						autoDismiss: true
                    }
                );
            }
        })
    }

    const voteFor = (voteFeatureId) => {
        const newFeatureList = featureList.map(feature => {
            return {
                ...feature,
                voted: (feature.id === voteFeatureId) ? !feature.voted : feature.voted,
            }
        });
        setFeatureList(newFeatureList);
    }

    useEffect(() => {
        fetchFeatureList();
    }, []);

    return (
        <Modal
            size="md"
            backdrop="static"
            isOpen={props.showModal}
            toggle={() => props.onCancel()}
        >
            <ModalHeader toggle={() => props.onCancel()} tag="h2">Future Features Vote</ModalHeader>
            <ModalBody>
                <p className="font-14">Cast your vote for the features that you want us to prioritize.</p>
                <div className="features-list">
                    {
                        featureList.map((feature,key) => {
                            return <div key={key} className="feature-wrapper justify-content-between">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="feature-vote-check" onClick={() => voteFor(feature.id)}>
                                        {
                                            feature.voted
                                            ? <i className="fas fa-thumbs-up voted"></i>
                                            : <i className="far fa-thumbs-up not-voted"></i>
                                        }
                                    </div>
                                    <div>
                                        <div className="feature-name">{feature.feature_name}</div>
                                        <div className="feature-desc">{feature.feature_description}</div>
                                    </div>
                                </div>
                                <div className="feature-count">
                                    {feature.count}
                                </div>
                            </div>
                        })
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                <Button 
                    color="secondary" 
                    onClick={() => props.onCancel()}
                >
                    Cancel
                </Button>
                <Button 
                    color="primary" 
                    onClick={() => onConfirm()}
                >
                    Submit Vote
                </Button>
            </ModalFooter>
            
        </Modal>
    )
}
