import React, { useState, useEffect } from "react";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader, 
    CardBody,
    Button,
    Input
} from "reactstrap";
import ENUMS from "constants/appEnums";
import CustomTablePrime from "components/CustomTable/CustomTablePrime";
import TableHeadersUtil from "util/TableHeadersUtil";
import { format } from 'date-fns';
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import { useToasts } from 'react-toast-notifications';
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import UserUtil from "util/UserUtil";
import TaskUtil from "util/TaskUtil";
import AddEditActionModal from "components/Action/AddEditActionModal/AddEditActionModal";
import DateTimeUtils from "util/DateTimeUtils";
import htmlToDraft from 'html-to-draftjs';
import { useSelector } from "react-redux";
import _ from "lodash";
import GeneralUtils from "util/GeneralUtils";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import InputGroupText from "reactstrap/lib/InputGroupText";
import AddEditStakeholderModal from "components/Stakeholder/AddEditStakeholderModal";
import parse from 'html-react-parser';
import ViewStakeholderModal from "components/Stakeholder/ViewStakeholderModal";
import AddEditIssueModal from "./Issue/AddEditIssueModal";
import TeamUtil from "util/TeamUtil";
import { MultiSelect } from 'primereact/multiselect';
import ViewRaidModal from "./ViewRaidModal";
import RULES from "rbac/rules";

export default function ProjectViewIssueTable (props) {

    const { addToast } = useToasts();

    // Team Data and Header for Table
    const [issueList, setIssueList] = useState([]);
    const [issueListHeader, setIssueListHeader] = useState([]);

    // Table props
    const [loading, setLoading] = useState(true);
    const [tableRef, setTableRef] = useState(null);
    
    const [quickFilterText, setQuickFilterText] = useState("");

    /**
     * Delete Modal
     */
    const [showDeleteIssueModal, setShowDeleteIssueModal] = useState(false);
    const [deleteIssueModalText, setDeleteIssueModalText] = useState("");
    const [selectedIssueId, setSelectedIssueId] = useState("");
    const [deleteModalIssueTexts, setDeleteModalIssueTexts] = useState({
        'confirm': ENUMS.BUTTON_TEXT.DELETE_ACTION_TEXT_CONFIRM,
        'cancel': ENUMS.BUTTON_TEXT.CANCEL
    });

    // Current User
    const user = useSelector(state => state.authReducer.user);

    /** ADD/EDIT issue modal */
    const [showAddEditIssueModal, setShowAddEditIssueModal] = useState(false);
    const [modalMode, setModalMode] = useState("");
    const [editIssueId, setEditIssueId] = useState(null);

    // editable table
    const [editTableText, setEditTableText] = useState(ENUMS.BUTTON_TEXT.EDIT_TABLE);
    const [tableEditMode, setTableEditMode] = useState(null);
    const [editedRows, setEditedRows] = useState([]);
    const [preEditTableData, setPreEditTableData] = useState([]);

    const [teamsList, setTeamsList] = useState([]);
    const [userList, setUserList] = useState([]);

    const [selectedCol, setSelectedCol] = useState([]);
    const [selectedColDropdown, setSelectedColDropdown] = useState([]);
    const [showSelectColumnDropDown, setShowSelectColumnDropDown] = useState(false);
    const [colOptionsList, setColOptionsList] = useState([]);

    /**
     * View Modal
     */
    const [viewIssueModalOpen, setViewIssueModalOpen] = useState(false);
    const [issueToView, setIssueToView] = useState(null);

    // TABLE filtering and sorting
    const [ogIssueList, setOgIssueList] = useState([]);
    const [colKeyList, setColKeyList] = useState(
        ['issue_id','created_at','team_name','description','status_value_field',
        'owner','severity_value_field','mitigation','due_date','mitigation_owner',
        'escalation_required','notes','note_view']
    );

    const populateIssueTable = () => {
        populateIssueHeaders();
        if (props.projectIssueList && 
            props.projectIssueList.length > 0) {
            populateIssueData();
        } else {
            setIssueList([]);
        }
    }

    const populateIssueHeaders = () => {
        /**
         * Columns object to be passed to table
         */
        const selectedColumnsDropdown = [];
        // the selected columns in the list
        const selectedColumns = [];
        // all the columns in the list
        const colOptions = [];
        // selected columns in state
        const stateColumns = [];

        for (var i = 0; i < TableHeadersUtil.projectViewIssueTableHeaders.length; i++) {
            const issueHeader = TableHeadersUtil.projectViewIssueTableHeaders[i];
            if (!issueHeader.hidden) {
                selectedColumnsDropdown.push({
                    label: issueHeader.headerName,
                    value: issueHeader.field
                });
                selectedColumns.push(issueHeader);
                stateColumns.push({...issueHeader, filterElement: ''});
            }
            colOptions.push({
                label: issueHeader.headerName,
                value: issueHeader.field
            });
        }
        setSelectedColDropdown(selectedColumnsDropdown);
        setSelectedCol(selectedColumns);
        setColOptionsList(colOptions);
        
        /**
         * Columns object to be passed to table
         */
        setIssueListHeader(TableHeadersUtil.projectViewIssueTableHeaders);
    }

    const populateIssueData = () => {
        const updatedIssueList = props.projectIssueList.map((issue, index) => {
            issue['issue_number_link'] = GeneralUtils.viewPageLink(
                issue.id,
                index +1,
                ENUMS.VIEW_PATH.ISSUE,
                '?issueId=' + issue.id,
                {passedId: issue.id}
            );
            issue['created_at'] = format(DateTimeUtils.convertTimezone(
                new Date(issue.createdAt), user), 
                DateTimeUtils.getUserDateTimeFormat(user.date_format, user.twenty_four_hour_clock))
            issue['team_name_value_field'] = issue.Team.id;
            issue['team_name'] = issue.Team.team_name;
            issue['description'] = issue.description;
            issue['status_value_field'] = issue.status;
            issue['status'] = ENUMS.RAID_STATUS[issue.status];
            issue['owner_value_field'] = issue.IssueOwner.id;
            issue['owner'] = UserUtil.formatUserForTableColumn(issue.IssueOwner);
            issue['severity_value_field'] = issue.severity;
            issue['severity'] = ENUMS.IMPACT[issue.severity];
            issue['mitigation'] = issue.mitigation;
            issue['due_date'] = format(DateTimeUtils.convertTimezone(
                new Date(issue.due_date), user), 
                DateTimeUtils.getUserDateTimeFormat(user.date_format, user.twenty_four_hour_clock));
            issue['mitigation_owner_value_field'] = issue.IssueOwner.id;
            issue['mitigation_owner'] = UserUtil.formatUserForTableColumn(issue.IssueMitigationOwner);
            issue['escalation_required_value_field'] = issue.escalation_required;
            issue['escalation_required'] = issue.escalation_required ? 'YES' : 'NO';
            issue['notes'] = issue.notes;
            issue['note_view'] = parse('<div class="table-note-display">' + issue.notes + '</div>');

            return issue;
        });
        setIssueList(updatedIssueList);
        setOgIssueList(updatedIssueList);
    }

    const openViewIssueModal = (data) => {
        setViewIssueModalOpen(true);
        setIssueToView(data);
    }


    const openDeleteIssueModal = (issue) => {
        setShowDeleteIssueModal(true);
        setDeleteIssueModalText(ENUMS.MODAL.DELETE_CONFIRMATION_TEXT + issue.description + " issue ?");
        setSelectedIssueId(issue.id);
    }

    const hideDeleteIssueModal = () => {
        setShowDeleteIssueModal(false);
    }

    const deleteIssueConfirmed = (issueId) => {
        API.del(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_ISSUE_DELETE, 
        {
            ...HttpUtil.adminHttpHeaders(),
            body:  {
                issue_id: issueId
            }
        })
        .then(res => {
            if(res && res.status === 200) {
                refreshPageOnSubmit("Issue has been deleted successfully");
            }
        })
        .catch(error => {
            if (error.response) {
                console.log(error.response);
                addToast(
                    "Failed to delete issue.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    /**
     * Fetches the user list for populating the dropdowns
     */
     const fetchUserList = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.USER_ALL_ORGANIZATION,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                project_id: props.projectDetails.id
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setUserList(res.data.userList);
            }
        })
        .catch(error => {
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch user list for owner selection", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const closeViewModal = () => {
        setViewIssueModalOpen(false);
    }

    /**
     * Method to handle opening the task modal in edit mode
     */
    const openEditIssueModal = (issue) => {
        setModalMode(ENUMS.MODAL.EDIT_MODE);
        setEditIssueId(issue.id);
        setShowAddEditIssueModal(true);
    }

    const optionsList = React.useMemo(() => 
    [
        {
            itemName: 'View',
            itemType: 'View',
            openViewModal: openViewIssueModal,
        },
        {
            itemName: 'Edit',
            itemType: 'Edit',
            openEditModal: openEditIssueModal,
            role: [RULES.ROLE_NAMES.PROJECT_OWNER, RULES.ROLE_NAMES.ISSUE_MITIGATION_OWNER, RULES.ROLE_NAMES.TEAM_LEADER, RULES.ROLE_NAMES.ISSUE_OWNER],
            perform: RULES.RULE_NAMES.ISSUE_EDIT,
        },
        {
            itemName: 'Delete',
            itemType: ENUMS.RENDER_OPTIONS_TYPE.DELETE,
            delete: openDeleteIssueModal,
            role: [RULES.ROLE_NAMES.PROJECT_OWNER, RULES.ROLE_NAMES.TEAM_LEADER, RULES.ROLE_NAMES.ISSUE_OWNER],
            perform: RULES.RULE_NAMES.ISSUE_DELETE,
        }
    ], []);

    /**
     * Function to handle custom global filtering
     * @param {*} value 
     */
    const handleGlobalFiltering = (value) => {
        setQuickFilterText(value);
        let filteredIssue = _.cloneDeep(ogIssueList);

        if (value !== '') {
            const lowerVal = value.toLowerCase();
            filteredIssue = filteredIssue.filter(issue => {
                return colKeyList.some(col => {
                    if (issue[col] !== null) {
                        return issue[col] && issue[col].toString().toLowerCase().includes(lowerVal);
                    }
                })
            });
        }
        setIssueList(filteredIssue);
    }

    /**
     * Method to handle the exporting to csv.
     * @param {Object} e 
     */
    const exportIssueList = (e) => {
        e.preventDefault();
        console.log(issueList)
        let csvContent = "data:text/csv;charset=utf-8,";
        let headers = "S.No.,Project ID,Project Name,Date Created,Team,Description,Status,Owner,Severity,Mitigation,Due Date,Mitigation Owner,Escalation Required\r\n";
        csvContent += headers;
        issueList.forEach((issue, index) => {
            let rowArray = [
                index + 1,
                props.projectDetails.id,
                GeneralUtils.handleStringForCSV(props.projectDetails.project_name),
                issue.created_at,
                issue.Team.team_name,
                GeneralUtils.handleStringForCSV(issue.description),
                issue.status_value_field,
                UserUtil.formatUserForTableColumn(issue.IssueOwner),
                issue.severity_value_field,
                issue.mitigation,
                issue.due_date,
                UserUtil.formatUserForTableColumn(issue.IssueMitigationOwner),
                issue.escalation_required
            ];
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", props.projectDetails.project_name + "_issues.csv");
        document.body.appendChild(link); // Required for FF
        link.click(); // This will download the data file named "my_data.csv".
    }

    /**
     * EDIT TABLE FUNCTIONS STARTS HERE
     */

     const updateTableData = () => {
        let rowsToEdit = _.cloneDeep(editedRows).filter(row => row !== null && row !== undefined);
        const tableEdits = rowsToEdit.map(row => {
            return {
                id: row.id,
                project_id: row.project_id,
                description: row.description,
                team_id: row.team_name_value_field,
                status: row.status_value_field,
                owner_id: row.owner_value_field,
                severity: row.severity_value_field,
                due_date: row.due_date,
                mitigation: row.mitigation,
                mitigation_owner_id: row.mitigation_owner_value_field,
                escalation_required: row.escalation_required_value_field,
                notes: row.notes,
            }
        });
        API.put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_ISSUE_UPDATE_TABLE_ROWS, 
        {
            ...HttpUtil.adminHttpHeaders(),
            body: 
            {
                editedRows: tableEdits,
                projectId: props.projectDetails.id
            }
        })
        .then(res => {
            if (res.status === 201 && res.data.success) {
                refreshPageOnSubmit("Issues have been updated successfully!");
                setEditedRows([]);
            }
        })
        .catch(error => {
            if (error.response) {
                addToast(
                    "Failed to update issues!", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const setEditableTable = () => {
        if (editTableText === ENUMS.BUTTON_TEXT.SAVE) {
            setEditTableText(ENUMS.BUTTON_TEXT.EDIT_TABLE);
            setTableEditMode(null);
            if (editedRows.length > 0) {
                updateTableData();
            }
            
        }
        else {
            setPreEditTableData(_.cloneDeep(issueList));
            setEditTableText(ENUMS.BUTTON_TEXT.SAVE);
            setTableEditMode(ENUMS.TABLE_CONSTANTS.EDIT_MODE_CELL);
        }
    }

    const setCancelEditableTable = () => {
        setIssueList(_.cloneDeep(preEditTableData));
        setEditTableText(ENUMS.BUTTON_TEXT.EDIT_TABLE);
        setTableEditMode(null);
    }

    const onEditorValueChange = (tableProps, value) => {
        let updatedProducts = _.cloneDeep(tableProps.value);
        if (tableProps.field === 'team_name' && value) {
            updatedProducts[tableProps.rowIndex]['team_name_value_field'] = value;
            updatedProducts[tableProps.rowIndex]['team_name'] = teamsList.find(t => t.value == value).label;
        }
        else if (tableProps.field === 'status' && value) {
            updatedProducts[tableProps.rowIndex]['status_value_field'] = value;
            updatedProducts[tableProps.rowIndex]['status'] = ENUMS.RAID_STATUS[value];
        }
        else if (tableProps.field === 'owner' && value) {
            updatedProducts[tableProps.rowIndex]['owner_value_field'] = value;
            updatedProducts[tableProps.rowIndex]['owner'] = userList.find(u => u.value == value).label;
        }
        else if (tableProps.field === 'severity' && value) {
            updatedProducts[tableProps.rowIndex]['severity_value_field'] = value;
            updatedProducts[tableProps.rowIndex]['severity'] = ENUMS.IMPACT[value];
        }
        else if (tableProps.field === 'due_date' && value) {
            updatedProducts[tableProps.rowIndex]['due_date'] = format(DateTimeUtils.convertTimezone(
                new Date(value), user), 
                DateTimeUtils.getUserDateTimeFormat(user.date_format, user.twenty_four_hour_clock));
        }
        else if (tableProps.field === 'mitigation_owner' && value) {
            updatedProducts[tableProps.rowIndex]['mitigation_owner_value_field'] = value;
            updatedProducts[tableProps.rowIndex]['mitigation_owner'] = userList.find(u => u.value == value).label;
        }
        else if (tableProps.field === 'escalation_required') {
            updatedProducts[tableProps.rowIndex]['escalation_required_value_field'] = value;
            updatedProducts[tableProps.rowIndex]['escalation_required'] = value ? 'YES' : 'NO';
        } else {
            updatedProducts[tableProps.rowIndex][tableProps.field] = value;
        }
        let updatedEditedRows = _.cloneDeep(editedRows);
        updatedEditedRows[tableProps.rowIndex] = updatedProducts[tableProps.rowIndex];
        setEditedRows(updatedEditedRows);
        setIssueList(updatedProducts);
    }

    const onEditorCancel = ({columnProps}, editingCellRows) => {
        const { rowIndex, field } = columnProps;
        let products = _.cloneDeep(issueList);
        products[rowIndex][field] = editingCellRows[rowIndex][field];
        delete editingCellRows[rowIndex][field];
        setIssueList(products);
    }

    /**
     * Method to handle the select dropdown of column ordering change
     * @param {Object} newValue 
     */
     const handleSelectChange = (e) => {
        const selected = e.value.map(val => colOptionsList.find(op => op.value === (val.value || val)));

        // update the column dropdown in the local storage
        setSelectedColDropdown(selected);
        
        let orderedSelectedColumns = TableHeadersUtil.projectViewIssueTableHeaders.filter(col => selected.some(sCol => sCol.value === col.field));
        setSelectedCol(orderedSelectedColumns);
        const stateColumns = orderedSelectedColumns.map(col => {
            return {
                ...col,
            }
        });
        setIssueListHeader(stateColumns);

        // TODO: set cols at local storage with sort order and f
    }

    /**
     * Method to reset the filters and sorting on the entire table. 
     */
    const resetTable = () => {
        tableRef.reset();
    }

    /**
     * Refreshes the page on submit of add/edit/delete form
     * @param {String} message 
     */
    const refreshPageOnSubmit = (message) => {
        setShowAddEditIssueModal(false);
        setShowDeleteIssueModal(false);
        props.refreshViewOnAction(ENUMS.TABS.ISSUES_LIST, message);
    }

    const openAddIssueModal = () => {
        setModalMode(ENUMS.MODAL.ADD_MODE);
        setShowAddEditIssueModal(true);
    }

    const closeAddEditIssueModal = () => {
        setShowAddEditIssueModal(false);
    }

    useEffect (() => {
        populateIssueTable();
        setLoading(false);
    }, [props.projectIssueList]);

    useEffect(() => {
        fetchUserList();
        setTeamsList(TeamUtil.formatTeamForDropdownBulk(props.projectDetails.Teams));
    }, []);

    return (
        <>
            <Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="bg-secondary box-shadow-down card-border-radius-0">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center mb-4">
                                <Col xs="2">
                                    <h3 className="mb-0">Issue List</h3>
                                </Col>
                                <Col className="text-right">
                                    {editTableText === ENUMS.BUTTON_TEXT.SAVE &&
                                        <Button
                                            color="secondary"
                                            onClick={setCancelEditableTable}
                                            size="md"
                                            disabled={issueList.length === 0}
                                        >
                                            Cancel Edits
                                        </Button>
                                    }
                                    <Button
                                        color="secondary"
                                        onClick={setEditableTable}
                                        size="md"
                                        disabled={issueList.length === 0}
                                    >
                                        {editTableText}
                                    </Button>
                                    <Button
                                        color="secondary"
                                        onClick={resetTable}
                                        size="md"
                                    >
                                        Reset Filters
                                    </Button>
                                    <Button
                                        color="secondary"
                                        onClick={e => exportIssueList(e)}
                                        size="md"
                                        disabled={issueList.length === 0}
                                    >
                                        Export
                                    </Button>
                                    <Button 
                                        size="md"
                                        color="primary"
                                        onClick={(e) => openAddIssueModal()}
                                    >
                                        Add Issue
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-2">
                                <Col className="text-right">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-search"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            size="md"
                                            type="text"
                                            id="quickFilterText"
                                            value={quickFilterText}
                                            onChange={e => handleGlobalFiltering(e.target.value)}
                                            placeholder="Search Table...">
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col xs="8" className="text-right">
                                    <Row className="justify-content-end align-items-center pr-3">
                                        <InputGroup className="w-auto align-items-center">
                                            {
                                                showSelectColumnDropDown
                                                ?   <div className="d-flex">
                                                        <MultiSelect 
                                                            value={selectedColDropdown.map(op => op.value)}
                                                            options={colOptionsList}
                                                            optionLabel="label"
                                                            selectedItemsLabel={"Selected Columns: " + selectedColDropdown.length}
                                                            onChange={(e) => handleSelectChange(e)}
                                                            style={{width:'14em'}}
                                                            className="selected-columns"
                                                            filter={true}
                                                        />
                                                        {/* <span onClick={(e) => setShowSelectColumnDropDown(false)}>
                                                            <i class="fat-remove"
                                                        </span> */}
                                                    </div>
                                                : <i 
                                                    id="select_columns"
                                                    className="fas fa-columns cursor-pointer hover-primary"
                                                    onClick={(e) => setShowSelectColumnDropDown(true)}
                                                ></i>
                                            }
                                            {
                                                showSelectColumnDropDown &&
                                                <i className="fas fa-times pl-2" onClick={(e) => setShowSelectColumnDropDown(false)}/>
                                            }
                                        </InputGroup>
                                    </Row>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <CustomTablePrime
                                columnDefs={issueListHeader} 
                                rowData={issueList}
                                loading={loading}
                                optionsList={optionsList}
                                tableRef={tableRef}
                                setTableRef={setTableRef}
                                onEditorValueChange={onEditorValueChange}
                                onEditorCancel={onEditorCancel}
                                editMode={tableEditMode}
                                userList={userList}
                                teamsList={teamsList}
                                user={user}
                                projectOwnerId={props.projectDetails.project_owner_id}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                showDeleteIssueModal && 
                <ConfirmationModal
                    actionText={deleteModalIssueTexts}
                    onCancel={hideDeleteIssueModal}
                    onConfirm={deleteIssueConfirmed}
                    modalBody={deleteIssueModalText}
                    showModal={showDeleteIssueModal}
                    modalTitle={ENUMS.MODAL.DELETE_ISSUE_TITLE}
                    data={selectedIssueId}
                />
            }
            {
                showAddEditIssueModal && 
                <AddEditIssueModal
                    modalTitle={modalMode === ENUMS.MODAL.ADD_MODE ? ENUMS.MODAL.ADD_ISSUE_TITLE : ENUMS.MODAL.EDIT_ISSUE_TITLE}
                    modalButtonName={ ENUMS.MODAL.ADD_MODE ? ENUMS.BUTTON_TEXT.ADD : ENUMS.BUTTON_TEXT.SAVE }
                    modalOpen={showAddEditIssueModal}
                    closeModal={closeAddEditIssueModal}
                    projectData={props.projectDetails}
                    modalMode={modalMode}
                    refreshPageOnSubmit={refreshPageOnSubmit}
                    editIssueId={editIssueId}
                /> 
            }
            { 
                viewIssueModalOpen && 
                <ViewRaidModal
                    modalTitle="Issue"
                    modalOpen={viewIssueModalOpen}
                    rowData={issueToView}
                    closeModal={closeViewModal}
                    list={TableHeadersUtil.projectViewIssueTableHeaders}
                /> 
            }
        </>
    )
}
