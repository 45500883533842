import React, { useState, useEffect, useRef } from "react";
import { 
    Modal, 
    ModalHeader,
    ModalBody,
    Card, 
    CardBody, 
    ModalFooter,
    Form, 
    FormGroup,
    Input, 
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button, 
    Label,
    Spinner,
    UncontrolledAlert,
    Collapse
} from "reactstrap";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import ENUMS from 'constants/appEnums';
import { useToasts } from 'react-toast-notifications';
import HttpUtil from "util/HttpUtil";
import { useForm } from 'react-hook-form';
import CustomErrorMessage from 'components/CustomErrorMessage/CustomErrorMessage';
import TeamUtil from 'util/TeamUtil';
import GroupUtil from 'util/GroupUtil';
import TaskUtil from "util/TaskUtil";
import DateTimeUtils from "util/DateTimeUtils";
import UserUtil from "util/UserUtil";
import { isBefore, isAfter, set } from "date-fns";
import ProjectUtil from "util/ProjectUtil";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker";
import GeneralUtils from "util/GeneralUtils";
import DataOptions from "constants/appDataOptions";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import RehearsalUtil from "util/RehearsalUtil";
import _ from "lodash";

export default function AddEditExecutionModeModal(props) {
    const { handleSubmit, errors } = useForm({
		mode: "onBlur"
    });

    const current_user = useSelector(state => state.authReducer.user);
    const { addToast } = useToasts();

    const [isExecutionModeDataLoading, setIsExecutionModeDataLoading] = useState(false);

    // loading and disabled
    const [isTypeLoading, setIsTypeLoading] = useState(true);
    const [isStatusLoading, setIsStatusLoading] = useState(true);
    const [isEnvOwnerLoading, setIsEnvOwnerLoading] = useState(true);

    // animated components
    const animatedComponents = makeAnimated();

    //data memebers
    const [name, setName] = useState('');
    const [type, setType] = useState();
    // const [status, setStatus] = useState();
    const [environmentName, setEnvironmentName] = useState("");
    const [environmentLink, setEnvironmentLink] = useState("");
    const [environmentOwner, setEnvironmentOwner] = useState();
    // const [environmentList, setEnvironmentList] = useState([]);
    
    const [typeList, setTypeList] = useState([]);
    // const [statusList, setStatusList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [isOpenEnvironmentInfo, setIsOpenEnvironmentInfo] = useState(false);
    const toggleEnvironmentInfo = () => setIsOpenEnvironmentInfo(!isOpenEnvironmentInfo);

    const [inviteEnvironmentOwner, setInviteEnvironmentOwner] = useState(null);
    const [openEnvironmentOwnerMenu, setOpenEnvironmentOwnerMenu] = useState(false);

    const [environmentDetails, setEnvironmentDetails] = useState(JSON.parse(JSON.stringify(
        RehearsalUtil.populateDefaultEnvironmentAdd)));

    /**
     * Closes the dropdown menu for owner and member select
     */
     const showEnvironmentOwnerMenu = (key) => {
        const newEnvironmentDetails = _.cloneDeep(environmentDetails);
        newEnvironmentDetails[key].openEnvironmentOwnerMenu = true;
        setEnvironmentDetails(newEnvironmentDetails);
    };

    /**
     * Closes the dropdown menu for owner and member select
     */
     const hideEnvironmentOwnerMenu = (key) => {
        const newEnvironmentDetails = _.cloneDeep(environmentDetails);
        newEnvironmentDetails[key].openEnvironmentOwnerMenu = false;
        setEnvironmentDetails(newEnvironmentDetails);
    };

    const formatEnvDropDownLabel = (inputValue) => {
        return "Invite - "+ inputValue;
    }

    /**
     * Closes the dropdown menu on input change for risk owner select
     * @param {Object} query 
     * @param {Obejct} param1 
     */
     const handleEnvironmentOwnerInputChange = (query, { action }, key) => {
        if (action === "input-change") {
            showEnvironmentOwnerMenu(key);
        }
    };

    /**
     * Sets the new value to the Environment owner and closes the select dropdown.
     * @param {Object} newValue 
     */
     const handleEnvironmentOwnerChange = (newValue, key) => {
        if(newValue == null) {
            setInviteEnvironmentOwner(null);
        }
        const newEnvironmentDetails = _.cloneDeep(environmentDetails);
        newEnvironmentDetails[key].environmentOwner = newValue;
        newEnvironmentDetails[key].openEnvironmentOwnerMenu = false;
        setEnvironmentDetails(newEnvironmentDetails);
    }

    const createOption = (label) => {
        return {
            label,
            value: label.toLowerCase()
        }
    };

    const handleInviteEnvironmentOwner = (inputValue, key) => {
        // TO-DO: Validate against email format
        if (GeneralUtils.validateEmail(inputValue)) {
            setInviteEnvironmentOwner(inputValue);
            const option = createOption(inputValue);
            setUserList([...userList, option]);
            const newEnvironmentDetails = _.cloneDeep(environmentDetails);
            newEnvironmentDetails[key].inviteEnvironmentOwner = option.value;
            newEnvironmentDetails[key].environmentOwner = option;
            newEnvironmentDetails[key].openEnvironmentOwnerMenu = false;
            setEnvironmentDetails(newEnvironmentDetails);
        }
        else {
            addToast(
                "Invalid format for email.", 
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
        }
    }

    const handleSaveButtonEnvironment = (key) => {
        const envDetails = JSON.parse(JSON.stringify(environmentDetails));
        envDetails[key].added = true;
        if (envDetails[envDetails.length - 1].added) {
            envDetails.push(JSON.parse(JSON.stringify(...RehearsalUtil.populateDefaultEnvironmentAdd)));
        }
        setEnvironmentDetails(envDetails);

    }

    const handleDeleteButtonEnvironment = (key) => {
        let envDetails = JSON.parse(JSON.stringify(environmentDetails));
        if (envDetails.length === 1) {
            setEnvironmentDetails(JSON.parse(JSON.stringify(
                RehearsalUtil.populateDefaultEnvironmentAdd)));
        }
        else {
            // TODO: Check to see if the last remove is empty or not and not delete it.
            envDetails.splice(key, 1);
            setEnvironmentDetails(JSON.parse(JSON.stringify(envDetails)));
        }
    }

    const handleEnvNameChange = (val, key) => {
        const newEnvironmentDetails = _.cloneDeep(environmentDetails);
        newEnvironmentDetails[key].environmentName = val;
        setEnvironmentDetails(newEnvironmentDetails);
    }

    const handleEnvLinkChange = (val, key) => {
        const newEnvironmentDetails = _.cloneDeep(environmentDetails);
        newEnvironmentDetails[key].environmentLink = val;
        setEnvironmentDetails(newEnvironmentDetails);
    }

    const environmentFormGroup = (key, env) => {
        return <React.Fragment key={key}>
            <FormGroup>
                <Label for="environmentName">Environment Name</Label>
                <InputGroup className="input-group-alternative mb-3">
                    <Input 
                        id="environmentName"
                        name="environmentName"
                        className="form-control-alternative"
                        type="text"
                        value={env.environmentName}
                        onChange={(e)=> handleEnvNameChange(e.target.value, key)}
                        autoComplete="off"
                    />
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <Label for="environmentLink">Environment Link</Label>
                <InputGroup className="input-group-alternative mb-3">
                    <Input 
                        id="environmentLink"
                        name="environmentLink"
                        className="form-control-alternative"
                        type="text"
                        value={env.environmentLink}
                        onChange={(e)=> handleEnvLinkChange(e.target.value, key)}
                        autoComplete="off"
                    />
                </InputGroup>
            </FormGroup>
            <FormGroup>
                    <Label for="environmentOwner">Environment Owner</Label>
                    <CreatableSelect
                        id="environmentOwner"
                        name="environmentOwner"
                        className="form-control-alternative"
                        // as={CreatableSelect}
                        // control={control}
                        // rules={{ required: "environment Owner is required." }}
                        isClearable
                        components={animatedComponents}
                        isDisabled={isEnvOwnerLoading}
                        isLoading={isEnvOwnerLoading}
                        onChange={(newValue)=>handleEnvironmentOwnerChange(newValue, key)}
                        onCreateOption={(e) => handleInviteEnvironmentOwner(e, key)}
                        createOptionPosition="first"
                        formatCreateLabel={formatEnvDropDownLabel}
                        options={userList}
                        value={env.environmentOwner}
                        onInputChange={(newValue, action) => handleEnvironmentOwnerInputChange(newValue, action, key)}
                        onFocus={(e) => showEnvironmentOwnerMenu(key)}
                        onBlur={e => hideEnvironmentOwnerMenu(key)}
                        menuIsOpen={env.openEnvironmentOwnerMenu}
                    />
                </FormGroup>
            <Button
                size="sm"
                color="primary"
                onClick={e => handleSaveButtonEnvironment(key, env)}
            >
                {env.added ? "Update" : "Save"}
            </Button>
            <Button
                size="sm"
                color="secondary"
                onClick={e => handleDeleteButtonEnvironment(key)}
            >
                Remove
            </Button>
            {env.added ? <hr className="my-4" /> : null}
        </React.Fragment>
    }

    const formatDropDownLabel = (inputValue) => {
        return "Create - "+ inputValue;
    }

    // API CALLS
    const handleRehearsalTypeCreation = (inputValue) => {
        // handle task group creation
        API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.REHEARSAL_TYPE_CREATE,
        {
            ...HttpUtil.adminHttpHeaders(),
            body: 
            {
                project_id: props.projectId,
                type: inputValue
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setType(RehearsalUtil.formatTypeForDropdown(res.data.typeCreated));
                setTypeList([...typeList, RehearsalUtil.formatTypeForDropdown(res.data.typeCreated)]);
            }
        })
        .catch(error => {
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to create type.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        });
    }

    // const handleRehearsalStatusCreation = (inputValue) => {
    //     // handle task group creation
    //     API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.REHEARSAL_STATUS_CREATE,
    //     {
    //         ...HttpUtil.adminHttpHeaders(),
    //         body: 
    //         {
    //             project_id: props.projectId,
    //             status: inputValue
    //         }
    //     })
    //     .then(res => {
    //         if (res.status === 200 && res.data.success) {
    //             setStatus(RehearsalUtil.formatStatusForDropdown(res.data.statusCreated));
    //             setStatusList([...statusList, RehearsalUtil.formatStatusForDropdown(res.data.statusCreated)]);
    //         }
    //     })
    //     .catch(error => {
    //         if (error.response) {
    //             console.error(error.response);
    //             addToast(
    //                 "Failed to create status.", 
    //                 { 
    //                     appearance: 'error',
    //                     autoDismiss: true
    //                 }
    //             );
    //         }
    //     });
    // }

    const handleConfirm = () => {
        if (props.modalMode === ENUMS.MODAL.EDIT_MODE) {
            API.put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.REHEARSAL_UPDATE,
                {
                    ...HttpUtil.adminHttpHeaders(),
                    body: 
                    {
                        id: props.editExecutionModeId,
                        name: name,
                        project_id: props.projectId,
                        type_id: type ? type.value : null,
                        status_id: null,
                        environmentDetails: environmentDetails.filter(e => e.added)
                    }
                })
                .then(res => {
                    if (res.status === 201 && res.data.success) {
                        props.refreshList(ENUMS.TABS.TASK_LIST, res.data.message);
                        props.closeModal(false);
                    }
                })
                .catch(error => {
                    // setIsSubmitting(false);
                    if (error.response) {
                        console.error(error.response);
                        addToast(
                            "Failed to update execution mode.", 
                            { 
                                appearance: 'error',
                                autoDismiss: true
                            }
                        );
                    }
                })
        } else {
            API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.REHEARSAL_CREATE,
                {
                    ...HttpUtil.adminHttpHeaders(),
                    body: 
                    {
                        name: name,
                        project_id: props.projectId,
                        type_id: type ? type.value : null,
                        status_id: null,
                        environmentDetails: environmentDetails.filter(e => e.added)
                    }
                })
                .then(res => {
                    if (res.status === 201 && res.data.success) {
                        props.refreshList(ENUMS.TABS.TASK_LIST, res.data.message);
                        props.closeModal(false);
                    }
                })
                .catch(error => {
                    // setIsSubmitting(false);
                    if (error.response) {
                        console.error(error.response);
                        addToast(
                            "Failed to create execution mode.", 
                            { 
                                appearance: 'error',
                                autoDismiss: true
                            }
                        );
                    }
                })

        }
    }

    /**
     * Populates the form data for edit mode.
     */
     const populateFormData = () => {
        setIsExecutionModeDataLoading(true);
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.REHEARSAL_GET_BY_ID,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                rehearsal_id: props.editExecutionModeId
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                console.log(res.data)
                const rehearsalData = res.data.rehearsal;
                setName(rehearsalData.name);
                setType(RehearsalUtil.formatTypeForDropdown(rehearsalData.Rehearsal_Type));
                const envData = rehearsalData.Rehearsal_Environments.map(env => {
                    return {
                        id: env.id,
                        environmentName: env.name,
                        environmentLink: env.link,
                        environmentOwner: UserUtil.formatUserForDropdown(env.User),
                        openEnvironmentOwnerMenu: false,
                        added: true
                    }
                });
                setEnvironmentDetails(envData);
            }
        })
        .catch(error => {
            setIsExecutionModeDataLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch assumption data for edit. Please try again.", 
                    { 
                        appearance: 'error',
						autoDismiss: true
                    }
                );
            }
        })
    }

    const getRehearsalTypes = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.REHEARSAL_TYPE_GET,
            {
                ...HttpUtil.adminHttpHeaders(),
                queryStringParameters: 
                {
                    project_id: props.projectId
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success) {
                    setTypeList(RehearsalUtil.formatTypeForDropdownBulk(res.data.data));
                    setIsTypeLoading(false);
                }
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        "Failed to get type.", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            });
    };

    // const getRehearsalStatuses = () => {
    //     API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.REHEARSAL_STATUS_GET,
    //         {
    //             ...HttpUtil.adminHttpHeaders(),
    //             queryStringParameters: 
    //             {
    //                 project_id: props.projectId
    //             }
    //         })
    //         .then(res => {
    //             if (res.status === 200 && res.data.success) {
    //                 setStatusList(RehearsalUtil.formatStatusForDropdownBulk(res.data.data));
    //                 setIsStatusLoading(false);
    //             }
    //         })
    //         .catch(error => {
    //             if (error.response) {
    //                 console.error(error.response);
    //                 addToast(
    //                     "Failed to get status.", 
    //                     { 
    //                         appearance: 'error',
    //                         autoDismiss: true
    //                     }
    //                 );
    //             }
    //         });
    // };

    /**
     * Fetches the user list for populating the dropdowns
     */
     const fetchUserList = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.USER_ALL_ORGANIZATION,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                project_id: props.projectId,
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setUserList(res.data.userList);
                setIsEnvOwnerLoading(false);
            }
        })
        .catch(error => {
            setIsEnvOwnerLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch user list for owner selection", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    useEffect(() => {
        getRehearsalTypes();
        // getRehearsalStatuses();
        fetchUserList();
    }, []);

    useEffect(() => {
        if (props.modalMode === ENUMS.MODAL.EDIT_MODE) {
            populateFormData();
        }
    }, [props.editExecutionModeId]);

	return (
		<Modal
			size="md"
			isOpen={props.showModal}
			toggle={() => props.closeModal(false)}
            backdrop="static"
		>
			<ModalHeader toggle={() => props.closeModal(false)} tag="h3">
				{props.modalTitle}
			</ModalHeader>
            <Form role="form" onSubmit={handleSubmit(handleConfirm)}>
                <ModalBody className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-4 py-lg-4">
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <InputGroup className="input-group-alternative mb-3">
                                    <Input 
                                        id="name"
                                        name="name"
                                        className="form-control-alternative"
                                        type="text"
                                        value={name}
                                        onChange={(e)=> setName(e.target.value)}
                                        autoComplete="off"
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label for="type">Execution type</Label>
                                <CreatableSelect
                                    id="type"
                                    className="form-control-alternative"
                                    isClearable
                                    components={animatedComponents}
                                    isDisabled={isTypeLoading}
                                    isLoading={isTypeLoading}
                                    onChange={(newValue)=>setType(newValue)}
                                    onCreateOption={handleRehearsalTypeCreation}
                                    createOptionPosition="first"
                                    formatCreateLabel={formatDropDownLabel}
                                    options={typeList}
                                    value={type}
                                />
                            </FormGroup>
                            {/* <FormGroup>
                                <Label for="status">Rehearsal status</Label>
                                <CreatableSelect
                                    id="status"
                                    className="form-control-alternative"
                                    isClearable
                                    components={animatedComponents}
                                    isDisabled={isStatusLoading}
                                    isLoading={isStatusLoading}
                                    onChange={(newValue)=>setStatus(newValue)}
                                    onCreateOption={handleRehearsalStatusCreation}
                                    createOptionPosition="first"
                                    formatCreateLabel={formatDropDownLabel}
                                    options={statusList}
                                    value={status}
                                />
                            </FormGroup> */}
                            <Button block color="default" onClick={toggleEnvironmentInfo} style={{ marginBottom: '1rem' }}>
                                    Environment Information
                            </Button>
                            <Collapse isOpen={isOpenEnvironmentInfo}>
                                {
                                    environmentDetails.length > 0 && environmentDetails.map((env,key) => {
                                        return environmentFormGroup(key, env);
                                    })
                                }
                            </Collapse>
                        </CardBody>
                    </Card>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={() => props.closeModal(false)}>
						Cancel
					</Button>
					<Button color="primary" type="submit">
						Save
					</Button>{" "}
				</ModalFooter>
			</Form>
		</Modal>
	);
}
