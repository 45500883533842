import ProjectView from "components/Project/ProjectView/ProjectView";
import ProjectHome from "components/Project/ProjectHome/ProjectHome";
import ProjectSettings from "components/Project/ProjectSettings/ProjectSettings";
import TeamsView from "components/Team/TeamsView/TeamsView";
import UserProfile from "components/User/UserProfile/UserProfile";
import UserSettings from "components/User/UserSettings/UserSettings";
import Register from "components/Register/Register";
import Login from "components/Login/Login";
import GoogleLogin from "components/GoogleLogin/GoogleLogin";
import InviteUserConfirm from "components/InviteUserConfirm/InviteUserConfirm";
import TaskView from "components/Task/TaskView/TaskView";
import ResetPasswordRequest from "components/ResetPassword/ResetPasswordRequest";
import ResetPasswordConfirm from "components/ResetPassword/ResetPasswordConfirm";
import GanttReport from "components/Reports/GanttReport/GantReport";
import CalendarReport from "components/Reports/CalendarReport/CalendarReport";
import Index from "views/Index";
import Subscribe from "components/Subscribe/Subscribe";
import DataFix from "components/DataFix/DataFix";
import TwoFactorAuthentication from "components/TwoFactorAuthentication/TwoFactorAuthentication";
import Settings from "components/Settings/Settings";
import ENUMS from "constants/appEnums";
import ActionView from "components/Action/ActionView/ActionView";
import RiskView from "components/RAID/Risk/RiskView/RiskView";
import IssueView from "components/RAID/Issue/IssueView/IssueView";
import AssumptionView from "components/RAID/Assumption/AssumptionView/AssumptionView";
import DependencyView from "components/RAID/Dependency/DependencyView/DependencyView";
import ProjectMilestone from "components/ProjectMilestone/ProjectMilestone";
import VerifyUser from "components/VerifyUser/VerifyUser";
import ExecutionModeView from "components/ExecutionModes/ExecutionModeView/ExecutionModeView";
import { ContactUs } from "components/ContactUs/ContactUs";

var routes = [
	// {
	// 	path: "/dashboard",
	// 	name: "Dashboard",
	// 	icon: "ni ni-tv-2 text-primary",
	// 	component: Dashboard,
    //     layout: ENUMS.ROUTES.ADMIN,
    //     menuItem: true,
	// },
	{
		path: "/projects-home",
		name: "Projects",
		icon: "ni ni-planet text-blue",
		component: ProjectHome,
        layout: ENUMS.ROUTES.ADMIN,
        menuItem: true,
	},
	{
		path: "/projects/projects-view",
		name: "Project View",
		icon: "ni ni-pin-3 text-orange",
		component: ProjectView,
		layout: ENUMS.ROUTES.ADMIN,
	},
	{
		path: "/projects/project-settings",
		name: "Project Settings",
		icon: "ni ni-single-02 text-yellow",
		component: ProjectSettings,
		layout: ENUMS.ROUTES.ADMIN,
	},
	{
		path: "/teams-home",
		name: "Teams",
		icon: "ni ni-vector text-info",
		component: TeamsView,
        layout: ENUMS.ROUTES.ADMIN,
        menuItem: true,
	},
	{
		path: "/tasks/task-view",
		name: "Task View",
		icon: "ni ni-pin-3 text-orange",
		component: TaskView,
		layout: ENUMS.ROUTES.ADMIN,
	},
	{
		path: "/projects/milestone",
		name: "Project Milestone",
		icon: "ni ni-pin-3 text-orange",
		component: ProjectMilestone,
		layout: ENUMS.ROUTES.ADMIN,
	},
	{
		path: "/actions/action-view",
		name: "Action View",
		icon: "ni ni-pin-3 text-orange",
		component: ActionView,
		layout: ENUMS.ROUTES.ADMIN,
	},
	{
		path: "/risks/risk-view",
		name: "Risk View",
		icon: "ni ni-pin-3 text-orange",
		component: RiskView,
		layout: ENUMS.ROUTES.ADMIN,
	},
	{
		path: "/issues/issue-view",
		name: "Issue View",
		icon: "ni ni-pin-3 text-orange",
		component: IssueView,
		layout: ENUMS.ROUTES.ADMIN,
	},
	{
		path: "/assumptions/assumption-view",
		name: "Assumption View",
		icon: "ni ni-pin-3 text-orange",
		component: AssumptionView,
		layout: ENUMS.ROUTES.ADMIN,
	},
	{
		path: "/dependencies/dependency-view",
		name: "Dependency View",
		icon: "ni ni-pin-3 text-orange",
		component: DependencyView,
		layout: ENUMS.ROUTES.ADMIN,
	},
	{
		path: "/execution-mode-view",
		name: "Execution mode view",
		icon: "ni ni-pin-3 text-orange",
		component: ExecutionModeView,
		layout: ENUMS.ROUTES.ADMIN,
	},
	{
		path: "/projects/gantt-report",
		name: "Gantt report",
		icon: "ni ni-bullet-list-67 text-red",
		component: GanttReport,
        layout: ENUMS.ROUTES.ADMIN,
        menuItem: false,
	},
	{
		path: "/projects/calendar-report",
		name: "Calendar report",
		icon: "ni ni-bullet-list-67 text-red",
		component: CalendarReport,
        layout: ENUMS.ROUTES.ADMIN,
        menuItem: false,
	},
	{
		path: "/user-profile",
		name: "User Profile",
		icon: "ni ni-single-02 text-yellow",
		component: UserProfile,
		layout: ENUMS.ROUTES.ADMIN,
	},
	{
		path: "/user-settings",
		name: "User Settings",
		icon: "ni ni-single-02 text-yellow",
		component: UserSettings,
		layout: ENUMS.ROUTES.ADMIN,
	},
	{
		path: "/home",
		name: "Landing Page",
		icon: "ni ni-key-25 text-info",
		component: Index,
		layout: ENUMS.ROUTES.AUTH,
	},
	{
		path: "/contact-us",
		name: "Contact Us",
		icon: "ni ni-key-25 text-info",
		component: ContactUs,
		layout: ENUMS.ROUTES.AUTH,
	},
	{
		path: "/login",
		name: "Login",
		icon: "ni ni-key-25 text-info",
		component: Login,
		layout: ENUMS.ROUTES.AUTH,
	},
	{
		path: "/register",
		name: "Register",
		icon: "ni ni-circle-08 text-pink",
		component: Register,
		layout: ENUMS.ROUTES.AUTH,
    },
	{
		path: "/subscribe",
		name: "Subscribe",
		icon: "ni ni-circle-08 text-pink",
		component: Subscribe,
		layout: ENUMS.ROUTES.ADMIN,
    },
    {
		path: "/google-login",
		name: "Google Login",
		icon: "ni ni-key-25 text-info",
		component: GoogleLogin,
		layout: ENUMS.ROUTES.AUTH,
    },
    {
		path: "/invite-user-confirm",
		name: "Invitation Confirmation",
		icon: "ni ni-key-25 text-info",
		component: InviteUserConfirm,
		layout: ENUMS.ROUTES.AUTH,
    },
	{
		path: "/verify-user",
		name: "Verify user",
		icon: "ni ni-key-25 text-info",
		component: VerifyUser,
		layout: ENUMS.ROUTES.AUTH,
    },
    {
		path: "/reset-password",
		name: "Reset Password",
		icon: "ni ni-key-25 text-info",
		component: ResetPasswordRequest,
		layout: ENUMS.ROUTES.AUTH,
    },
    {
		path: "/reset-password-confirm",
		name: "Reset Password Confirm",
		icon: "ni ni-key-25 text-info",
		component: ResetPasswordConfirm,
		layout: ENUMS.ROUTES.AUTH,
	},
	{
		path: "/settings",
		name: "Settings",
		icon: "ni ni-settings-gear-65 text-default",
		component: Settings,
        layout: ENUMS.ROUTES.ADMIN,
        menuItem: true,
	},
	{
		path: "/cop-data-fix",
		name: "Data Fix",
		icon: "ni ni-settings-gear-65 text-default",
		component: DataFix,
        layout: ENUMS.ROUTES.ADMIN,
	},
	{
		path: "/2fa-verification",
		name: "Verify token",
		icon: "ni ni-key-25 text-info",
		component: TwoFactorAuthentication,
		layout: ENUMS.ROUTES.AUTH,
    },
];
export default routes;
