import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import NotFound from "components/NotFound/NotFound";
import ENUMS from 'constants/appEnums';
import { AppContext } from "util/ContextUtil";
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import allActions from 'redux/actions/index';
import { useToasts } from 'react-toast-notifications';
import AuthUtil from "util/AuthUtil";

export default function Application () {
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);

    useEffect(() => {
        onLoad();
    }, []);
      
    async function onLoad() {
        if (localStorage.getItem('JWT')) {
            AuthUtil.setTheme();
            console.log('in side jwt token');
            API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.AUTH_STATUS,
            {
                ...HttpUtil.adminHttpHeaders()
            })
            .then(res => {
                dispatch(allActions.authActions.setUserState(res.data.user));
                dispatch(allActions.authActions.setUserProjectList(res.data.projectList));
                userHasAuthenticated(true);
                setIsAuthenticating(false);
            })
            .catch(error => {
                userHasAuthenticated(false);
                setIsAuthenticating(false);
                if (error.response) {
                    if (error.response.data.message === 'jwt expired') {
                        localStorage.removeItem('JWT');
                        addToast("Your login token has expired. Please login again!", { appearance: 'info' });
                    }
                    else {
                        // addToast(
                        //     error.response.data ? 
                        //         error.response.data.message : 
                        //         "Failed to fetch user details. Please login again!", 
                        //     { 
                        //         appearance: 'error',
                        //         autoDismiss: true
                        //     }
                        // );
                    }
                    
                    console.error(error.response.data.message);
                }
            })
        }
        else {
            setIsAuthenticating(false);
        }
    }       

    return (
        <>
            { !isAuthenticating &&
            <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
                <BrowserRouter>
                    <Switch>
                        <Route path={ENUMS.ROUTES.ADMIN} render={props => <AdminLayout {...props} isAuthenticated={isAuthenticated} />} />
                        <Route path={ENUMS.ROUTES.AUTH} render={props => <AuthLayout {...props} isAuthenticated={isAuthenticated} />} />
                        <Redirect from="/" to="/auth/home" />
                        <Route render={props => <NotFound {...props} />} />
                    </Switch>
                </BrowserRouter>
            </AppContext.Provider>
            }
        </>
    )
}
