const formatFileTypeForDropdown = (fileType) => {
    if (fileType.id && fileType.type_name) {
        return {
            value: fileType.id,
            label: fileType.type_name
        };
    }
};

const formatFileTypeForDropdownBulk = (fileTypeList) => {
    return fileTypeList.map(fileType => {
        return {...formatFileTypeForDropdown(fileType)};
    });
};

const humanFileSize = (size) => {
    var i = ( size === 0 ) ? 0 : Math.floor( Math.log(size) / Math.log(1024) );
    return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

const extractFileExtension = (fileName) => {
    return fileName.split('.').pop();
}

const FileUtil = {
    humanFileSize,
    formatFileTypeForDropdown,
    formatFileTypeForDropdownBulk,
    extractFileExtension
};

export default FileUtil;