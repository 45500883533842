import React, { useState } from "react";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import './FullPageCalendar.css';
import {
    UncontrolledTooltip,
    Badge
} from "reactstrap";

export default function FullPageCalendar (props) {
    const [weekendsVisible, setWeekendsVisible] = useState(false);

    const renderEventContent = (eventInfo) => {
        return (
          <>
            <div className="p-2 fc-event-card">
                <div className="label">
                {/* TODO: color acc to label */}
                </div>
                <div className="section-data" id={"calendar_task_tooltip-" + eventInfo.event.title}> 
                    {
                        eventInfo.event.taskHolidays &&
                        <i className="pl-2 fas fa-exclamation-triangle fs-11 warning-color"></i>
                    }
                    <b>{eventInfo.event.title}</b>
                    &nbsp;
                    <i>{eventInfo.event.extendedProps.description}</i>
                </div>
                <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target={"calendar_task_tooltip-" + eventInfo.event.title}
                    trigger="click"
                    className="calendar_click_details"
                >
                    <div>{eventInfo.event.title} - {eventInfo.event.extendedProps.description}</div> 

                    <div>Starts on - {eventInfo.event.extendedProps.task_start}</div>

                    <div>End by - {eventInfo.event.extendedProps.task_end}</div>

                    <div>Task Owner - {eventInfo.event.extendedProps.owner}</div>

                    <div>Duration - {eventInfo.event.extendedProps.duration}</div>

                    <div>Is Critical Path? - {eventInfo.event.extendedProps.critical_path}</div>
                </UncontrolledTooltip>
            </div>
          </>
        )
    }

    const handleWeekendsToggle = () => {
        setWeekendsVisible(!weekendsVisible)
    }

    return (
        <>
            <div className="weekendsCheckbox">
                <input
                    type='checkbox'
                    checked={weekendsVisible}
                    onChange={handleWeekendsToggle}
                    className="mr-2"
                />
                    Weekends Visible
            </div>
            <FullCalendar
                plugins={[ dayGridPlugin, timeGridPlugin ]}
                themeSystem='bootstrap'
                headerToolbar={props.headerToolbar}
                weekends={weekendsVisible}
                initialView="dayGridMonth"
                initialEvents={props.events}
                eventContent={renderEventContent}
            />
        </>
    )    
}