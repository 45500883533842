import React, { useState, useEffect } from "react";
import Header from "components/Headers/Header";
import { useSelector } from "react-redux";
import CustomTablePrime from "components/CustomTable/CustomTablePrime";
import { Container, Row, Col, Card, CardHeader, Button, CardBody } from "reactstrap";
import ProjectAddEditModal from "components/Project/ProjectAddEditModal/ProjectAddEditModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import ENUMS from "constants/appEnums";
import { useToasts } from 'react-toast-notifications';
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import UserUtil from "util/UserUtil";
import TableHeadersUtil from "util/TableHeadersUtil";
import ProjectUtil from "util/ProjectUtil";
import RULES from "rbac/rules";
import APP_CONSTANTS from "constants/appConstants";
import { useHistory } from "react-router-dom";

export default function ProjectsHome() {
    const history = useHistory();
    const { addToast } = useToasts();
    /**
     * Rows object to be passed to table
     */
    const [projectRows, setProjectRows] = useState([]);
    const [columns, setColumns] = useState([]);
    /**
     * Add/Edit Modal
     */
    const [addEditModalOpen, setAddEditModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalButtonName, setModalButtonName] = useState("");
    const [modalMode, setModalMode] = useState("");
    const [editProjectData, setEditProjectData] = useState({});
    
    /**
     * Delete Modal
     */
    const [deleteModalText, setDeleteModalText] = useState("");
    const [deleteModalTitle, setDeleteModalTitle] = useState("");
    const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
    const [projectIdToDelete, setProjectIdToDelete] = useState("");

    /**
     * Table properties
     */
    const [tableRef, setTableRef] = useState(null);
    const [loading, setLoading] = useState(true);

    // grab current state
    const user = useSelector(state => state.authReducer.user);

    /**
	 * Call to load projects when user info is available
	 *
	 * @param {object}
	 */
    const loadProjects = () => {
        getAllProjectsByUserId(user.id);
    };

    /**
     * Populates the task data.
     * Populates the name and owner.
     */
    const populateTaskData = (userProjects) => {
        const updatedProjects = userProjects.map((project, index) => {
            project['project_count'] = index + 1;
            project['project_name'] = ProjectUtil.formatProjectName(
                project.id, project.project_name);
            project['project_owner'] = UserUtil.formatUserForTableColumn(
                project.User);
            return project;
        });
        setProjectRows(updatedProjects);
    }

    /**
	 * get all projects assigned to the user
	 *
	 * @param {number} userId
	 */
    const getAllProjectsByUserId = (userId) => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_GET_ALL,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                user_id: userId
            }
        })
        .then(res => {
            if(res.status === 200 && res.data.success) {
                populateTaskData(res.data.userProjects);
                setLoading(false);
            }
        })
        .catch(error => {
            console.log(error);
        })
    }

    /**
     * Open the add modal
     * @param {Event} e 
     */
    const openAddModal = (e) => {
        setModalTitle(ENUMS.MODAL.ADD_PROJECT_TITLE);
        setModalButtonName(ENUMS.BUTTON_TEXT.ADD);
        setModalMode(ENUMS.MODAL.ADD_MODE);
        setAddEditModalOpen(true);
    };

    /**
     * Opens the delete project
     * @param {Object} data 
     */
    const deleteProject = (data) => {
        setShowDeleteProjectModal(true);
        setDeleteModalTitle(ENUMS.MODAL.DELETE_PROJECT_TITLE + data.project_name.props.children);
        setDeleteModalText(ENUMS.MODAL.DELETE_CONFIRMATION_TEXT + data.project_name.props.children + "?");
        setProjectIdToDelete(data.id);
    }

    /**
     * Opens the edit project
     * @param {Object} data 
     */
    const openEditModal = (data) => {
        setModalTitle(ENUMS.MODAL.EDIT_PROJECT_TITLE);
        setModalButtonName(ENUMS.BUTTON_TEXT.SAVE);
        setModalMode(ENUMS.MODAL.EDIT_MODE);
        setEditProjectData(data);
        setAddEditModalOpen(true);
    };

    /**
     * Closes the add/edit modal
     * @param {Event} e 
     */
    const closeAddEditModal = (e) => {
        setAddEditModalOpen(false);
    };

    /**
     * Refreshes the project home view
     */
    const refreshPageOnSubmit = () => {
        loadProjects();
        setAddEditModalOpen(false);
    }
    
    /**
     * Closes the delete project modal
     */
    const hideDeleteProjectModal = () => {
        setShowDeleteProjectModal(false);
    };

    /**
     * Method to delete the project upon confirmation
     * @param {Integer} projectId 
     */
    const deleteProjectConfirmed = (projectId) => {
        API.del(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.DELETE_PROJECT, 
        {
            ...HttpUtil.adminHttpHeaders(),
            body: {
                project_id: projectId
            }
        })
        .then(res => {
            if(res && res.status === 200) {
                addToast('Project has been deleted successfully!', { 
                    appearance: 'success',
                    autoDismiss: true
                });
                loadProjects();
                setShowDeleteProjectModal(false);
            }
        })
        .catch(error => {
            if (error.response) {
                console.log(error.response);
                addToast(
                    "Failed to delete project.", 
                    { 
                        appearance: 'error',
						autoDismiss: true
                    }
                );
            }
        })
    };

    const optionsList = [
        {
            itemName: 'Select',
            itemType: 'Select',
            selectRedirectionLink: '/admin/projects/projects-view',
            selectSearchPath: '?projectId=',
        },
        {
            itemName: 'Edit',
            itemType: 'Edit',
            openEditModal: openEditModal,
            role: [RULES.ROLE_NAMES.PROJECT_OWNER, RULES.ROLE_NAMES.PROJECT_ADMIN],
            perform: RULES.RULE_NAMES.PROJECT_EDIT,
        },
        {
            itemName: 'Delete',
            itemType: 'Delete',
            deleteProject: deleteProject,
            role: [RULES.ROLE_NAMES.PROJECT_OWNER, RULES.ROLE_NAMES.PROJECT_ADMIN],
            perform: RULES.RULE_NAMES.PROJECT_DELETE,
        }
    ];

    useEffect (() => {
        setColumns(TableHeadersUtil.projectHomeTableHeaders);
        if (user && user.id && localStorage.getItem('JWT'))
            loadProjects();
    }, [user.id, localStorage.getItem('JWT')]);

    useEffect(() => {
        console.log(user, '!!@@##')
        if (
            user.User_Licence && user.User_Licence.licence_type != 'collaborator'
            && user.Subscription.length < 1
        ) {
            history.push('/admin/subscribe');
        }
    }, [])

    return (
        <React.Fragment>
            <Header/>
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Project List</h3>
                                    </Col>
                                    <Col className="text-right" xs="4">
                                        {
                                            user.User_Licence && user.User_Licence.licence_type != "collaborator" &&
                                            <Button
                                                color="primary"
                                                onClick={e => openAddModal(e)}
                                            >
                                                Add Project
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <CustomTablePrime
                                    columnDefs={columns} 
                                    rowData={projectRows}
                                    loading={loading}
                                    optionsList={optionsList}
                                    tableRef={tableRef}
                                    setTableRef={setTableRef}
                                    stateStorageName={ENUMS.DT_STATE_STORAGE_NAMES.PROJECT_HOME_TABLE}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            { 
                addEditModalOpen && 
                <ProjectAddEditModal 
                    modalTitle={modalTitle}
                    modalButtonName={modalButtonName}
                    modalMode={modalMode}
                    modalOpen={addEditModalOpen} 
                    editProjectData={editProjectData}
                    closeAddEditModal={closeAddEditModal}
                    refreshPageOnSubmit={refreshPageOnSubmit}
                /> 
            }
            {
                showDeleteProjectModal && 
                <ConfirmationModal
                    actionText={APP_CONSTANTS.DELETE_MODAL_ACTION_TEXTS}
                    onCancel={hideDeleteProjectModal}
                    onConfirm={deleteProjectConfirmed}
                    modalBody={deleteModalText}
                    showModal={showDeleteProjectModal}
                    modalTitle={deleteModalTitle}
                    data={projectIdToDelete}
                />
            }
        </React.Fragment>
    );
}
