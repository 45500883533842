import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ENUMS from 'constants/appEnums';
import { useToasts } from 'react-toast-notifications';
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import { 
    Row, 
    Col, 
    Container, 
    Card, 
    CardHeader, 
    Button
} from "reactstrap";
import AuthNavbar from "components/Navbars/AuthNavbar";

/**
 * Function to verify users
 * @param {Props} props 
 */
export default function VerifyUser (props) {
	let history = useHistory();
    const { addToast } = useToasts();

    const [verified, setVerified] = useState(false);

    const verifyToken = (user_token) => {
        API.post(ENUMS.AWS.API_NAME,ENUMS.API_ROUTES.VERIFY_USER,
            {
                ...HttpUtil.authHttpHeaders,
                body: 
                {
                    user_token
                }
            }
        )
        .then((res) => {
            if (res.status === 200 && res.data.success) {
                setVerified(true);
            } else {
                addToast("There was some issue while verifying. Please try again!", { 
                    appearance: 'error',
                    autoDismiss: true
                });
            }
        })
        .catch((error) => {
            addToast(error.data.message, { 
                appearance: 'error',
                autoDismiss: true
            });
        });
    }

    useEffect(() => {
        console.log("here");
        // fetching the invite user token
        const search = props.location.search;
        const params = new URLSearchParams(search);
        const user_token = params.get('token');
        verifyToken(user_token);
	}, []);

	/**
	 * Redirect to Login
	 */
	const redirectToLogin = () => {
		history.push("/auth/login");
	};

	return (
		<>
            <Container className="mt-5 pb-5">
                <Row className="justify-content-center">
                    <Col lg="6" md="8">
                        <Card className="bg-secondary shadow border-0">
                            <CardHeader className="bg-transparent pb-5">
                                <div className="text-center fs-20">Account verified</div>
                                <div className="text-center">Thank you for verifying your account.</div>
                                <div className="text-center">
                                        <Button
                                            className="mt-4"
                                            color="primary"
                                            onClick={(e) => redirectToLogin()}
                                        >
                                            Login
                                        </Button>
                                    </div>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
            </Container>
		</>
	);
}