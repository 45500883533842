import React, { useState, useEffect } from "react";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader, 
    CardBody,
    Button,
    Input
} from "reactstrap";
import ENUMS from "constants/appEnums";
import CustomTablePrime from "components/CustomTable/CustomTablePrime";
import TableHeadersUtil from "util/TableHeadersUtil";
import { format } from 'date-fns';
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import { useToasts } from 'react-toast-notifications';
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import UserUtil from "util/UserUtil";
import TaskUtil from "util/TaskUtil";
import AddEditActionModal from "components/Action/AddEditActionModal/AddEditActionModal";
import DateTimeUtils from "util/DateTimeUtils";
import htmlToDraft from 'html-to-draftjs';
import { useSelector } from "react-redux";
import _ from "lodash";
import GeneralUtils from "util/GeneralUtils";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import InputGroupText from "reactstrap/lib/InputGroupText";
import AddEditStakeholderModal from "components/Stakeholder/AddEditStakeholderModal";
import parse from 'html-react-parser';
import ViewStakeholderModal from "components/Stakeholder/ViewStakeholderModal";

export default function ProjectViewStakeholderTable (props) {

    const { addToast } = useToasts();

    // Team Data and Header for Table
    const [stakeholderList, setStakeholderList] = useState([]);
    const [stakeholderListHeader, setStakeholderListHeader] = useState([]);

    // Table props
    const [loading, setLoading] = useState(true);
    const [tableRef, setTableRef] = useState(null);
    
    const [quickFilterText, setQuickFilterText] = useState("");

    /**
     * Delete Modal
     */
    const [showDeleteStakeholderModal, setShowDeleteStakeholderModal] = useState(false);
    const [deleteStakeholderModalText, setDeleteStakeholderModalText] = useState("");
    const [selectedStakeholderId, setSelectedStakeholderId] = useState("");
    const [deleteModalStakeholderTexts, setDeleteModalStakeholderTexts] = useState({
        'confirm': ENUMS.BUTTON_TEXT.DELETE_ACTION_TEXT_CONFIRM,
        'cancel': ENUMS.BUTTON_TEXT.CANCEL
    });

    // Current User
    const user = useSelector(state => state.authReducer.user);

    /** ADD/EDIT Stakeholder modal */
    const [showAddEditStakeholderModal, setShowAddEditStakeholderModal] = useState(false);
    const [modalMode, setModalMode] = useState("");
    const [editStakeholderId, setEditStakeholderId] = useState(null);

    /**
     * View Modal
     */
    const [viewStakeholderModalOpen, setViewStakeholderModalOpen] = useState(false);
    const [stakeholderToView, setStakeholderToView] = useState(null);

    // TABLE filtering and sorting
    const [ogStakeholderList, setOgStakeholderList] = useState([]);
    const [colKeyList, setColKeyList] = useState(
        ['first_name','last_name', 'email','organization_role','team_name','note']
    );

    const populateStakeholderTable = () => {
        populateStakeholderHeaders();
        if (props.projectStakeholderList && 
            props.projectStakeholderList.length > 0) {
            populateStakeholderData();
        } else {
            setStakeholderList([]);
        }
    }

    const populateStakeholderHeaders = () => {
        /**
         * Columns object to be passed to table
         */
        setStakeholderListHeader(TableHeadersUtil.projectViewStakeholderTableHeaders);
    }

    const populateStakeholderData = () => {
        const updatedStakeholderList = props.projectStakeholderList.map((stakeholder, index) => {
            stakeholder['first_name'] = stakeholder.Stakeholder.first_name;
            stakeholder['last_name'] = stakeholder.Stakeholder.last_name;
            stakeholder['organization_role'] = stakeholder.Stakeholder.role;
            stakeholder['email'] = stakeholder.Stakeholder.email;
            stakeholder['team_name'] = stakeholder.Team.team_name;
            stakeholder['note_view'] = parse('<div class="table-note-display">' + stakeholder.note + '</div>');
            stakeholder['note'] = stakeholder.note;
            return stakeholder;
        });
        setStakeholderList(updatedStakeholderList);
        setOgStakeholderList(updatedStakeholderList);
    }

    const openViewStakeholderModal = (data) => {
        setViewStakeholderModalOpen(true);
        setStakeholderToView(data);
    }


    const openDeleteStakeholderModal = (stakeholder) => {
        setShowDeleteStakeholderModal(true);
        setDeleteStakeholderModalText(ENUMS.MODAL.DELETE_CONFIRMATION_TEXT + stakeholder.Stakeholder.first_name + " stakeholder ?");
        setSelectedStakeholderId(stakeholder.id);
    }

    const hideDeleteStakeholderModal = () => {
        setShowDeleteStakeholderModal(false);
    }

    const deleteStakeholderConfirmed = (stakeholderId) => {
        API.del(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.STAKEHOLDER_DELETE, 
        {
            ...HttpUtil.adminHttpHeaders(),
            body:  {
                stakeholder_id: stakeholderId
            }
        })
        .then(res => {
            if(res && res.status === 200) {
                refreshPageOnSubmit("Stakeholder has been deleted successfully");
            }
        })
        .catch(error => {
            if (error.response) {
                console.log(error.response);
                addToast(
                    "Failed to delete stakeholder.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const closeViewModal = () => {
        setViewStakeholderModalOpen(false);
    }

    /**
     * Method to handle opening the task modal in edit mode
     */
    const openEditStakeholderModal = (stakeholder) => {
        setModalMode(ENUMS.MODAL.EDIT_MODE);
        setEditStakeholderId(stakeholder.id);
        setShowAddEditStakeholderModal(true);
    }

    const optionsList = React.useMemo(() => 
    [
        {
            itemName: 'View',
            itemType: 'View',
            openViewModal: openViewStakeholderModal,
        },
        {
            itemName: 'Edit',
            itemType: 'Edit',
            openEditModal: openEditStakeholderModal,
        },
        {
            itemName: 'Delete',
            itemType: ENUMS.RENDER_OPTIONS_TYPE.DELETE,
            delete: openDeleteStakeholderModal,
            // role: [RULES.ROLE_NAMES.TEAM_LEADER, RULES.ROLE_NAMES.PROJECT_OWNER],
            // perform: RULES.RULE_NAMES.FILE_DELETE,
        }
    ], []);

    /**
     * Function to handle custom global filtering
     * @param {*} value 
     */
    const handleGlobalFiltering = (value) => {
        setQuickFilterText(value);
        let filteredStakeholder = _.cloneDeep(ogStakeholderList);

        if (value !== '') {
            const lowerVal = value.toLowerCase();
            filteredStakeholder = filteredStakeholder.filter(stakeholder => {
                return colKeyList.some(col => {
                    if (stakeholder[col] !== null) {
                        return stakeholder[col].toString().toLowerCase().includes(lowerVal);
                    }
                })
            });
        }
        setStakeholderList(filteredStakeholder);
    }

    /**
     * Method to handle the exporting to csv.
     * @param {Object} e 
     */
    const exportStakeholderList = (e) => {
        e.preventDefault();
        let csvContent = "data:text/csv;charset=utf-8,";
        let headers = "S.No.,Project id,Project Name,Description,Status,Due Date,Task Number,Note,Action Owner,Created On\r\n";
        csvContent += headers;
        stakeholderList.forEach((stakeholder, index) => {
            let rowArray = [
                index + 1,
                props.projectDetails.id,
                GeneralUtils.handleStringForCSV(props.projectDetails.project_name),
                GeneralUtils.handleStringForCSV(stakeholder.description),
                TaskUtil.taskStatusTypes.filter(type => type.value === stakeholder.status)[0].label,
                stakeholder.due_date,
                stakeholder.Task.task_number,
                GeneralUtils.extractContentFromHTMLString(stakeholder.note.trim().replace(/(\r\n|\n|\r)/gm, " ")),
                UserUtil.formatUserForTableColumn(stakeholder.stakeholderOwner),
                stakeholder.created_at
            ];
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", props.projectDetails.project_name + "_actions.csv");
        document.body.appendChild(link); // Required for FF
        link.click(); // This will download the data file named "my_data.csv".
    }

    /**
     * Method to reset the filters and sorting on the entire table. 
     */
    const resetTable = () => {
        tableRef.reset();
    }

    /**
     * Refreshes the page on submit of add/edit/delete form
     * @param {String} message 
     */
    const refreshPageOnSubmit = (message) => {
        setShowAddEditStakeholderModal(false);
        setShowDeleteStakeholderModal(false);
        props.refreshViewOnAction(ENUMS.TABS.STAKEHOLDERS_LIST, message);
    }

    const openAddStakeholderModal = () => {
        setModalMode(ENUMS.MODAL.ADD_MODE);
        setShowAddEditStakeholderModal(true);
    }

    const closeAddEditStakeholderModal = () => {
        setShowAddEditStakeholderModal(false);
    }

    useEffect (() => {
        populateStakeholderTable();
        setLoading(false);
    }, [props.projectStakeholderList]);

    return (
        <>
            <Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="bg-secondary box-shadow-down card-border-radius-0">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center mb-4">
                                <Col xs="6">
                                    <h3 className="mb-0">Stakeholder List</h3>
                                </Col>
                                <Col className="text-right">
                                    <Button
                                        color="secondary"
                                        onClick={resetTable}
                                        size="md"
                                    >
                                        Reset Filters
                                    </Button>
                                    <Button
                                        color="secondary"
                                        onClick={e => exportStakeholderList(e)}
                                        size="md"
                                        disabled={stakeholderList.length === 0}
                                    >
                                        Export
                                    </Button>
                                    <Button 
                                        size="md"
                                        color="primary"
                                        onClick={(e) => openAddStakeholderModal()}
                                    >
                                        Add Stakeholder
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-2">
                                <Col xs="6" className="">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-search"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            size="md"
                                            type="text"
                                            id="quickFilterText"
                                            value={quickFilterText}
                                            onChange={e => handleGlobalFiltering(e.target.value)}
                                            placeholder="Search Table...">
                                        </Input>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <CustomTablePrime
                                columnDefs={stakeholderListHeader} 
                                rowData={stakeholderList}
                                loading={loading}
                                optionsList={optionsList}
                                tableRef={tableRef}
                                setTableRef={setTableRef}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                showDeleteStakeholderModal && 
                <ConfirmationModal
                    actionText={deleteModalStakeholderTexts}
                    onCancel={hideDeleteStakeholderModal}
                    onConfirm={deleteStakeholderConfirmed}
                    modalBody={deleteStakeholderModalText}
                    showModal={showDeleteStakeholderModal}
                    modalTitle={ENUMS.MODAL.DELETE_STAKEHOLDER_TITLE}
                    data={selectedStakeholderId}
                />
            }
            {
                showAddEditStakeholderModal && 
                <AddEditStakeholderModal
                    modalTitle={modalMode === ENUMS.MODAL.ADD_MODE ? ENUMS.MODAL.ADD_STAKEHOLDER_TITLE : ENUMS.MODAL.EDIT_STAKEHOLDER_TITLE}
                    modalButtonName={ ENUMS.MODAL.ADD_MODE ? ENUMS.BUTTON_TEXT.ADD : ENUMS.BUTTON_TEXT.SAVE }
                    modalOpen={showAddEditStakeholderModal}
                    closeModal={closeAddEditStakeholderModal}
                    projectData={props.projectDetails}
                    modalMode={modalMode}
                    refreshPageOnSubmit={refreshPageOnSubmit}
                    editStakeholderId={editStakeholderId}
                /> 
            }
            { 
                viewStakeholderModalOpen && 
                <ViewStakeholderModal
                    modalTitle="Stakeholder"
                    modalOpen={viewStakeholderModalOpen}
                    stakeholderData={stakeholderToView}
                    closeModal={closeViewModal}
                /> 
            }
        </>
    )
}
