import React, { useState, useEffect, useRef } from "react";
import { 
    Modal, 
    ModalHeader,
    ModalBody,
    Card, 
    CardBody, 
    ModalFooter,
    Form, 
    FormGroup,
    ListGroup, 
    ListGroupItem, 
    Badge,
    Input, 
    Button, 
    Label,
    Spinner
} from "reactstrap";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import ENUMS from 'constants/appEnums';
import { useToasts } from 'react-toast-notifications';
import HttpUtil from "util/HttpUtil";
import { useForm } from 'react-hook-form';
import CustomErrorMessage from 'components/CustomErrorMessage/CustomErrorMessage';
import UserUtil from 'util/UserUtil';
import TeamUtil from "util/TeamUtil";
import GeneralUtils from "util/GeneralUtils";

export default function TeamAddEditViewModal (props) {

    const { addToast } = useToasts();
    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur"
    });
    const current_user = useSelector(state => state.authReducer.user);
    const teamNameFocusRef = useRef(null);

    // form elements
    const [teamName, setTeamName] = useState("");
    const [teamDescription, setTeamDescription] = useState("");
    const [teamLeader, setTeamLeader] = useState({});
    const [teamMember, setTeamMember] = useState([]);

    // dropdown lists
    const [teamLeaderUserList, setTeamLeaderUserList] = useState([]);
    const [teamMemberUserList, setTeamMemberUserList] = useState([]);
    const [openTeamLeaderMenu, setOpenTeamLeaderMenu] = useState(false);
    const [openTeamMemberMenu, setOpenTeamMemberMenu] = useState(false);

    // invitation lists
    const [inviteTeamLeader, setInviteTeamLeader] = useState(null);
    const [inviteTeamMember, setInviteTeamMember] = useState([]);

    // loading
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingUserList, setIsLoadingUserList] = useState(false);
    const [showForm, setShowForm] = useState(true);

    // update team id
    const [updateTeamId, setUpdateTeamId] = useState(null);
    
    // copy teams
    const [copyTeamsList, setCopyTeamsList] = useState({});
    const [copyTeamsOptionsList, setCopyTeamsOptionsList] = useState([]);
    const [isLoadingCopyTeamsList, setIsLoadingCopyTeamsList] = useState(false);

    const animatedComponents = makeAnimated();

    const populateDefaultTeamLeader = (listOfUsers) => {
        // Assigning current user as project owner if mode is ADD
        if (props.modalMode === ENUMS.MODAL.ADD_MODE && listOfUsers.length > 0) {
            const defaultKey = listOfUsers.findIndex(user => 
                user.value === current_user.id
            );
            setTeamLeader(listOfUsers[defaultKey]);
        }   
    }

    const populateFormFields = (teamData) => {
        if (teamData) {
            setTeamName(teamData.team_name);
            setTeamDescription(teamData.team_description);
            if (teamData.TeamLeader)
                setTeamLeader({ ...UserUtil.formatUserForDropdown(teamData.TeamLeader) });
            if (teamData.Users && teamData.Users.length > 0)
                setTeamMember(teamData.Users.map(teamMem => {
                    return { ...UserUtil.formatUserForDropdown(teamMem) };
                }));
            if (props.modalMode === ENUMS.MODAL.EDIT_MODE) {
                setUpdateTeamId(teamData.id);
            }
            if (props.modalMode === ENUMS.MODAL.VIEW_MODE) {
                setTeamLeaderUserList({ ...UserUtil.formatUserForDropdown(teamData.TeamLeader) });
            }
        }
    }

    const populateForm = (teamId) => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TEAM_GET_SINGLE,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                teamId: teamId ? teamId : props.viewEditTeamId
            }
        })
        .then(res => {
            if (res.status === 200) {
                const teamData = res.data.team;
                populateFormFields(teamData);
            }
        })
        .catch(error => {
            setIsLoadingUserList(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch user list for owner selection", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const fetchUserList = () => {
        setIsLoadingUserList(true);
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.USER_ALL_ORGANIZATION,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                project_id: props.projectId,
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setTeamLeaderUserList(res.data.userList);
                setTeamMemberUserList(res.data.userList);
                populateDefaultTeamLeader(res.data.userList);
                setIsLoadingUserList(false);
            }
        })
        .catch(error => {
            setIsLoadingUserList(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch user list for owner selection", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const onButtonClickHandler = async (data, e) => {
        e.preventDefault();
        setIsLoading(true);

        if (props.modalMode === ENUMS.MODAL.ADD_MODE ||
            props.modalMode === ENUMS.MODAL.COPY_MODE) {
            // add mode code
            let newTeam = {
                team_leader: teamLeader.value,
                project_id: props.projectId,
                team_name: teamName,
                team_description: teamDescription
            }
            newTeam = !props.assignTeamTotaskId 
                ? newTeam
                : {
                    ...newTeam,
                    assignTeamTotaskId: props.assignTeamTotaskId
                }
            const teamMemberNonInvite = teamMember && teamMember.length > 0 ? 
            teamMember.filter(
                member => !inviteTeamMember.includes(member.label)).map(
                    member => member.value
            ) : null;
            try {
                const res = await API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TEAM_ADD, 
                {
                    ...HttpUtil.adminHttpHeaders(),
                    body: 
                    {
                        newTeam: newTeam,
                        teamMember: teamMemberNonInvite,
                        inviteTeamLeader: inviteTeamLeader,
                        inviteTeamMember: inviteTeamMember
                    }
                })
                if (res && res.status === 201 && res.data.success) {
                    props.closeModal();
                    props.refreshPageOnSubmit(res.data.message);
                }
            }
            catch(error) {
                setIsLoading(false);
                if (error.response) {
                    addToast(
                        error.response.data.message, 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            }
        }
        else if (props.modalMode === ENUMS.MODAL.EDIT_MODE) {
            // edit mode code
            const updatedTeam = {
                id: updateTeamId,
                project_id: props.projectId,
                team_leader: teamLeader.value,
                team_name: teamName,
                team_description: teamDescription
            }
            const teamMemberNonInvite = teamMember && teamMember.length > 0 ?
            teamMember.filter(
                member => !inviteTeamMember.includes(member.label)).map(
                    member => member.value
            ) : null;
            try {
                const res = await API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TEAM_UPDATE, 
                {
                    ...HttpUtil.adminHttpHeaders(),
                    body: 
                    {
                        updatedTeam: updatedTeam,
                        teamMember: teamMemberNonInvite,
                        inviteTeamLeader: inviteTeamLeader,
                        inviteTeamMember: inviteTeamMember
                    }
                })
                if (res && res.status === 200 && res.data.success) {
                    props.refreshPageOnSubmit(res.data.message);
                }
            }
            catch(error) {
                setIsLoading(false);
                if (error.response) {
                    addToast(
                        error.response.data.message, 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            }
            
        }
    }

    const createOption = (label) => {
        return {
            label,
            value: label.toLowerCase() + "-invite user"
        }
    };

    const handleInviteTeamLeader = (inputValue) => {
        // TO-DO: Validate against email format
        if (GeneralUtils.validateEmail(inputValue)) {
            setInviteTeamLeader(inputValue);
            const option = createOption(inputValue);
            setTeamLeaderUserList([...teamMemberUserList, option]);
            setTeamLeader(option);
        }
        else {
            addToast(
                "Invalid format for email.", 
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
        }
        hideMenu();
    }

    const formatDropDownLabel = (inputValue) => {
        return "Invite "+ inputValue;
    }

    const handleInviteTeamMember = (inputValue) => {
        // TO-DO: Validate against email format
        if (GeneralUtils.validateEmail(inputValue)) {
            setInviteTeamMember([...inviteTeamMember, inputValue]);
            const option = createOption(inputValue);
            setTeamMemberUserList([...teamMemberUserList, option]);
            setTeamMember([...teamMember, option]);
        }
        else {
            addToast(
                "Invalid format for email.", 
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
        }
        hideMenu();
    }

    const fetchTeamsListCopyMode = async () => {
        try {
            const res = await API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TEAM_GET_COPY_TEAMS, 
            {
                ...HttpUtil.adminHttpHeaders(),
                queryStringParameters: 
                {
                    userId: current_user.id
                }
            })
            if (res && res.status === 200 && res.data.success) {
                setCopyTeamsOptionsList(TeamUtil.formatCopyTeamsList(res.data.teams));
                fetchUserList();
                setIsLoadingCopyTeamsList(false);
            }
        }
        catch(error) {
            if (error.response) {
                addToast(
                    "Failed to fetch teams for copy.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
                props.closeModal();
            }
        }
    }

    const handleCopyTeamSelect = (newValue) => {
        setCopyTeamsList(newValue);
        populateForm(newValue.value);
        setShowForm(true);
    }

    /**
     * Sets the new value to the team leader and closes the select dropdown.
     * @param {Object} newValue 
     */
    const handleTeamLeaderChange = (newValue) => {
        setTeamLeader(newValue);
        hideMenu();
    }

    /**
     * Sets the new value to the team members and closes the select dropdown.
     * @param {Object} newValue 
     */
    const handleTeamMemberChange = (newValue) => {
        setTeamMember(newValue);
        hideMenu();
    }

    /**
     * Closes the dropdown menu on input change for team leader select
     * @param {Object} query 
     * @param {Obejct} param1 
     */
    const handleTeamLeaderInputChange = (query, { action }) => {
        if (action === "input-change") {
            setOpenTeamLeaderMenu(true);
        }
    };

    /**
     * Closes the dropdown menu on input change for team member select
     * @param {Object} query 
     * @param {Obejct} param1 
     */
    const handleTeamMemberInputChange = (query, { action }) => {
        if (action === "input-change") {
            setOpenTeamMemberMenu(true);
        }
    };

    /**
     * Closes the dropdown menu for leader and member select
     */
    const hideMenu = () => {
        setOpenTeamLeaderMenu(false);
        setOpenTeamMemberMenu(false);
    };

    useEffect(() => {
        if (props.modalMode === ENUMS.MODAL.COPY_MODE) {
            setIsLoadingCopyTeamsList(true);
            setShowForm(false);
            fetchTeamsListCopyMode();
        }
        else {
            if (props.modalMode === ENUMS.MODAL.EDIT_MODE || 
                props.modalMode === ENUMS.MODAL.VIEW_MODE)
                populateForm();
            if (props.modalMode !== ENUMS.MODAL.VIEW_MODE)
                fetchUserList();
        }
    }, []);

    useEffect(() => {
        if (teamNameFocusRef.current) {
            teamNameFocusRef.current.focus();
        } 
    }, [teamNameFocusRef.current]);

    return (
        <Modal
            className="modal-dialog-centered"
            size="lg"
            isOpen={props.modalOpen}
            toggle={() => props.closeModal()}
            backdrop="static"
        >
            <ModalHeader 
                toggle={() => props.closeModal()}
                className="modal-header"
                tag="div"
            >
                <h3 className="modal-title">
                    {props.modalTitle}
                </h3>
			</ModalHeader>
            <Form role="form" onSubmit={handleSubmit(onButtonClickHandler)}>
                <ModalBody className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-4 py-lg-4">
                            { props.modalMode === ENUMS.MODAL.COPY_MODE && 
                                <FormGroup>
                                    <Label for="copyTeamList">Copy From<span className="required-asterix">*</span></Label>
                                    <Select
                                        id="copyTeamList"
                                        className="form-control-alternative"
                                        isClearable
                                        components={animatedComponents}
                                        isLoading={isLoadingCopyTeamsList}
                                        onChange={(newValue)=>handleCopyTeamSelect(newValue)}
                                        options={copyTeamsOptionsList}
                                        value={copyTeamsList}
                                    />
                                </FormGroup>
                            }
                            { showForm && 
                                <FormGroup className="mb-3">
                                    <Label for="teamName">Team Name<span className="required-asterix">*</span></Label>
                                    <Input 
                                        name="teamName"
                                        className="form-control-alternative"
                                        type="text"
                                        value={teamName}
                                        onChange={(e)=>setTeamName(e.target.value)}
                                        disabled={props.modalMode === ENUMS.MODAL.VIEW_MODE}
                                        innerRef={(e) => {
                                            teamNameFocusRef.current = e;
                                            register(e,
                                            {
                                                required: "Team Name is required."
                                            });
                                        }}
                                        autoComplete="off"
                                    />
                                </FormGroup>
                            }
                            <CustomErrorMessage errors={errors} name="teamName" />
                            { showForm && 
                                <FormGroup>
                                    <Label for="teamDescription">Team Description</Label>
                                    <Input 
                                        id="teamDescription"
                                        className="form-control-alternative"
                                        type="textarea"
                                        rows="4"
                                        value={teamDescription}
                                        onChange={(e)=>setTeamDescription(e.target.value)}
                                        disabled={props.modalMode === ENUMS.MODAL.VIEW_MODE}
                                        autoComplete="off"
                                    />
                                </FormGroup>
                            }
                            { showForm && 
                                <FormGroup>
                                    <Label for="teamLeader">Team Leader<span className="required-asterix">*</span></Label>
                                    <CreatableSelect
                                        id="teamLeader"
                                        name="teamLeader"
                                        className="form-control-alternative"
                                        // as={CreatableSelect}
                                        // control={control}
                                        // rules={{ required: "Team Leader is required." }}
                                        isClearable
                                        components={animatedComponents}
                                        isDisabled={props.modalMode === ENUMS.MODAL.VIEW_MODE || isLoadingUserList}
                                        isLoading={isLoadingUserList}
                                        onChange={(newValue)=>handleTeamLeaderChange(newValue)}
                                        onCreateOption={handleInviteTeamLeader}
                                        createOptionPosition="first"
                                        formatCreateLabel={formatDropDownLabel}
                                        options={teamLeaderUserList}
                                        value={teamLeader}
                                        onInputChange={handleTeamLeaderInputChange}
                                        onBlur={hideMenu}
                                        onFocus={e => setOpenTeamLeaderMenu(true)}
                                        menuIsOpen={openTeamLeaderMenu}
                                    />
                                    <CustomErrorMessage errors={errors} name="teamLeader" />
                                </FormGroup>
                            }
                            { showForm ? 
                                props.modalMode === ENUMS.MODAL.VIEW_MODE ?
                                <FormGroup>
                                    <Label for="teamMemberList">Team Members<span className="required-asterix">*</span></Label>
                                    <ListGroup>
                                        {
                                            teamMember.map((member,i) => {
                                                return <ListGroupItem 
                                                            id="teamMemberList"
                                                            className="justify-content-between"
                                                            key={i}>
                                                    {member.label}{" "}
                                                    <Badge pill color="dark">Member</Badge>
                                                </ListGroupItem>
                                            })
                                        }
                                    </ListGroup>
                                </FormGroup>
                                :
                                <FormGroup>
                                    <Label for="teamMember">Add Team Members</Label>
                                    <CreatableSelect
                                        id="teamMember"
                                        className="form-control-alternative"
                                        isMulti
                                        components={animatedComponents}
                                        isDisabled={isLoadingUserList}
                                        isLoading={isLoadingUserList}
                                        onChange={(newValue) => handleTeamMemberChange(newValue)}
                                        onCreateOption={handleInviteTeamMember}
                                        createOptionPosition="first"
                                        formatCreateLabel={formatDropDownLabel}
                                        options={teamMemberUserList}
                                        value={teamMember}
                                        onInputChange={handleTeamMemberInputChange}
                                        onBlur={hideMenu}
                                        onFocus={e => setOpenTeamMemberMenu(true)}
                                        menuIsOpen={openTeamMemberMenu}
                                    />
                                </FormGroup>
                                :
                                null
                            }
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <Button 
                        color="secondary" 
                        onClick={() => props.closeModal()}
                    >
                        Cancel
                    </Button>
                        {isLoading ?
                            <Spinner size="sm" color="primary" /> :
                            <Button 
                                color="primary" 
                                type="submit"
                                disabled={props.modalMode === ENUMS.MODAL.VIEW_MODE}
                            >
                                {props.modalButtonName}
                            </Button>
                        }
                </ModalFooter>
            </Form>
                
        </Modal>
    )
}