import ENUMS from "./appEnums";

const DEFAULT_TASK_DURATION = 9;

const DELETE_MODAL_ACTION_TEXTS = {
    'confirm': ENUMS.BUTTON_TEXT.DELETE_ACTION_TEXT_CONFIRM,
    'cancel': ENUMS.BUTTON_TEXT.CANCEL
}

const CONFIRMATION_MODAL_ACTION_TEXTS = {
    'confirm': ENUMS.BUTTON_TEXT.ACTION_TEXT_CONFIRM,
    'cancel': ENUMS.BUTTON_TEXT.CANCEL
}

const LOGIN_TO_APP_ROUTES = [
    '/auth/login',
    '/auth/register',
    '/auth/invite-user-confirm',
    '/auth/reset-password',
    '/auth/reset-password-confirm',
]

const ACCEPTED_IMAGE_FORMATS = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG','gif', 'GIF', 'webp', 'WEBP', 'BMP', 'bmp'];

const APP_CONSTANTS = {
    DEFAULT_TASK_DURATION,
    DELETE_MODAL_ACTION_TEXTS,
    CONFIRMATION_MODAL_ACTION_TEXTS,
    LOGIN_TO_APP_ROUTES,
    ACCEPTED_IMAGE_FORMATS
}

export default APP_CONSTANTS;