import React from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useHistory } from "react-router-dom";
import * as PropTypes from 'prop-types';
import ENUMS from "constants/appEnums";
import { useSelector } from "react-redux";
import CheckPermissions from "components/CheckPermissions/CheckPermissions";
import RULES from "rbac/rules";

export default function OptionsRendererPrime (props) {

    let history = useHistory();
    // grab current state
    const user = useSelector(state => state.authReducer.user);

    /**
     * Returns a dropdownitem entry.
     * @param {Object} itemOptions 
     * @param {int} key 
     * @param {boolean} disabled 
     */
    const dropdownItem = (itemOptions, key, disabled=false) => {
        return <DropdownItem
            onClick={e => onItemClickHandler(e, itemOptions)}
            disabled={disabled}
            key={key}
        >
            {itemOptions.itemName}
        </DropdownItem>
    }

    const getRoleId = (role, data) => {
        if (role === RULES.ROLE_NAMES.PROJECT_OWNER) {
            return data.project_owner_id ? 
            data.project_owner_id : props.projectOwnerId;
        } else if (role === RULES.ROLE_NAMES.ACTION_OWNER) {
            return data.action_owner;
        } else if (
            role === RULES.ROLE_NAMES.RISK_OWNER ||
            role === RULES.ROLE_NAMES.ASSUMPTION_OWNER ||
            role === RULES.ROLE_NAMES.ISSUE_OWNER ||
            role === RULES.ROLE_NAMES.DEPENDENCY_OWNER
        ) {
            return data.owner_id;
        } else if (
            role === RULES.ROLE_NAMES.RISK_MITIGATION_OWNER ||
            role === RULES.ROLE_NAMES.ISSUE_MITIGATION_OWNER
        ) {
            return data.mitigation_owner_id;
        } else if (
            role === RULES.ROLE_NAMES.PROJECT_ADMIN
        ) {
            if (data && data.ProjectAdmins) {
                const admin = data.ProjectAdmins.find(admin => admin.id === user.id);
                return admin ? admin.id : data[ENUMS.PERMISSIONS_MAP[role]];
            }
        }
        else {
            return data[ENUMS.PERMISSIONS_MAP[role]];
        }
    } 
    /**
     * Permissions mapping.
     * Maps the role with project owner id or other roles primary id.
     * @param {Array} roles 
     */
    const createPermissionDataMappings = (roles) => {
        let data = [];
        roles.forEach(role => {
            data.push({
                'userId': user.id,
                'roleId': getRoleId(role, props.data)
            })
        })
        return data;
    }

    const onItemClickHandler = (e, itemOptions) => {
        const data = props.data;
        switch(itemOptions.itemType) {
            case "Select":
                history.push({
                    pathname: itemOptions.selectRedirectionLink,
                    search: itemOptions.selectSearchPath+data.id,
                    state: { passedId: data.id }
                });
                break;
            case "Edit":
                itemOptions.openEditModal(data);
                break;
            case "Delete":
                itemOptions.deleteProject(data);
                break;
            case "Copy Calendar":
                itemOptions.copyCalendar(data);
                break;
            case ENUMS.RENDER_OPTIONS_TYPE.DELETE_CALENDAR:
                itemOptions.deleteCalendar(data);
                break;
            case "Add Public Holiday":
                itemOptions.addPublicHolidays(data);
                break;
            case ENUMS.RENDER_OPTIONS_TYPE.CALENDAR_EXCEPTIONS_LIST:
                itemOptions.openPublicHolidays(data);
                break;
            case "View":
                itemOptions.openViewModal(data);
                break;
            case ENUMS.RENDER_OPTIONS_TYPE.DELETE:
                itemOptions.delete(data);
                break;
            default:
                itemOptions.action(data);
                break;
        }
    }

    return (
        <UncontrolledDropdown>
            <DropdownToggle
                className="btn-icon-only text-light"
                role="button"
                size="sm"
                color=""
                onClick={e => e.preventDefault()}
            >
                <i className="fas fa-ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
                {props.optionsList.map((itemOptions, key) => {
                    if (itemOptions.itemName === ENUMS.RENDER_OPTIONS_TYPE.START_TASK) { 
                        if (props.data.task_status === ENUMS.TASK_STATUS.NS) {
                            return dropdownItem(itemOptions, key);
                        }
                    }
                    else if (itemOptions.itemName === ENUMS.RENDER_OPTIONS_TYPE.STOP_TASK) {
                        if (props.data.task_status === ENUMS.TASK_STATUS.S) {
                            return dropdownItem(itemOptions, key);
                        }
                    }
                    else {
                        if (itemOptions.role) {
                            return <CheckPermissions
                                key={key}
                                role={itemOptions.role}
                                perform={itemOptions.perform}
                                data={createPermissionDataMappings(itemOptions.role)}
                                yes= {() => {return dropdownItem(itemOptions, key)}}
                                no= {() => {return dropdownItem(itemOptions, key, true)}}
                            />
                        }
                        else {
                            return dropdownItem(itemOptions, key);
                        }
                    }

                    return dropdownItem(itemOptions, key);
                })}
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

OptionsRendererPrime.propTypes = {
    params: PropTypes.object
};