import React, { useState, useEffect } from "react";
import { Row, Col, Form, FormGroup, Input, Button, InputGroup, InputGroupAddon, InputGroupText, Label, UncontrolledTooltip, Card, CardHeader, CardBody } from "reactstrap";
import { API } from "aws-amplify";
import { useToasts } from 'react-toast-notifications';
import ENUMS from 'constants/appEnums';
import HttpUtil from "util/HttpUtil";
import { useForm } from 'react-hook-form';
import CustomErrorMessage from 'components/CustomErrorMessage/CustomErrorMessage';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DataOptions from "constants/appDataOptions";
import ProjectUtil from "util/ProjectUtil";
import CheckPermissions from "components/CheckPermissions/CheckPermissions";
import RULES from "rbac/rules";
import { useSelector } from "react-redux";

export default function GeneralSettings(props) {
    const { addToast } = useToasts();
    const { register, handleSubmit, errors } = useForm();

    const [assignedCalendar, setAssignedCalendar] = useState({});
	const [startTaskId, setStartTaskId] = useState("");
	const [taskDuration, setTaskDuration] = useState("8");
    const [taskNumberInterval, setTaskNumberInterval] = useState("");
    const [savedSettings, setSavedSettings] = useState({});
	const [defaultDurationFormat, setDefaultDurationFormat] = useState("");
    const animatedComponents = makeAnimated();
    const user = useSelector(state => state.authReducer.user);

    const assignProjectSettings = (settings) => {
		// only proceed if settings and calendar list are loaded 
		if (Object.entries(settings).length !== 0 && props.calendarList.length !== 0) {
			// find and set calendar 
			const calendar = props.calendarList.find(calendar => calendar.id == settings.calendar_id);
			setAssignedCalendar(calendar);

			setStartTaskId(settings.start_task_id);
			setDefaultDurationFormat(
				settings.default_duration_format 
				? settings.default_duration_format 
				: ENUMS.DURATION_FORMAT_VALUES.HOUR
			);

			// Convert the hours to days if necessary for task duration
			setTaskDuration(
				ProjectUtil.convertTaskDurationForUI(
					settings.default_task_duration,
					settings.default_duration_format,
					ProjectUtil.findTimeDifference(calendar)
				)
			);
			setTaskNumberInterval(settings.default_task_no_intervals);
		}
    };

    const handleOnSave = (data, e) => {
		e.preventDefault();

		// convert days to hours if default duration is set in days
		// DB only saves duration in hours
		const timeDifference = ProjectUtil.findTimeDifference(assignedCalendar);
		const taskDurationInHours = ProjectUtil.convertTaskDurationToHoursForDB(taskDuration, defaultDurationFormat, timeDifference);

        let params = {
            project_id: savedSettings.project_id,
            calendar_id: assignedCalendar.id,
			start_task_id: startTaskId,
			default_duration_format: defaultDurationFormat,
            default_task_duration: taskDurationInHours,
            default_task_no_intervals: taskNumberInterval,
        };

        if (savedSettings && savedSettings.id) {
            params = {
                ...params,
                id: savedSettings.id
            }
        }
        API
			.post(
                ENUMS.AWS.API_NAME,
				ENUMS.API_ROUTES.PROJECT_CONFIGURE_SETTINGS,
				{
                    ...HttpUtil.adminHttpHeaders(),
                    body:
                    {
                        ...params
                    }
                }
			)
			.then((res) => {
				if (res.status === 200 && res.data.success) {
					addToast('Your changes to general settings has been updated!', { 
						appearance: 'success',
						autoDismiss: true
					});
                    console.log("Configured");
				} else {
					console.log("User not created, error occured!");
				}
			})
			.catch((error) => {
				console.log("Error Occured!", error);
			});
	}

	/**
	 * 
	 * @param {object} calendar 
	 * 
	 * Set default duration format
	 * Handler to change task duration
	 */
	const assignCalendarChanged = (calendarId) => {
		const calendar = props.calendarList.find(calendar => calendar.id == calendarId);
		setAssignedCalendar(calendar);
		setDefaultDurationFormat(ENUMS.DURATION_FORMAT_VALUES.HOUR);

		// Use the default time difference for task duration when assigned calendar is changed
		const timeDifference = ProjectUtil.findTimeDifference(calendar);
		setTaskDuration(timeDifference);
	}

	/**
	 * 
	 * @param {string} durationFormat 
	 * 
	 * Handler to change task duration
	 * And set default duration format
	 */
	const defaultTaskDurationFormatChange = (durationFormat) => {
		if (durationFormat != defaultDurationFormat) {	// check if the value is different than the selected one
			// get time difference from calendar
			const timeDifference = ProjectUtil.findTimeDifference(assignedCalendar);

			// Convert task duration as per duration format (day or hour)
			setTaskDuration(ProjectUtil.convertTaskDurationAsPerFormat(taskDuration, durationFormat, timeDifference));
			setDefaultDurationFormat(durationFormat);
		}
	}

	/**
	 * 
	 * @param {object} e 
	 * Set only numbers on task duration input
	 */
	const onTaskDurationInputChanged = (e) => {
		const numberPattern = /^\d+(\.\d+)?$/;	// pattern for integers and decimal numbers
		if (e.target.value === '' || numberPattern.test(e.target.value)) {
		   setTaskDuration(e.target.value);
		}
	}

    useEffect(() => {
		assignProjectSettings(props.projectSettings);
		setSavedSettings(props.projectSettings);
    }, [props.projectSettings, props.calendarList]);

	return (
		<React.Fragment>
			<Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="bg-secondary box-shadow-down card-border-radius-0">
						<Form onSubmit={handleSubmit(handleOnSave)}>
							<CardHeader className="bg-white border-0">
								<Row className="align-items-center">
									<Col xs="6">
										<h3 className="mb-0">Project Defaults</h3>
									</Col>
									<Col className="text-right">
										<CheckPermissions
											role={[RULES.ROLE_NAMES.PROJECT_OWNER]}
											perform={RULES.RULE_NAMES.PROJECT_SETTING_EDIT}
											data={[{userId: user.id, roleId: props.projectOwnerId}]}
											yes={() => {
												return <Button 
													color="primary" 
													type="submit">
													Save Changes
												</Button>
											}}
											no={() => {
												return <Button 
													color="primary" 
													type="submit"
													disabled={true}>
													Save Changes
												</Button>
											}}
										/>
									</Col>
								</Row>
							</CardHeader>
							<CardBody id="attachDropdownsAt">
									<Row>
										<Col lg="6">
											<FormGroup>
												<label
													className="form-control-label"
													htmlFor="input-assigned-calendar"
												>
													Assigned Calendar<span className="required-asterix">*</span>
												</label>
												<Input
													className="form-control-alternative"
													id="input-assigned-calendar"
													name="input-assigned-calendar"
													onChange={(e) => assignCalendarChanged(e.target.value, e) }
													placeholder="Assigned Calendar"
													type="select"
													value={assignedCalendar.id}
													innerRef={register(
														{
															required: "Calendar is required."
														})}
												>
													{props.calendarList.map((c, key) => {
														return (
															<option key={key} value={c.id}>
																{c.calendar_name}
															</option>
														);
													})}
												</Input>
											</FormGroup>
											<CustomErrorMessage errors={errors} name="assigned-calendar" />
										</Col>
									</Row>
									<Row>
										<Col lg="6">
											<FormGroup>
												<label
													className="form-control-label"
													htmlFor="input-start_task_id"
												>
													Start Task ID<span className="required-asterix">*</span>
												</label>
												<Input
													className="form-control-alternative"
													defaultValue={startTaskId}
													id="start_task_id"
													name="start_task_id"
													onChange={(e) =>
														setStartTaskId(e.target.value)
													}
													placeholder="Start Task ID"
													type="text"
													innerRef={register(
														{
															required: "Start Task ID is required."
														})}
												/>
											</FormGroup>
											<CustomErrorMessage errors={errors} name="start_task_id" />
										</Col>
									</Row>
									<Row>
										<Col lg="6">
											<FormGroup className="mb-3">
												<Label
													className="form-control-label"
													htmlFor="input-duration-format"
												>
													Default Task Duration Format
												</Label>
													<Select
														className="form-control-alternative"
														id="input-duration-format"
														name="input-duration-format"
														options={DataOptions.DURATION_FORMATS}
														components={animatedComponents}
														value={DataOptions.DURATION_FORMATS.filter(option => option.value === defaultDurationFormat)}
														onChange={(e) => defaultTaskDurationFormatChange(e.value)}
													/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col lg="6">
											<FormGroup className="mb-3">
												<Label
													className="form-control-label"
													htmlFor="input-task-duration"
												>
													Default Task Duration
													<i className="pl-2 fas fa-info-circle" id="default_duration"></i>
													<UncontrolledTooltip
														delay={0}
														placement="top"
														target="default_duration"
													>
														{
															assignedCalendar.working_start_time && <div>
																1 day of the calendar 
																= Start time({assignedCalendar.working_start_time}) - End time({assignedCalendar.working_end_time})
																= {ProjectUtil.findTimeDifference(assignedCalendar)} hours
															</div>
														}
													</UncontrolledTooltip>
												</Label>
												<InputGroup className="input-group-alternative mb-3">
													<Input
														id="input-task-duration"
														name="input-task-duration"
														className="form-control-alternative"
														value={taskDuration}
														onChange={(e) => onTaskDurationInputChanged(e)}
														placeholder="Default task duration"
														type="number"
													/>
													<InputGroupAddon addonType="append">
														<InputGroupText>
															{defaultDurationFormat === ENUMS.DURATION_FORMAT_VALUES.HOUR ? 'Hrs' : 'Days'}
														</InputGroupText>
													</InputGroupAddon>
												</InputGroup>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col lg="6">
											<FormGroup>
												<label
													className="form-control-label"
													htmlFor="input-taskNumberInterval"
												>
													Default Task Number Interval
												</label>
												<Input
													className="form-control-alternative"
													defaultValue={taskNumberInterval}
													id="input-taskNumberInterval"
													name="input-taskNumberInterval"
													type="number"
													onChange={(e) =>
														setTaskNumberInterval(e.target.value)
													}
												/>
											</FormGroup>
										</Col>
									</Row>
							</CardBody>
						</Form>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	);
}
