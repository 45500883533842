import React from "react";
import { Button } from 'reactstrap';
import * as PropTypes from 'prop-types';

export default function TableActionButtons (props) {

    const onButtonClickHandler = (e, button) => {
        const data = props.data;
        button.clickHandler(data);
    }

    return (
        <>
        {
            props.buttonsList && props.buttonsList.map((buttonItem, key) => {
                return (
                    <Button
                        className="btn-icon-only text-light"
                        size="sm"
                        onClick={e => onButtonClickHandler(e, buttonItem)}
                        key={key}
                    >
                        {
                            buttonItem.label 
                            ? buttonItem.label
                            : <i className={buttonItem.iconClass}></i>
                        }
                    </Button>
                )
            })
        }
        </>
    )
}

TableActionButtons.propTypes = {
    params: PropTypes.object
};