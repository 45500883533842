import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

require('./HorizontalTimeline.css');

const SVG_VERTICAL_PADDING = 80
const SVG_WIDTH = 1000
const SVG_HEIGHT = 120
const SVG_WORKING_WIDTH = SVG_WIDTH - (SVG_VERTICAL_PADDING * 2)

const diffMonthsBetweenDates = (firstDate,lastDate) => {
    return moment(lastDate).diff(moment(firstDate),'months')
}

const labelGroupPos = (dataArray,groupIndex) => {

    let widthInterval = SVG_WORKING_WIDTH / diffMonthsBetweenDates(dataArray[0].date, dataArray[dataArray.length - 1].date)
    
    if (dataArray[groupIndex - 1] === undefined) {
        return -26;
    } else if (dataArray.length == [groupIndex + 1]) {
        return SVG_WORKING_WIDTH - 26;
    }else {
        const startDate = dataArray[0].date;
        const endDate = dataArray[dataArray.length-1].date;
        const differenceInDays = moment(endDate).diff(moment(startDate), 'days');
        const forDate = dataArray[groupIndex].date;
        const differenceFromStartDate = moment(forDate).diff(moment(startDate), 'days');
        const pos = ((differenceFromStartDate/differenceInDays)*SVG_WORKING_WIDTH) - 26;
        return pos;
    }
}

export default function HorizontalTimeline(props) {


    const dateToLabel = (date) => {
        return moment(date).format('MMM DD YYYY').toUpperCase()
    }

    const sortDataByDates =(dates) => {
        return _.sortBy(dates,'date')
    }

    const transformTranslate = (x,y) => {
        return `translate(${x},${y})`
    }

    const Circle = ({x,y,r, color}) => {
        console.log(x)
        return <path 
                transform={ transformTranslate(x,y) }
                d={'M '+(x-4)+' '+(y)+' m -'+r+', 0 a '+r+','+r+' 0 1,0 '+(r*2)+',0 a '+r+','+r+' 0 1,0 -'+(r*2)+',0'}
                strokeOpacity=".492"
                stroke="#2A2A2A"
                strokeWidth=".5"
                fill={color}
                fillRule="evenodd"
            />
    }

    Circle.props = {
        x: 0,
        y: 0,
        r: 0
    }

    const Label = ({value, index}) =>
        <text x={ 0 } y={ (index%2 == 0 ? -30 : 30)  } 
            fontSize="1rem"
            width="100%"
        >
            { dateToLabel(value) }
        </text>

    let sortedData = sortDataByDates(props.data)
    let sortedDataUniqByDate = _.uniq(sortedData,'date')

    const translateX = (x) => {
      return `translate(${x},0)`
    }

    let monthMarkers = []
    let monthsBetweenExtremes = diffMonthsBetweenDates(sortedData[0].date, sortedData[sortedData.length - 1].date)
    
    for (var i = -1; i < monthsBetweenExtremes; i++) {
        if (monthsBetweenExtremes != 0) {
            monthMarkers.push(
                <rect width={ 1 } height={ 5 } y={ 0 } fill={ '#000000' } 
                    x={ (SVG_WORKING_WIDTH / monthsBetweenExtremes) * (i+1) }
                    key={ i } />
            )
        }
    }

    sortedDataUniqByDate.map( (date, index) => {
        
    });
    
    const handleSliderChange = (e) => {
        console.log(e)
    }

    return (
        <>
            <div style={{margin: "100px 0", width:"300px"}}>
            <Slider
                min={1}
                max={100}
                onChange={(e) => handleSliderChange(e)}
            />
            </div>
            <div style={ {position: 'relative', height: '250px', paddingLeft: '70px'} }>
                <svg width={ SVG_WIDTH } height={ SVG_HEIGHT }>
                <g transform={ transformTranslate(65, 90) }>
                    <rect width={ SVG_WORKING_WIDTH } height={ 1 } />
                    { sortedDataUniqByDate.map( (date, index) => {
                        return(
                            <g transform={ translateX(labelGroupPos(sortedDataUniqByDate,index)) } 
                                key={ index }
                                style={ { cursor: 'pointer' } }>
                                <Circle x={ 20 } y={ 0 } r={10} color={sortedDataUniqByDate[index].color}/>
                                {/* <Label value={ date.date } index={ index } uniqueLabelsCount={ sortedDataUniqByDate.length } /> */}
                            </g>
                        )
                    }
                    )}
                </g>
                </svg>

                <Popovers data={ sortedData }
                    showingPopover={ true }
                />
            </div>
            <div style={ {position: 'relative', height: '250px', paddingLeft: '70px'} }>
                <svg width={ SVG_WIDTH } height={ SVG_HEIGHT }>
                <g transform={ transformTranslate(65, 90) }>
                    <rect width={ SVG_WORKING_WIDTH } height={ 1 } />
                    { sortedDataUniqByDate.map( (date, index) => {
                        return(
                            <g transform={ translateX(labelGroupPos(sortedDataUniqByDate,index)) } 
                                key={ index }
                                style={ { cursor: 'pointer' } }>
                                <Circle x={ 20 } y={ 0 } r={10} color={sortedDataUniqByDate[index].color}/>
                                {/* <Label value={ date.date } index={ index } uniqueLabelsCount={ sortedDataUniqByDate.length } /> */}
                            </g>
                        )
                    }
                    )}
                </g>
                </svg>

                <Popovers data={ sortedData }
                    showingPopover={ true }
                />

            </div>
        </>
    );
}

class Popovers extends React.Component {

    render() {
  
        const { data,showingPopover } = this.props
    
        let style = { 
            fontSize: 16, 
            position: 'relative', 
            left: `${SVG_VERTICAL_PADDING - 25}px` 
        }
    
        return (
            <div style={ style }>
            { _.uniq(data,'date').map( (date, index) =>
                <Popover 
                    data={ data }
                    date={ date } 
                    offset={ index } 
                    visible={ true } 
                    key={ index }
                />
            )}
            </div>
        )
    }
  
}

class Popover extends React.Component {
  
    render() {
  
        const { showingPopover, offset, data, date } = this.props
        
        const getBoxPositioning = () => {
            const labelGroupPosValue = labelGroupPos(_.uniq(data,'date'),offset)
            if(offset == 0) {
                return '-125px'
            } else {
                return (labelGroupPosValue + 40) + 'px';
            }
        }
        const getLinkPositioning = () => {
            if(offset == 0) {
                return '100%';
            } else {
                return '7%';
            }
        }
        let styles = { 
            position: 'absolute', 
            left: getBoxPositioning(), 
            border: `2px solid ${data[offset].color}`, 
            boxShadow: "0px 0px 3px 0px #000",
            backgroundColor: 'white', 
            padding: '.5em',
            borderRadius: '6px',
            width: '150px',
            minHeight: '2em',
            display: this.props.visible ? 'block' : 'none',
            zIndex: 1
        }
        
        const Pops = styled.div`
            &:after {
                border-left: 1px solid ${data[offset].color};
                left: ${getLinkPositioning()}
            }
        `;
        return(
            <div>
                {
                    <Pops 
                        className={'timelineComponentPopover ' + (
                            (offset%2) == 0 
                            ? (data.arrow ? "timelineComponentPopoverDown" : "timelineCircleLinkDown")
                            : (data.arrow ? "timelineComponentPopoverUp" : "timelineCircleLinkUp")
                        )}
                        style={ styles }
                    >
                        <div style={ {margin: '0 0 .5em 0'} }>
                            <div className="timeline-task-title">{ data[offset].description }</div>
                            <div className="timeline-task-subtitle">{ data[offset].date }</div>
                        </div>
                    </Pops>
                }
            </div>
        )
    }
  
}
  
  