/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API } from "aws-amplify";
import allActions from 'redux/actions/index';
import ENUMS from 'constants/appEnums';
import { useToasts } from 'react-toast-notifications';
import HttpUtil from "util/HttpUtil";
import "./UserProfile.css";
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	Container,
	Row,
	Col,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";

// core components
import UserHeader from "components/Headers/UserHeader.js";
import DataOptions from "constants/appDataOptions";
import { useForm } from 'react-hook-form';
import CustomErrorMessage from 'components/CustomErrorMessage/CustomErrorMessage';
import UpdatePasswordModal from 'components/User/UpdatePasswordModal/UpdatePasswordModal';
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import UploadPictureModal from "components/UploadPictureModal/UploadPictureModal";
import UserUtil from "util/UserUtil";

export default function UserProfile() {
    let history = useHistory();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { register, handleSubmit, errors } = useForm({
		mode: "onBlur"
	});
    const firstNameFocusRef = useRef(null);

    const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [contactNo, setContactNo] = useState("");
    const [organization, setOrganization] = useState("");
    const [role, setRole] = useState("");
    const [timezone, setTimezone] = useState("");
    const [timezoneOptions, setTimezoneOptions] = useState([]);
    const [twentyFourHourClock, setTwentyFourHourClock] = useState(false);
    const [dateFormat, setDateFormat] = useState("");
    const [editOrganization, setEditOrganization] = useState(false);
    const [showEditOrganizationModal, setShowEditOrganizationModal] = useState(false);
    const [editOrganizationModalText, setEditOrganizationModalText] = useState("");
    const [organizationId, setOrganizationId] = useState("");
    const [organizationReadOnly, setOrganizationReadOnly] = useState("");
    const [userImgSrc, setUserImgSrc] = useState("");
    const animatedComponents = makeAnimated();

    const [licenceOptions, setLicenceOptions] = useState([]);
    const [licence, setLicence] = useState();

    // organization update confirmation
    const editOrganizationModalActionTexts = {
        'confirm': ENUMS.BUTTON_TEXT.UPDATE_ACTION_TEXT_CONFIRM,
        'cancel': ENUMS.BUTTON_TEXT.CANCEL
    };
    

    // grab current state
    const user = useSelector(state => state.authReducer.user);

    /**
     * Update password modal
     */
    const [updatePasswordModalOpen, setUpdatePasswordModalOpen] = useState(false);

    /**
     * Profile picture
     */
    const [showUploadPictureModal, setShowUploadPictureModal] = useState(false);

    /**
	 * Call to get loggedin user details
	 *
	 * @param {object} e
	 */
    const getUser = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.AUTH_STATUS,
        {
            ...HttpUtil.adminHttpHeaders()
        })
        .then(res => {
            if (res.status === 200) {
                dispatch(allActions.authActions.setUserState(res.data.user));
                dispatch(allActions.authActions.setUserProjectList(res.data.projectList));
                setUserDetails(res.data.user);
            }
            else {
                history.push('/auth/login');
            }
        })
        .catch(error => {
            console.log(error);
        })
    };

    /**
	 * Call to get loggedin user details
	 *
	 * @param {object} e
	 */
    const getTimeZoneOptions = () => {
        API
            .get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TIMEZONE_GET_ALL, 
            {
                ...HttpUtil.authHttpHeaders
            })
			.then((res) => {
                setTimezoneOptions(res.data);
			})
			.catch((error) => {
				console.log(error);
			});

            API
            .get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.LICENCES_GET_ALL,
            {
                ...HttpUtil.authHttpHeaders
            })
			.then((res) => {
				const options = UserUtil.formatLicenceForDropdownBulk(res.data.licences);
				setLicenceOptions(options);
				setLicence(options[0].value);
			})
			.catch((error) => {
				console.log(error);
			});
    };

    /**
	 * Set all local state for user details
	 *
	 * @param {object} userDetails
	 */
    const setUserDetails = (userDetails) => {
        setFirstName(userDetails.first_name);
        setLastName(userDetails.last_name);
        setEmail(userDetails.email);
        setContactNo(userDetails.contact_no);
        // setOrganization(userDetails.Organization.organization_name);
        // setOrganizationId(userDetails.Organization.id);
        // setOrganizationReadOnly(userDetails.Organization.organization_name);
        setLicence(userDetails.licence_type_id);
        setRole(userDetails.role);
        setTimezone(userDetails.timezone);
        setTwentyFourHourClock(userDetails.twenty_four_hour_clock);
        setDateFormat(userDetails.date_format);
    };

    /**
	 * Call to update user settings
	 *
	 * @param {object} e
	 */
	const handleUserUpdate = (data, e) => {
		e.preventDefault();
        
		API
			.put(
                ENUMS.AWS.API_NAME,
				ENUMS.API_ROUTES.USER_UPDATE,
				{
                    ...HttpUtil.adminHttpHeaders(),
                    body:
                    {
                        id: user.id,
                        first_name: firstName,
                        last_name: lastName,
                        username: email,
                        email: email,
                        contact_no: contactNo,
                        organization_id: organization.value,
                        role: role,
                        timezone: timezone,
                        twenty_four_hour_clock: twentyFourHourClock,
                        date_format: dateFormat,
                        licence_type_id: licence
                    }
				}
			)
			.then((res) => {
				if (res.status === 200 && res.data.success) {
                    addToast('Changes to your profile has beeen updated.', { 
                        appearance: 'success',
                        autoDismiss: true
                    });
					getUser();
				} else {
					console.log("User not updated, error occured!");
				}
			})
			.catch((error) => {
				console.log("Error Occured!", error);
			});
    };

    const openUpdatePasswordModal = () => {
        setUpdatePasswordModalOpen(true);
    }

    const closeUpdatePasswordModal = (e) => {
        setUpdatePasswordModalOpen(false);
    };

    /**
     * hide edit Organization modal - on cancel
     */
    const hideEditOrganizationModal = () => {
        setShowEditOrganizationModal(false);
    }

    /**
     * Update Organization
     */
    const editOrganizationConfirmed = (organizationId) => {
		API
			.put(
                ENUMS.AWS.API_NAME,
				ENUMS.API_ROUTES.ORGANIZATION_UPDATE,
				{
                    ...HttpUtil.adminHttpHeaders(),
                    body:
                    {
                        organization_id: organizationId,
                        organization_name: organization
                    }
				}
			)
			.then((res) => {
				if (res.status === 200 && res.data.success) {
                    addToast('Organisation has beeen updated.', { 
                        appearance: 'success',
                        autoDismiss: true
                    });
                    setShowEditOrganizationModal(false);
                    setEditOrganization(false);
                    getUser();
				} else {
					console.log("Organisation not updated, error occured!");
				}
			})
			.catch((error) => {
				console.log("Error Occured!", error);
			});
    }

    const getUserImage = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.S3_DOWNLOAD_SIGNED_URL, 
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                fileId: user.id,
                getFrom: 'profile-pictures'
            },
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setUserImgSrc(res.data.signedRequest);
            }
        })
        .catch(error => {
            if (error.response) {
                const content = (
                    <div>
                        <strong>Failed to generate presigned url.</strong>
                        <div>
                            {error.response.data && error.response.data.message}
                        </div>
                    </div>
                )
                addToast(
                    content,
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    /**
     * cancel editing organisation
     */
    const editOrganizationCanceled = ()  => {
        setOrganization(organizationReadOnly);
        setEditOrganization(false);
    }

    /**
     * show update organisation modal
     */
    const editOrganizationModalHandler = (organizationName) => {
        setShowEditOrganizationModal(true);
        setEditOrganizationModalText("Are you sure you want to update the organisation name to " + organizationName + " ?");
    }
     
    const openUploadPictureModal = () => {
        setShowUploadPictureModal(true);
    }

    const closeUploadPictureModal = (uploaded) => {
        setShowUploadPictureModal(false);
        if (uploaded) {
            getUser();
        }
    }

    useEffect (getUser, []);
    useEffect (() => { getTimeZoneOptions(); }, []);
    useEffect (() => {
        setUserDetails(user);
        if (user.s3filePath) {
            getUserImage()
        }
    }, [user]);
    useEffect(()=> {
		if (firstNameFocusRef.current) {
            firstNameFocusRef.current.focus();
        } 
	}, [firstNameFocusRef.current]);

	return (
        <>
            <UserHeader />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                        <Card className="card-profile shadow">
                            <Row className="justify-content-center">
                                <Col className="order-lg-2" lg="3">
                                    <div className="card-profile-image">
                                        <div onClick={(e) => openUploadPictureModal()}>
                                            {
                                                !userImgSrc 
                                                ? <img
                                                    alt="..."
                                                    className="rounded-circle"
                                                    src={require("assets/img/theme/default-user-image.png")}
                                                />
                                                : <img src={userImgSrc} className="profile-pic"></img>
                                            }
                                            <div className="upload-icon">
                                                <i className="fas fa-camera"></i>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                <div className="d-flex justify-content-between">
                                    
                                    
                                </div>
                            </CardHeader>
                            <CardBody className="pt-0 pt-md-4">
                                <Row>
                                    <div className="col">
                                        <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                                            
                                        </div>
                                    </div>
                                </Row>
                                <div className="text-center">
                                    <h3>
                                        {firstName} {lastName}
                                    </h3>
                                    <div className="h5 font-weight-300">
                                        <i className="ni location_pin mr-2" />
                                        {email}
                                    </div>
                                    <div className="h5 font-weight-300">
                                        <i className="ni location_pin mr-2" />
                                        {contactNo}
                                    </div>
                                    <div className="h5 font-weight-300">
                                        <i className="ni education_hat mr-2" />
                                        {role}
                                    </div>
                                    {/* <div>
                                        <i className="ni education_hat mr-2" />
                                        { !editOrganization
                                            ?   <span>
                                                    <span>{organizationReadOnly}</span>
                                                    <Button
                                                        className="btn-icon-only text-light ml-2"
                                                        size="sm"
                                                        onClick={e => setEditOrganization(true)}
                                                    >
                                                        {
                                                            <i className="far fa-edit text-info"></i>
                                                        }
                                                    </Button>
                                                </span>
                                            :   <InputGroup className="input-group-alternative">
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue={organization}
                                                        id="input-organization-name"
                                                        name="input-organization-name"
                                                        onChange={(e) => setOrganization(e.target.value)}
                                                        placeholder="Organisation name"
                                                        type="text"
                                                    />
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="fas fa-check text-success" onClick={e => editOrganizationModalHandler(organization)}/>
                                                            <i className="fas fa-times text-danger ml-2" onClick={e => editOrganizationCanceled()}/>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                        }
                                    </div> */}
                                    <hr className="my-4" />
                                    <p>
                                        {timezone}
                                    </p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <Form role="form" onSubmit={handleSubmit(handleUserUpdate)} noValidate>
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="4">
                                            <h3 className="mb-0">My account</h3>
                                        </Col>
                                        <Col className="text-right" xs="8">
                                            <Button
                                                color="secondary"
                                                onClick={openUpdatePasswordModal}
                                            >
                                                Update Password
                                            </Button>
                                            <Button
                                                type="submit"
                                                color="primary"
                                            >
                                                Save Changes
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                        <h6 className="heading-small text-muted mb-4">
                                            Licence Type: 
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                    <label
                                                            className="form-control-label"
                                                            htmlFor="input-first-name"
                                                        >
                                                            Licence
                                                        </label>
													<Select
														id="timezone"
														className="form-control-alternative"
														getOptionLabel={option =>`${option.label}`}
														options={licenceOptions}
														components={animatedComponents}
														value={licenceOptions.filter(option => option.value === licence)}
														onChange={(selectedLicence)=> setLicence(selectedLicence.value)}
													/>
                                                        <CustomErrorMessage errors={errors} name="input-role" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr className="my-4" />
                                        <h6 className="heading-small text-muted mb-4">
                                            User information
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-first-name"
                                                        >
                                                            First name<span className="required-asterix">*</span>
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue={firstName}
                                                            id="input-first-name"
                                                            name="input-first-name"
                                                            onChange={(e) => setFirstName(e.target.value)}
                                                            placeholder="First name"
                                                            type="text"
                                                            innerRef={(e) => {
                                                                firstNameFocusRef.current = e;
                                                                register(
                                                                    e,
                                                                    {
                                                                        required: "First name is required.",
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                        <CustomErrorMessage errors={errors} name="input-first-name" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-last-name"
                                                        >
                                                            Last name<span className="required-asterix">*</span>
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue={lastName}
                                                            id="input-last-name"
                                                            name="input-last-name"
                                                            onChange={(e) => setLastName(e.target.value)}
                                                            placeholder="Last name"
                                                            type="text"
                                                            innerRef={register(
                                                                {
                                                                    required: "Last name is required",
                                                                }
                                                            )}
                                                        />
                                                        <CustomErrorMessage errors={errors} name="input-last-name" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-email"
                                                        >
                                                            Email address<span className="required-asterix">*</span>
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue={email}
                                                            id="input-email"
                                                            name="input-email"
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            placeholder="jesse@example.com"
                                                            type="email"
                                                            readOnly
                                                            innerRef={register(
                                                                {
                                                                    required: "Email Address is required",
                                                                    pattern: {
                                                                        value: '^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$',
                                                                        message: "Invalid pattern for email."
                                                                    }
                                                                })}
                                                        />
                                                    </FormGroup>
                                                    <CustomErrorMessage errors={errors} name="input-email" />
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-contact"
                                                        >
                                                            Contact number<span className="required-asterix">*</span>
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue={contactNo}
                                                            onChange={(e) => setContactNo(e.target.value)}
                                                            id="input-contact"
                                                            name="input-contact"
                                                            placeholder="Contact"
                                                            type="text"
                                                            innerRef={register(
                                                                {
                                                                    required: "Contact is required",
                                                                    pattern: {
                                                                        value: /\d{8}/,
                                                                        message: "Invalid contact number"
                                                                    }
                                                                }
                                                            )}
                                                        />
                                                        <CustomErrorMessage errors={errors} name="input-contact" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr className="my-4" />
                                        {/* Address */}
                                        <h6 className="heading-small text-muted mb-4">
                                            Organisation information
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                {/* <Col md="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-organization-name"
                                                        >
                                                            Organisation
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue={organizationReadOnly}
                                                            id="organization-name"
                                                            name="organization-name"
                                                            placeholder="Organisation name"
                                                            type="text"
                                                            readOnly
                                                        />
                                                    </FormGroup>
                                                </Col> */}
                                                <Col md="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-role"
                                                        >
                                                            Role
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue={role}
                                                            id="input-role"
                                                            name="input-role"
                                                            onChange={(e) => setRole(e.target.value)}
                                                            placeholder="Role"
                                                            type="text"
                                                        />
                                                        <CustomErrorMessage errors={errors} name="input-role" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr className="my-4" />
                                        {/* Address */}
                                        <h6 className="heading-small text-muted mb-4">
                                            Timezone
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col md="5">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-timezone"
                                                        >
                                                            Default Timezone
                                                        </label>
                                                        <Select
                                                            className="form-control-alternative"
                                                            getOptionLabel={option =>`${option.text}`}
                                                            id="input-timezone"
                                                            name="input-timezon"
                                                            options={timezoneOptions}
                                                            placeholder="Timezone"
                                                            components={animatedComponents}
                                                            value={timezoneOptions.filter(option => option.value === timezone)}
                                                            onChange={(selectedTimezone)=>setTimezone(selectedTimezone.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-date-format"
                                                        >
                                                            Date Format
                                                        </label>
                                                        <Select
                                                            className="form-control-alternative"
                                                            id="input-date-format"
                                                            name="input-date-format"
                                                            options={DataOptions.DATE_FORMATS}
                                                            components={animatedComponents}
                                                            value={DataOptions.DATE_FORMATS.filter(option => option.value === dateFormat)}
                                                            onChange={(e) => setDateFormat(e.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="3">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-24_hr_clock"
                                                        >
                                                            Time Format
                                                        </label>
                                                        <Select
                                                            className="form-control-alternative"
                                                            id="input-24_hr_clock"
                                                            name="input-24_hr_clock"
                                                            options={DataOptions.CLOCK_OPTIONS}
                                                            components={animatedComponents}
                                                            value={DataOptions.CLOCK_OPTIONS.filter(option => option.value === twentyFourHourClock)}
                                                            onChange={(e) => setTwentyFourHourClock(e.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {updatePasswordModalOpen && 
                <UpdatePasswordModal
                    modalTitle="Update Password"
                    modalButtonName="Update Password"
                    modalOpen={updatePasswordModalOpen}
                    userId={user.id}
                    closeModal={closeUpdatePasswordModal}
                />
            }
            {
                showEditOrganizationModal &&
                <ConfirmationModal
                    actionText={editOrganizationModalActionTexts}
                    onCancel={hideEditOrganizationModal}
                    onConfirm={editOrganizationConfirmed}
                    modalBody={editOrganizationModalText}
                    showModal={showEditOrganizationModal}
                    modalTitle={ENUMS.MODAL.UPDATE_ORGANIZATION_TITLE}
                    data={organizationId}
                />
            }
            {
                showUploadPictureModal &&
                <UploadPictureModal
                    modalButtonName="Upload"
                    onCancel={closeUploadPictureModal}
                    showModal={showUploadPictureModal}
                    modalTitle="Upload Profile Picture"
                    user={user}
                />
            }
        </>
    );
}

