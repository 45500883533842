import React, { useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import {
    Container,
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselControl,
    Button,
  } from 'reactstrap';
import "./Home.css";
import Switch from "react-bootstrap-switch";
import { useHistory } from 'react-router-dom';

export default function Home() {
    const history = useHistory();
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const items = [
        {
          src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
          altText: 'Slide 1',
          caption: 'Slide 1',
          quote: '“I can collaborate with team members on a project’s CutOverPlan and capture dress rehearsal\
          results. In addition, all tasks, actions & RAID items per project are tracked on a single\
          platform.',
          content: '“After more than 20 years of leveraging spreadsheets to track critical items for projects, I wanted to create a platform that provided a single source of truth for collating all project centric items together that could be easily shared with all project participants.”',
          name: 'John Friebel',
          position: 'Founder of CutOverPlan'
        },
    ];
    
    const handleLogin = () => {
        history.push('/auth/login');
    }

    const handleSignup = () => {
        history.push('/auth/register');
    }

    const handleContactUs = () => {
        history.push('/auth/contact-us');
    }

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = items.map((item) => {
        return <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.src}
        >
            <div className="testimonial-content-wrap">
                <div className="feature-subcontent">
                    <div>{item.quote}</div><br></br>
                    <div>{item.content}</div>
                </div>
                <div className="feature-subcontent mb-4">
                    {/* {item.content} */}
                </div>
                <div className="testimonial-image">
                    <img src={require("assets/img/home/john.jpeg")}></img>
                    <div className="testimonial-img-gradient"></div>
                </div>
                <div className="testimonial-user-name">{item.name}</div>
                <div className="testimonial-user-position">{item.position}</div>
            </div>
        </CarouselItem>
    });

    const selectPlan = (type) => {
        history.push({
            pathname: '/auth/register',
            search: '?planId='+ type,
            state: { planId:  type }
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (<>
        <Container className="mt-64 home-cont" fluid>
            <Row className="section top-section">
                <Col sm="12" md="6">
                    <Row className="top-section-content-l">
                        <Col sm="12" md={{size: 9, offset: 3}}>
                            <Row className="section-title">
                                <Col className="mb-4" style={{maxWidth: "90%"}}>
                                    <Zoom collapse duration={2000}>Superpowers for project managers</Zoom>
                                </Col>
                            </Row>
                            <Row className="top-section-sub-content mb-4">
                                <Col className="pr-5 mb-4" style={{maxWidth: "95%"}}>
                                    <Zoom collapse duration={2000}>Track all tasks in your CutOverPlan. Collaboratively share your plan with anyone. Run dress rehearsals of the plan to record each tasks actual duration.</Zoom>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="top-buttons">
                                    <Button className="index-button-primary cursor-pointer top-button" onClick={(e) => handleSignup(e)}><Zoom collapse duration={2000}>Start a free trial</Zoom></Button>
                                    <Button className="index-button-secondary cursor-pointer top-button" onClick={(e) => handleLogin(e)}><Zoom collapse duration={2000}>Log in</Zoom></Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm="12" md={{size: 6}} className="top-section-right">
                    <Fade right >
                        <Row className="image-1">
                            <Col className="pr-0">
                                <div className="image-border-left">
                                    <Fade top>
                                        <img className="icon-shadow toggle-icon" src={require('assets/img/home/toggle.svg')}></img>
                                        <img className="icon-shadow cardboard-icon" src={require('assets/img/home/cardboard.svg')}></img>
                                        <img className="icon-shadow calendar-icon" src={require('assets/img/home/calendar.svg')}></img>
                                        <img className="icon-shadow dialog-icon" src={require('assets/img/home/dialog.svg')}></img>
                                    </Fade>
                                    <img src={require('assets/img/home/task_list.png')}></img>
                                    <div className="section-1-blur-gradient"></div>
                                    {/* <div className="section-1-blur-gradient1"></div> */}
                                </div>
                            </Col>
                        </Row>
                    </Fade>
                </Col>
            </Row>
            <Row className="section task-section">
                <Col sm="12" md="5">
                    <Row className="image-2">
                        <Col className="pr-0">
                            <Fade left>
                                <div className="image-border-right">
                                        <img src={require('assets/img/home/tasks.png')}></img>
                                </div>
                            </Fade>
                            <div className="task-blue-gradient"></div>
                            <div className="task-pink-gradient"></div>
                        </Col>
                    </Row>
                </Col>
                <Col sm="12" md={{size: 5, offset: 2}} className="task-content-section">
                    <Row className="feature-title task-list-r mb-2">
                        <Col>
                            <Zoom collapse duration={2000}>
                                Task List
                            </Zoom>
                        </Col>
                    </Row>
                    <Row className="mb-4 section-sub-content task-sub-cont">
                        <Col sm="12" md={{size: 9}}>
                            <Zoom collapse duration={2000}>
                                The task list view shows all tasks in a project. Users can define the visible fields, filter on any data to find specific tasks, group tasks and/or edit tasks. Task dependencies are clearly presented and can be easily altered to automatically recalibrate the project end date.
                            </Zoom>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ul className="index-list list-unstyled">
                                <Zoom collapse duration={2000}>
                                    <li className="list-item">
                                        <img src={require("assets/img/home/interface-task-lists.svg")} className="mr-4 icon-width"/>
                                        Visualise all tasks in the project
                                    </li>
                                    <li className="list-item">
                                        <img src={require("assets/img/home/task-list-share.svg")} className="mr-4 icon-width"/>
                                        Obvious task dependencies
                                    </li>
                                    <li className="">
                                        <img src={require("assets/img/home/interface-filter-list.svg")} className="mr-4 icon-width"/>
                                        Filter tasks
                                    </li>
                                </Zoom>
                            </ul>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="section calendar-section">
                <Col sm="12" md="6">
                    <Row>
                        <Col sm="12" md={{size: 9, offset: 3}} className="calendar-content-section">
                            <Row className="feature-title calendar-section-l mb-2">
                                <Col>
                                    <Zoom collapse duration={2000}>Actions and RAID</Zoom>
                                </Col>
                            </Row>
                            <Row className="mb-4 section-sub-content cal-sub-cont">
                                <Col sm="12" md={{size: 9}}>
                                    <Zoom collapse duration={2000}>
                                    To support your regular team meetings, use CutOverPlan to manage and monitor all actions and to track your Risks, Assumptions, Issues &amp; Dependencies (RAID) items.
                                    </Zoom>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ul className="index-list list-unstyled">
                                        <Zoom collapse duration={2000}>
                                            <li className="list-item">
                                                <img src={require("assets/img/home/interface-calendar-check.svg")} className="mr-4 icon-width"/>
                                                Maintain Actions & RAID items
                                            </li>
                                            <li className="list-item">
                                                <img src={require("assets/img/home/interface-calendar-settings.svg")} className="mr-4 icon-width"/>
                                                Assign owners and mitigation
                                            </li>
                                            <li className="list-item">
                                                <img src={require("assets/img/home/interface-calendar-extend.svg")} className="mr-4 icon-width"/>
                                                Capture progressive notes per item
                                            </li>
                                        </Zoom>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm="12" md={{size: 6}}>
                    <Row className="calendar-image calendar-section-r">
                        <Col className="pr-0">
                            <Fade right>
                                <div className="image-border-left calendar-img-wrapper border-blue-gradient">
                                    <img src={require('assets/img/home/Raid.png')}></img>
                                    <div className="calendar-gradient"></div>
                                </div>
                            </Fade>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="section execution-section">
                <Col sm="12" md="6">
                    <Row className="execution-image">
                        <Col className="execution-image-l">
                            <Fade left>
                                <div className="execution-image-border">
                                    <img src={require('assets/img/home/execution.png')}></img>
                                </div>
                            </Fade>
                            <div className="execution-gradient"></div>
                        </Col>
                    </Row>
                </Col>
                <Col sm="12" md={{size: 5, offset: 1}} className="execution-content-section">
                    <Row className="feature-title execution-section-r mb-2">
                        <Col><Zoom collapse duration={2000}>Execution Mode</Zoom></Col>
                    </Row>
                    <Row className="mb-4 section-sub-content">
                        <Col sm="12" md={{size: 9}}>
                            <Zoom collapse duration={2000}>
                                A well prepared and rehearsed CutOverPlan is a key ingredient to a successful go-live. Running dress rehearsals to create test environments prior to a production deployment provides the ability to surface missed tasks and confirms task time estimates to properly inform the timeframe required for the deployment change window.
                            </Zoom>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ul className="index-list list-unstyled">
                                <Zoom collapse duration={2000}>
                                    <li className="list-item">
                                        <img src={require("assets/img/home/interface-calendar-check.svg")} className="mr-4 icon-width"/>
                                        Planning mode
                                    </li>
                                    <li className="list-item">
                                        <img src={require("assets/img/home/interface-calendar-settings.svg")} className="mr-4 icon-width"/>
                                        Dress Rehearsals
                                    </li>
                                    <li className="list-item">
                                        <img src={require("assets/img/home/interface-calendar-extend.svg")} className="mr-4 icon-width"/>
                                        Compare rehearsals to the plan
                                    </li>
                                </Zoom>
                            </ul>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="feature-describe">
                <Col sm="12" md="4" className="text-center feature-collaboration">
                    <Fade top><img src={require('assets/img/home/collaboration.svg')} /></Fade>
                    <Fade bottom><div className="feature-title mb-2">Collaboration</div></Fade>
                    <Fade bottom>
                        <div className="feature-subcontent">
                            Collaborate with all deployment team participants in the preparation, maintenance and implementation of your CutOverPlan.
                        </div>
                    </Fade>
                </Col>
                <Col sm="12" md="4" className="text-center feature-visibility">
                    <Fade top><img src={require('assets/img/home/visibility.svg')} /></Fade>
                    <Fade bottom><div className="feature-title mb-2">Visibility</div></Fade>
                    <Fade bottom>
                        <div className="feature-subcontent">
                            CutOverPlan is highly accessible and visible, so any permitted user may view or edit the plan at any time.
                        </div>
                    </Fade>
                </Col>
                <Col sm="12" md="4" className="text-center">
                    <Fade top><img src={require('assets/img/home/contextual_info.svg')} /></Fade>
                    <Fade bottom><div className="feature-title mb-2">Contextual info</div></Fade>
                    <Fade bottom>
                        <div className="feature-subcontent">
                            All information needed to support each task like scripts, work instructions, test plans, configuration files and external links may be stored within CutOverPlan so it’s readily accessible.
                        </div>
                    </Fade>
                </Col>
            </Row>
            <Row className="price-content">
                <Col className="mb-5" sm="12">
                    <div className="section-price-card-title">
                        Pricing Plans
                    </div>
                    <div className="price-subcontent">Free Trial – register now for a limited time for an introductory free standard subscription. </div>
                </Col>
            </Row>
            {/* <Row>
                <Col className="price-change">
                    <span className="mr-3">Monthly</span>
                    <span className="mr-3"><Switch wrapperClass="m-0" onText="" offText="" animate={false}></Switch></span>
                    <span className="mr-3">Yearly</span>
                    <span className="discount-tag">Save 25%</span>
                </Col>
            </Row> */}
            <Row className="section-price-card">
                <Col>
                    <Fade left>
                        <div className="price-card price-free">
                            <div>
                                <div className="price-card-title">Collaborate Free</div>
                                <div className="price-subtitle">Unlimited project</div>
                                <div className="price-feature-list">
                                    <div className="price-feature">
                                        <img src={require('assets/img/home/check_circle.svg')} className="mr-2"/>
                                        Edit Tasks
                                    </div>
                                    <div className="price-feature">
                                        <img src={require('assets/img/home/check_circle.svg')} className="mr-2"/>
                                        Edit Actions
                                    </div>
                                    <div className="price-feature">
                                        <img src={require('assets/img/home/check_circle.svg')} className="mr-2"/>
                                        Edit RAID
                                    </div>
                                </div>
                            </div>
                            <div className="price-divider"></div>
                            <div className="price-card-price">$0</div>
                            <div className="price-per">per month</div>
                            <div>
                                <button type="button" className="btn-price-choose index-button-primary" onClick={e => selectPlan('collaborator')}>Choose plan</button>
                            </div>
                        </div>
                    </Fade>
                </Col>
                <Col>
                    <Fade bottom>
                        <div className="price-card price-standard">
                            <div>
                                <div className="price-card-title">Standard - free trial</div>
                                <div className="price-subtitle">Up to 1 projects</div>
                                <div className="price-feature-list">
                                    <div className="price-feature">
                                        <img src={require('assets/img/home/check_circle.svg')} className="mr-2"/>
                                        Create/edit Projects
                                    </div>
                                    <div className="price-feature">
                                        <img src={require('assets/img/home/check_circle.svg')} className="mr-2"/>
                                        Create/edit Tasks
                                    </div>
                                    <div className="price-feature">
                                        <img src={require('assets/img/home/check_circle.svg')} className="mr-2"/>
                                        Create/edit Actions, RAID
                                    </div>
                                    {/* <div className="price-feature">
                                        <img src={require('assets/img/home/check_circle.svg')} className="mr-2"/>
                                        30mb File Storage
                                    </div> */}
                                </div>
                            </div>
                            <div className="price-divider"></div>
                            <div className="price-card-price">$0</div>
                            <div className="price-per">per month</div>
                            <div>
                                <button type="button" className="btn-price-choose index-button-primary" onClick={e => selectPlan('standard_trial')}>Choose plan</button>
                            </div>
                        </div>
                    </Fade>
                </Col>
                <Col>
                    <Fade bottom>
                        <div className="price-card price-standard">
                            <div>
                                <div className="price-card-title">Standard</div>
                                <div className="price-subtitle">Up to 3 projects</div>
                                <div className="price-feature-list">
                                    <div className="price-feature">
                                        <img src={require('assets/img/home/check_circle.svg')} className="mr-2"/>
                                        Create/edit Projects
                                    </div>
                                    <div className="price-feature">
                                        <img src={require('assets/img/home/check_circle.svg')} className="mr-2"/>
                                        Create/edit Tasks
                                    </div>
                                    <div className="price-feature">
                                        <img src={require('assets/img/home/check_circle.svg')} className="mr-2"/>
                                        Create/edit Actions, RAID
                                    </div>
                                    <div className="price-feature">
                                        <img src={require('assets/img/home/check_circle.svg')} className="mr-2"/>
                                        30mb File Storage
                                    </div>
                                </div>
                            </div>
                            <div className="price-divider"></div>
                            <div className="price-card-price">$25</div>
                            <div className="price-per">per month</div>
                            <div>
                                <button type="button" className="btn-price-choose index-button-primary" onClick={e => selectPlan('standard')}>Choose plan</button>
                            </div>
                        </div>
                    </Fade>
                </Col>
                {/* <Col>
                    <Fade right>
                        <div className="price-card price-premium">
                            <div>
                                <div className="price-card-title">Enterprise</div>
                                <div className="price-subtitle">Unlimited projects</div>
                                <div className="price-feature-list">
                                    <div className="price-feature">
                                        <img src={require('assets/img/home/check_circle_light.svg')} className="mr-2"/>
                                        Use your own Cloud Infrastructure
                                    </div>
                                    <div className="price-feature">
                                        <img src={require('assets/img/home/check_circle_light.svg')} className="mr-2"/>
                                        Single Sign On
                                    </div>
                                    <div className="price-feature">
                                        <img src={require('assets/img/home/check_circle_light.svg')} className="mr-2"/>
                                        100 collaborate free users
                                    </div>
                                    <div className="price-feature">
                                        <img src={require('assets/img/home/check_circle_light.svg')} className="mr-2"/>
                                        200mb File Storage
                                    </div>
                                </div>
                            </div>
                            <div className="price-divider"></div>
                            <div className="price-card-price">$49</div>
                            <div className="price-per">per month</div>
                            <div>
                                <button type="button" className="btn-price-premium">Choose plan</button>
                            </div>
                        </div>
                    </Fade>
                </Col> */}
            </Row>
            <Row className="section-price-feature d-none">
                <Col className="col-price-feature" sm="12" md="6">
                    <div className="price-feature-title">
                        <img src={require("assets/img/home/check_circle.svg")} className="mr-4"/>Unlimited support
                    </div>
                    <div className="price-feature">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus mauris porta enim massa sit ac. Facilisi quis urna semper neque enim, amet morbi vel.
                    </div>
                </Col>
                <Col className="col-price-feature" sm="12" md="6">
                    <div className="price-feature-title">
                        <img src={require("assets/img/home/check_circle.svg")} className="mr-4"/>Get free updates
                    </div>
                    <div className="price-feature">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus mauris porta enim massa sit ac. Facilisi quis urna semper neque enim, amet morbi vel.
                    </div>
                </Col>
            </Row>
            <Row className="section-price-feature section-price-feature-2 d-none">
                <Col className="col-price-feature" sm="12" md="6">
                    <div className="price-feature-title">
                        <img src={require("assets/img/home/check_circle.svg")} className="mr-4"/>Unlimited support
                    </div>
                    <div className="price-feature">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus mauris porta enim massa sit ac. Facilisi quis urna semper neque enim, amet morbi vel.
                    </div>
                </Col>
                <Col className="col-price-feature" sm="12" md="6">
                    <div className="price-feature-title">
                        <img src={require("assets/img/home/check_circle.svg")} className="mr-4"/>Get free updates
                    </div>
                    <div className="price-feature">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus mauris porta enim massa sit ac. Facilisi quis urna semper neque enim, amet morbi vel.
                    </div>
                </Col>
            </Row>
            <Row className="section-testimonial">
                <Col className="testimonial-card" sm="12" md="6">
                    <Carousel
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                    >
                        {slides}
                        {/* <CarouselControl cssModule={{'color': 'arrows'}} direction="prev" directionText="Previous" onClickHandler={previous} />
                        <CarouselControl cssModule={{'color': 'arrows'}} direction="next" directionText="Next" onClickHandler={next} /> */}
                    </Carousel>
                    <div className="testimonial-gradient"></div>
                </Col>
            </Row>
            <Row className="justify-content-center section-slider">
                <Col sm="12" md={{size: 5}}>
                    <div className="p-slider">
                        <div className="p-slider-circle-blue">
                            <div className="p-slider-circle-blue-inner"></div>
                        </div>
                        <div className="p-slider-circle-green">
                            <div className="p-slider-circle-green-inner">
                                <img src={require('assets/img/home/tick.svg')} className="" />
                                <div className="complete_circle_gradient"></div>
                            </div>
                        </div>
                        <div className="slider-p-complete">PROJECT COMPLETE, WELL DONE!</div>
                    </div>
                    <div className="slider-title">
                        Ready to get started?
                    </div>
                    <div className="slider-subcontent text-center px-4 mb-4">
                    Setup your projects and provide full visibility across all project participants. No credit card required.
                    </div>
                    <div className="d-flex justify-content-center px-5 get-started-buttons">
                        <button className="index-button-black mr-3 get-started-button" onClick={() => handleSignup()}>Start a free trial</button>
                        <button className="index-button-primary mr-3 get-started-button" onClick={() => handleContactUs()}>Contact us</button>
                    </div>
                </Col>
            </Row>
            <Row className="section-our-story">
                <Col sm="12" md={{size: 4, offset: 2}} className="our-story-content">
                    <div className="our-story-title mb-2">Our story</div>
                        <div className="feature-subcontent">At its heart, CutOverPlan was driven from the need for IT project deployment plans to be collaborated on with a broad audience, during both their preparation and execution. The ability to monitor production deployment progress in real time surfaces to all stakeholders whether the plan is executing to schedule or not. It also flags to each task owner, the precise time for when their suite of tasks will require execution.
                        <br />
                        <br />
                        Often, in preparation for deployment, there are numerous actions and RAID items that require monitoring to support a successful outcome. These critical items necessary to support a successful production deployment have been incorporated into CutOverPlan.
                    </div>
                    <div className="our-story-left-gradient"></div>
                </Col>
                <Col sm="12" md="6">
                    <img src={require("assets/img/home/ourstory.png")} alt="our story"></img>
                    <img src={require("assets/img/home/cloud_light.png")} alt="our story" className="img-cloud-light"></img>
                    <img src={require("assets/img/home/cloud_dark.png")} alt="our story" className="img-cloud-dark"></img>
                    <img src={require("assets/img/home/spring.png")} alt="our story" className="img-spring"></img>
                    <img src={require("assets/img/home/arc.png")} alt="our story" className="img-arc"></img>
                    <img src={require("assets/img/home/green-toggle.png")} alt="our story" className="img-green-toggle"></img>
                    <div className="our-story-right-gradient"></div>
                </Col>
            </Row>
            <Row className="section-footer">
                <Col>
                    <Row>
                        <Col>
                            <img src={require("assets/img/home/logocop.svg")}></img>
                        </Col>
                        <Col className="text-right mb-4">
                            <div className="social-icons">
                                <img src={require("assets/img/home/facebook.svg")} alt="facebook" className="social-icon"></img>
                                <img src={require("assets/img/home/twitter.svg")} alt="twitter" className="social-icon"></img>
                                <img src={require("assets/img/home/instagram.svg")} alt="instagram" className="social-icon"></img>
                            </div>

                        </Col>
                    </Row>
                    <Row className="footer-row">
                        <Col className="footer-copyright">
                            Copyright © 2021 CutOverPlan. All rights reserved.
                        </Col>
                        <Col className="footer-links mb-4">
                            <span className="footer-link">Terms & Conditions</span>
                            <span className="footer-link">Privacy Policy</span>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </>);
}