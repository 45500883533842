/**
 * Formats the action for dropdown
 * @param {Object} action 
 */
const formatActionForDropdown = (action) => {
    if (action.id && action.description) {
        return {
            value: action.id,
            label: " (" + action.description + ")"
        };
    }
}

/**
 * Formats the action list for dropdown
 * @param {Array} actionList 
 */
const formatActionForDropdownBulk = (actionList, currentActionId) => {
    return actionList.map(action => {
        return {...formatActionForDropdown(action)};
    }).filter(action => action.value !== currentActionId);
}


const ActionUtil = {
    formatActionForDropdown,
    formatActionForDropdownBulk,
};

export default ActionUtil;