import React from "react";
import { ErrorMessage } from '@hookform/error-message';
import './CustomErrorMessage.css';

export default function CustomErrorMessage (props) {
    return (
        <div className="custom-error-message">
            <ErrorMessage errors={props.errors} name={props.name}>
            </ErrorMessage>
        </div>
    )
}