import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import ENUMS from 'constants/appEnums';
import allActions from 'redux/actions/index';
import { useDispatch } from "react-redux";
import { useToasts } from 'react-toast-notifications';

export default function GoogleLogin(props) {

    let history = useHistory();
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const redirectAuthenticatedUser = () => {
        console.log("User is authenticated through google");
        const search = props.location.search;
        const params = new URLSearchParams(search);
        let token = params.get('token');
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.AUTH_GOOGLE_SIGN_IN, 
        {
            ...HttpUtil.authHttpHeaders,
            queryStringParameters: {
                token: token
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                console.log("User is authenticated after submit");
                dispatch(allActions.authActions.setUserState(res.data.user));
                dispatch(allActions.authActions.setUserProjectList(res.data.projectList));
                localStorage.setItem('JWT', res.data.new_token);
                history.push('/admin/projects-home');
            }
            else {
                addToast(res.data.message, { 
                    appearance: 'error',
                    autoDismiss: true
                });
                console.log("User is not authenticated after submit");
                history.push('/auth/login');
            }
        })
        .catch(error => {
            if (error.response) {
                console.error(error.response);
                addToast(
                    error.response.data.message ? error.response.data.message : "Invalid Credentials", 
                    { 
                        appearance: 'error',
						autoDismiss: true
                    }
                );
            }
        })
    }

    useEffect( () => {
        redirectAuthenticatedUser();
    }, []);

    
    return (
        <React.Fragment>
            <p>Authenticated Successfully! Redirecting to Dashboard...</p>
        </React.Fragment>
    )
}