import { SET_USER_STATE, USER_LOGGED_OUT, SET_USER_PROJECT_LIST } from "redux/actionsTypes";

const initialState = {
    user: {},
    userProjectList: []
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_STATE: {
        const user = action.payload.user;
        return {
            ...state,
            user: { ...user}
        }
    }
    case USER_LOGGED_OUT: {
        return {
            ...state,
            user: {},
            userProjectList: []
        }
    }
    case SET_USER_PROJECT_LIST : {
        const projectList = action.payload.projectList;
        return {
            ...state,
            userProjectList: [...projectList]
        }
    }
    default:
      return state;
  }
}
