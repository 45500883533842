import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { API } from "aws-amplify";
import ENUMS from "constants/appEnums";
import HttpUtil from "util/HttpUtil";
import ReportHeader from "components/Headers/ReportHeader";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import FullPageCalendar from "components/FullPageCalendar/FullPageCalendar";
import CalendarUtil from "util/CalendarUtil";
import ProjectUtil from "util/ProjectUtil";
import { useSelector } from "react-redux";
import DateTimeUtils from "util/DateTimeUtils";
import { format } from "date-fns";

export default function CalendarReport(props) {

    /**
     * Location hook
     */
    const location = useLocation();
    //User props
    const current_user = useSelector(state => state.authReducer.user);

    /**
     * Project
     */
    const [projectId, setProjectId] = useState(null);
    const [projectDetails, setProjectDetails] = useState({});

    /**
     * TOAST hook
     */
    const { addToast } = useToasts();

    const fetchProjectDetails = (projectIdPassed) => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_GET_SINGLE,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                projectId: projectIdPassed
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success === true) {
                const projectDetailsData = res.data.projectDetails
                projectDetailsData.Tasks.map(task => {
                    const timeDifference = ProjectUtil.findTimeDifference(projectDetailsData.Project_Setting.AssignedCalendar);
                    const timeDuration = ProjectUtil.convertTaskDurationForUI(
                        task.task_duration,
                        projectDetailsData.Project_Setting.default_duration_format,
                        timeDifference
                    );
                    task['duration_view'] = timeDuration + 
                        (projectDetailsData.Project_Setting.default_duration_format === ENUMS.DURATION_FORMAT_VALUES.HOUR ? 'Hrs' : 'Days');
                    task['start_time_view'] = format(DateTimeUtils.convertTimezone(
                        new Date(task.task_start_time), current_user), 
                        DateTimeUtils.getUserDateTimeFormat(current_user.date_format, current_user.twenty_four_hour_clock));
                    task['end_time_view'] = format(DateTimeUtils.convertTimezone(
                        new Date(task.task_end_time), current_user), 
                        DateTimeUtils.getUserDateTimeFormat(current_user.date_format, current_user.twenty_four_hour_clock));


                    return task;
                });
                setProjectDetails(projectDetailsData);
            }
        })
        .catch(error => {
            if (error.response) {
                console.error(error.response);
                addToast(
                    error.response || "Failed to fetch project details",
                    {
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        });
    }

    const getProjectDetails = () => {
        if (location.state && location.state.passedId) {
            setProjectId(location.state.passedId);
            fetchProjectDetails(location.state.passedId);
        }
        else if (location.search) {
            const params = new URLSearchParams(location.search);
            const projectIdSearch = params.get('projectId');
            setProjectId(projectIdSearch);
            fetchProjectDetails(projectIdSearch);
        }
    }

    const printCalendar = () => {
        window.print()
    }

    useEffect (() => {
        getProjectDetails();
    }, []);

	return (
        <>
            <ReportHeader
                projectDetails={projectDetails}
                printFunction={printCalendar}
            />
            <style>
                {`@media print {#sidenav-main{display: none;}.main-content{margin-left: 0px;}}`}
            </style>
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="12">
                                        Calendar Report
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {
                                    projectDetails.id && 
                                    <FullPageCalendar
                                        events={CalendarUtil.formatTaskListForFullCalendar(projectDetails.Tasks)}
                                        headerToolbar={
                                            {
                                                left: 'prev,next today',
                                                center: 'title',
                                                right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                            }
                                        }
                                    >
                                    </FullPageCalendar>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
	);
}
