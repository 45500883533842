import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import parse from 'html-react-parser';

export default function ConfirmationModal(props) {
    return (
        <Modal
            backdrop="static"
            size="md"
            isOpen={props.showModal}
            toggle={() => props.onCancel()}
        >
            <ModalHeader toggle={() => props.onCancel()} tag="h2">{props.modalTitle}</ModalHeader>
            <ModalBody>
                {parse(props.modalBody)}
            </ModalBody>
            <ModalFooter>
                <Button 
                    color={props.primaryButtonColor ? props.primaryButtonColor : "danger" }
                    onClick={() => props.onConfirm(props.data)}
                >
                    {props.actionText.confirm}
                </Button>
                {' '}
                <Button 
                    color="secondary" 
                    onClick={() => props.onCancel()}
                >
                    {props.actionText.cancel}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
