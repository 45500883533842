import { API } from "aws-amplify";
import ENUMS from "constants/appEnums";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, CardHeader, CardBody, Card, Button } from "reactstrap";
import HttpUtil from "util/HttpUtil";
import Switch from "react-bootstrap-switch";
import { useToasts } from 'react-toast-notifications';
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";

/**
 * Function rendered for notification settings page
 * @param {Props} props 
 */
export default function NotificationSettings (props) {

    const { addToast } = useToasts();

    // grab current state
    const user = useSelector(state => state.authReducer.user);

    const [projectUpdateEmail, setProjectUpdateEmail] = useState(true);
    const [taskOwnerEmail, setTaskOwnerEmail] = useState(true);
    const [taskAddEmail, setTaskAddEmail] = useState(true);
    const [taskAssignedEmail, setTaskAssignedEmail] = useState(true);
    const [taskUpdateEmail, setTaskUpdateEmail] = useState(true);
    const [teamLeaderEmail, setTeamLeaderEmail] = useState(true);
    const [teamAssignedEmail, setTeamAssignedEmail] = useState(true);
    const [teamUpdateEmail, setTeamUpdateEmail] = useState(true);
    const [actionUpdateEmail, setActionUpdateEmail] = useState(true);
    const [actionOwnerEmail, setActionOwnerEmail] = useState(true);

    const getUserNotificationSettings = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.NOTIFICATION_GET_EMAIL_SETTINGS,
            {
                ...HttpUtil.adminHttpHeaders(),
                queryStringParameters: {
                    user_id: user.id,
                    project_id: props.projectId
                }
            })
            .then(res => {
                if(res && res.status === 200) {
                    const userEmailNotification = res.data.userEmailNotification;
                    setProjectUpdateEmail(userEmailNotification.project_update_email);
                    setTaskOwnerEmail(userEmailNotification.task_owner_email);
                    setTaskAddEmail(userEmailNotification.task_add_email);
                    setTaskAssignedEmail(userEmailNotification.task_assigned_email);
                    setTaskUpdateEmail(userEmailNotification.task_update_email);
                    setTeamLeaderEmail(userEmailNotification.team_leader_email);
                    setTeamAssignedEmail(userEmailNotification.team_assigned_email);
                    setTeamUpdateEmail(userEmailNotification.team_update_email);
                    setActionUpdateEmail(userEmailNotification.action_owner_email);
                    setActionOwnerEmail(userEmailNotification.action_update_email);
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    const saveNotificationSettings = () => {
        API.put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.NOTIFICATION_UPDATE_EMAIL_SETTINGS,
            {
                ...HttpUtil.adminHttpHeaders(),
                body:
                {
                    user_id: user.id,
                    project_id: props.projectId,
                    actionId: props.editActionId,
                    projectUpdateEmail,
                    taskOwnerEmail,
                    taskAddEmail,
                    taskAssignedEmail,
                    taskUpdateEmail,
                    teamLeaderEmail,
                    teamAssignedEmail,
                    teamUpdateEmail,
                    actionUpdateEmail,
                    actionOwnerEmail,
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success) {
                    addToast(
                        "Notification settings updated.", 
                        { 
                            appearance: 'success',
                            autoDismiss: true
                        }
                    );
                }
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        "Failed to update notificaiton", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
    }

    useEffect(() => {
        getUserNotificationSettings();
    }, []);
    return (
        <React.Fragment>
			<Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="bg-secondary box-shadow-down card-border-radius-0">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center">
                                <Col xs="6">
                                    <h3 className="mb-0">Email Notifications <i class="fas fa-info-circle" id="noti_info"></i></h3>
                                    <UncontrolledTooltip delay={0} placement="right" target="noti_info">Enable the emails you wish to be notified about.</UncontrolledTooltip>
                                </Col>
								<Col className="text-right">
                                    <Button 
                                        color="primary" 
                                        onClick={() => saveNotificationSettings()}>
                                        Save Changes
                                    </Button>
								</Col>
                            </Row>
                        </CardHeader>
                        <CardBody id="attachDropdownsAt">
                            <Container fluid className="mb-3">
                                <Row>
                                    <Col><h2>Project</h2></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="d-flex">
                                            <div className="mb-2" id="Project_updates_email">Project updates email</div>
                                            <UncontrolledTooltip delay={0} placement="top" target="Project_updates_email">This email notification is for Project updates email.</UncontrolledTooltip>
                                            <div className="ml-2"><Switch value={projectUpdateEmail} className="cop-btn-switch" onChange={() => setProjectUpdateEmail(!projectUpdateEmail)} name='test' /></div>
                                        </div>
                                    </Col>
                                </Row>
                                <hr></hr>
                            </Container>
                            <Container fluid className="mb-3">
                                <Row>
                                    <Col><h2>Task</h2></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="d-flex">
                                            <div className="mb-2" id="Task_owner_email">Task owner email</div>
                                            <UncontrolledTooltip delay={0} placement="top" target="Task_owner_email">This email notification is for Task owner email.</UncontrolledTooltip>
                                            <div className="ml-2"><Switch value={taskOwnerEmail} className="cop-btn-switch" onChange={() => setTaskOwnerEmail(!taskOwnerEmail)} name='test' /></div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="d-flex">
                                            <div className="mb-2" id="Task_add_email">Task add email</div>
                                            <UncontrolledTooltip delay={0} placement="top" target="Task_add_email">This email notification is for Task add email.</UncontrolledTooltip>
                                            <div className="ml-2"><Switch value={taskAddEmail} className="cop-btn-switch" onChange={() => setTaskAddEmail(!taskAddEmail)} name='test' /></div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="d-flex">
                                            <div className="mb-2" id="Task_assigned_email">Task assigned email</div>
                                            <UncontrolledTooltip delay={0} placement="top" target="Task_assigned_email">This email notification is for Task assigned email.</UncontrolledTooltip>
                                            <div className="ml-2"><Switch value={taskAssignedEmail} className="cop-btn-switch" onChange={() => setTaskAssignedEmail(!taskAssignedEmail)} name='test' /></div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="d-flex">
                                            <div className="mb-2" id="Task_update_email">Task update email</div>
                                            <UncontrolledTooltip delay={0} placement="top" target="Task_update_email">This email notification is for Task update email.</UncontrolledTooltip>
                                            <div className="ml-2"><Switch value={taskUpdateEmail} className="cop-btn-switch" onChange={() => setTaskUpdateEmail(!taskUpdateEmail)} name='test' /></div>
                                        </div>
                                    </Col>
                                </Row>
                                <hr></hr>
                            </Container>
                            <Container fluid className="mb-3">
                                <Row>
                                    <Col><h2>Team</h2></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="d-flex">
                                            <div className="mb-2" id="Team_leader_email">Team leader email</div>
                                            <UncontrolledTooltip delay={0} placement="top" target="Team_leader_email">This email notification is for Team leader email.</UncontrolledTooltip>
                                            <div className="ml-2"><Switch value={teamLeaderEmail} className="cop-btn-switch" onChange={() => setTeamLeaderEmail(!teamLeaderEmail)} name='test' /></div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="d-flex">
                                            <div className="mb-2" id="Team_assigned_email">Team assigned email</div>
                                            <UncontrolledTooltip delay={0} placement="top" target="Team_assigned_email">This email notification is for Team assigned email.</UncontrolledTooltip>
                                            <div className="ml-2"><Switch value={teamAssignedEmail} className="cop-btn-switch" onChange={() => setTeamAssignedEmail(!teamAssignedEmail)} name='test' /></div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="d-flex">
                                            <div className="mb-2" id="Team_update_email">Team update email</div>
                                            <UncontrolledTooltip delay={0} placement="top" target="Team_update_email">This email notification is for Team update email.</UncontrolledTooltip>
                                            <div className="ml-2"><Switch value={teamUpdateEmail} className="cop-btn-switch" onChange={() => setTeamUpdateEmail(!teamUpdateEmail)} name='test' /></div>
                                        </div>
                                    </Col>
                                    <Col></Col>
                                </Row>
                                <hr></hr>
                            </Container>
                            <Container fluid className="mb-3">
                                <Row>
                                    <Col><h2>Action</h2></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="d-flex">
                                            <div className="mb-2" id="Action_owner_email">Action owner email</div>
                                            <UncontrolledTooltip delay={0} placement="top" target="Action_owner_email">This email notification is for Action owner email.</UncontrolledTooltip>
                                            <div className="ml-2"><Switch value={actionUpdateEmail} className="cop-btn-switch" onChange={() => setActionUpdateEmail(!actionUpdateEmail)} name='test' /></div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="d-flex">
                                            <div className="mb-2" id="Action_update_email">Action update email</div>
                                            <UncontrolledTooltip delay={0} placement="top" target="Action_update_email">This email notification is for Action update email.</UncontrolledTooltip>
                                            <div className="ml-2"><Switch value={actionOwnerEmail} className="cop-btn-switch" onChange={() => setActionOwnerEmail(!actionOwnerEmail)} name='test' /></div>
                                        </div>
                                    </Col>
                                    <Col></Col>
                                    <Col></Col>
                                </Row>
                            </Container>
                        </CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
    );
}