/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes/routes.js";
import ENUMS from 'constants/appEnums';
import Loader from "components/Loader/Loader.js";
import APP_CONSTANTS from "constants/appConstants";

class Auth extends React.Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    document.body.classList.add("index-light-theme");
    // document.body.classList.add("bg-default");
    // document.body.classList.add("index-page");
    this.setState({loading: true})
    setTimeout(() => { 
          this.setState({loading: false})
    }, 500);
  }

  componentWillUnmount() {
    document.body.classList.remove("index-light-theme");
    // document.body.classList.remove("bg-default");
    // document.body.classList.remove("index-page");
  }

  getRoutes = routes => {
    if (this.props.isAuthenticated && APP_CONSTANTS.LOGIN_TO_APP_ROUTES.indexOf(this.props.location.pathname) > -1) {
      return <Redirect to="/admin/project-home" />
    }
    return routes.map((prop, key) => {
      if (prop.layout === ENUMS.ROUTES.AUTH) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  
  render() {
    return (
      <>
      {
        <div className="main-content">
          {this.state.loading && <Loader/>}
          {/* <IndexNavbar /> */}
          <div className="wrapper">
            <Switch>
              {this.getRoutes(routes)}
            </Switch>
          </div>
        </div>
      }
      </>
    );
  }
}

export default Auth;