/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';
import { ToastProvider } from 'react-toast-notifications';
import ENUMS from 'constants/appEnums';

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import "assets/scss/argon-dashboard-react.scss";
import "assets/css/style.css";

// import "assets/scss/blk-design-system-react.scss?v=1.1.0";
import "assets/demo/demo.css";

import "assets/css/nucleo-icons.css";

import store from "redux/store";
import Application from "./app";
import config from './config';


const App = () => {
    return (
        <Provider store={store}>
            <ToastProvider>
                <Application/>
            </ToastProvider>
        </Provider>
    )
}

Amplify.configure({
    API: {
      endpoints: [
        {
          name: ENUMS.AWS.API_NAME,
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },
      ]
    }
});
ReactDOM.render(<App/>, document.getElementById("root"));
