import React, { useState, useEffect } from "react";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader, 
    CardBody,
    Button,
    UncontrolledTooltip,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup
} from "reactstrap";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useToasts } from "react-toast-notifications";
import { useSelector } from "react-redux";
import CustomTablePrime from "components/CustomTable/CustomTablePrime";
import TableHeadersUtil from "util/TableHeadersUtil";
import ENUMS from "constants/appEnums";
import TaskAddEditModal from "components/Task/TaskAddEditModal/TaskAddEditModal";
import GroupUtil from "util/GroupUtil";
import TaskUtil from "util/TaskUtil";
import UserUtil from "util/UserUtil";
import DateTimeUtils from "util/DateTimeUtils";
import { format } from 'date-fns';
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import RULES from "rbac/rules";
import { firstBy } from "thenby";
import _ from "lodash";
import ProjectUtil from "util/ProjectUtil";
import { parse } from "date-fns";
import APP_CONSTANTS from "constants/appConstants";
import GeneralUtils from "util/GeneralUtils";
import { MultiSelect } from 'primereact/multiselect';
import DataOptions from "constants/appDataOptions";

export default function ProjectViewTaskTable (props) {
    
    const { addToast } = useToasts();
    const animatedComponents = makeAnimated();

    const [ogTaskList, setOgTaskList] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [taskListHeader, setTaskListHeader] = useState([]);
    const [quickFilterText, setQuickFilterText] = useState("");
    const [loading, setLoading] = useState(true);
    const [selectedCol, setSelectedCol] = useState([]);
    const [selectedColDropdown, setSelectedColDropdown] = useState([]);
    const [showSelectColumnDropDown, setShowSelectColumnDropDown] = useState(false);
    const [colOptionsList, setColOptionsList] = useState([]);
    const [tableRef, setTableRef] = useState(null);
    const [preEditTableData, setPreEditTableData] = useState([]);

    /**
     * Add/Edit Modal
     */
    const [addEditTaskModalOpen, setAddEditTaskModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalButtonName, setModalButtonName] = useState("");
    const [modalMode, setModalMode] = useState("");

    // Edit Modal
    const [editTaskId, setEditTaskId] = useState(null);

    /**
     * Delete task
     */
    const [deleteTaskId, setDeleteTaskId] = useState(null);
    const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);
    const [deleteModalText, setDeleteModalText] = useState("");

    // editable table
    const [editTableText, setEditTableText] = useState(ENUMS.BUTTON_TEXT.EDIT_TABLE);
    const [tableEditMode, setTableEditMode] = useState(null);
    const [editedRows, setEditedRows] = useState([]);

    // row groups
    const [isTableGrouped, setIsTableGrouped] = useState(false);
    const [rowGroupMode, setRowGroupMode] = useState(null);
    const [rowGroupField, setRowGroupField] = useState(null);
    const [expandableRowGroups, setExpandableRowGroups] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);

    // row sorting
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [colKeyList, setColKeyList] = useState(
        ['task_number', 'task_name', 'task_description', 
        'TaskOwner', 'task_start_time', 'task_end_time', 
        'task_duration', 'task_status', 'percentage_complete', 'Task_Group', 'Teams']
    );

    // filter dropdown
    const [selectedStatusFilter, setSelectedStatusFilter] = useState();
    const [selectedPercentageCompleteFilter, setSelectedPercentageCompleteFilter] = useState();

    // paginator
    const [showPaginator, setShowPaginator] = useState(true);
    const [rows, setRows] = useState(ENUMS.TABLE_CONSTANTS.MIN_ROWS);
    const [first, setFirst] = useState(0);

    // grab current state
    const user = useSelector(state => state.authReducer.user);

    // table inline elements
    const [taskOwnerList, setTaskOwnerList] = useState([]);
    const [projectGroupList, setProjectGroupList] = useState([]);

    // rehearsal 
    const [compareWithRehearsal, setCompareWithRehearsal] = useState(null);
    const [showCompareRehearsalDropdown, setShowCompareRehearsalDropdown] = useState(false);
    const [rehearsalOptions, setRehearsalOptions] = useState([]);
    
    /**
     * Puts the ungrouped rows at the bottom of the array list
     * @param {Array} unsortedRowData 
     */
    const sortUngroupedRows = (unsortedRowData) => {
        let noGroupArray = unsortedRowData.filter(task => task.group_name === "No Group Assigned");
        noGroupArray.forEach(f => unsortedRowData.splice(
            unsortedRowData.findIndex(e => e.id === f.id), 1));
        noGroupArray.forEach(f => unsortedRowData.push(f));
        return unsortedRowData;
    }

    /**
     * Sorts the table by group name, task status and then start time.
     */
    const sortTaskByGroup = () => {
        let unsortedRowData = _.cloneDeep(taskList);
        const groupedTasksObject = _.groupBy(unsortedRowData, function(task) {
            return task.group_name;
        });
        

        let groupedTasks = [];
        Object.values(groupedTasksObject).map(val => {
            groupedTasks = [...groupedTasks, ...val.sort(
                firstBy((a,b) => {
                    return b.task_status.localeCompare(a.task_status);
                })
                .thenBy((a,b) => {
                    return new Date(a.task_start_time) - new Date(b.task_start_time);
                })
            )]
        });
        unsortedRowData = _.cloneDeep(sortUngroupedRows(groupedTasks));
        setTaskList(unsortedRowData);
    }

    /**
     * Custom sort function to sort the table by task number for grouped and non grouped modes.
     * @param {Event} e 
     */
    const sortByTaskNum = (e, unsortedRowData) => {
        let value = [...unsortedRowData];
        if (localStorage.getItem(ENUMS.DT_STATE_STORAGE_NAMES.GROUP_TASK + props.projectDetails.id) == 'true') {
            const groupedTasksObject = _.groupBy(value, function(task) {
                return task.group_name;
            });
            let groupedTasks = [];
            Object.values(groupedTasksObject).map(val => {
                groupedTasks = [...groupedTasks, ...val.sort((a,b) => {
                    return e.order * a.task_number.localeCompare(b.task_number);
                })]
            })
            value = _.cloneDeep(sortUngroupedRows(groupedTasks));
            setExpandableRowGroups(true);
        }
        else {
            value.sort(
                firstBy((a,b) => {
                    return e.order * a.task_number.localeCompare(b.task_number);
                })
            );
        }
        return value;
    }
    
    /**
     * Custom sort function to sort the table by name for grouped and non grouped modes.
     * @param {Event} e 
     */
    const sortByName = (e, unsortedRowData) => {
        let value = [...unsortedRowData];
        if (localStorage.getItem(ENUMS.DT_STATE_STORAGE_NAMES.GROUP_TASK + props.projectDetails.id) == 'true') {
            const groupedTasksObject = _.groupBy(value, function(task) {
                return task.group_name;
            });
            let groupedTasks = [];
            Object.values(groupedTasksObject).map(val => {
                groupedTasks = [...groupedTasks, ...val.sort((a,b) => {
                    return e.order * a.task_name.localeCompare(b.task_name);
                })]
            })
            value = _.cloneDeep(sortUngroupedRows(groupedTasks));
            setExpandableRowGroups(true);
        }
        else {
            value.sort(
                firstBy((a,b) => {
                    return e.order * a.task_name.localeCompare(b.task_name);
                })
            );
        }
        return value;
    } 

    /**
     * Custom sort function to sort the table by task status for grouped and non grouped modes.
     * @param {Event} e 
     */
    const sortByStatus = (e, unsortedRowData) => {
        let value = [...unsortedRowData];
        if (localStorage.getItem(ENUMS.DT_STATE_STORAGE_NAMES.GROUP_TASK + props.projectDetails.id) == 'true') {
            const groupedTasksObject = _.groupBy(value, function(task) {
                return task.group_name;
            });
            let groupedTasks = [];
            Object.values(groupedTasksObject).map(val => {
                groupedTasks = [...groupedTasks, ...val.sort((a,b) => {
                    return e.order * a.task_status.localeCompare(b.task_status);
                })]
            })
            value = _.cloneDeep(sortUngroupedRows(groupedTasks));
            setExpandableRowGroups(true);
        }
        else {
            value.sort(
                firstBy((a,b) => {
                    return e.order * a.task_status.localeCompare(b.task_status);
                })
            );
        }
        return value;
    } 

    /**
     * Custom sort function to sort the table by task status for grouped and non grouped modes.
     * @param {Event} e 
     */
     const sortByPercentageComplete = (e, unsortedRowData) => {
        let value = [...unsortedRowData];
        if (localStorage.getItem(ENUMS.DT_STATE_STORAGE_NAMES.GROUP_TASK + props.projectDetails.id) == 'true') {
            const groupedTasksObject = _.groupBy(value, function(task) {
                return task.group_name;
            });
            let groupedTasks = [];
            Object.values(groupedTasksObject).map(val => {
                groupedTasks = [...groupedTasks, ...val.sort((a,b) => {
                    return e.order * a.percentage_complete.toString().localeCompare(b.percentage_complete.toString());
                })]
            })
            value = _.cloneDeep(sortUngroupedRows(groupedTasks));
            setExpandableRowGroups(true);
        }
        else {
            value.sort(
                firstBy((a,b) => {
                    return e.order * a.percentage_complete.toString().localeCompare(b.percentage_complete.toString());
                })
            );
        }
        return value;
    } 

    /**
     * Custom sort function to sort the table by task start time for grouped and non grouped modes.
     * @param {Event} e 
     */
    const sortByStartTime = (e, unsortedRowData) => {
        let value = [...unsortedRowData];
        if (localStorage.getItem(ENUMS.DT_STATE_STORAGE_NAMES.GROUP_TASK + props.projectDetails.id) == 'true') {
            const groupedTasksObject = _.groupBy(value, function(task) {
                return task.group_name;
            });
            let groupedTasks = [];
            Object.values(groupedTasksObject).map(val => {
                groupedTasks = [...groupedTasks, ...val.sort((a,b) => {
                    return e.order * (parse(
                        a.task_start_time,
                        DateTimeUtils.getUserDateTimeFormat(user.date_format, 
                            user.twenty_four_hour_clock),
                        new Date()
                    ) - parse(
                        b.task_start_time,
                        DateTimeUtils.getUserDateTimeFormat(user.date_format, 
                            user.twenty_four_hour_clock),
                        new Date()
                    ));
                })]
            })
            value = _.cloneDeep(sortUngroupedRows(groupedTasks));
            setExpandableRowGroups(true);
        }
        else {
            value.sort(
                firstBy((a,b) => {
                    return e.order * (parse(
                        a.task_start_time,
                        DateTimeUtils.getUserDateTimeFormat(user.date_format, 
                            user.twenty_four_hour_clock),
                        new Date()
                    ) - parse(
                        b.task_start_time,
                        DateTimeUtils.getUserDateTimeFormat(user.date_format, 
                            user.twenty_four_hour_clock),
                        new Date()
                    ));
                })
            );
        }
        return value;
    } 

    /**
     * Custom sort function to sort the table by task duration for grouped and non grouped modes.
     * @param {Event} e 
     */
    const sortByDuration = (e, unsortedRowData) => {
        let value = [...unsortedRowData];
        if (localStorage.getItem(ENUMS.DT_STATE_STORAGE_NAMES.GROUP_TASK + props.projectDetails.id) == 'true') {
            const groupedTasksObject = _.groupBy(value, function(task) {
                return task.group_name;
            });
            let groupedTasks = [];
            Object.values(groupedTasksObject).map(val => {
                groupedTasks = [...groupedTasks, ...val.sort((a,b) => {
                    return e.order * (a.task_duration - b.task_duration);
                })]
            })
            value = _.cloneDeep(sortUngroupedRows(groupedTasks));
            setExpandableRowGroups(true);
        }
        else {
            value.sort(
                firstBy((a,b) => {
                    return e.order * (a.task_duration - b.task_duration);
                })
            );
        }
        return value;
    }

    /**
     * Custom sort function to sort the table by task end time for grouped and non grouped modes.
     * @param {Event} e 
     */
    const sortByEndTime = (e, unsortedRowData) => {
        let value = [...unsortedRowData];
        if (localStorage.getItem(ENUMS.DT_STATE_STORAGE_NAMES.GROUP_TASK + props.projectDetails.id) == 'true') {
            const groupedTasksObject = _.groupBy(value, function(task) {
                return task.group_name;
            });
            let groupedTasks = [];
            Object.values(groupedTasksObject).map(val => {
                groupedTasks = [...groupedTasks, ...val.sort((a,b) => {
                    return e.order * (parse(
                        a.task_end_time,
                        DateTimeUtils.getUserDateTimeFormat(user.date_format, 
                            user.twenty_four_hour_clock),
                        new Date()
                    ) - parse(
                        b.task_end_time,
                        DateTimeUtils.getUserDateTimeFormat(user.date_format, 
                            user.twenty_four_hour_clock),
                        new Date()
                    ));
                })]
            })
            value = _.cloneDeep(sortUngroupedRows(groupedTasks));
            setExpandableRowGroups(true);
        }
        else {
            value.sort(
                firstBy((a,b) => {
                    return e.order * (parse(
                        a.task_end_time,
                        DateTimeUtils.getUserDateTimeFormat(user.date_format, 
                            user.twenty_four_hour_clock),
                        new Date()
                    ) - parse(
                        b.task_end_time,
                        DateTimeUtils.getUserDateTimeFormat(user.date_format, 
                            user.twenty_four_hour_clock),
                        new Date()
                    ));
                })
            );
        }
        return value;
    }

    /**
     * Custom sort function to sort the table by task owner for grouped and non grouped modes.
     * @param {Event} e 
     */
    const sortByTaskOwner = (e, unsortedRowData) => {
        let value = [...unsortedRowData];
        if (localStorage.getItem(ENUMS.DT_STATE_STORAGE_NAMES.GROUP_TASK + props.projectDetails.id) == 'true') {
            const groupedTasksObject = _.groupBy(value, function(task) {
                return task.group_name;
            });
            let groupedTasks = [];
            Object.values(groupedTasksObject).map(val => {
                groupedTasks = [...groupedTasks, ...val.sort((a,b) => {
                    return e.order * a.task_owner_name.localeCompare(b.task_owner_name);
                })]
            })
            value = _.cloneDeep(sortUngroupedRows(groupedTasks));
            setExpandableRowGroups(true);
        }
        else {
            value.sort(
                firstBy((a,b) => {
                    return e.order * a.task_owner_name.localeCompare(b.task_owner_name);
                })
            );
        }
        return value;
    }

    /**
     * Custom sort function to sort the table by task critical path for grouped and non grouped modes.
     * @param {Event} e 
     */
    const sortByCriticalPath = (e, unsortedRowData) => {
        let value = [...unsortedRowData];
        if (localStorage.getItem(ENUMS.DT_STATE_STORAGE_NAMES.GROUP_TASK + props.projectDetails.id) == 'true') {
            const groupedTasksObject = _.groupBy(value, function(task) {
                return task.group_name;
            });
            let groupedTasks = [];
            Object.values(groupedTasksObject).map(val => {
                groupedTasks = [...groupedTasks, ...val.sort((a,b) => {
                    return e.order * (a.is_critical_path === b.is_critical_path ? 0 : a.is_critical_path ? -1 : 1);
                })]
            })
            value = _.cloneDeep(sortUngroupedRows(groupedTasks));
            setExpandableRowGroups(true);
        }
        else {
            value.sort(
                firstBy((a,b) => {
                    return e.order * (a.is_critical_path === b.is_critical_path ? 0 : a.is_critical_path ? -1 : 1);
                })
            );
        }
        return value;
    }

    /**
     * Populates the task table
     * Populates the task header and data
     */
    const populateTaskTable = () => {
        if (props.projectTaskList) {
            populateTaskData();
        } else {
            setTaskList([]);
        }
    }

    const initialSort = (list) => {
        list.sort(
            firstBy((a,b) => {
                return b.task_status.localeCompare(a.task_status);
            })
            .thenBy((a,b) => {
                return new Date(a.task_start_time) - new Date(b.task_start_time);
            })
        )
        return list;
    }

    /**
     * Populates the task data.
     * Populates the owner, group, predecessor, times, critical path and status
     */
    const populateTaskData = () => {
        let updatedTaskList = props.projectTaskList.map(task => {
            task['task_number_link'] = TaskUtil.formatTaskNumber(task.id, task.task_number);
            task['task_owner_name'] = UserUtil.formatUserForTableColumn(task.TaskOwner);
            task['group_name'] = task.Task_Group ? 
                GroupUtil.formatGroupNameForTable(task.Task_Group) : "No Group Assigned";
            task['predecessor_task_name'] = task.PredecessorTask && 
                task.PredecessorTask.length > 0 ? 
                <span>
                    {
                        TaskUtil.formatPredecessorTaskForDisplay(task.PredecessorTask, props.projectDetails.Project_Setting)
                            .map((predTask, key) => 
                                <Col key={key}>
                                    {predTask}
                                </Col>
                            )
                    }
                </span> : 
                null;
            
            task['task_team_name'] = task.Teams && task.Teams.length > 0 
                    ? <span>{TaskUtil.formatTaskTeamForDisplay(task.Teams)}</span>
                    : null;
            task['task_duration'] = ProjectUtil.convertTaskDurationForUI(	
                task.task_duration,
                props.projectDetails.Project_Setting.default_duration_format,
                ProjectUtil.findTimeDifference(props.projectDetails.Project_Setting.AssignedCalendar)
            );
            const holidayOnTask = getHolidaysThatOccursOnTask(
                [
                    ...task.Task_Holidays,
                    ...props.projectDetails.Project_Setting.AssignedCalendar.Calendar_Holidays
                ],
                task.task_start_time,
                task.task_end_time
            )
            const workExceptions = getWorkExceptionsThatOccursOnTask(
                [
                    ...task.Task_Holidays,
                    ...props.projectDetails.Project_Setting.AssignedCalendar.Calendar_Holidays
                ],
                task.task_start_time,
                task.task_end_time
            )
            task['task_duration_view'] = TaskUtil.formatTaskDataForExceptionDisplay(
                task.id,
                Number(task['task_duration']).toFixed(2),
                holidayOnTask,
                workExceptions,
                user,
                props.projectDetails.Project_Setting.default_duration_format
            );
            task['task_start_time'] = format(DateTimeUtils.convertTimezone(
                new Date(task.task_start_time), user),
                DateTimeUtils.getUserDateTimeFormat(user.date_format, user.twenty_four_hour_clock));
            task['task_end_time'] = format(DateTimeUtils.convertTimezone(
                new Date(task.task_end_time), user), 
                DateTimeUtils.getUserDateTimeFormat(user.date_format, user.twenty_four_hour_clock));
            task['is_critical_path'] = TaskUtil.formatIsCriticalPath(task.is_critical_path);
            task['task_status_badge'] = TaskUtil.formatTaskStatusForTable(TaskUtil.getTaskStatusWithActiveRehearsal(task));
            task['Rehearsal_Tasks'] = task.Rehearsal_Tasks;
            const compareRehearsalId = JSON.parse(localStorage.getItem(
                ENUMS.DT_STATE_STORAGE_NAMES.COMPARE_REHEARSAL + props.projectDetails.id));
            if (compareRehearsalId) {
                task['rehearsal_start_time'] = format(DateTimeUtils.convertTimezone(
                    new Date(task.Rehearsal_Tasks.find(rehearsalTask => rehearsalTask.rehearsal_id == compareRehearsalId).start_time), user),
                    DateTimeUtils.getUserDateTimeFormat(user.date_format, user.twenty_four_hour_clock));
                const rehearsalDurationData = ProjectUtil.twoFixedPlaces(task.Rehearsal_Tasks.find(rehearsalTask => rehearsalTask.rehearsal_id == compareRehearsalId).duration)
                const durationClassName = rehearsalDurationData > task['task_duration'] ? 'text-danger' : 'text-success';
                task['rehearsal_duration_view'] = <span className={durationClassName}>{rehearsalDurationData}</span>;
                task['rehearsal_end_time'] = format(DateTimeUtils.convertTimezone(
                    new Date(task.Rehearsal_Tasks.find(rehearsalTask => rehearsalTask.rehearsal_id == compareRehearsalId).end_time), user), 
                    DateTimeUtils.getUserDateTimeFormat(user.date_format, user.twenty_four_hour_clock));
            }
            return task;
        });
        // sorts the list based on task status and start time
        updatedTaskList = initialSort(updatedTaskList);
        if (updatedTaskList.length > 0 && localStorage.getItem(ENUMS.DT_STATE_STORAGE_NAMES.SORT_FIELD + props.projectDetails.id)) {
            const e = {
                order: localStorage.getItem(ENUMS.DT_STATE_STORAGE_NAMES.SORT_ORDER + props.projectDetails.id),
                field: localStorage.getItem(ENUMS.DT_STATE_STORAGE_NAMES.SORT_FIELD + props.projectDetails.id),
            }
            updatedTaskList = sortDistributor(e, _.cloneDeep(updatedTaskList));
        }
        setTaskList(updatedTaskList);
        // a temporary original list for filtering
        setOgTaskList(updatedTaskList);
    }

    const getHolidaysThatOccursOnTask = (holidays, taskStartTime, taskEndTime) => {
        const allTaskHolidays = [];
        holidays.map(holiday => {
            if (
                holiday.exception_type === "holiday" 
                && DateTimeUtils.checkIfHolidayLiesInDate(
                    holiday.start_date,
                    holiday.end_date,
                    taskStartTime,
                    taskEndTime
                )
            ) {
                allTaskHolidays.push(holiday)
            }
        })
        return allTaskHolidays;
    }

    const getWorkExceptionsThatOccursOnTask = (holidays, taskStartTime, taskEndTime) => {
        const allTaskHolidays = [];
        holidays.map(holiday => {
            if (
                holiday.exception_type === "working_day" 
                && DateTimeUtils.checkIfHolidayLiesInDate(
                    holiday.start_date,
                    holiday.end_date,
                    taskStartTime,
                    taskEndTime
                )
            ) {
                allTaskHolidays.push(holiday)
            }
        })
        return allTaskHolidays;
    }

    /**
     * Distributes the sorting to the appropriate method.
     * @param {Event} e 
     * @param {Array} tasks 
     */
    const sortDistributor = (e, tasks) => {
        switch(e.field) {
            case 'task_number_link':
                return sortByTaskNum(e, tasks);
            case 'task_name':
                return sortByName(e, tasks);
            case 'task_status_badge':
                return sortByStatus(e, tasks);
            case 'percentage_complete':
                return sortByPercentageComplete(e, tasks);
            case 'task_start_time':
                return sortByStartTime(e, tasks);
            case 'task_duration_view':
                return sortByDuration(e, tasks);
            case 'task_end_time':
                return sortByEndTime(e, tasks);
            case 'task_owner_name':
                return sortByTaskOwner(e, tasks);
            case 'is_critical_path':
                return sortByCriticalPath(e, tasks);
        }
    }

    /**
     * Method to handle table sorting.
     * @param {Event} e 
     */
    const handleSorting = (e) => {
        return sortDistributor(e, _.cloneDeep(taskList));
    }

    /**
     * Method to handle filtering the task number column
     * @param {Link} value 
     * @param {String} filter 
     */
    const customTaskNumberFilter = (value, filter) => {
        if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }
        return value.props.children.toLowerCase().includes(filter.toLowerCase());
    }

    /**
     * Method to handle filtering the status column
     * @param {Link} value 
     * @param {String} filter 
     */
    const customStatusFilter = (e) => {
        if(e) {
            setSelectedStatusFilter(e.value);
            const filteredTaskList = ogTaskList.filter(task => task.task_status === e.value);
            setTaskList(filteredTaskList);
        } else {
            setSelectedStatusFilter(null);
            setTaskList(ogTaskList);
        }
    }

    /**
     * Method to handle filtering the status column
     * @param {Link} value 
     * @param {String} filter 
     */
     const customPercentageCompleteFilter = (e) => {
        if(e) {
            setSelectedPercentageCompleteFilter(e.value);
            const filteredTaskList = ogTaskList.filter(task => task.percentage_complete === e.value);
            setTaskList(filteredTaskList);
        } else {
            setSelectedPercentageCompleteFilter(null);
            setTaskList(ogTaskList);
        }
    }

    /**
     * Method to render the dropdown element for status filter
     * @param {Link} value 
     * @param {String} filter 
     */
    const statusFilterElement = <Select
            className="form-control-alternative"
            isClearable
            onChange={(e) => customStatusFilter(e)} 
            options={TaskUtil.taskStatusTypesInlineEdit}
            value={TaskUtil.taskStatusTypesInlineEdit.filter(statusType => statusType.value === selectedStatusFilter)}
            menuPortalTarget={document.getElementById('attachDropdownsAt')}
            menuPosition={"fixed"}
            styles={
                {
                    menu: (css) => ({
                        ...css,
                        width: '200px',
                    }),
                }
            }
        />;

    /**
     * Method to render the dropdown element for percentage filter
     * @param {Link} value 
     * @param {String} filter 
     */
     const percentageCompleteFilterElement = <Select
        className="form-control-alternative"
        isClearable
        onChange={(e) => customPercentageCompleteFilter(e)} 
        options={DataOptions.TASK_PERCENTAGE_COMPLETE_OPTIONS}
        value={DataOptions.TASK_PERCENTAGE_COMPLETE_OPTIONS.filter(pc => pc.value === selectedPercentageCompleteFilter)}
        menuPortalTarget={document.getElementById('attachDropdownsAt')}
        menuPosition={"fixed"}
        styles={
            {
                menu: (css) => ({
                    ...css,
                    width: '200px',
                }),
            }
        }
    />;

    /**
     * Method to handle filtering the predecessor task column
     * @param {Link} value 
     * @param {String} filter 
     */
    const customPredTaskFilter = (value, filter) => {
        if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }
        return value.props.children[0].props.children.props.children.filter((pred, key) => {
            if (key % 6 === 0)
                return pred.props.children.toLowerCase().includes(filter.toLowerCase());
            return false;
        }).length > 0;
    }

    const customDurationFilter = (value, filter) => {
      if (
        filter === undefined ||
        filter === null ||
        (typeof filter === "string" && filter.trim() === "")
      ) {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }
      return (
        value.props.children[0].props.children.filter((token) => {
          return token.includes(filter.toLowerCase());
        }).length > 0
      );
    };

    /**
     * Method to handle filtering the predecessor task column
     * @param {Link} value 
     * @param {String} filter 
     */
    const customTaskTeamFilter = (value, filter) => {
        if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }
        return value.props.children.filter((team, key) => team.props.children.toLowerCase().includes(filter.toLowerCase())).length > 0;
    }

    /**
     * Populates the task header for the table
     * Populates the column headers
     */
    const populateTaskHeaders = () => {
        setTaskListHeader(TableHeadersUtil.projectViewTaskTableHeaders(
            handleSorting, 
            customTaskNumberFilter,
            percentageCompleteFilterElement,
            statusFilterElement,
            customPredTaskFilter,
            customTaskTeamFilter,
            compareWithRehearsal,
            customDurationFilter
        ));
        populateColumnHeaderSelect(TableHeadersUtil.projectViewTaskTableHeaders(
            handleSorting, 
            customTaskNumberFilter,
            percentageCompleteFilterElement,
            statusFilterElement,
            customPredTaskFilter,
            customTaskTeamFilter,
            compareWithRehearsal,
            customDurationFilter
        ));
    }

    /**
     * Deserializes the column list stored in the local storage to include the 
     * custom functions which can't be stored in the local storage.
     * @param {Array} selCol 
     */
    const desrializeSelectedCol = (selCol) => {
        const headers = TableHeadersUtil.projectViewTaskTableHeaders(
            handleSorting, 
            customTaskNumberFilter,
            percentageCompleteFilterElement,
            statusFilterElement,
            customPredTaskFilter,
            customTaskTeamFilter,
            compareWithRehearsal,
            customDurationFilter
        );
        let finalHeaders = [];
        for (const header of headers) {
            for (const col of selCol) {
                if (header.field === col.field) {
                    finalHeaders.push(header);
                    break;
                }
            }
        }
        return finalHeaders;
    }

    /**
     * Populates the headers data into selectable header names for easy filtering of columns
     * @param {Array} headerData 
     */
    const populateColumnHeaderSelect = (headerData) => {
        // fetch paginator state
        if (localStorage.getItem(ENUMS.DT_STATE_STORAGE_NAMES.PAGINATOR_FIRST + props.projectDetails.id)) {
            // BIA-515
            // setFirst(localStorage.getItem(ENUMS.DT_STATE_STORAGE_NAMES.PAGINATOR_FIRST + props.projectDetails.id))
        }
        if (localStorage.getItem(ENUMS.DT_STATE_STORAGE_NAMES.PAGINATOR_ROWS + props.projectDetails.id)) {
            setRows(localStorage.getItem(ENUMS.DT_STATE_STORAGE_NAMES.PAGINATOR_ROWS + props.projectDetails.id))
        }
        // fetch the col states from the local storage
        if (localStorage.getItem(ENUMS.DT_STATE_STORAGE_NAMES.SELECTED_COL + props.projectDetails.id)) {
            setSelectedColDropdown(JSON.parse(localStorage.getItem(
                ENUMS.DT_STATE_STORAGE_NAMES.SELECTED_COL_DROPDOWN + props.projectDetails.id)));
            setSelectedCol(desrializeSelectedCol(JSON.parse(localStorage.getItem(
                ENUMS.DT_STATE_STORAGE_NAMES.SELECTED_COL + props.projectDetails.id))));
            setColOptionsList(JSON.parse(localStorage.getItem(
                ENUMS.DT_STATE_STORAGE_NAMES.SELECTED_COL_OPTIONS + props.projectDetails.id)));
            setCompareWithRehearsal(JSON.parse(localStorage.getItem(
                ENUMS.DT_STATE_STORAGE_NAMES.COMPARE_REHEARSAL + props.projectDetails.id)));
        }
        else {
            // selected column for the dropdown
            const selectedColumnsDropdown = [];
            // the selected columns in the list
            const selectedColumns = [];
            // all the columns in the list
            const colOptions = [];
            // selected columns in state
            const stateColumns = [];

            for (var i = 0; i < headerData.length; i++) {
                const taskHeader = headerData[i];
                if (!taskHeader.hidden) {
                    selectedColumnsDropdown.push({
                        label: taskHeader.headerName,
                        value: taskHeader.field
                    });
                    selectedColumns.push(taskHeader);
                    stateColumns.push({...taskHeader, filterElement: ''});
                }
                colOptions.push({
                    label: taskHeader.headerName,
                    value: taskHeader.field
                });
            }
            setSelectedColDropdown(selectedColumnsDropdown);
            setSelectedCol(selectedColumns);
            setColOptionsList(colOptions);
            // store the column header lists in the local storage
            localStorage.setItem(ENUMS.DT_STATE_STORAGE_NAMES.SELECTED_COL + props.projectDetails.id, 
                JSON.stringify(stateColumns));
            localStorage.setItem(ENUMS.DT_STATE_STORAGE_NAMES.SELECTED_COL_OPTIONS + props.projectDetails.id, 
                JSON.stringify(colOptions));
            localStorage.setItem(ENUMS.DT_STATE_STORAGE_NAMES.SELECTED_COL_DROPDOWN + props.projectDetails.id, 
                JSON.stringify(selectedColumnsDropdown));
        }
    }

    /**
     * Method to handle the select dropdown of column ordering change
     * @param {Object} newValue 
     */
    const handleSelectChange = (e) => {
        const selected = e.value.map(val => colOptionsList.find(op => op.value === (val.value || val)));
        // update the column dropdown in the local storage
        setSelectedColDropdown(selected);
        localStorage.setItem(ENUMS.DT_STATE_STORAGE_NAMES.SELECTED_COL_DROPDOWN + props.projectDetails.id, 
            JSON.stringify(selected));
        let orderedSelectedColumns = taskListHeader.filter(col => selected.some(sCol => sCol.value === col.field));
        setSelectedCol(orderedSelectedColumns);
        const stateColumns = orderedSelectedColumns.map(col => {
            return {
                ...col,
                filterElement: ''
            }
        });
        localStorage.setItem(ENUMS.DT_STATE_STORAGE_NAMES.SELECTED_COL + props.projectDetails.id, 
            JSON.stringify(stateColumns));
    }

    /**
     * Handle predecessor tasks printing for CSV
     * @param {*} predTasks 
     */
    const handlePredTaskForCSV = (predTasks) => {
        let predTaskString = "";
        if (predTasks && predTasks.length > 0) {
            predTasks.forEach(task => {
                predTaskString += task.SuccessorTask.task_number + ","
            })
            if (predTaskString[predTaskString.length - 1] === ',') {
                predTaskString = predTaskString.substring(0, predTaskString.length - 1);
            }
        }
        return GeneralUtils.handleStringForCSV(predTaskString);
    }

    /**
     * Method to handle the exporting to csv.
     * @param {Object} e 
     */
    const exportTaskList = (e) => {
        e.preventDefault();
        let csvContent = "data:text/csv;charset=utf-8,";
        const compareRehearsalId = JSON.parse(localStorage.getItem(
            ENUMS.DT_STATE_STORAGE_NAMES.COMPARE_REHEARSAL + props.projectDetails.id));
        let headers = "";
        if (compareRehearsalId) {
            headers = "Task Number,Name,Description,Status,Group,Predecessor Task,Start Time,Duration,End Time,Rehearsal Start Time, Rehearsal Duration, Rehearsal End Time, Task Owner,Is Critical Path?\r\n";
        } else {
            headers = "Task Number,Name,Description,Status,Group,Predecessor Task,Start Time,Duration,End Time,Task Owner,Is Critical Path?\r\n";
        }

        csvContent += headers;
        taskList.forEach(task => {
            console.log(task)
            let rowArray = []
            if (compareRehearsalId) {
                rowArray = [
                    GeneralUtils.handleStringForCSV(task.task_number), 
                    GeneralUtils.handleStringForCSV(task.task_name),
                    GeneralUtils.handleStringForCSV(task.task_description),
                    TaskUtil.taskStatusTypes.filter(type => type.value === task.task_status)[0].label,
                    GeneralUtils.handleStringForCSV(task.group_name),
                    handlePredTaskForCSV(task.PredecessorTask),
                    task.task_start_time,
                    task.task_duration,
                    task.task_end_time,
                    task.rehearsal_start_time,
                    ProjectUtil.twoFixedPlaces(task.Rehearsal_Tasks.find(r => r.rehearsal_id == compareRehearsalId).duration),
                    task.rehearsal_end_time,
                    UserUtil.formatUserForTableColumn(task.TaskOwner),
                    TaskUtil.formatIsCriticalPath(task.is_critical_path)];
            } else {
                rowArray = [
                    GeneralUtils.handleStringForCSV(task.task_number), 
                    GeneralUtils.handleStringForCSV(task.task_name),
                    GeneralUtils.handleStringForCSV(task.task_description),
                    TaskUtil.taskStatusTypes.filter(type => type.value === task.task_status)[0].label,
                    GeneralUtils.handleStringForCSV(task.group_name),
                    handlePredTaskForCSV(task.PredecessorTask),
                    task.task_start_time,
                    task.task_duration,
                    task.task_end_time,
                    UserUtil.formatUserForTableColumn(task.TaskOwner),
                    TaskUtil.formatIsCriticalPath(task.is_critical_path)];
            }
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", props.projectDetails.project_name + "_tasks.csv");
        document.body.appendChild(link); // Required for FF
        link.click(); // This will download the data file named "my_data.csv".
    }

    /**
     * Method to handle opening the task modal in add mode
     */
    const openAddTaskModal = () => {
        setModalTitle(ENUMS.MODAL.ADD_TASK_TITLE);
        setModalButtonName(ENUMS.BUTTON_TEXT.ADD);
        setModalMode(ENUMS.MODAL.ADD_MODE);
        setAddEditTaskModalOpen(true);
    }

    /**
     * Method to handle opening the task modal in edit mode
     */
    const openEditTaskModal = (task) => {
        setModalTitle(ENUMS.MODAL.EDIT_TASK_TITLE);
        setModalButtonName(ENUMS.BUTTON_TEXT.SAVE);
        setModalMode(ENUMS.MODAL.EDIT_MODE);
        setEditTaskId(task.id);
        setAddEditTaskModalOpen(true);
    }

    /**
     * Method to handle closing of the task modal
     */
    const closeAddEditModal = () => {
        setAddEditTaskModalOpen(false);
    };

    /**
     * Refreshes the page on submit of add/edit/delete form
     * @param {String} message 
     */
    const refreshPageOnSubmit = (message) => {
        setAddEditTaskModalOpen(false);
        setShowDeleteTaskModal(false);
        props.refreshViewOnAction(ENUMS.TABS.TASK_LIST, message);
    }

    /**
     * Method to handle the opening of the delete task modal
     * @param {Object} task 
     */
    const openDeleteConfirmationModal = (task) => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TASK_CHECK_DELETE, 
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                taskId: task.id
            }
        })
        .then(res => {
            if (res.status === 200) {
                if (res.data.success) {
                    setDeleteTaskId(task.id);
                    setDeleteModalText(ENUMS.MODAL.DELETE_TASK_TEXT + task.task_number + "?");
                    setShowDeleteTaskModal(true);
                }
                else {
                    const sucTaskString = res.data.successors.length > 0 ? " .Successors - " +
                        res.data.successors.map(pred => pred.PredecessorTask.task_number).join(",") : 
                        "";
                    addToast(
                        "Task cannot be deleted as it has existing successors. Please delete those before proceeding" + 
                        sucTaskString,
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            }
        })
        .catch(error => {
            if (error.response) {
                addToast(
                    "Failed to check deletion status of task!", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    /**
     * Method to handle the deletion of the task
     */
    const deleteTaskConfirmed = () => {
        API.del(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TASK_DELETE,
        {
            ...HttpUtil.adminHttpHeaders(),
            body: {
                taskId: deleteTaskId
            }
    
        })
        .then(res => {
            if(res && res.status === 200) {
                refreshPageOnSubmit('Task has been deleted successfully!');
            }
        })
        .catch(error => {
            if (error.response) {
                addToast(
                    "Failed to delete Task", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    /**
     * Method to reset the filters and sorting on the entire table. 
     */
    const resetTable = () => {
        tableRef.reset();
    }

    const updateTableData = () => {
        let rowsToEdit = _.cloneDeep(editedRows).filter(row => row !== null && row !== undefined);
        rowsToEdit.forEach(row => {
            const task_start_time = parse(
                row.task_start_time,
                DateTimeUtils.getUserDateTimeFormat(user.date_format, 
                    user.twenty_four_hour_clock),
                new Date()
            );
            const task_end_time = parse(
                row.task_end_time,
                DateTimeUtils.getUserDateTimeFormat(user.date_format, 
                    user.twenty_four_hour_clock),
                new Date()
            );
            row.task_start_time = DateTimeUtils.convertTimezone(task_start_time, user);
            row.task_end_time = DateTimeUtils.convertTimezone(task_end_time, user);
            row.task_duration = ProjectUtil.convertTaskDurationToHoursForDB(
                row.task_duration,
                props.projectDetails.Project_Setting.default_duration_format,
                ProjectUtil.findTimeDifference(props.projectDetails.Project_Setting.AssignedCalendar)
            );
        });
        API.put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TASK_UPDATE_TABLE_ROWS, 
        {
            ...HttpUtil.adminHttpHeaders(),
            body: 
            {
                editedRows: rowsToEdit,
                projectId: props.projectDetails.id
            }
        })
        .then(res => {
            if (res.status === 201 && res.data.success) {
                refreshPageOnSubmit("Tasks have been updated successfully!");
                setEditedRows([]);
            }
        })
        .catch(error => {
            if (error.response) {
                addToast(
                    "Failed to update tasks!", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const setEditableTable = () => {
        if (editTableText === ENUMS.BUTTON_TEXT.SAVE) {
            setEditTableText(ENUMS.BUTTON_TEXT.EDIT_TABLE);
            setTableEditMode(null);
            if (editedRows.length > 0) {
                updateTableData();
            }
            
        }
        else {
            setPreEditTableData(_.cloneDeep(taskList));
            setEditTableText(ENUMS.BUTTON_TEXT.SAVE);
            setTableEditMode(ENUMS.TABLE_CONSTANTS.EDIT_MODE_CELL);
        }
    }

    const setCancelEditableTable = () => {
        setTaskList(_.cloneDeep(preEditTableData));
        setEditTableText(ENUMS.BUTTON_TEXT.EDIT_TABLE);
        setTableEditMode(null);
    }

    // const checkIfTaskChangedFF = (taskStartTime, taskEndTime, predTasks) => {
    //     const isTaskChangedFF = false;
    //     predTasks.forEach(task => {
    //         if (task.predecessor_task_link_type === ENUMS.PREDECESSOR_TASK_LINK_TYPES.FF) {
                
    //         }
    //     })
    //     return isTaskChangedFF;
    // }

    const onEditorValueChange = (tableProps, value) => {
        let updatedProducts = _.cloneDeep(tableProps.value);
        let taskHolidays = updatedProducts[tableProps.rowIndex]['Task_Holidays'];
        if (tableProps.field === 'task_start_time') {
            updatedProducts[tableProps.rowIndex][tableProps.field] = format(
                DateTimeUtils.convertTimezone(new Date(value), user), 
                DateTimeUtils.getUserDateTimeFormat(
                    user.date_format, user.twenty_four_hour_clock));

            if (updatedProducts[tableProps.rowIndex]['task_duration']) {
                const timeDifference = ProjectUtil.findTimeDifference(
                    props.projectDetails.Project_Setting.AssignedCalendar);
                const taskDurationInHours = ProjectUtil.convertTaskDurationToHoursForDB(
                    updatedProducts[tableProps.rowIndex]['task_duration'], 
                    props.projectDetails.Project_Setting.default_duration_format, 
                    timeDifference);
                const taskEndTimeTable = DateTimeUtils.calculateTaskTimeForward(
                    props.projectDetails.Project_Setting, 
                    parse(
                        updatedProducts[tableProps.rowIndex][tableProps.field],
                        DateTimeUtils.getUserDateTimeFormat(user.date_format, 
                            user.twenty_four_hour_clock),
                        new Date()
                    ), 
                    taskDurationInHours,
                    taskHolidays);

                updatedProducts[tableProps.rowIndex]['task_end_time'] = format(
                    DateTimeUtils.convertTimezone(new Date(taskEndTimeTable), user), 
                    DateTimeUtils.getUserDateTimeFormat(
                        user.date_format, user.twenty_four_hour_clock));
            }
        }
        else if (tableProps.field === 'task_end_time') {
            updatedProducts[tableProps.rowIndex][tableProps.field] = format(
                DateTimeUtils.convertTimezone(new Date(value), user), 
                DateTimeUtils.getUserDateTimeFormat(
                    user.date_format, user.twenty_four_hour_clock));
            
            if (updatedProducts[tableProps.rowIndex]['task_duration']) {
                const timeDifference = ProjectUtil.findTimeDifference(
                    props.projectDetails.Project_Setting.AssignedCalendar);
                const taskDurationInHours = ProjectUtil.convertTaskDurationToHoursForDB(
                    updatedProducts[tableProps.rowIndex]['task_duration'], 
                    props.projectDetails.Project_Setting.default_duration_format, 
                    timeDifference);
                const taskStartTimeTable = DateTimeUtils.calculateTaskTimeBackwards(
                    props.projectDetails.Project_Setting,
                    parse(
                        updatedProducts[tableProps.rowIndex][tableProps.field],
                        DateTimeUtils.getUserDateTimeFormat(user.date_format, 
                            user.twenty_four_hour_clock),
                        new Date()
                    ), 
                    taskDurationInHours,
                    taskHolidays);

                updatedProducts[tableProps.rowIndex]['task_start_time'] = format(
                    DateTimeUtils.convertTimezone(new Date(taskStartTimeTable), user), 
                    DateTimeUtils.getUserDateTimeFormat(
                        user.date_format, user.twenty_four_hour_clock));
            }
        }
        // TODO : Check for predecessor task FF properly
        else if (tableProps.field === 'task_duration_view' && value !== '') {
            const timeDifference = ProjectUtil.findTimeDifference(
                props.projectDetails.Project_Setting.AssignedCalendar);
            const taskDurationInHours = ProjectUtil.convertTaskDurationToHoursForDB(
                parseFloat(Number(value)), 
                props.projectDetails.Project_Setting.default_duration_format, 
                timeDifference);
            updatedProducts[tableProps.rowIndex]['task_duration'] = Number(value);

            if (updatedProducts[tableProps.rowIndex]['task_start_time']) {
                const task_start_time = parse(
                    updatedProducts[tableProps.rowIndex]['task_start_time'],
                    DateTimeUtils.getUserDateTimeFormat(user.date_format, 
                        user.twenty_four_hour_clock),
                    new Date()
                );
                const taskEndTimeTable = DateTimeUtils.calculateTaskTimeForward(
                    props.projectDetails.Project_Setting, 
                    task_start_time, 
                    taskDurationInHours,
                    taskHolidays);

                updatedProducts[tableProps.rowIndex]['task_end_time'] = format(
                    DateTimeUtils.convertTimezone(new Date(taskEndTimeTable), user), 
                    DateTimeUtils.getUserDateTimeFormat(
                        user.date_format, user.twenty_four_hour_clock));
            }
            else if (updatedProducts[tableProps.rowIndex]['task_end_time']) {
                // handle backward change
                const task_end_time = parse(
                    updatedProducts[tableProps.rowIndex]['task_end_time'],
                    DateTimeUtils.getUserDateTimeFormat(user.date_format, 
                        user.twenty_four_hour_clock),
                    new Date()
                );
                const taskStartTimeTable = DateTimeUtils.calculateTaskTimeBackwards(
                    props.projectDetails.Project_Setting, 
                    task_end_time, 
                    taskDurationInHours,
                    taskHolidays);

                updatedProducts[tableProps.rowIndex]['task_start_time'] = format(
                    DateTimeUtils.convertTimezone(new Date(taskStartTimeTable), user), 
                    DateTimeUtils.getUserDateTimeFormat(
                        user.date_format, user.twenty_four_hour_clock));
            }
        }
        else if (tableProps.field === 'task_owner_name' && value) {
            updatedProducts[tableProps.rowIndex][tableProps.field] = value ? UserUtil.formatUserForTableColumnInline(value) : "";
            updatedProducts[tableProps.rowIndex]['task_owner'] = value ? value.value : "";
        }
        else if (tableProps.field === 'group_name') {
            updatedProducts[tableProps.rowIndex][tableProps.field] = value ? 
                GroupUtil.formatGroupNameForTableInline(value) : 
                "No Group Assigned";
            updatedProducts[tableProps.rowIndex]['task_group_id'] = value ? value.value : null;
        }
        else if (tableProps.field === 'task_status_badge' && value) {
            updatedProducts[tableProps.rowIndex][tableProps.field] = value.label;
            updatedProducts[tableProps.rowIndex]['task_status'] = value.value;
        }
        else if (tableProps.field === 'percentage_complete' && value) {
            updatedProducts[tableProps.rowIndex][tableProps.field] = value.label;
            updatedProducts[tableProps.rowIndex]['percentage_complete'] = value.value;
        }
        else {
            updatedProducts[tableProps.rowIndex][tableProps.field] = value;
        }
        let updatedEditedRows = _.cloneDeep(editedRows);
        updatedEditedRows[tableProps.rowIndex] = updatedProducts[tableProps.rowIndex];
        setEditedRows(updatedEditedRows);
        setTaskList(updatedProducts);
    }

    const onEditorCancel = ({columnProps}, editingCellRows) => {
        const { rowIndex, field } = columnProps;
        let products = _.cloneDeep(taskList);
        products[rowIndex][field] = editingCellRows[rowIndex][field];
        delete editingCellRows[rowIndex][field];
        setTaskList(products);
    }

    const optionsList = React.useMemo(() => 
    [
        {
            itemName: 'Select',
            itemType: 'Select',
            selectRedirectionLink: '/admin/tasks/task-view',
            selectSearchPath: '?taskId=',
        },
        {
            itemName: 'Edit',
            itemType: 'Edit',
            openEditModal: openEditTaskModal,
            role: [RULES.ROLE_NAMES.TASK_OWNER, RULES.ROLE_NAMES.PROJECT_OWNER],
            perform: RULES.RULE_NAMES.TASK_EDIT,
        },
        {
            itemName: 'Delete',
            itemType: 'Delete',
            deleteProject: openDeleteConfirmationModal,
            role: [RULES.ROLE_NAMES.TASK_OWNER, RULES.ROLE_NAMES.PROJECT_OWNER],
            perform: RULES.RULE_NAMES.TASK_DELETE,
        }
    ], []);

    /**
     * Function to handle group table checkbox
     * @param {boolean} checked 
     */
    const handleGroupCheckbox = () => {
        // Resets the table, sets all the variables for table grouping and removes row limit and pagination
        if (!isTableGrouped) {
            resetTable();
            setIsTableGrouped(true);
            localStorage.setItem(ENUMS.DT_STATE_STORAGE_NAMES.GROUP_TASK + props.projectDetails.id, true);
            sortTaskByGroup();
            setRowGroupMode("subheader");
            setRowGroupField("group_name");
            setExpandableRowGroups(true);
            setRows(null);
            setShowPaginator(false);
        }
        // Resets the table to previous order after unchecking the grouping
        else {
            setExpandedRows([]);
            setIsTableGrouped(false);
            localStorage.setItem(ENUMS.DT_STATE_STORAGE_NAMES.GROUP_TASK + props.projectDetails.id, false);
            setRowGroupMode(null);
            setRowGroupField(null);
            setExpandableRowGroups(false);
            setShowPaginator(true);
            setRows(ENUMS.TABLE_CONSTANTS.MIN_ROWS);
            resetTable();
            setTaskList(initialSort(_.cloneDeep(ogTaskList)));
        }
    }

    /**
     * Function to handle expansion and closing of row expansions during row grouping
     * @param {Event} e 
     */
    const onRowGroupToggle = (e) => {
        setExpandedRows(e.data);
    }

    /**
     * Function to handle custom global filtering
     * @param {*} value 
     */
    const handleGlobalFiltering = (value) => {
        setQuickFilterText(value);
        const e = {
            order: sortOrder,
            field: sortField
        }
        let filteredTasks = _.cloneDeep(ogTaskList);
        if (sortField) {
            filteredTasks = sortDistributor(e, filteredTasks);
        }
        if (value !== '') {
            const lowerVal = value.toLowerCase();
            filteredTasks = filteredTasks.filter(task => {
                return colKeyList.some(col => {
                    if (task[col] !== null) {
                        if (col === 'TaskOwner') {
                            if (task[col]['email'].toString().toLowerCase().includes(lowerVal))
                                return true;
                            else {
                                return UserUtil.formatUserForTableColumn(task[col]).toString().toLowerCase().includes(lowerVal);
                            }
                        }
                        else if (col === 'Task_Group') {
                            return task[col]['group_name'].toString().toLowerCase().includes(lowerVal);
                        }
                        else if (col === 'Teams' && task[col].length > 0) {
                            const isMatch = task[col].filter(team => {
                                if (team['team_name'].toString().toLowerCase().includes(lowerVal))
                                    return true;
                                if (team['TeamLeader']) {
                                    if (team['TeamLeader']['email'].toString().toLowerCase().includes(lowerVal))
                                        return true;
                                    if (UserUtil.formatUserForTableColumn(team['TeamLeader']).toString().toLowerCase().includes(lowerVal)){
                                        return true;
                                    }
                                }
                                if (team['Users'] && team['Users'].length > 0) {
                                    const userMatch = team['Users'].filter(teamMember => {
                                        if (teamMember['email'].toString().toLowerCase().includes(lowerVal))
                                            return true;
                                        else 
                                            return UserUtil.formatUserForTableColumn(teamMember).toString().toLowerCase().includes(lowerVal);
                                    })
                                    return userMatch.length > 0;
                                }
                                return false;
                            })
                            return isMatch.length > 0;
                        }
                        return task[col].toString().toLowerCase().includes(lowerVal);
                    }
                })
            });
        }
        setTaskList(filteredTasks);
    }

    /**
     * Function to set sort field and order for custom sorting
     * @param {Event} e 
     */
    const onSort = (e) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
        localStorage.setItem(ENUMS.DT_STATE_STORAGE_NAMES.SORT_FIELD + props.projectDetails.id, e.sortField ? e.sortField : '');
        localStorage.setItem(ENUMS.DT_STATE_STORAGE_NAMES.SORT_ORDER + props.projectDetails.id, e.sortOrder);
    }

    /**
     * Function to handle state and table management for paginator rows and first page
     * @param {Event} e 
     */
    const setOnPage = (e) => {
        setRows(e.rows);
        setFirst(e.first);
        localStorage.setItem(ENUMS.DT_STATE_STORAGE_NAMES.PAGINATOR_FIRST + props.projectDetails.id, e.first);
        localStorage.setItem(ENUMS.DT_STATE_STORAGE_NAMES.PAGINATOR_ROWS + props.projectDetails.id, e.rows);
    }

    const handleCompareOptionSelected = (value) => {
        if (value) {
            const compareWithRehearsalId = value.value; 
            const newTaskList = _.cloneDeep(taskList).map(task => {
                const rehearsalDurationData = ProjectUtil.twoFixedPlaces(task.Rehearsal_Tasks.find(rehearsalTask => rehearsalTask.rehearsal_id == compareWithRehearsalId).duration);
                const durationClassName = rehearsalDurationData > task['task_duration'] ? 'text-danger' : 'text-success';
                
                return {
                    ...task,
                    rehearsal_start_time: format(DateTimeUtils.convertTimezone(
                        new Date(task.Rehearsal_Tasks.find(rehearsalTask => rehearsalTask.rehearsal_id == compareWithRehearsalId).start_time), user),
                        DateTimeUtils.getUserDateTimeFormat(user.date_format, user.twenty_four_hour_clock)),
                    rehearsal_duration_view: <span className={durationClassName}>{rehearsalDurationData}</span>,
                    rehearsal_end_time: format(DateTimeUtils.convertTimezone(
                        new Date(task.Rehearsal_Tasks.find(rehearsalTask => rehearsalTask.rehearsal_id == compareWithRehearsalId).end_time), user),
                        DateTimeUtils.getUserDateTimeFormat(user.date_format, user.twenty_four_hour_clock)),
                };
            });
            localStorage.setItem(ENUMS.DT_STATE_STORAGE_NAMES.COMPARE_REHEARSAL + props.projectDetails.id, value.value);
            const changeParams = selectedColDropdown.map(x => x.value)
            changeParams.push('rehearsal_end_time', 'rehearsal_start_time', 'rehearsal_duration_view');
            handleSelectChange({value: changeParams})
            setCompareWithRehearsal(value.value);
            setTaskList(newTaskList);
            
        } else {
            const newTaskList = _.cloneDeep(taskList).map(task => {
                return {
                    ...task,
                    rehearsal_start_time: null,
                    rehearsal_duration_view: null,
                    rehearsal_end_time: null,
                };
            });
            const changeParams = selectedColDropdown.map(x => (x.value != "rehearsal_end_time" && x.value != "rehearsal_start_time" && x.value != "rehearsal_duration_view") && x.value).filter(y => y);
            handleSelectChange({value: changeParams})
            localStorage.setItem(ENUMS.DT_STATE_STORAGE_NAMES.COMPARE_REHEARSAL + props.projectDetails.id, null);
            setCompareWithRehearsal(null);
            setTaskList(newTaskList);
        }
    }

    const fetchUserList = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.USER_ALL_ORGANIZATION,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                project_id: props.projectDetails.id
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setTaskOwnerList(res.data.userList);
            }
        })
        .catch(error => {
            if (error.response) {
                console.error(error.response);
            }
        })
    }

    useEffect (() => {
        fetchUserList();
    }, []);

    useEffect (() => {
        setProjectGroupList(GroupUtil.formatGroupForDropdownBulk(props.projectDetails.Task_Groups));
        props.projectDetails && localStorage.setItem(ENUMS.DT_STATE_STORAGE_NAMES.GROUP_TASK + props.projectDetails.id, false);
    }, [props.projectDetails]);

    useEffect (() => {
        populateTaskHeaders();
        populateTaskTable();
        setLoading(false);
    }, [props.projectTaskList]);

    useEffect (() => {
       setRehearsalOptions(props.rehearsalsList.filter(reahearsalData => reahearsalData.end_time).map(r => {
            return {
                value: r.id,
                label: r.name
            }
       }))
    }, [props.rehearsalsList]);

    useEffect (() => {
        populateTaskHeaders();
    }, [taskList]);

    return (
        <>
            <Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="bg-secondary box-shadow-down card-border-radius-0">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center mb-4">
                                <Col xs="2">
                                    <h3 className="mb-0">Task List</h3>
                                </Col>
                                <Col className="text-right">
                                    {editTableText === ENUMS.BUTTON_TEXT.SAVE &&
                                        <Button
                                            color="secondary"
                                            onClick={setCancelEditableTable}
                                            size="md"
                                            disabled={taskList.length === 0}
                                        >
                                            Cancel Edits
                                        </Button>
                                    }
                                    <Button
                                        color="secondary"
                                        onClick={setEditableTable}
                                        size="md"
                                        disabled={taskList.length === 0}
                                    >
                                        {editTableText}
                                    </Button>
                                    <Button
                                        color="secondary"
                                        onClick={resetTable}
                                        size="md"
                                    >
                                        Reset Filters
                                    </Button>
                                    <Button
                                        color="secondary"
                                        onClick={e => exportTaskList(e)}
                                        size="md"
                                        disabled={taskList.length === 0}
                                    >
                                        Export
                                    </Button>
                                    <Button
                                        color="primary"
                                        onClick={openAddTaskModal}
                                        size="md"
                                    >
                                        Add Task
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-2">
                                <Col className="text-right">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-search"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            size="md"
                                            type="text"
                                            id="quickFilterText"
                                            value={quickFilterText}
                                            onChange={e => handleGlobalFiltering(e.target.value)}
                                            placeholder="Search Table...">
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col xs="8" className="text-right">
                                    <Row className="justify-content-end align-items-center pr-3">
                                        <div>
                                            <div  className="mr-3 d-flex align-items-center  justify-content-end" style={{width:'14em'}}>
                                                {
                                                    showCompareRehearsalDropdown
                                                    ? <Select
                                                        id="compareRehearsal"
                                                        className="form-control-alternative w-100"
                                                        isClearable
                                                        components={animatedComponents}
                                                        onChange={(newValue)=>handleCompareOptionSelected(newValue)}
                                                        options={rehearsalOptions}
                                                        value={rehearsalOptions.find(r => compareWithRehearsal && (r.value == compareWithRehearsal))}
                                                    />
                                                    : <span>
                                                        <img
                                                            src={require("assets/img/icons/common/compare.png")}
                                                            width="16"
                                                            onClick={(e) => setShowCompareRehearsalDropdown(true)}
                                                            id="compare_execution_mode" 
                                                        ></img>
                                                        <UncontrolledTooltip
                                                            delay={0}
                                                            placement="top"
                                                            target="compare_execution_mode"
                                                        >
                                                            Compare Execution Times
                                                        </UncontrolledTooltip>
                                                    </span>
                                                }
                                                {
                                                    showCompareRehearsalDropdown &&
                                                    <span>
                                                        <i className="fas fa-times pl-2" onClick={(e) => setShowCompareRehearsalDropdown(false)}/>
                                                    </span>
                                                }
                                            </div>

                                            
                                        </div>
                                        <div>
                                            <i 
                                                id="group_tasks"
                                                className={"mr-3 fas fa-layer-group cursor-pointer hover-primary " + (isTableGrouped ? ' primary-color' : '')}
                                                onClick={(e) => handleGroupCheckbox()}
                                            ></i>
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target={"group_tasks"}
                                            >
                                                Group tasks
                                            </UncontrolledTooltip>
                                        </div>
                                        <InputGroup className="w-auto align-items-center">
                                            {
                                                showSelectColumnDropDown
                                                ?   <div className="d-flex">
                                                        <MultiSelect 
                                                            value={selectedColDropdown.map(op => op.value)}
                                                            options={colOptionsList}
                                                            optionLabel="label"
                                                            selectedItemsLabel={"Selected Columns: " + selectedColDropdown.length}
                                                            onChange={(e) => handleSelectChange(e)}
                                                            style={{width:'14em'}}
                                                            className="selected-columns"
                                                            filter={true}
                                                        />
                                                        {/* <span onClick={(e) => setShowSelectColumnDropDown(false)}>
                                                            <i class="fat-remove"
                                                        </span> */}
                                                    </div>
                                                : 
                                                <div>
                                                    <i 
                                                        id="select_columns"
                                                        className="fas fa-columns cursor-pointer hover-primary"
                                                        onClick={(e) => setShowSelectColumnDropDown(true)}
                                                    ></i>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        placement="top"
                                                        target={"select_columns"}
                                                    >
                                                        Select Columns
                                                    </UncontrolledTooltip>
                                                </div>
                                            }
                                            {
                                                showSelectColumnDropDown &&
                                                <i className="fas fa-times pl-2" onClick={(e) => setShowSelectColumnDropDown(false)}/>
                                            }
                                        </InputGroup>
                                    </Row>
                                </Col>
                                
                            </Row>
                            <Row className="align-items-center">
                                <Col>
                                    
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody id="attachDropdownsAt">
                            <CustomTablePrime
                                className="project-task-table"
                                columnDefs={selectedCol} 
                                rowData={taskList}
                                loading={loading}
                                optionsList={optionsList}
                                tableRef={tableRef}
                                setTableRef={setTableRef}
                                stateStorageName={ENUMS.DT_STATE_STORAGE_NAMES.PROJECT_VIEW_TASK_TABLE +
                                    "-" + props.projectDetails.id}
                                onEditorValueChange={onEditorValueChange}
                                onEditorCancel={onEditorCancel}
                                editMode={tableEditMode}
                                user={user}
                                projectOwnerId={props.projectDetails.project_owner_id}
                                rowGroupMode={rowGroupMode}
                                rowGroupField={rowGroupField}
                                expandableRowGroups={expandableRowGroups}
                                expandedRows={expandedRows} 
                                onRowGroupToggle={onRowGroupToggle}
                                sortField={sortField}
                                sortOrder={sortOrder}
                                onSort={onSort}
                                showPaginator={showPaginator}
                                rows={rows}
                                first={first}
                                setOnPage={setOnPage}
                                renderToEl="attachDropdownsAt"
                                taskOwnerList={taskOwnerList}
                                projectGroupList={projectGroupList}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                addEditTaskModalOpen && 
                <TaskAddEditModal 
                    modalTitle={modalTitle}
                    modalButtonName={modalButtonName}
                    modalMode={modalMode}
                    modalOpen={addEditTaskModalOpen} 
                    projectId={props.projectDetails.id}
                    editTaskId={editTaskId}
                    closeModal={closeAddEditModal}
                    refreshPageOnSubmit={refreshPageOnSubmit}
                /> 
            }
            {
                showDeleteTaskModal && 
                <ConfirmationModal
                    showModal={showDeleteTaskModal}
                    actionText={APP_CONSTANTS.DELETE_MODAL_ACTION_TEXTS}
                    modalBody={deleteModalText}
                    modalTitle={ENUMS.MODAL.DELETE_TASK_TITLE}
                    onCancel={setShowDeleteTaskModal}
                    onConfirm={deleteTaskConfirmed}
                    data={deleteTaskId}
                />
            }
        </>
    )
}