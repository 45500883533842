import React, { useState, useEffect } from "react";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader, 
    CardBody,
    Button,
    Input
} from "reactstrap";
import ENUMS from "constants/appEnums";
import CustomTablePrime from "components/CustomTable/CustomTablePrime";
import TableHeadersUtil from "util/TableHeadersUtil";
import { format } from 'date-fns';
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import { useToasts } from 'react-toast-notifications';
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import UserUtil from "util/UserUtil";
import TaskUtil from "util/TaskUtil";
import DateTimeUtils from "util/DateTimeUtils";
import { useSelector } from "react-redux";
import _ from "lodash";
import GeneralUtils from "util/GeneralUtils";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import InputGroupText from "reactstrap/lib/InputGroupText";
import parse from 'html-react-parser';
import AddEditRiskModal from "./Risk/AddEditRiskModal";
import RiskRatingMatrixModal from "./Risk/RiskRatingMatrixModal";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import TeamUtil from "util/TeamUtil";
import { MultiSelect } from 'primereact/multiselect';
import ViewRaidModal from "./ViewRaidModal";
import RULES from "rbac/rules";

export default function ProjectViewRiskTable (props) {

    const { addToast } = useToasts();

    // Team Data and Header for Table
    const [riskList, setRiskList] = useState([]);
    const [riskListHeader, setRiskListHeader] = useState([]);
    const [riskRatingsList, setRiskRatingsList] = useState([]);

    // Table props
    const [loading, setLoading] = useState(true);
    const [tableRef, setTableRef] = useState(null);
    
    const [quickFilterText, setQuickFilterText] = useState("");

    const [showRiskRatingMatrixModal, setShowRiskRatingMatrixModal] = useState(false);

    /**
     * Delete Modal
     */
    const [showDeleteRiskModal, setShowDeleteRiskModal] = useState(false);
    const [deleteRiskModalText, setDeleteRiskModalText] = useState("");
    const [selectedRiskId, setSelectedRiskId] = useState("");
    const [deleteModalRiskTexts, setDeleteModalRiskTexts] = useState({
        'confirm': ENUMS.BUTTON_TEXT.DELETE_ACTION_TEXT_CONFIRM,
        'cancel': ENUMS.BUTTON_TEXT.CANCEL
    });

    // Current User
    const user = useSelector(state => state.authReducer.user);

    /** ADD/EDIT Risk modal */
    const [showAddEditRiskModal, setShowAddEditRiskModal] = useState(false);
    const [modalMode, setModalMode] = useState("");
    const [editRiskId, setEditRiskId] = useState(null);

    // editable table
    const [editTableText, setEditTableText] = useState(ENUMS.BUTTON_TEXT.EDIT_TABLE);
    const [tableEditMode, setTableEditMode] = useState(null);
    const [editedRows, setEditedRows] = useState([]);
    const [preEditTableData, setPreEditTableData] = useState([]);

    const [teamsList, setTeamsList] = useState([]);
    const [userList, setUserList] = useState([]);

    const [selectedCol, setSelectedCol] = useState([]);
    const [selectedColDropdown, setSelectedColDropdown] = useState([]);
    const [showSelectColumnDropDown, setShowSelectColumnDropDown] = useState(false);
    const [colOptionsList, setColOptionsList] = useState([]);

    /**
     * View Modal
     */
    const [viewRiskModalOpen, setViewRiskModalOpen] = useState(false);
    const [riskToView, setRiskToView] = useState(null);

    // TABLE filtering and sorting
    const [ogRiskList, setOgRiskList] = useState([]);
    const [colKeyList, setColKeyList] = useState(
        ['risk_id','created_at','team_name','description','status_value_field',
        'owner','likelihood','impact','overall_value_field','mitigation','due_date',
        'mitigation_owner','escalation_required','notes','note_view']
    );

    const populateRiskTable = () => {
        populateRiskHeaders();
        if (props.projectRiskList && 
            props.projectRiskList.length > 0) {
            populateRiskData();
        } else {
            setRiskList([]);
        }
    }

    const populateRiskHeaders = () => {
        /**
         * Columns object to be passed to table
         */
        const selectedColumnsDropdown = [];
        // the selected columns in the list
        const selectedColumns = [];
        // all the columns in the list
        const colOptions = [];
        // selected columns in state
        const stateColumns = [];

        for (var i = 0; i < TableHeadersUtil.projectViewRiskTableHeaders.length; i++) {
            const riskHeader = TableHeadersUtil.projectViewRiskTableHeaders[i];
            if (!riskHeader.hidden) {
                selectedColumnsDropdown.push({
                    label: riskHeader.headerName,
                    value: riskHeader.field
                });
                selectedColumns.push(riskHeader);
                stateColumns.push({...riskHeader, filterElement: ''});
            }
            colOptions.push({
                label: riskHeader.headerName,
                value: riskHeader.field
            });
        }
        setSelectedColDropdown(selectedColumnsDropdown);
        setSelectedCol(selectedColumns);
        setColOptionsList(colOptions);

        setRiskListHeader(TableHeadersUtil.projectViewRiskTableHeaders);
    }

    const populateRiskData = () => {
        const updatedRiskList = props.projectRiskList.map((risk, index) => {
            const newRisk = {
                ...risk
            }
            newRisk['risk_number_link'] = GeneralUtils.viewPageLink(
                risk.id,
                index +1,
                ENUMS.VIEW_PATH.RISK,
                '?riskId=' + risk.id+'&projectId='+props.projectDetails.id,
                {passedId: risk.id, projectId:props.projectDetails.id}
            );
            newRisk['created_at'] = format(DateTimeUtils.convertTimezone(
                new Date(risk.createdAt), user), 
                DateTimeUtils.getUserDateTimeFormat(user.date_format, user.twenty_four_hour_clock))
            newRisk['team_name_value_field'] = risk.Team.id;
            newRisk['team_name'] = risk.Team.team_name;
            newRisk['description'] = risk.description;
            newRisk['status_value_field'] = risk.status;
            newRisk['status'] = ENUMS.RAID_STATUS[risk.status];
            newRisk['owner_value_field'] = risk.RiskOwner.id;
            newRisk['owner'] = UserUtil.formatUserForTableColumn(risk.RiskOwner);
            newRisk['likelihood_value_field'] = risk.likelihood;
            newRisk['likelihood'] = ENUMS.LIKELIHOOD[risk.likelihood];
            newRisk['impact_value_field'] = risk.impact;
            newRisk['impact'] = risk.impact.charAt(0).toUpperCase() + risk.impact.slice(1);
            newRisk['overall_value_field'] = riskRatingsList.length > 0 ? riskRatingsList.find(rating => rating.id == risk.overall).id : risk.overall;
            newRisk['overall'] = riskRatingsList.length > 0 ? ENUMS.IMPACT[riskRatingsList.find(rating => rating.id == risk.overall)[risk.impact]] : risk.overall;
            newRisk['mitigation'] = risk.mitigation;
            newRisk['due_date'] = format(DateTimeUtils.convertTimezone(
                new Date(risk.due_date), user), 
                DateTimeUtils.getUserDateTimeFormat(user.date_format, user.twenty_four_hour_clock));
            newRisk['mitigation_owner_value_field'] = risk.RiskOwner.id;
            newRisk['mitigation_owner'] = UserUtil.formatUserForTableColumn(risk.RiskMitigationOwner);
            newRisk['escalation_required_value_field'] = risk.escalation_required;
            newRisk['escalation_required'] = risk.escalation_required ? 'YES' : 'NO';
            newRisk['notes'] = risk.notes;
            newRisk['note_view'] = parse('<div class="table-note-display">' + risk.notes + '</div>');
            newRisk['ProjectAdmins'] = props.projectDetails.ProjectAdmins;
            return newRisk;
        });
        setRiskList(updatedRiskList);
        setOgRiskList(updatedRiskList);
    }

    const openViewRiskModal = (data) => {
        setRiskToView(data);
        setViewRiskModalOpen(true);
    }


    const openDeleteRiskModal = (risk) => {
        setShowDeleteRiskModal(true);
        setDeleteRiskModalText(ENUMS.MODAL.DELETE_CONFIRMATION_TEXT + risk.description + " risk ?");
        setSelectedRiskId(risk.id);
    }

    const hideDeleteRiskModal = () => {
        setShowDeleteRiskModal(false);
    }

    const deleteRiskConfirmed = (riskId) => {
        API.del(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_RISK_DELETE, 
        {
            ...HttpUtil.adminHttpHeaders(),
            body:  {
                risk_id: riskId
            }
        })
        .then(res => {
            if(res && res.status === 200) {
                refreshPageOnSubmit("Risk has been deleted successfully");
            }
        })
        .catch(error => {
            if (error.response) {
                console.log(error.response);
                addToast(
                    "Failed to delete risk.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    /**
     * Fetches the user list for populating the dropdowns
     */
     const fetchUserList = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.USER_ALL_ORGANIZATION,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                project_id: props.projectDetails.id
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setUserList(res.data.userList);
            }
        })
        .catch(error => {
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch user list for owner selection", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    /**
     * Fetches the project risk rating list
     */
     const fetchProjectRiskRating = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.RISK_RATINGS_GET,
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: {
                project_id: props.projectDetails.id
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setRiskRatingsList(res.data.projectRiskRatingData.Risk_Ratings);
            }
        })
        .catch(error => {
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to fetch user list for owner selection", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const closeViewModal = () => {
        setViewRiskModalOpen(false);
    }

    /**
     * Method to handle opening the task modal in edit mode
     */
    const openEditRiskModal = (risk) => {
        setModalMode(ENUMS.MODAL.EDIT_MODE);
        setEditRiskId(risk.id);
        setShowAddEditRiskModal(true);
    }

    const optionsList = React.useMemo(() => 
    [
        {
            itemName: 'View',
            itemType: 'View',
            openViewModal: openViewRiskModal,
        },
        {
            itemName: 'Edit',
            itemType: 'Edit',
            openEditModal: openEditRiskModal,
            role: [RULES.ROLE_NAMES.PROJECT_OWNER, RULES.ROLE_NAMES.PROJECT_ADMIN, RULES.ROLE_NAMES.RISK_MITIGATION_OWNER, RULES.ROLE_NAMES.TEAM_LEADER, RULES.ROLE_NAMES.RISK_OWNER],
            perform: RULES.RULE_NAMES.RISK_EDIT,
        },
        {
            itemName: 'Delete',
            itemType: ENUMS.RENDER_OPTIONS_TYPE.DELETE,
            delete: openDeleteRiskModal,
            role: [RULES.ROLE_NAMES.PROJECT_OWNER, RULES.ROLE_NAMES.PROJECT_ADMIN, RULES.ROLE_NAMES.TEAM_LEADER, RULES.ROLE_NAMES.RISK_OWNER],
            perform: RULES.RULE_NAMES.RISK_DELETE,
        }
    ], []);

    /**
     * Function to handle custom global filtering
     * @param {*} value 
     */
    const handleGlobalFiltering = (value) => {
        setQuickFilterText(value);
        let filteredRisk = _.cloneDeep(ogRiskList);

        if (value !== '') {
            const lowerVal = value.toLowerCase();
            filteredRisk = filteredRisk.filter(risk => {
                return colKeyList.some(col => {
                    if (risk[col] !== null) {
                        return risk[col] && risk[col].toString().toLowerCase().includes(lowerVal);
                    }
                })
            });
        }
        setRiskList(filteredRisk);
    }

    /**
     * Method to handle the exporting to csv.
     * @param {Object} e 
     */
    const exportRiskList = (e) => {
        e.preventDefault();
        console.log(riskList);
        console.log(riskRatingsList);
        let csvContent = "data:text/csv;charset=utf-8,";
        let headers = "S.No.,Project ID,Project Name,Date Created,Team,Description,Status,Owner,Likelihood,Impact,Overall,Mitigation,Due Date,Mitigation Owner,Escalation Required\r\n";
        csvContent += headers;
        riskList.forEach((risk, index) => {
            let rowArray = [
                index + 1,
                props.projectDetails.id,
                GeneralUtils.handleStringForCSV(props.projectDetails.project_name),
                risk.created_at,
                risk.Team.team_name,
                GeneralUtils.handleStringForCSV(risk.description),
                risk.status_value_field,
                UserUtil.formatUserForTableColumn(risk.RiskOwner),
                risk.likelihood,
                risk.impact,
                riskRatingsList.find(rating => rating.id == risk.overall_value_field)[risk.impact_value_field],
                risk.mitigation,
                risk.due_date,
                UserUtil.formatUserForTableColumn(risk.RiskMitigationOwner),
                risk.escalation_required
            ];
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", props.projectDetails.project_name + "_risks.csv");
        document.body.appendChild(link); // Required for FF
        link.click(); // This will download the data file named "my_data.csv".
    }

    /**
     * EDIT TABLE FUNCTIONS STARTS HERE
     */

    const updateTableData = () => {
        let rowsToEdit = _.cloneDeep(editedRows).filter(row => row !== null && row !== undefined);
        const tableEdits = rowsToEdit.map(row => {
            return {
                id: row.id,
                project_id: row.project_id,
                description: row.description,
                team_id: row.team_name_value_field,
                status: row.status_value_field,
                owner_id: row.owner_value_field,
                likelihood: row.likelihood_value_field,
                impact: row.impact_value_field,
                overall: row.overall_value_field,
                due_date: row.due_date,
                mitigation: row.mitigation,
                mitigation_owner_id: row.mitigation_owner_value_field,
                escalation_required: row.escalation_required_value_field,
                notes: row.notes,
            }
        });
        API.put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.PROJECT_RISK_UPDATE_TABLE_ROWS, 
        {
            ...HttpUtil.adminHttpHeaders(),
            body: 
            {
                editedRows: tableEdits,
                projectId: props.projectDetails.id
            }
        })
        .then(res => {
            if (res.status === 201 && res.data.success) {
                refreshPageOnSubmit("Risks have been updated successfully!");
                setEditedRows([]);
            }
        })
        .catch(error => {
            if (error.response) {
                addToast(
                    "Failed to update risks!", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const setEditableTable = () => {
        if (editTableText === ENUMS.BUTTON_TEXT.SAVE) {
            setEditTableText(ENUMS.BUTTON_TEXT.EDIT_TABLE);
            setTableEditMode(null);
            if (editedRows.length > 0) {
                updateTableData();
            }
            
        }
        else {
            setPreEditTableData(_.cloneDeep(riskList));
            setEditTableText(ENUMS.BUTTON_TEXT.SAVE);
            setTableEditMode(ENUMS.TABLE_CONSTANTS.EDIT_MODE_CELL);
        }
    }

    const setCancelEditableTable = () => {
        setRiskList(_.cloneDeep(preEditTableData));
        setEditTableText(ENUMS.BUTTON_TEXT.EDIT_TABLE);
        setTableEditMode(null);
    }

    const onEditorValueChange = (tableProps, value) => {
        let updatedProducts = _.cloneDeep(tableProps.value);

        if (tableProps.field === 'team_name' && value) {
            updatedProducts[tableProps.rowIndex]['team_name_value_field'] = value;
            updatedProducts[tableProps.rowIndex]['team_name'] = teamsList.find(t => t.value == value).label;
        }
        else if (tableProps.field === 'status' && value) {
            updatedProducts[tableProps.rowIndex]['status_value_field'] = value;
            updatedProducts[tableProps.rowIndex]['status'] = ENUMS.RAID_STATUS[value];
        }
        else if (tableProps.field === 'owner' && value) {
            updatedProducts[tableProps.rowIndex]['owner_value_field'] = value;
            updatedProducts[tableProps.rowIndex]['owner'] = userList.find(u => u.value == value).label;
        }
        else if (tableProps.field === 'likelihood' && value) {
            updatedProducts[tableProps.rowIndex]['likelihood_value_field'] = value;
            updatedProducts[tableProps.rowIndex]['likelihood'] = ENUMS.LIKELIHOOD[value];
            updatedProducts[tableProps.rowIndex]['overall_value_field'] = riskRatingsList.find(rating => rating.likelihood == updatedProducts[tableProps.rowIndex]['likelihood_value_field']).id;
            updatedProducts[tableProps.rowIndex]['overall'] = ENUMS.IMPACT[riskRatingsList.find(rating => rating.likelihood == updatedProducts[tableProps.rowIndex]['likelihood_value_field'])[updatedProducts[tableProps.rowIndex]['impact_value_field']]];
        }
        else if (tableProps.field === 'impact' && value) {
            updatedProducts[tableProps.rowIndex]['impact_value_field'] = value;
            updatedProducts[tableProps.rowIndex]['impact'] = value.toUpperCase();
            updatedProducts[tableProps.rowIndex]['overall_value_field'] = riskRatingsList.find(rating => rating.likelihood == updatedProducts[tableProps.rowIndex]['likelihood_value_field']).id;
            updatedProducts[tableProps.rowIndex]['overall'] = ENUMS.IMPACT[riskRatingsList.find(rating => rating.likelihood == updatedProducts[tableProps.rowIndex]['likelihood_value_field'])[updatedProducts[tableProps.rowIndex]['impact_value_field']]];
        }
        else if (tableProps.field === 'due_date' && value) {
            updatedProducts[tableProps.rowIndex]['due_date'] = format(DateTimeUtils.convertTimezone(
                new Date(value), user), 
                DateTimeUtils.getUserDateTimeFormat(user.date_format, user.twenty_four_hour_clock));
        }
        else if (tableProps.field === 'mitigation_owner' && value) {
            updatedProducts[tableProps.rowIndex]['mitigation_owner_value_field'] = value;
            updatedProducts[tableProps.rowIndex]['mitigation_owner'] = userList.find(u => u.value == value).label;
        }
        else if (tableProps.field === 'escalation_required') {
            updatedProducts[tableProps.rowIndex]['escalation_required_value_field'] = value;
            updatedProducts[tableProps.rowIndex]['escalation_required'] = value ? 'YES' : 'NO';
        } else {
            updatedProducts[tableProps.rowIndex][tableProps.field] = value;
        }
        let updatedEditedRows = _.cloneDeep(editedRows);
        updatedEditedRows[tableProps.rowIndex] = updatedProducts[tableProps.rowIndex];
        setEditedRows(updatedEditedRows);
        setRiskList(updatedProducts);
    }

    const onEditorCancel = ({columnProps}, editingCellRows) => {
        const { rowIndex, field } = columnProps;
        let products = _.cloneDeep(riskList);
        products[rowIndex][field] = editingCellRows[rowIndex][field];
        delete editingCellRows[rowIndex][field];
        setRiskList(products);
    }

    /**
     * Method to handle the select dropdown of column ordering change
     * @param {Object} newValue 
     */
     const handleSelectChange = (e) => {
        const selected = e.value.map(val => colOptionsList.find(op => op.value === (val.value || val)));

        // update the column dropdown in the local storage
        setSelectedColDropdown(selected);
        
        let orderedSelectedColumns = TableHeadersUtil.projectViewRiskTableHeaders.filter(col => selected.some(sCol => sCol.value === col.field));
        setSelectedCol(orderedSelectedColumns);
        const stateColumns = orderedSelectedColumns.map(col => {
            return {
                ...col,
            }
        });
        setRiskListHeader(stateColumns);

        // TODO: set cols at local storage with sort order and f
    }

    /**
     * Method to reset the filters and sorting on the entire table. 
     */
    const resetTable = () => {
        tableRef.reset();
    }

    /**
     * Refreshes the page on submit of add/edit/delete form
     * @param {String} message 
     */
    const refreshPageOnSubmit = (message) => {
        setShowAddEditRiskModal(false);
        setShowDeleteRiskModal(false);
        fetchProjectRiskRating();
        props.refreshViewOnAction(ENUMS.TABS.RISK_LIST, message);
    }

    const openAddRiskModal = () => {
        setModalMode(ENUMS.MODAL.ADD_MODE);
        setShowAddEditRiskModal(true);
    }

    const closeAddEditRiskModal = () => {
        setShowAddEditRiskModal(false);
    }

    const handleRiskRatingMatrix = () => {
        setShowRiskRatingMatrixModal(true);
    }

    const closeRiskRatingMatrix = () => {
        setShowRiskRatingMatrixModal(false);
    }

    useEffect (() => {
        populateRiskTable();
        setLoading(false);
    }, [props.projectRiskList, riskRatingsList]);

    useEffect(() => {
        fetchProjectRiskRating();
        fetchUserList();
        setTeamsList(TeamUtil.formatTeamForDropdownBulk(props.projectDetails.Teams));
    }, []);

    return (
        <>
            <Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="bg-secondary box-shadow-down card-border-radius-0">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center mb-4">
                                <Col xs="2">
                                    <h3 className="mb-0">Risk List</h3>
                                </Col>
                                <Col className="text-right">
                                    {editTableText === ENUMS.BUTTON_TEXT.SAVE &&
                                        <Button
                                            color="secondary"
                                            onClick={setCancelEditableTable}
                                            size="md"
                                            disabled={riskList.length === 0}
                                        >
                                            Cancel Edits
                                        </Button>
                                    }
                                    <Button
                                        color="secondary"
                                        onClick={setEditableTable}
                                        size="md"
                                        disabled={riskList.length === 0}
                                    >
                                        {editTableText}
                                    </Button>
                                    <Button
                                        color="secondary"
                                        onClick={resetTable}
                                        size="md"
                                    >
                                        Reset Filters
                                    </Button>
                                    <Button
                                        color="secondary"
                                        onClick={e => exportRiskList(e)}
                                        size="md"
                                        disabled={riskList.length === 0}
                                    >
                                        Export
                                    </Button>
                                    <Button 
                                        size="md"
                                        color="primary"
                                        onClick={(e) => openAddRiskModal()}
                                    >
                                        Add Risk
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-2">
                                <Col className="text-right">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-search"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            size="md"
                                            type="text"
                                            id="quickFilterText"
                                            value={quickFilterText}
                                            onChange={e => handleGlobalFiltering(e.target.value)}
                                            placeholder="Search Table...">
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col xs="8" className="text-right">
                                    <Row className="justify-content-end align-items-center pr-3">
                                        <div>
                                            <i
                                                id="risk_rating"
                                                className={"mr-3 fas fa-star-half-alt cursor-pointer hover-primary"}
                                                onClick={(e) => handleRiskRatingMatrix()}
                                            ></i>
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target={"risk_rating"}
                                            >
                                                Risk Rating Matrix
                                            </UncontrolledTooltip>
                                        </div>
                                        <InputGroup className="w-auto align-items-center">
                                            {
                                                showSelectColumnDropDown
                                                ?   <div className="d-flex">
                                                        <MultiSelect 
                                                            value={selectedColDropdown.map(op => op.value)}
                                                            options={colOptionsList}
                                                            optionLabel="label"
                                                            selectedItemsLabel={"Selected Columns: " + selectedColDropdown.length}
                                                            onChange={(e) => handleSelectChange(e)}
                                                            style={{width:'14em'}}
                                                            className="selected-columns"
                                                            filter={true}
                                                        />
                                                        {/* <span onClick={(e) => setShowSelectColumnDropDown(false)}>
                                                            <i class="fat-remove"
                                                        </span> */}
                                                    </div>
                                                : <i 
                                                    id="select_columns"
                                                    className="fas fa-columns cursor-pointer hover-primary"
                                                    onClick={(e) => setShowSelectColumnDropDown(true)}
                                                ></i>
                                            }
                                            {
                                                showSelectColumnDropDown &&
                                                <i className="fas fa-times pl-2" onClick={(e) => setShowSelectColumnDropDown(false)}/>
                                            }
                                        </InputGroup>
                                    </Row>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <CustomTablePrime
                                columnDefs={riskListHeader} 
                                rowData={riskList}
                                loading={loading}
                                optionsList={optionsList}
                                tableRef={tableRef}
                                setTableRef={setTableRef}
                                onEditorValueChange={onEditorValueChange}
                                onEditorCancel={onEditorCancel}
                                editMode={tableEditMode}
                                userList={userList}
                                teamsList={teamsList}
                                user={user}
                                projectOwnerId={props.projectDetails.project_owner_id}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                showDeleteRiskModal && 
                <ConfirmationModal
                    actionText={deleteModalRiskTexts}
                    onCancel={hideDeleteRiskModal}
                    onConfirm={deleteRiskConfirmed}
                    modalBody={deleteRiskModalText}
                    showModal={showDeleteRiskModal}
                    modalTitle={ENUMS.MODAL.DELETE_RISK_TITLE}
                    data={selectedRiskId}
                />
            }
            {
                showAddEditRiskModal && 
                <AddEditRiskModal
                    modalTitle={modalMode === ENUMS.MODAL.ADD_MODE ? ENUMS.MODAL.ADD_RISK_TITLE : ENUMS.MODAL.EDIT_RISK_TITLE}
                    modalButtonName={ ENUMS.MODAL.ADD_MODE ? ENUMS.BUTTON_TEXT.ADD : ENUMS.BUTTON_TEXT.SAVE }
                    modalOpen={showAddEditRiskModal}
                    closeModal={closeAddEditRiskModal}
                    projectData={props.projectDetails}
                    modalMode={modalMode}
                    refreshPageOnSubmit={refreshPageOnSubmit}
                    editRiskId={editRiskId}
                    riskRatingsList={riskRatingsList}
                /> 
            }
            { 
                viewRiskModalOpen && 
                <ViewRaidModal
                    modalTitle="Risk"
                    modalOpen={viewRiskModalOpen}
                    rowData={riskToView}
                    closeModal={closeViewModal}
                    list={TableHeadersUtil.projectViewRiskTableHeaders}
                /> 
            }
            {
                showRiskRatingMatrixModal &&
                <RiskRatingMatrixModal
                        modalTitle="Risk Rating"
                        showModal={showRiskRatingMatrixModal}
                        closeModal={closeRiskRatingMatrix}
                        projectId={props.projectDetails.id}
                        refreshPageOnSubmit={refreshPageOnSubmit}
                >
                </RiskRatingMatrixModal>
            }
        </>
    )
}
