import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
    Spinner,
    Container,
    Row
  } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-bootstrap-switch";
import ENUMS from "constants/appEnums";
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import { useToasts } from "react-toast-notifications";
import CustomErrorMessage from "components/CustomErrorMessage/CustomErrorMessage";
import { useForm } from "react-hook-form";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import APP_CONSTANTS from 'constants/appConstants';
import AddEditCalendarModal from "components/Project/ProjectSettings/AddEditCalendarModal/AddEditCalendarModal";
import allActions from 'redux/actions/index';
import { useHistory } from "react-router-dom";

export default function Settings() {

    // grab current state
    const user = useSelector(state => state.authReducer.user);
    let history = useHistory();
    const dispatch = useDispatch();

    const { addToast } = useToasts();
    const { register, handleSubmit, errors } = useForm({
		mode: "onBlur"
    });
    
    const [token, setToken] = useState('');
    const [userInitialSetting, setUserInitialSetting] = useState({});
    const [twoFactorAuthEnabled, setTwoFactorAuthEnabled] = useState(!!user.mfa_enabled);
    const [showVerifyTokenField, setShowVerifyTokenField] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const [userDefaultCalendar, setUserDefaultCalendar] = useState();
    
    /**
     * ADD EDIT COPY CALENDAR MODAL
     */
    const [showAddEditCalendarModal, setShowAddEditCalendarModal] = useState(false);
    const [addEditCalendarModalTitle, setAddEditCalendarModalTitle] = useState("");
    const [calendarData, setCalendarData] = useState(null);
    const [addEditCalendarModalActionText, setAddEditCalendarModalActionText] = useState("");
    const [modalType, setModalType] = useState(ENUMS.MODAL.SET_MODE);


    const disableTwoFactorAuth = () => {
        API.put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.DISABLE_TWO_FACTOR_AUTHENTICATION,
            {
                ...HttpUtil.adminHttpHeaders(),
                body:
                {
                    user_id: user.id,
                    mfa_enabled: false
                }
            })
            .then(res => {
                if (res.status === 200 && res.data.success) {
                    setTwoFactorAuthEnabled(false);
                    setUserInitialSetting({
                        ...userInitialSetting,
                        mfa_enabled: false
                    })
                    addToast(
                        "Two-factor authentication disabled!", 
                        { 
                            appearance: 'success',
                            autoDismiss: true
                        }
                    );
                }
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response);
                    addToast(
                        "Failed to update notificaiton", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
    }

    const enableTwoFactorAuth = (e) => {
        if(!twoFactorAuthEnabled && !userInitialSetting.mfa_enabled) {
            setIsLoading(true);
            API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.SEND_TOKEN_TWO_FACTOR_AUTHENTICATION,
                {
                    ...HttpUtil.adminHttpHeaders(),
                    body:
                    {
                        user_id: user.id,
                    }
                })
                .then(res => {
                    if (res.status === 200 && res.data.success) {
                        setIsLoading(false);
                        setShowVerifyTokenField(true);
                        addToast(
                            "Token sent to user's email!", 
                            { 
                                appearance: 'success',
                                autoDismiss: true
                            }
                        );
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.error(error.response);
                        addToast(
                            "Failed to update notificaiton", 
                            { 
                                appearance: 'error',
                                autoDismiss: true
                            }
                        );
                    }
                })
        } else {
            setShowConfirmationModal(true);
        }
    }

    /**
     * Method to handle verify token.
     * @param {FormData} data 
     * @param {Event} e 
     */
    const handleVerifyToken = (data, e) => {
        e.preventDefault();
        setIsLoading(true);
        API.put(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.VERIFY_ENABLE_2FA_TOKEN,
        {
            ...HttpUtil.adminHttpHeaders(),
            body: {
                email : user.email,
                mfa_enabled: true,
                token: token 
            }
        })
        .then(res => {
            setIsLoading(false);
            console.log(res);
            if (res.status === 200 && res.data.success) {
                if (res.data.verified) {
                    setShowVerifyTokenField(false);
                    setTwoFactorAuthEnabled(true);
                    addToast('Token verified successfully!', { 
                        appearance: 'success',
                        autoDismiss: true
                    });
                } else {
                    addToast('Invalid Token!', { 
                        appearance: 'error',
                        autoDismiss: true
                    });
                }
            }
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    error.response.data.message ? error.response.data.message : "Invalid token.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    };

    const disableTwoFactorAuthConfirm = () => {
        setShowConfirmationModal(false);
        disableTwoFactorAuth();
    }

    const hideConfirmationModal = () => {
        setShowConfirmationModal(false);
    }

    const editDefaultCalendar = () => {
        let title = "Set Default Calendar";
        let actionText = "Set Calendar";
        setModalType(ENUMS.MODAL.SET_MODE);
        
        setAddEditCalendarModalTitle(title);
        setAddEditCalendarModalActionText(actionText);
        setCalendarData(userDefaultCalendar);
        setShowAddEditCalendarModal(true);
    }

    const hideCalendarModal = () => {
        setShowAddEditCalendarModal(false);
    }

    /**
	 * Call to get loggedin user details
	 *
	 * @param {object} e
	 */
    const getUser = () => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.AUTH_STATUS,
        {
            ...HttpUtil.adminHttpHeaders()
        })
        .then(res => {
            if (res.status === 200) {
                dispatch(allActions.authActions.setUserState(res.data.user));
                dispatch(allActions.authActions.setUserProjectList(res.data.projectList));
                setUserDefaultCalendar(res.data.user.User_Default_Calendar);
            }
            else {
                history.push('/auth/login');
            }
        })
        .catch(error => {
            console.log(error);
        })
    };

    const onDefaultCalendarSet = (data) => {
        API
			.put(
                ENUMS.AWS.API_NAME,
				ENUMS.API_ROUTES.UPDATE_USER_DEFAULT_CALENDAR,
				{
                    ...HttpUtil.adminHttpHeaders(),
                    body:
                    {
                        id: data.calendar_id,
                        user_id: user.id,
                        calendar_name: data.calendar_name,
                        work_day_type: data.work_day_type,
                        working_start_time: data.working_start_time,
                        working_end_time: data.working_end_time,
                    }
				}
			)
			.then((res) => {
				if (res.status === 200 && res.data.success) {
					addToast('The default calendar was updated successfully!', { 
						appearance: 'success',
						autoDismiss: true
					});
                    hideCalendarModal();
                    getUser();
				} else {
					addToast(
						"Default calendar not updated, error occured!.", 
						{ 
							appearance: 'error',
							autoDismiss: true
						}
					);
				}
			})
			.catch((error) => {
				console.log("Error Occured!", error);
				addToast(
					"Calendar not updated, error occured!.", 
                    { 
                        appearance: 'error',
						autoDismiss: true
                    }
                );
			});
    }

    useEffect (() => {
        if(user) {
            setUserDefaultCalendar(user.User_Default_Calendar);
        }
    }, [user]);

    useEffect(() => {
        setUserInitialSetting({
            mfa_enabled: user.mfa_enabled
        })
    }, [])

    return (
        <React.Fragment>
            <Header/>
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="6">
                                        <h2 className="mb-0">Settings</h2>

                                    </Col>
                                    <Col className="text-right">
                                        
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Container fluid className="mb-3">
                                    <Row>
                                        <Col className="mb-3"><h4>Two-factor authentication</h4></Col>
                                    </Row>
                                    <Row>
                                        <Col xs="5">
                                            {    
                                                !showVerifyTokenField 
                                                ? <div className="d-flex">
                                                    <div className="mr-2"><Switch value={twoFactorAuthEnabled} className="cop-btn-switch" onChange={(e) => enableTwoFactorAuth(e)} name='twoFactorAuthEnabled' /></div>
                                                    <div className="mb-2">{twoFactorAuthEnabled ? "Enabled" : "Disabled"}</div>
                                                    { isLoading && <Spinner size="sm" color="primary" /> }
                                                </div> 
                                                : <div>
                                                    <p>A 6 digit code has been sent to <i>{user.email}</i>.<br />Please enter the code to activate two-factor authentication.</p>
                                                    <Form role="form" onSubmit={handleSubmit(handleVerifyToken)}>
                                                        <Col>
                                                            <Row>
                                                                <FormGroup className="mb-3 mr-3">
                                                                    <InputGroup className="input-group-alternative">
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                                <i className="ni ni-key-25 circle" />
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input 
                                                                            name="token"
                                                                            placeholder="Enter code" 
                                                                            type="text"
                                                                            onChange={(e)=>setToken(e.target.value)} 
                                                                            autoComplete="off"
                                                                            innerRef={register({
                                                                                required: "Token is required."
                                                                            })}
                                                                        />
                                                                    </InputGroup>
                                                                    <CustomErrorMessage errors={errors} name="token" />
                                                                </FormGroup>
                                                                
                                                                <div className="text-center">
                                                                    { isLoading
                                                                        ? <Spinner size="sm" color="primary" />
                                                                        : <Button color="primary" type="submit">
                                                                            Verify Token
                                                                        </Button>
                                                                    }
                                                                    <Button color="secondary" onClick={() => setShowVerifyTokenField(!showVerifyTokenField)}>
                                                                            Cancel
                                                                    </Button>
                                                                </div>
                                                            </Row>
                                                        </Col>
                                                    </Form>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                        <Col className="mb-3"><h4>User Default Calendar</h4></Col>
                                    </Row>
                                    <Row>
                                        {
                                            userDefaultCalendar &&
                                            <Card>
                                                <CardHeader>
                                                    <div className="d-flex justify-content-between">
                                                        <div><strong>{userDefaultCalendar.calendar_name}</strong></div>
                                                        <div className="hover-primary cursor-pointer" onClick={(e) => editDefaultCalendar()}><i class="fas fa-edit mr-2"></i>Edit</div>
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    <div className="mb-3">Work day type: {userDefaultCalendar.work_day_type}</div>
                                                    <div className="mb-3">Working start time: {userDefaultCalendar.working_start_time}</div>
                                                    <div className="mb-3">Working end time: {userDefaultCalendar.working_end_time}</div>
                                                </CardBody>
                                            </Card>
                                        }
                                    </Row>
                                    <hr></hr>
                                </Container>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {
                showConfirmationModal
                && <ConfirmationModal
                    actionText={APP_CONSTANTS.CONFIRMATION_MODAL_ACTION_TEXTS}
                    onCancel={hideConfirmationModal}
                    onConfirm={disableTwoFactorAuthConfirm}
                    modalBody="Are you sure you want to disable two-factor authentication?"
                    showModal={showConfirmationModal}
                    modalTitle={ENUMS.MODAL.DISABLE_TWO_FACT_AUTH}
                />
            }
            {
                showAddEditCalendarModal && 
                <AddEditCalendarModal
                    closeModal={hideCalendarModal}
                    showModal={showAddEditCalendarModal}
                    modalTitle={addEditCalendarModalTitle}
                    calendarData={calendarData}
                    modalType={modalType}
                    confirmActionText={addEditCalendarModalActionText}
                    onSet={onDefaultCalendarSet}
                />
            }
        </React.Fragment>
    );
}