/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ENUMS from 'constants/appEnums';
import { useToasts } from 'react-toast-notifications';
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import { useForm } from 'react-hook-form';
import CustomErrorMessage from 'components/CustomErrorMessage/CustomErrorMessage';

// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Row,
	Col,
	Label,
	Container,
} from "reactstrap";
import CreatableSelect from 'react-select/creatable';
import makeAnimated from "react-select/animated";
import Select from "react-select";
import AuthNavbar from "components/Navbars/AuthNavbar";
import "./Register.css";
import UserUtil from "util/UserUtil";

export default function Register() {
	let history = useHistory();
    const { addToast } = useToasts();
	const location = useLocation();

	/**
	 * react-hook-form
	 */
	const { register, handleSubmit, errors, getValues } = useForm({
		mode: "onBlur"
	});
	const firstNameFocusRef = useRef(null);

	/**
	 * user details field states
	 */
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [contactNo, setContactNo] = useState("");
	const [timezone, setTimezone] = useState("");
	const [licence, setLicence] = useState("");
	
	const [showPassword, setShowPassword] = useState(false);

	// dropdown states
	const [timezoneOptions, setTimezoneOptions] = useState([]);
	const [licenceOptions, setLicenceOptions] = useState([]);
	
	const [isLoading, setIsLoading] = useState(true);

	const [showRegistrationSuccessful, setShowRegistrationSuccessful] = useState(false);
	const animatedComponents = makeAnimated();

	useEffect(() => {
        API
            .get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.TIMEZONE_GET_ALL,
            {
                ...HttpUtil.authHttpHeaders
            })
			.then((res) => {
				setTimezoneOptions(res.data);
				const userBrowserTimeZone = Intl.DateTimeFormat().resolvedOptions()
					.timeZone;
				const timezoneValue = res.data.find(
					(t) => t.utc.indexOf(userBrowserTimeZone) > -1
				).value;
				setTimezone(timezoneValue);
			})
			.catch((error) => {
				console.log(error);
			});
		
		API
            .get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.LICENCES_GET_ALL,
            {
                ...HttpUtil.authHttpHeaders
            })
			.then((res) => {
				const options = UserUtil.formatLicenceForDropdownBulk(res.data.licences);
				setLicenceOptions(options);
				console.log(options)
				if (location.state && location.state.planId) {
					const planId = location.state.planId;
					const cc = options.find(o => o.type == planId).value;
					console.log(cc)
					setLicence(cc);
				}
				else if (location.search) {
					const params = new URLSearchParams(location.search);
					const planId = params.get('planId');
					const cc = options.find(o => o.type == planId).value;
					console.log(cc)
					setLicence(cc);
				}
				
			})
			.catch((error) => {
				console.log(error);
			});
		
			

	}, []);

	useEffect(()=> {
		if (firstNameFocusRef.current) {
            firstNameFocusRef.current.focus();
        } 
	}, [firstNameFocusRef.current]);

	/**
	 * Redirect to Login
	 */
	const redirectToLogin = () => {
		history.push("/auth/login");
	};

	/**
	 * Call to create new user
	 *
	 * @param {object} e
	 */
	const handleRegistration = (data, e) => {
		e.preventDefault();
		API.post(
                ENUMS.AWS.API_NAME,
				ENUMS.API_ROUTES.USER_CREATE,
				{
                    ...HttpUtil.authHttpHeaders,
                    body: 
                    {
                        first_name: firstName,
                        last_name: lastName,
                        username: email,
                        email: email,
                        password: password,
                        confirmPassword: confirmPassword,
                        contact_no: contactNo,
                        timezone: timezone,
						licence_type_id: licence
                    }
                }
			)
			.then((res) => {
				if (res.status === 201 && res.data.success) {
					addToast('You have registerd successfully!', { 
						appearance: 'success',
						autoDismiss: true
					});
					setShowRegistrationSuccessful(true);
				} else {
					addToast('User not created, error occured! Contact Dev team', { 
						appearance: 'error',
						autoDismiss: true
					});
				}
			})
			.catch((error) => {
				if (error.response) {
					const responseData = error.response.data;
					addToast(responseData.message, { 
						appearance: 'error',
						autoDismiss: true
					});
				}
				console.log("Error Occured!", error);
			});
	};

	const goHomePage = () => {
        history.push("/auth/home");
    }

	useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
	
	return (
        <div className="bg-white">
			{
				<Container fluid className="register-container">
					<Row>
						<Col sm="12" md="5" className="outroute-left-pannel bg-white">
							<div className="mt-4 d-flex justify-content-between">
								<div><img className="cursor-pointer" onClick={() => goHomePage()} src={require("assets/img/home/logocop.svg")}></img></div>
								<div><span className="login-signup-link" onClick={() => redirectToLogin()}>Log in</span></div>
							</div>
							{
								showRegistrationSuccessful ?
									<div className="success-register-wrapper">
										<div className="success-register-card">
											<Card>
												<CardBody>
														<div className="text-center">
															Thank you for registering with CutoverPlan.<br></br>
															We have sent you a verification link on your email.<br></br>
															Please verify your account by clicking the link.
														</div>
												</CardBody>
											</Card>
										</div>
									</div>
								: 	<div className="outroute-wrapper">
										<div>
											<img className="cursor-pointer" onClick={() => goHomePage()} src={require("assets/img/home/arrow_back.png")} alt="Go back"></img>
											<span className="back-text">BACK</span>
										</div>
										<div className="outroute-title mb-5">Sign up now</div>
										<div className="mb-5">
											<div className="btn-wrapper text-center">
												<a href={ENUMS.API_ROUTES.AUTH_GOOGLE}>
													<Button className="btn-neutral btn-icon google-btn">
														<span className="btn-inner--icon">
															<img
																alt="..."
																src={require("assets/img/icons/common/google.svg")}
															/>
														</span>
														<span className="btn-inner--text">Sign up with Google</span>
													</Button>
												</a>
											</div>
										</div>
										<div className="text-center mb-4 some-div"><span>or</span></div>
										<div className="mb-4"><span className="fs-20">Sign up with email</span></div>
										<div className="mt-4">
											<Form role="form" onSubmit={handleSubmit(handleRegistration)} noValidate>
												<FormGroup>
													<Label className="outroute-form-label">First name *</Label>
													<Input
														placeholder="First name *"
														type="text"
														id="firstName"
														name="firstName"
														onChange={(e) => setFirstName(e.target.value)}
														innerRef={(e) => {
															firstNameFocusRef.current = e;
															register(
																e,
																{
																	required: "First name is required.",
																}
																);
															}}
														autoComplete="off"
													/>
													<CustomErrorMessage errors={errors} name="firstName" />
												</FormGroup>
												<FormGroup>
													<Label className="outroute-form-label">Last name *</Label>
													<Input
														placeholder="Last name *"
														type="text"
														id="lastName"
														name="lastName"
														onChange={(e) => setLastName(e.target.value)}
														innerRef={register(
															{
																required: "Last name is required.",
															}
														)}
														autoComplete="off"
													/>
													<CustomErrorMessage errors={errors} name="lastName" />
												</FormGroup>
												<FormGroup>
													<Label className="outroute-form-label">Email *</Label>
													<Input
														placeholder="Email *"
														type="email"
														autoComplete="new-email"
														name="email"
														onChange={(e) => setEmail(e.target.value)}
														innerRef={register(
															{
																required: "Email is required.",
																pattern: {
																	value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
																	message: "Invalid pattern for email. Ex: example@example.com"
																},
															}
														)}
														autoComplete="off"
													/>
													<CustomErrorMessage errors={errors} name="email" />
												</FormGroup>
												<FormGroup>
													<Label className="outroute-form-label">Password *</Label>
														<Input
															placeholder="Password *"
															type={showPassword ? "text" : "password"}
															autoComplete="new-password"
															name="password"
															onChange={(e) => setPassword(e.target.value)}
															innerRef={register(
																{
																	required: "Password is required.",
																	pattern: {
																		value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
																		message: "Password should contain at least one capital letter, one lower case letter, a number and a special character"
																	},
																	minLength: {
																		value: 8,
																		message: "Password should be minimum 8 charachters long."
																	}
																}
															)}
														autoComplete="off"
													/>
													<p className="text-gray mt-2 text-small">Password should be minimum 8 characters long and should contain at least one capital letter, one lower case letter, a number and a special character.</p>
													<CustomErrorMessage errors={errors} name="password" />
												</FormGroup>
												<FormGroup>
													<Label className="outroute-form-label">Confirm Password *</Label>
														<Input
															placeholder="Confirm Password *"
															type="password"
															autoComplete="confirm-password"
															name="confirm-password"
															onChange={(e) => setConfirmPassword(e.target.value)}
															innerRef={register(
																{
																	validate: (value) => {
																		if (value === getValues()["password"]) {
																			return true;
																		} else {
																			return "The passwords do not match";
																		}
																	}
																}
															)}
															autoComplete="off"
														/>
													<CustomErrorMessage errors={errors} name="confirm-password" />
												</FormGroup>
												<FormGroup>
													<Label className="outroute-form-label">Contact number *</Label>
														<Input
															placeholder="Contact number *"
															type="text"
															id="contactNumber"
															name="contactNumber"
															onChange={(e) => setContactNo(e.target.value)}
															innerRef={register(
																{
																	required: "Contact number is required.",
																	pattern: {
																		value: /\d{8}/,
																		message: "Invalid contact number"
																	}	
																}
															)}
															autoComplete="off"
														/>
													<CustomErrorMessage errors={errors} name="contactNumber" />
												</FormGroup>
												<FormGroup>
													<Label for="timezone">Timezone</Label>
													<Select
														id="timezone"
														className="form-control-alternative"
														getOptionLabel={option =>`${option.text}`}
														options={timezoneOptions}
														components={animatedComponents}
														value={timezoneOptions.filter(option => option.value === timezone)}
														onChange={(selectedTimezone)=> setTimezone(selectedTimezone.value)}
													/>
												</FormGroup>
												<FormGroup>
													<Label for="timezone">Licence</Label>
													<Select
														id="timezone"
														className="form-control-alternative"
														getOptionLabel={option =>`${option.label}`}
														options={licenceOptions}
														components={animatedComponents}
														value={licenceOptions.filter(option => option.value === licence)}
														onChange={(selectedLicence)=> setLicence(selectedLicence.value)}
													/>
												</FormGroup>
												<Button className="index-button-primary w-100 my-4 cursor-pointer mb-5" color="primary" type="submit">
													Sign up
												</Button>
												<div className="mb-5"></div>
											</Form>
										</div>
									</div>
							}
							<div className="outroute-footer">
								<Row className="d-flex justify-content-between">
									<Col sm="12" md="4" className="d-none d-md-block">
										<img className="cursor-pointer"  onClick={() => goHomePage()} src={require("assets/img/home/logocop_gray.png")}></img>
									</Col>
									<Col className="sm-text-center text-right outroute-footer-links" sm="12" md="8">
										<span className="ml-md-5">Terms & Conditions</span>
										<span className="ml-md-5">Privacy Policy</span>
									</Col>
								</Row>
							</div>
						</Col>
						<Col className="d-none d-md-flex outroute-right-col align-items-center stickthis" md="7">
							<div className="outroute-register-top-content">Have more control over your projects</div>
							<div className="outroute-register-col-gradient"></div>
							<div className="d-flex align-items-center w-100 h-100 justify-content-center">
								<img src={require("assets/img/home/register_human.png")} className="right-img ml-5"></img>
								<div className="register-blur-image"></div>
								<div className="register-blur-image"></div>
								<div className="register-blur-image"></div>
								<img src={require('assets/img/home/toggle.svg')} className="right-floating-img icon-shadow right-img-toggle register-toggle"></img>
								<img src={require('assets/img/home/cardboard.svg')} className="right-floating-img icon-shadow right-img-cardboard register-cardboard"></img>
								<img src={require('assets/img/home/calendar.svg')} className="right-floating-img icon-shadow right-img-calendar register-calendar"></img>
								<img src={require('assets/img/home/dialog_1.svg')} className="right-floating-img icon-shadow right-img-dialog register-dialog"></img>
							</div>
							<div className="outroute-register-bottom-content">Track all Tasks, Actions and RAID items. Perform deployment dress rehearsals to automatically recalibrate deployment go-live dates and times.</div>
						</Col>
					</Row>
            	</Container>
			}
        </div>
    );
}
