import { API } from "aws-amplify";
import Header from "components/Headers/Header";
import ENUMS from "constants/appEnums";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, CardHeader, CardBody, Card } from "reactstrap";
import HttpUtil from "util/HttpUtil";
/**
 * Function rendered for notification settings page
 * @param {Props} props 
 */
export default function DataFix (props) {

    // grab current state
    const user = useSelector(state => state.authReducer.user);

    const [runningScript, setRunningScript] = useState(true);

    const runDataFixScript = (token) => {
        API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.RUN_DATA_FIX_SCRIPT,
            {
                ...HttpUtil.adminHttpHeaders(),
                body: {
                    user_id: user.id,
                    token: token
                }
            })
            .then(res => {
                if(res && res.status === 200) {
                    console.log(res);
                    setRunningScript(false);
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        const search = props.location.search;
        const params = new URLSearchParams(search);
        runDataFixScript(params.get('script-token'));
    }, []);
    return (
        <>
            {
                runningScript ? "Running..." : "Complete"
            }
        </>
    );
}