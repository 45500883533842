/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";
// import "./ProjectViewHeader.css";
import { useHistory } from "react-router-dom";
import UserUtil from "util/UserUtil";
import TaskUtil from "util/TaskUtil";
import ProjectUtil from "util/ProjectUtil";
import ENUMS from "constants/appEnums";
import DateTimeUtils from "util/DateTimeUtils";
import { format } from "date-fns";

export default function ExecutionModeViewHeader (props) {

    const history = useHistory();

    return (
        <>
            <div className="header bg-info pb-8 pt-5 pt-md-8">
                <Container fluid>
                    <div className="header-body">
                        <Row>
                            <Col>
                                <h2 className="text-white">
                                    Name:
                                </h2>
                                <p className="text-white">
                                    {props.rehearsalDetails.name}
                                </p>
                            </Col>
                            <Col>
                                <h2 className="text-white">
                                    Type:
                                </h2>
                                <p className="text-white">
                                    {props.rehearsalDetails.Rehearsal_Type.type}
                                </p>
                            </Col>
                            <Col>
                                <h2 className="text-white">
                                    Status:
                                </h2>
                                <p className="text-white">
                                    {props.rehearsalDetails.Rehearsal_Status.status}
                                </p>
                            </Col>
                            <Col>
                                <h3 className="text-white">
                                    Start Time:
                                </h3>
                                <p className="text-white"  id="start_end_tooltip">
                                    {
                                        format(DateTimeUtils.convertTimezone(
                                            new Date(props.rehearsalDetails['start_time']), props.currentUser), 
                                            DateTimeUtils.getUserDateTimeFormat(
                                                props.currentUser.date_format,
                                                props.currentUser.twenty_four_hour_clock
                                            )
                                        )
                                    }
                                </p>
                            </Col>
                            <Col>
                                <h3 className="text-white">
                                    End Time:
                                </h3>
                                <p className="text-white"  id="start_end_tooltip">
                                    {
                                        format(DateTimeUtils.convertTimezone(
                                            new Date(props.rehearsalDetails['end_time']), props.currentUser), 
                                            DateTimeUtils.getUserDateTimeFormat(
                                                props.currentUser.date_format,
                                                props.currentUser.twenty_four_hour_clock
                                            )
                                        )
                                    }
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}