import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import allActions from 'redux/actions/index';
import { useDispatch, useSelector } from "react-redux";
import ENUMS from 'constants/appEnums';
import { useToasts } from 'react-toast-notifications';
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import { useAppContext } from "util/ContextUtil";
import { useForm } from 'react-hook-form';
import CustomErrorMessage from 'components/CustomErrorMessage/CustomErrorMessage';
import "./Login.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  Label,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
  Container
} from "reactstrap";
import AuthNavbar from "components/Navbars/AuthNavbar";
import AuthUtil from "util/AuthUtil";
import { LinkContainer } from "react-router-bootstrap";

/**
 * Function to implement the Login View.
 */
export default function Login () {
    let location = useLocation();
    let history = useHistory();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur"
    });
    const userNameFocusRef = useRef(null);

    // grab current state
    const user = useSelector(state => state.authReducer.user);

    // state variables
    const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    /**
     * Method to handle the user login after login button press.
     * @param {FormData} data 
     * @param {Event} e 
     */
    const handleLogin = (data, e) => {
        e.preventDefault();
        setIsLoading(true);
        API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.AUTH_LOGIN,
        {
            ...HttpUtil.authHttpHeaders,
            body: {
                email : username.toLowerCase(), 
                password : password
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                if (res.data.user.mfa_enabled) {
                    console.log('here')
                    history.push({ 
                        pathname: '/auth/2fa-verification',
                        state: res.data.user
                    });
                } else {
                    localStorage.setItem('JWT', res.data.token);
                    userHasAuthenticated(true);
                    dispatch(allActions.authActions.setUserState(res.data.user));
                    dispatch(allActions.authActions.setUserProjectList(res.data.projectList));
                    
                    if (location && location.state && location.state.from) {
                        history.push({
                            pathname: location.state.from.pathname,
                            search: location.state.from.search,
                            state: location.state.from.state
                        });
                    } else {
                        history.push('/admin/projects-home');
                    }
                    AuthUtil.setTheme();
                }
            }
            else {
                addToast(res.data.message, { 
                    appearance: 'error',
                    autoDismiss: true
                });
            }
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                console.error(error.response);
                addToast(
                    error.response.data.message ? error.response.data.message : "Invalid Credentials", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    };

    /**
     * Method which handles the account register redirection link
     * @param {Event} e 
     */
    const handleNewAccountRedirection = (e) => {
        history.push('/auth/register');
    }

    /**
     * Method which handles the reset password link
     * @param {Event} e 
     */
    const handleResetPassword = (e) => {
        history.push('/auth/reset-password');
    }

    // useEffect(() => {
    //     console.log(user);
    //     if(user) {
    //         history.push('/admin/project-home');
    //     }
    // }, [])

    useEffect(() => {
        console.log(location.state)
        if (userNameFocusRef.current) {
            userNameFocusRef.current.focus();
        } 
    }, [userNameFocusRef.current]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const goHomePage = () => {
        history.push("/auth/home");
    }

    return (
      <div className="bg-white">
        <Container fluid>
          <Row>
            <Col sm="12" md="5" className="outroute-left-pannel bg-white">
              <div className="mt-4 d-flex justify-content-between">
                <div>
                  <img
                    className="cursor-pointer"
                    onClick={() => goHomePage()}
                    src={require("assets/img/home/logocop.svg")}
                  ></img>
                </div>
                <div>
                  Don’t have a free account yet?{" "}
                  <span
                    className="login-signup-link"
                    onClick={() => handleNewAccountRedirection()}
                  >
                    Sign up
                  </span>
                </div>
              </div>
              <div className="outroute-wrapper">
                <div>
                  <img
                    className="cursor-pointer"
                    onClick={() => goHomePage()}
                    src={require("assets/img/home/arrow_back.png")}
                    alt="Go back"
                  ></img>
                  <span className="back-text">BACK</span>
                </div>
                <div className="outroute-title">Log In</div>
                <div className="outroute-content">
                  Enter your details to log in to your account.
                </div>
                <div className="mt-4">
                  <Form role="form" onSubmit={handleSubmit(handleLogin)}>
                    <FormGroup className="mb-3">
                      <Label className="outroute-form-label">Email</Label>
                      <Input
                        name="username"
                        placeholder=""
                        type="email"
                        onChange={(e) => setUsername(e.target.value)}
                        autoComplete="new-email"
                        innerRef={(e) => {
                          userNameFocusRef.current = e;
                          register(e, {
                            required: "Email is required.",
                            pattern: {
                              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                              message:
                                "Invalid pattern for email. Ex: example@example.com",
                            },
                          });
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label className="outroute-form-label">Password</Label>
                      <Input
                        name="password"
                        placeholder=""
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                        innerRef={register({
                          required: "Password is required.",
                        })}
                      />
                    </FormGroup>

                    <div className="text-center mb-4">
                      {isLoading ? (
                        <Spinner size="sm" color="primary" />
                      ) : (
                        <Button
                          className="index-button-primary w-100 my-4 cursor-pointer"
                          color="primary"
                          type="submit"
                        >
                          Log in
                        </Button>
                      )}
                    </div>
                  </Form>
                </div>
                <LinkContainer to="/auth/reset-password">
                  <div className="text-center mb-4 login-forgot-link">Forgot Password</div>
                </LinkContainer>
                <div className="text-center mb-4 some-div">
                  <span>or</span>
                </div>
                <div>
                  <div className="btn-wrapper text-center">
                    <a href={ENUMS.API_ROUTES.AUTH_GOOGLE}>
                      <Button className="btn-neutral btn-icon google-btn">
                        <span className="btn-inner--icon">
                          <img
                            alt="..."
                            src={require("assets/img/icons/common/google.svg")}
                          />
                        </span>
                        <span className="btn-inner--text">
                          Sign in with Google
                        </span>
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="outroute-footer">
                <Row className="d-flex justify-content-between">
                  <Col sm="12" md="4" className="d-none d-md-block">
                    <img
                      className="cursor-pointer"
                      onClick={() => goHomePage()}
                      src={require("assets/img/home/logocop_gray.png")}
                    ></img>
                  </Col>
                  <Col
                    className="sm-text-center text-right outroute-footer-links"
                    sm="12"
                    md="8"
                  >
                    <span className="ml-md-5">Terms & Conditions</span>
                    <span className="ml-md-5">Privacy Policy</span>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              className="d-none d-md-flex outroute-right-col align-items-center"
              md="7"
            >
              <div className="outroute-login-col-gradient"></div>
              <div className="d-flex align-items-center w-100 h-100">
                <img
                  src={require("assets/img/home/login_human.png")}
                  className="right-img ml-5"
                ></img>
                <div className="login-blur-image"></div>
                <div className="login-blur-image"></div>
                <div className="login-blur-image"></div>
                <img
                  src={require("assets/img/home/toggle.svg")}
                  className="right-floating-img icon-shadow right-img-toggle login-toggle"
                ></img>
                <img
                  src={require("assets/img/home/cardboard.svg")}
                  className="right-floating-img icon-shadow right-img-cardboard login-cardboard"
                ></img>
                <img
                  src={require("assets/img/home/calendar.svg")}
                  className="right-floating-img icon-shadow right-img-calendar login-calendar"
                ></img>
                <img
                  src={require("assets/img/home/dialog_1.svg")}
                  className="right-floating-img icon-shadow right-img-calendar login-dialog"
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
}