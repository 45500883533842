/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import ENUMS from 'constants/appEnums';
import { LinkContainer } from "react-router-bootstrap";
import './Sidebar.css';
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";
import FeedbackModal from "components/FeedbackModal/FeedbackModal";
import FutureFeatureModal from "components/FutureFeatureModal/FutureFeatureModal";

class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
    showFeedbackModal: false,
    showFutureFeatureVoteModal: false
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };
  //close feedback modal
  closeFeedbackModal = () => {
    this.setState({
      showFeedbackModal: false
    });
  }
  openFeedbackModal = () => {
    this.setState({
      showFeedbackModal: true
    })
  }
  openFutureFeatureVoteModal = () => {
    console.log('here');
    this.setState({
      showFutureFeatureVoteModal: true
    });
  }
  closeFutureFeatureVoteModal = () => {
    this.setState({
      showFutureFeatureVoteModal: false
    });
  }
  // creates the links that appear in the left menu / Sidebar
  createLinks = routes => {
    return routes.map((prop, key) => {
      if (prop.layout !== ENUMS.ROUTES.AUTH && prop.menuItem) {
        return (
            <LinkContainer key={key} to={prop.layout + prop.path}>
                <NavItem>
                    <NavLink
                    tag={NavLinkRRD}
                    to={prop.layout + prop.path}
                    onClick={this.closeCollapse}
                    activeClassName="active"
                    >
                    <i className={prop.icon} />
                    {
                      <span className={`${this.props.sidebarCollapsed && !this.props.showSidebar ? 'hide-sidebar-component' : ''}`} >{prop.name}</span>
                    }
                    </NavLink>
                </NavItem>
          </LinkContainer>
        );
      }
    });
  };
  
  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank"
      };
    }
    return (
      <>
        <Navbar
          className={`navbar-vertical fixed-left navbar-light side-nav ${this.props.sidebarCollapsed && !this.props.showSidebar ? 'sidenavbar-collapsed' : ''} `}
          expand="md"
          id="sidenav-main"
        >
          <Container fluid>
            {/* Toggler */}
            <button
              className="navbar-toggler"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-icon" />
            </button>
            {/* Brand */}
            {logo ? (
              <NavbarBrand {...navbarBrandProps}>
                <img
                  alt={logo.imgAlt}
                  className="navbar-brand-img"
                  src={logo.imgSrc}
                />
              </NavbarBrand>
            ) : null}
            {/* User */}
            <Nav className="align-items-center d-md-none">
              {/** TO-DO Commented out for later edition of elements on mobile view
              <UncontrolledDropdown nav>
                
                <DropdownToggle nav className="nav-link-icon">
                  <i className="ni ni-bell-55" />
                </DropdownToggle>
                
                <DropdownMenu
                  aria-labelledby="navbar-default_dropdown_1"
                  className="dropdown-menu-arrow"
                  right
                >
                  <DropdownItem>Action</DropdownItem>
                  <DropdownItem>Another action</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>Something else here</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>**/}
              <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require("assets/img/theme/team-1-800x800.jpg")}
                      />
                    </span>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Settings</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Activity</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>Support</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            {/* Collapse */}
            <Collapse navbar isOpen={this.state.collapseOpen}>
              {/* Collapse header */}
              <div className="navbar-collapse-header d-md-none">
                <Row>
                  {logo ? (
                    <Col className="collapse-brand" xs="6">
                      {logo.innerLink ? (
                        <Link to={logo.innerLink}>
                          <img alt={logo.imgAlt} src={logo.imgSrc} />
                        </Link>
                      ) : (
                        <a href={logo.outterLink}>
                          <img alt={logo.imgAlt} src={logo.imgSrc} />
                        </a>
                      )}
                    </Col>
                  ) : null}
                  <Col className="collapse-close" xs="6">
                    <button
                      className="navbar-toggler"
                      type="button"
                      onClick={this.toggleCollapse}
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              {/* Form */}
              <Form className="mt-4 mb-3 d-md-none">
                <InputGroup className="input-group-rounded input-group-merge">
                  <Input
                    aria-label="Search"
                    className="form-control-rounded form-control-prepended"
                    placeholder="Search"
                    type="search"
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <span className="fa fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Form>
              {/* Navigation */}
              <Nav navbar>{this.createLinks(routes)}</Nav>
            </Collapse>
            <div className="sidebar-lock">
              <button
                className={
                  `toggle-nav 
                  ${(this.props.sidebarCollapsed && !this.props.showSidebar) ? 'toggle-nav-collapsed' : ''}
                  ${!this.props.sidebarCollapsed ? 'sidebar-locked' : ''}`
                }
                type="button"
                onClick={this.props.toggleCollapseSidebar}
              >
                <i className="fas fa-lock"></i>
              </button>
            </div>
            <div className={`sidebar-bottom-links ${(this.props.sidebarCollapsed && !this.props.showSidebar) ? 'hide-sidebar-component' : ''}`}>
              <div className={`${(this.props.sidebarCollapsed && !this.props.showSidebar) ? 'hide-sidebar-component' : ''}`} onClick={this.openFeedbackModal}>Give feedback</div>
              <div className={`${(this.props.sidebarCollapsed && !this.props.showSidebar) ? 'hide-sidebar-component' : ''}`} onClick={this.openFutureFeatureVoteModal}>Vote For Features</div>
            </div>
            <div className={`fs-11 cursor-pointer mt-3 d-flex justify-content-center ${(this.props.sidebarCollapsed && !this.props.showSidebar) ? 'hide-sidebar-component' : ''}`}>
              <div className={`${(this.props.sidebarCollapsed && !this.props.showSidebar) ? 'hide-sidebar-component' : ''}`} onClick={this.props.changeTheme}><i className="fas fa-palette mr-2"></i>Change Theme</div>
            </div>
          </Container>
        </Navbar>
        {
          this.state.showFeedbackModal && 
          <FeedbackModal
            {...this.props}
            showModal={this.state.showFeedbackModal}
            closeFeedbackModal={this.closeFeedbackModal}
          >
          </FeedbackModal>
        }
        {
          this.state.showFutureFeatureVoteModal &&
          <FutureFeatureModal
            showModal={this.state.showFutureFeatureVoteModal}
            onCancel={this.closeFutureFeatureVoteModal}
          ></FutureFeatureModal>
        }
      </>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}]
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};

export default Sidebar;
