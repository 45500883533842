import './UploadModal.css';
import { API } from "aws-amplify";
import FileUtil from "util/FileUtil";
import HttpUtil from "util/HttpUtil";
import ENUMS from "constants/appEnums";
import  axios  from "../../axiosConfig";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import makeAnimated from "react-select/animated";
import React, { useEffect, useState } from "react";
import CreatableSelect from 'react-select/creatable';
import { useToasts } from "react-toast-notifications";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Card, CardBody, Spinner } from "reactstrap";
import Select from "react-select";
import TaskUtil from 'util/TaskUtil';
import ActionUtil from 'util/ActionUtil';

export default function UploadModal(props) {
    const { addToast } = useToasts();
    const { handleSubmit } = useForm({
        mode: "onBlur"
    });
    const user = useSelector(state => state.authReducer.user);

    const [selectedFile, setSelectedFile] = useState('');
    const [description, setDescription] = useState('');
    const [fileTypeList, setFileTypeList] = useState([]);
    const [fileType, setFileType] = useState({});
    const [task, setTask] = useState();
    const [taskList, setTaskList] = useState([]);
    const [action, setAction] = useState();
    const [actionList, setActionList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const animatedComponents = makeAnimated();

    // On file select (from the pop up) 
    const onFileChange = (event) => {
        if (event.target.files[0].size > 15000000) {
            addToast(
                "File size is larger than 15MB. Please upload a file upto 15MB",
                { 
                    appearance: 'error',
                    autoDismiss: true
                }
            );
            return;
        }
        setSelectedFile(event.target.files[0]);
    }; 

    const handleConfirm = () => {
        getS3PresignedUrl(selectedFile);
    }

    const getS3PresignedUrl = (file) => {
        setIsSubmitting(true);
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.S3_UPLOAD_SIGNED_URL, 
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                fileName: file.name,
                fileType: file.type,
                path: props.uploadTo ? props.uploadTo : '',
                projectId: props.projectId ? props.projectId : '',
                taskId: props.taskId ? props.taskId : ''
            },
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                uploadFileToS3(res.data.signedRequest, file);
            }
        })
        .catch(error => {
            setIsSubmitting(false);
            if (error.response) {
                const content = (
                    <div>
                        <strong>Failed to generate presigned url.</strong>
                        <div>
                            {error.response.data && error.response.data.message}
                        </div>
                    </div>
                )
                addToast(
                    content,
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const uploadFileToS3 = (signedRequest, file) => {
        axios.put(signedRequest.presignedURL, file)
        .then(result => {
            saveUploadedInfoToDatabase(signedRequest);
        })
        .catch(err => {
            setIsSubmitting(false);
            console.log(err);
        })
    }

    const saveUploadedInfoToDatabase = (signedRequest) => {
        let requestParams = {
            s3filePath: signedRequest.fileUploadPath,
            fileName: selectedFile.name,
            description: description,
            userId: user.id,
            typeId: fileType ? fileType.value : null,
            assignToTaskId: props.uploadTo === 'project' && task ? task.value : null,
            assignToActionId: props.uploadTo === 'project' && action ? action.value : null
        };
        
        requestParams = (props.uploadTo === 'project')
            ? {...requestParams, projectId: props.projectId}
            : {...requestParams, taskId: props.taskId};

        const apiRoute = (props.uploadTo === 'project')
            ? ENUMS.API_ROUTES.PROJECT_FILE_CREATE
            : ENUMS.API_ROUTES.TASK_FILE_CREATE;

        API.post(ENUMS.AWS.API_NAME, apiRoute, 
        {
            ...HttpUtil.adminHttpHeaders(),
            body: requestParams,
        })
        .then(res => {
            if (res.status === 201 && res.data.success) {
                (props.uploadTo === 'project') 
                    ? props.refreshViewOnAction(ENUMS.TABS.FILES_LIST, "File uploaded successfully.")
                    : props.refreshViewOnAction("File uploaded successfully.");
                
                closeModal();
                setIsSubmitting(false);
            }
        })
        .catch(error => {
            setIsSubmitting(false);
            if (error.response) {
                const content = (
                    <div>
                        <strong>Failed to add feedback.</strong>
                        <div>
                            {error.response.data.message }
                        </div>
                    </div>
                )
                addToast(
                    content,
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const removeFile = () => {
        setSelectedFile('');
    }

    const handleFileTypeCreation = (inputValue) => {
        // handle file type creation
        API.post(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.FILE_TYPE_CREATE,
        {
            ...HttpUtil.adminHttpHeaders(),
            body: 
            {
                projectId: props.projectId,
                fileTypeName: inputValue
            }
        })
        .then(res => {
            if (res.status === 201 && res.data.success) {
                setFileType(FileUtil.formatFileTypeForDropdown(res.data.createdType));
                setFileTypeList([...fileTypeList, FileUtil.formatFileTypeForDropdown(res.data.createdType)]);
            }
        })
        .catch(error => {
            if (error.response) {
                console.error(error.response);
                addToast(
                    "Failed to create file type.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        });
    }

    const formatDropDownLabelType = (inputValue) => {
        return "Create File Type "+ inputValue;
    }

    const closeModal = () => {
        props.closeUploadModal();
    }

    useEffect(() => {
        API.get(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.FILE_TYPES_GET, 
        {
            ...HttpUtil.adminHttpHeaders(),
            queryStringParameters: 
            {
                projectId: props.projectId,
            },
        })
        .then(res => {
            if (res.status === 200 && res.data.success) {
                setFileTypeList(FileUtil.formatFileTypeForDropdownBulk(res.data.fileTypes));
                setIsLoading(false);
            }
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                const content = (
                    <div>
                        <strong>Failed to get file types.</strong>
                        <div>
                            {error.response.data && error.response.data.message}
                        </div>
                    </div>
                )
                addToast(
                    content,
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }, []);

    useEffect(() => {
        if (props.projectTaskList && props.projectTaskList.length > 0) {
            setTaskList(TaskUtil.formatTaskForDropdownBulk(props.projectTaskList));
        }
        else {
            setTaskList([]);
        }
    }, [props.projectTaskList]);

    useEffect(() => {
        if (props.projectActionList && props.projectActionList.length > 0) {
            setActionList(ActionUtil.formatActionForDropdownBulk(props.projectActionList));
        }
        else {
            setActionList([]);
        }
    }, [props.projectActionList]);

    // Method to handle task change
    const handleTaskChange = (newValue) => {
        setTask(newValue);
    }

    // Method to handle action change
    const handleActionChange = (newValue) => {
        setAction(newValue);
    }

    return (
        <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={props.showModal}
            toggle={() => closeModal()}
            backdrop="static"
        >
            <ModalHeader className="modal-header" toggle={() => closeModal()} tag="h2">{props.modalTitle}</ModalHeader>
            <Form role="form" onSubmit={handleSubmit(handleConfirm)}>
                <ModalBody className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-4 py-lg-4">
                            {
                                !selectedFile &&
                                <div className="dialog-content-wrapper">
                                    <div className="dialog-content">
                                    <div className="text-center">
                                        <i className="fas fa-file fs-120 mb-3"></i>
                                        <div>Drop file here (or)</div>
                                        <div><u>Choose File</u></div>
                                    </div>
                                    </div>
                                    <Input
                                        type="file"
                                        name="file"
                                        id="file"
                                        className="file-selector"
                                        onChange={(e) => onFileChange(e)}
                                    />
                                </div>
                            }
                            {
                                selectedFile &&
                                <div className="file-wrapper">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <i className="fas fa-file mr-3 file-icon"></i>
                                        </div>
                                        <div className="file-info">
                                            <div className="file-name text-truncate">{selectedFile.name}</div>
                                            <div className="file-size">{FileUtil.humanFileSize(selectedFile.size)}</div>
                                        </div>
                                    </div>
                                    <div className="file-action" onClick={() => removeFile()}>
                                        <i className="far fa-trash-alt text-danger"></i>
                                    </div>
                                </div>
                            }
                            <FormGroup className="mb-3">
                                <Label for="description">Description:</Label>
                                <Input
                                    type="textarea"
                                    name="description"
                                    id="description"
                                    value={description}
                                    onChange={(e)=>setDescription(e.target.value)}
                                />
                            </FormGroup>
                            {
                                props.uploadTo == 'project' &&
                                <FormGroup>
                                    <Label for="task_id">Task</Label>
                                    <Select
                                        id="task_id"
                                        className="form-control-alternative"
                                        isClearable
                                        components={animatedComponents}
                                        isDisabled={isLoading}
                                        isLoading={isLoading}
                                        onChange={(newValue)=>handleTaskChange(newValue)}
                                        options={taskList}
                                        value={task}
                                    />
                                </FormGroup>
                            }
                            {
                                props.uploadTo == 'project' &&
                                <FormGroup>
                                    <Label for="action_id">Action</Label>
                                    <Select
                                        id="action_id"
                                        className="form-control-alternative"
                                        isClearable
                                        components={animatedComponents}
                                        isDisabled={isLoading}
                                        isLoading={isLoading}
                                        onChange={(newValue)=>handleActionChange(newValue)}
                                        options={actionList}
                                        value={action}
                                    />
                                </FormGroup>
                            }
                            <FormGroup className="mb-0">
                                <Label for="fileType">Type of file</Label>
                                <CreatableSelect
                                    id="fileType"
                                    className="form-control-alternative"
                                    isClearable
                                    components={animatedComponents}
                                    isDisabled={isLoading}
                                    isLoading={isLoading}
                                    onChange={(newValue)=>setFileType(newValue)}
                                    onCreateOption={handleFileTypeCreation}
                                    createOptionPosition="first"
                                    formatCreateLabel={formatDropDownLabelType}
                                    options={fileTypeList}
                                    value={fileType}
                                />
                            </FormGroup>
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <Button 
                        color="secondary" 
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </Button>
                    {' '}
                    {isSubmitting ?
                        <Spinner size="sm" color="primary" /> :
                        <Button 
                            color="primary" 
                            type="submit"
                            disabled={!selectedFile}
                        >
                            {props.modalButtonName}
                        </Button>
                    }
                    {" "}
                </ModalFooter>
            </Form>
        </Modal>
    )
}
