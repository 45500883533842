import React, { useState, useEffect, useRef } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	FormGroup,
	Label,
	Input,
	Form,
	InputGroup,
} from "reactstrap";
import Select from 'react-select';
import { API } from "aws-amplify";
import { useToasts } from 'react-toast-notifications';
import ENUMS from 'constants/appEnums';
import HttpUtil from "util/HttpUtil";
import { useForm } from 'react-hook-form';
import CustomErrorMessage from 'components/CustomErrorMessage/CustomErrorMessage';

import "./AddEditCalendarModal.css";
import DataOptions from "constants/appDataOptions";

export default function AddEditCalendarModal(props) {
    const { register, handleSubmit, errors } = useForm({
		mode: "onBlur"
	});
	const calendarNameFocusRef = useRef(null);

	const [calendarName, setCalendarName] = useState("");
    const [workingDays, setWorkingDay] = useState([]);
    const [workingStartTime, setWorkingStartTime] = useState("00:00");
	const [workingEndTime, setWorkingEndTime] = useState("00:00");
	const [copyCalendarHolidays, setCopyCalendarHolidays] = useState(false);
	const { addToast } = useToasts();

	const workingDayOptions = DataOptions.WORKING_DAYS_OPTIONS;

	/**
	 * Call to create new calendar
	 *
	 */
	const addCalendar = () => {
		API
			.post(
                ENUMS.AWS.API_NAME,
				ENUMS.API_ROUTES.CALENDAR_CREATE,
				{
                    ...HttpUtil.adminHttpHeaders(),
                    body:
                    {
                        project_settings_id: props.projectSettingsId,
                        calendar_name: calendarName,
                        work_day_type: workingDays.map(w => w.value).toString(),
                        working_start_time: workingStartTime,
                        working_end_time: workingEndTime,
                    }
				}
			)
			.then((res) => {
				if (res.status === 201 && res.data.success) {
					addToast('A new calendar has been created successfully!', { 
						appearance: 'success',
						autoDismiss: true
					});
                    props.refreshGrid();
                    props.closeModal(false);
				} else {
					addToast( 
						"Calendar not created, error occured!.", 
						{ 
							appearance: 'error',
							autoDismiss: true
						}
					);
				}
			})
			.catch((error) => {
				console.log("Error Occured!", error);
				addToast(
					"Calendar not created, error occured!.", 
                    { 
                        appearance: 'error',
						autoDismiss: true
                    }
                );
			});
	};

	/**
	 * Call to update the calendar
	 *
	 */
	const updateCalendar = () => {
		API
			.put(
                ENUMS.AWS.API_NAME,
				ENUMS.API_ROUTES.CALENDAR_UPDATE,
				{
                    ...HttpUtil.adminHttpHeaders(),
                    body:
                    {
                        id: props.calendarData.id,
                        project_setting_id: props.projectSettingsId,
                        calendar_name: calendarName,
                        work_day_type: workingDays.map(w => w.value).toString(),
                        working_start_time: workingStartTime,
                        working_end_time: workingEndTime,
                    }
				}
			)
			.then((res) => {
				if (res.status === 200 && res.data.success) {
					addToast('The calendar was updated successfully!', { 
						appearance: 'success',
						autoDismiss: true
					});
                    props.refreshGrid();
                    props.closeModal(false);
				} else {
					addToast(
						"Calendar not updated, error occured!.", 
						{ 
							appearance: 'error',
							autoDismiss: true
						}
					);
				}
			})
			.catch((error) => {
				console.log("Error Occured!", error);
				addToast(
					"Calendar not updated, error occured!.", 
                    { 
                        appearance: 'error',
						autoDismiss: true
                    }
                );
			});
	};

	/**
	 * Call to copy the calendar
	 *
	 */
	const copyCalendar = () => {
		API
			.post(
                ENUMS.AWS.API_NAME,
				ENUMS.API_ROUTES.CALENDAR_COPY,
				{
                    ...HttpUtil.adminHttpHeaders(),
                    body: 
                    {
                        copy_from_calendar_id: props.calendarData.id,
						project_settings_id: props.projectSettingsId,
						calendar_name: calendarName,
						work_day_type: workingDays.map(w => w.value).toString(),
                        working_start_time: workingStartTime,
                        working_end_time: workingEndTime,
						copy_holidays: copyCalendarHolidays
                    }
				}
			)
			.then((res) => {
				if (res.status === 201 && res.data.success) {
					const successMessage = `The calendar was copied from ${props.calendarData.calendar_name} successfully`
						+ (copyCalendarHolidays ? ', along with its Calendar Exception' : '.');
					addToast(successMessage, { 
						appearance: 'success',
						autoDismiss: true
					});
                    props.refreshGrid();
                    props.closeModal(false);
				} else {
					addToast(
						"Calendar not created, error occured!.", 
						{ 
							appearance: 'error',
							autoDismiss: true
						}
					);
				}
			})
			.catch((error) => {
				console.log("Error Occured!", error);
				addToast(
					"Calendar not created, error occured!.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
			});
	};

	const setCalendar = () => {
		const calendarDetails = {
			calendar_id: props.calendarData.id,
			calendar_name: calendarName,
			work_day_type: workingDays.map(w => w.value).toString(),
			working_start_time: workingStartTime,
			working_end_time: workingEndTime,
		}
		props.onSet(calendarDetails);
	}

	const handleConfirm = (data, e) => {
        e.preventDefault();
		switch(props.modalType) {
			case ENUMS.MODAL.ADD_MODE: 
				addCalendar()
				break;
			case ENUMS.MODAL.EDIT_MODE: 
				updateCalendar()
				break;
			case ENUMS.MODAL.COPY_MODE: 
				copyCalendar()
				break;
			case ENUMS.MODAL.SET_MODE: 
				setCalendar()
				break;
			default: 
				copyCalendar()
				break;
		}
	};

	const populateCalendarData = () => {
		setCalendarName(props.calendarData.calendar_name);
		setWorkingDay(constructWorkingDaysDefaultValue(props.calendarData.work_day_type));
        setWorkingStartTime(props.calendarData.working_start_time);
        setWorkingEndTime(props.calendarData.working_end_time);
	};

	const constructWorkingDaysDefaultValue = (workingDaysString) => {
		return workingDaysString
			.split(',')
			.map(wdt => workingDayOptions.find(option => option.value === wdt));
	}

	const toggleCopyHolidayCheckbox = (event) => {
		setCopyCalendarHolidays(!copyCalendarHolidays);
	};

	useEffect(() => {
		setWorkingDay(constructWorkingDaysDefaultValue("monday,tuesday,wednesday,thursday,friday"));
		if (props.calendarData) {
			populateCalendarData();
		}
	}, []);

	useEffect(()=> {
		if (calendarNameFocusRef.current) {
			calendarNameFocusRef.current.focus()
		}
	}, [calendarNameFocusRef.current]);
	
	return (
		<Modal
			size="md"
			isOpen={props.showModal}
			toggle={() => props.closeModal(false)}
			backdrop="static"
		>
			<ModalHeader toggle={() => props.closeModal(false)}>
				{props.modalTitle}
			</ModalHeader>
            <Form role="form" onSubmit={handleSubmit(handleConfirm)}>
			    <ModalBody className="bg-secondary">
					<FormGroup className="mb-3">
						<Label for="calendarName">Calendar Name<span className="required-asterix">*</span></Label>
						<Input
                            id="calendarName"
                            name="calendarName"
							className="form-control-alternative"
							type="text"
							value={calendarName}
                            onChange={(e) => setCalendarName(e.target.value)}
                            innerRef={(e) => {
								calendarNameFocusRef.current = e;
								register(
									e,
									{
										required: "Calendar Name is required."
									})
								}
							}
						/>
						<CustomErrorMessage errors={errors} name="calendarName" />
					</FormGroup>
					<FormGroup className="mb-3">
						<Label for="workingDays">Working Days<span className="required-asterix">*</span></Label>
						<Select
							id="workingDays"
							name="workingDays"
							className="form-control-alternative"
							defaultValue={workingDays}
							isMulti
							options={workingDayOptions}
							classNamePrefix="select"
							// as={Select}
							// control={control}
							// rules={{ required: "Workdays is required." }}
							onChange={(e) => setWorkingDay(e)}
						/>
						<CustomErrorMessage errors={errors} name="workingDays" />
					</FormGroup>
					<FormGroup className="mb-3">
						<Label for="workingStartTime">Working Day Start Time<span className="required-asterix">*</span></Label>
						<InputGroup className="input-group-alternative mb-3">
							<Input
								id="workingStartTime"
								name="workingStartTime"
								type="time"
								value={workingStartTime}
								onChange={(e) => setWorkingStartTime(e.target.value)}
								innerRef={register(
									{
										required: "Working Day Start Time is required."
									})}
							/>
						</InputGroup>
						<CustomErrorMessage errors={errors} name="workingStartTime" />
					</FormGroup>
                    <FormGroup className="mb-3">
						<Label for="workingEndTime">Working Day End Time<span className="required-asterix">*</span></Label>
						<InputGroup className="input-group-alternative mb-3">
							<Input
								id="workingEndTime"
								name="workingEndTime"
								type="time"
								value={workingEndTime}
								onChange={(e) => setWorkingEndTime(e.target.value)}
								innerRef={register(
									{
										required: "Working Day End Time is required."
									})}
							/>
						</InputGroup>
						<CustomErrorMessage errors={errors} name="workingEndTime" />
					</FormGroup>
					{
						props.modalType === ENUMS.MODAL.COPY_MODE &&
						<FormGroup check>
							<Label check>
								<Input 
									id="copyCalendarCheckbox"
									className="form-control-alternative"
									type="checkbox"
									value={copyCalendarHolidays}
									onChange={(e) => toggleCopyHolidayCheckbox(e)}
								/>{' '}
								Copy all calendar exceptions
							</Label>
						</FormGroup>
					}
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={() => props.closeModal(false)}>
						Cancel
					</Button>
					<Button color="primary" type="submit">
						{props.confirmActionText}
					</Button>{" "}
				</ModalFooter>
			</Form>
		</Modal>
	);
}
