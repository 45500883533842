import React, { useState, useEffect } from "react";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader, 
    CardBody,
    Button,
    Input
} from "reactstrap";
import ENUMS from "constants/appEnums";
import CustomTablePrime from "components/CustomTable/CustomTablePrime";
import TableHeadersUtil from "util/TableHeadersUtil";
import { format } from 'date-fns';
import { API } from "aws-amplify";
import HttpUtil from "util/HttpUtil";
import { useToasts } from 'react-toast-notifications';
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import UserUtil from "util/UserUtil";
import TaskUtil from "util/TaskUtil";
import AddEditActionModal from "components/Action/AddEditActionModal/AddEditActionModal";
import DateTimeUtils from "util/DateTimeUtils";
import htmlToDraft from 'html-to-draftjs';
import { useSelector } from "react-redux";
import _ from "lodash";
import GeneralUtils from "util/GeneralUtils";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import InputGroupText from "reactstrap/lib/InputGroupText";
import ViewActionModal from "components/Action/ViewActionModal";
import RULES from "rbac/rules";

export default function ProjectViewActionTable (props) {

    const { addToast } = useToasts();

    // Team Data and Header for Table
    const [actionList, setActionList] = useState([]);
    const [actionListHeader, setActionListHeader] = useState([]);

    // Table props
    const [loading, setLoading] = useState(true);
    const [tableRef, setTableRef] = useState(null);
    
    const [quickFilterText, setQuickFilterText] = useState("");

    /**
     * Delete Modal
     */
    const [showDeleteActionModal, setShowDeleteActionModal] = useState(false);
    const [deleteActionModalText, setDeleteActionModalText] = useState("");
    const [selectedActionId, setSelectedActionId] = useState("");
    const [deleteModalActionTexts, setDeleteModalActionTexts] = useState({
        'confirm': ENUMS.BUTTON_TEXT.DELETE_ACTION_TEXT_CONFIRM,
        'cancel': ENUMS.BUTTON_TEXT.CANCEL
    });

    // Current User
    const user = useSelector(state => state.authReducer.user);

    /** ADD/EDIT Action modal */
    const [showAddEditActionModal, setShowAddEditActionModal] = useState(false);
    const [modalMode, setModalMode] = useState("");
    const [editActionId, setEditActionId] = useState(null);

    /**
     * View Modal
     */
    const [viewActionModalOpen, setViewActionModalOpen] = useState(false);
    const [viewActionModalTitle, setViewActionModalTitle] = useState("View Stakeholder");
    const [actionToView, setActionToView] = useState('');
 
    // TABLE filtering and sorting
    const [ogActionList, setOgActionList] = useState([]);
    const [colKeyList, setColKeyList] = useState(
        ['description', 'ActionOwner',
        'status', 'note', 'due_date']
    );

    const populateActionTable = () => {
        populateActionHeaders();
        if (props.projectActionList && 
            props.projectActionList.length > 0) {
            populateActionData();
        } else {
            setActionList([]);
        }
    }

    const populateActionHeaders = () => {
        /**
         * Columns object to be passed to table
         */
        setActionListHeader(TableHeadersUtil.projectViewActionTableHeaders);
    }

    const populateActionData = () => {
        const updatedActionList = props.projectActionList.map((action, index) => {
            action['action_number_link'] = GeneralUtils.viewPageLink(
                action.id,
                index +1,
                ENUMS.VIEW_PATH.ACTION,
                '?actionId=' + action.id,
                {passedId: action.id}
            );
            action['action_owner_name'] = UserUtil.formatUserForTableColumn(action.ActionOwner);
            action['task'] = TaskUtil.formatTaskNumberWithName(action.Task.id, action.Task.task_number, action.Task.task_name);
            action['action_status_view'] = TaskUtil.formatTaskStatusForTable(action.status);
            action['due_date'] = format(DateTimeUtils.convertTimezone(
                new Date(action['due_date']), user), 
                DateTimeUtils.getUserDateTimeFormat(user.date_format, user.twenty_four_hour_clock));
            action['created_at'] = format(DateTimeUtils.convertTimezone(
                new Date(action['createdAt']), user), 
                DateTimeUtils.getUserDateTimeFormat(user.date_format, user.twenty_four_hour_clock))
            return action;
        });
        setActionList(updatedActionList);
        setOgActionList(updatedActionList);
    }

    const openViewActionModal = (data) => {
        setViewActionModalOpen(true);
        setActionToView(data);
    }

    const closeActionViewModal = () => {
        setViewActionModalOpen(false);
    }

    const openDeleteActionModal = (action) => {
        console.log(action)
        setShowDeleteActionModal(true);
        setDeleteActionModalText(ENUMS.MODAL.DELETE_CONFIRMATION_TEXT + action.description + " action ?");
        setSelectedActionId(action.id);
    }

    const hideDeleteActionModal = () => {
        setShowDeleteActionModal(false);
    }

    const deleteActionConfirmed = (actionId) => {
        API.del(ENUMS.AWS.API_NAME, ENUMS.API_ROUTES.ACTION_DELETE, 
        {
            ...HttpUtil.adminHttpHeaders(),
            body:  {
                actionId: actionId
            }
        })
        .then(res => {
            if(res && res.status === 200) {
                refreshPageOnSubmit("Action has been deleted successfully");
            }
        })
        .catch(error => {
            if (error.response) {
                console.log(error.response);
                addToast(
                    "Failed to delete action.", 
                    { 
                        appearance: 'error',
                        autoDismiss: true
                    }
                );
            }
        })
    }

    const closeViewModal = () => {
        // setViewActionModalOpen(false);
    }

    /**
     * Method to handle opening the task modal in edit mode
     */
    const openEditActionModal = (action) => {
        setModalMode(ENUMS.MODAL.EDIT_MODE);
        setEditActionId(action.id);
        setShowAddEditActionModal(true);
    }

    const optionsList = React.useMemo(() => 
    [
        {
            itemName: 'View',
            itemType: 'View',
            openViewModal: openViewActionModal,
        },
        {
            itemName: 'Edit',
            itemType: 'Edit',
            openEditModal: openEditActionModal,
            role: [RULES.ROLE_NAMES.PROJECT_OWNER, RULES.ROLE_NAMES.TEAM_LEADER, RULES.ROLE_NAMES.ACTION_OWNER],
            perform: RULES.RULE_NAMES.ACTION_EDIT,
        },
        {
            itemName: 'Delete',
            itemType: ENUMS.RENDER_OPTIONS_TYPE.DELETE,
            delete: openDeleteActionModal,
            role: [RULES.ROLE_NAMES.PROJECT_OWNER, RULES.ROLE_NAMES.TEAM_LEADER, RULES.ROLE_NAMES.ACTION_OWNER],
            perform: RULES.RULE_NAMES.ACTION_DELETE,
        }
    ], []);
    
    /**
     * Function to handle custom global filtering
     * @param {*} value 
     */
    const handleGlobalFiltering = (value) => {
        setQuickFilterText(value);
        let filteredActions = _.cloneDeep(ogActionList);

        if (value !== '') {
            const lowerVal = value.toLowerCase();
            filteredActions = filteredActions.filter(action => {
                return colKeyList.some(col => {
                    if (action[col] !== null) {
                        if (col === 'ActionOwner') {
                            if (action[col]['email'].toString().toLowerCase().includes(lowerVal))
                                return true;
                            else {
                                return UserUtil.formatUserForTableColumn(action[col]).toString().toLowerCase().includes(lowerVal);
                            }
                        }
                        return action[col].toString().toLowerCase().includes(lowerVal);
                    }
                })
            });
        }
        setActionList(filteredActions);
    }

    /**
     * Method to handle the exporting to csv.
     * @param {Object} e 
     */
    const exportActionList = (e) => {
        e.preventDefault();
        let csvContent = "data:text/csv;charset=utf-8,";
        let headers = "S.No.,Project id,Project Name,Description,Status,Due Date,Task Number,Note,Action Owner,Created On\r\n";
        csvContent += headers;
        actionList.forEach((action, index) => {
            let rowArray = [
                index + 1,
                props.projectDetails.id,
                GeneralUtils.handleStringForCSV(props.projectDetails.project_name),
                GeneralUtils.handleStringForCSV(action.description),
                TaskUtil.taskStatusTypes.filter(type => type.value === action.status)[0].label,
                action.due_date,
                action.Task.task_number,
                GeneralUtils.extractContentFromHTMLString(action.note.trim().replace(/(\r\n|\n|\r)/gm, " ")),
                UserUtil.formatUserForTableColumn(action.ActionOwner),
                action.created_at
            ];
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", props.projectDetails.project_name + "_actions.csv");
        document.body.appendChild(link); // Required for FF
        link.click(); // This will download the data file named "my_data.csv".
    }

    /**
     * Method to reset the filters and sorting on the entire table. 
     */
    const resetTable = () => {
        tableRef.reset();
    }

    /**
     * Refreshes the page on submit of add/edit/delete form
     * @param {String} message 
     */
    const refreshPageOnSubmit = (message) => {
        setShowAddEditActionModal(false);
        setShowDeleteActionModal(false);
        props.refreshViewOnAction(ENUMS.TABS.ACTIONS_LIST, message);
    }

    const openAddActionModal = () => {
        setModalMode(ENUMS.MODAL.ADD_MODE);
        setShowAddEditActionModal(true);
    }

    const closeAddEditActionModal = () => {
        setShowAddEditActionModal(false);
    }

    useEffect (() => {
        populateActionTable();
        setLoading(false);
    }, [props.projectActionList]);

    return (
        <>
            <Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="bg-secondary box-shadow-down card-border-radius-0">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center mb-4">
                                <Col xs="6">
                                    <h3 className="mb-0">Action List</h3>
                                </Col>
                                <Col className="text-right">
                                    <Button
                                        color="secondary"
                                        onClick={resetTable}
                                        size="md"
                                    >
                                        Reset Filters
                                    </Button>
                                    <Button
                                        color="secondary"
                                        onClick={e => exportActionList(e)}
                                        size="md"
                                        disabled={actionList.length === 0}
                                    >
                                        Export
                                    </Button>
                                    <Button 
                                        size="md"
                                        color="primary"
                                        onClick={(e) => openAddActionModal()}
                                    >
                                        Add Action
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-2">
                                <Col xs="6" className="">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-search"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            size="md"
                                            type="text"
                                            id="quickFilterText"
                                            value={quickFilterText}
                                            onChange={e => handleGlobalFiltering(e.target.value)}
                                            placeholder="Search Table...">
                                        </Input>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <CustomTablePrime
                                columnDefs={actionListHeader} 
                                rowData={actionList}
                                loading={loading}
                                optionsList={optionsList}
                                tableRef={tableRef}
                                setTableRef={setTableRef}
                                projectOwnerId={props.projectDetails.project_owner_id}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            { 
                viewActionModalOpen && 
                <ViewActionModal
                    modalTitle={viewActionModalTitle}
                    modalOpen={viewActionModalOpen}
                    actionData={actionToView}
                    closeModal={closeActionViewModal}
                /> 
            }
            {
                /* {
                    uploadModalOpen && 
                    <UploadModal
                        modalTitle={ENUMS.MODAL.UPLOAD_FILE_MODAL_TITLE}
                        modalButtonName={ENUMS.BUTTON_TEXT.UPLOAD}
                        showModal={uploadModalOpen}
                        closeUploadModal={closeUploadModal}
                        uploadTo="project"
                        projectId={props.projectDetails.id}
                        refreshViewOnAction={props.refreshViewOnAction}
                    />
                } */
            }
            {
                showDeleteActionModal && 
                <ConfirmationModal
                    actionText={deleteModalActionTexts}
                    onCancel={hideDeleteActionModal}
                    onConfirm={deleteActionConfirmed}
                    modalBody={deleteActionModalText}
                    showModal={showDeleteActionModal}
                    modalTitle={ENUMS.MODAL.DELETE_ACTION_TITLE}
                    data={selectedActionId}
                />
            }
            {
                showAddEditActionModal && 
                <AddEditActionModal
                    modalTitle={modalMode === ENUMS.MODAL.ADD_MODE ? ENUMS.MODAL.ADD_ACTION_TITLE : ENUMS.MODAL.EDIT_ACTION_TITLE}
                    modalButtonName={ ENUMS.MODAL.ADD_MODE ? ENUMS.BUTTON_TEXT.ADD : ENUMS.BUTTON_TEXT.SAVE }
                    modalOpen={showAddEditActionModal}
                    closeModal={closeAddEditActionModal}
                    projectData={props.projectDetails}
                    modalMode={modalMode}
                    refreshPageOnSubmit={refreshPageOnSubmit}
                    editActionId={editActionId}
                /> 
            }
        </>
    )
}
