import React, { useState, useEffect } from "react";
import { 
    Modal, 
    ModalHeader,
    ModalBody,
    Card, 
    CardBody,
    Button,
    Row,
    Col,
    CardHeader,
} from "reactstrap";
import { useToasts } from "react-toast-notifications";
import ENUMS from "constants/appEnums";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import CustomTablePrime from "components/CustomTable/CustomTablePrime";
import AddCalendarExceptionModal from "../AddCalendarExceptionModal/AddCalendarExceptionModal";
import HttpUtil from "util/HttpUtil";
import { API } from "aws-amplify";
import TableHeadersUtil from "util/TableHeadersUtil";
import RULES from "rbac/rules";
import { format } from "date-fns";
import DateTimeUtils from "util/DateTimeUtils";
import { useSelector } from "react-redux";
import "./ListCalendarExceptionsModal.css";

export default function ListCalendarExceptionsModal (props) {
    
    const [calendarHolidayId, setCalendarHolidayId] = useState('');

    // grab current state
    const user = useSelector(state => state.authReducer.user);

    /**
     * TOAST hook
     */
    const { addToast } = useToasts();
    
    const [columns, setColumns] = useState([]);
    /**
     * public holidays rows for table
     */
    const [calendarHolidays, setCalendarHolidays] = useState([]);

    /**
     * DELETE CALENDAR MODAL
     */
    const [deleteModalText, setDeleteModalText] = useState("");
    const [showDeleteHolidayModal, setShowDeleteHolidayModal] = useState(false);
    const [deleteModalActionTexts, setDeleteModalActionTexts] = useState({
        'confirm': ENUMS.BUTTON_TEXT.DELETE_ACTION_TEXT_CONFIRM,
        'cancel': ENUMS.BUTTON_TEXT.CANCEL
    });

    /**
     * Add Edit Public Holiday Modal
     */
    const [showAddEditPublicHolidayModal, setShowAddEditPublicHolidayModal] = useState(false);
    const [holiday, setHoliday] = useState(null);

    /**
     * Table properties
     */
    const [tableRef, setTableRef] = useState(null);
    const [loading, setLoading] = useState(true);

    /**
     * Open AddEditPublicHolidayModal
     * @param {object} holiday 
     */
    const openAddEditCalendarHolidayModal = (holidayData = null) => {
        setHoliday(holidayData);
        setShowAddEditPublicHolidayModal(true);
    }

    const hidePublicHolidayModal = () => {
        setShowAddEditPublicHolidayModal(false);
    }

    const openConfirmationModal = (openHoliday) => {
        setCalendarHolidayId(openHoliday.id);
        setDeleteModalText(ENUMS.MODAL.DELETE_CONFIRMATION_TEXT + openHoliday.holiday_name + " calendar exception ?");
        setShowDeleteHolidayModal(true);
    }

    const optionsList = [
        {
            itemName: 'Edit calendar exception',
            itemType: 'Edit',
            openEditModal: openAddEditCalendarHolidayModal,
            role: [RULES.ROLE_NAMES.PROJECT_OWNER],
            perform: RULES.RULE_NAMES.CALENDAR_EXCEPTIONS_EDIT,
        },
        {
            itemName: 'Delete calendar exception',
            itemType: ENUMS.RENDER_OPTIONS_TYPE.DELETE_CALENDAR,
            deleteCalendar: openConfirmationModal,
            role: [RULES.ROLE_NAMES.PROJECT_OWNER],
            perform: RULES.RULE_NAMES.CALENDAR_EXCEPTIONS_DELETE,
        }
    ];

    const  getAllCalendarHolidays = () => {
        API
            .get(
                ENUMS.AWS.API_NAME,
                ENUMS.API_ROUTES.CALENDAR_EXCEPTION_GET_ALL,
                {
                    ...HttpUtil.adminHttpHeaders(),
                    queryStringParameters: 
                    {
                        calendar_id: props.calendar.id
                    }
                }
            )
            .then(res => {
                if(res && res.status === 200) {
                    const calendarHolidayList = res.data.data.map(exception => {
                        return {
                            ...exception,
                            start_date: format(DateTimeUtils.convertTimezone(
                                    new Date(exception.start_date), user), 
                                DateTimeUtils.getUserDateTimeFormat(
                                    user.date_format, user.twenty_four_hour_clock)),
                            end_date: format(DateTimeUtils.convertTimezone(
                                    new Date(exception.end_date), user), 
                                DateTimeUtils.getUserDateTimeFormat(
                                    user.date_format, user.twenty_four_hour_clock))
                        }
                    })
                    setCalendarHolidays(calendarHolidayList);
                    setLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    /**
     * handle delete calendar holiday
     * @param {integer} calendarHolidayIdToDelete 
     */
    const deleteCalendarHolidayConfirmed = (calendarHolidayIdToDelete) => {
        API
            .del(
                ENUMS.AWS.API_NAME,
                ENUMS.API_ROUTES.CALENDAR_EXCEPTION_DELETE,
                {
                    ...HttpUtil.adminHttpHeaders(),
                    body: {
                        calendar_holiday_id: calendarHolidayIdToDelete
                    }
            
                }
            )
            .then(res => {
                if(res && res.status === 200) {
                    addToast('Calendar Exception has been deleted successfully!', { 
                        appearance: 'success',
                        autoDismiss: true
                    });
                    getAllCalendarHolidays();
                    setShowDeleteHolidayModal(false);
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response);
                    addToast(
                        "Failed to delete Calendar Exception", 
                        { 
                            appearance: 'error',
                            autoDismiss: true
                        }
                    );
                }
            })
    }

    useEffect(() => {
        setColumns(TableHeadersUtil.calendarHolidayTableHeaders);
        getAllCalendarHolidays();
    }, [])

    return (
        <>
            <Modal
                className="modal-dialog-centered custom-modal-style"
                isOpen={props.showModal}
                toggle={() => props.closeModal(false)}
                backdrop="static"
            >
                <ModalHeader 
                    toggle={() => props.closeModal(false)}
                    className="modal-header"
                    tag="div"
                >
                    <h3 className="modal-title">
                        {props.modalTitle}
                    </h3>
                </ModalHeader>
                <ModalBody className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-4 py-lg-4">
                            <Row>
                                <Col className="mb-5 mb-xl-0" xl="12">
                                    <Card className="shadow">
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <div className="col">
                                                    
                                                </div>
                                                <div className="col text-right">
                                                    <Button className="float-right" color="primary" onClick={(e) => openAddEditCalendarHolidayModal()}>Add Calendar Exception</Button>
                                                </div>
                                            </Row>
                                        </CardHeader>
                                        <CustomTablePrime
                                            columnDefs={columns} 
                                            rowData={calendarHolidays}
                                            loading={loading}
                                            optionsList={optionsList}
                                            tableRef={tableRef}
                                            setTableRef={setTableRef}
                                            stateStorageName={
                                                ENUMS.DT_STATE_STORAGE_NAMES.CALENDAR_HOLIDAY_LIST + 
                                            "-" + props.calendar.id}
                                            projectOwnerId={props.projectOwnerId}
                                        />
                                    </Card>
                                </Col>
                            </Row>          
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>
            {
                showDeleteHolidayModal && 
                <ConfirmationModal
                    showModal={showDeleteHolidayModal}
                    actionText={deleteModalActionTexts}
                    modalBody={deleteModalText}
                    modalTitle={ENUMS.MODAL.DELETE_CALENDAR_HOLIDAY_TITLE}
                    onCancel={setShowDeleteHolidayModal}
                    onConfirm={deleteCalendarHolidayConfirmed}
                    data={calendarHolidayId}
                />
            }

            {
                showAddEditPublicHolidayModal && 
                <AddCalendarExceptionModal
                    onCancel={hidePublicHolidayModal}
                    showModal={showAddEditPublicHolidayModal}
                    calendar={props.calendar}
                    holidayData={holiday}
                    refreshList={getAllCalendarHolidays}
                />
            }
        </>
    )
}