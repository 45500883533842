import React, { useState, useEffect } from "react";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader, 
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    } from "reactstrap";
import ENUMS from "constants/appEnums";
import { useToasts } from 'react-toast-notifications';
import _ from "lodash";
import classnames from "classnames";
import ProjectViewRiskTable from "./ProjectViewRiskTable";
import ProjectViewAssumptionTable from "./ProjectViewAssumptionTable";
import ProjectViewIssueTable from "./ProjectViewIssueTable";
import ProjectViewDependencyTable from "./ProjectViewDependencyTable";
import { useLocalStorage } from "hooks/useLocalStorage";

export default function ProjectRaidView (props) {

    const { addToast } = useToasts();

    const [activeRaidTab, setActiveRaidTab] = useLocalStorage(
      ENUMS.LOCAL_STORAGE_KEYS.PROJECT_VIEW_ACTIVE_RAID_TAB,
      ENUMS.TABS.RISK_LIST
    );

    const [listOfRisks, setRisks] = useState([]);
    const [listOfAssumptions, setAssumptions] = useState([]);
    const [listOfIssues, setIssues] = useState([]);
    const [listOfDependencies, setDependencies] = useState([]);

    const toggle = (tab) => {
		if (activeRaidTab !== tab) setActiveRaidTab(tab);
	};
    
    const refreshViewOnAction = (tab, message) => {
        // addToast(message, { 
        //     appearance: 'success',
        //     autoDismiss: true
        // });
        toggle(tab);
        props.refreshViewOnAction(ENUMS.TABS.RAID_VIEW, message);
    }

    useEffect (() => {
        setRisks(props.risks);
    }, [props.risks]);

    useEffect (() => {
        setAssumptions(props.assumptions);
    }, [props.assumptions]);

    useEffect (() => {
        setIssues(props.issues);
    }, [props.issues]);

    useEffect (() => {
        setDependencies(props.dependencies);
    }, [props.dependencies]);

    return (
        <>
            {
                listOfRisks &&
                listOfAssumptions &&
                listOfIssues &&
                listOfDependencies &&
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary box-shadow-down card-border-radius-0">
                            <CardHeader className="bg-white border-0 pb-0">
                                <Nav tabs>
                                    <NavItem className="settings-tabs">
                                        <NavLink
                                            className={classnames({ active: activeRaidTab === ENUMS.TABS.RISK_LIST })}
                                            onClick={() => toggle(ENUMS.TABS.RISK_LIST)}
                                        >
                                            Risk
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="settings-tabs">
                                        <NavLink
                                            className={classnames({ active: activeRaidTab === ENUMS.TABS.ASSUMPTION_LIST })}
                                            onClick={() => toggle(ENUMS.TABS.ASSUMPTION_LIST)}
                                        >
                                            Assumptions
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="settings-tabs">
                                        <NavLink
                                            className={classnames({ active: activeRaidTab === ENUMS.TABS.ISSUES_LIST })}
                                            onClick={() => toggle(ENUMS.TABS.ISSUES_LIST)}
                                        >
                                            Issues
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="settings-tabs">
                                        <NavLink
                                            className={classnames({ active: activeRaidTab === ENUMS.TABS.DEPENDENCIES_LIST })}
                                            onClick={() => toggle(ENUMS.TABS.DEPENDENCIES_LIST)}
                                        >
                                            Dependencies
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <CardBody className="">
                                <TabContent activeTab={activeRaidTab}>
                                    <TabPane tabId={ENUMS.TABS.RISK_LIST}>
                                        <ProjectViewRiskTable
                                            projectDetails={props.projectDetails}
                                            refreshViewOnAction={refreshViewOnAction}
                                            projectRiskList={listOfRisks}
                                        ></ProjectViewRiskTable>
                                    </TabPane>
                                    <TabPane tabId={ENUMS.TABS.ASSUMPTION_LIST}>
                                        <ProjectViewAssumptionTable
                                            projectDetails={props.projectDetails}
                                            refreshViewOnAction={refreshViewOnAction}
                                            projectAssumptionList={listOfAssumptions}
                                        ></ProjectViewAssumptionTable>
                                    </TabPane>
                                    <TabPane tabId={ENUMS.TABS.ISSUES_LIST}>
                                        <ProjectViewIssueTable
                                            projectDetails={props.projectDetails}
                                            refreshViewOnAction={refreshViewOnAction}
                                            projectIssueList={listOfIssues}
                                        ></ProjectViewIssueTable>
                                    </TabPane>
                                    <TabPane tabId={ENUMS.TABS.DEPENDENCIES_LIST}>
                                        <ProjectViewDependencyTable
                                            projectDetails={props.projectDetails}
                                            refreshViewOnAction={refreshViewOnAction}
                                            projectDependencyList={listOfDependencies}
                                        ></ProjectViewDependencyTable>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </>
    )
}
