/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

export default function AuthNavbar(props) {
  
  return (
    <>
      <Navbar
        className="navbar-top navbar-horizontal navbar-dark"
        expand="md"
      >
        <Container className="px-4">
          <NavbarBrand href="/">
            <img alt="..." className="navbar-image-auth" src={require("assets/img/brand/cutover-plan-logo.png")} />
          </NavbarBrand>
          <button className="navbar-toggler" id="navbar-collapse-main">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/">
                    <img
                      alt="..."
                      src={require("assets/img/brand/argon-react.png")}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    id="navbar-collapse-main"
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>
                <LinkContainer to="/auth/register">
                    <NavItem>
                        <NavLink
                        className="nav-link-icon"
                        to="/auth/register"
                        tag={Link}
                        >
                        <i className="ni ni-circle-08" />
                        <span className="nav-link-inner--text">Register</span>
                        </NavLink>
                    </NavItem>
                </LinkContainer>
                <LinkContainer to="/auth/login">
                    <NavItem>
                        <NavLink
                        className="nav-link-icon"
                        to="/auth/login"
                        tag={Link}
                        >
                        <i className="ni ni-key-25" />
                        <span className="nav-link-inner--text">Login</span>
                        </NavLink>
                    </NavItem>
                </LinkContainer>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
}
