import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, Form, InputGroup } from "reactstrap";
import { API } from "aws-amplify";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker";
import { useToasts } from 'react-toast-notifications';
import ENUMS from 'constants/appEnums';
import HttpUtil from "util/HttpUtil";
import { useForm } from 'react-hook-form';
import CustomErrorMessage from 'components/CustomErrorMessage/CustomErrorMessage';
import Select from "react-select";
import "./AddCalendarExceptionModal.css";
import DataOptions from "constants/appDataOptions";
import makeAnimated from "react-select/animated";

export default function AddCalendarExceptionModal(props) {
    const { addToast } = useToasts();

    /**
     * React hook form local state
     */
    const { register, handleSubmit, errors, getValues } = useForm({
        mode: "onBlur"
    });
    const holidayNameFocusRef = useRef(null);

    const [holidayName, setHolidayName] = useState("");
    const [holidayId, setHolidayId] = useState('');
    const [edit, setEdit] = useState(false);
    const [exceptionType, setExceptionType] = useState(ENUMS.EXCEPTION_DEFAULT_VALUES.HOLIDAY);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [workingStartTime, setWorkingStartTime] = useState("09:00");
	const [workingEndTime, setWorkingEndTime] = useState("17:00");
    const animatedComponents = makeAnimated();

    /**
	 * Check if the form data is filled or not
	 *
	 * @return {boolean}
	 */
	const isFormValid = () => {
		if (
			!holidayName ||
            !exceptionType ||
            !startDate ||
            !endDate ||
            (
                exceptionType === ENUMS.EXCEPTION_DEFAULT_VALUES.WORKING_DAY
                && (!workingStartTime || !workingEndTime)
            ) 
		) {
			return false;
        }

		return true;
    };

    /**
	 * Call to create new calendar
	 *
	 * @param {object} e
	 */
	const handleAddEditPublicHoliday = (data, e) => {
		e.preventDefault();
        
		//TODO: Proper Validation to be used
		if (!isFormValid()) return;

        if (edit) {
            editHoliday();
        } else {
            addHoliday();
        }
    };

    const addHoliday = () => {
		API
			.post(
                ENUMS.AWS.API_NAME,
				ENUMS.API_ROUTES.CALENDAR_EXCEPTION_ADD,
				{
                    ...HttpUtil.adminHttpHeaders(),
                    body: 
                    {
                        calendar_id: props.calendar.id,
                        holiday_name: holidayName,
                        exception_type: exceptionType,
                        start_date: startDate,
                        end_date: endDate,
                        working_start_time: exceptionType === ENUMS.EXCEPTION_DEFAULT_VALUES.WORKING_DAY ? workingStartTime: null,
                        working_end_time: exceptionType === ENUMS.EXCEPTION_DEFAULT_VALUES.WORKING_DAY ? workingEndTime: null,
                    }
				}
			)
			.then((res) => {
				if (res.status === 201 && res.data.success) {
                    addToast('Calendar Exception has been created.', { 
                        appearance: 'success',
                        autoDismiss: true
                    });
                    props.onCancel();
                    props.refreshList && props.refreshList();
				} else {
					console.log("Calendar Exception not created, error occured!");
				}
			})
			.catch((error) => {
				console.log("Error Occured!", error);
			});
    }

    const editHoliday = () => {
        API
            .put(
                ENUMS.AWS.API_NAME,
                ENUMS.API_ROUTES.CALENDAR_EXCEPTION_EDIT,
                {
                    ...HttpUtil.adminHttpHeaders(),
                    body: 
                    {
                        id: holidayId,
                        calendar_id: props.calendar.id,
                        holiday_name: holidayName,
                        exception_type: exceptionType,
                        start_date: startDate,
                        end_date: endDate,
                        working_start_time: exceptionType === ENUMS.EXCEPTION_DEFAULT_VALUES.WORKING_DAY ? workingStartTime: null,
                        working_end_time: exceptionType === ENUMS.EXCEPTION_DEFAULT_VALUES.WORKING_DAY ? workingEndTime: null,
                    }
                }
            )
			.then((res) => {
				if (res.status === 200 && res.data.success) {
                    addToast('Calendar Exception has been updated.', { 
                        appearance: 'success',
                        autoDismiss: true
                    });
                    props.onCancel();
                    props.refreshList && props.refreshList();
				} else {
					console.log("Calendar Exception not updated, error occured!");
				}
			})
			.catch((error) => {
				console.log("Error Occured!", error);
			});
    }

    useEffect(() => {
        if (props.holidayData) {
            setEdit(true);
            setHolidayId(props.holidayData.id);
            setHolidayName(props.holidayData.holiday_name);
            setExceptionType(props.holidayData.exception_type);
            setStartDate(new Date(props.holidayData.start_date));
            setEndDate(new Date(props.holidayData.end_date));
            setWorkingStartTime(props.holidayData.working_start_time);
            setWorkingEndTime(props.holidayData.working_end_time);
        }
    }, []);

    useEffect(()=> {
		if (holidayNameFocusRef.current) {
            holidayNameFocusRef.current.focus();
        } 
    }, [holidayNameFocusRef.current]);

    return (
        <Modal
            size="md"
            isOpen={props.showModal}
            toggle={() => props.onCancel()}
            backdrop="static"
        >
            <ModalHeader toggle={() => props.onCancel()}>
                {
                    edit 
                        ? ENUMS.MODAL.CALENDAR_EXCEPTION_EDIT_MODAL_TITLE
                        : ENUMS.MODAL.CALENDAR_EXCEPTION_ADD_MODAL_TITLE
                }
            </ModalHeader>
            <Form role="form" onSubmit={handleSubmit(handleAddEditPublicHoliday)}>
                <ModalBody className="bg-secondary">
                    <FormGroup className="mb-3">
                        <Label for="exceptionType">Calendar Exception Type <span className="required-asterix">*</span></Label>
                        <Select
                            className="form-control-alternative"
                            id="exceptionType"
                            name="exceptionType"
                            options={DataOptions.EXCEPTION_OPTIONS}
                            components={animatedComponents}
                            value={DataOptions.EXCEPTION_OPTIONS.filter(option => option.value === exceptionType)}
                            onChange={(e) => setExceptionType(e.value)}
                        />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Label for="holidayName">{exceptionType === ENUMS.EXCEPTION_DEFAULT_VALUES.WORKING_DAY ? 'Working Day' : 'Holiday'}  Name<span className="required-asterix">*</span></Label>
                        <Input
                            id="holidayName"
                            name="holidayName"
                            className="form-control-alternative"
                            type="text"
                            value={holidayName}
                            onChange={(e) => setHolidayName(e.target.value)}
                            innerRef={(e) => {
                                holidayNameFocusRef.current = e;
                                register(
                                    e,
                                    {
                                        required: (exceptionType === ENUMS.EXCEPTION_DEFAULT_VALUES.WORKING_DAY ? 'Working Day' : 'Holiday') + " name is required.",
                                    }
                                );
                            }}
                        />
                        <CustomErrorMessage errors={errors} name="holidayName" />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Label for="startDate">Start Date<span className="required-asterix">*</span></Label>
                        <div className="react-datepicker-manager" id="start-date-picker">
                            <CustomDatePicker
                                id="startDate"
                                name="startDate"
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                showTimeSelect={false}
                                renderToEl="start-date-picker"
                            />
                            {/* <DatePicker
                                id="startDate"
                                name="startDate"
                                className="form-control form-control-alternative react-datepicker-manager"
                                // as={DatePicker}
                                // control={control}
                                // rules={{ required: "Start date is required." }}
                                selected={startDate}
                                onChange={setStartDate}
                                defaultValue={startDate}
                                dateFormat={DateTimeUtils.getUserDateFormat(user.date_format, 
                                    user.twenty_four_hour_clock)}
                            /> */}
                        </div>
                        {/* <CustomErrorMessage errors={errors} name="startDate" /> */}
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Label for="endDate">End Date<span className="required-asterix">*</span></Label>
                        <div className="react-datepicker-manager" id="end-date-picker">
                            <CustomDatePicker
                                id="endDate"
                                name="endDate"
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                showTimeSelect={false}
                                renderToEl="end-date-picker"
                            />
                            {/* <DatePicker
                                id="endDate"
                                name="endDate"
                                className="form-control form-control-alternative react-datepicker-manager"
                                // as={DatePicker}
                                // control={control}
                                // rules={{ required: "Start date is required." }}
                                selected={endDate}
                                onChange={setEndDate}
                                defaultValue={endDate}
                                dateFormat={DateTimeUtils.getUserDateFormat(user.date_format, 
                                    user.twenty_four_hour_clock)}
                            /> */}
                        </div>
                        {/* <CustomErrorMessage errors={errors} name="endDate" /> */}
                    </FormGroup>
                    { 
                        exceptionType === ENUMS.EXCEPTION_DEFAULT_VALUES.WORKING_DAY &&
                        <FormGroup className="mb-3">
                            <Label for="workingStartTime">Working Day Start Time</Label>
                            <InputGroup className="input-group-alternative mb-3">
                                <Input
                                    id="workingStartTime"
                                    name="workingStartTime"
                                    type="time"
                                    value={workingStartTime}
                                    onChange={(e) => setWorkingStartTime(e.target.value)}
                                    innerRef={register(
                                        {
                                            validate: (value) => {
                                                if (
                                                    getValues()["exceptionType"] === ENUMS.EXCEPTION_DEFAULT_VALUES.WORKING_DAY
                                                    && !value
                                                ) {
                                                    return "Working Day Start Time is required";
                                                } else {
                                                    return true;
                                                }
                                            }
                                        })}
                                />
                            </InputGroup>
                            <CustomErrorMessage errors={errors} name="workingStartTime" />
                        </FormGroup>
                    }
                    { 
                        exceptionType === ENUMS.EXCEPTION_DEFAULT_VALUES.WORKING_DAY &&
                        <FormGroup className="mb-3">
                            <Label for="workingEndTime">Working Day End Time</Label>
                            <InputGroup className="input-group-alternative mb-3">
                                <Input
                                    id="workingEndTime"
                                    name="workingEndTime"
                                    type="time"
                                    value={workingEndTime}
                                    onChange={(e) => setWorkingEndTime(e.target.value)}
                                    innerRef={register(
                                        {
                                            validate: (value) => {
                                                if (
                                                    getValues()["exceptionType"] === ENUMS.EXCEPTION_DEFAULT_VALUES.WORKING_DAY
                                                    && !value
                                                ) {
                                                    return "Working Day End Time is required";
                                                } else {
                                                    return true;
                                                }
                                            }
                                        }
                                    )}
                                />
                            </InputGroup>
                            <CustomErrorMessage errors={errors} name="workingEndTime" />
                        </FormGroup>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => props.onCancel()}>Cancel</Button>
                    <Button color="primary" type="submit">Save</Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    )
}
